import React from "react";
import "./termsConditionStyles.scss";
import { Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { sessionPrivileges } from "privileges";
import { useLogoContext } from "context/logoContext";

function studentsTermsAndConditions() {
  const Priv = sessionPrivileges().userprivileges || [];
  let history = useHistory();
  const logoImage = useLogoContext();

  return (
    <>
      <div
        className="termsAndCondition"
        style={
          Priv && Priv.includes("ADMIN_ROLE")
            ? { marginLeft: 225 }
            : Priv && Priv.includes("ATTEND_ASSESSMENT")
            ? { marginLeft: 225 }
            : { marginLeft: 225 }
        }
      >
        <h1 className="termsheader">Terms and conditions</h1>
        <div className="topics">
          <p className="topic-heading">1. Definitions</p>
          <div className="topic-content">
            <span className="subtopic-header">Company:</span>
            <span className="subtopic-content">
              Top Freshers Technologies Private Limited (TFTPL), who is offering
              the program or course.
            </span>
          </div>
          <div className="topic-content1">
            <span className="subtopic-header">Program:</span>
            <span className="subtopic-content">
              (or Course) A set of learning sessions and accessories available
              to a learner to access online
            </span>
          </div>
          <div className="subtopic-content1">
            <p>
              (via the web) and complete according to the performance
              requirements by the Company in a stipulated time duration.
            </p>
          </div>
          <div className="topic-content1">
            <span className="subtopic-header">Platform:</span>
            <span className="subtopic-content">
            {logoImage?.productName}, A Technology driven solution in which the learning
              content, the program, the course,{" "}
            </span>
          </div>
          <div className="subtopic-content1">
            <p>
              the package resides and functions enabling the learner to acquire
              the knowledge or skill, which he/she is opting acquire from TFTPL.
            </p>
          </div>
          <div className="topic-content1">
            <span className="subtopic-header">Package:</span>
            <span className="subtopic-content">
              A set of question/problem set to which the learner is to find
              solution or answer, with or without{" "}
            </span>
          </div>
          <div className="subtopic-content1">
            <p>
              practice. Package enables the learner to prepare, practice and
              perform.
            </p>
          </div>
          <div className="topic-content1">
            <span className="subtopic-header">Applicant:</span>
            <span className="subtopic-content">
              A person who wishes to enroll for a program or course offered by
              the Company by{" "}
            </span>
          </div>
          <div className="subtopic-content1">
            <p>completing its Registration steps.</p>
          </div>
          <div className="topic-content1">
            <span className="subtopic-header">Learner:</span>
            <span className="subtopic-content">
              A person who is enrolled for a course or program offered by the
              Company.
            </span>
          </div>
          <div className="topic-content1">
            <span className="subtopic-header">User:</span>
            <span className="subtopic-content">
              A term used for an applicant and/or a learner, to whomsoever the
              particular condition/statement{" "}
            </span>
          </div>
          <div className="subtopic-content1">
            <p>is applicable.</p>
          </div>
          <div className="topic-content1">
            <span className="subtopic-header">Event:</span>
            <span className="subtopic-content">
              A workshop or seminar or activity conducted by the Company for
              education, awareness
            </span>
          </div>
          <div className="subtopic-content1">
            <p>
              creation, testing, or similar purposes, whether on charge or free
              of charge.
            </p>
          </div>
          <div className="topic-content1">
            <span className="subtopic-header">Registration:</span>
            <span className="subtopic-content">
              The act of filling in a Registration Form meant for the program or
              course, which could be{" "}
            </span>
          </div>
          <div className="subtopic-content1">
            <p>
              done either online or in paper form, as the current practice may
              be.
            </p>
          </div>
          <div className="topic-content1">
            <span className="subtopic-header">Fee:</span>
            <span className="subtopic-content">
              Amount of money payable and/or paid by an applicant or learner for
              going through the program{" "}
            </span>
          </div>
          <div className="subtopic-content1">
            <p>
              or course, including any relevant miscellaneous expenses specified
              by the Company.
            </p>
          </div>
          <div className="topic-content1">
            <span className="subtopic-header">Payment:</span>
            <span className="subtopic-content">
              The act of paying fee for accessing and using a program or course,
              usually after Registration,
            </span>
          </div>
          <div className="subtopic-content1">
            <p>
              either online through the Company’s payment gateway, or by
              depositing an instrument (Cheque / Demand Draft / Pay Order) in
              the Company’s specified bank account, or handing over the payment
              instrument or cash at the specified Company center.
            </p>
          </div>
          <div className="topic-content1">
            <span className="subtopic-header">Enrolment:</span>
            <span className="subtopic-content">
              The act of completing the Registration and Payment of fee for a
              program or course as per{" "}
            </span>
          </div>
          <div className="subtopic-content1">
            <p>
              the payment terms applicable. Also, the license or right granted
              to the learner to access and use the program.
            </p>
          </div>
          <div className="topic-content1">
            <span className="subtopic-header">Learning space:</span>
            <span className="subtopic-content">
              The set of pages containing learning sessions and support, which
              the learner will be{" "}
            </span>
          </div>
          <div className="subtopic-content1">
            <p>
              able to view, navigate through, and use, after he/she logs in as
              ‘learner’ in the Company’s website.
            </p>
          </div>
          <div className="topic-content1">
            <span className="subtopic-header">Account:</span>
            <span className="subtopic-content">
              User’s records (personal information, performance data etc) stored
              in any form with the{" "}
            </span>
          </div>
          <div className="subtopic-content1">
            <p>Company</p>
          </div>
          <div className="topic-content1">
            <span className="subtopic-header">Online Identity:</span>
            <span className="subtopic-content">
              The unique combination of username, PIN and key that are assigned
              to a user, through{" "}
            </span>
          </div>
          <div className="subtopic-content1">
            <p>
              which he/she is identified by the system as a particular learner.
            </p>
          </div>
          <div className="topic-content1">
            <span className="subtopic-header">Commencement date:</span>
            <span className="subtopic-content">
              The date on which user will be able to start accessing and using
              the program.
            </span>
          </div>
          <div className="topic-content1">
            <span className="subtopic-header">Certificate:</span>
            <span className="subtopic-content">
              A proof issued by the Company in learner’s name that the learner
              has completed the{" "}
            </span>
          </div>
          <div className="subtopic-content1">
            <p>
              program as per the standards of performance set by the Company.
            </p>
          </div>
          <p className="topic-heading1">2. Fee and payment</p>
          <div className="subtopic-content1">
            <p>
              Program fees should be paid by the user or anybody on behalf of
              the user in full before the program commences, unless the Company
              has permitted any deviations in writing.
            </p>
          </div>
          <div className="subtopic-content2">
            <p>
              Any program fee displaying in the Company’s online payment
              facility is for guidance only. It is the user’s liability to pay
              correct and current fee as confirmed by the authorized personnel
              of the Company in order to enroll for the program.
            </p>
          </div>
          <div className="subtopic-content2">
            <p>
              All payments made for program, whether through the Company’s
              online payment facility, or through a valid payment instrument, or
              cash, must be made in Indian Rupees only. Otherwise, any charges
              incurred in processing the payment or any currency conversion
              costs or cheque bouncing charges or outstation cheque collection
              charges or other such connected charges shall be borne by the user
              or his/her representative making payment on the user’s behalf.
            </p>
          </div>
          <div className="subtopic-content2">
            <p>
              Program fee collected by the Company is subject to current
              taxation rules as applicable in the country of India.
            </p>
          </div>
          <div className="subtopic-content2">
            <p>
              Program fee collected in part or whole by the Company is
              non-refundable even under circumstances like withdrawal of
              enrolment by the user or termination/cancellation of the enrolment
              by the Company, unless permitted by the Company in writing as a
              special case. Refunds for such special cases shall be made to the
              card account or bank account from which the payment was originally
              made in case of online payments, or vide a crossed cheque
              favouring the payee. No refunds will be made in cash.
            </p>
          </div>
          <p className="topic-heading1">3. Online payment security</p>
          <div className="subtopic-content1">
            <p>
              The Company shall not be liable for damages or loss of any kind if
              the online payee fails to exercise caution and/or follow security
              rules and/or protect the information/data from leaking to others,
              or inputs data obtained from others for making the online payment.
            </p>
          </div>
          <p className="topic-heading1">4. Enrolment exclusivity</p>
          <div className="subtopic-content1">
            <p>
              The user is assigned a unique and limited license in the form of a
              Username and Key to access and use the program over the web for
              the period of program duration for which the applicable fee has
              been paid. It is the duty and responsibility of the user to
              protect his/her online identity from voluntary or accidental
              disclosures to any other persons before program commencement, in
              the program duration, and after program completion. The Company
              will not be liable for any consequences that arise from such
              disclosures or negligence on the part of the user.
            </p>
          </div>
          <div className="subtopic-content2">
            <p>
              If the Company decides to assign a new online identity to the user
              upon his/her request, it will be considered a new enrolment, and
              all rules, terms and conditions of a fresh enrolment will apply to
              it. The program will commence from the beginning, and all earlier
              work done or data input by the user in the program under the
              earlier (exposed) identity will be lost forever, which the Company
              is not liable to restore or waive off for the purpose of
              certificate issuance or considering the program as ‘completed’ by
              the user.
            </p>
          </div>
          <p className="topic-heading1">5. Non-transferability</p>
          <div className="subtopic-content1">
            <p>
              User will not allow access or usage of the program in any way to
              another person using his/her assigned online identity, or transfer
              his/her enrolment and/or certificate to another person, whether
              free of charge or for monetary consideration of any kind, before,
              during, or after the program duration, temporarily or permanently.
              If the Company learns about such violations, enrolment(s) of the
              user and any other users concerned shall be cancelled / terminated
              immediately. No fee refund requests will be entertained in the
              matter.
            </p>
          </div>
          <div className="subtopic-content2">
            <p>
              The Company will have the right to refuse or deny the persons
              concerned to avail of any services or programs offered by the
              Company for as long the Company wishes to.
            </p>
          </div>
          <p className="topic-heading1">6. Cancellation & termination</p>
          <div className="subtopic-content1">
            <p>
              The Company in its sole discretion has the right to refuse or
              cancel or terminate registration or enrollment of any user at any
              time without providing an explanation, and refuse any or all
              current or future access to their learning programs or services.
              Such cancellation or termination will result in the deactivation
              and/or deletion of the user’s account, and/or denial of its access
              to the user, and/or forfeiture and relinquishment of all content
              in the user’s account, and the user will have no rights of
              ownership whatsoever over the contents of the account.
            </p>
          </div>
          <p className="topic-heading1">7. Program access and usage</p>
          <div className="subtopic-content1">
            <p>
              By way of enrolment the user will have a right to access and use,
              for the intended learning purpose under the program, all features
              and support that are meant for / available in the learning space
              that would be visible to the user after logging into his/her
              learning space, and to the extent of fee paid if there are any
              such linkages between fee paid to the features accessible, from
              the commencement date which will be intimated to the user in
              his/her email ID.
            </p>
          </div>
          <div className="subtopic-content2">
            <p>
              The user or user’s representative will have no right to demand or
              persuade the Company to provide access to any additional features
              or support, technical or non-technical, outside the purview of the
              program that the user is enrolled for and paid for. This right of
              access and usage does not amount to any ownership rights,
              partially or wholly, on the part of the user.
            </p>
          </div>
          <p className="topic-heading1">8. Ownership</p>
          <div className="subtopic-content1">
            <p>
              All rights and titles including copyrights and other intellectual
              property rights in the program in print and machine readable form
              belong to the Company. No one else including the user has any
              ownership on any part of the program or copies thereof. All the
              material and work submitted by the user, and data arising out of
              user’s performance during the program, is the sole property of the
              Company, and the user will not have any ownership rights on it in
              part of whole.
            </p>
          </div>
          <div className="subtopic-content2">
            <p>
              The user or any other person is strictly not permitted to download
              or store or copy or reproduce or transmit or distribute or display
              or share with others or print or publish or reuse or modify or
              adapt or translate or create derivative works from the contents
              and visual layouts contained in the program in all forms like
              lessons, assignments, tasks, reports, learning features,
              technology features and all. The user or any other person is not
              permitted to remove or disfigure the website or obscure the
              copyright, trademark or other notices contained in the program
            </p>
          </div>
          <p className="topic-heading1">9. User Privacy</p>
          <div className="subtopic-content1">
            <p>
              The data or information related to the user’s identity and/or
              his/her performance in the program, which resides with the
              Company, will be used only for academic and research purposes, for
              purposes of database, performance benchmarking, and such, but will
              not be sold to a third party for monetary consideration
              whatsoever. However, under extraordinary circumstances, the
              Company will be obliged to share the user’s identity with legal
              and/or regulatory authorities if stipulated by them.
            </p>
          </div>
          <p className="topic-heading1">10. User obligation</p>
          <div className="subtopic-content1">
            <p>
              User of any program agrees that all data/information provided to
              the Company during Registration and subsequently in any form, is
              accurate and true to his/her knowledge, and that he/she
              understands that if the Company learns about the user providing
              any incorrect/false data/information intentionally or
              accidentally, his/her registration and/or enrolment will be
              cancelled/terminated immediately. The Company shall not be held
              responsible for any inconvenience/loss resulting from incorrect
              entry of details by the user or his/her representative.
            </p>
          </div>
          <div className="subtopic-content2">
            <p>
              The user is entirely responsible for all activities that occur
              under his/her account, and is obligated to watch over and protect
              against unauthorized or unlawful use of or access to the program,
              and notify the Company immediately about any unauthorized use of
              his/her account or any breach of security.
            </p>
          </div>
          <div className="subtopic-content2">
            <p>
              The Company has created a technology to provide a virtual,
              interactive learning space to all its users across all programs.
              While the user is logged in to his/her learning space, he/she will
              become aware of other users who are logged into their respective
              learning spaces (online). The user is expected to maintain decent
              code of conduct with other online users by not causing disturbance
              or disruptions to their learning process or work, and obliged not
              to share/disclose any data/information pertaining to another
              user’s account to other users or anyone else. If such violation is
              reported against the user, his/her enrolment will be cancelled /
              terminated by the Company.
            </p>
          </div>
          <p className="topic-heading1">11. Certification:</p>
          <div className="subtopic-content1">
            <p>
              Certificate(s) will issued by the Company in the name of the user
              at its sole discretion, based on specific conditions being met by
              the user, like completion of all lessons and work assigned,
              completion within stipulated time, meeting performance standards,
              user’s acceptable conduct and such, as may be fixed by the Company
              before completion of the program’s stipulated maximum duration.
              The certificate may be issued in electronic or paper form as may
              be decided by the Company.
            </p>
          </div>
          <p className="topic-heading1">12. Limited warranty</p>
          <div className="subtopic-content1">
            <p>
              The Company provides to the user all relevant and important
              information about technical requirements for smooth running of the
              program at instances of selling and commencement of the program.
              The Company deduces that if the user has enrolled for the program,
              he/she has done so after reading, understanding, and accepting the
              requirements, and the user or anybody else cannot claim ignorance
              about such information afterwards.
            </p>
          </div>
          <div className="subtopic-content2">
            <p>
              The Company does not make any promise about commencement date of a
              program for any user. The Company has the sole discretion to
              decide on the commencement date for a user, which will be after
              considering several factors, and the user will be intimated about
              his/her particular commencement date in his/her email ID and also
              displayed to the user when he/she logs into his/her learning
              space. The Company shall try in all good faith to fix the
              commencement date as close as possible to the enrolment date, but
              cannot be forced by the user or his/her representatives to
              commence the program on a certain date.
            </p>
          </div>
          <div className="subtopic-content2">
            <p>
              The Company will make all attempts to the best of their abilities
              to maintain and keep in functional condition, the technical and
              non-technical aspects that contribute to smooth and uninterrupted
              access to and usage of the program by its users. Although the
              Company intends to provide the user 24×7 access to and usage of
              the program via the internet/web, except for regularly scheduled
              downtime periods, the Company reserves the right to change its
              availability policy from time to time, and does not guarantee that
              the program will be uninterrupted at all times.
            </p>
          </div>
          <div className="subtopic-content2">
            <p>
              Access to and usage of the program as received remotely by the
              user is a factor of technical aspects/features like processor
              speed, internet speed, headphone or speaker quality etc. at the
              user’s end, and hence, to that extent, lies outside the purview of
              the Company’s liability/responsibility.
            </p>
          </div>
          <p className="topic-heading1">13. Miscellaneous</p>
          <div className="subtopic-content1">
            <p>
              The Company has entered into an Agreement of use with a reputed
              and secured online payment gateway – E-billing Solutions Pvt Ltd
              (EBS). The user or his/her representatives cannot raise a dispute
              with the Company that contradicts or challenges any of the clauses
              of the Agreement with EBS.
            </p>
          </div>
          <div className="subtopic-content2">
            <p>
              User or his/her representatives will indemnify and hold harmless
              the Company and its licensors or suppliers or business partners
              against any and all judgments, settlements, penalties, costs and
              expenses (including attorneys’ fee) paid or incurred in connection
              with claims due to, resulting from or arising in connection with
              the user’s actions or negligence, including but not limited to
              those attributable to any breach of this agreement or any
              infringement, misappropriation or violation of any copyrights,
              intellectual property rights or other proprietary rights of any
              third party.
            </p>
          </div>
          <div className="subtopic-content2">
            <p>
              The user or any of his/her representatives will not enter into any
              agreements apart from this one, with any of the Company’s
              suppliers, business partners, and vendors, unless initiated in
              writing by the Company.
            </p>
          </div>
          <div className="subtopic-content2">
            <p>
              The Company may change from time to time the above Terms and
              Conditions including any the additional terms and conditions. All
              current users of the Company’s programs will be notified in their
              learning space of any such changes. Continued use by the user of
              the programs following the change notification will constitute
              acceptance of the change by the user.
            </p>
          </div>
          <div className="subtopic-content2">
            <p>
              The user or his/her representatives agree that this Agreement
              shall be governed by the laws of the State of Tamil Nadu, India,
              and any dispute arising under this Agreement shall be brought
              solely and exclusively in a court of competent jurisdiction
              located in the state of Tamil Nadu, India, and agree to submit to
              personal jurisdiction in the State of Tamil Nadu for that purpose.
            </p>
          </div>
          <div className="subtopic-content2">
            <p>
              If for any reason a court of competent jurisdiction finds any
              provision of this Agreement, or portion thereof, to be
              unenforceable, that provision of the Agreement shall be enforced
              to the maximum extent permissible so as to effect the intent of
              the Company and the user, and the remainder of this Agreement
              shall continue in full force and effect.
            </p>
          </div>
          <div className="subtopic-content2">
            <p>
              This agreement supersedes and replaces any previous or present
              written or oral agreements and communications relating to the
              program.
            </p>
          </div>
        </div>
        <div className="termsBackButton-Container">
          <Button
            variant="outlined"
            style={{
              border: "1px solid #794DF5",
              color: "#794DF5",
              textTransform: "none",
              marginTop: "20px",
              width: "110px",
              height: "43px",
              fontSize: "18px",
              fontWeight: 600,
              fontFamily: "Poppins",
            }}
            onClick={() => history.goBack()}
          >
            Back
          </Button>
        </div>
      </div>
    </>
  );
}

export default studentsTermsAndConditions;
