import { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import cn from "classnames";
import { Button, Modal,
  ModalHeader,
  ModalFooter,
  ModalBody, } from "reactstrap";
import { ArrowBack } from "@material-ui/icons";
import { ToastContainer } from "react-toastify";
import { Tabs } from "../../../components/tabs/tabs";
import { TabsPractice } from "../../../components/tabs/tabsPractice";
import Dashboard from "../components/dashboard/index";
import ReportTable from "../components/report-table/index";
import ReportTableProctoring from "../components/proctoringTable/index";
import api from "../../../api/baseConfig";
import Apihelper from "../../../api/apiHelper";
import styles from "./index.module.scss";
import equal from "fast-deep-equal";
import RemoveRedEyeOutlinedIcon from '@material-ui/icons/RemoveRedEyeOutlined';
import { IconButton } from "@material-ui/core";
import moment from "moment";
import Divider from '@material-ui/core/Divider';

function AdminAssessmentReport(props) {
  const labId = props?.location?.state?.labId;
  const [reportDashboardData, setReportDashboardData] = useState({});
  const [departmentLength, setDepartmentLength] = useState(0)
  const [reportDepartmentPerfData, setReportDepartmentPerfData] = useState([]);
  const [reportStudentPerfData, setReportStudentPerfData] = useState([]);
  const [reportSectionPerfData, setReportSectionPerfData] = useState({});
  const [deptPerfDataLoading, setDeptPerfDataLoadingStatus] = useState([]);
  const [stuPerfDataLoading, setStuPerfDataLoadingStatus] = useState([]);
  const [secPerfDataLoading, setSecPerfDataLoadingStatus] = useState([]);
  const [activeTab, setActiveTab] = useState("department-performance");
  const [activeSection, setActiveSection] = useState("");
  const [activeTopic, setActiveTopic] = useState("");
  const [sections, setSections] = useState([]);
  const [topics, setTopics] = useState([]);
  const [topicsVal, setTopicsVal] = useState([]);
  const [loadingCsvExport, setCsvExportLoading] = useState(false);
  const [loadingHtmlExport, setHtmlExportLoading] = useState(false);
  const [loadingPdfExport, setPdfExportLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [modal2, setModal2] = useState(false);
  const [proctoringUserId, setProctoringUserId] = useState();
  const [proctoringLog, setProctoringLog] = useState({})
  const [proctoringList, setProctoringList] = useState({})
  const [proctoringImage, setProctoringImage] = useState(null);

  const assessmentId = props?.location?.state?.assessmentId;
  const customerId = props?.location?.state?.customerId;
  const activeTabb = props?.location?.state?.activeTab;
  const overAllReport = props?.location?.state?.overAllReport;
  const activePage =
    overAllReport === true
      ? props?.location?.state?.activePage
      : props?.location?.state?.activePage?.allList;
  const itemsPerPage = props?.location?.state?.itemsPerPage;

  const toggle = (userId) => {
    setProctoringUserId(userId)
    setProctoringImage(null)
    setModal(!modal);
  };

  const imgUrl = (uploadUrl) => {
    setProctoringImage(uploadUrl)
  }

  async function exportPDF() {
    setPdfExportLoading(true);

    await Apihelper.axiosCall(
      labId
        ? `${api.baseURL}adminReport/downloadAssessmentReport/${assessmentId}?labId=${labId}&reportType=all`
        : `${api.baseURL}adminReport/downloadAssessmentReport/${assessmentId}?&reportType=all`,
      "get"
    ).then((data) => {
      if (data?.resourceURL) {
        window.location = data?.resourceURL;
      }

      setPdfExportLoading(false);
    });
  }

  async function exportHTML() {
    setHtmlExportLoading(true);
    await Apihelper.axiosCall(
      labId
        ? `${api.baseURL}adminReport/downloadAssessmentReport/${assessmentId}?labId=${labId}&resourceType=html&reportType=all`
        : `${api.baseURL}adminReport/downloadAssessmentReport/${assessmentId}?resourceType=html&reportType=all`,
      "get"
    ).then((data) => {
      if (data?.resourceURL) {
        window.location = data?.resourceURL;
      }
      setHtmlExportLoading(false);
    });
  }

  async function exportCSV() {
    setCsvExportLoading(true);
    await Apihelper.axiosCall(
      labId
        ? `${api.baseURL}adminReport/downloadAssessmentReport/${assessmentId}?labId=${labId}&resourceType=csv&reportType=all`
        : `${api.baseURL}adminReport/downloadAssessmentReport/${assessmentId}?resourceType=csv&reportType=all`,
      "get"
    ).then((data) => {
      if (data?.resourceURL) {
        window.location = data?.resourceURL;
      }

      setCsvExportLoading(false);
    });
  }

  async function exportStudentPerformancePdf() {
    setPdfExportLoading(true);
    await Apihelper.axiosCall(
      labId
        ? `${api.baseURL}adminReport/downloadAssessmentReport/${assessmentId}?labId=${labId}&reportType=student`
        : `${api.baseURL}adminReport/downloadAssessmentReport/${assessmentId}?reportType=student`,
      "get"
    ).then((data) => {
      if (data?.resourceURL) {
        window.location = data?.resourceURL;
      }
      setPdfExportLoading(false);
    });
  }

  async function exportDepartmentPerformancePdf() {
    setPdfExportLoading(true);
    await Apihelper.axiosCall(
      labId
        ? `${api.baseURL}adminReport/downloadAssessmentReport/${assessmentId}?labId=${labId}&reportType=department`
        : `${api.baseURL}adminReport/downloadAssessmentReport/${assessmentId}?reportType=department`,
      "get"
    ).then((data) => {
      if (data?.resourceURL) {
        window.location = data?.resourceURL;
      }
      setPdfExportLoading(false);
    });
  }

  async function exportSectionPerformancePdf() {
    setPdfExportLoading(true);
    await Apihelper.axiosCall(
      labId
        ? `${api.baseURL}adminReport/downloadAssessmentReport/${assessmentId}?labId=${labId}&reportType=section`
        : `${api.baseURL}adminReport/downloadAssessmentReport/${assessmentId}?reportType=section`,
      "get"
    ).then((data) => {
      if (data?.resourceURL) {
        window.location = data?.resourceURL;
      }
      setPdfExportLoading(false);
    });
  }

  async function exportDeptStudPerformancePdf() {
    setPdfExportLoading(true);
    await Apihelper.axiosCall(
      labId
        ? `${api.baseURL}adminReport/downloadAssessmentReport/${assessmentId}?labId=${labId}&reportType=student,department`
        : `${api.baseURL}adminReport/downloadAssessmentReport/${assessmentId}?reportType=student,department`,
      "get"
    ).then((data) => {
      if (data?.resourceURL) {
        window.location = data?.resourceURL;
      }
      setPdfExportLoading(false);
    });
  }

  async function exportDeptSecPerformancePdf() {
    setPdfExportLoading(true);
    await Apihelper.axiosCall(
      labId
        ? `${api.baseURL}adminReport/downloadAssessmentReport/${assessmentId}?labId=${labId}&reportType=section,department`
        : `${api.baseURL}adminReport/downloadAssessmentReport/${assessmentId}?reportType=section,department`,
      "get"
    ).then((data) => {
      if (data?.resourceURL) {
        window.location = data?.resourceURL;
      }
      setPdfExportLoading(false);
    });
  }

  async function exportStudSecPerformancePdf() {
    setPdfExportLoading(true);
    await Apihelper.axiosCall(
      labId
        ? `${api.baseURL}adminReport/downloadAssessmentReport/${assessmentId}?labId=${labId}&reportType=student,section`
        : `${api.baseURL}adminReport/downloadAssessmentReport/${assessmentId}?reportType=student,section`,
      "get"
    ).then((data) => {
      if (data?.resourceURL) {
        window.location = data?.resourceURL;
      }
      setPdfExportLoading(false);
    });
  }

  async function exportDeptSecStudPerformancePdf() {
    setPdfExportLoading(true);
    await Apihelper.axiosCall(
      labId
        ? `${api.baseURL}adminReport/downloadAssessmentReport/${assessmentId}?labId=${labId}&reportType=department,student,section`
        : `${api.baseURL}adminReport/downloadAssessmentReport/${assessmentId}?reportType=department,student,section`,
      "get"
    ).then((data) => {
      if (data?.resourceURL) {
        window.location = data?.resourceURL;
      }
      setPdfExportLoading(false);
    });
  }

  async function exportDepartmentPerformanceHtml() {
    setHtmlExportLoading(true);
    await Apihelper.axiosCall(
      labId
        ? `${api.baseURL}adminReport/downloadAssessmentReport/${assessmentId}?labId=${labId}&resourceType=html&reportType=department`
        : `${api.baseURL}adminReport/downloadAssessmentReport/${assessmentId}?resourceType=html&reportType=department`,
      "get"
    ).then((data) => {
      if (data?.resourceURL) {
        window.location = data?.resourceURL;
      }
      setHtmlExportLoading(false);
    });
  }

  async function exportStudentPerformanceHtml() {
    setHtmlExportLoading(true);
    await Apihelper.axiosCall(
      labId
        ? `${api.baseURL}adminReport/downloadAssessmentReport/${assessmentId}?labId=${labId}&resourceType=html&reportType=student`
        : `${api.baseURL}adminReport/downloadAssessmentReport/${assessmentId}?resourceType=html&reportType=student`,
      "get"
    ).then((data) => {
      if (data?.resourceURL) {
        window.location = data?.resourceURL;
      }
      setHtmlExportLoading(false);
    });
  }

  async function exportSectionPerformanceHtml() {
    setHtmlExportLoading(true);
    await Apihelper.axiosCall(
      labId
        ? `${api.baseURL}adminReport/downloadAssessmentReport/${assessmentId}?labId=${labId}&resourceType=html&reportType=section`
        : `${api.baseURL}adminReport/downloadAssessmentReport/${assessmentId}?resourceType=html&reportType=section`,
      "get"
    ).then((data) => {
      if (data?.resourceURL) {
        window.location = data?.resourceURL;
      }
      setHtmlExportLoading(false);
    });
  }

  async function exportDeptStudPerformanceHtml() {
    setHtmlExportLoading(true);
    await Apihelper.axiosCall(
      labId
        ? `${api.baseURL}adminReport/downloadAssessmentReport/${assessmentId}?labId=${labId}&resourceType=html&reportType=student,department`
        : `${api.baseURL}adminReport/downloadAssessmentReport/${assessmentId}?resourceType=html&reportType=student,department`,
      "get"
    ).then((data) => {
      if (data?.resourceURL) {
        window.location = data?.resourceURL;
      }
      setHtmlExportLoading(false);
    });
  }

  async function exportDeptSecPerformanceHtml() {
    setHtmlExportLoading(true);
    await Apihelper.axiosCall(
      labId
        ? `${api.baseURL}adminReport/downloadAssessmentReport/${assessmentId}?labId=${labId}&resourceType=html&reportType=section,department`
        : `${api.baseURL}adminReport/downloadAssessmentReport/${assessmentId}?resourceType=html&reportType=section,department`,
      "get"
    ).then((data) => {
      if (data?.resourceURL) {
        window.location = data?.resourceURL;
      }
      setHtmlExportLoading(false);
    });
  }

  async function exportStudSecPerformanceHtml() {
    setHtmlExportLoading(true);
    await Apihelper.axiosCall(
      labId
        ? `${api.baseURL}adminReport/downloadAssessmentReport/${assessmentId}?labId=${labId}&resourceType=html&reportType=student,section`
        : `${api.baseURL}adminReport/downloadAssessmentReport/${assessmentId}?resourceType=html&reportType=student,section`,
      "get"
    ).then((data) => {
      if (data?.resourceURL) {
        window.location = data?.resourceURL;
      }
      setHtmlExportLoading(false);
    });
  }

  async function exportDeptSecStudPerformanceHtml() {
    setHtmlExportLoading(true);
    await Apihelper.axiosCall(
      labId
        ? `${api.baseURL}adminReport/downloadAssessmentReport/${assessmentId}?labId=${labId}&resourceType=html&reportType=department,student,section`
        : `${api.baseURL}adminReport/downloadAssessmentReport/${assessmentId}?resourceType=html&reportType=department,student,section`,
      "get"
    ).then((data) => {
      if (data?.resourceURL) {
        window.location = data?.resourceURL;
      }
      setHtmlExportLoading(false);
    });
  }

  async function exportDepartmentPerformanceCsv() {
    setCsvExportLoading(true);
    await Apihelper.axiosCall(
      labId
        ? `${api.baseURL}adminReport/downloadAssessmentReport/${assessmentId}?labId=${labId}&resourceType=csv&reportType=department`
        : `${api.baseURL}adminReport/downloadAssessmentReport/${assessmentId}?resourceType=csv&reportType=department`,
      "get"
    ).then((data) => {
      if (data?.resourceURL) {
        window.location = data?.resourceURL;
      }
      setCsvExportLoading(false);
    });
  }

  async function exportStudentPerformanceCsv() {
    setCsvExportLoading(true);
    await Apihelper.axiosCall(
      labId
        ? `${api.baseURL}adminReport/downloadAssessmentReport/${assessmentId}?labId=${labId}&resourceType=csv&reportType=student`
        : `${api.baseURL}adminReport/downloadAssessmentReport/${assessmentId}?resourceType=csv&reportType=student`,
      "get"
    ).then((data) => {
      if (data?.resourceURL) {
        window.location = data?.resourceURL;
      }
      setCsvExportLoading(false);
    });
  }

  async function exportSectionPerformanceCsv() {
    setCsvExportLoading(true);
    await Apihelper.axiosCall(
      labId
        ? `${api.baseURL}adminReport/downloadAssessmentReport/${assessmentId}?labId=${labId}&resourceType=csv&reportType=section`
        : `${api.baseURL}adminReport/downloadAssessmentReport/${assessmentId}?resourceType=csv&reportType=section`,
      "get"
    ).then((data) => {
      if (data?.resourceURL) {
        window.location = data?.resourceURL;
      }
      setCsvExportLoading(false);
    });
  }

  async function exportProctoringCsv() {
    setCsvExportLoading(true);
    await Apihelper.axiosCall(
      labId
        ? `${api.baseURL}adminReport/downloadAssessmentReport/${assessmentId}?labId=${labId}&resourceType=csv&reportType=proctoring`
        : `${api.baseURL}adminReport/downloadAssessmentReport/${assessmentId}?resourceType=csv&reportType=proctoring`,
      "get"
    ).then((data) => {
      if (data?.resourceURL) {
        window.location = data?.resourceURL;
      }
      setCsvExportLoading(false);
    });
  }
  async function exportProctoringHtml() {
    setCsvExportLoading(true);
    await Apihelper.axiosCall(
      labId
        ? `${api.baseURL}adminReport/downloadAssessmentReport/${assessmentId}?labId=${labId}&resourceType=html&reportType=proctoring`
        : `${api.baseURL}adminReport/downloadAssessmentReport/${assessmentId}?resourceType=html&reportType=proctoring`,
      "get"
    ).then((data) => {
      if (data?.resourceURL) {
        window.location = data?.resourceURL;
      }
      setCsvExportLoading(false);
    });
  }
  async function exportProctoringPdf() {
    setCsvExportLoading(true);
    await Apihelper.axiosCall(
      labId
        ? `${api.baseURL}adminReport/downloadAssessmentReport/${assessmentId}?labId=${labId}&resourceType=pdf&reportType=proctoring`
        : `${api.baseURL}adminReport/downloadAssessmentReport/${assessmentId}?resourceType=pdf&reportType=proctoring`,
      "get"
    ).then((data) => {
      if (data?.resourceURL) {
        window.location = data?.resourceURL;
      }
      setCsvExportLoading(false);
    });
  }

  // pdf start here 

  async function exportProctoringDeptPdf() {
    setCsvExportLoading(true);
    await Apihelper.axiosCall(
      labId
        ? `${api.baseURL}adminReport/downloadAssessmentReport/${assessmentId}?labId=${labId}&resourceType=pdf&reportType=proctoring,department`
        : `${api.baseURL}adminReport/downloadAssessmentReport/${assessmentId}?resourceType=pdf&reportType=proctoring,department`,
      "get"
    ).then((data) => {
      if (data?.resourceURL) {
        window.location = data?.resourceURL;
      }
      setCsvExportLoading(false);
    });
  }
  async function exportProctoringSectionPdf() {
    setCsvExportLoading(true);
    await Apihelper.axiosCall(
      labId
        ? `${api.baseURL}adminReport/downloadAssessmentReport/${assessmentId}?labId=${labId}&resourceType=pdf&reportType=proctoring,section`
        : `${api.baseURL}adminReport/downloadAssessmentReport/${assessmentId}?resourceType=pdf&reportType=proctoring,section`,
      "get"
    ).then((data) => {
      if (data?.resourceURL) {
        window.location = data?.resourceURL;
      }
      setCsvExportLoading(false);
    });
  }
  async function exportProctoringStudentPdf() {
    setCsvExportLoading(true);
    await Apihelper.axiosCall(
      labId
        ? `${api.baseURL}adminReport/downloadAssessmentReport/${assessmentId}?labId=${labId}&resourceType=pdf&reportType=proctoring,student`
        : `${api.baseURL}adminReport/downloadAssessmentReport/${assessmentId}?resourceType=pdf&reportType=proctoring,student`,
      "get"
    ).then((data) => {
      if (data?.resourceURL) {
        window.location = data?.resourceURL;
      }
      setCsvExportLoading(false);
    });
  }
  async function exportProctoringStuSecPdf() {
    setCsvExportLoading(true);
    await Apihelper.axiosCall(
      labId
        ? `${api.baseURL}adminReport/downloadAssessmentReport/${assessmentId}?labId=${labId}&resourceType=pdf&reportType=proctoring,student,section`
        : `${api.baseURL}adminReport/downloadAssessmentReport/${assessmentId}?resourceType=pdf&reportType=proctoring,student,section`,
      "get"
    ).then((data) => {
      if (data?.resourceURL) {
        window.location = data?.resourceURL;
      }
      setCsvExportLoading(false);
    });
  }

  async function exportProctoringStudeptPdf() {
    setCsvExportLoading(true);
    await Apihelper.axiosCall(
      labId
        ? `${api.baseURL}adminReport/downloadAssessmentReport/${assessmentId}?labId=${labId}&resourceType=pdf&reportType=proctoring,student,department`
        : `${api.baseURL}adminReport/downloadAssessmentReport/${assessmentId}?resourceType=pdf&reportType=proctoring,student,department`,
      "get"
    ).then((data) => {
      if (data?.resourceURL) {
        window.location = data?.resourceURL;
      }
      setCsvExportLoading(false);
    });
  }
  async function exportProctoringSecdeptPdf() {
    setCsvExportLoading(true);
    await Apihelper.axiosCall(
      labId
        ? `${api.baseURL}adminReport/downloadAssessmentReport/${assessmentId}?labId=${labId}&resourceType=pdf&reportType=proctoring,section,department`
        : `${api.baseURL}adminReport/downloadAssessmentReport/${assessmentId}?resourceType=pdf&reportType=proctoring,section,department`,
      "get"
    ).then((data) => {
      if (data?.resourceURL) {
        window.location = data?.resourceURL;
      }
      setCsvExportLoading(false);
    });
  }

  // pdf end here

  // html start here 

   async function exportProctoringDeptHtml() {
    setCsvExportLoading(true);
    await Apihelper.axiosCall(
      labId
        ? `${api.baseURL}adminReport/downloadAssessmentReport/${assessmentId}?labId=${labId}&resourceType=html&reportType=proctoring,department`
        : `${api.baseURL}adminReport/downloadAssessmentReport/${assessmentId}?resourceType=html&reportType=proctoring,department`,
      "get"
    ).then((data) => {
      if (data?.resourceURL) {
        window.location = data?.resourceURL;
      }
      setCsvExportLoading(false);
    });
  }
  async function exportProctoringSectionHtml() {
    setCsvExportLoading(true);
    await Apihelper.axiosCall(
      labId
        ? `${api.baseURL}adminReport/downloadAssessmentReport/${assessmentId}?labId=${labId}&resourceType=html&reportType=proctoring,section`
        : `${api.baseURL}adminReport/downloadAssessmentReport/${assessmentId}?resourceType=html&reportType=proctoring,section`,
      "get"
    ).then((data) => {
      if (data?.resourceURL) {
        window.location = data?.resourceURL;
      }
      setCsvExportLoading(false);
    });
  }
  async function exportProctoringStudentHtml() {
    setCsvExportLoading(true);
    await Apihelper.axiosCall(
      labId
        ? `${api.baseURL}adminReport/downloadAssessmentReport/${assessmentId}?labId=${labId}&resourceType=html&reportType=proctoring,student`
        : `${api.baseURL}adminReport/downloadAssessmentReport/${assessmentId}?resourceType=html&reportType=proctoring,student`,
      "get"
    ).then((data) => {
      if (data?.resourceURL) {
        window.location = data?.resourceURL;
      }
      setCsvExportLoading(false);
    });
  }
  async function exportProctoringStuSecHtml() {
    setCsvExportLoading(true);
    await Apihelper.axiosCall(
      labId
        ? `${api.baseURL}adminReport/downloadAssessmentReport/${assessmentId}?labId=${labId}&resourceType=html&reportType=proctoring,student,section`
        : `${api.baseURL}adminReport/downloadAssessmentReport/${assessmentId}?resourceType=html&reportType=proctoring,student,section`,
      "get"
    ).then((data) => {
      if (data?.resourceURL) {
        window.location = data?.resourceURL;
      }
      setCsvExportLoading(false);
    });
  }

  async function exportProctoringStudeptHtml() {
    setCsvExportLoading(true);
    await Apihelper.axiosCall(
      labId
        ? `${api.baseURL}adminReport/downloadAssessmentReport/${assessmentId}?labId=${labId}&resourceType=html&reportType=proctoring,student,department`
        : `${api.baseURL}adminReport/downloadAssessmentReport/${assessmentId}?resourceType=html&reportType=proctoring,student,department`,
      "get"
    ).then((data) => {
      if (data?.resourceURL) {
        window.location = data?.resourceURL;
      }
      setCsvExportLoading(false);
    });
  }
  async function exportProctoringSecdeptHtml() {
    setCsvExportLoading(true);
    await Apihelper.axiosCall(
      labId
        ? `${api.baseURL}adminReport/downloadAssessmentReport/${assessmentId}?labId=${labId}&resourceType=html&reportType=proctoring,section,department`
        : `${api.baseURL}adminReport/downloadAssessmentReport/${assessmentId}?resourceType=html&reportType=proctoring,section,department`,
      "get"
    ).then((data) => {
      if (data?.resourceURL) {
        window.location = data?.resourceURL;
      }
      setCsvExportLoading(false);
    });
  }

  // html end here

   // Csv start here 

   async function exportProctoringDeptCsv() {
    setCsvExportLoading(true);
    await Apihelper.axiosCall(
      labId
        ? `${api.baseURL}adminReport/downloadAssessmentReport/${assessmentId}?labId=${labId}&resourceType=csv&reportType=proctoring,department`
        : `${api.baseURL}adminReport/downloadAssessmentReport/${assessmentId}?resourceType=csv&reportType=proctoring,department`,
      "get"
    ).then((data) => {
      if (data?.resourceURL) {
        window.location = data?.resourceURL;
      }
      setCsvExportLoading(false);
    });
  }
  async function exportProctoringSectionCsv() {
    setCsvExportLoading(true);
    await Apihelper.axiosCall(
      labId
        ? `${api.baseURL}adminReport/downloadAssessmentReport/${assessmentId}?labId=${labId}&resourceType=csv&reportType=proctoring,section`
        : `${api.baseURL}adminReport/downloadAssessmentReport/${assessmentId}?resourceType=csv&reportType=proctoring,section`,
      "get"
    ).then((data) => {
      if (data?.resourceURL) {
        window.location = data?.resourceURL;
      }
      setCsvExportLoading(false);
    });
  }
  async function exportProctoringStudentCsv() {
    setCsvExportLoading(true);
    await Apihelper.axiosCall(
      labId
        ? `${api.baseURL}adminReport/downloadAssessmentReport/${assessmentId}?labId=${labId}&resourceType=csv&reportType=proctoring,student`
        : `${api.baseURL}adminReport/downloadAssessmentReport/${assessmentId}?resourceType=csv&reportType=proctoring,student`,
      "get"
    ).then((data) => {
      if (data?.resourceURL) {
        window.location = data?.resourceURL;
      }
      setCsvExportLoading(false);
    });
  }
  async function exportProctoringStuSecCsv() {
    setCsvExportLoading(true);
    await Apihelper.axiosCall(
      labId
        ? `${api.baseURL}adminReport/downloadAssessmentReport/${assessmentId}?labId=${labId}&resourceType=csv&reportType=proctoring,student,section`
        : `${api.baseURL}adminReport/downloadAssessmentReport/${assessmentId}?resourceType=csv&reportType=proctoring,student,section`,
      "get"
    ).then((data) => {
      if (data?.resourceURL) {
        window.location = data?.resourceURL;
      }
      setCsvExportLoading(false);
    });
  }

  async function exportProctoringStudeptCsv() {
    setCsvExportLoading(true);
    await Apihelper.axiosCall(
      labId
        ? `${api.baseURL}adminReport/downloadAssessmentReport/${assessmentId}?labId=${labId}&resourceType=csv&reportType=proctoring,student,department`
        : `${api.baseURL}adminReport/downloadAssessmentReport/${assessmentId}?resourceType=csv&reportType=proctoring,student,department`,
      "get"
    ).then((data) => {
      if (data?.resourceURL) {
        window.location = data?.resourceURL;
      }
      setCsvExportLoading(false);
    });
  }
  async function exportProctoringSecdeptCsv() {
    setCsvExportLoading(true);
    await Apihelper.axiosCall(
      labId
        ? `${api.baseURL}adminReport/downloadAssessmentReport/${assessmentId}?labId=${labId}&resourceType=csv&reportType=proctoring,section,department`
        : `${api.baseURL}adminReport/downloadAssessmentReport/${assessmentId}?resourceType=csv&reportType=proctoring,section,department`,
      "get"
    ).then((data) => {
      if (data?.resourceURL) {
        window.location = data?.resourceURL;
      }
      setCsvExportLoading(false);
    });
  }

  // Csv end here

  async function exportDeptStudPerformanceCsv() {
    setCsvExportLoading(true);
    await Apihelper.axiosCall(
      labId
        ? `${api.baseURL}adminReport/downloadAssessmentReport/${assessmentId}?labId=${labId}&resourceType=csv&reportType=student,department`
        : `${api.baseURL}adminReport/downloadAssessmentReport/${assessmentId}?resourceType=csv&reportType=student,department`,
      "get"
    ).then((data) => {
      if (data?.resourceURL) {
        window.location = data?.resourceURL;
      }
      setCsvExportLoading(false);
    });
  }

  async function exportDeptSecPerformanceCsv() {
    setCsvExportLoading(true);
    await Apihelper.axiosCall(
      labId
        ? `${api.baseURL}adminReport/downloadAssessmentReport/${assessmentId}?labId=${labId}&resourceType=csv&reportType=section,department`
        : `${api.baseURL}adminReport/downloadAssessmentReport/${assessmentId}?resourceType=csv&reportType=section,department`,
      "get"
    ).then((data) => {
      if (data?.resourceURL) {
        window.location = data?.resourceURL;
      }
      setCsvExportLoading(false);
    });
  }

  async function exportDeptMockPerformanceCsv() {
    setCsvExportLoading(true);
    await Apihelper.axiosCall(
      labId
        ? `${api.baseURL}adminReport/downloadAssessmentReport/${assessmentId}?labId=${labId}&resourceType=csv&reportType=mockassessment,department`
        : `${api.baseURL}adminReport/downloadAssessmentReport/${assessmentId}?resourceType=csv&reportType=mockassessment,department`,
      "get"
    ).then((data) => {
      if (data?.resourceURL) {
        window.location = data?.resourceURL;
      }
      setCsvExportLoading(false);
    });
  }
  async function exportStudSecPerformanceCsv() {
    setCsvExportLoading(true);
    await Apihelper.axiosCall(
      labId
        ? `${api.baseURL}adminReport/downloadAssessmentReport/${assessmentId}?labId=${labId}&resourceType=csv&reportType=student,section`
        : `${api.baseURL}adminReport/downloadAssessmentReport/${assessmentId}?resourceType=csv&reportType=student,section`,
      "get"
    ).then((data) => {
      if (data?.resourceURL) {
        window.location = data?.resourceURL;
      }
      setCsvExportLoading(false);
    });
  }

  async function exportDeptSecStudPerformanceCsv() {
    setCsvExportLoading(true);
    await Apihelper.axiosCall(
      labId
        ? `${api.baseURL}adminReport/downloadAssessmentReport/${assessmentId}?labId=${labId}&resourceType=csv&reportType=department,student,section`
        : `${api.baseURL}adminReport/downloadAssessmentReport/${assessmentId}?resourceType=csv&reportType=department,student,section`,
      "get"
    ).then((data) => {
      if (data?.resourceURL) {
        window.location = data?.resourceURL;
      }
      setCsvExportLoading(false);
    });
  }

  async function getReportDasboardData(id) {
    if (id) {
      await Apihelper.axiosCall(
        labId
          ? `${api.baseURL}adminReport/getAdminAssessmentReportDashboard/${id}?labId=${labId}`
          : `${api.baseURL}adminReport/getAdminAssessmentReportDashboard/${id}`,
        "get"
      )
        .then((data) => {
          setReportDashboardData(data);
        })
        .catch(() => {});
    }
  }

 

  const getReportStudentData = async ({
    id,
    page,
    pageLimit,
    totalCount,
    searchkey,
  }) => {
    try {
      if (id) {
        setStuPerfDataLoadingStatus(true);
        const url =
          page !== undefined && page !== null && totalCount !== undefined
            ? (labId !== undefined ?
              `${
                api.baseURL
              }adminReport/getAdminAssessmentReportStudent/${id}?labId=${labId}&pageIndex=${
                page + 1
              }&limit=${pageLimit}&searchkey=${searchkey}`
              : 
              `${
                api.baseURL
              }adminReport/getAdminAssessmentReportStudent/${id}?pageIndex=${
                page + 1
              }&limit=${pageLimit}&searchkey=${searchkey}`)
            : `${api.baseURL}adminReport/getAdminAssessmentReportStudent/${id}?labId=${labId}`;

        const data = await Apihelper.axiosCall(url, "get");
        const dataList = [];

        (data?.studentPerformance || []).forEach(
          ({
            codeQualityIndex,
            completed,
            correct,
            department,
            firstName,
            lastName,
            emailId,
            performance,
            plagerismIndex,
            registrationNumber,
            timeTaken,
            totalMarks,
            totalQuestion,
            unattempted,
            wrong,
          }) => {
            dataList.push({
              completed,
              correct,
              department,
              name: `${firstName} ${lastName}`,
              emailId,
              performance,
              registrationNumber,
              timeTaken: parseFloat(timeTaken / 60)?.toFixed(1),
              totalMarks,
              totalQuestion,
              unAttempted: unattempted,
              wrong,
            });
          }
        );

        // setReportStudentPerfData(dataList);

        return {
          data: dataList,
          page,
          totalCount,
        };
      }
    } catch (error) {
    } finally {
      setStuPerfDataLoadingStatus(false);
    }
  };

  const getReportSectionData = async ({
    id,
    page,
    pageLimit,
    totalCount,
    currentSection,
    currentTopic,
    searchkey,
  }) => {
    try {
      if (id) {
        setSecPerfDataLoadingStatus(true);
        const url =
          (page !== undefined && page !== null && totalCount !== undefined)
            ?
            (labId !== undefined ?
            `${
                api.baseURL
              }adminReport/getAdminAssessmentReportSection/${id}?labId=${labId}&pageIndex=${
                page + 1
              }&limit=${pageLimit}&searchkey=${searchkey}`
              : `${api.baseURL}adminReport/getAdminAssessmentReportSection/${id}?pageIndex=${ page + 1
              }&limit=${pageLimit}&searchkey=${searchkey}`)
            : `${api.baseURL}adminReport/getAdminAssessmentReportSection/${id}?labId=${labId}`;

        const data = await Apihelper.axiosCall(url, "get");

        const tempSections = [];
        const tempPerf = data?.sectionPerformance?.[0]
        const newTopic = []
        const newListTopic = []
        for(let i=0; i < data?.sectionPerformance?.length; i++ ) {
          newTopic.push(data?.sectionPerformance?.[i])
          newListTopic.push(newTopic[i]?.Topic)
        }
        const tempTopics = (newListTopic[activeSection?.no || 0]).map((item, index) => ({
          name: item,
          id: item,
          no: index
        }));
        setTopics(tempTopics)
        const allSections = (data?.sectionPerformance || []).reduce(
          (acc, section, index) => {
            tempSections.push({
              name: section?.sectionName,
              id: section?.sectionName,
              no: index
            });
          },{});
        const topicValue = data?.sectionPerformance?.map((e) => {
            return {[e?.sectionName]: e?.Topic}
        })
        setReportSectionPerfData(...[topicValue])
        const areSectionsEqual = equal(sections, tempSections);
        if (!areSectionsEqual) {
          setSections(tempSections);
        }
        const ActiveSection = tempSections[0];

        if (!activeSection) {
          setActiveSection(ActiveSection);
        }
        const ActiveTopic = tempTopics[0];

        if(!activeTopic){
          setActiveTopic(ActiveTopic);
        }
        const dataList = [];
        (data?.sectionPerformance[activeSection.no]?.adminStudentPerformanceDTO || []).forEach(
          ({
              completed,
              department,
              name,
              firstName,
              lastName,
              registrationNumber,
              sectionDetails,
          }) => {
            dataList.push({
              completed,
              department,
              name: `${firstName} ${lastName}`,
              registrationNumber,
              sectionMarks: sectionDetails[activeTopic.no || 0].sectionMarks,
              attempted: sectionDetails[activeTopic.no || 0].attempted ,
              correct: sectionDetails[activeTopic.no || 0].correct,
              totalQuestion: sectionDetails[activeTopic.no || 0].totalQuestion,
              unAttempted: sectionDetails[activeTopic.no || 0].unattempted,
              wrong: sectionDetails[activeTopic.no || 0].wrong,
            });
          }
        );
        return {
          data: dataList,
          page,
          totalCount,
        };
      }
    } catch (error) {
    } finally {
      setSecPerfDataLoadingStatus(false);
    }
  };

  const getReportDepartmentData = async ({
    id,
    page,
    pageLimit,
    totalCount,
    searchkey,
  }) => {
    try {
      if (id) {
        setDeptPerfDataLoadingStatus(true);
        const url =
          page !== undefined && page !== null && totalCount !== undefined
            ? (labId !== undefined ? `${
                api.baseURL
              }adminReport/getAdminAssessmentReportDepartment/${id}?labId=${labId}&pageIndex=${page + 1}&limit=${pageLimit}&searchkey=${searchkey}`
              : `${
                api.baseURL
              }adminReport/getAdminAssessmentReportDepartment/${id}?pageIndex=${page + 1}&limit=${pageLimit}&searchkey=${searchkey}`)
            : `${api.baseURL}adminReport/getAdminAssessmentReportDepartment/${id}?labId=${labId}`;

        const data = await Apihelper.axiosCall(url, "get");
        const dataList = [];

        (data?.departmentPerformance || []).forEach(
          ({
            attempted,
            avgMarks,
            avgTimeTaken,
            department,
            fail,
            firstClass,
            fourthClass,
            notAttempted,
            pass,
            secondClass,
            thirdClass,
          }) => {
            dataList.push({
              "0_25": fourthClass,
              "26_50": thirdClass,
              "51_75": secondClass,
              "76_100": firstClass,
              attempted,
              avgMarks,
              avgTimeTaken: parseFloat(avgTimeTaken / 60)?.toFixed(1),
              fail,
              notAttempted,
              pass,
              sectionBranch: department,
            });
          }
        );
          setDepartmentLength(data?.departmentPerformanceCount || 0)
        // setReportDepartmentPerfData(dataList);
        return {
          data: dataList,
          page,
          // totalCount: reportDashboardData?.setDepartmentLength,
          totalCount: data?.departmentPerformanceCount,
        };
      }
    } catch (error) {
    } finally {
      setDeptPerfDataLoadingStatus(false);
    }
  };
  
  const getReportProctoringData = async ({
    id,
    page,
    pageLimit,
    totalCount,
    searchkey,
  }) => {
    try {
      if (id) {
        setStuPerfDataLoadingStatus(true);
        const url =
          page !== undefined && page !== null && totalCount !== undefined
            ? (labId !== undefined ?
              `${
                api.baseURL
                
              }adminReport/getAdminAssessmentReportProctoring/${id}?labId=${labId}&pageIndex=${
                page + 1
              }&limit=${pageLimit}&searchkey=${searchkey}`
              : 
              `${
                api.baseURL
              }adminReport/getAdminAssessmentReportProctoring/${id}?pageIndex=${
                page + 1
              }&limit=${pageLimit}&searchkey=${searchkey}`)
            : `${api.baseURL}adminReport/getAdminAssessmentReportProctoring/${id}?labId=${labId}`;

        const data = await Apihelper.axiosCall(url, "get");
        const dataList = [];
        (data?.proctoringLog || []).forEach(
          ({
            annotations,
            assessmentDuration,
            assessmentTimeLimit,
            department,
            emailId,
            firstName,
            id,
            laptop,
            lastName,
            marks,
            mobilePhone,
            outOfFrame,
            proctoringList,
            registrationNumber,
            suspicionLevel,
            timerValue,
          }) => {
            dataList.push({
              annotations,
              assessmentDuration,
              assessmentTimeLimit,
              department,
              emailId,
              name: `${firstName || ''} ${lastName || ''}`,
              id,
              laptop,
              marks,
              mobilePhone,
              outOfFrame,
              proctoringList,
              registrationNumber,
              suspicionLevel,
              timerValue,
            });
          }
        );

        // setReportStudentPerfData(dataList);

        return {
          data: dataList,
          page,
          totalCount,
        };
      }
    } catch (error) {
    } finally {
      setStuPerfDataLoadingStatus(false);
    }
  };
  const getReportProctoringLog = async ({
    id,
    page,
    pageLimit,
    totalCount,
    searchkey,
  }) => {
    try {
      if (id) {
        setStuPerfDataLoadingStatus(true);
        const url =
          page !== undefined && page !== null && totalCount !== undefined
            ? (labId !== undefined ?
              `${
                api.baseURL
                
              }adminReport/getIndividualProctoringReport/${proctoringUserId}/${id}?labId=${labId}&pageIndex=${
                page + 1
              }&limit=${pageLimit}&searchkey=${searchkey}`
              : 
              `${
                api.baseURL
              }adminReport/getIndividualProctoringReport/${proctoringUserId}/${id}?pageIndex=${
                page + 1
              }&limit=${pageLimit}&searchkey=${searchkey}`)
            : `${api.baseURL}adminReport/getIndividualProctoringReport/${proctoringUserId}/${id}?labId=${labId}`;

        const data = await Apihelper.axiosCall(url, "get");
        setProctoringLog(data)
        const dataList = [];
        (data?.proctoringList || []).forEach(
          ({
            duration,
            logName,
            time,
            uploadURL
          }) => {
            dataList.push({
              duration,
              logName,
              time,
              uploadURL
            });
          }
        );
        // setReportStudentPerfData(dataList);
        return {
          data: dataList,
          page,
          totalCount,
        };
      }
    } catch (error) {
    } finally {
      setStuPerfDataLoadingStatus(false);
    }
  };
   

  useEffect(() => {
    getReportDasboardData(props?.location?.state?.assessmentId);
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setReportDepartmentPerfData([]);
    setReportStudentPerfData([]);
    setReportSectionPerfData([]);
    setSections([]);
    setTopics([]);
  }, [activeTab]);

  
  useEffect(() => {
    const topicsTab = Object.values(reportSectionPerfData).map((t) => (
      t?.[activeSection]
    ))
  const newValueOfTopics = topicsTab?.map((t) => (
    t?.map((e, index) => (
      {name:e, id:e, no:index}
    ))
  ))

  newValueOfTopics?.filter((e) => e !== undefined).map((e) => {
    setTopics(e)
  })
}, [activeSection]);

  return (
    <div className={cn("page-content", styles.page)}>
      <Dashboard isAssessment reportDashboardData={reportDashboardData} />
     
        <Modal isOpen={modal} toggle={toggle}  style={{maxWidth: '55%', width: '100%', paddingTop: '50px'}} >
          <ModalHeader>
            <div className={styles.proctoringHead}>{"Proctoring log"}</div>
          </ModalHeader>
            <div className={styles.proctoringHeader}>
              <div>
                <p className={styles.logHeading}>Student name</p>
                <p className={styles.logSubHeading}>{`${proctoringLog?.firstName || ''} ${proctoringLog?.lastName || ''}`}</p>
              </div>
              <div className="pl-3">
                <p className={styles.logHeading}>Assessment duration(min)</p>
                <p className={styles.logSubHeading}>{proctoringLog?.assessmentTimeLimit}</p>
              </div>
              <div className="pl-3">
                <p className={styles.logHeading}>Marks scored</p>
                <p className={styles.logSubHeading}>{proctoringLog?.marks}</p>
              </div>
              <div className="pl-3">
                <p className={styles.logHeading}>Annotations</p>
                <p className={styles.logSubHeading}>{proctoringLog?.annotations}</p>
              </div>
              <div className="pl-3">
                <p className={styles.logHeading}>Suspicion level</p>
                <p className={styles.logSubHeading}>{proctoringLog?.suspicionLevel}</p>
              </div>
            </div>
          <ModalBody>
            <div className="pt-3 pb-3">
              <Divider />
            </div>
          <div className={styles.proctoringBody}>
          <ReportTableProctoring
            data={reportDepartmentPerfData}
            fetchData={ getReportProctoringLog }
            labId={props?.location?.state?.assessmentId}
            totalCount={reportDashboardData?.enrolled || 0}
            columns={[
              {
                align: "left",
                field: "time",
                render: (d) => (
                  <span >
                 
                  {d?.time ? moment(d?.time).format('hh:mm A') : '-'}
                  </span>
                ),
                title: "Time",
              },
              
              {
                align: "left",
                field: "logName",                 
                render: (d) => (
                  <span >{`${d?.logName}` || '-' }</span>
                ),
                title: "Log",
              },
              {
                align: "left",
                field: "durartion",
                render: (d) => (
                  <span>
                   {d?.duration >= 0 ? `${d?.duration}` || '-' : '-'}
                  </span>
                ),
                title: "Duration(sec)",
              },
              {
                 align: "right",
                 render: (d) => (
                <input
                  name="radio"
                  checked={proctoringImage == d?.uploadURL}
                  id={d?.uploadURL}
                  value={d?.uploadURL}
                  style={{ transform: "scale(1.5)" }}
                  type="radio"
                  onChange={() => imgUrl(d?.uploadURL)}
                />
              ),
               },
             
            ]}
            isLoading={deptPerfDataLoading}
          />
          <div>
            <img src={proctoringImage === null ? "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png " : proctoringImage} alt='image'  style={{width:"250px",height:"250px"}}/>
          </div>  
          </div>
          </ModalBody>
        </Modal>
      <Tabs
        activeTab={activeTab}
        contentClassName={styles.tabContent}
        hideBadge
        onChange={setActiveTab}
        tabs={[
          { name: "Department Performance", id: "department-performance" },
          { name: "Student Performance", id: "student-performance" },
          { name: "Section Performance", id: "section-performance" },
          {name: "Proctoring Log", id:"Proctoring-Log"},
          { name : "AI Graded Performance" , id:"AI-Graded-Performance"}
        ]}
      >
        {activeTab === "department-performance" && (
          <ReportTable
            customExport
            loadingCsvExport={loadingCsvExport}
            loadingHtmlExport={loadingHtmlExport}
            loadingPdfExport={loadingPdfExport}
            exportCSV={exportCSV}
            exportPDF={exportPDF}
            exportHTML={exportHTML}
            exportDepartmentPerformancePdf={exportDepartmentPerformancePdf}
            exportStudentPerformancePdf={exportStudentPerformancePdf}
            exportSectionPerformancePdf={exportSectionPerformancePdf}
            exportDeptStudPerformancePdf={exportDeptStudPerformancePdf}
            exportDeptSecPerformancePdf={exportDeptSecPerformancePdf}
            exportStudSecPerformancePdf={exportStudSecPerformancePdf}
            exportDeptSecStudPerformancePdf={exportDeptSecStudPerformancePdf}
            exportDepartmentPerformanceHtml={exportDepartmentPerformanceHtml}
            exportStudentPerformanceHtml={exportStudentPerformanceHtml}
            exportSectionPerformanceHtml={exportSectionPerformanceHtml}
            exportDeptStudPerformanceHtml={exportDeptStudPerformanceHtml}
            exportDeptSecPerformanceHtml={exportDeptSecPerformanceHtml}
            exportStudSecPerformanceHtml={exportStudSecPerformanceHtml}
            exportDeptSecStudPerformanceHtml={exportDeptSecStudPerformanceHtml}
            exportDepartmentPerformanceCsv={exportDepartmentPerformanceCsv}
            exportStudentPerformanceCsv={exportStudentPerformanceCsv}
            exportSectionPerformanceCsv={exportSectionPerformanceCsv}
            exportDeptStudPerformanceCsv={exportDeptStudPerformanceCsv}
            exportDeptSecPerformanceCsv={exportDeptSecPerformanceCsv}
            exportStudSecPerformanceCsv={exportStudSecPerformanceCsv}
            exportDeptSecStudPerformanceCsv={exportDeptSecStudPerformanceCsv}
            exportProctoringPdf={exportProctoringPdf}   
            exportProctoringCsv={exportProctoringCsv}
            exportProctoringHtml={exportProctoringHtml}
            exportProctoringDeptPdf={exportProctoringDeptPdf}
            exportProctoringSectionPdf={exportProctoringSectionPdf}
            exportProctoringStudentPdf={exportProctoringStudentPdf}
            exportProctoringStuSecPdf={exportProctoringStuSecPdf}
            exportProctoringSecdeptPdf={exportProctoringSecdeptPdf}
            exportProctoringStudeptPdf={exportProctoringStudeptPdf}
            exportProctoringDeptHtml={exportProctoringDeptHtml}
            exportProctoringSectionHtml={exportProctoringSectionHtml}
            exportProctoringStudentHtml={exportProctoringStudentHtml}
            exportProctoringStuSecHtml={exportProctoringStuSecHtml}
            exportProctoringSecdeptHtml={exportProctoringSecdeptHtml}
            exportProctoringStudeptHtml={exportProctoringStudeptHtml}
            exportProctoringDeptCsv={exportProctoringDeptCsv}
            exportProctoringSectionCsv={exportProctoringSectionCsv}
            exportProctoringStudentCsv={exportProctoringStudentCsv}
            exportProctoringStuSecCsv={exportProctoringStuSecCsv}
            exportProctoringSecdeptCsv={exportProctoringSecdeptCsv}
            exportProctoringStudeptCsv={exportProctoringStudeptCsv}
            data={reportDepartmentPerfData}
            fetchData={getReportDepartmentData}
            labId={props?.location?.state?.assessmentId}
            totalCount={departmentLength}
            title="Department wise performance"
            columns={[
              {
                align: "left",
                field: "sectionBranch",
                sorting: false,
                title: "Section\xa0/\xa0Branch",
              },
              {
                align: "center",
                field: "attempted",
                title: "Attempted",
              },
              {
                align: "right",
                field: "notAttempted",
                title: "Not\xa0attempted",
              },
              {
                align: "right",
                field: "pass",
                render: (d) => (
                  <span style={{ color: "#246611" }}>{d?.pass}</span>
                ),
                title: "Pass",
              },
              {
                align: "right",
                field: "fail",
                render: (d) => (
                  <span style={{ color: "#D42216" }}>{d?.fail}</span>
                ),
                title: "Fail",
              },
              {
                align: "right",
                field: "avgMarks",
                render: (d) => (
                  <span
                    style={{
                      backgroundColor: "#8BFFD5",
                      borderRadius: 5,
                      color: "#20222F",
                      height: 21,
                      padding: "0 6px",
                    }}
                  >
                    {`${d?.avgMarks || 0}%`}
                  </span>
                ),
                title: "Avg\xa0marks",
              },
              {
                align: "center",
                field: "avgTimeTaken",
                title: "Avg\xa0time\xa0taken\n(in\xa0min)",
              },
              {
                align: "center",
                field: "0_25",
                title: "0\xa0-\xa025",
              },
              {
                align: "center",
                field: "26_50",
                title: "26\xa0-\xa050",
              },
              {
                align: "center",
                field: "51_75",
                title: "51\xa0-\xa075",
              },
              {
                align: "center",
                field: "76_100",
                title: "76\xa0-\xa0100",
              },
            ]}
            isLoading={deptPerfDataLoading}
          />
        )}
        {activeTab === "student-performance" && (
          <ReportTable
            customExport
            loadingCsvExport={loadingCsvExport}
            loadingHtmlExport={loadingHtmlExport}
            loadingPdfExport={loadingPdfExport}
            exportCSV={exportCSV}
            exportPDF={exportPDF}
            exportHTML={exportHTML}
            exportDepartmentPerformancePdf={exportDepartmentPerformancePdf}
            exportStudentPerformancePdf={exportStudentPerformancePdf}
            exportSectionPerformancePdf={exportSectionPerformancePdf}
            exportDeptStudPerformancePdf={exportDeptStudPerformancePdf}
            exportDeptSecPerformancePdf={exportDeptSecPerformancePdf}
            exportStudSecPerformancePdf={exportStudSecPerformancePdf}
            exportDeptSecStudPerformancePdf={exportDeptSecStudPerformancePdf}
            exportDepartmentPerformanceHtml={exportDepartmentPerformanceHtml}
            exportStudentPerformanceHtml={exportStudentPerformanceHtml}
            exportSectionPerformanceHtml={exportSectionPerformanceHtml}
            exportDeptStudPerformanceHtml={exportDeptStudPerformanceHtml}
            exportDeptSecPerformanceHtml={exportDeptSecPerformanceHtml}
            exportStudSecPerformanceHtml={exportStudSecPerformanceHtml}
            exportDeptSecStudPerformanceHtml={exportDeptSecStudPerformanceHtml}
            exportDepartmentPerformanceCsv={exportDepartmentPerformanceCsv}
            exportStudentPerformanceCsv={exportStudentPerformanceCsv}
            exportSectionPerformanceCsv={exportSectionPerformanceCsv}
            exportDeptStudPerformanceCsv={exportDeptStudPerformanceCsv}
            exportDeptSecPerformanceCsv={exportDeptSecPerformanceCsv}
            exportDeptMockPerformanceCsv={exportDeptMockPerformanceCsv}
            exportStudSecPerformanceCsv={exportStudSecPerformanceCsv}
            exportDeptSecStudPerformanceCsv={exportDeptSecStudPerformanceCsv}
            exportProctoringPdf={exportProctoringPdf}   
            exportProctoringCsv={exportProctoringCsv}
            exportProctoringHtml={exportProctoringHtml}
            exportProctoringDeptPdf={exportProctoringDeptPdf}
            exportProctoringSectionPdf={exportProctoringSectionPdf}
            exportProctoringStudentPdf={exportProctoringStudentPdf}
            exportProctoringSecdeptPdf={exportProctoringSecdeptPdf}
            exportProctoringStudeptPdf={exportProctoringStudeptPdf}
            exportProctoringDeptHtml={exportProctoringDeptHtml}
            exportProctoringSectionHtml={exportProctoringSectionHtml}
            exportProctoringStudentHtml={exportProctoringStudentHtml}
            exportProctoringSecdeptHtml={exportProctoringSecdeptHtml}
            exportProctoringStudeptHtml={exportProctoringStudeptHtml}
            exportProctoringDeptCsv={exportProctoringDeptCsv}
            exportProctoringSectionCsv={exportProctoringSectionCsv}
            exportProctoringStudentCsv={exportProctoringStudentCsv}
            exportProctoringSecdeptCsv={exportProctoringSecdeptCsv}
            exportProctoringStudeptCsv={exportProctoringStudeptCsv}
            fetchData={getReportStudentData}
            labId={props?.location?.state?.assessmentId}
            totalCount={reportDashboardData?.enrolled || 0}
            data={reportStudentPerfData}
            title="Student wise performance"
            columns={[
              {
                align: "left",
                field: "name",
                title: "Name",
              },
              {
                align: "left",
                field: "registrationNumber",
                title: "Registration\xa0Number",
              },
              {
                align: "left",
                field: "emailId",
                title: "EmailId",
              },
              {
                align: "left",
                field: "department",
                title: "Department",
              },
              {
                align: "right",
                field: "totalQuestion",
                title: "Total\xa0Question",
              },
              {
                align: "right",
                field: "correct",
                render: (d) =>
                  d?.completed ? (
                    <span style={{ color: "#246611" }}>{d?.correct}</span>
                  ) : (
                    "-"
                  ),
                title: "Correct",
              },
              {
                align: "right",
                field: "wrong",
                render: (d) =>
                  d?.completed ? (
                    <span style={{ color: "#D42216" }}>{d?.wrong}</span>
                  ) : (
                    "-"
                  ),
                title: "Wrong",
              },
              {
                align: "right",
                field: "unAttempted",
                render: (d) => (d?.completed ? d?.unAttempted : "-"),
                title: "Un\u2011attempted",
              },
              {
                align: "right",
                field: "totalMarks",
                render: (d) =>
                  d?.completed ? (
                    <span
                      style={{
                        backgroundColor: "#8BFFD5",
                        borderRadius: 5,
                        color: "#20222F",
                        height: 21,
                        padding: "0 6px",
                      }}
                    >
                      {`${d?.totalMarks || 0}%`}
                    </span>
                  ) : (
                    "-"
                  ),
                title: "Total\xa0Marks",
              },
              {
                align: "center",
                field: "timeTaken",
                render: (d) => (d?.completed ? d?.timeTaken : "-"),
                title: "Time\xa0taken\n(in\xa0min)",
              },
              {
                align: "center",
                field: "performance",
                render: (d) =>
                  d?.completed ? (
                    <span
                      style={{
                        backgroundColor:
                          d?.performance === "Good"
                            ? "#EEFFEA"
                            : d?.performance === "Average"
                            ? "#FFF8E1"
                            : "#FFF4F3",
                        borderColor:
                          d?.performance === "Good"
                            ? "#B3DAC7"
                            : d?.performance === "Average"
                            ? "#FFCA99"
                            : "#FFD7D7",
                        borderStyle: "solid",
                        borderWidth: 1,
                        borderRadius: 3,
                        color:
                          d?.performance === "Good"
                            ? "#43B224"
                            : d?.performance === "Average"
                            ? "#886E1D"
                            : "#D42216",
                        height: 24,
                        padding: "0 6px",
                        width: 66,
                      }}
                    >
                      {d?.performance}
                    </span>
                  ) : (
                    "-"
                  ),
                title: "Performance",
              },
            ]}
            isLoading={stuPerfDataLoading}
          />
        )}
        {activeTab === "section-performance" && (
          <>
            {!!(sections || [0]).length && (
              <TabsPractice
                activeTab={activeSection?.id}
                className={styles.sectionsTab}
                contentClassName={styles.tabContent}
                green
                hideBadge
                isLoading={secPerfDataLoading}
                noChildren
                onChange={setActiveSection}
                tabs={sections}
              />
            )}
            {!!(topics || []).length && (
              <TabsPractice
                activeTab={activeTopic?.id}
                className={styles.sectionsTab}
                contentClassName={styles.tabContent}
                hideBadge
                isLoading={secPerfDataLoading}
                noChildren
                onChange={setActiveTopic}
                tabs={topics}
              />
            )}
            <ReportTable
              customExport
              loadingCsvExport={loadingCsvExport}
              loadingHtmlExport={loadingHtmlExport}
              loadingPdfExport={loadingPdfExport}
              exportCSV={exportCSV}
              exportPDF={exportPDF}
              exportHTML={exportHTML}
              exportDepartmentPerformancePdf={exportDepartmentPerformancePdf}
              exportStudentPerformancePdf={exportStudentPerformancePdf}
              exportSectionPerformancePdf={exportSectionPerformancePdf}
              exportDeptStudPerformancePdf={exportDeptStudPerformancePdf}
              exportDeptSecPerformancePdf={exportDeptSecPerformancePdf}
              exportStudSecPerformancePdf={exportStudSecPerformancePdf}
              exportDeptSecStudPerformancePdf={exportDeptSecStudPerformancePdf}
              exportDepartmentPerformanceHtml={exportDepartmentPerformanceHtml}
              exportStudentPerformanceHtml={exportStudentPerformanceHtml}
              exportSectionPerformanceHtml={exportSectionPerformanceHtml}
              exportDeptStudPerformanceHtml={exportDeptStudPerformanceHtml}
              exportDeptSecPerformanceHtml={exportDeptSecPerformanceHtml}
              exportStudSecPerformanceHtml={exportStudSecPerformanceHtml}
              exportProctoringPdf={exportProctoringPdf}   
              exportProctoringCsv={exportProctoringCsv}
              exportProctoringHtml={exportProctoringHtml}
              exportDeptSecStudPerformanceHtml={
                exportDeptSecStudPerformanceHtml
              }
              exportDepartmentPerformanceCsv={exportDepartmentPerformanceCsv}
              exportStudentPerformanceCsv={exportStudentPerformanceCsv}
              exportSectionPerformanceCsv={exportSectionPerformanceCsv}
              exportDeptStudPerformanceCsv={exportDeptStudPerformanceCsv}
              exportDeptSecPerformanceCsv={exportDeptSecPerformanceCsv}
              exportStudSecPerformanceCsv={exportStudSecPerformanceCsv}
              exportDeptSecStudPerformanceCsv={exportDeptSecStudPerformanceCsv}
              exportProctoringSectionPdf={exportProctoringSectionPdf}
              exportProctoringStudentPdf={exportProctoringStudentPdf}
              exportProctoringDeptPdf={exportProctoringDeptPdf}
              exportProctoringStuSecPdf={exportProctoringStuSecPdf}
              exportProctoringSectionHtml={exportProctoringSectionHtml}
              exportProctoringStudentHtml={exportProctoringStudentHtml}
              exportProctoringDeptHtml={exportProctoringDeptHtml}
              exportProctoringStuSecHtml={exportProctoringStuSecHtml}
              exportProctoringSectionCsv={exportProctoringSectionCsv}
              exportProctoringStudentCsv={exportProctoringStudentCsv}
              exportProctoringDeptCsv={exportProctoringDeptCsv}
              exportProctoringStuSecCsv={exportProctoringStuSecCsv}
              data={
                reportSectionPerfData?.[activeSection]?.data?.[activeTopic] ||
                []
              }
              labId={props?.location?.state?.assessmentId}
              activeSection={activeSection}
              activeTopic={activeTopic}
              fetchData={getReportSectionData}
              totalCount={reportDashboardData?.enrolled || 0}
              title="Section wise performance"
              columns={[
                {
                  align: "left",
                  field: "name",
                  title: "Name",
                },
                {
                  align: "left",
                  field: "registrationNumber",
                  title: "Registration\xa0Number",
                },
                {
                  align: "left",
                  field: "department",
                  title: "Department",
                },
                {
                  align: "right",
                  field: "totalQuestion",
                  
                  title: "Total\xa0Question",
                },
                {
                  align: "right",
                  field: "Attempted",
                  render: (d) => (d?.completed ? d?.attempted : "-"),
                  title: "Attempted",
                },
                {
                  align: "right",
                  field: "unAttempted",
                  render: (d) => (d?.completed ? d?.unAttempted : "-"),
                  title: "Un\u2011attempted",
                },
                {
                  align: "right",
                  field: "correct",
                  render: (d) =>
                    d?.completed ? (
                      <span style={{ color: "#246611" }}>{d?.correct}</span>
                    ) : (
                      "-"
                    ),
                  title: "Correct",
                },
                {
                  align: "right",
                  field: "wrong",
                  render: (d) =>
                    d?.completed ? (
                      <span style={{ color: "#D42216" }}>{d?.wrong}</span>
                    ) : (
                      "-"
                    ),
                  title: "Wrong",
                },
                {
                  align: "right",
                  field: "sectionMarks",
                  render: (d) =>
                    d?.completed ? (
                      <span
                        style={{
                          backgroundColor: "#8BFFD5",
                          borderRadius: 5,
                          color: "#20222F",
                          height: 21,
                          padding: "0 6px",
                        }}
                      >
                        {`${d?.sectionMarks || 0}%`}
                      </span>
                    ) : (
                      "-"
                    ),
                  title: "Section\xa0Marks",
                },
              ]}
              isLoading={secPerfDataLoading}
            />
            
          </>
        )}
        {activeTab === "Proctoring-Log" && (
            <ReportTable
              customExport
              loadingCsvExport={loadingCsvExport}
              loadingHtmlExport={loadingHtmlExport}
              loadingPdfExport={loadingPdfExport}
              exportCSV={exportCSV}
              exportPDF={exportPDF}
              exportHTML={exportHTML}
              exportDepartmentPerformancePdf={exportDepartmentPerformancePdf}
              exportStudentPerformancePdf={exportStudentPerformancePdf}
              exportSectionPerformancePdf={exportSectionPerformancePdf}
              exportDeptStudPerformancePdf={exportDeptStudPerformancePdf}
              exportDeptSecPerformancePdf={exportDeptSecPerformancePdf}
              exportStudSecPerformancePdf={exportStudSecPerformancePdf}
              exportDeptSecStudPerformancePdf={exportDeptSecStudPerformancePdf}
              exportDepartmentPerformanceHtml={exportDepartmentPerformanceHtml}
              exportStudentPerformanceHtml={exportStudentPerformanceHtml}
              exportSectionPerformanceHtml={exportSectionPerformanceHtml}
              exportDeptStudPerformanceHtml={exportDeptStudPerformanceHtml}
              exportDeptSecPerformanceHtml={exportDeptSecPerformanceHtml}
              exportStudSecPerformanceHtml={exportStudSecPerformanceHtml}
              exportDeptSecStudPerformanceHtml={exportDeptSecStudPerformanceHtml}
              exportDepartmentPerformanceCsv={exportDepartmentPerformanceCsv}
              exportStudentPerformanceCsv={exportStudentPerformanceCsv}
              exportSectionPerformanceCsv={exportSectionPerformanceCsv}
              exportDeptStudPerformanceCsv={exportDeptStudPerformanceCsv}
              exportDeptSecPerformanceCsv={exportDeptSecPerformanceCsv}
              exportDeptMockPerformanceCsv={exportDeptMockPerformanceCsv}
              exportStudSecPerformanceCsv={exportStudSecPerformanceCsv}
              exportDeptSecStudPerformanceCsv={exportDeptSecStudPerformanceCsv}
              exportProctoringPdf={exportProctoringPdf}   
              exportProctoringCsv={exportProctoringCsv}
              exportProctoringHtml={exportProctoringHtml}
              exportProctoringDeptPdf={exportProctoringDeptPdf}
              exportProctoringSectionPdf={exportProctoringSectionPdf}
              exportProctoringStuSecPdf={exportProctoringStuSecPdf}
              exportProctoringSecdeptPdf={exportProctoringSecdeptPdf}
              exportProctoringStudeptPdf={exportProctoringStudeptPdf}
              exportProctoringDeptHtml={exportProctoringDeptHtml}
              exportProctoringSectionHtml={exportProctoringSectionHtml}
              exportProctoringStuSecHtml={exportProctoringStuSecHtml}
              exportProctoringSecdeptHtml={exportProctoringSecdeptHtml}
              exportProctoringStudeptHtml={exportProctoringStudeptHtml}
              exportProctoringDeptCsv={exportProctoringDeptCsv}
              exportProctoringSectionCsv={exportProctoringSectionCsv}
              exportProctoringStuSecCsv={exportProctoringStuSecCsv}
              exportProctoringSecdeptCsv={exportProctoringSecdeptCsv}
              exportProctoringStudeptCsv={exportProctoringStudeptCsv}
              fetchData={getReportProctoringData}
              labId={props?.location?.state?.assessmentId}
              totalCount={reportDashboardData?.enrolled || 0}
              data={reportStudentPerfData}
              title="Proctoring Log"
              columns={[
                {
                  align: "left",
                  field: "name",
                  title: "Name",
                 
                },
                {
                  align: "left",
                  field: "registrationNumber",
                  title: "Registration\xa0Number",
                  
                },
                {
                  align: "left",
                  field: "department",
                  title: "Department",
                 
                },
                {
                  align: "left",
                  field: " annotations",
                  title: "Annotations",
                  render: (d) =>
                    <div>
                      <span style={{ color: "#246611", paddingRight:'5px' }}>{d?.annotations >= 0 ? (`${d?.annotations}` || '-') : '-'}</span>
                    </div>
                },
                {
                  align: "left",
                  field: "laptop",
                  title: "Laptop",
                  render: (d) =>
                  // d?.completed ? (
                    <div>
                      <span style={{ color: "#246611", paddingRight:'5px' }}>{d?.laptop >= 0 ? `${d?.laptop}` || '-' : '-'}</span>
                    </div>
                },
                {
                  align: "left",
                  field: "mobilePhone",
                  title: "Mobile\xa0Phone",
                  render: (d) =>
                  // d?.completed ? (
                    <div>
                      <span style={{ color: "#246611", paddingRight:'5px' }}>{d?.mobilePhone >= 0 ? `${d?.mobilePhone}` || '-' : '-'}</span>
                    </div>
                },
                {
                  align: "left",
                  field: "outOfFrame",
                  title: "out\xa0Of\xa0Frame",
                  render: (d) =>
                    // d?.completed ? (
                      <div>
                        <span style={{ color: "#246611", paddingRight:'5px' }}>{d?.outOfFrame >= 0 ? `${d?.outOfFrame}` || '-' : '-'}</span>
                      </div>
                },
                {
                  align: "left",
                  field: "suspicionLevel",
                  title: "Suspicion\xa0Level",
                  render: (d) =>
                    // d?.completed ? (
                      <div>
                        <span style={{ color: "#246611", paddingRight:'5px' }}>{d?.suspicionLevel >= 0 ? `${d?.suspicionLevel}%` || '-' : '-'}</span>
                        
                        {(d?.suspicionLevel <= '40' ) && 
                        <span style={{
                          height: '10px',
                          width: '10px',
                          backgroundColor: '#089A79',
                          borderRadius: '50%',
                          display: 'inline-block',
                        }} /> }
                          {(d?.suspicionLevel > '40' && d?.suspicionLevel <= '80' ) && 

                        <span style={{
                          height: '10px',
                          width: '10px',
                          backgroundColor: '#D99229',
                          borderRadius: '50%',
                          display: 'inline-block',
                        }} /> }
                          {( d?.suspicionLevel > '80' && d?.suspicionLevel === '100' ) && 
                        <span style={{
                          height: '10px',
                          width: '10px',
                          backgroundColor: '#DE5445',
                          borderRadius: '50%',
                          display: 'inline-block',
                        }} /> }
                      </div>
                    // ) : (
                    //   "-"
                    // ),
                },
                {
                  align: "left",
                  title: "Action",
                  render: (d) =>
                    // d?.completed ? (
                      <div>
                        <IconButton onClick={() => toggle(d?.emailId)} >
                          < RemoveRedEyeOutlinedIcon  style={{ color: "#63606C",}}/>
                        </IconButton>
                      </div>
                    // ) : (
                    //   "-"
                    // ),
                },
              ]}
              isLoading={stuPerfDataLoading}
            />
        )}
        {activeTab === "AI-Graded-Performance" && (
          <ReportTable
            customExport
            loadingCsvExport={loadingCsvExport}
            loadingHtmlExport={loadingHtmlExport}
            loadingPdfExport={loadingPdfExport}
            exportCSV={exportCSV}
            exportPDF={exportPDF}
            exportHTML={exportHTML}
            exportDepartmentPerformancePdf={exportDepartmentPerformancePdf}
            exportStudentPerformancePdf={exportStudentPerformancePdf}
            exportSectionPerformancePdf={exportSectionPerformancePdf}
            exportDeptStudPerformancePdf={exportDeptStudPerformancePdf}
            exportDeptSecPerformancePdf={exportDeptSecPerformancePdf}
            exportStudSecPerformancePdf={exportStudSecPerformancePdf}
            exportDeptSecStudPerformancePdf={exportDeptSecStudPerformancePdf}
            exportDepartmentPerformanceHtml={exportDepartmentPerformanceHtml}
            exportStudentPerformanceHtml={exportStudentPerformanceHtml}
            exportSectionPerformanceHtml={exportSectionPerformanceHtml}
            exportDeptStudPerformanceHtml={exportDeptStudPerformanceHtml}
            exportDeptSecPerformanceHtml={exportDeptSecPerformanceHtml}
            exportStudSecPerformanceHtml={exportStudSecPerformanceHtml}
            exportDeptSecStudPerformanceHtml={exportDeptSecStudPerformanceHtml}
            exportDepartmentPerformanceCsv={exportDepartmentPerformanceCsv}
            exportStudentPerformanceCsv={exportStudentPerformanceCsv}
            exportSectionPerformanceCsv={exportSectionPerformanceCsv}
            exportDeptStudPerformanceCsv={exportDeptStudPerformanceCsv}
            exportDeptSecPerformanceCsv={exportDeptSecPerformanceCsv}
            exportDeptMockPerformanceCsv={exportDeptMockPerformanceCsv}
            exportStudSecPerformanceCsv={exportStudSecPerformanceCsv}
            exportDeptSecStudPerformanceCsv={exportDeptSecStudPerformanceCsv}
            exportProctoringPdf={exportProctoringPdf}   
            exportProctoringCsv={exportProctoringCsv}
            exportProctoringHtml={exportProctoringHtml}
            exportProctoringDeptPdf={exportProctoringDeptPdf}
            exportProctoringSectionPdf={exportProctoringSectionPdf}
            exportProctoringStudentPdf={exportProctoringStudentPdf}
            exportProctoringSecdeptPdf={exportProctoringSecdeptPdf}
            exportProctoringStudeptPdf={exportProctoringStudeptPdf}
            exportProctoringDeptHtml={exportProctoringDeptHtml}
            exportProctoringSectionHtml={exportProctoringSectionHtml}
            exportProctoringStudentHtml={exportProctoringStudentHtml}
            exportProctoringSecdeptHtml={exportProctoringSecdeptHtml}
            exportProctoringStudeptHtml={exportProctoringStudeptHtml}
            exportProctoringDeptCsv={exportProctoringDeptCsv}
            exportProctoringSectionCsv={exportProctoringSectionCsv}
            exportProctoringStudentCsv={exportProctoringStudentCsv}
            exportProctoringSecdeptCsv={exportProctoringSecdeptCsv}
            exportProctoringStudeptCsv={exportProctoringStudeptCsv}
            fetchData={getReportStudentData}
            labId={props?.location?.state?.assessmentId}
            totalCount={reportDashboardData?.enrolled || 0}
            data={reportStudentPerfData}
            title="Student wise performance"
            columns={[
              {
                align: "left",
                field: "name",
                title: "Name",
              },
              {
                align: "left",
                field: "registrationNumber",
                title: "Registration\xa0Number",
              },
              {
                align: "left",
                field: "emailId",
                title: "EmailId",
              },
              {
                align: "left",
                field: "department",
                title: "Department",
              },
              {
                align: "center",
                field: "totalQuestion",
                title: "Total\xa0Question",
              },
              {
                align: "center",
                field: "unAttempted",
                render: (d) => (d?.completed ? d?.unAttempted : "-"),
                title: "Un\u2011attempted",
              },
              {
                align: "center",
                field: "aiGrade",  // change to the response mark field
                render: (d) =>
                  d?.completed ? (
                    <span
                      style={{
                        backgroundColor: "#8BFFD5",
                        borderRadius: 5,
                        color: "#20222F",
                        height: 21,
                        padding: "0 6px",
                      }}
                    >
                      {`${d?.totalMarks || 0}%`}
                    </span>
                  ) : (
                    "-"
                  ),
                title: "Total\xa0Marks",
              },
              {
              align: "left",
              field: "performance",
              render: (d) =>{
                d.performance = {
                  question1 : {
                  name : "hardSHift",
                  grade : "80",
                  feedback : "Good Implementation of logic ,but need to be optimized"
                  },
                  question2 : {
                  name : "wrong Turn",
                  grade : "50",
                  feedback : "lack of logic ,need to be more logical in solving"
                }}
                return d?.performance ? (
                  <div>
                    {Object.entries(d?.performance || {}).map(([key, value], index) => (
                      <div key={index} style={{ marginBottom: 8 , textAlign: "left" }}>
                        <div
                          style={{
                            backgroundColor: value.grade >= 70 ? "#EEFFEA" : value.grade >= 50 ? "#FFF8E1" : "#FFF4F3",
                            borderColor: value.grade >= 70 ? "#B3DAC7" : value.grade >= 50 ? "#FFCA99" : "#FFD7D7",
                            borderStyle: "solid",
                            borderWidth: 1,
                            borderRadius: 3,
                            color: value.grade >= 70 ? "#43B224" : value.grade >= 50 ? "#886E1D" : "#D42216",
                            padding: "6px 12px",
                            marginBottom: "6px",
                          }}
                        >
                          Question Name: <span style={{ fontWeight: "normal" }}>{value.name}</span> <br />
                          Grade: <span style={{ fontWeight: "normal" }}>{value.grade}</span> <br />
                          Feedback: <span style={{ fontWeight: "normal" }}>{value.feedback}</span>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  "-"
                )},
                title: "Performance",
              }
            ]}
            isLoading={stuPerfDataLoading}
          />
        )}
      </Tabs>
      <div>
        <Button
          outline
          style={{ display: "inline-block !important", fontSize: 16 }}
          onClick={() => {
            if (props?.location?.state?.labId) {
              sessionStorage.setItem(
                "practice-report-id",
                props?.location?.state?.labId
              );
              props.history.push("/practice-report?tab=mock-assessments");
            } else {
              props.history.push({
                pathname:
                  overAllReport === true ? "/overall-report" : "/assessments",
                state: {
                  customer: customerId,
                  activePage: activePage,
                  activeTab: activeTab,
                  itemsPerPage: itemsPerPage,
                },
              });
            }
          }}
        >
          <ArrowBack />
          &nbsp;&nbsp;
          {"Go Back"}
        </Button>
      </div>
      <br />
      <br />
      <ToastContainer />

    </div>
  );
}


export default withRouter(AdminAssessmentReport);
