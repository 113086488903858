import api from "api/baseConfig";
import apiHelper from "api/apiHelper";

export const CreateOrUpdate = async (formValues) => {
  const url = `${api.baseURL}${api.CloudLab.Create}`;
  const payload = {
    ...formValues,
  };
  try {
    const data = await apiHelper.axiosCallPost(url, "post", payload);
    return data;
  } catch (error) {
    throw new Error(`Some error occurred: ${error}`);
  }
};

export const getCloudLab = async (customerId) => {
  const url = `${api.baseURL}${api.CloudLab.CloudlabGet}?customerId=${customerId}`;
  try {
    const data = await apiHelper.axiosCallPost(url, "get");
    return data;
  } catch (error) {
    throw new Error(`Some error occurred: ${error}`);
  }
};

export const DeleteCourse = async (cloudLabId) => {
  try {
    await apiHelper.axiosCallPost(
      `${api.baseURL}${api.CloudLab.CloudLabDelete}${cloudLabId}`,
      "delete"
    );
  } catch (error) {
    throw new Error(`Some error occurred: ${error}`);
  }
};

export const GetCourse = async (url) => {
  try {
    const data = await apiHelper.axiosCall(url, "get");
    return data;
  } catch (error) {
    throw new Error(`Some error occurred: ${error}`);
  }
};

export const CheckIfCloudLabNameExists = async (meetingName) => {
  const url = `${api.baseURL}${
    api.CloudLab.CloudLabNameCheck
  }/${sessionStorage.getItem("customer_id")}/${meetingName}`;
  try {
    const res = await apiHelper.axiosCall(url, "get");
    return res;
  } catch (error) {
    (e) => {
      console.log(e, "something error");
    };
  }
};
