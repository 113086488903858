import { useEffect, useState } from "react";
import { ResponsiveBar } from "@nivo/bar";
import truncate from "truncate-html";
import GridItem from "../../../components/GridItem/GridItem";
import SkeletonGridItem from "../../../components/SkeletonGridItem/SkeletonGridItem";
import ChartContainer from "../../../components/ChartContainer/ChartContainer";
import { useGetDashboardInfo } from "pages/Dashboard/SWRhooks";
import { AdminAssessmentMarks } from "pages/Dashboard/SampleData";

const color = {
  "0-25%": "#7181FB",
  "26-50%": "#344CFF",
  "51-75%": "#0A1FBB",
  "76-100%": "#061163",
};
const getColor = (bar) => color[bar.id];

export function AssessmentMarks({ showReal, isMobile }) {
  const [dataIsLoading, setIfDataIsLoading] = useState(false);
  const [assessmentMarksViewOption, setStudentAssessmentMarksViewOption] =
    useState({
      name: "",
      value: "",
    });
  const [assessmentMarksViewOptions, setAssessmentMarksViewOptions] = useState(
    []
  );
  const [assessmentMarksData, setAssessmentMarksData] = useState([]);
  const [assessmentMarksMaxValue, setAssessmentMarksMaxValue] = useState(null);
  const [assessmentMarksDetails, setAssessmentMarksDetails] = useState({});

  useEffect(() => {
    if (assessmentMarksViewOption.value) {
      const data = assessmentMarksDetails[assessmentMarksViewOption.value];
      const maxValue =
        Math.max(
          0,
          ...(data || [])
            .map((a) => [
              a?.["0-25%"] + a?.["26-50%"] + a?.["51-75%"] + a?.["76-100%"],
            ])
            .flat()
        ) || 1;

      setAssessmentMarksData(data);
      setAssessmentMarksMaxValue(maxValue + maxValue / 7.5);
    }
  }, [assessmentMarksViewOption]);

  const apiResp = useGetDashboardInfo(
    "getAdminDashBoardStudentAssessmentMarks",
    showReal
  );

  const setData = (data) => {
    const studentAssessmentMarksGroups = [];
    const assessmentMarksDetails = {};

    if (data?.studentAssessmentsMarkDetails) {
      data?.studentAssessmentsMarkDetails.forEach((g, idx) => {
        studentAssessmentMarksGroups.push({
          name: g.name,
          value: `SAM-${idx + 1}`,
        });
        assessmentMarksDetails[`SAM-${idx + 1}`] = (g.data || []).map((d) => ({
          assessment: d?.name,
          "0-25%": d.marks["0_25"],
          "26-50%": d.marks["26_50"],
          "51-75%": d.marks["51_75"],
          "76-100%": d.marks["76_100"],
        }));
      });
    }

    setAssessmentMarksViewOptions(studentAssessmentMarksGroups);
    setAssessmentMarksDetails(assessmentMarksDetails);

    if (studentAssessmentMarksGroups?.[0]) {
      setStudentAssessmentMarksViewOption(studentAssessmentMarksGroups?.[0]);
      setAssessmentMarksData(
        assessmentMarksDetails[studentAssessmentMarksGroups?.[0]?.value] || []
      );
    }
  };

  useEffect(() => {
    if (apiResp === "Fetching...") {
      setIfDataIsLoading(true);
    }
    if (apiResp && apiResp.hasOwnProperty("studentAssessmentsMarkDetails")) {
      setData(apiResp);
      setIfDataIsLoading(false);
    }
  }, [apiResp]);

  useEffect(() => {
    setData(AdminAssessmentMarks);
  }, []);

  const hasData = !!assessmentMarksViewOptions.length;

  return dataIsLoading ? (
    <SkeletonGridItem
      gridPosition={[
        isMobile ? [1, -1] : [7, 13],
        showReal ? (isMobile ? [5, 6] : [6, 7]) : isMobile ? [6, 7] : [6, 7],
      ]}
    />
  ) : (
    <GridItem
      gridPosition={[
        isMobile ? [1, -1] : [7, 13],
        showReal ? (isMobile ? [5, 6] : [6, 7]) : isMobile ? [6, 7] : [6, 7],
      ]}
    >
      <ChartContainer
        currentOption={assessmentMarksViewOption.value}
        options={assessmentMarksViewOptions}
        onOptionChange={setStudentAssessmentMarksViewOption}
        title={<strong>Students assessment marks</strong>}
      >
        {hasData && (
          <ResponsiveBar
            animate={true}
            axisBottom={{
              legend: "% Students Marks",
              legendOffset: 20,
              legendPosition: "middle",
              renderTick: () => <></>,
              tickSize: 0,
            }}
            axisLeft={{
              legendPosition: "middle",
              tickSize: 0,
              tickPadding: 8,
              tickValues: false,
              renderTick: ({
                textAnchor,
                textBaseline,
                textX,
                textY,
                value,
                x,
                y,
              }) => {
                return (
                  <g transform={`translate(${x - 5},${y + 2})`}>
                    <text
                      alignmentBaseline={textBaseline}
                      textAnchor={textAnchor}
                      transform={`translate(${textX},${textY})`}
                      fontSize={11}
                    >
                      <tspan>{truncate(value, 7)}</tspan>
                    </text>
                  </g>
                );
              },
            }}
            axisRight={null}
            axisTop={null}
            colors={getColor}
            data={assessmentMarksData}
            enableGridX={false}
            enableGridY={false}
            groupMode="stacked"
            indexBy="assessment"
            innerPadding={1}
            keys={["0-25%", "26-50%", "51-75%", "76-100%"]}
            labelFormat={() => <tspan />}
            layout="horizontal"
            legends={[
              {
                anchor: "bottom",
                dataFrom: "keys",
                direction: "row",
                itemDirection: "left-to-right",
                itemHeight: 12,
                itemOpacity: 1,
                itemsSpacing: 8,
                itemWidth: 100,
                justify: false,
                symbolSize: 12,
                translateX: 0,
                translateY: 60,
              },
            ]}
            margin={{
              bottom: 65,
              left: 70,
              right: 25,
            }}
            maxValue={assessmentMarksMaxValue}
            minValue={0}
            motionDamping={15}
            motionStiffness={90}
            padding={0.3}
            theme={{
              legends: {
                text: {
                  fontSize: 12,
                },
              },
              axis: {
                domain: {
                  line: {
                    stroke: "#564848",
                    strokeWidth: 1,
                  },
                },
                legend: {
                  text: {
                    color: "#000",
                    fontSize: 14,
                  },
                },
              },
            }}
            valueScale={{ type: "linear" }}
          />
        )}
      </ChartContainer>
    </GridItem>
  );
}
