import React, { useEffect, useMemo, useState } from "react";
import { Paper } from "@material-ui/core";
import styles from "./UserAssessmentMarks.module.scss";
import Select from "react-select";
import { GetObjectFromString } from "../utils/GetObjectFromString";
import { getCustomers } from "../../store/StudentAndCustomer/actions";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import CustomSearch from "./component/CustomSearch";
import * as CustomIcons from "../../assets/icons/icons.js";
import { Button } from "reactstrap";
import { Tabs } from "../../components/tabs/tabsStudent";
import Calender from "./component/calender";
import Apihelper from "../../api/apiHelper";
import api from "../../api/baseConfig";
import MeetingList from "./meetingList";
import CloudLabForm from "./component/cloudLabForm";
import { DeleteModule } from "../../components/DeleteModule.jsx";
import { ModalToggle } from "pages/utils/ModalToggle";
import { DeleteCourse } from "./component/Apis";
import { Link, useHistory } from "react-router-dom";
import Skeleton from "@material-ui/lab/Skeleton";
import { sessionPrivileges } from "privileges";

function CloudLabAdmin(props) {
  const defaultCourses = {
    meetingTimeline: [],
    MeetingsList: [],
  };
  const userPrivileges = sessionPrivileges();
  const Priv = userPrivileges.userprivileges || [];
  const [createNew, setCreateNew] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [activeTab, setActiveTab] = useState("MeetingTimeline");
  const [userDataList, setuserDataList] = useState([]);
  const [customSearchValue, setCustomSearchValue] = useState("");
  const [formValue, setFormValue] = useState("");
  const [formValue1, setFormValue1] = useState("");
  const [cloudLabList, setCloudLabList] = useState({
    real: defaultCourses,
    modify: defaultCourses,
  });
  const [cloudLabData, setCloudLabData] = useState([]);
  const [currDate, setCurrDate] = useState();
  const [cloudLabId, setCloudLabId] = useState([]);
  const [cloudLab, setCloudLab] = useState();
  const [deleteCourseId, setDeleteCourseId] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const [schedularDate, setSchedularDate] = useState([]);
  const [durationValue, setDurationValue] = useState([]);
  const [meetingNameValue, setMeetingNameValue] = useState([]);
  const [courseSearch, setCourseSearch] = useState("");
  const [customerId, setCustomerId] = useState(() =>
    Priv.includes("MANAGE_CUSTOMER")
      ? props?.location?.params?.customerId || ""
      : sessionStorage.getItem("customer_id") || ""
  );
  const customers = useMemo(
    () =>
      (props?.Customers || []).sort(
        ({ name: n1 = "" }, { name: n2 = "" }) =>
          n1?.toLowerCase() < n2?.toLowerCase()
      ),
    [props?.Customers]
  );
  const customersList = [];
  customers.forEach(function (item) {
    customersList.push({ label: item.name, value: item.id });
  });

  useEffect(() => {
    if (props?.location?.state?.state) {
      setCustomerId(props?.location?.state?.state);
    }
    if (
      props?.location?.state?.currentDate &&
      props?.location?.state?.customer !== "all"
    ) {
      setCurrDate(props?.location?.state?.currentDate);
    }
    if (Priv?.includes("MANAGE_CUSTOMER")) {
      props.dispatch(getCustomers());
    } else {
      getAssessmentsByCustomer(sessionStorage.getItem("customer_id"));
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (customerId) {
      getAssessmentsByCustomer(customerId);
    }
  }, [customerId]);

  async function getAssessmentsByCustomer() {
    setLoading(true);
    await Apihelper.axiosCall(
      `${api.baseURL}${api.CloudLab.CloudlabGet}?customerId=${customerId}`,
      "get"
    )
      .then((data) => {
        setLoading(false);
        const newCoursesSkeleton = { ...data };
        // setCloudLabData(data.find((e) => {setDeleteCourseId(e.id)}))
        setCloudLabList({
          real: newCoursesSkeleton,
          modify: newCoursesSkeleton,
        });
      })
      .catch((e) => {
        console.log(`something went wrong ${e}`);
      });
  }

  const deleteCourse = async () => {
    try {
      await DeleteCourse(deleteCourseId);
      getAssessmentsByCustomer(
        userPrivileges?.isAdmin
          ? sessionStorage.getItem("customer_id")
          : customerId
      );
    } catch (error) {
      console.log(`something went wrong ${error}`);
    }
  };

  const toggleDeleteModal = () => setDeleteModal(ModalToggle);
  const totalCloud = Object.keys(cloudLabList.modify);

  const tabList = [
    {
      name: "Meeting timeline",
      id: "MeetingTimeline",
    },
    {
      name: "Meetings list",
      id: "MeetingsList",
    },
  ];
  const handleCourseChange = (e) => {
    setCourseSearch(e.target.value);
  };

  return (
    <div>
      <DeleteModule
        Header="Course"
        Close={toggleDeleteModal}
        isOpen={deleteModal}
        Delete={deleteCourse}
      />
      {createNew ? (
        <CloudLabForm
          formValue={formValue}
          setFormValue={setFormValue}
          customersList={customersList}
          customerId={customerId}
        />
      ) : (
        <Paper elevation={1} className={styles.headerBox}>
          <h3>Cloud lab</h3>
          <p>Can create and schedule meetings, live class, etc</p>
          <div className={styles.selectWrapper1}>
            <div className={styles.selectWrapper1}>
              <div className={styles.selectBox}>
                <div>
                  <div className="mb-4 search-AdminInput">
                    <input
                      type="search"
                      placeholder="&#xf002; Search...."
                      aria-describedby="button-addon4"
                      className="form-controls"
                      style={{ fontFamily: "FontAwesome, Poppins" }}
                      value={courseSearch}
                      onChange={handleCourseChange}
                      autoComplete="off"
                      onClick={(e) => setActiveTab("MeetingsList")}
                    />
                  </div>
                </div>
              </div>
              {userPrivileges?.isSuperAdmin && (
                <div className={styles.selectBox}>
                  <Select
                    placeholder="Choose a customer"
                    value={GetObjectFromString(customersList, customerId)}
                    onChange={(e) => {
                      setCustomerId(e.value);
                      setuserDataList("");
                      setData(null);
                    }}
                    options={customersList}
                    maxMenuHeight={150}
                    styles={{
                      menu: (provided) => ({ ...provided, zIndex: 9999 }),
                    }}
                  />
                </div>
              )}
              <div style={{ position: "absolute", right: "20px" }}>
                <Button
                  style={{
                    fontSize: "16px",
                    backgroundColor: "#794DF5",
                    border: "none",
                  }}
                >
                  <Link
                    to={{
                      pathname: `/cloudLabForm`,
                      state: { customerId: customerId },
                    }}
                  >
                    <CustomIcons.PlusIcon size="16" color="#fff" />{" "}
                    <span style={{ paddingLeft: "10px", color: "#fff" }}>
                      Create new
                    </span>
                  </Link>
                </Button>
              </div>
            </div>
          </div>
        </Paper>
      )}
      {!loading ? (
        <div>
          <Tabs
            activeTab={activeTab}
            contentClassName={styles.tabContent}
            onChange={setActiveTab}
            tabs={tabList}
            hideBadge={true}
          />
          {customerId ? (
            <div>
              {activeTab === "MeetingTimeline" && (
                <>
                  <Calender
                    customerId={customerId}
                    cloudLabList={cloudLabList}
                    setCloudLabList={setCloudLabList}
                    totalCloud={totalCloud}
                    schedularDate={schedularDate}
                    durationValue={durationValue}
                    meetingNameValue={meetingNameValue}
                    currDate={currDate}
                  />
                </>
              )}
              {activeTab === "MeetingsList" && (
                <div>
                  <MeetingList
                    customerId={customerId}
                    cloudLabList={cloudLabList}
                    setCloudLabList={setCloudLabList}
                    totalCloud={totalCloud}
                    toggleDeleteModal={toggleDeleteModal}
                    setDeleteCourseId={setDeleteCourseId}
                    courseSearch={courseSearch}
                  />
                </div>
              )}
            </div>
          ) : (
            <div>
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "bold",
                  fontSize: "18px",
                }}
              >
                Please Choose a Customer
              </p>
            </div>
          )}
        </div>
      ) : (
        <>
          <Skeleton height={80} />
          <Skeleton height={80} />
          <Skeleton height={80} />
          <Skeleton height={80} />
          <Skeleton height={80} />
        </>
      )}
    </div>
  );
}
const mapStateToProps = (state) => ({
  Customers: state.StudentAndCustomerReducer.Customers,
});

export default connect(mapStateToProps, null)(withRouter(CloudLabAdmin));
