import React from "react";
import ReactDOM from "react-dom";
import App from "./Main/AppNew";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";
import "./i18n";
import { Provider } from "react-redux";
import store from "./store";
import "./global-styles.scss";
import LogoContext from "./context/logoContext";
import "assets/scss/theme.scss";


const app = (
  <Provider store={store}>
    <LogoContext>
    <BrowserRouter>
      <App />
    </BrowserRouter>
    </LogoContext>
  </Provider>
);

ReactDOM.render(app, document.getElementById("root"));
serviceWorker.unregister();
