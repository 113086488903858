import styles from "./hackathonCard.module.scss";
import { useMediaQuery } from "@material-ui/core";
import mobileStyles from "./hackathonCard.module.scss";

const HackathonCard = () => {
  const smallScreen = useMediaQuery("(max-width: 767px)");
  return (
    <div className={styles.main}>
      <SVG />
    </div>
  );
};

export default HackathonCard;

const SVG = () => (
  <svg
    width="100%"
    height="25rem"
    viewBox="0 0 1366 250"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_10629_41915)">
      <rect width="1366" height="200" fill="#021244" />
      <rect
        x="1163.73"
        y="-72"
        width="330"
        height="109.637"
        transform="rotate(29.1288 1163.73 -122)"
        fill="#F79B65"
      />
      {/* <rect x="-48.3926" y="60" width="330" height="122.373" transform="rotate(37.5655 -48.3926 60)" fill="#DC30D0"/> */}
    </g>
    <defs>
      <pattern
        id="pattern0"
        patternContentUnits="objectBoundingBox"
        width="1"
        height="1"
      >
        <use transform="scale(0.000853971 0.00636943)" />
      </pattern>
      <clipPath id="clip0_10629_41915">
        <rect width="1366" height="250" fill="white" />
      </clipPath>
      <image id="image0_10629_41915" width="1171" height="157" />
    </defs>
  </svg>
);
