import styles from './table.module.scss';

export default function Table({
  headerCells = [],
  bodyRows = [], }) {
  return (
    <div className={styles.tableContainer}>
      <table className={styles.table}>
        <thead>
          <tr>
            {headerCells.map((hc, idx) => (
              <th key={idx}>{hc}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {bodyRows.map(({ data = [], highlight }, idx) => (
            <tr className={highlight ? styles.highlight : undefined} key={idx}>
              {data.map((d, idx2) => (
                <td key={idx2}>{d}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
