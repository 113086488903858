import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Button } from "reactstrap";
import Styles from "./CustomTable.module.scss";
import * as CustomIcons from "../../assets/icons/icons";
import { EditAction } from "./utils/EditAction";
import { DeleteAction } from "./utils/DeleteAction";
import { uid } from "react-uid";

const SubTable = (props) => {
  const [allData, setAllData] = useState([]);
  const history = useHistory();

  const activePage = props?.activePage;
  const itemsPerPage = props?.itemsPerPage;

  useEffect(() => {
    setAllData(props.subData);
  }, [props.subData]);

  const setEditObject = (row) => {
    let editObject = {};
    props.editSubData.forEach((item) => {
      editObject[item] = row[item];
    });
    return editObject;
  };

  return (
    <>
      <div className={Styles.subTableHead}>
        <div className={Styles.subTableHeadColor}>{props.subTitle}</div>
        <div className={Styles.subTablePosition}>
          {props.hideSubAdd && (
            <Button
              color="primary"
              onClick={() =>
                history.push({
                  pathname: props.createSubLink,
                  state: {
                    activePage: activePage,
                  }
                })
              }
            >
              <CustomIcons.PlusIcon size="17" color="#fff" /> Add New
            </Button>
          )}
        </div>
      </div>
      <div>
        {allData.map((datum, index) => (
          <>
            <div className={Styles.subTableRow} key={uid(index)}>
              <div>{index + 1}</div>
              {props.tableSubHeaders.map((value, ind) => (
                <div className={Styles.subTablePositionLeft} key={uid(ind)}>
                  {datum[value.val]}
                </div>
              ))}
              <div className={Styles.subTablePositionTwo}>
                {props.hideSubEdit && (
                  <EditAction
                    editLink={props.createSubLink}
                    id={datum.id}
                    fromRow={props.passSubEditFromRow}
                    data={props.passSubEditFromRow && setEditObject(datum)}
                    editable={datum?.editable ? true : false}
                    activePage={activePage}
                    itemsPerPage={itemsPerPage}
                  />
                )}
                {props.hideSubDelete && (
                  <DeleteAction
                    delete={() => {
                      props.deleteSubDatum(datum.id);
                    }}
                  />
                )}
              </div>
            </div>
          </>
        ))}
      </div>
    </>
  );
};

export default SubTable;
