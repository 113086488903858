import { Dialog, DialogTitle, DialogContent } from "@material-ui/core";
import Select from "react-select";
import { TervButtonFilled } from "components/TervButtons";
import { CloseOutlined } from "@material-ui/icons";
import styles from "../CustomerManagement/BulkUserUpload.module.scss";

const DefaultSolutions = ({
  isOpen,
  onClose,
  languages,
  setLanguageSolution,
  setLanguage,
  add,
  saveDisabled,
  editValue,
  editorState,
}) => {
  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle classes={{ root: styles.header }}>
        Add default solution
        <CloseOutlined
          classes={{ root: styles.closeButton }}
          onClick={onClose}
        />
      </DialogTitle>
      <DialogContent>
        {editValue ? (
          <h5>{editorState.lang.label}</h5>
        ) : (
          <Select options={languages} onChange={setLanguage} />
        )}
        <textarea
          className="form-control"
          onChange={(e) => setLanguageSolution(e.target.value)}
          value={editorState.val}
          style={{ minHeight: "20rem", margin: "0.625rem 0" }}
        />
      </DialogContent>
      <div
        style={{
          display: "grid",
          placeItems: "center",
          marginBottom: "0.625rem",
        }}
      >
        <TervButtonFilled text="Save" disabled={saveDisabled} onClick={add} />
      </div>
    </Dialog>
  );
};

export default DefaultSolutions;
