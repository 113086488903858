import Tooltip from "@material-ui/core/Tooltip";
import * as CustomIcons from "../assets/icons/icons";
import { useHistory } from "react-router-dom";

const buttonStyle = {
  width: 40,
  height: 35,
  border: "1px solid #784df4",
  textAlign: "center",
  borderRadius: 5,
  padding: 6,
  color: "#784df4",
  cursor: "pointer",
};

export const CardButtons = ({
  showEdit,
  showDelete,
  editHistory,
  openReport,
  handleEdit,
  handleDelete,
}) => {
  const history = useHistory();
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        gap: 10,
      }}
    >
      {showEdit && (
        <Tooltip title="Edit" arrow placement="top-start">
          <div style={buttonStyle} onClick={() => history.push(editHistory)}>
            {CustomIcons.penIcon("#784df4")}
          </div>
        </Tooltip>
      )}
      {showDelete && (
        <Tooltip placement="top-start" arrow title="Delete">
          <div style={buttonStyle} onClick={handleDelete}>
            {CustomIcons.trashFilled}
          </div>
        </Tooltip>
      )}
      {openReport && (
        <Tooltip placement="top-start" arrow title="Report">
          <div style={buttonStyle} onClick={openReport}>
            {CustomIcons.fileReportOne}
          </div>
        </Tooltip>
      )}
    </div>
  );
};
