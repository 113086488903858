import React, { useEffect, useState } from "react";
import { Col, Row, Label, Button } from "reactstrap";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import moment from "moment";
import { Link } from "react-router-dom";
import api from "../../api/baseConfig";
import ApiHelper from "../../api/apiHelper";
import { emailRegex } from "pages/utils/constants";
import { makeStyles } from "@material-ui/core/styles";
import cn from "classnames";
import { Checkbox, FormControlLabel, Paper } from "@material-ui/core";
import { FormLayout } from "components/FormLayout";
import { RequiredIndicator } from "pages/utils/RequiredIndicator";
import { GetObjectFromString } from "pages/utils/GetObjectFromString";
import { AddImageComponent } from "components/AddImageComponent";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { useLogoContext} from "context/logoContext";

const customStyles = {
  control: (styles) => ({
    ...styles,
    backgroundColor: "#FAFBFC",
  }),
};

const useStyles = makeStyles({
  root: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  icon: {
    borderRadius: 3,
    width: 20,
    height: 20,
    boxShadow:
      "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: "#f5f8fa",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    "$root.Mui-focusVisible &": {
      outline: "2px auto rgba(19,124,189,.6)",
      outlineOffset: 2,
    },
    "input:hover ~ &": {
      backgroundColor: "#ebf1f5",
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "rgba(206,217,224,.5)",
    },
  },
  checkedIcon: {
    backgroundColor: "#794DF5",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 20,
      height: 20,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fillRule='evenodd' clipRule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""',
    },
    "input:hover ~ &": {
      backgroundColor: "#6A5ACD",
    },
  },
});

// const EntitlementCards = ({
//   input: { onChange, value, name, control },
//   label,
//   uniqueId,
// }) => (
//   <div>
//     <Controller
//       name={name}
//       control={control}
//       render={({ field }) => (
//         <FormControlLabel
//           control={
//             <Checkbox
//               color="primary"
//               checked={value}
//               onChange={(e, c) => onChange(c)}
//             />
//           }
//           label={label}
//           key={uniqueId}
//         />
//       )}
//     />
//   </div>
// );

export default function CustomerManagementDumb({
  states,
  logo,
  setLogo,
  id,
  existingData,
  setExistingData,
  createCustomer,
  updateCustomer,
  viewOnly,
  checkIfEmailExists,
  checkIfNameExists,
  activePage,
  itemsPerPage,
}) {
  const {
    handleSubmit,
    control,
    register,
    watch,
    reset,
    formState: { errors },
  } = useForm({ mode: "onBlur" });
  const customer = useLogoContext();
  const getCustomerById = async (id) => {
    try {
      const data = await ApiHelper.axiosCallPost(
        `${api.baseURL}${api.customerManagement.get}${id}`,
        "get"
      );
      setExistingData(data);
      if (data?.logo) {
        setLogo(data.logo);
      }
      reset({
        name: data?.name || "",
        code: data?.code || "",
        emailId: data?.emailId || "",
        phoneNumber: data?.phoneNumber || "",
        startDate: data?.startDate || "",
        endDate: data?.endDate || "",
        description: data?.description || "",
        address: data?.address || "",
        state: data?.state || "",
        pincode: data?.pincode || "",
        prepare: data?.entitlement?.prepare || "",
        practice: data?.entitlement?.practice || "",
        assessment: data?.entitlement?.assessment || "",
        resumeBuilder: data?.entitlement?.resumeBuilder || "",
      });
    } catch (e) {}
  };

  const classes = useStyles();

  useEffect(() => {
    window.scrollTo(0, 0);
    if (id) {
      getCustomerById(id);
    }
  }, [reset]);

  return (
    <div className="page-content" style={{ marginBottom: 20 }}>
      <Breadcrumbs title="Customers" breadcrumbItem="Create Customer" />
      <Paper style={{ padding: 20, border: "1px solid #f2f1f3" }} elevation={0}>
        <form onSubmit={handleSubmit(id ? updateCustomer : createCustomer)}>
          <FormLayout
            title="Customer Details"
            description="Enter the necessary details"
          >
            <Row className="mb-2">
              <Col lg={6}>
                <Label for="name">{RequiredIndicator("Customer Name")}</Label>
                <input
                  className="form-control"
                  {...register("name", {
                    required: "Please enter a valid name",
                    validate: async (value) => {
                      if (value.replace(/<(.|\n)*?>/g, "").trim().length < 3)
                      return ("Customer name should be minimum 3 characters");
                      if (value.replace(/<(.|\n)*?>/g, "").trim().length > 40)
                      return ("Customer name should be maximum 40 characters");
                      if (value === existingData?.name) return true;
                      const encodedName = window.btoa(value);
                      const booo = await checkIfNameExists(encodedName);
                      return (
                        !booo ||
                        "This name is already in use, please enter a different name"
                      );
                    },
                  })}
                  onChange={(e)=>{
                    e.target.value = `${e.target.value.replace(/\s\s+/g, ` `)}`;
                  }}
                  disabled={viewOnly}
                />

                {errors.name && (
                  <span style={{ color: "red" }}>{errors.name.message}</span>
                )}
              </Col>
              <Col lg={6}>
                <Label for="code">Code</Label>
                <input
                  className="form-control"
                  {...register("code")}
                  disabled={viewOnly}
                />
              </Col>
            </Row>

            <Row className="mb-2">
              <Col lg={6}>
                <Label for="emailId">{RequiredIndicator("Email")}</Label>
                <input
                  className="form-control"
                  {...register("emailId", {
                    required: "Please enter a valid email",
                    pattern: {
                      value: emailRegex,
                      message: "Please enter a valid email",
                    },
                    validate: async (value) => {
                      if (value === existingData?.emailId) return true;
                      const boo = await checkIfEmailExists(value);
                      return (
                        !boo ||
                        "This email is already in use. Please enter a different email"
                      );
                    },
                  })}
                  disabled={viewOnly}
                />

                {errors.emailId && (
                  <span style={{ color: "red" }}>{errors.emailId.message}</span>
                )}
              </Col>
              <Col lg={6}>
                <Label for="phoneNumber">
                  {RequiredIndicator("Phone Number")}
                </Label>
                <input
                  className="form-control"
                  maxLength="10"
                  {...register("phoneNumber", {
                    required: "Please enter a valid phone number",
                    pattern: {
                      value: /^[0-9]*$/g,
                      message: "Please enter a valid phone number",
                    },
                  })}
                  disabled={viewOnly}
                />

                {errors.phoneNumber && (
                  <span style={{ color: "red" }}>
                    {errors.phoneNumber.message}
                  </span>
                )}
              </Col>
            </Row>
            <Row className="mb-2">
              <Col lg={6}>
                <Label for="startDate">
                  {RequiredIndicator("Start Date and Time")}
                </Label>
                <input
                  className="form-control"
                  {...register("startDate", {
                    required: "Please enter a valid start date",
                  })}
                  type="datetime-local"
                  disabled={viewOnly}
                />
                {errors.startDate && (
                  <span style={{ color: "red" }}>
                    {errors.startDate.message}
                  </span>
                )}
              </Col>
              <Col lg={6}>
                <Label for="endDate">
                  {RequiredIndicator("End Date and Time")}
                </Label>
                <input
                  className="form-control"
                  {...register("endDate", {
                    required: "Please enter a valid end date",
                    validate: (value) =>
                      moment(value).diff(
                        moment(watch("startDate")),
                        "minutes"
                      ) > 0 || "End date cannot be before the Start Date",
                  })}
                  type="datetime-local"
                  disabled={viewOnly}
                />
                {errors.endDate && (
                  <span style={{ color: "red" }}>{errors.endDate.message}</span>
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                <Label for="description">Description</Label>
                <textarea
                  className="form-control"
                  {...register("description")}
                  disabled={viewOnly}
                />
              </Col>
            </Row>
          </FormLayout>

          <FormLayout title="Customer Address" description="Enter  address">
            <Row className="mb-2">
              <Col lg={12}>
                <Label for="address">{RequiredIndicator("Address")}</Label>
                <textarea
                  className="form-control"
                  {...register("address", {
                    required: "Please enter a valid Address",
                  })}
                  type="textarea"
                  disabled={viewOnly}
                />

                {errors.address && (
                  <span style={{ color: "red" }}>{errors.address.message}</span>
                )}
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <Label>{RequiredIndicator("State")}</Label>
                <Controller
                  name="state"
                  control={control}
                  rules={{ required: "Please select a State" }}
                  render={({ field: { onChange, value } }) => (
                    <Select
                      onChange={(val) => onChange(val.value)}
                      value={GetObjectFromString(states, value)}
                      options={states}
                      styles={customStyles}
                      isDisabled={viewOnly}
                    />
                  )}
                />
                {errors.state && (
                  <span style={{ color: "red" }}>{errors.state.message}</span>
                )}
              </Col>
              <Col>
                <Label for="pincode">{RequiredIndicator("Pincode")}</Label>
                <input
                  className="form-control"
                  {...register("pincode", {
                    required: "Please enter a valid pincode",
                    pattern: {
                      value: /^[0-9]*$/g,
                      message: "Please enter a valid pincode",
                    },
                  })}
                  disabled={viewOnly}
                />

                {errors.pincode && (
                  <span style={{ color: "red" }}>{errors.pincode.message}</span>
                )}
              </Col>
            </Row>
          </FormLayout>

          <FormLayout title="Logo">
            <AddImageComponent
              handleCroppedImage={setLogo}
              media={
                logo.includes("default")
                  ? `https://d2ndap9rlps54r.cloudfront.net/DefaultUploadImages/${logo}.jpeg`
                  : logo
              }
              moduleName={"customerManagement"}
              customerName={watch("name")}
              viewOnly={viewOnly}
            />
          </FormLayout>

          <FormLayout
            title="Customer Entitlement"
            description={`Select the entitlements which customer will access in ${customer?.productName}`}
          >
            <Row>
              <fieldset disabled={viewOnly}>
                <Col lg={12}>
                  <div
                    className="container-prepareCourses"
                    style={{
                      display: "flex",
                      alignItems: "left",
                      justifyContent: "left",
                      backgroundColor: "#F2F1F3",
                      width: "470px",
                      height: "98px",
                      borderRadius: "5px",
                      padding: "10px",
                    }}
                  >
                    <div style={{ width: "40px" }}>
                      <Controller
                        name="prepare"
                        control={control}
                        defaultValue={false}
                        render={({ field: { onChange, value } }) => (
                          <Checkbox
                            className={classes.root}
                            checked={value}
                            checkedIcon={
                              <span
                                className={cn(
                                  classes.icon,
                                  classes.checkedIcon
                                )}
                              />
                            }
                            color="default"
                            onChange={(event, checked) => onChange(checked)}
                          />
                        )}
                      />
                    </div>
                    <div style={{ marginTop: "8px" }}>
                      <p
                        style={{
                          fontWeight: 500,
                          fontFamily: "Poppins",
                          fontSize: "16px",
                          lineHeight: "26px",
                          color: "323036",
                        }}
                      >
                        Prepare courses
                      </p>
                      <p
                        style={{
                          fontWeight: "normal",
                          fontStyle: "normal",
                          fontFamily: "Poppins",
                          fontSize: "14px",
                          lineHeight: "23px",
                          color: "#63606c",
                        }}
                      >
                        Customer can access all the prepare content
                      </p>
                    </div>
                  </div>
                </Col>
                <Col lg={12}>
                  <div
                    className="container-practicePackages"
                    style={{
                      display: "flex",
                      alignItems: "left",
                      justifyContent: "left",
                      backgroundColor: "#F2F1F3",
                      width: "470px",
                      height: "98px",
                      borderRadius: "5px",
                      padding: "10px",
                      marginTop: "20px",
                    }}
                  >
                    <div style={{ width: "40px" }}>
                      <Controller
                        name="practice"
                        control={control}
                        defaultValue={false}
                        render={({ field: { onChange, value } }) => (
                          <Checkbox
                            className={classes.root}
                            checked={value}
                            color="default"
                            checkedIcon={
                              <span
                                className={cn(
                                  classes.icon,
                                  classes.checkedIcon
                                )}
                              />
                            }
                            onChange={(event, checked) => onChange(checked)}
                          />
                        )}
                      />
                    </div>
                    <div style={{ marginTop: "8px" }}>
                      <p
                        style={{
                          fontWeight: 500,
                          fontFamily: "Poppins",
                          fontSize: "16px",
                          lineHeight: "26px",
                          color: "323036",
                        }}
                      >
                        Practice packages
                      </p>
                      <p
                        style={{
                          fontWeight: "normal",
                          fontStyle: "normal",
                          fontFamily: "Poppins",
                          fontSize: "14px",
                          lineHeight: "23px",
                          color: "#63606c",
                        }}
                      >
                        Customer can access all the practice packages content
                      </p>
                    </div>
                  </div>
                </Col>
                <Col lg={12}>
                  <div
                    className="container-performAssessments"
                    style={{
                      display: "flex",
                      alignItems: "left",
                      justifyContent: "left",
                      backgroundColor: "#F2F1F3",
                      width: "470px",
                      height: "98px",
                      borderRadius: "5px",
                      padding: "10px",
                      marginTop: "20px",
                    }}
                  >
                    <div style={{ width: "40px" }}>
                      <Controller
                        name="assessment"
                        control={control}
                        defaultValue={false}
                        render={({ field: { onChange, value } }) => (
                          <Checkbox
                            className={classes.root}
                            checked={value}
                            color="default"
                            checkedIcon={
                              <span
                                className={cn(
                                  classes.icon,
                                  classes.checkedIcon
                                )}
                              />
                            }
                            onChange={(event, checked) => onChange(checked)}
                          />
                        )}
                      />
                    </div>
                    <div style={{ marginTop: "8px" }}>
                      <p
                        style={{
                          fontWeight: 500,
                          fontFamily: "Poppins",
                          fontSize: "16px",
                          lineHeight: "26px",
                          color: "323036",
                        }}
                      >
                        Perform (assessments)
                      </p>
                      <p
                        style={{
                          fontWeight: "normal",
                          fontStyle: "normal",
                          fontFamily: "Poppins",
                          fontSize: "14px",
                          lineHeight: "23px",
                          color: "#63606c",
                        }}
                      >
                        {`Customer can access and conduct assessment in ${customer?.productName}`}
                      </p>
                    </div>
                  </div>
                </Col>
                <Col lg={12}>
                  <div
                    className="container-resumeBuilder"
                    style={{
                      display: "flex",
                      alignItems: "left",
                      justifyContent: "left",
                      backgroundColor: "#F2F1F3",
                      width: "470px",
                      height: "98px",
                      borderRadius: "5px",
                      padding: "10px",
                      marginTop: "20px",
                    }}
                  >
                    <div style={{ width: "40px" }}>
                      <Controller
                        name="resumeBuilder"
                        control={control}
                        defaultValue={false}
                        render={({ field: { onChange, value } }) => (
                          <Checkbox
                            className={classes.root}
                            checked={value}
                            color="default"
                            checkedIcon={
                              <span
                                className={cn(
                                  classes.icon,
                                  classes.checkedIcon
                                )}
                              />
                            }
                            onChange={(event, checked) => onChange(checked)}
                          />
                        )}
                      />
                    </div>
                    <div style={{ marginTop: "8px" }}>
                      <p
                        style={{
                          fontWeight: 500,
                          fontFamily: "Poppins",
                          fontSize: "16px",
                          lineHeight: "26px",
                          color: "323036",
                        }}
                      >
                        Resume builder
                      </p>
                      <p
                        style={{
                          fontWeight: "normal",
                          fontStyle: "normal",
                          fontFamily: "Poppins",
                          fontSize: "14px",
                          lineHeight: "23px",
                          color: "#63606c",
                        }}
                      >
                        {`Customer can access resume builder in ${customer?.productName}`}
                      </p>
                    </div>
                  </div>
                </Col>
              </fieldset>
            </Row>
          </FormLayout>
          {!viewOnly && (
            <FormLayout hideDivider>
              <Button
                color="primary"
                type="submit"
                style={{ fontSize: 16, width: "9rem" }}
              >
                Submit
              </Button>
            </FormLayout>
          )}
        </form>
      </Paper>
      <Link
        to={{
          pathname: "/customerList",
          state: {
            activePage: activePage,
            itemsPerPage: itemsPerPage,
          },
        }}
        className="terv-back"
      >
        <i className="mdi mdi-arrow-left mr-1" /> Back to Customer List{" "}
      </Link>
    </div>
  );
}
