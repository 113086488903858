import { SET_ALL_STUDENTS, SET_ALL_CUSTOMERS } from "./actionTypes";

export default function DataReducer(state = {}, action) {
  switch (action.type) {
    case SET_ALL_STUDENTS: {
      return {
        ...state,
        UserStudents: action.payload,
      };
    }
    case SET_ALL_CUSTOMERS: {
      return {
        ...state,
        Customers: action.payload,
      };
    }
    default: {
      return state;
    }
  }
}
