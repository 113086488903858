import { useState, useEffect } from "react";
import pt from "prop-types";
import cn from "classnames";
import axios from "axios";
import {
  Button,
  Collapse,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Grow,
  LinearProgress,
  Link,
  Table,
  TableBody as MuiTableBody,
  TableCell as MuiTableCell,
  TableContainer,
  TableHead as MuiTableHead,
  TableRow,
  Typography,
  withStyles,
  Zoom,
} from "@material-ui/core";
import { CloseOutlined } from "@material-ui/icons";
import { DropzoneArea } from "material-ui-dropzone";
import Api from "../../api/apiHelper";
import ApiList from "../../api/baseConfig";
import * as CustomIcons from "../../assets/icons/icons";
import styles from "./BulkQuestionUpload.module.scss";
import { TrimString } from "pages/utils/TrimString";
import { sessionPrivileges } from "privileges";

const ProgressBar = withStyles({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: "#DEDEDE",
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "#51B960",
  },
})(LinearProgress);

const CancelButton = withStyles({
  root: {
    borderColor: "#794DF5",
    color: "#794DF5",
    textTransform: "capitalize",
    transitionDuration: 0,
    "&:hover": {
      backgroundColor: `#794DF5 !important`,
      color: "#fff",
    },
  },
})(Button);

const TableHead = withStyles({
  root: {
    backgroundColor: `#C5EAFC !important`,
    fontWeight: "bold",
    "& th": {
      borderBottom: "none !important",
    },
  },
})(MuiTableHead);

const TableBody = withStyles({
  root: {
    "& tr:nth-child(even)": {
      backgroundColor: "#F5FCFF !important",
    },
  },
})(MuiTableBody);

const TableCell = withStyles({
  root: {
    whiteSpace: "nowrap",
    borderBottom: "none !important",
  },
})(MuiTableCell);

function TabButton({ isActive, onClick, title, value }) {
  return (
    <button
      className={cn(styles.tabButton, { [styles.active]: isActive })}
      onClick={onClick}
    >
      <span className={styles.tabButtonTitle}>{title}</span>
      <span className={styles.tabButtonValue}>{value}</span>
    </button>
  );
}

export default function BulkQuestionUpload({
  onComplete,
  questionBankId,
  isOpen,
  onClose,
}) {
  const userId = sessionPrivileges().userId;
  const [file, setFile] = useState();
  const [uploadCancelTokenSource, setUploadCancelTokenSource] = useState();
  const [uploadedDataCount, setUploadedDataCount] = useState(null);
  const [uploadedFailedDataCount, setUploadedFailedDataCount] = useState(null);
  const [uploadedData, setUploadedData] = useState([]);
  const [uploadedDataTab, setUploadedDataTab] = useState("success");
  const [uploadId, setUploadId] = useState();
  const [uploadPercentage, setUploadPercentage] = useState(0);

  useEffect(() => {
    if (file) {
      const formData = new FormData();

      setUploadCancelTokenSource(axios.CancelToken.source());
      formData.append("file", file);
      const url = TrimString(
        `${ApiList.baseURL}${ApiList.questionBankController.bulkUploadQuestions}${questionBankId}?userId=${userId}`
      );
      Api.uploadFile(url, formData, {
        cancelToken: uploadCancelTokenSource?.token,
        onUploadProgress: (progressEvent) => {
          setUploadPercentage(
            Math.round((progressEvent.loaded * 100) / progressEvent.total)
          );
        },
      })
        .then((data) => {
          setUploadId(data.data?.message.split("Id: ")[1]);
        })
        .catch(() => {});
    }
  }, [file]);

  useEffect(() => {
    if (uploadId) {
      Api.axiosCall(
        `${ApiList.baseURL}${ApiList.customerManagement.uploadStatus(
          uploadId
        )}`,
        "get"
      )
        .then((data) => {
          if (data.failedRecords?.length) {
            setUploadedData(
              data.failedRecords.map(({ name, complexity, type, reason }) => [
                name,
                type,
                complexity,
                false,
                reason,
              ])
            );
          }

          setUploadedDataCount(data?.totalRecordsCount || 0);
          setUploadedFailedDataCount(data?.failedRecordsCount || 0);

          if (onComplete) {
            onComplete();
          }
        })
        .catch(() => {});
    }
  }, [uploadId]);

  function close(closeComponent = true) {
    if (closeComponent) {
      onClose();
    }

    setFile(undefined);
    setUploadCancelTokenSource(null);
    setUploadedData([]);
    setUploadId(null);
    setUploadPercentage(0);
  }

  return (
    <Dialog
      classes={{ root: styles.modal, paper: styles.modalContent }}
      onClose={close}
      open={isOpen}
      scroll="paper"
      TransitionComponent={Grow}
      TransitionProps={{ unmountOnExit: true }}
    >
      <DialogTitle classes={{ root: styles.header }}>
        <>Bulk Upload Questions</>
        <CloseOutlined classes={{ root: styles.closeButton }} onClick={close} />
      </DialogTitle>
      <DialogContent classes={{ root: styles.dialogContent }}>
        <Collapse in={!file}>
          <Zoom in={!file}>
            <div>
              <Grid
                alignItems="center"
                classes={{ root: styles.uploadHeader }}
                container
                direction="row"
                justifyContent="space-between"
              >
                <Typography variant="body2">Select DOCX file:</Typography>
                <span>
                  <Grid
                    alignItems="center"
                    container
                    wrap="nowrap"
                    spacing={1}
                    classes={{ root: styles.links }}
                    direction="row"
                  >
                    <Link
                      component="a"
                      download="Sample Bulk Coding Question File.docx"
                      href={`${process.env.PUBLIC_URL}/samples/bulk-coding-question-upload-sample.docx`}
                      variant="body2"
                    >
                      <CustomIcons.DownloadIcon size={16} />
                      <span style={{ marginInlineStart: 4 }}>
                        Coding Sample
                      </span>
                    </Link>
                    <Link
                      component="a"
                      download="Sample Bulk Aptitude Question File.docx"
                      href={`${process.env.PUBLIC_URL}/samples/bulk-aptitude-question-upload-sample.docx`}
                      variant="body2"
                    >
                      <CustomIcons.DownloadIcon size={16} />
                      <span style={{ marginInlineStart: 4 }}>
                        Aptitude Sample
                      </span>
                    </Link>
                  </Grid>
                </span>
              </Grid>
              <DropzoneArea
                acceptedFiles={[".docx", ".doc"]}
                classes={{ root: styles.uploadButton }}
                dropzoneText={
                  <>
                    <div
                      style={{
                        color: "#A6A6AA",
                        fontSize: 18,
                        padding: "16px 0 28px 0",
                      }}
                    >
                      <div>{"Drag and drop a docx / doc file here"}</div>
                      <br />
                      <div>
                        {"---"}
                        &nbsp;&nbsp;&nbsp;
                        {"or"}
                        &nbsp;&nbsp;&nbsp;
                        {"---"}
                      </div>
                    </div>
                    <button
                      style={{
                        background: "transparent",
                        border: "1px solid #51B960",
                        borderRadius: 3,
                        color: "#51B960",
                        fontSize: 16,
                        fontWeight: 600,
                        padding: "6px 24px",
                        textTransform: "capitalize",
                      }}
                    >
                      {CustomIcons.uploadIcon}
                      &nbsp;
                      {"Upload"}
                    </button>
                  </>
                }
                filesLimit={1}
                maxFileSize={10000000}
                onChange={(files) => {
                  setFile(files?.[0]);
                }}
                showPreviewsInDropzone={false}
              />
              <br />
            </div>
          </Zoom>
        </Collapse>
        <Collapse in={!!file}>
          <Zoom in={!!file}>
            <div>
              <Collapse in={!uploadId}>
                <Zoom in={!uploadId}>
                  <div style={{ padding: "16px 4px" }}>
                    <Typography
                      noWrap
                      style={{ width: "100%", display: "inline-block" }}
                      variant="body1"
                    >
                      {"Uploading "}
                      {file?.name}
                    </Typography>
                    <Grid
                      alignItems="center"
                      container
                      direction="row"
                      justifyContent="space-between"
                    >
                      <div style={{ flexGrow: 1 }}>
                        <ProgressBar
                          variant="determinate"
                          value={uploadPercentage}
                        />
                      </div>
                      <Typography
                        style={{ margin: "0 24px 0 16px" }}
                        variant="body1"
                      >
                        {uploadPercentage}%
                      </Typography>
                      <CancelButton
                        onClick={() => {
                          if (uploadCancelTokenSource) {
                            uploadCancelTokenSource.cancel();

                            close(false);
                          }
                        }}
                        size="small"
                        variant="outlined"
                      >
                        {"Cancel"}
                      </CancelButton>
                    </Grid>
                  </div>
                </Zoom>
              </Collapse>
              <Collapse in={!!uploadId}>
                <Zoom in={!!uploadId}>
                  <div>
                    {uploadedFailedDataCount === 0 && (
                      <>
                        <Grid
                          classes={{ root: styles.successNoteContainer }}
                          container
                          direction="row"
                          justifyContent="center"
                        >
                          <div className={styles.successNote}>
                            <CustomIcons.TickIcon size={20} />
                            <span>Questions uploaded successfully</span>
                          </div>
                        </Grid>
                        <br />
                      </>
                    )}
                    <div className={styles.tabs}>
                      <TabButton
                        isActive={uploadedDataTab === "success"}
                        onClick={() => {
                          // setUploadedDataTab("success");
                        }}
                        title="Success"
                        value={uploadedDataCount - uploadedFailedDataCount || 0}
                      />
                      <TabButton
                        isActive={uploadedDataTab === "error"}
                        onClick={() => {
                          // setUploadedDataTab("error");
                        }}
                        title="Error"
                        value={uploadedFailedDataCount || 0}
                      />
                    </div>
                    <br />
                  </div>
                </Zoom>
              </Collapse>
            </div>
          </Zoom>
        </Collapse>
      </DialogContent>
      <DialogContent classes={{ root: styles.tableContainer }}>
        <Collapse in={!!uploadId}>
          <div>
            {!!(uploadedFailedDataCount && uploadedFailedDataCount !== 0) && (
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>#</TableCell>
                      <TableCell>Question Name</TableCell>
                      <TableCell>Type</TableCell>
                      <TableCell>Complexity</TableCell>
                      <TableCell>Status</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {uploadedData
                      .filter((ud) => ud)
                      .map((ud, idx) => (
                        <TableRow key={idx}>
                          <TableCell>{idx + 1}</TableCell>
                          <TableCell style={{ textTransform: "capitalize" }}>
                            {ud[0]}
                          </TableCell>
                          <TableCell style={{ textTransform: "capitalize" }}>
                            {ud[1] === "CD"
                              ? "Coding"
                              : ud[1] === "MC"
                              ? "Aptitude"
                              : ""}
                          </TableCell>
                          <TableCell style={{ textTransform: "capitalize" }}>
                            {ud[2]}
                          </TableCell>
                          <TableCell>
                            <Grid
                              alignItems="center"
                              wrap="nowrap"
                              container
                              direction="row"
                              className={styles.tableCell}
                            >
                              {ud[3] ? (
                                <>
                                  <CustomIcons.TickIcon size={18} />
                                  &nbsp;&nbsp;
                                  {"Completed"}
                                </>
                              ) : (
                                <>
                                  <CustomIcons.ErrorIcon size={18} />
                                  &nbsp;&nbsp;
                                  {ud[4]}
                                </>
                              )}
                            </Grid>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </div>
        </Collapse>
      </DialogContent>
    </Dialog>
  );
}
BulkQuestionUpload.propTypes = {
  customerId: pt.string.isRequired,
  isOpen: pt.bool.isRequired,
  onClose: pt.func.isRequired,
};
