export default () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none">
    <rect width="32" height="32" rx="5" fill="#1F50CC" />
    <path d="M17.6668 7.66669H11.0002C10.5581 7.66669 10.1342 7.84228 9.82165 8.15484C9.50909 8.4674 9.3335 8.89133 9.3335 9.33335V22.6667C9.3335 23.1087 9.50909 23.5326 9.82165 23.8452C10.1342 24.1578 10.5581 24.3334 11.0002 24.3334H21.0002C21.4422 24.3334 21.8661 24.1578 22.1787 23.8452C22.4912 23.5326 22.6668 23.1087 22.6668 22.6667V12.6667L17.6668 7.66669Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M17.6665 7.66669V12.6667H22.6665" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M19.3332 16.8333H12.6665" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M19.3332 20.1667H12.6665" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M14.3332 13.5H13.4998H12.6665" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)
