export const validateStudentData = (studentData) => {
  let errors = {};
  let canSubmit = true;
  if (!studentData.filterType) {
    canSubmit = false;
    errors["filterType"] = "Please choose a valid filter";
  }
  if (studentData.filterType && studentData.filterType === "custom_batch") {
    if (!studentData.batchName) {
      canSubmit = false;
      errors["batchName"] = "Please enter a Batch name";
    }
    if (!studentData.department) {
      errors["department"] = "Please choose a valid Department";
    }
  }
  if (
    studentData.filterType !== "all" &&
    (!studentData.selectedStudents || studentData.selectedStudents.length === 0)
  ) {
    canSubmit = false;
    errors["selectedStudents"] = "Please choose atleast one Student";
  }
  return { errors, canSubmit };
};
