import useSWR from "swr";
import Apihelper from "../../../api/apiHelper";
import api from "../../../api/baseConfig";
import Cookies from "universal-cookie";

export const useGetAssessments = (
  status: string,
  shouldFetch: boolean,
  userId: string
) => {
  const cookies = new Cookies();
  const { data, error, isValidating } = useSWR(
    [
      shouldFetch
        ? status
          ? `${api.baseURL}${api.userManagement.getUserAssessment}${userId}?status=${status}`
          : `${api.baseURL}${api.assessmentController.getPublicAssessments}`
        : null,
      cookies.get("userToken"),
    ],
    Apihelper.axiosCallSWR,
    { dedupingInterval: 60000, refreshInterval: 60000 }
  );

  return error ? `Error occured ${error}` : isValidating ? "Fetching..." : data;
};
