import { useState, useEffect, forwardRef } from "react";
import Container from "@material-ui/core/Container";
import Divider from "@material-ui/core/Divider";
import CourseCard from "./course-card";
import { GetItemWithMedia } from "pages/utils/GetItemWithMedia";
import apiHelper from "../../../api/apiHelper";
import api from "../../../api/baseConfig";
import "./hackathons.css";
import PrepareHeroCard from "./prepareHeroCard";
import { useMediaQuery } from "@material-ui/core";
import { Tabs } from "../../../components/tabs/tabsStudent";
import { MobileTabs } from "../../../components/tabs/tabMobile";

const TabScrollButton = forwardRef((props, ref) => {
  const { direction, ...other } = props;

  return (
    <ButtonBase
      component="div"
      ref={ref}
      style={{ opacity: other.disabled ? 0 : 1 }}
      {...other}
    >
      {direction === "left" ? (
        <ArrowBackIosIcon
          fontSize="small"
          className="arrow-icons"
          style={{ fill: "black" }}
        />
      ) : (
        <ArrowForwardIosIcon
          fontSize="small"
          className="arrow-icons"
          style={{ fill: "black" }}
        />
      )}
    </ButtonBase>
  );
});

function Prepare(props) {
  const [searchTerm, setSearchTerm] = useState("");
  const [courseSearch, setCourseSearch] = useState("");
  const [topPackages, setTopPackages] = useState([]);
  const [exploreCourse, setExploreCourse] = useState(false);
  const [activeTab, setActiveTab] = useState("YourHackathon");

  const smallScreen = useMediaQuery("(max-width: 767px)");
  useEffect(() => {
    getTopPackages();
  }, []);

  const getTopPackages = async () => {
    try {
      const data = await apiHelper.axiosCall(
        `${api.baseURL}${api.Hackathon.getUserHackathon}`,
        "get"
      );
      if (data) {
        const newList = [];
        data.forEach((course) => {
          newList.push(GetItemWithMedia(course));
        });
        setTopPackages(newList);
      }
    } catch (e) {}
  };

  const handleChange = (e) => {
    setSearchTerm(e.target.value);
  };
  const handleExploreCourse = () => {
    setExploreCourse(!exploreCourse);
  };
  const handleCourseChange = (e) => {
    setCourseSearch(e.target.value);
    setActiveTab("All");
  };
  const registeredCount = topPackages.filter((e) => e.userRegistered).length;

  const tabList = [
    {
      name: "Your hackathon",
      badge: topPackages.filter((e) => e.userRegistered).length,
      id: "YourHackathon",
    },
    {
      name: "Live",
      badge: topPackages.filter((e) => e.status === "Live").length,
      id: "Live",
    },
    {
      name: "Upcoming",
      badge: topPackages.filter((e) => e.status === "Scheduled").length,
      id: "Upcoming",
    },
    {
      name: "Completed",
      // badge: topPackages.filter((e) => e.status === "Completed").filter((e) => e.userRegistered).length,
      badge: topPackages.filter((e) => e.status === "Completed").length,
      id: "Completed",
    },
    {
      name: "All",
      badge: topPackages.length,
      id: "All",
    },
  ];

  return (
    <div
      className={`pt-5 ${smallScreen ? "" : "prepare-body"}`}
      style={{ backgroundColor: "#F4F7FA" }}
    >
      <Container>
        {exploreCourse ? "" : <PrepareHeroCard />}
        <div className="continue-card">
          <div
            className={
              smallScreen ? "w-50 mb-4" : "mb-4 d-flex justify-content-between"
            }
          >
            <div className="mb-4 search-inputValue">
              <input
                type="search"
                placeholder="&#xf002; Search Hackathons...."
                className="SearchDesign"
                style={{
                  fontFamily: "FontAwesome, Poppins",
                  outline: "none",
                  width: "380px",
                }}
                value={courseSearch}
                onChange={handleCourseChange}
                autoComplete="off"
              />
            </div>
            <div></div>
          </div>
          {!smallScreen ? (
            <Tabs
              activeTab={activeTab}
              tabs={tabList}
              registerCount={registeredCount}
              onChange={setActiveTab}
              variant="scrollable"
              scrollButtons="auto"
              textColor="primary"
              // hideBadge={true}
              ScrollButtonComponent={TabScrollButton}
            />
          ) : (
            <MobileTabs
              activeTab={activeTab}
              tabs={tabList}
              registerCount={registeredCount}
              onChange={setActiveTab}
              textColor="primary"
            />
          )}
        </div>
        <div style={{ backgroundColor: "#ffffff" }}>
          <CourseCard
            ContinuePackages={topPackages}
            setExploreCourse={handleExploreCourse}
            exploreCourse={exploreCourse}
            courseSearch={courseSearch}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
        </div>
      </Container>
    </div>
  );
}

export default Prepare;
