/** @format */

import React from "react";
import styles from "./TandC.module.scss";
import { HomeContent } from "./HomeContent";
import { uid } from "react-uid";
import { Dot, Heart, IndianFlag } from "assets/icons/icons";
import { Link } from "react-router-dom";
import { useLogoContext } from "../../../context/logoContext";

interface Props {
  isMobile: boolean;
  isTab: boolean;
}

// const logoImage= useLogoContext();
const TandC: React.FC<Props> = ({ isMobile, isTab }) => {
  const logoImage= useLogoContext();
  return (
<div>
    <div className={styles.tandC}>
      <div className={styles.footer}>
        <div className={styles.flex}>
          <ul className={styles.list}>
            {HomeContent.Terms.titles.map(
              (ter: { label: string; to: string }, ind: number) => (
                <li key={uid(ind)}>
                  <Link className={styles.listItem} to={ter.to}>
                    {ter.label}
                  </Link>
                </li>
              ),
            )}
          </ul>
        </div>
        <div className={styles.flex}>
          <p className={styles.copyRight}>{HomeContent.Terms.desc}</p>
        </div>
      </div>
      {!isMobile &&
     (
      <div className={ styles.footerFlex}>
        {/* <img src="/terv-logo.png" alt="terv-logo" /> */}
        {logoImage && (
               <img
               src={
                 logoImage.productLogo
               }
             />
            )}
        <div className={styles.made}>
          <span>Made with</span>
          <Heart size="16" color="#f13030" />
          <span>in</span>
          <IndianFlag size={{ width: 24, height: 18 }} />
        </div>
      </div>
     )
}
    </div>
</div>
  );
};

export default TandC;
