import React from 'react';
import cn from 'classnames';
import { Icon } from '@iconify/react';
import badgeIcon from '@iconify/icons-cil/badge';
import styles from './LeaderboardCard.module.scss';

function LeaderboardCard({ adminSide, isUser, photo, name, college, place, questions, department, year, pts }) {
  return (
    <div
      className={cn(styles.card, {
        [styles.green]: adminSide && (place === 1),
        [styles.darkViolet]: adminSide && (place === 2),
        [styles.maroon]: adminSide && (place === 3),
        [styles.red]: !adminSide && (place === 1),
        [styles.violet]: !adminSide && (place === 2),
        [styles.blue]: !adminSide && (place === 3),
      })}
      style={{ ...isUser && { transform: 'scale(1.05)' } }}>
      <Icon
        className={styles.badge}
        icon={badgeIcon}
        style={{ color: '#fff', fontSize: 32 }}
      />
      <img
        className={styles.photo}
        src={photo}
      />
      <div className={styles.name}>{isUser ? 'You' : name}</div>
      {college && (
        <div className={styles.college}>{college}</div>
      )}
      {(department || year) && (
        <div className={styles.deptYear}>
          {department},
          &nbsp;
          {year}
        </div>
      )}
      {questions && (
        <div className={styles.questions}>{questions} questions</div>
      )}
      {pts && (
        <div className={styles.pts}>{pts} pts</div>
      )}
      <div className={styles.place}>
        {place}
        {(place === 1)
          ? 'st'
          : (place === 2)
            ? 'nd'
            : (place === 3)
              ? 'rd'
              : ''}
      </div>
    </div>
  );
}

export default LeaderboardCard;
