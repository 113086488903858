import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import "./CustomBatchDumb.scss";
import { RequiredIndicator } from "pages/utils/RequiredIndicator";
import { Link } from "react-router-dom";
import Select from "react-select";
import { GetObjectFromString } from "pages/utils/GetObjectFromString";
import { ReactSelectTervStyle } from "pages/utils/ReactSelectTervStyle";
import StyledNumberInput from "components/StyledNumberInput";
import CustomSelect from "components/CustomSelect";
import { TervButtonFilled } from "./../../components/TervButtons";
import { GetObjectArray } from "pages/utils/GetObjectArray";
import { ErrorBlock } from "./../../components/ErrorBlock";
import { StudentFilter } from "./../utils/StudentFilter";

const CustomBatchDumb = ({
  checkDuplicate,
  createBatch,
  updateBatch,
  adminStudents,
  students,
  departments,
  customerStudents,
  customer,
  editData,
  activePage,
  itemsPerPage,
  addFilterBy,
  assessments,
  packages,
  filterByPercentage,
  getStudentsByAssessment,
  getStudentsByPractice,
  getStudentsByCustomer,
  studentFilter,
  customerId
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    watch,
    control,
    reset,
    setValue,
  } = useForm({ mode: "onBlur" });

  const [filteredStudents, setFilteredStudents] = useState([]);

  useEffect(() => {
    if (editData?.edit || editData?.type === "viewOnly") {
      reset({
        batchName: editData?.row?.name || "",
        userIdList: editData?.row?.students || [],
        id: editData?.row?.id,
        addFilter: editData?.row?.filterBy || "", 
        selectAssessment: editData?.row?.byAssessment || "",
        selectPackage: editData?.row?.byPackage || "",
        department: editData?.row?.dept || "", 
        regNoFrom: editData?.row?.regFrom || "", 
        regNoTo: editData?.row?.regTo || "", 
      });
    }
  }, [reset]);

  useEffect(() => {
    if (watch("addFilter") === "none") {
      getStudentsByCustomer(customerId)
    } 
  }, [watch("addFilter")]);

  const getRightStudents = () => 
    students

  const regNoLength = () =>
    getRightStudents()[0] && getRightStudents()[0]["registrationNo"]
      ? getRightStudents()[0]["registrationNo"].length
      : "";

  const getFilteredStudents = (students, from, to) =>
    students.filter(
      (stu) => +stu.registrationNo >= from && +stu.registrationNo <= to
    );

  useEffect(() => {
    if (
      watch("regNoFrom") &&
      watch("regNoTo")
    ) {
      const filtered = getFilteredStudents(
        getRightStudents(),
        watch("regNoFrom"),
        watch("regNoTo")
      );
      setFilteredStudents(filtered);
    }
    if (
      watch("regNoFrom") === 0 &&
      watch("regNoTo") === 0
    ) {
      setFilteredStudents([]);
    }
  }, [watch("regNoFrom"), watch("regNoTo")]);

  return (
    <div className="page-content">
      <form
        onSubmit={handleSubmit(editData?.edit ? updateBatch : createBatch)}
        className="customBatch__form"
      >
        <h4 className="terv-form-header">
          {editData?.type === "viewOnly" ? "View" : editData?.edit ? "Update" : "Add"} Custom Batch
        </h4>
        <div className="terv-row terv-row-lg">
          <div>
            <label className="terv-label" htmlFor="batch_name">
              {RequiredIndicator("Batch Name ")}
            </label>
            <input
              id="batch_name"
              {...register("batchName", {
                required: "Please enter a valid batch name",
                validate: checkDuplicate,
              })}
              className="terv-form terv-form-smd"
              style={{ pointerEvents: editData?.type === "viewOnly" ? "none" : "" }}
            />
            {errors.batchName && (
              <div style={{ color: "red", maxWidth: 200 }}>
                {errors.batchName.message}
              </div>
            )}
          </div>
          <div
            style={{ pointerEvents: editData?.type === "viewOnly" ? "none" : "" }}
          >
            <label className="terv-label" htmlFor="batch_filter_by_dept">
              Filter by Department
            </label>
            <Controller
              name="department"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Select
                  inputId="batch_filter_by_dept"
                  options={departments}
                  onChange={(val) => {
                    onChange(val ? val.value : "");
                  }}
                  value={GetObjectFromString(departments, value)}
                  styles={ReactSelectTervStyle("smd")}
                  isClearable
                />
              )}
            />
          </div>
        </div>
        <div 
          className="terv-row terv-row-lg"
          style={{ pointerEvents: editData?.type === "viewOnly" ? "none" : "" }}
        >
          <div>
            <label className="terv-label" htmlFor="filterByRegNo">
              Filter by Reg. No
            </label>
            <div className="customBatch_RegFilter">
              <Controller
                name="regNoFrom"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <StyledNumberInput
                    onChange={onChange}
                    value={value || 0}
                    inputId="filterByRegNo"
                    className="terv-form-with-icon-sm"
                  />
                )}
              />
              <Controller
                name="regNoTo"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <StyledNumberInput
                    onChange={onChange}
                    value={value || 0}
                    inputId="filterByRegNo"
                    className="terv-form-with-icon-sm"
                  />
                )}
              />
            </div>
          </div>
          <div
            style={{ pointerEvents: editData?.type === "viewOnly" ? "none" : "" }}
          >
            <label className="terv-label" htmlFor="filterBy">
              Add Filter By
            </label>
            <Controller
              name="addFilter"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Select
                  inputId="filterBy"
                  options={addFilterBy}
                  onChange={(val) => {
                    onChange(val.value)
                  }}
                  value={GetObjectFromString(addFilterBy, value)}
                  styles={ReactSelectTervStyle("smd")}
                />
              )}
            />
          </div>
        </div>
        {watch("addFilter") === "assessment" && (
          <div className="terv-row terv-row-lg">
            <div>
              <label className="terv-label" htmlFor="filterByAssessment">
                Select Assessment
              </label>
              <Controller
                name="selectAssessment"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Select
                    inputId="filterByAssessment"
                    options={assessments}
                    onChange={(val) => {
                      onChange(val.value);
                      getStudentsByAssessment(val.value);
                    }}
                    value={GetObjectFromString(assessments, value)}
                    styles={ReactSelectTervStyle("smd")}
                  />
                )}
              />
            </div>
            {/* <div>
              <label className="terv-label" htmlFor="filterBy">
                Marks
              </label>
              <Controller
                name="selectMarks"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Select
                    inputId="filterMarks"
                    options={filterByPercentage}
                    onChange={(val) =>
                      onChange(val.value)
                    }
                    value={GetObjectFromString(filterByPercentage, value)}
                    styles={ReactSelectTervStyle("smd")}
                  />
                )}
              />
            </div> */}
          </div>
        )}
        {watch("addFilter") === "practicePackage" && (
          <div className="terv-row terv-row-lg">
            <div>
              <label className="terv-label" htmlFor="filterByPractice">
                Select Package
              </label>
              <Controller
                name="selectPackage"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Select
                    inputId="filterByPractice"
                    options={packages}
                    onChange={(val) =>{
                      onChange(val.value)
                      getStudentsByPractice(val.value)
                    }}
                    value={GetObjectFromString(packages, value)}
                    styles={ReactSelectTervStyle("smd")}
                    isDisabled={editData?.type === "viewOnly"}
                  />
                )}
              />
            </div>
            {/* <div>
              <label className="terv-label" htmlFor="filterBy">
                Score
              </label>
              <Controller
                name="selectScore"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Select
                    inputId="filterScore"
                    options={filterByPercentage}
                    onChange={(val) =>
                      onChange(val.value)
                    }
                    value={GetObjectFromString(filterByPercentage, value)}
                    styles={ReactSelectTervStyle("smd")}
                  />
                )}
              />
            </div> */}
          </div>
        )}
        <div className="terv-row terv-row-lg">
          <div>
            <label className="terv-label" htmlFor="all_students">
              {RequiredIndicator("Add Students ")}
            </label>
            <Controller
              name="userIdList"
              control={control}
              rules={{ required: "Please select a student" }}
              render={({ field: { onChange, value } }) => (
                <CustomSelect
                  inputId="all_students"
                  options={students}
                  isMulti
                  onChange={(val) =>
                    onChange(val ? val.map((it) => it.value) : [])
                  }
                  value={GetObjectArray(students, value)}
                  styles={ReactSelectTervStyle("xlg", true)}
                  viewOnly={editData?.type === "viewOnly" ? true : false}
                  isSearchable
                  isDisabled={editData?.type === "viewOnly"}
                  filterOption={(val) =>
                    StudentFilter(
                      val,
                      +watch("regNoFrom"),
                      +watch("regNoTo"),
                      watch("department"),
                      students[0]?.["registrationNo"]?.length || 0
                    )
                  }
                />
              )}
            />
            {errors.userIdList && (
              <div style={{ color: "red", maxWidth: 200 }}>
                {errors.userIdList.message}
              </div>
            )}
          </div>
        </div>
        {editData?.type === "viewOnly" ? (
          ""
        ) : (
          <>
            <hr className="terv-divider" />
            <TervButtonFilled
              text={editData?.edit ? "Update" : "Add"}
              type="submit"
              disabled={isSubmitting}
            />
          </>
        )}
      </form>
      <div className="custom__batch__back">
        <Link
          to={{
            pathname: "/customBatchList",
            state: {
              customer: customer,
              activePage: activePage,
              itemsPerPage: itemsPerPage,
            },
          }}
          className="terv-back"
        >
          <i className="mdi mdi-arrow-left mr-1" /> Back to Custom Batches{" "}
        </Link>
      </div>
    </div>
  );
};

export default CustomBatchDumb;
