import { Controller, useForm } from "react-hook-form";
import { FormLayout } from "components/FormLayout";
import { ErrorBlock } from "components/ErrorBlock";
import { RequiredIndicator } from "pages/utils/RequiredIndicator";
import RichTextEditor from "components/RichTextEditor";
import { TervButtonFilled } from "components/TervButtons";
import { UpdateOverviews } from "./Apis";
import { useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Form3Schema } from "./ValidationSchemas";

const Overview = ({
  courseId,
  goNext,
  goPrevious,
  courseData,
  formValue3,
  setFormValue3,
}) => {
  const {
    control,
    formState: { isSubmitting, errors },
    handleSubmit,
    reset,
  } = useForm({ mode: "onBlur", resolver: yupResolver(Form3Schema) });

  useEffect(() => {
    if (courseData?.overviews || formValue3?.overviews) {
      const value = courseData?.overviews ? courseData : formValue3;
      reset({
        overviews: value?.overviews ?? "",
        outcomes: value?.outcomes ?? "",
        requirements: value?.requirements ?? "",
        instructorDetails: value?.instructorDetails ?? "",
      });
    }
  }, [reset, courseData]);

  const onSubmit = async (formValues) => {
    try {
      await UpdateOverviews({ ...formValues, id: courseId });
      setFormValue3(formValues);
      goNext();
    } catch (error) {}
  };

  const handlePrevious = () => {
    goPrevious();
  };

  return (
    <form className="manage-prepare__form" onSubmit={handleSubmit(onSubmit)}>
      <FormLayout
        title="Course Overview"
        description="Enter course overview in Max 500 words"
      >
        <div className="terv-row">
          <div>
            <label className="terv-label">
              {RequiredIndicator("Course Overview ")}
            </label>
            <Controller
              name="overviews"
              control={control}
              render={({ field: { onChange, value } }) => (
                <RichTextEditor onChange={onChange} value={value || ""} />
              )}
            />
            {errors.overviews && (
              <ErrorBlock
                eType="span"
                errorText={errors.overviews.message || ""}
              />
            )}
          </div>
        </div>
      </FormLayout>
      <FormLayout
        title="Outcomes and Requirements"
        description="Enter course outcomes and requirements (max 500 words)"
      >
        <div className="terv-row">
          <div>
            <label className="terv-label">
              {RequiredIndicator("Course Outcomes ")}
            </label>
            <Controller
              name="outcomes"
              control={control}
              render={({ field: { onChange, value } }) => (
                <RichTextEditor onChange={onChange} value={value || ""} />
              )}
            />
            {errors.outcomes && (
              <ErrorBlock
                eType="span"
                errorText={errors.outcomes.message || ""}
              />
            )}
          </div>
        </div>
        <div className="terv-row">
          <div>
            <label className="terv-label">
              {RequiredIndicator("Course Requirements ")}
            </label>
            <Controller
              name="requirements"
              control={control}
              render={({ field: { onChange, value } }) => (
                <RichTextEditor onChange={onChange} value={value || ""} />
              )}
            />
            {errors.requirements && (
              <ErrorBlock
                eType="span"
                errorText={errors.requirements.message || ""}
              />
            )}
          </div>
        </div>
        <div className="terv-row">
          <div>
            <label className="terv-label">Instructor Details</label>
            <Controller
              name="instructorDetails"
              control={control}
              render={({ field: { onChange, value } }) => (
                <RichTextEditor onChange={onChange} value={value || ""} />
              )}
            />
            {errors.instructorDetails && (
              <ErrorBlock
                eType="span"
                errorText={errors.instructorDetails.message || ""}
              />
            )}
          </div>
        </div>
      </FormLayout>
      <FormLayout hideDivider>
        <div style={{ display: "flex", justifyContent: "flexStart", gap: "5px" }}>
          <TervButtonFilled
            type="button"
            text="Previous"
            onClick={handlePrevious}
            width="6.125rem"
          />
          <TervButtonFilled
            type="submit"
            text="Continue"
            width="6.125rem"
            disabled={isSubmitting}
          />
        </div>
      </FormLayout>
    </form>
  );
};

export default Overview;
