import React, { useState, useEffect } from "react";
import Button from '@material-ui/core/Button';
import useStyles from  "./UserProfileStyles.js";
import EditUserInput from  "./EditUserInput.js";
import { DeleteOutlineIcon } from "assets/icons/icons";
import { ConformDelete } from "../../components/DeleteModelePre";

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const paraStyle = {
  fontSize: 15,
  fontWeight: 500,
  color: "#5B5A5F",
  marginTop: 12,
  textAlign: "center",
};

export default function EditUserCertificate({pageCount, userData, handlePageCount, submitUserData, history}) {
  // const [pageCount, setPageCount] = useState(1);
  const css = useStyles();
  
  const defaultData = {
    projects: {
      title: {value: "", validation:{key:"title", placeholder:"outer", name:"Title", min:1, max:48, type:"text"}},
      technologiesOrToolsUsed: {
        value: [], validation: {
          key:"technologiesOrToolsUsed", placeholder:"outer", name:"Technologies / Tools used", 
          data:["ASP .NET", "Accounting", "Aerospace Engineering", "Agribusiness", "Agriculture", "Animation", "Anthropology", "Apache", "Applied Ethics", "Applied Mathematics", "Architecture", "Artificial Intelligence", "Arts", "Astronomy", "Astrophysics", "Behavioral Sciences", "Bioethics", "Bioinformatics", "Biology", "Botany", "Business Ethics", "Business", "C / C++", "C#", "COBOL", "Civil Engineering", "Computer Science", "Cultural Studies", "Data Science", "Design", "Engineering", "Flyway", "GWT", "Go (Golang)", "Groovy", "Hibernate", "Industrial Design", "Industrial Engineering", "Information Science", "Information Security", "JEE", "JPA", "JSF", "JXL", "Java", "Kotlin", "Materials Science", "Mathematics", "Neurological Sciences", "Objective-C", "Oracle", "PHP", "PL/SQL", "Python", "Robotics", "Ruby", "Rust", "SQL", "Scala", "Scientific Computing", "Social Sciences", "Sociology", "Space Science", "Spring Boot", "Spring Security", "Spring", "Statistics", "Swift", "Swing", "Systems Science", "Velocity", "Visual Basic"],
          search:true, type:"select-chip"
        }
      },
      description: {value: "", validation:{key:"description", placeholder:"outer", name:"Description", min:10, max:500, type:"text", status: true}},
    },
    certifications: {
      name: {value: "", validation:{key:"name", placeholder:"outer", name:"Certificate name", min:1, max:48, type:"text"}},
      credentialId: {value: "", validation:{key:"credentialId", placeholder:"outer", name:"Credential ID", min:1, max:48, type:"text"}},
    },
  }
  const [userId, setUserId] = useState("");
  const [errorShow, setErrorShow] = useState(false);
  const [errorComponentList, setErrorComponentList] = useState([]);
  const [proExpandIndex, setProExpandIndex] = useState(0); 

  const [pageData, setPageData] = useState({
    projects: [{...defaultData.projects}],
    certifications: [{...defaultData.certifications}],
  });

  const [dataDelete, setDataDelete] = useState({ type: "", index: null });

  const addData = (key, data, type) => {
    const tempPageData = {...pageData}
    if(type && type.key) data[type.key]["value"] = type.value;
    tempPageData[key].push(data);
    setPageData(tempPageData)
    setProExpandIndex(tempPageData[key].length-1);
  }
  const removeData = (key, index) => {
    const tempPageData = {...pageData}
    tempPageData[key].splice(index, 1);
    setPageData(tempPageData);
    if(proExpandIndex === index){
      setProExpandIndex(tempPageData[key].length-1);
    }
  }
  const traceData = (keys, keyIndex, dataName, val, data) => {
    if(keys[keyIndex+1] !== undefined) data[keys[keyIndex]] = traceData(keys, keyIndex+1, dataName, val, data[keys[keyIndex]]);
    else data[keys[keyIndex]][dataName] = val;
    return data;
  }
  const updateData = ({keys, dataName, value}) => {
    if(keys && keys.length > 0 ){
      const tempData = traceData(keys, 0, dataName, value, {...pageData});
      setPageData(tempData);
    }
  }
  const errorComponentUpdate = (component, type) => {
    const tempList = [...errorComponentList];
    const index = tempList.indexOf(component);
    if(type === "remove" && index > -1){
      tempList.splice(index, 1);
      setErrorComponentList(tempList);
    } else if(type === "add" && index === -1)
      setErrorComponentList([...tempList, component]);
  }
  const submitPageDataVal = async () => {
    const isSubmit = await submitUserData(pageData, "users/saveUsersProjects/");
    if(isSubmit){
      handlePageCount(pageCount+1)
    }
  }
  const goNextPage = () => {
    if(errorComponentList.length > 0)
      setErrorShow(true);
    else 
      submitPageDataVal()
  }
  const goPrevPage = () => {
    handlePageCount(pageCount-1);
  }

  useEffect(() => {
    if((dataDelete.type === "projectsDeleteConform" || dataDelete.type === "certificationsDeleteConform") && dataDelete.index !== null ) {
      removeData(dataDelete.index.key, dataDelete.index.index);
      setDataDelete({ type: "", index: null });
    }
  }, [dataDelete]);

  useEffect(() => {
    if(userData.id) {
      let tempPageData = {...pageData};
      for(const key in tempPageData) {
        if(tempPageData[key]["value"] !== undefined) {
          if(Array.isArray(tempPageData[key])) {
            if(Array.isArray(userData[key])) tempPageData[key]["value"] = [...userData[key]];
            else tempPageData[key]["value"] = [];
          } else tempPageData[key]["value"] = userData[key] ? userData[key] : tempPageData[key]["value"];
        } else if(tempPageData[key] && Array.isArray(tempPageData[key]) && defaultData[key]) {
          const tempArray = [];
          const fromUserData = userData[key] && Array.isArray(userData[key]) ? [...userData[key]] : [];
          if(fromUserData.length > 0 ) {
            fromUserData.map((data, i) => {
              let defaultKeyData = {...defaultData[key]};
              for(const defaultKey in defaultKeyData) {
                defaultKeyData[defaultKey] = {...defaultKeyData[defaultKey], value: fromUserData[i] && fromUserData[i][defaultKey] ? fromUserData[i][defaultKey] : ""};
              }
              tempArray.push(defaultKeyData);
            });
          } else {
            // tempArray.push({...defaultData[key]});
          }
          tempPageData[key] = tempArray;
        } else if(typeof tempPageData[key] === "object") {
          for (const subKey in tempPageData[key]) {
            if(tempPageData[key][subKey]["value"] !== undefined) tempPageData[key][subKey]["value"] = userData[key] && userData[key][subKey] ? userData[key][subKey] : "";
          }
        }
      }
      setPageData(tempPageData);
      setUserId(userData.id);
    }
  },[userData]);

  return (
    <React.Fragment>
      <div className="form-group">
        <h4>Projects and Certification</h4>
        {pageData.projects.length > 0 && pageData.projects.map((data, i)=> (
          <div key={`projects-${i}`} className="flex-box flex-100 flex-gap-2" style={{margin:"24px 0"}}>
            <div className="flex-4">
              <h5>{`Project ${i+1}`}</h5>
              <Accordion expanded={i === proExpandIndex} onChange={()=>setProExpandIndex(i === proExpandIndex ? -1 : i)}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`panel${i+1}a-content`} id={`panel${i+1}a-header`}>
                {i !== proExpandIndex ? `${data.title.value}` : ''}
              </AccordionSummary>
              <AccordionDetails>
                <div>
                <div className="form-row flex-box flex-100 flex-gap-2">
                  {data.title && (
                    <EditUserInput type="text" value={data.title.value} errorShow={errorShow}
                      setValue={(v)=>updateData({keys: ["projects", i, "title"], dataName:"value", value: v})}
                      updateError={errorComponentUpdate} validation={data.title.validation} />
                  )}
                  {data.technologiesOrToolsUsed && (
                    <EditUserInput type="select" value={data.technologiesOrToolsUsed.value} errorShow={errorShow}
                      setValue={(v)=>updateData({keys: ["projects", i, "technologiesOrToolsUsed"], dataName:"value", value: v})}
                      updateError={errorComponentUpdate} validation={data.technologiesOrToolsUsed.validation} />
                  )}
                </div>
                <div className="form-row flex-box flex-100 flex-gap-2">
                  {data.description && (
                    <EditUserInput type="textArea" value={data.description.value} errorShow={errorShow}
                      setValue={(v)=>updateData({keys: ["projects", i, "description"], dataName:"value", value: v})}
                      updateError={errorComponentUpdate} validation={data.description.validation} />
                  )}
                </div>
                </div>
              </AccordionDetails>
              </Accordion>
            </div>
            <div className="flex-1">
            {true && (
              <div>
                <h5>&nbsp;</h5>
                <Button variant="text" className={css.buttonDeleteIcon} disableElevation onClick={()=>setDataDelete({type: "projectsDelete", index: {key: "projects",index : i}})}>
                  <DeleteOutlineIcon />
                </Button>
              </div>
            )}
            </div>
          </div>
        ))}
        
      </div>
      <div className="form-group last-0">
        <Button variant="outlined" className={css.buttonSmSuccess} disableElevation onClick={()=>addData("projects", defaultData.projects)}>
          Add project
        </Button>
      </div>
      <div className="form-group">
        <h5>Certification</h5>
        {pageData.certifications.length > 0 && pageData.certifications.map((data, i)=>(
          <div key={`certifications-${i}`} className="flex-box flex-100 flex-gap-2" style={{margin: i === 0 ? "24px 0 12px 0" : "12px 0"}}>
            <div className="flex-4">
              <div className="form-row flex-box flex-100 flex-gap-2">
                {data.name && (
                  <EditUserInput type="text" value={data.name.value} errorShow={errorShow}
                    setValue={(v)=>updateData({keys: ["certifications", i, "name"], dataName:"value", value: v})}
                    updateError={errorComponentUpdate} validation={{...data.name.validation, placeholder: (i === 0 ? "outer" : "inner")}} />
                )}
                {data.credentialId && (
                  <EditUserInput type="text" value={data.credentialId.value} errorShow={errorShow}
                    setValue={(v)=>updateData({keys: ["certifications", i, "credentialId"], dataName:"value", value: v})}
                    updateError={errorComponentUpdate} validation={{...data.credentialId.validation, placeholder: (i === 0 ? "outer" : "inner")}} />
                )}
              </div>
            </div>
            <div className="flex-1">
            {true && (
              <div>
                <Button variant="text" className={css.buttonDeleteIcon} disableElevation onClick={()=>setDataDelete({type: "certificationsDelete", index: {key: "certifications",index : i}})}>
                  <DeleteOutlineIcon />
                </Button>
              </div>
            )}
            </div>
          </div>
        ))}
      </div>
      <div className="form-group last-0">
        <Button variant="outlined" className={css.buttonSmSuccess} disableElevation onClick={()=>addData("certifications", defaultData.certifications)}>
          Add certificate
        </Button>
      </div>
      <div className="form-group fill-width">
        <div className="form-row flex-box flex-100 flex-gap-2 flex-center">
          <Button variant="outlined" className={css.buttonPrimary} disableElevation 
            onClick={()=>goPrevPage()}>
            Prev
          </Button>
          <Button variant="contained" className={css.buttonPrimary} disableElevation
            onClick={()=>goNextPage()}>
            Next
          </Button>
        </div>
      </div>
      <ConformDelete
        Header={
          dataDelete.type === "projectsDelete" ? "Delete project" : dataDelete.type === "certificationsDelete" ? "Delete certificate" : ""
        }
        Content={() =>
          dataDelete.type === "projectsDelete" ? (
            <p style={paraStyle}>
              <div>Are you sure want to delete project?</div>
              <div>You cannot recover once deleted</div>
            </p>
          ) : dataDelete.type === "certificationsDelete" ? (
            <p style={paraStyle}>
              <div>Are you sure want to delete certificate?</div>
              <div>You cannot recover once deleted</div>
            </p>
          ) : (
            ""
          )
        }
        isOpen={dataDelete.type === "projectsDelete" || dataDelete.type === "certificationsDelete"}
        handleClose={() => {
          setDataDelete({
            type: "",
            index: null,
          });
        }}
        handleDelete={() => {
          if (dataDelete.type === "projectsDelete")
            setDataDelete({
              type: "projectsDeleteConform",
              index: dataDelete.index,
            });
          if (dataDelete.type === "certificationsDelete")
            setDataDelete({
              type: "certificationsDeleteConform",
              index: dataDelete.index,
            });
        }}
      />
    </React.Fragment>
  );
};