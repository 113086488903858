import { useEffect, useState } from "react";
import GridItem from "../../../components/GridItem/GridItem";
import SkeletonGridItem from "../../../components/SkeletonGridItem/SkeletonGridItem";
import IntermediateIcon from "../../../assets/intermediate";
import BeginnerIcon from "../../../assets/beginner";
import ProfessionalIcon from "../../../assets/professional";
import styles from "./Level.module.scss";
import { useGetDashboardInfo } from "pages/Dashboard/SWRhooks";
import { LevelSample } from "pages/Dashboard/SampleData";

export function Level({ showReal, isMobile }) {
  const [dataIsLoading, setIfDataIsLoading] = useState(false);
  const [score, setScore] = useState({});

  const apiResp = useGetDashboardInfo("getStudentDashBoardLevel", showReal);

  const setData = (data) => {
    setScore({
      scored: data?.totalPointsScored,
      total: data?.totalPointsAttempted,
    });
  };

  useEffect(() => {
    if (apiResp === "Fetching...") {
      setIfDataIsLoading(true);
    }
    if (apiResp && apiResp.hasOwnProperty("totalPointsScored")) {
      setData(apiResp);
      setIfDataIsLoading(false);
    }
  }, [apiResp]);

  useEffect(() => {
    setData(LevelSample);
  }, []);

  const hasData = score.hasOwnProperty("scored");

  return dataIsLoading ? (
    <SkeletonGridItem
      gridPosition={[
        isMobile ? [2, -2] : [1, 3],
        showReal ? (isMobile ? [7, 8] : [3, 4]) : isMobile ? [7, 8] : [4, 5],
      ]}
    />
  ) : (
    <GridItem
      gridPosition={[
        isMobile ? [2, -2] : [1, 3],
        showReal ? (isMobile ? [7, 8] : [3, 4]) : isMobile ? [7, 8] : [4, 5],
      ]}
    >
      <div className={styles.level}>
        <div className={styles.levelTitle}>Your Level</div>
        {!!(score?.scored !== undefined && score?.total !== undefined) &&
          (score.total > 0 ? (
            (score.scored / score.total) * 100 > 75 ? (
              <ProfessionalIcon className={styles.levelIcon} />
            ) : (score.scored / score.total) * 100 >= 50 ? (
              <IntermediateIcon className={styles.levelIcon} />
            ) : (
              <BeginnerIcon className={styles.levelIcon} />
            )
          ) : (
            <BeginnerIcon className={styles.levelIcon} />
          ))}
        <div className={styles.levelScore}>
          You scored {score.scored} points
        </div>
      </div>
    </GridItem>
  );
}
