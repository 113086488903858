import React from 'react';
import cn from 'classnames';
import styles from './Tabs.module.scss';

function Tabs({ tabs = [], color = 'black', activeColor = 'green', activeTabId = '' }) {
  return (
    <div
      className={cn(styles.tabs, {
        [styles.black]: color === 'black',
        [styles.white]: color === 'white'
      })}>
      {tabs.map(({ id, text, onClick }) => (
        <button
          key={id}
          className={cn(styles.tab, {
            [styles.green]: activeColor === 'green',
            [styles.violet]: activeColor === 'violet',
            [styles.active]: activeTabId === id,
          })}
          onClick={() => {
            onClick();
          }}>
          {text}
        </button>
      ))}
    </div>
  );
}

export default Tabs;
