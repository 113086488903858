import { useSpring, animated, config } from "react-spring";

const AnimatedMount = ({ children }) => {
  const animatedStyle = useSpring({
    from: { x: 200, opacity: 0 },
    to: { x: 0, opacity: 1 },
    config: config.gentle,
  });
  return <animated.div style={animatedStyle}>{children}</animated.div>;
};

export default AnimatedMount;
