import React, { Component } from "react"
import { getSafe } from "../../Taketest/common/testHelper"
import ReactHtmlParser from "html-react-parser"
import { Input, Row, Button } from "reactstrap"

export default function Descreport(props) {
  const [acccordian, setAccordian] = React.useState(false)

  try {
    if (getSafe(() => props.data.solutionMap.codeSolution, null)) {
      let strCon = JSON.parse(
        JSON.stringify(props.data.solutionMap.codeSolution)
      )

      let v = strCon.substr(1, strCon.length - 2)
      let carry = v.split(",")
      carry.forEach(element => {
        if (getSafe(() => props.data.optionsList, null)) {
          props.data.optionsList.forEach((option, i) => {
            if (option.id == element) {
              props.data.optionsList[i].userSelect = true
            }
          })
        }
      })
    }
  } catch (e) {}

  return (
    <div style={{ padding: 10 }}>
      <div
        onClick={() => {
          setAccordian(!acccordian)
        }}
      >
        {" "}
        <h5>
          Question {props.num + 1}{" "}
          {acccordian ? (
            <i style={{ marginLeft: 10 }} className="fas fa-chevron-down"></i>
          ) : (
            <i style={{ marginLeft: 10 }} className="fas fa-chevron-right"></i>
          )}{" "}
        </h5>{" "}
      </div>

      {acccordian ? (
        <div style={{ padding: 10 }}>
          {ReactHtmlParser(props.data.question || "")}
          {props.from && props.from === "assessment" ? (
            props.grading === "manual" ? (
              <Row style={{ marginLeft: 6 }}>
                <label>Marks</label>{" "}
                <Input
                  type="number"
                  style={{ width: 100, marginBottom: 20, marginLeft: 10 }}
                  value={
                    props.grade
                      ? props.grade
                      : props.data.solutionMap?.marksGained
                  }
                  onChange={e => props.updateGrade(e.target.value)}
                  disabled={props.disabled}
                />
              </Row>
            ) : (
              <span>
                Marks Gained: &nbsp;{props.data.solutionMap?.marksGained}
              </span>
            )
          ) : (
            <span>
                Marks Gained: &nbsp;{props.data.solutionMap?.marksGained}
              </span>
          )}
        </div>
      ) : (
        ""
      )}
      <div style={{ padding: 15 }}>
        {acccordian &&
          props.data.solutionMap &&
          props.data.solutionMap.codeSolution && (
            <div style={{ padding: 10, border: "2px solid #c2c2c2" }}>
              <h5>Answer </h5>
              <div
                style={{
                  borderRadius: 5,
                  padding: 10,
                  border: "2px solid #c2c2c2",
                  backgroundColor: "#e8e3e3",
                }}
              >
                {ReactHtmlParser(
                  getSafe(() => props.data.solutionMap.codeSolution, "Missing")
                )}
              </div>
            </div>
          )}
      </div>
    </div>
  )
}
