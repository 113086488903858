export const TopicSample = {
  id: null,
  numQuestionsSolved: 0,
  numCoursesEnrolled: 0,
  numAssessmentsPending: 0,
  avgAssessmentMarks: 0.0,
  totalPointsScored: 0.0,
  totalPointsAttempted: 0.0,
  pointsScored: {
    "this-week": [
      { coding: 0.0, topicName: "Array", aptitude: 0.0 },
      { coding: 0.0, topicName: "Series Completion", aptitude: 10.0 },
      { coding: 0.0, topicName: "Hash tables", aptitude: 25.0 },
      { coding: 0.0, topicName: "Ratio & Proportion", aptitude: 7.25 },
    ],
    "past-week": [
      { coding: 0.0, topicName: "Series Completion", aptitude: 20.0 },
      { coding: 0.0, topicName: "Hash tables", aptitude: 25.0 },
      { coding: 0.0, topicName: "Partnership", aptitude: 5 },
      { coding: 0.0, topicName: "Clocks & Calendar", aptitude: 21.25 },
      { coding: 0.0, topicName: "Alligation and Mixture", aptitude: 9.0 },
      { coding: 0.0, topicName: "Ratio & Proportion", aptitude: 6.0 },
    ],
  },
  questionsSolved: null,
  assessments: null,
  practicePackage: null,
};

export const QuestionsSolvedSample = {
  id: null,
  numQuestionsSolved: 0,
  numCoursesEnrolled: 0,
  numAssessmentsPending: 0,
  avgAssessmentMarks: 0.0,
  totalPointsScored: 0.0,
  totalPointsAttempted: 0.0,
  pointsScored: null,
  questionsSolved: {
    "this-week": [
      {
        coding: 0,
        currentDay: false,
        aptitude: 4,
        aptitudePoints: 7.25,
        day: 1,
        codingPoints: 0.0,
      },
      {
        coding: 1,
        currentDay: false,
        aptitude: 3,
        aptitudePoints: 35.0,
        day: 3,
        codingPoints: 0.0,
      },
      {
        coding: 1,
        currentDay: true,
        aptitude: 0,
        aptitudePoints: 0.0,
        day: 6,
        codingPoints: 0.0,
      },
      {
        coding: 0,
        currentDay: false,
        aptitude: 0,
        aptitudePoints: 0,
        day: 0,
        codingPoints: 0,
      },
      {
        coding: 0,
        currentDay: false,
        aptitude: 0,
        aptitudePoints: 0,
        day: 2,
        codingPoints: 0,
      },
      {
        coding: 0,
        currentDay: false,
        aptitude: 0,
        aptitudePoints: 0,
        day: 4,
        codingPoints: 0,
      },
      {
        coding: 0,
        currentDay: false,
        aptitude: 0,
        aptitudePoints: 0,
        day: 5,
        codingPoints: 0,
      },
    ],
    "past-week": [
      {
        coding: 0,
        currentDay: false,
        aptitude: 23,
        aptitudePoints: 41.25,
        day: 2,
        codingPoints: 0.0,
      },
      {
        coding: 0,
        currentDay: false,
        aptitude: 4,
        aptitudePoints: 45.0,
        day: 3,
        codingPoints: 0.0,
      },
      {
        coding: 0,
        currentDay: false,
        aptitude: 0,
        aptitudePoints: 0,
        day: 0,
        codingPoints: 0,
      },
      {
        coding: 0,
        currentDay: false,
        aptitude: 0,
        aptitudePoints: 0,
        day: 1,
        codingPoints: 0,
      },
      {
        coding: 0,
        currentDay: false,
        aptitude: 0,
        aptitudePoints: 0,
        day: 4,
        codingPoints: 0,
      },
      {
        coding: 0,
        currentDay: false,
        aptitude: 0,
        aptitudePoints: 0,
        day: 5,
        codingPoints: 0,
      },
      {
        coding: 0,
        currentDay: true,
        aptitude: 0,
        aptitudePoints: 0,
        day: 6,
        codingPoints: 0,
      },
    ],
  },
  assessments: null,
  practicePackage: null,
};

export const LevelSample = {
  id: null,
  numQuestionsSolved: 0,
  numCoursesEnrolled: 0,
  numAssessmentsPending: 0,
  avgAssessmentMarks: 0.0,
  totalPointsScored: 140.0,
  totalPointsAttempted: 4425.0,
  pointsScored: null,
  questionsSolved: null,
  assessments: null,
  practicePackage: null,
};

export const AssessmentsSample = {
  id: null,
  numQuestionsSolved: 0,
  numCoursesEnrolled: 0,
  numAssessmentsPending: 0,
  avgAssessmentMarks: 0.0,
  totalPointsScored: 0.0,
  totalPointsAttempted: 0.0,
  pointsScored: null,
  questionsSolved: null,
  assessments: {
    completed: [
      {
        name: "Slip test 1",
        progress: "0",
        gradingCompleted: true,
        id: "613c702811c3cc29e8765a94",
      },
      {
        name: "Slip test 2",
        progress: "0",
        gradingCompleted: true,
        id: "6139e65b9f3b2714dd287d9d",
      },
      {
        name: "Slip test 3",
        progress: "0",
        gradingCompleted: true,
        id: "6139ca039f3b2714dd287d84",
      },
      {
        name: "Slip test 4",
        progress: "0",
        gradingCompleted: false,
        id: "61399ba79f3b2714dd287d7f",
      },
    ],
    pending: [
      {
        name: "Slip test 1",
        progress: "0",
        gradingCompleted: true,
        id: "613c702811c3cc29e8765a94",
      },
      {
        name: "Slip test 2",
        progress: "0",
        gradingCompleted: true,
        id: "6139e65b9f3b2714dd287d9d",
      },
      {
        name: "Slip test 3",
        progress: "0",
        gradingCompleted: true,
        id: "6139ca039f3b2714dd287d84",
      },
      {
        name: "Slip test 4",
        progress: "0",
        gradingCompleted: false,
        id: "61399ba79f3b2714dd287d7f",
      },
      {
        name: "Slip test 5",
        progress: "0",
        gradingCompleted: true,
        id: "6135fa019e090619c4ee0daa",
      },
    ],
  },
  practicePackage: null,
};

export const PackagesSample = {
  id: null,
  numQuestionsSolved: 0,
  numCoursesEnrolled: 0,
  numAssessmentsPending: 0,
  avgAssessmentMarks: 0.0,
  totalPointsScored: 0.0,
  totalPointsAttempted: 0.0,
  pointsScored: null,
  questionsSolved: null,
  assessments: null,
  practicePackage: {
    total: 11,
    data: [
      {
        practiceName: "Advanced C++",
        practiceProgress: "0",
        id: "613c8c4340d89d024c08011c",
      },
      {
        practiceName: "Advanced C",
        practiceProgress: "0",
        id: "613c6f1b11c3cc29e8765a4e",
      },
      {
        practiceName: "Advanced Python",
        practiceProgress: "1",
        id: "60c1feab7df1f20e03d17729",
      },
      {
        practiceName: "Advanced .NET",
        practiceProgress: "60",
        id: "6135f3ab9e090619c4ee0da7",
      },
      {
        practiceName: "Advanced Ruby",
        practiceProgress: "0",
        id: "6131bbd8a2b8b87685bba639",
      },
    ],
  },
};

export const AdminAssessmentPerf = {
  id: null,
  numStudents: 0,
  numPackagesCreated: 0,
  numAssessmentsCreated: 0,
  numQuestionsCreated: 0,
  assessmentDetails: null,
  studentPackageEnrollmentDetails: null,
  studentAssessmentsMarkDetails: null,
  assessmentPerformance: {
    top: [
      { percentage: 85.17, name: "Advanced Node 1" },
      { percentage: 85.04, name: "Advanced Node 2" },
      { percentage: 82.44, name: "Javascript" },
      { percentage: 81.2, name: "Testing with Jest" },
      { percentage: 50.0, name: "Testing with Chai" },
    ],
    least: [
      { percentage: 0.0, name: "Testing with Jest" },
      { percentage: 0.0, name: "Testing with Chai" },
      { percentage: 0.0, name: "Advanced Node 2" },
      { percentage: 0.0, name: "Advanced Node 1" },
      { percentage: 0.0, name: "Javascript" },
    ],
  },
  avgAssessmentMarkDetails: null,
  leaderboard: null,
};

export const AdminAssessmentMarks = {
  id: null,
  numStudents: 0,
  numPackagesCreated: 0,
  numAssessmentsCreated: 0,
  numQuestionsCreated: 0,
  assessmentDetails: null,
  studentPackageEnrollmentDetails: null,
  studentAssessmentsMarkDetails: [
    {
      data: [
        {
          name: "React practice",
          marks: { "51_75": 0, "0_25": 1, "26_50": 0, "76_100": 0 },
        },
        {
          name: "Angular practice",
          marks: { "51_75": 0, "0_25": 1, "26_50": 0, "76_100": 0 },
        },
        {
          name: "SWR practice",
          marks: { "51_75": 0, "0_25": 1, "26_50": 0, "76_100": 0 },
        },
        {
          name: "React Hooks practice",
          marks: { "51_75": 0, "0_25": 1, "26_50": 0, "76_100": 0 },
        },
        {
          name: "Java 8 practice",
          marks: { "51_75": 0, "0_25": 1, "26_50": 0, "76_100": 0 },
        },
      ],
      name: "BCA",
    },
    {
      data: [
        {
          name: "HTML practice",
          marks: { "51_75": 0, "0_25": 0, "26_50": 0, "76_100": 12 },
        },
        {
          name: "CSS practice",
          marks: { "51_75": 0, "0_25": 14, "26_50": 3, "76_100": 0 },
        },
        {
          name: "SASS practice",
          marks: { "51_75": 0, "0_25": 17, "26_50": 0, "76_100": 0 },
        },
        {
          name: "LESS practice",
          marks: { "51_75": 0, "0_25": 17, "26_50": 0, "76_100": 0 },
        },
      ],
      name: "CIVIL",
    },
    {
      data: [
        {
          name: "HTML practice",
          marks: { "51_75": 0, "0_25": 0, "26_50": 0, "76_100": 12 },
        },
        {
          name: "CSS practice",
          marks: { "51_75": 0, "0_25": 14, "26_50": 3, "76_100": 0 },
        },
        {
          name: "SASS practice",
          marks: { "51_75": 0, "0_25": 17, "26_50": 0, "76_100": 0 },
        },
        {
          name: "LESS practice",
          marks: { "51_75": 0, "0_25": 17, "26_50": 0, "76_100": 0 },
        },
      ],
      name: "CSS",
    },
    {
      data: [
        {
          name: "LESS practice",
          marks: { "51_75": 0, "0_25": 17, "26_50": 0, "76_100": 0 },
        },
      ],
      name: "Bsc",
    },
    {
      data: [
        {
          name: "CSS practice",
          marks: { "51_75": 0, "0_25": 14, "26_50": 3, "76_100": 0 },
        },
        {
          name: "SASS practice",
          marks: { "51_75": 0, "0_25": 17, "26_50": 0, "76_100": 0 },
        },
      ],
      name: "common",
    },
    {
      data: [
        {
          name: "HTML practice",
          marks: { "51_75": 0, "0_25": 0, "26_50": 0, "76_100": 12 },
        },
        {
          name: "CSS practice",
          marks: { "51_75": 0, "0_25": 14, "26_50": 3, "76_100": 0 },
        },
        {
          name: "SASS practice",
          marks: { "51_75": 0, "0_25": 17, "26_50": 0, "76_100": 0 },
        },
        {
          name: "LESS practice",
          marks: { "51_75": 0, "0_25": 17, "26_50": 0, "76_100": 0 },
        },
      ],
      name: "CSE",
    },
    {
      data: [
        {
          name: "HTML practice",
          marks: { "51_75": 0, "0_25": 0, "26_50": 0, "76_100": 12 },
        },
        {
          name: "CSS practice",
          marks: { "51_75": 0, "0_25": 14, "26_50": 3, "76_100": 0 },
        },
        {
          name: "SASS practice",
          marks: { "51_75": 0, "0_25": 17, "26_50": 0, "76_100": 0 },
        },
        {
          name: "LESS practice",
          marks: { "51_75": 0, "0_25": 17, "26_50": 0, "76_100": 0 },
        },
      ],
      name: "EEE",
    },
    {
      data: [
        {
          name: "HTML practice",
          marks: { "51_75": 0, "0_25": 0, "26_50": 0, "76_100": 12 },
        },
        {
          name: "CSS practice",
          marks: { "51_75": 0, "0_25": 14, "26_50": 3, "76_100": 0 },
        },
        {
          name: "SASS practice",
          marks: { "51_75": 0, "0_25": 17, "26_50": 0, "76_100": 0 },
        },
        {
          name: "LESS practice",
          marks: { "51_75": 0, "0_25": 17, "26_50": 0, "76_100": 0 },
        },
      ],
      name: "cse",
    },
    {
      data: [
        {
          name: "HTML practice",
          marks: { "51_75": 0, "0_25": 0, "26_50": 0, "76_100": 12 },
        },
        {
          name: "CSS practice",
          marks: { "51_75": 0, "0_25": 14, "26_50": 3, "76_100": 0 },
        },
        {
          name: "SASS practice",
          marks: { "51_75": 0, "0_25": 17, "26_50": 0, "76_100": 0 },
        },
        {
          name: "LESS practice",
          marks: { "51_75": 0, "0_25": 17, "26_50": 0, "76_100": 0 },
        },
      ],
      name: "ECE",
    },
    {
      data: [
        {
          name: "HTML practice",
          marks: { "51_75": 0, "0_25": 0, "26_50": 0, "76_100": 12 },
        },
        {
          name: "CSS practice",
          marks: { "51_75": 0, "0_25": 14, "26_50": 3, "76_100": 0 },
        },
        {
          name: "SASS practice",
          marks: { "51_75": 0, "0_25": 17, "26_50": 0, "76_100": 0 },
        },
        {
          name: "LESS practice",
          marks: { "51_75": 0, "0_25": 17, "26_50": 0, "76_100": 0 },
        },
      ],
      name: "MECH",
    },
    {
      data: [
        {
          name: "HTML practice",
          marks: { "51_75": 0, "0_25": 0, "26_50": 0, "76_100": 12 },
        },
        {
          name: "CSS practice",
          marks: { "51_75": 0, "0_25": 14, "26_50": 3, "76_100": 0 },
        },
        {
          name: "SASS practice",
          marks: { "51_75": 0, "0_25": 17, "26_50": 0, "76_100": 0 },
        },
        {
          name: "LESS practice",
          marks: { "51_75": 0, "0_25": 17, "26_50": 0, "76_100": 0 },
        },
      ],
      name: "Agriculture Eng",
    },
  ],
  assessmentPerformance: null,
  avgAssessmentMarkDetails: null,
  leaderboard: null,
};

export const AdminAssessmentDetails = {
  id: null,
  numStudents: 0,
  numPackagesCreated: 0,
  numAssessmentsCreated: 0,
  numQuestionsCreated: 0,
  assessmentDetails: [
    { notCompleted: 0, name: "Company Specific test", completed: 1 },
    { notCompleted: 10, name: "Coding basics", completed: 1 },
    { notCompleted: 0, name: "Design basics", completed: 0 },
    { notCompleted: 11, name: "Tech basics", completed: 0 },
    { notCompleted: 11, name: "Testing basics", completed: 0 },
  ],
  studentPackageEnrollmentDetails: null,
  studentAssessmentsMarkDetails: null,
  assessmentPerformance: null,
  avgAssessmentMarkDetails: null,
  leaderboard: null,
};

export const AdminAvgMarks = {
  id: null,
  numStudents: 0,
  numPackagesCreated: 0,
  numAssessmentsCreated: 0,
  numQuestionsCreated: 0,
  assessmentDetails: null,
  studentPackageEnrollmentDetails: null,
  studentAssessmentsMarkDetails: null,
  assessmentPerformance: null,
  avgAssessmentMarkDetails: [
    {
      data: { totalAssessments: 15, totalStudents: 1, avgMarks: 8.77 },
      name: "BCA",
    },
    {
      data: { totalAssessments: 4, totalStudents: 35, avgMarks: 64.17 },
      name: "CIVIL",
    },
    {
      data: { totalAssessments: 64, totalStudents: 1, avgMarks: 2.09 },
      name: "CSS",
    },
    {
      data: { totalAssessments: 0, totalStudents: 1, avgMarks: 0.0 },
      name: "Bsc",
    },
    {
      data: { totalAssessments: 1, totalStudents: 2, avgMarks: 25.0 },
      name: "common",
    },
    {
      data: { totalAssessments: 4, totalStudents: 90, avgMarks: 89.95 },
      name: "CSE",
    },
    {
      data: { totalAssessments: 6, totalStudents: 37, avgMarks: 59.73 },
      name: "EEE",
    },
    {
      data: { totalAssessments: 12, totalStudents: 1, avgMarks: 1.72 },
      name: "cse",
    },
    {
      data: { totalAssessments: 4, totalStudents: 131, avgMarks: 90.39 },
      name: "ECE",
    },
    {
      data: { totalAssessments: 8, totalStudents: 152, avgMarks: 83.27 },
      name: "MECH",
    },
    {
      data: { totalAssessments: 4, totalStudents: 1, avgMarks: 0.0 },
      name: "Agriculture Eng",
    },
  ],
  leaderboard: null,
};

export const AdminStudentEnrolled = {
  id: null,
  numStudents: 0,
  numPackagesCreated: 0,
  numAssessmentsCreated: 0,
  numQuestionsCreated: 0,
  assessmentDetails: null,
  studentPackageEnrollmentDetails: [
    {
      data: [
        { name: "React Redux", count: 1 },
        { name: "Node js", count: 1 },
        { name: "SOAP", count: 0 },
        { name: "GraphQL", count: 1 },
        { name: "REST", count: 0 },
      ],
      name: "BBA",
    },
    {
      data: [
        { name: "React Redux", count: 1 },
        { name: "Node js", count: 1 },
        { name: "SOAP", count: 0 },
        { name: "GraphQL", count: 1 },
        { name: "REST", count: 0 },
      ],
      name: "MCA 1",
    },
    {
      data: [
        { name: "learninPath01", count: 0 },
        { name: "Practice testing2", count: 0 },
        { name: "sep11 pp1", count: 0 },
        { name: "Sept 03 Paid Pack", count: 0 },
        { name: "Afsar testing", count: 0 },
      ],
      name: "EIE",
    },
    {
      data: [
        { name: "React Redux", count: 1 },
        { name: "Node js", count: 1 },
        { name: "SOAP", count: 0 },
        { name: "GraphQL", count: 1 },
        { name: "REST", count: 0 },
      ],
      name: "MCA 2",
    },
    {
      data: [
        { name: "React Redux", count: 1 },
        { name: "Node js", count: 1 },
        { name: "SOAP", count: 0 },
        { name: "GraphQL", count: 1 },
        { name: "REST", count: 0 },
      ],
      name: "BCA",
    },
    {
      data: [
        { name: "React Redux", count: 1 },
        { name: "Node js", count: 1 },
        { name: "SOAP", count: 0 },
        { name: "GraphQL", count: 1 },
        { name: "REST", count: 0 },
      ],
      name: "CSS",
    },
    {
      data: [
        { name: "learninPath01", count: 0 },
        { name: "Practice testing2", count: 0 },
        { name: "sep11 pp1", count: 0 },
        { name: "Sept 03 Paid Pack", count: 0 },
        { name: "Afsar testing", count: 0 },
      ],
      name: "EEE",
    },
    {
      data: [
        { name: "React Redux", count: 1 },
        { name: "Node js", count: 1 },
        { name: "SOAP", count: 0 },
        { name: "GraphQL", count: 1 },
        { name: "REST", count: 0 },
      ],
      name: "PG ECE",
    },
    {
      data: [
        { name: "React Redux", count: 1 },
        { name: "Node js", count: 1 },
        { name: "SOAP", count: 0 },
        { name: "GraphQL", count: 1 },
        { name: "REST", count: 0 },
      ],
      name: "ECE",
    },
    {
      data: [
        { name: "React Redux", count: 1 },
        { name: "Node js", count: 1 },
        { name: "SOAP", count: 0 },
        { name: "GraphQL", count: 1 },
        { name: "REST", count: 0 },
      ],
      name: "IT",
    },
    {
      data: [
        { name: "React Redux", count: 1 },
        { name: "Node js", count: 1 },
        { name: "SOAP", count: 0 },
        { name: "GraphQL", count: 1 },
        { name: "REST", count: 0 },
      ],
      name: "PG DS",
    },
    {
      data: [
        { name: "React Redux", count: 1 },
        { name: "Node js", count: 1 },
        { name: "SOAP", count: 0 },
        { name: "GraphQL", count: 1 },
        { name: "REST", count: 0 },
      ],
      name: "Civil",
    },
    {
      data: [
        { name: "React Redux", count: 1 },
        { name: "Node js", count: 1 },
        { name: "SOAP", count: 0 },
        { name: "GraphQL", count: 1 },
        { name: "REST", count: 0 },
      ],
      name: "Bsc",
    },
    {
      data: [
        { name: "React Redux", count: 1 },
        { name: "Node js", count: 1 },
        { name: "SOAP", count: 0 },
        { name: "GraphQL", count: 1 },
        { name: "REST", count: 0 },
      ],
      name: "Chemical",
    },
    {
      data: [
        { name: "React Redux", count: 1 },
        { name: "Node js", count: 1 },
        { name: "SOAP", count: 0 },
        { name: "GraphQL", count: 1 },
        { name: "REST", count: 0 },
      ],
      name: "cse",
    },
    {
      data: [
        { name: "React Redux", count: 1 },
        { name: "Node js", count: 1 },
        { name: "SOAP", count: 0 },
        { name: "GraphQL", count: 1 },
        { name: "REST", count: 0 },
      ],
      name: "CSE",
    },
    {
      data: [
        { name: "React Redux", count: 1 },
        { name: "Node js", count: 1 },
        { name: "SOAP", count: 0 },
        { name: "GraphQL", count: 1 },
        { name: "REST", count: 0 },
      ],
      name: "MECH",
    },
    {
      data: [
        { name: "React Redux", count: 1 },
        { name: "Node js", count: 1 },
        { name: "SOAP", count: 0 },
        { name: "GraphQL", count: 1 },
        { name: "REST", count: 0 },
      ],
      name: "PG IS",
    },
    {
      data: [
        { name: "React Redux", count: 1 },
        { name: "Node js", count: 1 },
        { name: "SOAP", count: 0 },
        { name: "GraphQL", count: 1 },
        { name: "REST", count: 0 },
      ],
      name: "Agriculture Eng",
    },
    {
      data: [
        { name: "React Redux", count: 1 },
        { name: "Node js", count: 1 },
        { name: "SOAP", count: 0 },
        { name: "GraphQL", count: 1 },
        { name: "REST", count: 0 },
      ],
      name: "PG IoT",
    },
  ],
  studentAssessmentsMarkDetails: null,
  assessmentPerformance: null,
  avgAssessmentMarkDetails: null,
  leaderboard: null,
};