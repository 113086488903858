import { Fragment, useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import {
  Divider as MuiDivider,
  ListItemIcon as MuiListItemIcon,
  Menu as MuiMenu,
  MenuItem as MuiMenuItem,
  Tooltip,
  Typography,
  withStyles,
} from "@material-ui/core";
import {
  AccountCircleOutlined,
  DescriptionOutlined,
  ExitToApp,
  Fullscreen,
  ShoppingCartOutlined,
} from "@material-ui/icons";
import { postData } from "../../api";
import apiList from "../../api/baseConfig";
import defaultAvatar from "../../assets/images/users/default-avatar.svg";
import styles from "./profile-menu.module.scss";
import moment from "moment";
import { useSelector } from "react-redux";
import Cookies from "universal-cookie";
import { sessionPrivileges } from "privileges";

const Menu = withStyles({
  paper: {
    border: "1px solid #E1E1E1",
    borderRadius: 5,
    boxShadow: "2px 6px 16px rgba(84, 84, 84, 0.2)",
    padding: 0,
    width: 200,
  },
  list: {
    padding: 0,
  },
})(MuiMenu);

const MenuItem = withStyles({
  root: {
    height: 42,
  },
})(MuiMenuItem);

const ListItemIcon = withStyles({
  root: {
    marginRight: 12,
    minWidth: "auto",
  },
})(MuiListItemIcon);

const Divider = withStyles({
  root: {
    backgroundColor: "#F2F2F2",
  },
})(MuiDivider);

function ProfileMenuItem({ Icon, name, onClick }) {
  return (
    <MenuItem key={name} onClick={onClick}>
      <ListItemIcon className={styles.menuItemIcon}>
        <Icon />
      </ListItemIcon>
      <Typography style={{ fontSize: 14 }} noWrap>
        {name}
      </Typography>
    </MenuItem>
  );
}

function ProfileMenu({ anchorEl, onClose, userDetails, ...props }) {
  const cookies = new Cookies();
  const userPrivileges = sessionPrivileges();
  const isLoggedIn = useSelector((state) => state.LoggedInReducer);
  function toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      !document?.mozFullScreenElement &&
      !document?.webkitFullscreenElement
    ) {
      if (document.documentElement.requestFullscreen)
        document.documentElement.requestFullscreen();
      else if (document?.documentElement?.mozRequestFullScreen)
        document?.documentElement?.mozRequestFullScreen();
      else if (document?.documentElement?.webkitRequestFullscreen)
        document?.documentElement?.webkitRequestFullscreen(
          Element?.ALLOW_KEYBOARD_INPUT
        );
    } else {
      if (document?.cancelFullScreen) document?.cancelFullScreen();
      else if (document?.mozCancelFullScreen) document?.mozCancelFullScreen();
      else if (document?.webkitCancelFullScreen)
        document?.webkitCancelFullScreen();
    }
  }

  const profileMenuItems = [
    {
      visible: true,
      Icon: Fullscreen,
      name: "Full-screen mode",
      onClick() {
        toggleFullscreen();
      },
    },
    {
      visible:
        isLoggedIn.canViewProfile ||
        (!isLoggedIn.canViewProfile && isLoggedIn.student),
      Icon: AccountCircleOutlined,
      name: "View Profile",
      onClick() {
        props.history.push("/profile");
      },
    },
    {
      visible: !isLoggedIn.admin && isLoggedIn.canViewCart,
      Icon: ShoppingCartOutlined,
      name: "My cart",
      onClick() {
        props.history.push("/cart");
      },
    },
    {
      visible: !isLoggedIn.admin,
      Icon: DescriptionOutlined,
      name: "Build resume",
      onClick() {
        // props.history.push('');
      },
    },
    {
      visible: true,
      Icon: ExitToApp,
      name: "Logout",
      async onClick() {
        await postData(`${apiList.baseURL}terv/saveLoginActivity`, {
          emailId: userPrivileges.userName,
          sessionId: sessionStorage.getItem("session_id"),
          name: "",
          logoutTime: moment().format("yyyy-MM-DDTHH:mm:ss"),
        })
          .then(() => {
            const url = cookies.get("returnLink", { path: "/" });
            cookies.remove("userToken", { path: "/" });
            cookies.remove("refreshToken", {
              path: "/",
            });
            cookies.remove("userId", { path: "/" });
            sessionStorage.clear();
            cookies.remove("returnLink", { path: "/" });
            window.location.assign(url ? url : "/login");
          })
          .catch(() => {
            const url = cookies.get("returnLink", { path: "/" });
            sessionStorage.clear();
            cookies.remove("userToken", { path: "/" });
            cookies.remove("refreshToken", {
              path: "/",
            });
            cookies.remove("userId", { path: "/" });
            cookies.remove("returnLink", { path: "/" });
            window.location.assign(url ? url : "/login");
          });
      },
    },
  ];

  return (
    <Menu
      anchorEl={anchorEl}
      id="profile-menu"
      keepMounted
      onClose={onClose}
      open={Boolean(anchorEl)}
    >
      <div className={styles.bg} />
      <img src={defaultAvatar} className={styles.avatar} />
      <div style={{ padding: "0 16px" }}>
        <Typography
          noWrap
          style={{
            color: "#222222",
            fontSize: 15,
            lineHeight: "22px",
            marginTop: 8,
            textAlign: "center",
          }}
        >
          {`${userDetails?.firstName} ${userDetails?.lastName}`}
        </Typography>
        <Tooltip title={userDetails?.emailId}>
          <Typography
            noWrap
            style={{
              color: "#676666",
              fontSize: 12,
              lineHeight: "18px",
              marginBottom: 16,
              marginTop: 4,
              textAlign: "center",
            }}
          >
            {userDetails?.emailId}
          </Typography>
        </Tooltip>
      </div>
      {profileMenuItems.map(({ visible, Icon, name, onClick }, idx) => (
        <div key={idx}>
          {visible && (
            <div>
              <Divider />
              <ProfileMenuItem
                Icon={Icon}
                key={name}
                name={name}
                onClick={onClick}
              />
            </div>
          )}
        </div>
      ))}
    </Menu>
  );
}

ProfileMenu.propTypes = {
  anchorEl: PropTypes.any,
  t: PropTypes.any,
};

const ProfileMenuWithRouter = withRouter(withTranslation()(ProfileMenu));

export { ProfileMenuWithRouter as ProfileMenu };
