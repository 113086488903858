import { IconButton, Tooltip } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import PauseIcon from '@material-ui/icons/Pause';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import TimerIcon from '@material-ui/icons/Timer';
import Timer from './timer';
import styles from './stopwatch.regular.module.scss';

export default function StopwatchRegular(props) {
  return (
    <div className={styles.stopwatch}>
      {(props?.isPaused && props?.isActive)
        ? (
          <Tooltip title="Reset">
            <IconButton
              color="inherit"
              onClick={() => {
                props?.handleReset?.();
              }}
              size="small">
              <CloseIcon />
            </IconButton>
          </Tooltip>
        )
        : (
          <IconButton color="inherit" size="small" disabled>
            <TimerIcon />
          </IconButton>
        )}
      <>&nbsp;&nbsp;&nbsp;</>
      <Timer time={props.time} size="regular" />
      <>&nbsp;</>
      <Tooltip title={props?.isActive ? (props?.isPaused ? 'Resume' : 'Pause') : 'Start'}>
        <IconButton
          color="inherit"
          onClick={() => {
            if (props?.isActive) {
              props?.handlePauseResume?.();
            } else {
              props?.handleStart?.();
            }
          }}
          size="small">
          {props?.isPaused ? <PlayArrowIcon /> : <PauseIcon />}
        </IconButton>
      </Tooltip>
    </div>
  );
}