import MasterForm from "./MasterForm";

export default function CreateDomain(props) {
  return (
    <MasterForm
      postLink="createDomain"
      label={{
        breadcrumb: "Domain",
        back: "Domains",
      }}
      backLink="/domain"
      location={{
        ...props.location,
      }}
      history={{
        ...props.history,
      }}
      activePage={props?.location?.state?.activePage}
      itemsPerPage={props?.location?.state?.itemsPerPage}
    />
  );
}
