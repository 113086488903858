import React from "react"
import mainLogo from "../../assets/images/LearningPath/project.svg"

export default function Comingsoon() {
  return (
    <>
      <div className="page-content">
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20vh",
          }}
        >
          <p style={{ fontSize: 80, textAlign: "right", flex: 1 }}>
            Coming soon......
          </p>
          <img
            src={mainLogo}
            style={{ width: "40vw", height: "40vh", flex: 2 }}
          />
        </div>
      </div>
    </>
  )
}
