import React, { useEffect, useState } from "react";
import QuestionBankForm from "./QuestionBankForm";
import Apihelper from "../../api/apiHelper";
import api from "../../api/baseConfig";
import { toast } from "react-toastify";
import { sessionPrivileges } from "privileges";

export default function CreateQuestionBank(props) {
  const userEmail = sessionPrivileges().userName;
  const [competencies, setCompetencies] = useState([]);
  const [subCompetencies, setSubCompetencies] = useState([]);
  const [domains, setDomians] = useState([]);
  const [subDomains, setSubDomains] = useState([]);
  const [media, setMedia] = useState(
    props?.location?.state?.data?.media
      ? props?.location?.state?.data?.media
      : ""
  );
  const [qbId, setQBId] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
    getCompetencies();
    getDomains();
    if (props?.location?.state?.data?.id) {
      setQBId(props?.location?.state?.data?.id);
    }
  }, []);
  const getSubCompetencies = async (competency) => {
    await Apihelper.axiosCall(
      `${api.baseURL}${api.questionBankController.getSubCompetencies}${competency}`,
      "get"
    )
      .then((val) => {
        let tempSubComps = [];
        val.forEach((subcomp) => {
          tempSubComps.push({ value: subcomp.id, label: subcomp.name });
        });
        setSubCompetencies(tempSubComps);
      })
      .catch(() => {});
  };
  const getSubDomains = async (domain) => {
    await Apihelper.axiosCall(
      `${api.baseURL}${api.questionBankController.getSubDomains}${domain}`,
      "get"
    )
      .then((val) => {
        let tempSubDoms = [];
        val.forEach((subdom) => {
          tempSubDoms.push({ value: subdom.id, label: subdom.name });
        });
        setSubDomains(tempSubDoms);
      })
      .catch(() => {});
  }; 
  const getDomains = async () => {
    await Apihelper.axiosCall(
      `${api.baseURL}${api.questionBankController.getAllCompetencies}`,
      "get"
    )
      .then((datum) => {
        let tempComps = [];
        datum.forEach((element) => {
          tempComps.push({ value: element.id, label: element.name });
          if (props.editData) {
            if (element.id === props.editData.competencyId) {
              setCompetency({ value: element.id, label: element.name });
              getSubCompetencies({ value: element.id, label: element.name });
            }
          }
        });
        setCompetencies(tempComps);
      })
      .catch(() => {});
  };

  const getCompetencies = async () => {
    await Apihelper.axiosCall(
      `${api.baseURL}${api.questionBankController.getAllDomains}`,
      "get"
    )
      .then((datum) => {
        let tempDoms = [];
        datum.forEach((element) => {
          tempDoms.push({ value: element.id, label: element.name });
          if (props.editData) {
            if (element.id === props.editData.domainId) {
              setDomians({ value: element.id, label: element.name });
              getSubDomains({ value: element.id, label: element.name });
            }
          }
        });
        setDomians(tempDoms);
      })
      .catch(() => {});
  };

  let editData = {};
  if (
    props?.location?.state?.data &&
    props.location.state.data.hasOwnProperty("id")
  ) {
    const {
      name,
      description,
      competencyId,
      subCompetencyId,
      domainId,
      subDomainId,
      id,
    } = props.location.state.data;
    editData = {
      id,
      name,
      description,
      competencyId,
      subCompetencyId,
      domainId,
      subDomainId,
    };
  }

  const submit = (values) => {
    let payload = {
      ...values,
      media,
      courseId: null,
      status: "draft",
      isActive: true,
      createdBy: userEmail,
      updatedBy: userEmail,
    };
    if (editData.hasOwnProperty("id")) {
      payload.id = editData.id;
    }
    Apihelper.axiosCallPost(
      `${api.baseURL}${api.questionBankController.saveQuestionBank}`,
      "post",
      payload
    )
      .then(() => {
        toast.success("Sucessfully Created Question Bank", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: false,
        });
        props.history.push({
          pathname: "/create-question-bank",
          state: { customer: values?.collegeId },
        });
      })
      .catch(() => {});
  };

  if (props?.location?.state?.customer) {
    editData["collegeId"] = props.location.state.customer;
  }

  return (
    <QuestionBankForm
      onSubmit={submit}
      competencies={competencies}
      domains={domains}
      subCompetencies={subCompetencies}
      subDomains={subDomains}
      getSubDomains={getSubDomains}
      getSubCompetencies={getSubCompetencies}
      initialValues={editData}
      media={media}
      customer={props?.location?.state?.customer}
      activeTab={props?.location?.state?.activeTab}
      activePage={props?.location?.state?.activePage}
      setMedia={setMedia}
      customerName={props?.location?.state?.customerName}
      currentId={qbId}
    />
  );
}
