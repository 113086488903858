import api from "../../../api/baseConfig";
import ApiHelper from "../../../api/apiHelper";
       
export async function GetAllUsers(id) {
          let data;
          const url = 
          `${api.baseURL}${api.userManagement.getAllUsers}`;
          const payload = {
            customerId: id,
            limit: 0,
            pageIndex: 0,
                 };
            try {
              await ApiHelper.axiosCallPost(url, "post", payload).then((res) => {
                data = res.userDetailsList;
              });
            } catch (e) {}
          
          return data;
        };