import type { ActionType } from "./actions";
import { SET_LOGGED_IN } from "./actionTypes";

const initialState = {
  state: false,
  dashboard: false,
  prepare: false,
  practice: false,
  assessment: false,
  cloudLab: false
};

export default function LoggedInReducer(
  state = initialState,
  action: ActionType
) {
  switch (action.type) {
    case SET_LOGGED_IN: {
      return { ...state, ...action.payload };
    }
    default: {
      return state;
    }
  }
}
