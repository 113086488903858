import React, { Component } from "react";
import MockSubject from "./mocksubject";
import { Card, Button, CardTitle, CardText } from "reactstrap";
import { withRouter } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import { uid } from "react-uid";

class subjectList extends Component {
  constructor(props) {
    super(props);
  }
  async componentDidMount() {}

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {}
            <h4>Subjects</h4>
            <Row>
              {MockSubject.map((subject, subjectIndex) => (
                <Col sm="3" key={uid(subjectIndex)}>
                  <Card body style={{ borderRadius: 20, padding: 20 }}>
                    <CardTitle tag="h5">{subject.name}</CardTitle>
                    <CardText>{subject.description}</CardText>
                    <i className="bx bxs-face-mask"></i>
                    <CardText>{subject.teacher}</CardText>
                    <Button
                      onClick={() => this.props.history.push("/assessment")}
                    >
                      Go to Assessment
                    </Button>
                  </Card>
                </Col>
              ))}
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(subjectList);
