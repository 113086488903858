import React from 'react';
import styles from './GridItem.module.scss';

function GridItem({ children, gridPosition }) {
  return (
    <div
      className={styles.gridItem}
      style={{
        gridColumn: `${gridPosition?.[0]?.[0]} / ${gridPosition?.[0]?.[1]}`,
        gridRow: `${gridPosition?.[1]?.[0]} / ${gridPosition?.[1]?.[1]}`,
      }}>
      {children}
    </div>
  );
}

export default GridItem;
