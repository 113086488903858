import React, { Component } from "react";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "react-datepicker/dist/react-datepicker.css";
import CreateAssessmentComponent from "./AssessmentForm";
import api from "../../api/baseConfig";
import Apihelper from "../../api/apiHelper";
import { sessionPrivileges } from "privileges";

export default class EditAssessmentComponent extends Component {
  constructor(props) {
    super(props);
    // this.Priv = sessionPrivileges().userprivileges || [];
    this.userPrivileges = sessionPrivileges(true);
    this.UserEmail = this.userPrivileges.userName;
    this.state = {
      editableAssessment: {},
      userIdList: [],
      media: "",
      queLable:"",
      studentData: {},
      canSubmit: false,
      customerId: props?.location?.state?.customer,
      activePage: props?.location?.state?.activePage,
      activeTab: props?.location?.state?.activeTab,
      customerName: props?.location?.state?.customerName,
      assmtId: "",
    };
  }

  getAssessmentById = async (id) => {
    this.setState({ assmtId: id });
    await Apihelper.axiosCallPost(
      `${api.baseURL}${api.assessmentController.getAssessmentbyId}?id=${id}`,
      "post"
    ).then(async (res) => {
      let response = res;
      if (response) {
        let q = response;
        if (q) {
          q.startDate = q.startDate?.slice(0, 16);
          q.endDate = q.endDate?.slice(0, 16);
          if (q.filterType && q.userIdList && q.userIdList.length > 0) {
            this.setState({ canSubmit: true });
          }
          q.languages = q?.languageList || [];
          this.setState({
            editableAssessment: q,
            media: q.media || "",
          });
          this.setState({
            labIdValue: [q?.labIdValue],
          });
          this.setState({
            queLable: q?.questionPaperName,
          });
        }
      }
    });
  };
  componentDidMount() {
    const {
      match: { params },
    } = this.props;
    let assessmentId = params.questionId;
    this.getAssessmentById(assessmentId);
    window.scrollTo(0, 0);
  }

  onSubmit = async (val, assessmentUrl) => {
    if (val?.isPublicAssessment || val?.mock ? true : this.state.canSubmit) {
      let assessmentSchema = {
        active: true,
        createdBy: "",
        createdDate: "",
        id: {},
        name: "test123a",
        protoringEnabled: true,
        showReview: true,
        updatedBy: this.UserEmail ? this.UserEmail : "",
        updatedDate: Date.now(),
        classId: "",
        labIdValue: this.state?.labIdValue || [],
      };

      const {
        userDetailsName,
        userDetailsEmail,
        userDetailsPhone,
        userDetailsAddress,
        userDetailsGender,
        isPublicAssessment,
        ...remainingVal
      } = val;
      let sum = 0;
      if(val?.sectionTimeLimit){
        const timeLimitParse = Object.values(val?.sectionTimeLimit)
        var result = timeLimitParse?.map(function (x) { 
          return parseInt(x, 10); 
        });
        for (let i = 0; i < result.length; i++) {
          sum += result[i];
        }
      }
      let actualPayload = {
        ...assessmentSchema,
        ...remainingVal,
        mock: val?.isMock,
        timeLimit: val?.timeType == "question" ? sum : val?.timeLimit,
        questionPaperName: this.state.queLable,
        customDetails: {
          userName: true,
          emailId: true,
          phoneNumber: true,
          address: !!userDetailsAddress,
          gender: true,
          assessmentUrl,
        },
        media: this.state.media,
        assessmentType: isPublicAssessment ? "Public" : "Private",
      };
   
      if (!isPublicAssessment) {
      if (this.state.studentData.hasOwnProperty("filterType")) {
        actualPayload = { ...actualPayload, ...this.state.studentData };
      } else {
        const { filterType, userIdList, batchName, department } =
          this.state.editableAssessment;
        actualPayload = {
          ...actualPayload,
          filterType,
          userIdList,
          batchName,
          department,
        };
      }
    }else {
        delete actualPayload.userIdList;
        delete actualPayload.filterType;
        delete actualPayload.batchName;
        delete actualPayload.department;
      };

      if (
        actualPayload.filterType === "all" ||
        actualPayload.filterType === "except" ||
        actualPayload.filterType === "only"
      ) {
        delete actualPayload.batchName;
        delete actualPayload.department;
        delete actualPayload.regNoTo;
        delete actualPayload.regNoFrom;
      } else {
        actualPayload.filterType = actualPayload.batchName;
      }

      actualPayload.imageInterval = actualPayload.imageEnabled
        ? actualPayload.imageInterval
        : null;
      actualPayload.passcodeType = actualPayload.passcodeEnabled
        ? actualPayload.passcodeType
        : null;
      await Apihelper.axiosCallPost(
        `${api.baseURL}${api.assessmentController.assessment}`,
        "post",
        actualPayload
      )
        .then(() => {
          this.props.history.push({
            pathname: "/assessments",
            state: { customer: val?.collegeId || "" },
          });
        })
        .catch(function () {});
    }
  };
  render() {
    return (
      <>
        <CreateAssessmentComponent
          onSubmit={this.onSubmit}
          edit={true}
          initialValues={{
            ...this.state.editableAssessment,
            userDetailsName:
              this.state?.editableAssessment?.customDetails?.userName,
            userDetailsEmail:
              this.state?.editableAssessment?.customDetails?.emailId,
            userDetailsPhone:
              this.state?.editableAssessment?.customDetails?.phoneNumber,
            userDetailsGender:
              this.state?.editableAssessment?.customDetails?.gender,
            userDetailsAddress:
              this.state?.editableAssessment?.customDetails?.address,
            isPublicAssessment:
              this.state?.editableAssessment?.assessmentType === "Public",
            isMock: this.state?.editableAssessment?.mock,
          }}
          assessmentUrl={
            this.state?.editableAssessment?.customDetails?.assessmentUrl
          }
          media={this.state.media}
          setMedia={(media) => this.setState({ media })}
          setQueLable={(queLable) => this.setState({ queLable })}
          queLable={this.state.queLable}
          setStudentData={(studentData) => this.setState({ studentData })}
          setCanSubmit={(canSubmit) => this.setState({ canSubmit })}
          customer={this.state.customerId}
          activePage={this.state.activePage}
          activeTab={this.state.activeTab}
          assmtId={this.state.assmtId}
          customerName={this.state.customerName}
        />
      </>
    );
  }
}
