import { useState, useEffect, useMemo } from "react";
import AllCoursesDumb from "./AllCoursesDumb";
import { DeleteCourse, GetAllCourses, UpdateStatus } from "./Apis";
import { useGetCustomers } from "hooks/useGetCustomers";
import { GetItemWithMedia } from "pages/utils/GetItemWithMedia";
import { DeleteModule } from "../../components/DeleteModule.jsx";
import { ModalToggle } from "pages/utils/ModalToggle";
import { sessionPrivileges } from "privileges";
import CourseStatusUpdateModal from "./CourseStatusUpdateModal";
import { toast } from "react-toastify";

function AllCoursesSmart({ location }) {
  const userPrivileges = sessionPrivileges();
  const defaultCourses = {
    approved: [],
    draft: [],
    pending: [],
    rejected: [],
    all: [],
    rest: [],
  };

  const [courses, setCourses] = useState({
    real: defaultCourses,
    modify: defaultCourses,
  });
  const [customerId, setCustomerId] = useState("");
  const [activeTab, setActiveTab] = useState("approved");

  const [customerName, setCustomerName] = useState("");

  const [activePages, setActivePages] = useState({
    draft: 1,
    approved: 1,
    pending: 1,
    rejected: 1,
    all: 1,
  });

  const [deleteModal, setDeleteModal] = useState(false);
  const [statusModal, setStatusModal] = useState({
    state: false,
    status: "",
    id: "",
  });
  const [deleteCourseId, setDeleteCourseId] = useState("");

  const allCustomers = useGetCustomers();

  useEffect(() => {
    window.scrollTo(0, 0);

    if (userPrivileges.isAdmin) {
      getAllByCustomer(sessionStorage.getItem("customer_id"));
      setCustomerName(sessionStorage.getItem("customer_name"));
      setCustomerId(sessionStorage.getItem("customer_id"));
    }

    if (location?.state?.customer) {
      setCustomerId(location?.state?.customer);
      setCustomerName(location?.state?.customerName);
    }
  }, []);

  useEffect(() => {
    if (customerId) {
      getAllByCustomer(customerId);
    }
  }, [customerId]);

  useEffect(() => {
    if (userPrivileges.isSuperAdmin) {
      const hasVarsity = Array.isArray(allCustomers)
        ? allCustomers.find((cus) =>
            cus.label.toLowerCase().includes("varsity")
          )
        : null;
      if (
        !customerId &&
        hasVarsity &&
        !location?.state?.customer &&
        userPrivileges.isSuperAdmin
      ) {
        setCustomerId(hasVarsity.value);
        setCustomerName(hasVarsity.label);
      }
    }
  }, [allCustomers]);

  const getAllByCustomer = async (id) => {
    try {
      const coursesRes = await GetAllCourses(id);
      const newCoursesSkeleton = { ...defaultCourses };
      coursesRes.forEach((course) => {
        if (course.status) {
          newCoursesSkeleton[
            course.status === "published" ? "approved" : course.status
          ].push(GetItemWithMedia(course));
        }
        newCoursesSkeleton.all.push(GetItemWithMedia(course));
      });
      setCourses({ real: newCoursesSkeleton, modify: newCoursesSkeleton });
    } catch (error) {}
  };

  const deleteCourse = async () => {
    try {
      await DeleteCourse(deleteCourseId);
      getAllByCustomer(
        userPrivileges.isAdmin
          ? sessionStorage.getItem("customer_id")
          : customerId
      );
      toast.error("Course deleted successfully", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: false,
      });
    } catch (error) {}
  };

  const updateCourseStatus = async () => {
    try {
      await UpdateStatus({ id: statusModal.id, status: statusModal.status });
      toggleStatusModal({});
      getAllByCustomer(
        userPrivileges.isAdmin
          ? sessionStorage.getItem("customer_id")
          : customerId
      );
    } catch (error) {}
  };

  const tabs = useMemo(
    () => [
      {
        name: "Approved",
        badge: courses.real.approved.length,
        id: "approved",
      },
      {
        name: "Draft",
        badge: courses.real.draft.length,
        id: "draft",
      },
      {
        name: "Pending",
        badge: courses.real.pending.length,
        id: "pending",
      },
      {
        name: "Rejected",
        badge: courses.real.rejected.length,
        id: "rejected",
      },
      {
        name: "All",
        badge: courses.real.all.length,
        id: "all",
      },
    ],
    [courses]
  );

  const toggleDeleteModal = () => setDeleteModal(ModalToggle);
  const toggleStatusModal = (values) =>
    setStatusModal({ state: !statusModal.state, ...values });

  const lastIndex = activePages[activeTab] * 6;
  const firstIndex = lastIndex - 6;

  return (
    <>
    {userPrivileges.showPrepare && (
    <div>
      <DeleteModule
        Header="Course"
        Close={toggleDeleteModal}
        isOpen={deleteModal}
        Delete={deleteCourse}
      />
      <CourseStatusUpdateModal
        isOpen={statusModal.state}
        message={
          <h5>
            {statusModal.status === "pending"
              ? `Are you sure you want to send ${statusModal.name} for
          approval?`
              : statusModal.status === "published"
              ? `Are you sure you want to approve ${statusModal.name} for publish ?`
              : statusModal.status == "rejected"
              ? `Are you sure you want to send ${statusModal.name} for rejections?`
              : `Are you sure you want move to draft  ${statusModal.name}`}
          </h5>
        }
        onClose={toggleStatusModal}
        onInvoke={updateCourseStatus}
      />
      <AllCoursesDumb
        setActiveTab={setActiveTab}
        activeTab={activeTab}
        courses={courses}
        setCourses={setCourses}
        allCustomers={allCustomers}
        customerId={customerId}
        handleCustomerChange={setCustomerId}
        customerName={customerName}
        setCustomerName={setCustomerName}
        getAllByCustomer={getAllByCustomer}
        tabs={tabs}
        lastIndex={lastIndex}
        firstIndex={firstIndex}
        activePage={activePages[activeTab]}
        handlePageChange={(newPage) =>
          setActivePages((prev) => ({ ...prev, [activeTab]: newPage }))
        }
        toggleDeleteModal={toggleDeleteModal}
        toggleStatusModal={toggleStatusModal}
        setDeleteCourseId={setDeleteCourseId}
      />
    </div>
    )}
    </>
  );
}

export default AllCoursesSmart;
