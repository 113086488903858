export const GetObjectArray = (arr1, arr2) => {
  try {
    let arr2Obj = {};
    let newArr = [];
    arr2.forEach((item) => {
      arr2Obj[item] = true;
    });
    arr1.forEach((item) => {
      if (arr2Obj[item.value]) {
        newArr.push(item);
      }
    });
    return newArr;
  } catch (e) {}
};
