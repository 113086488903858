type contentType = {
  title: string;
  description: string;
  className?: string;
  icon?: boolean;
};

export const Contents: contentType[] = [
  {
    title: `terv Practice Let's take a quick tour!`,
    description: `You are just few seconds away from experiencing a new world!`,
    icon: true,
  },
  {
    title: "Package Information Pane",
    description: `Information pane contains the package name, description, progress indicator
    and other informations which adds value to the package`,
    className: ".packageHeader",
  },
  {
    title: "Navigation Bar",
    description: `You can navigate and opt to attempt the questions grouped either by difficulty or category or random option or choose a mock assessment`,
    className: ".groupingTabs",
  },
  {
    title: "Dashboard",
    description: `This pane showcases the points that you have earned, questions attended, attempted, remaining problems and also view various detailed reports`,
    className: ".summaryDashboard",
  },
  {
    title: "Question Deck",
    description: `Each card in this deck, contains one question, which can be identified by a name and difficulty level. The learner can move into solving them by clicking the “Solve” button
      `,
    className: ".questionsDeck",
  },
  {
    title: "Now lets solve one question",
    description: `Click the “Solve” button now, and explore how to solve them`,
    className: ".questionCard",
  },
];

export const LpContents: contentType[] = [
  {
    title: `terv Practice Let's take a quick tour!`,
    description: `You are just few seconds away from experiencing a new world!`,
    icon: true,
  },
  {
    title: "Package Information Pane",
    description: `Information pane contains Package name, description, Progress indicator
    and other informations which adds value to the package`,
    className: ".packageHeader",
  },
  {
    title: "Dashboard",
    description: `This pane showcases the points that you have earned, questions attended, attempted, remaining problems and also view various detailed reports`,
    className: ".summaryDashboard",
  },
  {
    title: "Learning path",
    description: `This package containes learning path, Each path contains questions to practice and mock test to solve, you get access to next path after finishing current path`,
    className: ".tourLearningPath",
  },
  {
    title: "Now click button",
    description: `By clicking solve button you get access to that particular path contents, If the lock is open you can access the path, if it is closed you cannot access it, but still you can watch the content`,
    className: ".tourLearningPath",
  },
  {
    title: "Question Deck",
    description: `Each card in this deck, contains one question, which can be identified by a name and difficulty level. The learner can move into solving them by clicking the “Solve” button
      `,
    className: ".questionsDeck",
  },
  {
    title: "Summary view",
    description: `You can go back to summary view of learning path by clicking view summary button at the top`,
    className: ".summaryView",
  },
]

export const LabMCQContents: contentType[] = [
  {
    title: "Hints",
    description: `You can watch for hints to solve the question by enabling or disabling the “Show” button, by default it is disabled
      Note: Your score gets reduced if the hints are used`,
    className: ".hintsAnchor",
  },
  {
    title: "Solution",
    description: `You can watch for solution to solve the question by enabling or disabling the “Show” button, by default it is disabled
      Note: Your score gets reduced if the solutions are used`,
    className: ".solutionSec",
  },
  {
    title: "Workspace",
    description: `You can scribble out your ideas to solve the question by using or workspace, here you can
      access notes, canvas and calculator`,
    className: ".workspaceSec",
  },
  {
    title: "Question and answer",
    description: `Get to have a clear understanding of the questions by scrolling down, before attempting to solve them. Choose the best answer from the answer container`,
    className: ".questionsAndAns",
  },
  {
    title: `Excellent!, Walkthrough is successfully completed now you are ready to use terv`,
    description: "",
    icon: true,
  },
];

export const LabCDContents: contentType[] = [
  {
    title: "Navigate Questions",
    description: "You can navigate questions by using this tab",
    className: ".labPaginationItem",
  },
  {
    title: "Screen Theme and Timer",
    description: `Switch to dark mode and light mode by clicking the moon icon. The timer can aid you plan and monitor your time better
      `,
    className: ".headerTools",
  },
  {
    title: "Go to Dashboard",
    description: `You can navigate to dashboard by clicking the dashboard button on top left`,
    className: ".headerDashboard",
  },
  {
    title: "Hints",
    description: `You can watch for hints to solve the question by enabling or disabling the “Show” button, by default it is disabled
      Note: Your score gets reduced if the hints are used`,
    className: ".hintsAnchor",
  },
  {
    title: "Test case",
    description: `You can watch Test cases to the question by enable this switch,
      Note: Watching Testcases can reduce your score`,
    className: ".testCasesAnchor",
  },
  {
    title: "Question",
    description: `Get to have a clear understanding of the questions by scrolling down, before attempting to solve them. Alternatively you can use the full screen mode for a better view`,
    className: ".questionAnchor",
  },
  {
    title: "Code editor",
    description: `Type the Code for the following question in this editor`,
    className: ".codeSolutionAnchor",
  },
  {
    title: "Output",
    description: `Output will be shown here after running code`,
    className: ".outputAnchor",
  },
  {
    title: "Output",
    description: `While compiling code, various indications will be shown`,
    className: ".outputAnchor",
  },
  {
    title: "Code/Answer Submission",
    description: `On verifying the output, click Submit button to submit your code, whereby it automatically traverses to the next question`,
    className: ".submitButtonAnchor",
  },
  {
    title: `Excellent!, Walkthrough is successfully completed now you are ready terv`,
    description: "",
    icon: true,
  },
];
