import { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import cn from "classnames";
import { Button } from "reactstrap";
import { ArrowBack } from "@material-ui/icons";
import { ToastContainer } from "react-toastify";
import { Tabs } from "components/tabs/tabs";
import Dashboard from "../component/dashboard/index";
import ReportTable from "../component/report-table/index";
import api from "../../../api/baseConfig";
import Apihelper from "../../../api/apiHelper";
import styles from "../../report/assessment/index.module.scss";
import equal from "fast-deep-equal";
import { useParams, Link } from "react-router-dom";
import moment from "moment";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import { MenuItem } from "@material-ui/core";

function AdminAssessmentReport(props) {
  const labId = props?.location?.state?.labId;
  const [reportDashboardData, setReportDashboardData] = useState({});
  const [reportDepartmentPerfData, setReportDepartmentPerfData] = useState([]);
  const [reportStudentPerfData, setReportStudentPerfData] = useState([]);
  const [reportSectionPerfData, setReportSectionPerfData] = useState({});
  const [deptPerfDataLoading, setDeptPerfDataLoadingStatus] = useState([]);
  const [stuPerfDataLoading, setStuPerfDataLoadingStatus] = useState([]);
  const [secPerfDataLoading, setSecPerfDataLoadingStatus] = useState([]);
  const [activeTab, setActiveTab] = useState("Registered User");
  const [activeSection, setActiveSection] = useState("");
  const [activeTopic, setActiveTopic] = useState("all");
  const [sections, setSections] = useState([]);
  const [topics, setTopics] = useState([]);
  const [loadingCsvExport, setCsvExportLoading] = useState(false);
  const [loadingHtmlExport, setHtmlExportLoading] = useState(false);
  const [loadingPdfExport, setPdfExportLoading] = useState(false);
  const [winner,setWinner] = useState("")
  const [winnerValue, setWinnerValue ] = useState("")

  const assessmentId = props?.location?.state?.assessmentId;
  const customerId = props?.location?.state?.customerId;
  const activeTabb = props?.location?.state?.activeTab;
  const overAllReport = props?.location?.state?.overAllReport;
  const activePage =
    overAllReport === true
      ? props?.location?.state?.activePage
      : props?.location?.state?.activePage?.allList;
  const itemsPerPage = props?.location?.state?.itemsPerPage;

  const { id } = useParams();
    useEffect(() => {
        getReportDasboardData(id);
        // getReportForHackathon(id);
    window.scrollTo(0, 0);
  }, []);
  async function exportPDF() {
    setPdfExportLoading(true);

    await Apihelper.axiosCall(
      labId
        ? `${api.baseURL}adminReport/downloadAssessmentReport/${assessmentId}?labId=${labId}&reportType=all`
        : `${api.baseURL}adminReport/downloadAssessmentReport/${assessmentId}?&reportType=all`,
      "get"
    ).then((data) => {
      if (data?.resourceURL) {
        window.location = data?.resourceURL;
      }

      setPdfExportLoading(false);
    });
  }

  async function exportHTML() {
    setHtmlExportLoading(true);
    await Apihelper.axiosCall(
      labId
        ? `${api.baseURL}adminReport/downloadAssessmentReport/${assessmentId}?labId=${labId}&resourceType=html&reportType=all`
        : `${api.baseURL}adminReport/downloadAssessmentReport/${assessmentId}?resourceType=html&reportType=all`,
      "get"
    ).then((data) => {
      if (data?.resourceURL) {
        window.location = data?.resourceURL;
      }
      setHtmlExportLoading(false);
    });
  }

  async function exportCSV() {
    setCsvExportLoading(true);
    await Apihelper.axiosCall(
      labId
        ? `${api.baseURL}adminReport/downloadAssessmentReport/${assessmentId}?labId=${labId}&resourceType=csv&reportType=all`
        : `${api.baseURL}adminReport/downloadAssessmentReport/${assessmentId}?resourceType=csv&reportType=all`,
      "get"
    ).then((data) => {
      if (data?.resourceURL) {
        window.location = data?.resourceURL;
      }

      setCsvExportLoading(false);
    });
  }

  async function exportStudentPerformancePdf() {
    setPdfExportLoading(true);
    await Apihelper.axiosCall(
      labId
        ? `${api.baseURL}adminReport/downloadAssessmentReport/${assessmentId}?labId=${labId}&reportType=student`
        : `${api.baseURL}adminReport/downloadAssessmentReport/${assessmentId}?reportType=student`,
      "get"
    ).then((data) => {
      if (data?.resourceURL) {
        window.location = data?.resourceURL;
      }
      setPdfExportLoading(false);
    });
  }

  async function exportDepartmentPerformancePdf() {
    setPdfExportLoading(true);
    await Apihelper.axiosCall(
      labId
        ? `${api.baseURL}adminReport/downloadAssessmentReport/${assessmentId}?labId=${labId}&reportType=department`
        : `${api.baseURL}adminReport/downloadAssessmentReport/${assessmentId}?reportType=department`,
      "get"
    ).then((data) => {
      if (data?.resourceURL) {
        window.location = data?.resourceURL;
      }
      setPdfExportLoading(false);
    });
  }

  async function exportSectionPerformancePdf() {
    setPdfExportLoading(true);
    await Apihelper.axiosCall(
      labId
        ? `${api.baseURL}adminReport/downloadAssessmentReport/${assessmentId}?labId=${labId}&reportType=section`
        : `${api.baseURL}adminReport/downloadAssessmentReport/${assessmentId}?reportType=section`,
      "get"
    ).then((data) => {
      if (data?.resourceURL) {
        window.location = data?.resourceURL;
      }
      setPdfExportLoading(false);
    });
  }

  async function exportDeptStudPerformancePdf() {
    setPdfExportLoading(true);
    await Apihelper.axiosCall(
      labId
        ? `${api.baseURL}adminReport/downloadAssessmentReport/${assessmentId}?labId=${labId}&reportType=student,department`
        : `${api.baseURL}adminReport/downloadAssessmentReport/${assessmentId}?reportType=student,department`,
      "get"
    ).then((data) => {
      if (data?.resourceURL) {
        window.location = data?.resourceURL;
      }
      setPdfExportLoading(false);
    });
  }

  async function exportDeptSecPerformancePdf() {
    setPdfExportLoading(true);
    await Apihelper.axiosCall(
      labId
        ? `${api.baseURL}adminReport/downloadAssessmentReport/${assessmentId}?labId=${labId}&reportType=section,department`
        : `${api.baseURL}adminReport/downloadAssessmentReport/${assessmentId}?reportType=section,department`,
      "get"
    ).then((data) => {
      if (data?.resourceURL) {
        window.location = data?.resourceURL;
      }
      setPdfExportLoading(false);
    });
  }

  async function exportStudSecPerformancePdf() {
    setPdfExportLoading(true);
    await Apihelper.axiosCall(
      labId
        ? `${api.baseURL}adminReport/downloadAssessmentReport/${assessmentId}?labId=${labId}&reportType=student,section`
        : `${api.baseURL}adminReport/downloadAssessmentReport/${assessmentId}?reportType=student,section`,
      "get"
    ).then((data) => {
      if (data?.resourceURL) {
        window.location = data?.resourceURL;
      }
      setPdfExportLoading(false);
    });
  }

  async function exportDeptSecStudPerformancePdf() {
    setPdfExportLoading(true);
    await Apihelper.axiosCall(
      labId
        ? `${api.baseURL}adminReport/downloadAssessmentReport/${assessmentId}?labId=${labId}&reportType=department,student,section`
        : `${api.baseURL}adminReport/downloadAssessmentReport/${assessmentId}?reportType=department,student,section`,
      "get"
    ).then((data) => {
      if (data?.resourceURL) {
        window.location = data?.resourceURL;
      }
      setPdfExportLoading(false);
    });
  }

  async function exportDepartmentPerformanceHtml() {
    setHtmlExportLoading(true);
    await Apihelper.axiosCall(
      labId
        ? `${api.baseURL}adminReport/downloadAssessmentReport/${assessmentId}?labId=${labId}&resourceType=html&reportType=department`
        : `${api.baseURL}adminReport/downloadAssessmentReport/${assessmentId}?resourceType=html&reportType=department`,
      "get"
    ).then((data) => {
      if (data?.resourceURL) {
        window.location = data?.resourceURL;
      }
      setHtmlExportLoading(false);
    });
  }

  async function exportStudentPerformanceHtml() {
    setHtmlExportLoading(true);
    await Apihelper.axiosCall(
      labId
        ? `${api.baseURL}adminReport/downloadAssessmentReport/${assessmentId}?labId=${labId}&resourceType=html&reportType=student`
        : `${api.baseURL}adminReport/downloadAssessmentReport/${assessmentId}?resourceType=html&reportType=student`,
      "get"
    ).then((data) => {
      if (data?.resourceURL) {
        window.location = data?.resourceURL;
      }
      setHtmlExportLoading(false);
    });
  }

  async function exportSectionPerformanceHtml() {
    setHtmlExportLoading(true);
    await Apihelper.axiosCall(
      labId
        ? `${api.baseURL}adminReport/downloadAssessmentReport/${assessmentId}?labId=${labId}&resourceType=html&reportType=section`
        : `${api.baseURL}adminReport/downloadAssessmentReport/${assessmentId}?resourceType=html&reportType=section`,
      "get"
    ).then((data) => {
      if (data?.resourceURL) {
        window.location = data?.resourceURL;
      }
      setHtmlExportLoading(false);
    });
  }

  async function exportDeptStudPerformanceHtml() {
    setHtmlExportLoading(true);
    await Apihelper.axiosCall(
      labId
        ? `${api.baseURL}adminReport/downloadAssessmentReport/${assessmentId}?labId=${labId}&resourceType=html&reportType=student,department`
        : `${api.baseURL}adminReport/downloadAssessmentReport/${assessmentId}?resourceType=html&reportType=student,department`,
      "get"
    ).then((data) => {
      if (data?.resourceURL) {
        window.location = data?.resourceURL;
      }
      setHtmlExportLoading(false);
    });
  }

  async function exportDeptSecPerformanceHtml() {
    setHtmlExportLoading(true);
    await Apihelper.axiosCall(
      labId
        ? `${api.baseURL}adminReport/downloadAssessmentReport/${assessmentId}?labId=${labId}&resourceType=html&reportType=section,department`
        : `${api.baseURL}adminReport/downloadAssessmentReport/${assessmentId}?resourceType=html&reportType=section,department`,
      "get"
    ).then((data) => {
      if (data?.resourceURL) {
        window.location = data?.resourceURL;
      }
      setHtmlExportLoading(false);
    });
  }

  async function exportStudSecPerformanceHtml() {
    setHtmlExportLoading(true);
    await Apihelper.axiosCall(
      labId
        ? `${api.baseURL}adminReport/downloadAssessmentReport/${assessmentId}?labId=${labId}&resourceType=html&reportType=student,section`
        : `${api.baseURL}adminReport/downloadAssessmentReport/${assessmentId}?resourceType=html&reportType=student,section`,
      "get"
    ).then((data) => {
      if (data?.resourceURL) {
        window.location = data?.resourceURL;
      }
      setHtmlExportLoading(false);
    });
  }

  async function exportDeptSecStudPerformanceHtml() {
    setHtmlExportLoading(true);
    await Apihelper.axiosCall(
      labId
        ? `${api.baseURL}adminReport/downloadAssessmentReport/${assessmentId}?labId=${labId}&resourceType=html&reportType=department,student,section`
        : `${api.baseURL}adminReport/downloadAssessmentReport/${assessmentId}?resourceType=html&reportType=department,student,section`,
      "get"
    ).then((data) => {
      if (data?.resourceURL) {
        window.location = data?.resourceURL;
      }
      setHtmlExportLoading(false);
    });
  }

  async function exportDepartmentPerformanceCsv() {
    setCsvExportLoading(true);
    await Apihelper.axiosCall(
      labId
        ? `${api.baseURL}adminReport/downloadAssessmentReport/${assessmentId}?labId=${labId}&resourceType=csv&reportType=department`
        : `${api.baseURL}adminReport/downloadAssessmentReport/${assessmentId}?resourceType=csv&reportType=department`,
      "get"
    ).then((data) => {
      if (data?.resourceURL) {
        window.location = data?.resourceURL;
      }
      setCsvExportLoading(false);
    });
  }

  async function exportStudentPerformanceCsv() {
    setCsvExportLoading(true);
    await Apihelper.axiosCall(
      labId
        ? `${api.baseURL}adminReport/downloadAssessmentReport/${assessmentId}?labId=${labId}&resourceType=csv&reportType=student`
        : `${api.baseURL}adminReport/downloadAssessmentReport/${assessmentId}?resourceType=csv&reportType=student`,
      "get"
    ).then((data) => {
      if (data?.resourceURL) {
        window.location = data?.resourceURL;
      }
      setCsvExportLoading(false);
    });
  }

  async function exportSectionPerformanceCsv() {
    setCsvExportLoading(true);
    await Apihelper.axiosCall(
      labId
        ? `${api.baseURL}adminReport/downloadAssessmentReport/${assessmentId}?labId=${labId}&resourceType=csv&reportType=section`
        : `${api.baseURL}adminReport/downloadAssessmentReport/${assessmentId}?resourceType=csv&reportType=section`,
      "get"
    ).then((data) => {
      if (data?.resourceURL) {
        window.location = data?.resourceURL;
      }
      setCsvExportLoading(false);
    });
  }

  async function exportDeptStudPerformanceCsv() {
    setCsvExportLoading(true);
    await Apihelper.axiosCall(
      labId
        ? `${api.baseURL}adminReport/downloadAssessmentReport/${assessmentId}?labId=${labId}&resourceType=csv&reportType=student,department`
        : `${api.baseURL}adminReport/downloadAssessmentReport/${assessmentId}?resourceType=csv&reportType=student,department`,
      "get"
    ).then((data) => {
      if (data?.resourceURL) {
        window.location = data?.resourceURL;
      }
      setCsvExportLoading(false);
    });
  }

  async function exportDeptSecPerformanceCsv() {
    setCsvExportLoading(true);
    await Apihelper.axiosCall(
      labId
        ? `${api.baseURL}adminReport/downloadAssessmentReport/${assessmentId}?labId=${labId}&resourceType=csv&reportType=section,department`
        : `${api.baseURL}adminReport/downloadAssessmentReport/${assessmentId}?resourceType=csv&reportType=section,department`,
      "get"
    ).then((data) => {
      if (data?.resourceURL) {
        window.location = data?.resourceURL;
      }
      setCsvExportLoading(false);
    });
  }

  async function exportDeptMockPerformanceCsv() {
    setCsvExportLoading(true);
    await Apihelper.axiosCall(
      labId
        ? `${api.baseURL}adminReport/downloadAssessmentReport/${assessmentId}?labId=${labId}&resourceType=csv&reportType=mockassessment,department`
        : `${api.baseURL}adminReport/downloadAssessmentReport/${assessmentId}?resourceType=csv&reportType=mockassessment,department`,
      "get"
    ).then((data) => {
      if (data?.resourceURL) {
        window.location = data?.resourceURL;
      }
      setCsvExportLoading(false);
    });
  }
  async function exportStudSecPerformanceCsv() {
    setCsvExportLoading(true);
    await Apihelper.axiosCall(
      labId
        ? `${api.baseURL}adminReport/downloadAssessmentReport/${assessmentId}?labId=${labId}&resourceType=csv&reportType=student,section`
        : `${api.baseURL}adminReport/downloadAssessmentReport/${assessmentId}?resourceType=csv&reportType=student,section`,
      "get"
    ).then((data) => {
      if (data?.resourceURL) {
        window.location = data?.resourceURL;
      }
      setCsvExportLoading(false);
    });
  }

  async function exportDeptSecStudPerformanceCsv() {
    setCsvExportLoading(true);
    await Apihelper.axiosCall(
      labId
        ? `${api.baseURL}adminReport/downloadAssessmentReport/${assessmentId}?labId=${labId}&resourceType=csv&reportType=department,student,section`
        : `${api.baseURL}adminReport/downloadAssessmentReport/${assessmentId}?resourceType=csv&reportType=department,student,section`,
      "get"
    ).then((data) => {
      if (data?.resourceURL) {
        window.location = data?.resourceURL;
      }
      setCsvExportLoading(false);
    });
  }

  async function getReportDasboardData(id) {
    if (id) {
      await Apihelper.axiosCall(
         `${api.baseURL}hackathon/getHackathonReportDashboard/${id}`,
        "get"
      )
        .then((data) => {
          setReportDashboardData(data);
        })
        .catch((e) => {});
    }
  }

  const getReportForHackathon = async ({
    id,
    page,
    pageLimit,
    totalCount,
    searchkey,
  }) => {
    try {
      if (id) {
        setDeptPerfDataLoadingStatus(true);
        const url =
          page !== undefined && page !== null && totalCount !== undefined
            ? `${api.baseURL}hackathon/getHackathonReportProject/${id}?pageIndex=${ page > 0 ? page + 10 : page}&limit=${pageLimit}`
            : `${api.baseURL}adminReport/getAdminAssessmentReportDepartment/${id}`;

        const data = await Apihelper.axiosCall(url, "get");
        const dataList = data.map((item, index) => ({
            index: index,
            participantName:`${item.firstName} ${item.lastName}`,
            email: item.emailId,
            department: item.department,
            registeredTime: item.registeredDate,
            submissionStatus:item.submitted,
            noOfSubmissions:item.noOfSubmissions,
            dateSubmitted:item.submittedDate,
            assessmentId: item?.assessmentId ?? '',
          })
        );

        // setReportDepartmentPerfData(dataList);
        return {
          data: dataList,
          page,
          totalCount,
        };
      }
    } catch (error) {
    } finally {
      setDeptPerfDataLoadingStatus(false);
    }
  };

  const getReportStudentData = async ({
    id,
    page,
    pageLimit,
    totalCount,
    searchkey,
  }) => {
    try {
      if (id) {
        setStuPerfDataLoadingStatus(true);
        const url =
        page !== undefined && page !== null && totalCount !== undefined
        ? `${api.baseURL}hackathon/getHackathonReportProject/${id}?pageIndex=${ page > 0 ? page + 10 : page}&limit=${pageLimit}&searchkey=${searchkey}`
        : `${api.baseURL}adminReport/getAdminAssessmentReportDepartment/${id}`;

        const data = await Apihelper.axiosCall(url, "get");
        const dataListLeaderBoard = data.map((item, index) => ({
          index: index,
          participantName:`${item.firstName} ${item.lastName}`,
          email: item.emailId,
          department: item.department,
          submissionStatus:item.submitted,
          registeredTime: item.registeredDate,
          noOfSubmissions:item.noOfSubmissions,
          dateSubmitted:item.submittedDate,
          marksScored:item.overallMarks,
          topper:item.topper
        })
      );
        // setReportStudentPerfData(dataList);
        return {
          data: dataListLeaderBoard,
          page,
          totalCount,
        };
      }
    } catch (error) {
    } finally {
      setStuPerfDataLoadingStatus(false);
    }
  };

  useEffect(() => {
    setReportDepartmentPerfData([]);
    setReportStudentPerfData([]);
    setReportSectionPerfData({});
    setSections([]);
    setTopics([]);
  }, [activeTab]);

  useEffect(() => {
    setTopics(
      (reportSectionPerfData?.[activeSection]?.topics || []).map((t) => ({
        name: t,
        id: t,
      }))
    );
    setActiveTopic("all");
  }, [activeSection]);

  const handleChange = (event, d) => {
    setWinnerValue({
      ...winnerValue,
      [event.target.name]: event.target.value
    })
    setWinner({
      ...winner,
      [event.target.value]: {
        winner:event.target.value,
        userId:d,
      }
    })
  }
  return (
    <div className={cn("page-content", styles.page)}>
      <Dashboard reportDashboardData={reportDashboardData} winner={winner}/>
      <Tabs
        activeTab={activeTab}
        contentClassName={styles.tabContent}
        hideBadge
        onChange={setActiveTab}
        tabs={[
          { name: "Registered User", id: "Registered User" },
          { name: "Leaderboard", id: "Leaderboard" },
        ]}
      >
        {activeTab === "Registered User" && (
          <ReportTable
            customExport
            loadingCsvExport={loadingCsvExport}
            loadingHtmlExport={loadingHtmlExport}
            loadingPdfExport={loadingPdfExport}
            exportCSV={exportCSV}
            exportPDF={exportPDF}
            exportHTML={exportHTML}
            exportDepartmentPerformancePdf={exportDepartmentPerformancePdf}
            exportStudentPerformancePdf={exportStudentPerformancePdf}
            exportSectionPerformancePdf={exportSectionPerformancePdf}
            exportDeptStudPerformancePdf={exportDeptStudPerformancePdf}
            exportDeptSecPerformancePdf={exportDeptSecPerformancePdf}
            exportStudSecPerformancePdf={exportStudSecPerformancePdf}
            exportDeptSecStudPerformancePdf={exportDeptSecStudPerformancePdf}
            exportDepartmentPerformanceHtml={exportDepartmentPerformanceHtml}
            exportStudentPerformanceHtml={exportStudentPerformanceHtml}
            exportSectionPerformanceHtml={exportSectionPerformanceHtml}
            exportDeptStudPerformanceHtml={exportDeptStudPerformanceHtml}
            exportDeptSecPerformanceHtml={exportDeptSecPerformanceHtml}
            exportStudSecPerformanceHtml={exportStudSecPerformanceHtml}
            exportDeptSecStudPerformanceHtml={exportDeptSecStudPerformanceHtml}
            exportDepartmentPerformanceCsv={exportDepartmentPerformanceCsv}
            exportStudentPerformanceCsv={exportStudentPerformanceCsv}
            exportSectionPerformanceCsv={exportSectionPerformanceCsv}
            exportDeptStudPerformanceCsv={exportDeptStudPerformanceCsv}
            exportDeptSecPerformanceCsv={exportDeptSecPerformanceCsv}
            exportStudSecPerformanceCsv={exportStudSecPerformanceCsv}
            exportDeptSecStudPerformanceCsv={exportDeptSecStudPerformanceCsv}
            data={reportDepartmentPerfData}
            fetchData={getReportForHackathon}
            labId={props?.location?.state?.assessmentId}
            totalCount={reportDashboardData?.assignedUsersCount || 0}
            title="Registered users"
            columns={[
              {
                align: "left",
                field: "participantName",
                sorting: false,
                title: "Participant\xa0name",
              },
              {
                align: "left",
                field: "email",
                title: "Email",
              },
              {
                align: "left",
                field: "department",
                title: "Department",
              },
              {
                align: "left",
                field: "registeredTime",
                render: (d) => (
                  <div>
                    <p>{d?.registeredTime ? moment(d?.registeredTime).format('DD/MM/YYYY') : '-'}</p>
                    <p>{d?.registeredTime && moment(d?.registeredTime).format('hh:mm:ss A')}</p>
                  </div>
                ),
                title: "Registered\xa0time",
              },
              {
                align: "left",
                field: "submissionStatus",
                render: (d) => (
                  <span className={d?.submissionStatus ? styles.green : styles.red}>{reportDashboardData.type !== 'Challenge' ? d?.submissionStatus ? "Submitted" : 'Not Submitted' : d?.submissionStatus ? "Attended" : 'Not Attended'}</span>
                ),
                title:reportDashboardData.type !== 'Challenge' ? "Submission\xa0status" : "Attended\xa0status" ,
              },
              {
                align: "left",
                field: "noOfSubmissions",
                render: (d) => (
                    <span style={{ color: "#246611" }}>{d?.noOfSubmissions}</span>
                  ),
                title: "No\xa0of\xa0submissions",
              },
              {
                align: "left",
                field: "dateSubmitted",
                render: (d) => (
                  <div>
                    <p>{d?.dateSubmitted ? moment(d?.dateSubmitted).format('DD/MM/YYYY') : '-'}</p>
                    <p>{d?.dateSubmitted && moment(d.dateSubmitted).format('hh:mm:ss A')}</p>
                  </div> 
                ),
                title: reportDashboardData.type !== 'Challenge' ? "Date\xa0submitted" : "Date\xa0Attended",
              },
              {
                align: "left",
                render: (d) => (
                  reportDashboardData?.type == 'Challenge' ? (
                    <Link
                      to={{
                        pathname: `/HackathonChallengeReport/${id}`,
                        state: { name: d.participantName, studentId:d.email, department:d.department, assessmentId: d?.assessmentId },
                      }}
                    >
                      <p style={{color:'#0030DA', textTransform: "capitalize"}}><u>View</u></p>
                    </Link>
                  ) : (
                    d.submissionStatus ?
                    <Link
                      to={{
                        pathname: `/HackathonGrading/${id} `,
                        state: { name: d.participantName, studentId:d.email, department:d.department },
                      }}
                    >
                      <p style={{color:'#0030DA', textTransform: "capitalize"}}><u>View</u></p>
                    </Link>
                    :
                    <Link
                    >
                      <p style={{color:'#7F7F7F', textTransform: "capitalize"}}><u>view</u></p>
                    </Link>
                  )
                ),
              },
              
            ]}
            isLoading={deptPerfDataLoading}
          />
        )}
        {activeTab === "Leaderboard" && reportDashboardData.type !== 'Challenge' && (
          <ReportTable
            customExport
            loadingCsvExport={loadingCsvExport}
            loadingHtmlExport={loadingHtmlExport}
            loadingPdfExport={loadingPdfExport}
            exportCSV={exportCSV}
            exportPDF={exportPDF}
            exportHTML={exportHTML}
            exportDepartmentPerformancePdf={exportDepartmentPerformancePdf}
            exportStudentPerformancePdf={exportStudentPerformancePdf}
            exportSectionPerformancePdf={exportSectionPerformancePdf}
            exportDeptStudPerformancePdf={exportDeptStudPerformancePdf}
            exportDeptSecPerformancePdf={exportDeptSecPerformancePdf}
            exportStudSecPerformancePdf={exportStudSecPerformancePdf}
            exportDeptSecStudPerformancePdf={exportDeptSecStudPerformancePdf}
            exportDepartmentPerformanceHtml={exportDepartmentPerformanceHtml}
            exportStudentPerformanceHtml={exportStudentPerformanceHtml}
            exportSectionPerformanceHtml={exportSectionPerformanceHtml}
            exportDeptStudPerformanceHtml={exportDeptStudPerformanceHtml}
            exportDeptSecPerformanceHtml={exportDeptSecPerformanceHtml}
            exportStudSecPerformanceHtml={exportStudSecPerformanceHtml}
            exportDeptSecStudPerformanceHtml={exportDeptSecStudPerformanceHtml}
            exportDepartmentPerformanceCsv={exportDepartmentPerformanceCsv}
            exportStudentPerformanceCsv={exportStudentPerformanceCsv}
            exportSectionPerformanceCsv={exportSectionPerformanceCsv}
            exportDeptStudPerformanceCsv={exportDeptStudPerformanceCsv}
            exportDeptSecPerformanceCsv={exportDeptSecPerformanceCsv}
            exportDeptMockPerformanceCsv={exportDeptMockPerformanceCsv}
            exportStudSecPerformanceCsv={exportStudSecPerformanceCsv}
            exportDeptSecStudPerformanceCsv={exportDeptSecStudPerformanceCsv}
            fetchData={getReportStudentData}
            labId={props?.location?.state?.assessmentId}
            totalCount={reportDashboardData?.assignedUsersCount || 0}
            data={reportStudentPerfData}
            title="Student wise performance"
            columns={[
              {
                align: "left",
                field: "participantName",
                sorting: false,
                title: "Participant\xa0name",
              },
              {
                align: "left",
                field: "email",
                title: "Email",
              },
              {
                align: "left",
                field: "department",
                title: "Department",
              },
              {
                align: "left",
                field: "registeredTime",
                render: (d) => (
                  <div>
                    <p>{d?.registeredTime ? moment(d?.registeredTime).format('DD/MM/YYYY') : '-'}</p>
                    <p>{d?.registeredTime && moment(d?.registeredTime).format('HH:mm:ss a')}</p>
                </div>
                ),
                title: "Registered\xa0time",
              },
              {
                align: "right",
                field: "submissionStatus",
                render: (d) => (
                  <span className={d?.submissionStatus ? styles.green : styles.red}>{reportDashboardData.type !== 'Challenge' ? d?.submissionStatus ? "Submitted" : 'Not Submitted' : d?.submissionStatus ? "Attended" : 'Not Attended'}</span>
                ),
                title:reportDashboardData.type !== 'Challenge' ? "Submission\xa0status" : "Attended\xa0status" ,
              },
              {
                align: "right",
                field: "marksScored",
                render: (d) => (
                    <span style={{ color: "#246611" }}>{d?.marksScored}</span>
                  ),
                title: "Marks\xa0scored",
              },
              {
                align: "right",
                field: "chooseWinner",
                title: "Choose\xa0winner",
                render: (d) => (
                  <>
                    {d?.topper === "First" ?
                      <div>
                        <svg width="19" height="32" viewBox="0 0 19 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11.2723 1.05947C11.0023 1.29947 8.90234 5.46697 8.90234 5.46697L7.33984 11.7695L13.4198 11.212C13.4198 11.212 18.4773 1.80697 18.5798 1.58947C18.7623 1.19447 18.8598 1.03197 18.1623 1.03197C17.4648 1.02947 11.5248 0.834467 11.2723 1.05947V1.05947Z" fill="#176CC7"/>
<path d="M14.4215 10.8227C14.119 10.6602 5.20897 10.5202 4.99897 10.8227C4.80897 11.0977 4.83647 12.3552 4.92897 12.5177C5.02147 12.6802 8.01647 14.0727 8.01647 14.0727L8.01397 14.5802C8.01397 14.5802 8.17897 14.9777 9.84897 14.9777C11.519 14.9777 11.6915 14.6402 11.6915 14.6402L11.7065 14.1277C11.7065 14.1277 14.329 12.8177 14.4665 12.7027C14.6065 12.5852 14.724 10.9852 14.4215 10.8227V10.8227ZM11.6765 12.9052C11.6765 12.9052 11.669 12.5552 11.4965 12.4677C11.324 12.3802 8.54647 12.3952 8.31147 12.4077C8.07647 12.4202 8.07647 12.8402 8.07647 12.8402L6.17647 11.9152V11.7302L13.2515 11.7802L13.264 11.9902L11.6765 12.9052V12.9052Z" fill="#FCC417"/>
<path d="M1.24414 22.6805C1.24414 28.6705 6.65914 31.338 10.2591 31.2105C14.2291 31.068 18.9841 27.973 18.6966 22.258C18.4241 16.843 13.8691 14.2755 9.92414 14.303C5.33914 14.3355 1.24414 17.448 1.24414 22.6805V22.6805Z" fill="#FCC417"/>
<path d="M10.1326 30.1666C10.0701 30.1666 10.0051 30.1666 9.94259 30.1641C8.06759 30.1016 6.21509 29.3116 4.86009 27.9991C3.41009 26.5941 2.61509 24.6941 2.62509 22.6516C2.64759 17.6641 7.00759 15.3516 9.96509 15.3516H9.99009C13.9976 15.3691 17.2876 18.4841 17.3801 22.7066C17.4201 24.5866 16.6501 26.5591 15.1401 28.0441C13.7301 29.4266 11.8601 30.1666 10.1326 30.1666ZM9.96009 16.1816C7.30759 16.1816 3.38509 18.3516 3.37509 22.6516C3.36759 25.8666 5.85759 29.1716 10.0051 29.3116C11.5851 29.3616 13.2126 28.7566 14.5276 27.4641C15.8926 26.1216 16.6601 24.3916 16.6326 22.7166C16.5726 19.0841 13.6026 16.1941 9.99759 16.1791C9.98759 16.1791 9.96759 16.1816 9.96009 16.1816Z" fill="#FA912C"/>
<path d="M7.96249 19.2556C7.83249 19.4481 7.94999 21.1356 8.02749 21.2106C8.17749 21.3606 9.31749 20.8231 9.31749 20.8231L9.27499 25.3356C9.27499 25.3356 8.43749 25.3256 8.34999 25.3581C8.17749 25.4231 8.19999 27.1631 8.32749 27.2481C8.45499 27.3331 11.8725 27.3781 12.0025 27.2056C12.1325 27.0331 12.1 25.5106 12.04 25.4406C11.9325 25.3106 11.115 25.3631 11.115 25.3631C11.115 25.3631 11.185 18.7181 11.1625 18.5056C11.14 18.2931 10.905 18.1606 10.625 18.2256C10.345 18.2906 8.04499 19.1306 7.96249 19.2556Z" fill="#FA912C"/>
<path d="M0.378036 0.930104C0.220536 1.0751 6.24304 11.8001 6.24304 11.8001C6.24304 11.8001 7.25304 11.9301 9.50804 11.9501C11.763 11.9701 12.8805 11.8201 12.8805 11.8201C12.8805 11.8201 8.19804 1.0376 7.91804 0.930104C7.78054 0.875104 5.92554 0.855104 4.11304 0.835104C2.29804 0.812604 0.528036 0.790104 0.378036 0.930104V0.930104Z" fill="#2E9DF4"/>
</svg>

                      </div>
                      : d?.topper === "Second" ?
                      <svg width="19" height="32" viewBox="0 0 19 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11.2723 1.05947C11.0023 1.29947 8.90234 5.46697 8.90234 5.46697L7.33984 11.7695L13.4198 11.212C13.4198 11.212 18.4773 1.80697 18.5798 1.58947C18.7623 1.19447 18.8598 1.03197 18.1623 1.03197C17.4648 1.02947 11.5248 0.834467 11.2723 1.05947Z" fill="#176CC7"/>
                        <path d="M14.4217 10.8227C14.1192 10.6602 5.20918 10.5202 4.99918 10.8227C4.80918 11.0977 4.91668 12.5402 4.98918 12.7127C5.11918 13.0127 7.99668 14.3202 7.99668 14.3202L7.94168 14.9152C7.94168 14.9152 8.17668 14.9752 9.84918 14.9752C11.5217 14.9752 11.8517 14.8902 11.8517 14.8902L11.8567 14.3527C11.8567 14.3527 14.4467 13.0927 14.5767 12.9177C14.6867 12.7727 14.7242 10.9852 14.4217 10.8227ZM11.7692 13.1027C11.7692 13.1027 11.8517 12.7352 11.6792 12.6502C11.5067 12.5627 8.54668 12.6027 8.31168 12.6152C8.07668 12.6277 8.07668 13.0477 8.07668 13.0477L6.17668 11.9152V11.7302L13.2517 11.7802L13.2642 11.9902L11.7692 13.1027Z" fill="#CECDD2"/>
                        <path d="M1.24414 22.9734C1.24414 28.9634 6.75664 31.5059 10.3591 31.3984C14.5566 31.2734 18.9866 28.0884 18.6991 22.3734C18.4266 16.9584 13.8341 14.5509 9.88914 14.5784C5.30414 14.6134 1.24414 17.7409 1.24414 22.9734Z" fill="#CECDD2"/>
                        <path d="M10.1326 30.2838C10.0701 30.2838 10.0051 30.2838 9.94259 30.2813C8.06759 30.2188 6.21509 29.4288 4.86009 28.1163C3.41009 26.7113 2.61509 24.8113 2.62509 22.7688C2.64759 17.7813 7.00759 15.4688 9.96509 15.4688H9.99009C13.9976 15.4863 17.2876 18.6013 17.3801 22.8238C17.4201 24.7038 16.6501 26.6763 15.1401 28.1613C13.7301 29.5438 11.8601 30.2838 10.1326 30.2838ZM9.96009 16.2988C7.30759 16.2988 3.38509 18.4688 3.37509 22.7688C3.36759 25.9838 5.85759 29.2888 10.0051 29.4288C11.5851 29.4788 13.2126 28.8738 14.5276 27.5813C15.8926 26.2388 16.6601 24.5088 16.6326 22.8338C16.5676 19.2013 13.5976 16.3113 9.99259 16.2937C9.98759 16.2937 9.96759 16.2988 9.96009 16.2988Z" fill="#9B9B9D"/>
                        <path d="M0.378036 0.930104C0.220536 1.0751 6.24304 11.8001 6.24304 11.8001C6.24304 11.8001 7.25304 11.9301 9.50804 11.9501C11.763 11.9701 12.8805 11.8201 12.8805 11.8201C12.8805 11.8201 8.19804 1.0376 7.91804 0.930104C7.78054 0.875104 5.92554 0.855104 4.11304 0.835104C2.29804 0.812604 0.528036 0.790104 0.378036 0.930104Z" fill="#2E9DF4"/>
                        <path d="M8.21247 21.5885C8.47247 21.591 8.70497 21.2385 8.91997 21.0235C9.37747 20.5635 9.85747 20.1985 10.405 20.6935C11.75 21.916 9.04997 23.156 8.02497 24.851C7.25997 26.116 7.10497 27.0885 7.31747 27.3485C7.52997 27.6085 12.7025 27.596 12.7725 27.466C12.8425 27.336 12.915 25.6035 12.795 25.521C12.675 25.4385 10.1325 25.486 10.1325 25.486C10.1325 25.486 10.345 24.991 11.2175 24.236C12.175 23.4085 12.9425 22.271 12.7375 20.926C12.3125 18.146 9.51247 18.0435 8.29997 19.011C7.11747 19.9535 7.37247 21.5785 8.21247 21.5885Z" fill="#9B9B9D"/>
                      </svg>
                      : d?.topper === "Third" ?
<svg width="19" height="32" viewBox="0 0 19 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11.2723 1.05947C11.0023 1.29947 8.90234 5.46697 8.90234 5.46697L7.33984 11.7695L13.4198 11.212C13.4198 11.212 18.4773 1.80697 18.5798 1.58947C18.7623 1.19447 18.8598 1.03197 18.1623 1.03197C17.4648 1.02947 11.5248 0.834467 11.2723 1.05947Z" fill="#176CC7"/>
<path d="M14.4217 10.8227C14.1192 10.6602 5.20918 10.5202 4.99918 10.8227C4.80918 11.0977 4.91668 12.5402 4.98918 12.7127C5.11918 13.0127 7.99668 14.3202 7.99668 14.3202L7.94168 14.9152C7.94168 14.9152 8.17668 14.9752 9.84918 14.9752C11.5217 14.9752 11.8517 14.8902 11.8517 14.8902L11.8567 14.3527C11.8567 14.3527 14.4467 13.0927 14.5767 12.9177C14.6867 12.7727 14.7242 10.9852 14.4217 10.8227ZM11.7692 13.1027C11.7692 13.1027 11.8517 12.7352 11.6792 12.6502C11.5067 12.5627 8.54668 12.6027 8.31168 12.6152C8.07668 12.6277 8.07668 13.0477 8.07668 13.0477L6.21168 12.0877L6.17668 11.7302L13.2517 11.7802L13.2267 12.1177L11.7692 13.1027Z" fill="#F79429"/>
<path d="M1.32812 23.0234C1.32812 29.0134 6.75563 31.5059 10.3581 31.3984C14.5556 31.2734 19.0706 28.0884 18.6981 22.3734C18.3481 17.0109 13.8331 14.5509 9.88812 14.5784C5.30312 14.6134 1.32812 17.7909 1.32812 23.0234Z" fill="#F79429"/>
<path d="M10.1658 30.4927C10.1033 30.4927 10.0383 30.4927 9.97579 30.4902C8.1008 30.4277 6.2483 29.6377 4.8933 28.3252C3.4433 26.9202 2.6483 25.0202 2.6583 22.9777C2.6808 17.9902 7.0408 15.6777 9.99829 15.6777H10.0233C14.0308 15.6952 17.3208 18.8102 17.4133 23.0327C17.4533 24.9127 16.8533 26.7827 15.3433 28.2677C13.9333 29.6477 11.8933 30.4927 10.1658 30.4927ZM9.99329 16.5077C7.3408 16.5077 3.4183 18.6777 3.4083 22.9777C3.4008 26.1927 5.8908 29.4977 10.0383 29.6377C11.6183 29.6877 13.4008 29.1027 14.7133 27.8127C16.0783 26.4702 16.6908 24.7202 16.6633 23.0452C16.6033 19.4127 13.6333 16.5227 10.0283 16.5077C10.0208 16.5027 10.0008 16.5077 9.99329 16.5077Z" fill="#D25116"/>
<path d="M0.378036 0.930104C0.220536 1.0751 6.24304 11.8001 6.24304 11.8001C6.24304 11.8001 7.25304 11.9301 9.50804 11.9501C11.763 11.9701 12.8805 11.8201 12.8805 11.8201C12.8805 11.8201 8.19804 1.0376 7.91804 0.930104C7.78054 0.875104 5.92554 0.855104 4.11304 0.835104C2.29804 0.812604 0.528036 0.790104 0.378036 0.930104Z" fill="#2E9DF4"/>
<path d="M12.7366 20.9273C12.4466 18.4148 9.52162 18.2048 8.30912 19.1698C7.12912 20.1098 7.24162 21.4198 8.06912 21.5723C8.72162 21.6923 8.89162 21.1748 9.08162 20.9373C9.51162 20.4048 10.1966 20.3548 10.6441 20.7823C11.1516 21.2698 10.8391 22.4348 10.0766 22.4823C9.50912 22.5173 9.22162 22.4873 9.14912 22.5848C9.04662 22.7223 9.06162 23.8048 9.16662 23.9248C9.28662 24.0623 9.76662 23.9773 10.1791 23.9923C10.7466 24.0148 11.3491 24.8548 10.9516 25.4848C10.5391 26.1373 9.44162 25.8448 9.04662 25.4498C8.52412 24.9273 8.00162 25.2273 7.82662 25.4323C7.55162 25.7573 7.25912 26.4973 8.27412 27.2173C9.28662 27.9373 12.3591 28.0748 12.9791 25.8448C13.5191 23.8973 12.2591 23.0973 12.2591 23.0973C12.2591 23.0973 12.9016 22.3598 12.7366 20.9273Z" fill="#D25116"/>
</svg>
 :
    reportDashboardData.type !== 'Challenge' ?
      <div>
        {d?.submissionStatus && reportDashboardData?.adminEvaluation == "Completed" ?
        <select
          className={styles.customselect}
          name={d?.email}
          value={winnerValue[d?.email]}
          defaultValue={'choose'}
          onChange={(e) => (handleChange(e, d?.email)) }
        >
          <option value={'choose'} disabled>Choose</option>
          <option value={'First'}>1st</option>
          <option value={'Second'}>2nd</option>
          <option value={'Third'}>3rd</option>
        </select>
           :
           <select
          className={styles.customselect}
          name={d?.email}
          value={winnerValue[d?.email]}
          defaultValue={'choose'}
          onChange={(e) => (handleChange(e, d?.email)) }
          disabled={true}
        >
          <option value={'choose'} disabled>Choose</option>
          <option value={'First'}>1st</option>
          <option value={'Second'}>2nd</option>
          <option value={'Third'}>3rd</option>
        </select>
            }
      </div>
      :
      <div>
        {d?.submissionStatus ?
        <select
          className={styles.customselect}
          name={d?.email}
          value={winnerValue[d?.email]}
          defaultValue={'choose'}
          onChange={(e) => (handleChange(e, d?.email)) }
        >
          <option value={'choose'} disabled>Choose</option>
          <option value={'First'}>1st</option>
          <option value={'Second'}>2nd</option>
          <option value={'Third'}>3rd</option>
        </select>
            : 
          <select
            className={styles.customselect}
            name={d?.email}
            value={winnerValue[d?.email]}
            defaultValue={'choose'}
            disabled
          >
            <option value={'choose'} disabled>Choose</option>
            <option value={'First'}>1st</option>
            <option value={'Second'}>2nd</option>
            <option value={'Third'}>3rd</option>
          </select>}
      </div>        
                    }                  
                  </>
                )
              },
            ]}
            isLoading={stuPerfDataLoading}
          />
        )}
        {activeTab === "Leaderboard" && reportDashboardData.type == 'Challenge' && (
          <ReportTable
            customExport
            loadingCsvExport={loadingCsvExport}
            loadingHtmlExport={loadingHtmlExport}
            loadingPdfExport={loadingPdfExport}
            exportCSV={exportCSV}
            exportPDF={exportPDF}
            exportHTML={exportHTML}
            exportDepartmentPerformancePdf={exportDepartmentPerformancePdf}
            exportStudentPerformancePdf={exportStudentPerformancePdf}
            exportSectionPerformancePdf={exportSectionPerformancePdf}
            exportDeptStudPerformancePdf={exportDeptStudPerformancePdf}
            exportDeptSecPerformancePdf={exportDeptSecPerformancePdf}
            exportStudSecPerformancePdf={exportStudSecPerformancePdf}
            exportDeptSecStudPerformancePdf={exportDeptSecStudPerformancePdf}
            exportDepartmentPerformanceHtml={exportDepartmentPerformanceHtml}
            exportStudentPerformanceHtml={exportStudentPerformanceHtml}
            exportSectionPerformanceHtml={exportSectionPerformanceHtml}
            exportDeptStudPerformanceHtml={exportDeptStudPerformanceHtml}
            exportDeptSecPerformanceHtml={exportDeptSecPerformanceHtml}
            exportStudSecPerformanceHtml={exportStudSecPerformanceHtml}
            exportDeptSecStudPerformanceHtml={exportDeptSecStudPerformanceHtml}
            exportDepartmentPerformanceCsv={exportDepartmentPerformanceCsv}
            exportStudentPerformanceCsv={exportStudentPerformanceCsv}
            exportSectionPerformanceCsv={exportSectionPerformanceCsv}
            exportDeptStudPerformanceCsv={exportDeptStudPerformanceCsv}
            exportDeptSecPerformanceCsv={exportDeptSecPerformanceCsv}
            exportDeptMockPerformanceCsv={exportDeptMockPerformanceCsv}
            exportStudSecPerformanceCsv={exportStudSecPerformanceCsv}
            exportDeptSecStudPerformanceCsv={exportDeptSecStudPerformanceCsv}
            fetchData={getReportStudentData}
            labId={props?.location?.state?.assessmentId}
            totalCount={reportDashboardData?.assignedUsersCount || 0}
            data={reportStudentPerfData}
            title="Student wise performance"
            columns={[
              {
                align: "left",
                field: "participantName",
                sorting: false,
                title: "Participant\xa0name",
              },
              {
                align: "left",
                field: "email",
                title: "Email",
              },
              {
                align: "left",
                field: "department",
                title: "Department",
              },
              {
                align: "left",
                field: "totalQuestion",
                title: "Total\xa0Question",
              },
              {
                align: "left",
                field: "correct",
                title: "Correct",
              },
              {
                align: "left",
                field: "wrong",
                title: "Wrong",
              },
              {
                align: "left",
                field: "unattempted",
                title: "Unattempted",
              },
              {
                align: "right",
                field: "submissionStatus",
                render: (d) => (
                  <span className={d?.submissionStatus ? styles.green : styles.red}>{reportDashboardData.type !== 'Challenge' ? d?.submissionStatus ? "Submitted" : 'Not Submitted' : d?.submissionStatus ? "Attended" : 'Not Attended'}</span>
                ),
                title:"Attended\xa0status" ,
              },
              {
                align: "right",
                field: "marksScored",
                render: (d) => (
                    <span style={{ color: "#246611" }}>{d?.marksScored}</span>
                  ),
                title: "Marks\xa0scored",
              },
              {
                align: "right",
                field: "timeTaken",
                // render: (d) => (
                //     <span style={{ color: "#246611" }}>{d?.marksScored}</span>
                //   ),
                title: "Time\xa0taken",
              },
              {
                align: "right",
                field: "chooseWinner",
                title: "Choose\xa0winner",
                render: (d) => (
                  <>
                    {d?.topper === "First" ?
                      <div>
                        <svg width="19" height="32" viewBox="0 0 19 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11.2723 1.05947C11.0023 1.29947 8.90234 5.46697 8.90234 5.46697L7.33984 11.7695L13.4198 11.212C13.4198 11.212 18.4773 1.80697 18.5798 1.58947C18.7623 1.19447 18.8598 1.03197 18.1623 1.03197C17.4648 1.02947 11.5248 0.834467 11.2723 1.05947V1.05947Z" fill="#176CC7"/>
<path d="M14.4215 10.8227C14.119 10.6602 5.20897 10.5202 4.99897 10.8227C4.80897 11.0977 4.83647 12.3552 4.92897 12.5177C5.02147 12.6802 8.01647 14.0727 8.01647 14.0727L8.01397 14.5802C8.01397 14.5802 8.17897 14.9777 9.84897 14.9777C11.519 14.9777 11.6915 14.6402 11.6915 14.6402L11.7065 14.1277C11.7065 14.1277 14.329 12.8177 14.4665 12.7027C14.6065 12.5852 14.724 10.9852 14.4215 10.8227V10.8227ZM11.6765 12.9052C11.6765 12.9052 11.669 12.5552 11.4965 12.4677C11.324 12.3802 8.54647 12.3952 8.31147 12.4077C8.07647 12.4202 8.07647 12.8402 8.07647 12.8402L6.17647 11.9152V11.7302L13.2515 11.7802L13.264 11.9902L11.6765 12.9052V12.9052Z" fill="#FCC417"/>
<path d="M1.24414 22.6805C1.24414 28.6705 6.65914 31.338 10.2591 31.2105C14.2291 31.068 18.9841 27.973 18.6966 22.258C18.4241 16.843 13.8691 14.2755 9.92414 14.303C5.33914 14.3355 1.24414 17.448 1.24414 22.6805V22.6805Z" fill="#FCC417"/>
<path d="M10.1326 30.1666C10.0701 30.1666 10.0051 30.1666 9.94259 30.1641C8.06759 30.1016 6.21509 29.3116 4.86009 27.9991C3.41009 26.5941 2.61509 24.6941 2.62509 22.6516C2.64759 17.6641 7.00759 15.3516 9.96509 15.3516H9.99009C13.9976 15.3691 17.2876 18.4841 17.3801 22.7066C17.4201 24.5866 16.6501 26.5591 15.1401 28.0441C13.7301 29.4266 11.8601 30.1666 10.1326 30.1666ZM9.96009 16.1816C7.30759 16.1816 3.38509 18.3516 3.37509 22.6516C3.36759 25.8666 5.85759 29.1716 10.0051 29.3116C11.5851 29.3616 13.2126 28.7566 14.5276 27.4641C15.8926 26.1216 16.6601 24.3916 16.6326 22.7166C16.5726 19.0841 13.6026 16.1941 9.99759 16.1791C9.98759 16.1791 9.96759 16.1816 9.96009 16.1816Z" fill="#FA912C"/>
<path d="M7.96249 19.2556C7.83249 19.4481 7.94999 21.1356 8.02749 21.2106C8.17749 21.3606 9.31749 20.8231 9.31749 20.8231L9.27499 25.3356C9.27499 25.3356 8.43749 25.3256 8.34999 25.3581C8.17749 25.4231 8.19999 27.1631 8.32749 27.2481C8.45499 27.3331 11.8725 27.3781 12.0025 27.2056C12.1325 27.0331 12.1 25.5106 12.04 25.4406C11.9325 25.3106 11.115 25.3631 11.115 25.3631C11.115 25.3631 11.185 18.7181 11.1625 18.5056C11.14 18.2931 10.905 18.1606 10.625 18.2256C10.345 18.2906 8.04499 19.1306 7.96249 19.2556Z" fill="#FA912C"/>
<path d="M0.378036 0.930104C0.220536 1.0751 6.24304 11.8001 6.24304 11.8001C6.24304 11.8001 7.25304 11.9301 9.50804 11.9501C11.763 11.9701 12.8805 11.8201 12.8805 11.8201C12.8805 11.8201 8.19804 1.0376 7.91804 0.930104C7.78054 0.875104 5.92554 0.855104 4.11304 0.835104C2.29804 0.812604 0.528036 0.790104 0.378036 0.930104V0.930104Z" fill="#2E9DF4"/>
</svg>

                      </div>
                      : d?.topper === "Second" ?
                      <svg width="19" height="32" viewBox="0 0 19 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11.2723 1.05947C11.0023 1.29947 8.90234 5.46697 8.90234 5.46697L7.33984 11.7695L13.4198 11.212C13.4198 11.212 18.4773 1.80697 18.5798 1.58947C18.7623 1.19447 18.8598 1.03197 18.1623 1.03197C17.4648 1.02947 11.5248 0.834467 11.2723 1.05947Z" fill="#176CC7"/>
                        <path d="M14.4217 10.8227C14.1192 10.6602 5.20918 10.5202 4.99918 10.8227C4.80918 11.0977 4.91668 12.5402 4.98918 12.7127C5.11918 13.0127 7.99668 14.3202 7.99668 14.3202L7.94168 14.9152C7.94168 14.9152 8.17668 14.9752 9.84918 14.9752C11.5217 14.9752 11.8517 14.8902 11.8517 14.8902L11.8567 14.3527C11.8567 14.3527 14.4467 13.0927 14.5767 12.9177C14.6867 12.7727 14.7242 10.9852 14.4217 10.8227ZM11.7692 13.1027C11.7692 13.1027 11.8517 12.7352 11.6792 12.6502C11.5067 12.5627 8.54668 12.6027 8.31168 12.6152C8.07668 12.6277 8.07668 13.0477 8.07668 13.0477L6.17668 11.9152V11.7302L13.2517 11.7802L13.2642 11.9902L11.7692 13.1027Z" fill="#CECDD2"/>
                        <path d="M1.24414 22.9734C1.24414 28.9634 6.75664 31.5059 10.3591 31.3984C14.5566 31.2734 18.9866 28.0884 18.6991 22.3734C18.4266 16.9584 13.8341 14.5509 9.88914 14.5784C5.30414 14.6134 1.24414 17.7409 1.24414 22.9734Z" fill="#CECDD2"/>
                        <path d="M10.1326 30.2838C10.0701 30.2838 10.0051 30.2838 9.94259 30.2813C8.06759 30.2188 6.21509 29.4288 4.86009 28.1163C3.41009 26.7113 2.61509 24.8113 2.62509 22.7688C2.64759 17.7813 7.00759 15.4688 9.96509 15.4688H9.99009C13.9976 15.4863 17.2876 18.6013 17.3801 22.8238C17.4201 24.7038 16.6501 26.6763 15.1401 28.1613C13.7301 29.5438 11.8601 30.2838 10.1326 30.2838ZM9.96009 16.2988C7.30759 16.2988 3.38509 18.4688 3.37509 22.7688C3.36759 25.9838 5.85759 29.2888 10.0051 29.4288C11.5851 29.4788 13.2126 28.8738 14.5276 27.5813C15.8926 26.2388 16.6601 24.5088 16.6326 22.8338C16.5676 19.2013 13.5976 16.3113 9.99259 16.2937C9.98759 16.2937 9.96759 16.2988 9.96009 16.2988Z" fill="#9B9B9D"/>
                        <path d="M0.378036 0.930104C0.220536 1.0751 6.24304 11.8001 6.24304 11.8001C6.24304 11.8001 7.25304 11.9301 9.50804 11.9501C11.763 11.9701 12.8805 11.8201 12.8805 11.8201C12.8805 11.8201 8.19804 1.0376 7.91804 0.930104C7.78054 0.875104 5.92554 0.855104 4.11304 0.835104C2.29804 0.812604 0.528036 0.790104 0.378036 0.930104Z" fill="#2E9DF4"/>
                        <path d="M8.21247 21.5885C8.47247 21.591 8.70497 21.2385 8.91997 21.0235C9.37747 20.5635 9.85747 20.1985 10.405 20.6935C11.75 21.916 9.04997 23.156 8.02497 24.851C7.25997 26.116 7.10497 27.0885 7.31747 27.3485C7.52997 27.6085 12.7025 27.596 12.7725 27.466C12.8425 27.336 12.915 25.6035 12.795 25.521C12.675 25.4385 10.1325 25.486 10.1325 25.486C10.1325 25.486 10.345 24.991 11.2175 24.236C12.175 23.4085 12.9425 22.271 12.7375 20.926C12.3125 18.146 9.51247 18.0435 8.29997 19.011C7.11747 19.9535 7.37247 21.5785 8.21247 21.5885Z" fill="#9B9B9D"/>
                      </svg>
                      : d?.topper === "Third" ?
<svg width="19" height="32" viewBox="0 0 19 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11.2723 1.05947C11.0023 1.29947 8.90234 5.46697 8.90234 5.46697L7.33984 11.7695L13.4198 11.212C13.4198 11.212 18.4773 1.80697 18.5798 1.58947C18.7623 1.19447 18.8598 1.03197 18.1623 1.03197C17.4648 1.02947 11.5248 0.834467 11.2723 1.05947Z" fill="#176CC7"/>
<path d="M14.4217 10.8227C14.1192 10.6602 5.20918 10.5202 4.99918 10.8227C4.80918 11.0977 4.91668 12.5402 4.98918 12.7127C5.11918 13.0127 7.99668 14.3202 7.99668 14.3202L7.94168 14.9152C7.94168 14.9152 8.17668 14.9752 9.84918 14.9752C11.5217 14.9752 11.8517 14.8902 11.8517 14.8902L11.8567 14.3527C11.8567 14.3527 14.4467 13.0927 14.5767 12.9177C14.6867 12.7727 14.7242 10.9852 14.4217 10.8227ZM11.7692 13.1027C11.7692 13.1027 11.8517 12.7352 11.6792 12.6502C11.5067 12.5627 8.54668 12.6027 8.31168 12.6152C8.07668 12.6277 8.07668 13.0477 8.07668 13.0477L6.21168 12.0877L6.17668 11.7302L13.2517 11.7802L13.2267 12.1177L11.7692 13.1027Z" fill="#F79429"/>
<path d="M1.32812 23.0234C1.32812 29.0134 6.75563 31.5059 10.3581 31.3984C14.5556 31.2734 19.0706 28.0884 18.6981 22.3734C18.3481 17.0109 13.8331 14.5509 9.88812 14.5784C5.30312 14.6134 1.32812 17.7909 1.32812 23.0234Z" fill="#F79429"/>
<path d="M10.1658 30.4927C10.1033 30.4927 10.0383 30.4927 9.97579 30.4902C8.1008 30.4277 6.2483 29.6377 4.8933 28.3252C3.4433 26.9202 2.6483 25.0202 2.6583 22.9777C2.6808 17.9902 7.0408 15.6777 9.99829 15.6777H10.0233C14.0308 15.6952 17.3208 18.8102 17.4133 23.0327C17.4533 24.9127 16.8533 26.7827 15.3433 28.2677C13.9333 29.6477 11.8933 30.4927 10.1658 30.4927ZM9.99329 16.5077C7.3408 16.5077 3.4183 18.6777 3.4083 22.9777C3.4008 26.1927 5.8908 29.4977 10.0383 29.6377C11.6183 29.6877 13.4008 29.1027 14.7133 27.8127C16.0783 26.4702 16.6908 24.7202 16.6633 23.0452C16.6033 19.4127 13.6333 16.5227 10.0283 16.5077C10.0208 16.5027 10.0008 16.5077 9.99329 16.5077Z" fill="#D25116"/>
<path d="M0.378036 0.930104C0.220536 1.0751 6.24304 11.8001 6.24304 11.8001C6.24304 11.8001 7.25304 11.9301 9.50804 11.9501C11.763 11.9701 12.8805 11.8201 12.8805 11.8201C12.8805 11.8201 8.19804 1.0376 7.91804 0.930104C7.78054 0.875104 5.92554 0.855104 4.11304 0.835104C2.29804 0.812604 0.528036 0.790104 0.378036 0.930104Z" fill="#2E9DF4"/>
<path d="M12.7366 20.9273C12.4466 18.4148 9.52162 18.2048 8.30912 19.1698C7.12912 20.1098 7.24162 21.4198 8.06912 21.5723C8.72162 21.6923 8.89162 21.1748 9.08162 20.9373C9.51162 20.4048 10.1966 20.3548 10.6441 20.7823C11.1516 21.2698 10.8391 22.4348 10.0766 22.4823C9.50912 22.5173 9.22162 22.4873 9.14912 22.5848C9.04662 22.7223 9.06162 23.8048 9.16662 23.9248C9.28662 24.0623 9.76662 23.9773 10.1791 23.9923C10.7466 24.0148 11.3491 24.8548 10.9516 25.4848C10.5391 26.1373 9.44162 25.8448 9.04662 25.4498C8.52412 24.9273 8.00162 25.2273 7.82662 25.4323C7.55162 25.7573 7.25912 26.4973 8.27412 27.2173C9.28662 27.9373 12.3591 28.0748 12.9791 25.8448C13.5191 23.8973 12.2591 23.0973 12.2591 23.0973C12.2591 23.0973 12.9016 22.3598 12.7366 20.9273Z" fill="#D25116"/>
</svg>
 :
    reportDashboardData.type !== 'Challenge' ?
      <div>
        {d?.submissionStatus && reportDashboardData?.adminEvaluation == "completed" &&
        <select
          className={styles.customselect}
          name={d?.email}
          value={winnerValue[d?.email]}
          defaultValue={'choose'}
          onChange={(e) => (handleChange(e, d?.email)) }
        >
          <option value={'choose'} disabled>Choose</option>
          <option value={'First'}>1st</option>
          <option value={'Second'}>2nd</option>
          <option value={'Third'}>3rd</option>
        </select>
            }
      </div>
      :
      <div>
        {d?.submissionStatus &&
        <select
          className={styles.customselect}
          name={d?.email}
          value={winnerValue[d?.email]}
          defaultValue={'choose'}
          onChange={(e) => (handleChange(e, d?.email)) }
        >
          <option value={'choose'} disabled>Choose</option>
          <option value={'First'}>1st</option>
          <option value={'Second'}>2nd</option>
          <option value={'Third'}>3rd</option>
        </select>
            }
      </div>        
                    }                  
                  </>
                )
              },
            ]}
            isLoading={stuPerfDataLoading}
          />
        )}
      </Tabs>
      <div>
      <Link
        to={{ pathname: "/hackathon", state: { customer: props?.location?.state?.customerId } }}
        className="terv-back"
      >
        <Button
          outline
          style={{ display: "inline-block !important", fontSize: 16 }}
        >
          <ArrowBack />
          &nbsp;&nbsp;
          {"Go Back"}
        </Button>
        </Link>
      </div>
      <br />
      <br />
      <ToastContainer />
    </div>
  );
}

export default withRouter(AdminAssessmentReport);
