import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Label } from "reactstrap";
import { InputField } from "./InputField";
import "./ViewQ.scss";
import api from "../../api/baseConfig";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import Axios from "axios";
import moment from "moment";
import ViewQuestionTable from "./ViewQuestionTable";
import "./ViewQ.scss";
import Cookies from "universal-cookie";

const tableHeaders = {
  MC: [
    { label: "Option", value: "optionValue" },
    { label: "Is", value: "answer" },
  ],
  CD: [
    { label: "Input Testcase", value: "inputText" },
    { label: "Output Testcase", value: "outputText" },
  ],
  DESC: [
    { label: "Answer Key", value: "answerKey" },
    { label: "Answer Key Points", value: "answerKeyPoints" },
  ],
};

const typeList = {
  MC: "optionsList",
  CD: "testCaseList",
  DESC: "answerKey",
};

const innerHtmlStyle = {
  backgroundColor: "#FAFBFC",
  border: "1px solid #DFE1E6",
  borderRadius: 5,
  padding: 10,
};

export default function ViewQuestion(props) {
  const [data, setData] = useState({
    name: "",
    description: "",
    question: "",
    type: "",
    complexity: "",
    optionsList: [],
    testCaseList: [],
    answerKey: {},
  });

  useEffect(async () => {
    const cookies = new Cookies();
    const config = {
      method: "post",
      url: `${api.baseURL}${api.questionController.getQuestionById}`,
      headers: {
        authorization: cookies.get("userToken"),
        "Content-Type": "application/json",
      },
      params: {
        id: props?.location?.state?.data.id,
      },
    };
    await Axios(config)
      .then((response) => {
        setData(response.data);
      })
      .catch((e) => {});
  }, []);

  const parseAnswerKeyObject = (answerKeys) => {
    let temp = [];
    for (const key in answerKeys) {
      temp.push({
        answerKey: key,
        answerKeyPoints: answerKeys[key],
      });
    }
    return temp;
  };

  return (
    <div className="page-content">
      <Breadcrumbs title="View Question" breadcrumbItem="Questions" />
      <div style={{ backgroundColor: "#fff", padding: 20 }}>
        <Row style={{ marginBottom: 15 }}>
          <InputField
            len={12}
            id="name"
            label="Name"
            value={data.name}
            disabled
          />
        </Row>
        <Row style={{ marginBottom: 15 }}>
          <Col>
            <Label>Question</Label>
            <p
              dangerouslySetInnerHTML={{ __html: data.question }}
              style={innerHtmlStyle}
              className="questionField"
            />
          </Col>
        </Row>

        <Row style={{ marginBottom: 15 }}>
          <InputField
            len={6}
            id="com"
            label="Complexity"
            value={data.complexity}
            disabled
          />
          <InputField
            len={6}
            id="cat"
            label="Category"
            value={
              data.type === "MC"
                ? "Multi-Choice"
                : data.type === "CD"
                ? "Coding"
                : data.type === "DESC"
                ? "Descriptive"
                : ""
            }
            disabled
          />
        </Row>
        {data.type === "CD" && (
          <Row style={{ marginBottom: 15 }}>
            <Col>
              <label>Sample input</label>
              <p
                dangerouslySetInnerHTML={{ __html: data.sampleInput }}
                style={innerHtmlStyle}
              />
            </Col>
            <Col>
              <label>Sample output</label>
              <p
                dangerouslySetInnerHTML={{ __html: data.sampleOutput }}
                style={innerHtmlStyle}
              />
            </Col>
          </Row>
        )}
        {data.type === "CD" && (
          <Row style={{ marginBottom: 15 }}>
            {data?.inputFormat && (
              <Col>
                <label>Input format</label>
                <p
                  dangerouslySetInnerHTML={{ __html: data?.inputFormat }}
                  style={innerHtmlStyle}
                />
              </Col>
            )}
            {data?.outputFormat && (
              <Col>
                <label>Output format</label>
                <p
                  dangerouslySetInnerHTML={{ __html: data?.outputFormat }}
                  style={innerHtmlStyle}
                />
              </Col>
            )}
          </Row>
        )}
        {data.type === "CD" && data?.inputConstraints && (
          <Row style={{ marginBottom: 15 }}>
            <Col>
              <label>Input Constraints</label>
              <p
                dangerouslySetInnerHTML={{ __html: data?.inputConstraints }}
                style={innerHtmlStyle}
              />
            </Col>
          </Row>
        )}
        {(data.optionsList && data.optionsList.length > 0) ||
        (data.testCaseList && data.testCaseList.length > 0) ||
        (data.answerKey && Object.keys(data.answerKey).length > 0) ? (
          <ViewQuestionTable
            headers={tableHeaders[data.type]}
            values={
              data.type === "DESC"
                ? parseAnswerKeyObject(data.answerKey)
                : data[typeList[data.type]]
            }
          />
        ) : (
          ""
        )}

        {data.type === "CD" &&
          data.languagesList &&
          data.languagesList.length > 0 && (
            <Row style={{ marginBottom: 15, marginTop: 15 }}>
              <Col>
                <Label>Selected Languages</Label>&nbsp;
                <div
                  style={{
                    borderRadius: 5,
                    backgroundColor: "#FAFBFC",
                    border: "1px solid #DFE1E6",
                    padding: 15,
                  }}
                >
                  {data.languagesList.map((s, k) => (
                    <span
                      key={`k{s.optionValue}`}
                      style={{
                        borderRadius: "1.2em",
                        border: "1px solid #DFE1E6",
                        padding: 5,
                        paddingLeft: 8,
                        paddingRight: 8,
                        backgroundColor: "#dbdbdb",
                        color: "#323232",
                        fontWeight: 600,
                        marginRight:
                          k + 1 !== data.languagesList.length ? 10 : 0,
                      }}
                    >
                      {s.optionValue}
                    </span>
                  ))}
                </div>
              </Col>
            </Row>
          )}
        {data && data.createdDate && (
          <div
            style={{ display: "flex", justifyContent: "center", marginTop: 15 }}
          >
            <div
              style={{
                width: "26rem",
                padding: "1em",
                backgroundColor: "#FFF9CC",
                color: "#CC9510",
                fontWeight: 600,
                borderRadius: 5,
                fontSize: 15,
              }}
            >
              This question was created on{" "}
              {moment(data.createdDate).subtract(5, "H").subtract(30, "m").format("LLL")}
            </div>
          </div>
        )}

        <Row style={{ marginTop: 15 }}>
          <InputField
            label="Skill Weightage"
            id="SW"
            value={data.skillWeightage}
            disabled
          />
          <InputField
            label="Knowledge Weightage"
            id="KW"
            value={data.knowledgeWeightage}
            disabled
          />
          <InputField label="Marks" id="marks" value={data.marks} disabled/>
          <InputField
            label="Negative Marks"
            id="neg-marks"
            value={data.negativeMarks}
            disabled
          />
          <InputField label="Duration" id="duartion" value={data.duration} disabled/>
        </Row>
      </div>
      <Link
        className="btn text-muted d-none d-sm-inline-block btn-link"
        to={{
          pathname: "view-questions",
          state: {
            viewQuesTableNameandId:
              props.location?.state?.viewQuesTableNameandId,
            activePage: props?.location?.state?.activePage,
            currentPage: props?.location?.state?.currentPage,
            itemsPerPage: props?.location?.state?.itemsPerPage,
            customerId: props?.location?.state?.customerId,
            viewQues: true,
          },
        }}
      >
        <i className="mdi mdi-arrow-left mr-1" /> Back to Questions{" "}
      </Link>
    </div>
  );
}
