import { IconButton } from "@material-ui/core";
import { ChevronLeft, ChevronRight } from "@material-ui/icons";
import { uid } from "react-uid";
import Style from "./ManualGradingQuestion.module.scss";
import { Button } from "reactstrap";
import { Skeleton } from "@material-ui/lab";
import { useEffect, useState } from "react";
import apiHelper from "api/apiHelper";
import api from "api/baseConfig";
import { sessionPrivileges } from "privileges";

const SectionHeader = ({
  student,
  allStudents,
  setStudent,
  loading,
  assessmentDetails,
  toggleSummaryModal,
  startTime,
  summaryModal,
}) => {
  const ind = allStudents.findIndex((stu) => stu.id === student.id);

  const gradedOne = () => {
    let boo = false;
    for (const key in assessmentDetails?.questions) {
      if (
        assessmentDetails?.questions[key] &&
        assessmentDetails?.questions[key].find((q) => q.manualGradingCompleted)
      ) {
        boo = true;
        break;
      }
    }
    return boo;
  };

  const nextStudent = () => {
    if (allStudents[ind + 1]?.id && allStudents[ind + 1]?.startTime) {
      if (gradedOne()) {
        if (allStudents[ind]?.published) {
          setStudent(allStudents[ind + 1]);
        } else {
          toggleSummaryModal();
        }
      } else {
        setStudent(allStudents[ind + 1]);
      }
    }
  };

  const prevStudent = () => {
    if (allStudents[ind - 1]?.id && allStudents[ind - 1]?.startTime) {
      if (gradedOne()) {
        if (allStudents[ind]?.published) {
          setStudent(allStudents[ind - 1]);
        } else {
          toggleSummaryModal();
        }
      } else {
        setStudent(allStudents[ind - 1]);
      }
    }
  };

  return (
    <div className={Style.rightSectionHeader}>
      <IconButton onClick={prevStudent} disabled={ind === 0 || loading}>
        <ChevronLeft />
      </IconButton>
      <div className={Style.studentName}>{student.name}</div>
      <IconButton
        disabled={ind + 1 === allStudents.length || loading}
        onClick={nextStudent}
      >
        <ChevronRight />
      </IconButton>
    </div>
  );
};

const ActionItems = ({
  currentQuestion,
  setCurrentQuestion,
  assessmentDetails,
  activeSection,
  setAssessmentDetails,
  student,
  toggleSummaryModal,
  viewOnly,
  details,
}) => {
  const userPrivileges = sessionPrivileges();
  const [answerOption, setAnswerOption] = useState({});
  const [totalMarks, setTotalMarks] = useState({});
  const [markErr, setMarkErr] = useState(false);

  useEffect(() => {
    if (!totalMarks[currentQuestion?.id]) {
      setTotalMarks({ ...totalMarks, [currentQuestion?.id]: 0 });
    }

    if (!answerOption[currentQuestion?.id]) {
      setAnswerOption({
        ...answerOption,
        [currentQuestion?.id]: currentQuestion?.solutionMap
          ? currentQuestion?.solutionMap?.isCorrectOption
            ? "Correct".toLowerCase()
            : currentQuestion?.solutionMap?.marksGained === 0
            ? "Wrong".toLowerCase()
            : "Partially correct".toLowerCase()
          : "",
      });
    }
  }, [currentQuestion]);

  useEffect(() => {
    if (answerOption[currentQuestion?.id]) {
      switch (answerOption[currentQuestion?.id]) {
        case "correct": {
          setTotalMarks({
            ...totalMarks,
            [currentQuestion?.id]: currentQuestion?.marks ?? 0,
          });
          break;
        }
        case "wrong": {
          setTotalMarks({
            ...totalMarks,
            [currentQuestion?.id]: currentQuestion?.negativeMarks ?? 0,
          });
          break;
        }
        case "partially correct": {
          setTotalMarks({
            ...totalMarks,
            [currentQuestion?.id]:
              currentQuestion?.solutionMap?.marksGained ?? 0,
          });
          break;
        }
      }
    }
  }, [answerOption]);

  const validateMarks = () => {
    if (!totalMarks[currentQuestion.id]) {
      setMarkErr(true);
      return false;
    } else if (markErr) {
      setMarkErr(false);
    }
    return true;
  };

  const isDefined = assessmentDetails?.questions?.[activeSection];
  const currentQuestionIndex = isDefined
    ? isDefined.findIndex((q) => q.id === currentQuestion.id)
    : null;

  const goForward = () => {
    if (
      isDefined?.length > 0 &&
      currentQuestionIndex !== -1 &&
      currentQuestionIndex < isDefined?.length - 1
    ) {
      setCurrentQuestion({
        ...isDefined[currentQuestionIndex + 1],
        serialNo: currentQuestionIndex + 2,
      });
    }
  };

  const goBackward = () => {
    if (
      isDefined?.length > 0 &&
      currentQuestionIndex !== -1 &&
      currentQuestionIndex !== 0
    ) {
      setCurrentQuestion({
        ...isDefined[currentQuestionIndex - 1],
        serialNo: currentQuestionIndex,
      });
    }
  };

  const submitMarks = async () => {
    if (
      answerOption[currentQuestion?.id] === "partially correct"
        ? validateMarks()
        : true
    ) {
      const body = {
        userId: student.userId,
        assessmentId: assessmentDetails.id,
        adminMarks: {
          [currentQuestion["id"]]: +totalMarks[currentQuestion?.id],
        },
        gradingUserId: userPrivileges.userId,
      };
      try {
        const res = await apiHelper.axiosCallPost(
          `${api.baseURL}${api.userManagement.saveMarks}`,
          "post",
          body
        );
        if (res) {
          let arr = [...assessmentDetails?.questions?.[activeSection]];
          arr[currentQuestionIndex] = {
            ...arr[currentQuestionIndex],
            manualGradingCompleted: true,
          };

          setAssessmentDetails({
            ...assessmentDetails,
            questions: {
              ...assessmentDetails?.questions,
              [activeSection]: arr,
            },
            total:
              assessmentDetails?.total + body.adminMarks[currentQuestion["id"]],
          });
          let boo = [];
          for (const key in assessmentDetails?.questions) {
            for (
              let i = 0;
              i < assessmentDetails?.questions?.[key]?.length;
              i++
            ) {
              if (
                assessmentDetails?.questions?.[key]?.[i]?.solutionMap &&
                assessmentDetails?.questions?.[key]?.[i]
                  ?.manualGradingCompleted === false
              ) {
                boo.push(assessmentDetails?.questions?.[key]?.[i].id);
              }
            }
          }
          if (
            (boo.length === 1 && boo?.[0] === currentQuestion["id"]) ||
            boo.length === 0
          ) {
            toggleSummaryModal();
          }
          goForward();
        }
      } catch (e) {}
    }
  };

  return (
    <div className={Style.actionItemsContainer}>
      <p>Action Items</p>
      {details?.manualGradingCompleted ? (
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: "1rem",
            margin: "0 1rem",
          }}
        >
          {["Correct", "Wrong", "Partially correct"].map((item, ind) => (
            <div
              key={uid(ind)}
              className={
                answerOption?.[currentQuestion?.id] === item.toLowerCase()
                  ? Style.actionGradesPublished
                  : Style.actionGrades
              }
            >
              <input
                type="radio"
                value={item.toLowerCase()}
                checked={
                  answerOption?.[currentQuestion?.id] === item.toLowerCase()
                }
                disabled
              />
              <span style={{ color: "#000", fontSize: "0.9rem" }}>{item}</span>
            </div>
          ))}
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: "1rem",
            margin: "0 1rem",
          }}
        >
          {["Correct", "Wrong", "Partially correct"].map((item, ind) => (
            <div
              key={uid(ind)}
              className={Style.actionGrades}
              onClick={() =>
                setAnswerOption({
                  ...answerOption,
                  [currentQuestion?.id]: item.toLowerCase(),
                })
              }
            >
              <input
                type="radio"
                value={item.toLowerCase()}
                checked={
                  answerOption?.[currentQuestion?.id] === item.toLowerCase()
                }
                readOnly
              />
              <span style={{ color: "#000", fontSize: "0.9rem" }}>{item}</span>
            </div>
          ))}
        </div>
      )}
      <div
        style={{
          margin: "1rem 0",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: 5,
        }}
      >
        <span style={{ color: "#000", fontSize: "0.9rem", fontWeight: 500 }}>
          Total Marks
        </span>
        <input
          type="number"
          value={totalMarks?.[currentQuestion?.id]}
          onChange={(e) =>
            setTotalMarks({
              ...totalMarks,
              [currentQuestion?.id]: e.target.value,
            })
          }
          className="form-control"
          style={{
            width: "4rem",
            height: "2rem",
            fontSize: "0.9rem",
            fontWeight: 500,
            color: "#000",
          }}
          disabled={answerOption?.[currentQuestion?.id] !== "partially correct"}
        />
        <span style={{ color: "#000", fontSize: "0.9rem", fontWeight: 500 }}>
          / {currentQuestion?.marks}
        </span>
      </div>
      <div
        style={{
          display: "flex",
          gap: "0.7rem",
          marginBottom: "1rem",
          justifyContent: "center",
        }}
      >
        <Button
          color="primary"
          outline
          style={{ fontSize: "0.8rem", fontWeight: 500, width: "42.5%" }}
          onClick={goBackward}
        >
          Previous
        </Button>
        <Button
          color="primary"
          outline
          style={{ fontSize: "0.8rem", fontWeight: 500, width: "42.5%" }}
          onClick={goForward}
        >
          Next
        </Button>
      </div>
      <Button
        color="success"
        style={{ width: "90%", margin: "0 auto" }}
        onClick={submitMarks}
        disabled={
          !answerOption?.[currentQuestion?.id] ||
          viewOnly ||
          currentQuestion.solutionMap === null
        }
      >
        Save grade
      </Button>
      {markErr &&
        answerOption?.[currentQuestion?.id] === "partially correct" && (
          <div
            style={{ color: "red", textAlign: "center", marginTop: "0.1rem" }}
          >
            Please enter valid marks
          </div>
        )}
    </div>
  );
};

const SectionQuestions = ({
  assessmentDetails,
  currentQuestion,
  setCurrentQuestion,
  activeSection,
  setActiveSection,
  setAssessmentDetails,
  student,
  toggleSummaryModal,
  viewOnly,
  details,
}) => {
  return (
    <div className={Style.sectionQuestions}>
      <p>Questions</p>
      <div className={Style.sectionContainer}>
        <div className={Style.sectionHeaders}>
          {assessmentDetails?.sections &&
            assessmentDetails?.sections.map((sec, ind) => (
              <div
                key={uid(ind)}
                className={`${Style.sectionHeader} ${
                  activeSection === sec && Style.sectionHeaderColor
                }`}
                onClick={() => {
                  if (sec !== activeSection) {
                    setActiveSection(sec);
                    setCurrentQuestion(
                      assessmentDetails.questions?.[sec]?.[0] || {}
                    );
                  }
                }}
              >
                {sec}
              </div>
            ))}
        </div>
        <div className={Style.allQuestions}>
          {assessmentDetails?.questions &&
            assessmentDetails.questions.hasOwnProperty(activeSection) &&
            assessmentDetails.questions[activeSection].map((ques, idx) => (
              <div
                key={uid(idx)}
                className={`${Style.eachQuestion} ${
                  currentQuestion?.id === ques?.id ? Style.activeQuestion : ""
                } ${ques?.manualGradingCompleted && Style.gradedQuestion}`}
                onClick={() =>
                  setCurrentQuestion({ ...ques, serialNo: idx + 1 })
                }
              >
                {idx + 1}
              </div>
            ))}
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          gap: "1rem",
          justifyContent: "center",
          margin: "2rem 0",
        }}
      >
        {["Active", "Graded", "Not Graded"].map((item, ind) => (
          <div key={uid(ind)} style={{ display: "flex", gap: "0.4rem" }}>
            <span
              style={{
                backgroundColor:
                  item === "Active"
                    ? "#676767"
                    : item === "Graded"
                    ? "#51B960"
                    : "#fff",
                height: "1.1rem",
                width: "1.1rem",
                border: item === "Not Graded" && "1px solid #000",
                borderRadius: 3,
              }}
            />
            <span>{item}</span>
          </div>
        ))}
      </div>
      <ActionItems
        setCurrentQuestion={setCurrentQuestion}
        currentQuestion={currentQuestion}
        assessmentDetails={assessmentDetails}
        activeSection={activeSection}
        setAssessmentDetails={setAssessmentDetails}
        student={student}
        toggleSummaryModal={toggleSummaryModal}
        viewOnly={viewOnly}
        details={details}
      />
    </div>
  );
};

const GradingQuestion = ({
  assessmentDetails,
  currentQuestion,
  setCurrentQuestion,
  student,
  activeSection,
  setActiveSection,
  allStudents,
  setStudent,
  loading,
  setAssessmentDetails,
  toggleSummaryModal,
  viewOnly,
  startTime,
  summaryModal,
  details,
}) => {
  return (
    <div className={Style.rightSection}>
      <SectionHeader
        student={student}
        allStudents={allStudents}
        setStudent={setStudent}
        loading={loading}
        toggleSummaryModal={toggleSummaryModal}
        assessmentDetails={assessmentDetails}
        startTime={startTime}
        summaryModal={summaryModal}
        details={details}
      />
      {loading ? (
        <Skeleton
          animation="wave"
          variant="rect"
          height={300}
          width="100%"
          style={{ margin: "0 auto" }}
        />
      ) : (
        <SectionQuestions
          activeSection={activeSection}
          setActiveSection={setActiveSection}
          assessmentDetails={assessmentDetails}
          setCurrentQuestion={setCurrentQuestion}
          currentQuestion={currentQuestion}
          setAssessmentDetails={setAssessmentDetails}
          student={student}
          toggleSummaryModal={toggleSummaryModal}
          viewOnly={viewOnly}
          details={details}
        />
      )}
    </div>
  );
};

export default GradingQuestion;
