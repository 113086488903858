import api from "../../api/baseConfig";
import Apihelper from "../../api/apiHelper";

export async function SendMediaHof(media, moduleName, customerName, currentId) {
  let uploadedUrl = "";
  if (media && media.length > 0) {
    let body = new FormData();
    body.append("file", media[0]);
    await Apihelper.axiosUploadFile(
      `${api.baseURL}${api.userManagement.fileUpload}?${customerName ? 
        customerName : ""}&moduleName=${moduleName ? 
          moduleName : ""}&id=${currentId ? currentId : ""}`,
      body
    )
      .then((fileResponse) => {
        uploadedUrl = fileResponse.data.fileURL;
      })
      .catch((e) => {});
  }
  return uploadedUrl;
}
