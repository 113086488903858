import { useState } from "react";
import { Label, Button } from "reactstrap";
import Select from "react-select";
import uuid from "react-uuid";
import { Droppable, Draggable } from "react-beautiful-dnd";
import { DragDropContext } from "react-beautiful-dnd";
import cn from "classnames";
import { Input } from "reactstrap";
import { List, Box } from "@material-ui/core";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import BorderColorIcon from "@material-ui/icons/BorderColor";
import DragHandleIcon from "@material-ui/icons/DragIndicator";
import LaptopIcon from "@material-ui/icons/Laptop";
import CloseIcon from "@material-ui/icons/Close";
import InboxIcon from "@material-ui/icons/Inbox";
import InfoIcon from "@material-ui/icons/Info";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import {
  Card,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@material-ui/core";
import styles from "./LearningPath.module.scss";
import { ArraySwap } from "pages/utils/ArraySwap";

function PathButton({ disabled, Icon, onClick, text }) {
  return (
    <Button
      disabled={disabled}
      onClick={onClick}
      style={{
        backgroundColor: "#F2F1F3",
        border: "none",
        borderRadius: 5,
        padding: "18px 32px",
      }}
    >
      <Icon
        style={{
          color: "#63606C",
          fontSize: 48,
        }}
      />
      <Typography
        align="center"
        style={{
          color: "#323036",
          fontSize: 14,
          fontWeight: 500,
          marginTop: 16,
        }}
      >
        {text}
      </Typography>
    </Button>
  );
}

export function LearningPath({
  categoryHashMap,
  learningPathList,
  onClose,
  onDelete,
  open,
  practiceData,
  setLearningPathList,
  setPracticeData,
  setTestData,
  testData,
  editMock,
  newValueCheck,
}) {
  const [currentSection, setCurrentSection] = useState("list");
  const [edit, setEdit] = useState(null);
  const [editIndex, setEditIndex] = useState(0);
  const [category, setCategory] = useState({});
  const [name, setName] = useState("");
  const [mockAssessments, setMockAssessments] = useState([]);
  const [subcategory, setSubcategory] = useState([]);

  // const onDrop = ({ removedIndex, addedIndex }) => {
  //   setLearningPathList(ArraySwap(learningPathList, removedIndex, addedIndex));
  // };

  // const onDrop  = (e) => {
  //   const source = e.source.index,  destination = e.destination.index;
  //   console.log(source, destination);
  //   if(source > -1 && destination > -1 && source !== destination) {
  //     setLearningPathList(ArraySwap(learningPathList, source, destination));
  //   }
  // }
  
  const onDrop = (result) => {
    const newItems = Array.from(learningPathList);
    const [removed] = newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, removed);
    setLearningPathList(newItems);
  };

  return (
    <Dialog
      keepMounted={false}
      open={open}
      onClose={onClose}
      PaperProps={{
        style: {
          maxWidth: 500,
          minHeight: 200,
          width: "100%",
        },
      }}
    >
      <DialogTitle
        style={{
          position: "relative",
          borderBottom: "1px solid #bbb",
          padding: "8px 0px",
        }}
      >
        <Typography align="center">
          {currentSection === "list" || currentSection === "add"
            ? "Learning Path"
            : currentSection === "practice"
            ? !edit
              ? "Add Practice"
              : `View Path ${(editIndex || 0) + 1} (Practice)`
            : !edit
            ? "Add Mock Assessment"
            : `View Path ${(editIndex || 0) + 1} (Mock Assessment)`}
        </Typography>
        <IconButton
          size="small"
          style={{
            position: "absolute",
            right: 5,
            top: 5,
          }}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent
        style={{
          padding: 24,
        }}
      >
        {currentSection === "add" && (
          <div>
            <Typography
              align="center"
              style={{
                fontWeight: 500,
                color: "#323036",
                fontSize: 16,
                padding: "0 44px",
                lineHeight: "1.5",
              }}
            >
              Please select a learning path you want to add below
            </Typography>
            <br />
            <br />
            <Grid alignItems="center" container justifyContent="center">
              <PathButton
                disabled={
                  !Object.values(practiceData || {}).length
                  //some((d) => d?.length)
                }
                Icon={LaptopIcon}
                onClick={() => {
                  setCurrentSection("practice");
                }}
                text="Practice questions"
              />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <PathButton
                disabled={editMock == 0}
                Icon={BorderColorIcon}
                onClick={() => {
                  setCurrentSection("test");
                }}
                text="Mock assessment"
              />
            </Grid>
            <br />
          </div>
        )}
        {(currentSection === "practice" || currentSection === "test") && (
          <div>
            {!edit && (
              <>
                <Grid
                  alignItems="center"
                  container
                  justifyContent="space-between"
                  style={{
                    backgroundColor: "#EDF9FF",
                    padding: "12px 24px",
                    borderRadius: 5,
                  }}
                >
                  <Typography>
                    Path type:{" "}
                    {currentSection === "practice"
                      ? "Practice Questions"
                      : "Mock Assessment"}
                  </Typography>
                  <Typography
                    onClick={() => {
                      setCurrentSection("add");
                      setCategory({});
                      setSubcategory([]);
                      setMockAssessments([]);
                      setName("");
                    }}
                    style={{
                      cursor: "pointer",
                      textDecoration: "underline",
                    }}
                  >
                    Change
                  </Typography>
                </Grid>
                <br />
              </>
            )}
            <Grid
              alignItems="flex-start"
              container
              wrap="nowrap"
              justifyContent="flex-start"
              style={{
                backgroundColor: "#FFFBEF",
                border: "1px solid #EEB60C",
                borderRadius: 5,
                padding: "12px 20px",
              }}
            >
              <InfoIcon
                style={{
                  color: "#EEB60C",
                }}
              />
              &nbsp;&nbsp;&nbsp;&nbsp;
              <Typography>
                {currentSection === "practice"
                  ? "Choose any one category, from that add many sub-category you want"
                  : "Choose the mock assessment you want"}
              </Typography>
            </Grid>
            <br />
            <br />
            <Label>Path name</Label>
            <Input
              disabled={edit}
              defaultValue={edit ? learningPathList[editIndex]?.name : ""}
              onChange={(e) => {
                setName(e.target.value);
              }}
              placeholder="Path name"
            />
            <br />
            <br />
            {currentSection === "practice" && (
              <>
                <Label>Choose Category</Label>
                <Select
                  isDisabled={edit}
                  options={Object.entries(practiceData)
                    .filter((c) => c[1]?.length)
                    .map((c) => ({
                      label: categoryHashMap[c[0]],
                      value: c[0],
                    }))}
                    value={
                      edit
                        ? {
                            label:
                              categoryHashMap[
                                learningPathList[editIndex]?.category
                              ],
                            value: learningPathList[editIndex]?.category,
                          }
                        : category
                        // ? category
                        // : []
                    }
                  onChange={(val) => {
                    setCategory(val);
                    setSubcategory([]);
                  }}
                  placeholder="Choose category"
                />
                <br />
                <br />
                <Label>Choose sub-category</Label>
                <Select
                  isDisabled={edit || !category}
                  options={
                    category
                      ? practiceData?.[category?.value]?.map?.((sc) => ({
                          label: categoryHashMap[sc],
                          value: sc,
                        }))
                      : []
                  }
                  isMulti
                  value={
                    edit
                      ? (learningPathList[editIndex]?.subCategory || []).map(
                          (sc) => ({
                            label: categoryHashMap[sc],
                            value: sc,
                          })
                        )
                      : subcategory || []
                  }
                  onChange={(val) => {
                    setSubcategory(val);
                  }}
                  placeholder="Choose Sub category"
                />
              </>
            )}
            {currentSection === "test" && (
              <>
                <Label>Select Mock assessments</Label>
                <Select
                  isDisabled={edit}
                  options={
                    testData.map((ma) => ({
                    label: ma.label,
                    value: ma.value,
                    })
                  )}
                  isMulti
                  value={
                    edit
                      ? learningPathList[editIndex].mockAssessments
                      : mockAssessments
                  }
                  onChange={(val) => {
                    setMockAssessments(val);
                  }}
                  placeholder="Choose mock assessments"
                />
              </>
            )}
            <br />
            <br />
            <Grid container justifyContent={edit ? "center" : "space-between"}>
              <Button
                size="lg"
                outline
                color="primary"
                onClick={() => {
                  setName("");
                  setMockAssessments([]);
                  setCategory({});
                  setSubcategory([]);
                  setCurrentSection("list");
                }}
              >
                {edit ? "Back" : "Cancel"}
              </Button>
              {!edit && (
                <Button
                  disabled={
                    !name ||
                    (currentSection === "test"
                      ? !mockAssessments?.length
                      : !category?.value || !subcategory?.length)
                  }
                  size="lg"
                  color="primary"
                  onClick={() => {
                    setName("");
                    if (currentSection === "practice") {
                      const oldPracticeData = {
                        ...practiceData,
                        [category?.value]: practiceData?.[
                          category?.value
                        ]?.filter(
                          (sb) => !subcategory.find(({ value }) => value === sb)
                        ),
                      };
                      const newPracticeData = {};

                      Object.entries(oldPracticeData)
                        .filter((tpd) => tpd[1]?.length)
                        .forEach((tpd) => {
                          newPracticeData[tpd[0]] = tpd[1];
                        });

                      setPracticeData(newPracticeData);
                    } else {
                      setTestData(
                        testData?.filter(
                          ({ value }) =>
                            !mockAssessments?.find(
                              ({ value: val }) => value === val
                            )
                        )
                      );
                    }

                    setLearningPathList([
                      ...learningPathList,
                      {
                        name,
                        id: uuid(),
                        ...(currentSection === "practice" && {
                          type: "Practice",
                          category: category?.value,
                          subCategory: subcategory?.map?.(({ value }) => value),
                        }),
                        ...(currentSection === "test" && {
                          type: "Mock Assessment",
                          mockAssessments,
                        }),
                      },
                    ]);
                    setMockAssessments([]);
                    setCategory({});
                    setSubcategory([]);
                    setCurrentSection("list");
                  }}
                >
                  Add
                </Button>
              )}
            </Grid>
            <br />
          </div>
        )}
        {currentSection === "list" && (
          <>
            <Grid
              alignItems="center"
              container
              justifyContent="space-between"
              wrap="nowrap"
            >
              <div>
                <Typography style={{ fontSize: 16, marginBottom: 2 }}>
                  Create your own learning path
                </Typography>
                <Typography style={{ color: "#4A4851", fontSize: 12 }}>
                  Add your learning path for structured package
                </Typography>
              </div>
              <Button
                size="lg"
                color="primary"
                onClick={() => {
                  setCurrentSection("add");
                  setEdit(false);
                  setEditIndex(0);
                }}
                disabled={
                  newValueCheck?.length == 0 && editMock == 0
                }
              >
                Add path
              </Button>
            </Grid>
            <br />
            <br />
            {!!learningPathList?.length ? (
            <DragDropContext onDragEnd={onDrop}>
              <Droppable droppableId={`droppableLessons`} type="LESSONS">
              {(providedOuter) => (
                  <div
                    ref={providedOuter.innerRef}
                    {...providedOuter.droppableProps}
                  >
                {learningPathList?.map(({ name, id, type }, idx) => (
                  <div className={styles?.timeLine}>
                    <div style={{paddingTop: '10px'}}>
                      <Draggable key={`learningPath-${idx}`} draggableId={`mapindex-${idx}`} index={idx} >
                        {(provided) => (
                          <div
                            style={{paddingBottom: 16}}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                          >
                          <Card
                            classes={{
                            root: cn(
                              styles.card,
                              type === "Practice" && styles.practice
                            ),
                          }}
                          >
                          <Grid
                            wrap="nowrap"
                            alignItems="center"
                            classes={{
                              root: styles.listItem,
                            }}
                            container
                            justifyContent="space-between"
                          >
                        <Typography
                          noWrap
                          align="center"
                          className={styles.pathNumber}
                          style={{ flexShrink: 0 }}
                        >
                          Path {idx + 1}
                        </Typography>
                        <Box mx={1} />
                        <Typography noWrap>{name}</Typography>
                        <Box mx={1} />
                        <Box mx="auto" />
                        <IconButton
                          color="primary"
                          onClick={() => {
                            setEdit(true);
                            setEditIndex(idx);
                            setCurrentSection(
                              type === "Practice" ? "practice" : "test"
                            );
                          }}
                        >
                          <VisibilityOutlinedIcon />
                        </IconButton>
                        <IconButton
                          color="secondary"
                          onClick={() => {
                            onDelete(() => {
                              const deletionPath = learningPathList.find(
                                ({ id: pid }) => pid === id
                              );
                              if (deletionPath?.type === "Practice") {
                                setPracticeData({
                                  ...practiceData,
                                  ...(practiceData?.[deletionPath.category]
                                    ?.length
                                    ? {
                                        [deletionPath.category]: [
                                          ...practiceData?.[
                                            deletionPath.category
                                          ],
                                          ...deletionPath.subCategory,
                                        ],
                                      }
                                    : {
                                        [deletionPath.category]:
                                          deletionPath.subCategory,
                                      }),
                                });
                              } else {
                                setTestData([
                                  ...testData,
                                  ...deletionPath.mockAssessments,
                                ]);
                              }

                              setLearningPathList(
                                learningPathList.filter(
                                  ({ id: pid }) => pid !== id
                                )
                              );
                            });
                          }}
                        >
                          <DeleteOutlineIcon />
                        </IconButton>
                        <IconButton
                          disableRipple
                          {...provided.dragHandleProps}
                          size="small"
                          className="drag-handle"
                        >
                          <DragHandleIcon />
                        </IconButton>
                      </Grid>
                    </Card>
                  </div>
                  )}
                  </Draggable>
                  </div>
                  </div>
                ))}
                {providedOuter.placeholder}
              </div>
              )}
              </Droppable>
            </DragDropContext>
            ): ''}
             {/* {!!learningPathList?.length ? (
              <List
                classes={{
                  root: cn(
                    styles.learningPathList,
                    learningPathList?.length && styles.notEmpty
                  ),
                }}
              >
                <Container
                  dragHandleSelector=".drag-handle"
                  lockAxis="y"
                  onDrop={onDrop}
                >
                  {console.log(learningPathList)}
                  {learningPathList?.map(({ name, id, type }, idx) => (
                    <Draggable key={id}>
                      <div
                        style={{
                          paddingBottom: 16,
                        }}
                      >
                        <Card
                          classes={{
                            root: cn(
                              styles.card,
                              type === "Practice" && styles.practice
                            ),
                          }}
                        >
                          <Grid
                            wrap="nowrap"
                            alignItems="center"
                            classes={{
                              root: styles.listItem,
                            }}
                            container
                            justifyContent="space-between"
                          >
                            <Typography
                              noWrap
                              align="center"
                              className={styles.pathNumber}
                              style={{ flexShrink: 0 }}
                            >
                              Path {idx + 1}
                            </Typography>
                            <Box mx={1} />
                            <Typography noWrap>{name}</Typography>
                            <Box mx={1} />
                            <Box mx="auto" />
                            <IconButton
                              color="primary"
                              onClick={() => {
                                setEdit(true);
                                setEditIndex(idx);
                                setCurrentSection(
                                  type === "Practice" ? "practice" : "test"
                                );
                              }}
                            >
                              <VisibilityOutlinedIcon />
                            </IconButton>
                            <IconButton
                              color="secondary"
                              onClick={() => {
                                onDelete(() => {
                                  const deletionPath = learningPathList.find(
                                    ({ id: pid }) => pid === id
                                  );
                                  if (deletionPath?.type === "Practice") {
                                    setPracticeData({
                                      ...practiceData,
                                      ...(practiceData?.[deletionPath.category]
                                        ?.length
                                        ? {
                                            [deletionPath.category]: [
                                              ...practiceData?.[
                                                deletionPath.category
                                              ],
                                              ...deletionPath.subCategory,
                                            ],
                                          }
                                        : {
                                            [deletionPath.category]:
                                              deletionPath.subCategory,
                                          }),
                                    });
                                  } else {
                                    setTestData([
                                      ...testData,
                                      ...deletionPath.mockAssessments,
                                    ]);
                                  }

                                  setLearningPathList(
                                    learningPathList.filter(
                                      ({ id: pid }) => pid !== id
                                    )
                                  );
                                });
                              }}
                            >
                              <DeleteOutlineIcon />
                            </IconButton>
                            <IconButton
                              disableRipple
                              size="small"
                              className="drag-handle"
                            >
                              <DragHandleIcon />
                            </IconButton>
                          </Grid>
                        </Card>
                      </div>
                    </Draggable>
                  ))}
                </Container>
              </List>
            ) : (
              <>
                <br />
                <br />
                <Grid
                  alignItems="center"
                  container
                  direction="column"
                  justifyContent="center"
                >
                  <InboxIcon
                    style={{ fontSize: 128, color: "#ccc", marginBottom: 8 }}
                  />
                  <Typography align="center">No path added</Typography>
                </Grid>
                <br />
              </>
            )} */}
          </>
        )}
      </DialogContent>
    </Dialog>
  );
}
