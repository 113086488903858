import { useContext } from 'react';
import { TestContext } from '../../context/test.context';
import { LargeHeader } from './components/large-header/large-header';
import { SmallHeader } from './components/small-header/small-header';
import { SmallFooter } from './components/small-footer/small-footer';
import { TProps } from './header-footer.props';

export function HeaderFooter(props: TProps) {
  const { smallScreen } = useContext(TestContext);

  return smallScreen
    ? (
      <>
        <SmallHeader
          {...props}
        />
        <SmallFooter
          {...props}
        />
      </>
    )
    : (
      <LargeHeader
        {...props}
      />
    );
}
