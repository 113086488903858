export const InternetSpeedChecker = async ({ setSpeed }) => {
  var userImageLink =
    "https://media.geeksforgeeks.org/wp-content/cdn-uploads/20200714180638/CIP_Launch-banner.png";
  var time_start, end_time;

  // The size in bytes
  var downloadSize = 5616998;
  var downloadImgSrc = new Image();

  const setSpeedState = (speed) => {
    if (speed.hasOwnProperty("bps")) {
      setSpeed(speed);
    }
  };

  downloadImgSrc.onload = function () {
    end_time = new Date().getTime();
    displaySpeed(setSpeedState);
  };
  time_start = new Date().getTime();
  downloadImgSrc.src = userImageLink;

  function displaySpeed(callback) {
    var timeDuration = (end_time - time_start) / 1000;
    var loadedBits = downloadSize * 8;

    var bps = (loadedBits / timeDuration).toFixed(2);
    var speedInKbps = (bps / 1024).toFixed(2);
    var speedInMbps = (speedInKbps / 1024).toFixed(2);
    callback({ bps, speedInKbps, speedInMbps });
  }
};
