import ReactAudioPlayer from 'react-audio-player';
import audio from "./audio.mp3";
import './index.css';

const AudioBasedQuestion = ({ audioFile }) => {

  const audiofile = `data:audio/wav;base64,${audioFile}`;

  return (
    <div className="audio-player-container">
      <ReactAudioPlayer
        src={audioFile? audiofile : audio}
        autoPlay={false}
        controls
        controlsList={"nodownload"}
      />
    </div>
  );
};

export default AudioBasedQuestion;
