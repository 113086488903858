import React, { useState, useEffect } from "react";
import MaterialTable, { MTableToolbar } from "@material-table/core";
import {
  Container,
  Button,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  UncontrolledTooltip,
  Input,
} from "reactstrap";
import Select from "react-select";
import api from "../../../api/baseConfig";
import Apihelper from "../../../api/apiHelper";
import Breadcrumbs from "components/Common/Breadcrumb";
import { Link } from "react-router-dom";
import { sessionPrivileges } from "privileges";

const userTypes = [
  { label: "Author", value: "Author" },
  { label: "Administrator", value: "Administrator" },
  { label: "Learner", value: "Learner" },
];

const defaultValue = { value: "", label: "Select Role" };

export default function UserRole() {
  const userPrivileges = sessionPrivileges();
  const [rows, setRows] = useState([]);
  const [modal, setModal] = useState(false);
  const [userID, setUserID] = useState("");
  const [edit, setEdit] = useState(false);
  const [initialValue, setInitialValue] = useState("");
  const [selectedType, setSelectedType] = useState(defaultValue);

  const toggle = () => {
    setModal(!modal);
    if (edit) {
      setEdit(false);
      setSelectedType(defaultValue);
      setUserID("");
    }
  };

  const handleEdit = (row) => {
    userTypes.forEach((u) => {
      if (row.role === u.value) {
        setSelectedType(u);
        setInitialValue(row.role);
      }
    });

    setUserID(row.userId);

    setEdit(true);
  };

  const createNewUser = async () => {
    let body = {
      userId: userID,
      role: selectedType.value,
      updatedBy: userPrivileges.userName,
    };
    await Apihelper.axiosCallPost(
      `${api.baseURL}${api.userManagement.saveNewRole}`,
      "post",
      body
    )
      .then((d) => {
        setUserID("");
        setSelectedType(defaultValue);

        setInitialValue("");
        toggle();
        getAllRoles();
      })
      .catch((e) => {});
  };

  const getAllRoles = async () => {
    await Apihelper.axiosCall(
      `${api.baseURL}${api.userManagement.getUserRolesList}`,
      "get"
    )
      .then((data) => {
        let tempRows = [];
        data.forEach((datum, ind) => {
          tempRows.push({
            num: ind + 1,
            role: datum.role,
            userId: datum.userId,
            actions: (
              <div
                onClick={() => {
                  handleEdit(datum);
                  setModal(true);
                }}
                className="mr-3 title-primary"
                style={{ color: "red", cursor: "pointer" }}
              >
                <i
                  className="mdi mdi-pencil font-size-18 mr-3"
                  id="edittooltip"
                />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </div>
            ),
          });
        });

        setRows(tempRows);
      })
      .catch((e) => {});
  };

  useEffect(async () => {
    getAllRoles();
  }, []);

  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs title="Master" breadcrumbItem="User Roles" />
        <Modal isOpen={modal} toggle={toggle} centered>
          <ModalHeader>{edit ? "Edit Role" : "Create new Role"}</ModalHeader>
          <ModalBody>
            <Input
              type="text"
              value={userID}
              disabled={edit}
              placeholder="User ID"
              onChange={(e) => setUserID(e.target.value)}
            />
            &nbsp;
            <Select
              value={selectedType}
              options={userTypes}
              onChange={(val) => setSelectedType(val)}
            />
            &nbsp;
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              onClick={createNewUser}
              disabled={
                edit
                  ? selectedType.value === initialValue
                  : selectedType.value && userID
                  ? false
                  : true
              }
            >
              Create
            </Button>
            <Button onClick={toggle}>Close</Button>
          </ModalFooter>
        </Modal>
        <MaterialTable
          title="ROLES"
          components={{
            Toolbar: (props) => (
              <div className="custom-table-toolbar pr-16">
                <MTableToolbar {...props} />
              </div>
            ),
          }}
          actions={[
            {
              icon: () => (
                <Button
                  onClick={toggle}
                  type="button"
                  color="success"
                  className="btn-rounded waves-effect waves-light mb-2 mr-2"
                >
                  <i className="mdi mdi-plus mr-1" />
                  Add user
                </Button>
              ),
              isFreeAction: true,
            },
          ]}
          columns={[
            {
              title: "#",
              field: "num",
              sort: "asc",
              width: 50,
            },
            {
              title: "User ID",
              field: "userId",
              sort: "asc",
              width: 200,
            },
            {
              title: "Role",
              field: "role",
              sort: "asc",
              width: 200,
            },

            {
              title: "Actions",
              field: "actions",
              width: 100,
              searchable: true,
            },
          ]}
          data={rows}
          //isLoading={this.state.rows.length + data.columns.length === 0}
          options={{
            search: true,
            loadingType: "linear",
            padding: "default",
            pageSize: 10,
            tableLayout: "auto",
            exportButton: true,
            columnsButton: true,
            maxBodyHeight: 500,
            headerStyle: {
              whiteSpace: "nowrap",
              fontWeight: "bold",
              fontSize: "1.00rem !important",
            },
            rowStyle: {
              whiteSpace: "nowrap",
            },
            paginationPosition: "both",
          }}
        />
      </Container>
    </div>
  );
}
