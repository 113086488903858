import { AddImageComponent } from "components/AddImageComponent";

export const QuestionImage = ({ 
  input: { value, onChange },  
  moduleName,
  customerName,
  currentId,
}) => {
  return (
  <AddImageComponent
    handleCroppedImage={onChange}
    media={
      value
        ? value.includes("default")
          ? `https://d2ndap9rlps54r.cloudfront.net/DefaultUploadImages/${value}.jpeg`
          : value
        : ""
    }
    moduleName={moduleName}
    customerName={customerName}
    currentId={currentId}
  />
)}
