import React from "react";
import { ContentHeader } from "./ContentHeader";
import { HomeContent } from "./HomeContent";
import styles from "./Resources.module.scss";
import { uid } from "react-uid";
import {Link} from "react-router-dom";
import { useTranslation } from "react-i18next";
import Resource1 from "../../../assets/Landing/Resourse1.png";
import Resource2 from "../../../assets/Landing/Resource2.png";
import Resource3 from "../../../assets/Landing/Resource3.png";
import Resource4 from "../../../assets/Landing/Resource4.png";
import { Button } from "reactstrap";

type ResObj = {
  image: string;
  title: string;
  link: string;
  tag: string;
  description: string;
};

const Resources: React.FC = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.container}>
      {/* <ContentHeader
        title={t(HomeContent.Headers.Resources.Main)}
        sub={t(HomeContent.Headers.Resources.Sub)}
        description={t(HomeContent.Headers.Resources.Description)}
        titleRev
      /> */}
       <div className={styles.content__header__container}>
    <p className={styles.content__header__title_wrap}>
    
        <>
        <span className={styles.content__header__sub} style={{color:"#ffffff"}}>{t(HomeContent.Headers.Resources.Sub)}</span>{" "}
          <span className={styles.content__header__title} >{t(HomeContent.Headers.Resources.Main)}</span>{" "}
        </>
    </p>
    <p className={styles.content__header__description} style={{color:"#ffffff"}}>{t(HomeContent.Headers.Resources.Description)}</p>
  </div>
      <div className={styles.resourcesContainer}>
          <Link to={{ pathname: " https://blog.terv.pro/interview_prep/the-art-of-communication/" }}  className={styles.resourceCard} target="_blank">
            <div>
              <img src={Resource1}  />
            </div>
            <p className={styles.resourceCardTitle}>Why Practicing is cosidered as best to increase your Knowledge?</p>
            <p className={styles.resourceCardTitle} style={{display:"flex", justifyContent:"space-between"}}>
            <svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="19.7872" cy="20.5174" r="19.6231" fill="#D9D9D9"/>
</svg>
<span style={{paddingTop:"2%"}}>
              <p className={styles.resourceCardTitle} >Matthew</p>
              </span>
              <Button
                color="success"
                style={{
                  width: "120px",
                  height: "42px",
                }}
              >
                Career Hacks
              </Button>

              </p>
          </Link>

<Link to={{ pathname: " https://blog.terv.pro/interview_prep/the-art-of-communication/" }}  className={styles.resourceCard} target="_blank">
<div>
  <img src={Resource2}  />
</div>
<p className={styles.resourceCardTitle}>Why Practicing is cosidered as best to increase your Knowledge?</p>
<p className={styles.resourceCardTitle} style={{display:"flex", justifyContent:"space-between"}}>
            <svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="19.7872" cy="20.5174" r="19.6231" fill="#D9D9D9"/>
</svg>
<span style={{paddingTop:"2%"}}>
              <p className={styles.resourceCardTitle} >Matthew</p>
              </span>
              <Button
                color="info"
                style={{
                  width: "120px",
                  height: "42px",
                }}
              >
                Companies
              </Button>

              </p>
</Link>

<Link to={{ pathname: " https://blog.terv.pro/interview_prep/the-art-of-communication/" }}  className={styles.resourceCard} target="_blank">
<div>
  <img src={Resource3}  />
</div>
<p className={styles.resourceCardTitle}>Why Practicing is cosidered as best to increase your Knowledge?</p>
<p className={styles.resourceCardTitle} style={{display:"flex", justifyContent:"space-between"}}>
            <svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="19.7872" cy="20.5174" r="19.6231" fill="#D9D9D9"/>
</svg>
<span style={{paddingTop:"2%"}}>
              <p className={styles.resourceCardTitle} >Matthew</p>
              </span>
              <Button
                color="warning"
                style={{
                  width: "120px",
                  height: "42px",
                }}
              >
                College Life
              </Button>

              </p>
</Link>

<Link to={{ pathname: " https://blog.terv.pro/interview_prep/the-art-of-communication/" }}  className={styles.resourceCard} target="_blank">
<div>
  <img src={Resource4}  />
</div>
<p className={styles.resourceCardTitle}>Why Practicing is cosidered as best to increase your Knowledge?</p>
<p className={styles.resourceCardTitle} style={{display:"flex", justifyContent:"space-between"}}>
            <svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="19.7872" cy="20.5174" r="19.6231" fill="#D9D9D9"/>
</svg>
<span style={{paddingTop:"2%"}}>
              <p className={styles.resourceCardTitle} >Matthew</p>
              </span>
              <Button
                color="danger"
                style={{
                  width: "120px",
                  height: "42px",
                }}
              >
                Interview Prep
              </Button>

              </p>
</Link>
      </div>
    </div>
  );
};

export default Resources;
