import React from "react";
import { Button, InputGroup } from "reactstrap";
import AceEditor from "react-ace";
import "brace/mode/javascript";
import "brace/mode/java";
import "brace/theme/monokai";
import "brace/theme/github";
import "brace/mode/c_cpp";
import "brace/mode/python";
import "brace/mode/perl";
import "brace/mode/pgsql";
import "brace/ext/language_tools";
import api from "../../api/baseConfig";
import Apihelper from "../../api/apiHelper";
import ReactHtmlParser from "html-react-parser";
import { sessionPrivileges } from "privileges";

export default class CodeLab extends React.Component {
  constructor(props) {
    super(props);
    this.userPrivileges = sessionPrivileges(true);
    this.state = {
      outputCode: "",
      inputState: "output",
      theme: "monokai",
      codevalue: "",
      inputvalue: "",
      editorlanguage:
        props.location.state &&
        props.location.state.languagesList &&
        props.location.state.languagesList[0].optionValue
          ? props.location.state.languagesList[0].optionValue.toLowerCase()
          : "",
      language:
        props.location.state &&
        props.location.state.languagesList &&
        props.location.state.languagesList[0].id
          ? props.location.state.languagesList[0].id
          : "",
      languagesList: props.location.state.languagesList,
      highlightState: {
        borderBottom: "5px solid red",
        paddingLeft: 15,
        paddingRight: 15,
      },
      nonHighlight: { paddingLeft: 15, paddingRight: 15 },
      curstate: {
        active: {
          float: "left",
          margin: 10,
          padding: 5,
          borderRadius: 5,
          width: 25,
          height: 25,
          backgroundColor: "#3383f9",
          justifyContent: "center",
          textAlign: "center",
        },
        marked: {
          float: "left",
          margin: 10,
          padding: 5,
          borderRadius: 5,
          width: 25,
          height: 25,
          backgroundColor: "#dec808",
          justifyContent: "center",
          textAlign: "center",
        },
        unanswered: {
          float: "left",
          margin: 10,
          padding: 5,
          borderRadius: 5,
          width: 25,
          height: 25,
          backgroundColor: "#df0606",
          justifyContent: "center",
          textAlign: "center",
        },
        answered: {
          float: "left",
          margin: 10,
          padding: 5,
          borderRadius: 5,
          width: 25,
          height: 25,
          backgroundColor: "#05c759",
          justifyContent: "center",
          textAlign: "center",
        },
        unvisited: {
          float: "left",
          margin: 10,
          padding: 5,
          borderRadius: 5,
          width: 25,
          height: 25,
          backgroundColor: "#d6dce3",
          justifyContent: "center",
          textAlign: "center",
        },
      },
    };

    if (this.props.location.state.solutionMap != null) {
      this.setState({
        codevalue: this.props.location.state.solutionMap.codeSolution,
      });
    }
  }

  componentDidMount() {
    this.getAllLanguages();
  }

  handleNumberNavigate(e) {
    this.props.questionSetter({ num: e });
  }
  handleCode() {
    try {
      let body = {
        collegeId: JSON.parse(sessionStorage.getItem("role"))
          ? JSON.parse(sessionStorage.getItem("role")).college_id
          : "",
        languageId: this.state.language,
        questionId: this.props.location.state.id,
        solution: window.btoa(this.state.codevalue),
        userId: this.userPrivileges.userName,
        // userId: sessionStorage.getItem("user_id"),
      };
      Apihelper.axiosCallPost(
        `${api.baseURL}${api.userManagement.executeCode}`,
        "post",
        body
      ).then((data) => {
        if (data && data.Judge0Response_Description) {
          let val =
            data.Judge0Response_Description +
            "\n" +
            data.judge0Response.compile_output;
          this.setState({
            outputCode: val,
          });
        } else {
          this.setState({
            outputCode: "error",
          });
        }
      });
    } catch (e) {}
  }
  handleConfirm() {
    try {
      let body = {
        collegeId: JSON.parse(sessionStorage.getItem("role"))
          ? JSON.parse(sessionStorage.getItem("role")).college_id
          : "",
        labId: this.props.location.state.labId,
        languagesList: this.props.location.state.languagesList,
        languageId: this.state.language,
        questionId: this.props.location.state.id,
        codeSolution: window.btoa(this.state.codevalue),
        userId: this.userPrivileges.userName,
        // userId: sessionStorage.getItem("user_id")
        //   ? sessionStorage.getItem("user_id")
        //   : 10001,
      };
      Apihelper.axiosCallPost(
        `${api.baseURL}${api.lab.saveUserSolution}`,
        "post",
        body
      ).then((data) => {
        this.setState({
          outputCode: "",
        });
        this.props.history.push("/lab-questions");
      });
    } catch (e) {}
  }

  async getAllLanguages() {
    await Apihelper.axiosCall(`${api.baseURL}${api.lab.getAllLanguages}`, "get")
      .then((res) => {
        this.setState({
          languagesList: res.data,
        });
      })
      .catch((e) => {});
  }

  render() {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          padding: 5,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "35%",
            minHeight: "70vh",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              backgroundColor: "#fff",
              marginTop: 40,
              minHeight: "85vh",
              padding: 5,
            }}
          >
            <div>
              {1}
              {".    "}
              {ReactHtmlParser(this.props.location.state.question || "")}
            </div>
            <div style={{ marginTop: 15, fontWeight: "bold" }}>
              Input Description
            </div>
            <div>{ReactHtmlParser(this.props.location.state.description || "")}</div>
            <div
              style={{ marginTop: 15, fontWeight: "bold", marginTop: "15%" }}
            >
              Output Description
            </div>
            <div>{ReactHtmlParser(this.props.location.state.helpText || "")}</div>
            <div
              style={{ marginTop: 15, fontWeight: "bold", marginTop: "15%" }}
            >
              Sample Input
            </div>
            <div
              style={{
                width: "90%",
                padding: 30,
                height: 100,
                overflow: "scroll",
              }}
            >
              {ReactHtmlParser(this.props.location.state.sampleInput || "")}
            </div>
            <div
              style={{ marginTop: 15, fontWeight: "bold", marginTop: "15%" }}
            >
              Sample Output
            </div>
            <div
              style={{
                width: "90%",
                padding: 30,
                height: 100,
                overflow: "scroll",
              }}
            >
              {ReactHtmlParser(this.props.location.state.sampleOutput || "")}
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                marginTop: "50%",
              }}
            ></div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "60%",
            marginLeft: 10,
            backgroundColor: "#fff",
            maxHeight: "50vh",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginTop: 70,
              backgroundColor: "#fff",
              marginBottom: 5,
              padding: 5,
            }}
          >
            <div style={{}}>Code Editor</div>
            <div style={{ marginLeft: "60%" }}>
              <InputGroup className="mb-3">
                <select
                  className="custom-select"
                  style={{ maxWidth: "90px" }}
                  onClick={(e) => {
                    let val = "";
                    this.state.languagesList.forEach((element) => {
                      if (element.id === e.target.value) {
                        if (
                          element.optionValue.toLowerCase() !== "c" &&
                          element.optionValue.toLowerCase() !== "c++"
                        )
                          this.setState({
                            language: e.target.value,
                            editorlanguage: element.optionValue,
                          });
                        else {
                          this.setState({
                            language: e.target.value,
                            editorlanguage: "c_cpp",
                          });
                        }
                      }
                    });
                  }}
                >
                  {this.state.languagesList.map((ele, i) => (
                    <option value={ele.id} ids={ele.optionValue} key={i}>
                      {ele.optionValue}
                    </option>
                  ))}
                  {}
                </select>
              </InputGroup>
            </div>
            <div style={{ marginLeft: "4%" }}>
              <InputGroup className="mb-3">
                <select
                  className="custom-select"
                  style={{ maxWidth: "90px" }}
                  onClick={(e) => {
                    this.setState({ theme: e.target.value });
                  }}
                >
                  <option value="monokai" defaultValue>
                    Dark
                  </option>
                  <option value="github">Light</option>
                </select>
              </InputGroup>
            </div>
          </div>
          <div style={{ marginLeft: "2%" }}>
            <AceEditor
              height={"50vh"}
              width={"100%"}
              value={this.state.codevalue}
              showGutter={true}
              showPrintMargin={true}
              setOptions={{
                enableBasicAutocompletion: true,
                enableLiveAutocompletion: true,
                enableSnippets: true,
                showLineNumbers: true,
                tabSize: 2,
              }}
              fontSize={23}
              highlightActiveLine={true}
              onChange={(e) => {
                this.setState({ codevalue: e });
              }}
              mode={this.state.editorlanguage}
              enableBasicAutocompletion={true}
              theme={this.state.theme}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-around",
              marginTop: 10,
            }}
          >
            <Button
              type="submit"
              outline
              color="secondary"
              onClick={() => {
                this.handleCode();
              }}
            >
              Run Code
            </Button>
            <Button
              type="submit"
              color="success"
              onClick={() => {
                this.setState({ codevalue: "" });
                this.handleConfirm();
              }}
            >
              confirm
            </Button>
          </div>
          <div style={{ display: "flex", flexDirection: "row", padding: 20 }}>
            <div
              onClick={(e) => {
                this.setState({ inputState: "input" });
              }}
              style={
                this.state.inputState === "input"
                  ? this.state.highlightState
                  : this.state.nonHighlight
              }
            >
              Input
            </div>
            <div
              onClick={(e) => {
                this.setState({ inputState: "output" });
              }}
              style={
                this.state.inputState === "output"
                  ? this.state.highlightState
                  : this.state.nonHighlight
              }
            >
              Output
            </div>
          </div>
          {this.state.inputState === "input" ? (
            <div id="input">
              <textarea
                style={{ borderRadius: 5, marginLeft: 20 }}
                id="w3review"
                name="w3review"
                rows="30"
                value={this.state.inputvalue}
                cols="100"
                onChange={(e) => {
                  this.setState({ inputvalue: e.target.value });
                }}
              ></textarea>
            </div>
          ) : (
            <div id="output">
              <textarea
                style={{ borderRadius: 5, marginLeft: 20 }}
                readOnly
                rows="30"
                cols="80"
                value={this.state.outputCode}
                defaultValue={this.state.outputCode}
              ></textarea>
            </div>
          )}
        </div>
      </div>
    );
  }
}
