import React, { Component } from "react";
import CustomListTable from "../../CustomerManagement/qpTable";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import api from "../../../api/baseConfig";
import Apihelper from "../../../api/apiHelper";
import { TrimString } from "pages/utils/TrimString";
import { sessionPrivileges } from "privileges";

export default class AllQuestionPapers extends Component {
  constructor(props) {
    super(props);
    this.userPrivileges = sessionPrivileges(true);
    this.Priv = this.userPrivileges.userprivileges || [];
    this.state = {
      questionPapers: [],
      questionPapersLength: "",
      snack: false,
      customer: props?.location?.state?.customer,
      activePage: 1,
      learningPath: {
        itemsPerPage: "",
        activePage: "",
      },
    };
  }
 
  componentDidMount() {   
    window.scrollTo(0, 0);
    this.setState({
    learningPath:{itemsPerPage:"10", activePage:"1"},
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.learningPath.itemsPerPage !== prevState.learningPath.itemsPerPage ||
      this.state.learningPath.activePage !== prevState.learningPath.activePage
    ) {
      !this.Priv.includes("MANAGE_CUSTOMER") && this.getAllQuestionPapers();
    }
    // if (this.state.learningPath.activePage !== prevState.learningPath.activePage) {
    //   this.getAllQuestionPapers();
    // }
  }

  async getAllQuestionPapers(customerId) {
    const url = 
    // this.Priv.includes("MANAGE_CUSTOMER") ?
    `${api.baseURL}${api.QuestionPaperController.getQPbyCustomer}`;
    // :
    // `${api.baseURL}${api.QuestionPaperController.getallQuestionPaper}`;
    const customerIds = sessionStorage.getItem("customer_id");
    const payload = {
      customerId: this.userPrivileges.isAdmin ? customerIds : customerId,
      pageIndex: this.state.learningPath.activePage - 1,
      limit : this.state.learningPath.itemsPerPage,
           };
           try {
            Apihelper.axiosCallPost(url, "post", payload).then((data) => {
              this.setState({
                questionPapers: data.questionPaperList,
              });
              this.setState({
                questionPapersLength: data.totalCount,
              });
            });
          } catch (e) {}
  }

  // async getAllQuestionPapers(customerId) {
  //   const url = this.Priv.includes("MANAGE_CUSTOMER")
  //     ? TrimString(
  //         `${api.baseURL}${api.QuestionPaperController.getQPbyCustomer}${customerId}`
  //       )
  //     : TrimString(
  //         `${api.baseURL}${api.QuestionPaperController.getallQuestionPaper}`
  //       );
  //   try {
  //     Apihelper.axiosCall(url, "get").then((data) => {
  //       this.setState({
  //         questionPapers: data,
  //       });
  //     });
  //   } catch (e) {}
  // }

  deleteQP = async (id, customer) => {
    try {
      await Apihelper.axiosCall(
        `${api.baseURL}${api.QuestionPaperController.deleteAssemmentQuestionpaper}?id=${id}`,
        "post"
      ).then((res) => {
        res
          ? this.getAllQuestionPapers(customer)
          : this.setState({ snack: !this.state.snack });
      });
    } catch (e) {}
  };
  render() {
    return (
      <>
      {this.userPrivileges?.showQPList && (
      <div className="page-content" style={{ marginBottom: 20 }}>
        <Snackbar
          open={this.state.snack}
          autoHideDuration={5000}
          onClose={() => this.setState({ snack: !this.state.snack })}
        >
          <MuiAlert
            variant="filled"
            elevation={6}
            onClose={() => this.setState({ snack: !this.state.snack })}
            severity="error"
            style={{ fontFamily: "Poppins" }}
          >
            Since this Question Paper is in use it can't be deleted
          </MuiAlert>
        </Snackbar>
        <CustomListTable
          header="Question Papers"
          createLink="/create-qp"
          data={this.state.questionPapers}
          dataLength={this.state.questionPapersLength}
          tableHeaders={[
            { label: "Name", val: "name" },
            { label: "Description", val: "description" },
          ]}
          setUsers={(c) => this.getAllQuestionPapers(c)}
          searchPlaceholder="Search Question Papers"
          deleteDatum={this.deleteQP}
          showCustomers={this.Priv.includes("MANAGE_CUSTOMER")}
          showView={this.Priv.includes("EDIT_QUESTIONBANK")}
          showAdd={this.Priv.includes("ADD_QUESTION_PAPER")}
          hideShowView={!this.Priv.includes("VIEW_QUESTION_PAPER_DETAILS")}
          hideDelete={!this.Priv.includes("DELETE_QUESTION_PAPER")}
          hideEdit={!this.Priv.includes("EDIT_QUESTION_PAPER")}
          customer={this.state.customer}
          activePage={this.state.learningPath.activePage}
          itemsPerPage={this.state.learningPath.itemsPerPage}
          setActivePage={(val) =>
            this.setState({
              learningPath: { ...this.state.learningPath, activePage: val },
            })
          }
          setItemsPerPage={(val) =>
            this.setState({
              learningPath: { ...this.state.learningPath, itemsPerPage: val },
            })
          }
        />
      </div>
      )}
      </>
    );
  }
}
