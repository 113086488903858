import { withStyles } from "@material-ui/core/styles";
import Slider from "@material-ui/core/Slider";

export const ProfileSlider = withStyles({
  root: {
    height: 15,
    width: 500,
    cursor: "default",
    "& .MuiSlider-rail" : {
      backgroundColor: "#efceb8",
      height: 15,
      borderRadius: 8,
    },
    "& .MuiSlider-track" : {
      backgroundColor: "#F56F18",
      height: 15,
      borderRadius: 8,
    },
  },
  thumb: {
    display: "none"
  },
  valueLabel: {
    left: "-50%",
  },
})(Slider);
