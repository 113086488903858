import React, { useState, useEffect, useRef } from "react";
import "./buyCourse.css";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import AddCommentIcon from "@material-ui/icons/AddComment";
import ReviewPopup from "./reviewPopup";
import { Button } from "@material-ui/core";
import { ProfileMenu } from "components/profile-menu";
import DescriptionIcon from "@material-ui/icons/Description";
import IconButton from "@material-ui/core/IconButton";
import CourseDiscussion from "./sub-discussion/show-discussion";
import CourseNotes from "./notes";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useParams, Link } from "react-router-dom";
import apiHelper from "../../api/apiHelper";
import { TervButtonFilled } from "../../../src/components/TervButtons";
import api from "api/baseConfig";
import YouTubePlayer from "react-youtube";
import moment from "moment";
import Tooltip from "@material-ui/core/Tooltip";
import {
  CirclePlay,
  documentView,
  circleCheckSolid,
  micIcon,
  PracIcon,
  LiveClassIcon,
  bsxSpreadSheetIcon,
  bxCalendar,
  bxTimeIcon,
  bxChartIcon,
  bxRefreshIcon,
  bxTickGreenIcon,
  AssessmentIcon,
  bxMeetingScheduled,
  bxMeetingPin,
} from "./../../assets/icons/icons";
import { ModalToggle } from "pages/utils/ModalToggle";
import { YoutubeParser } from "pages/utils/YoutubeParser/parser";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory } from "react-router-dom";
import styles from "./index.module.scss";
import defaultAvatar from "../../assets/images/users/default-avatar.svg";
import { CircularProgress as MuiCircularProgress } from "@material-ui/core";
import { useMediaQuery } from "@material-ui/core";
import { useSelector } from "react-redux";
import SignInModal from "components/VerticalLayout/SignInModal";
import { InstructionModal } from "pages/Assessment/InstructionModal";
import { CheckModal } from "pages/Assessment/CheckModal";
import { getSafe } from "pages/Taketest/common/testHelper";
import { useLogoContext } from "context/logoContext";
import { Document, Page, pdfjs } from "react-pdf";
import { CopyToClipboard } from "react-copy-to-clipboard";	
import Card from "@material-ui/core/Card";	
import CardContent from "@material-ui/core/CardContent";	
import { penIcon, trashFilled } from "assets/icons/icons";
import Cookies from "universal-cookie";
import { sessionPrivileges } from "privileges";
import pdfLocal from "./pdfValue.pdf";
import VideoQuiz from "./videoQuiz";
import {getBasePdf} from "components/VerticalLayout/Apis/Api";
import { setActiveLink } from "react-scroll/modules/mixins/scroller";

const CircularProgress = withStyles({
  circle: {
    stroke: "#F56F18",
    strokeLinecap: "round",
  },
})(MuiCircularProgress);
const BgCircularProgress = withStyles({
  circle: {
    stroke: "black",
  },
})(MuiCircularProgress);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      className="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={2}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

const newSourceStyle = {
  position: "absolute",
  width: "100%",
  height: "100%",
  // top: "-2px",
};

const sourceStyle = {
  margin: "1rem 0",
  borderRadius: 5,
  boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
  // height: "65vh",
  backgroundColor: "#4A4851",
};

const useStyles = makeStyles((theme) => ({
  root: {
    position: "absolute",
    overflow: "hidden",
    width: "100%",
    height: "100%",
  },
  rootValue: {	
    background: "#ffffff",	
    boxShadow: "0px 0px 25px rgba(170, 170, 170, 0.25)",	
    borderRadius: "5px",	
    transition: "transform 0.5s ease-out",	
    maxWidth: "300px",	
    maxHeight: "285px",	
    height: "235px",	
  },
  scrollBar: {
    flex:"1 1 0%",
    width: '100%',
    height: '100%',
    position: 'relative',
    "& .pdfDocs":{
      width: '100%',
      height: '100%',
      position:'absolute',
      overflow: 'auto',
      display: 'flex',
      justifyContent:'center',
      "&::-webkit-scrollbar": {
        width: "0.2em",
      },
      "&::-webkit-scrollbar-track": {
        "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "rgba(0,0,0,.1)",
        outline: "1px solid slategrey",
      },
    },
  },
  paper: {
    position: "absolute",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export default function BuyCourse(props) {
  const userPrivileges = sessionPrivileges();
  const classes = useStyles();
  const { courseId, resourceID } = useParams();
  const logoImage = useLogoContext();
  const [tab, setTab] = useState(0);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [showContent, setShowContent] = useState(false);
  const [resourceDiscussion, setResourceDiscussion] = useState([]);
  const [initialValue, setInitialValue] = useState([]);
  const [courseNotes, setCourseNotes] = useState([]);
  const [coursedetails, setCourseDetails] = useState([]);
  const [moduleValue, setModuleValue] = useState([]);
  const [lastViewedResource, setLastViewedResource] = useState({});
  const [searchValues, setSearchValues] = useState("");
  const [courseContent, setCourseContent] = useState({ real: [], modify: [] });
  const [courseSearch, setCourseSearch] = useState({ real: [], modify: [] });
  const [completedResources, setCompletedResources] = useState([]);
  const [minimizeLeftMenu, setMinimizeLeftMenu] = useState(false);
  const [discussionsIcon, setDiscussionsIcon] = useState(false);
  const [notesContent, setNotes] = useState(false);
  const [notesId, setNotesId] = useState("");
  const [dataName, setDataName] = useState("");
  const [moduleData, setModuleData] = useState("");
  const [notesTextArea, setNotesTextArea] = useState("");
  const childNotes = React.useRef(null);
  const childDiscussions = React.useRef(null);
  const pdfRef = useRef(null);
  const [showMedia, setShowMedia] = useState({
    state: false,
    resourceId: "",
    media: "",
    moduleId: "",
    type: "",
    name: "",
    externalResourceId: "",
    completionCriteria: "",
    status: "",
  });
  const [pdfPageNumber, setPdfPageNumber] = useState(1);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [pdfMedia, setPdfMedia] = useState("");
  const [pdfEncoded,  setPdfEncoded] = useState("")
  const [certificate, setCertificate] = useState();
  const [currentTimeCourse, setCurrentTimeCourse] = useState();
  const [practiceDetails, setPracticeDetails] = useState("");
  const [liveClassDetails, setLiveClassDetails] = useState("");
  const [assessmentDetails, setAssessmentDetails] = useState("");
  const [courseDataDetails, setCourseDataDetails] = useState({});
  const [courseTypeForNotes, setCourseTypeForNotes] = useState();
  const [currVideoDuration, setCurrVideoDuration] = useState(0);
  
  const handleVideoDuration = (sec) => setCurrVideoDuration(sec? sec :0);

  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  const audioRef = useRef();
  const videoRef = useRef();
  const completedRefs = useRef([]);
  const appBarRef = useRef();

  const getCurrentDur = () => {
    return Math.floor(videoRef?.current?.currentTime);
  };

  const getCurrentAudDur = () => {
    return Math.floor(audioRef?.current?.currentTime);
  };

  useEffect(() => {
    const mediaProgressInt = setInterval(() => {
      if (
        showMedia.state &&
        (showMedia.type === "video" || showMedia.type === "audio")
      ) {
        handleProgress(
          showMedia.type === "video"
            ? videoRef?.current?.currentTime
            : audioRef?.current?.currentTime,

          showMedia.type === "video"
            ? videoRef?.current?.duration
            : audioRef?.current?.duration
        );
      }
    }, 1000);
    return () => clearInterval(mediaProgressInt);
  });

  async function fetchPracticeData(externalResourceId) {
    const data = await apiHelper.axiosCall(
      `${api.baseURL}${api.lab.userPracticeLab}${externalResourceId}/${userPrivileges.userId}`,
      "get"
    );
    setPracticeDetails(data);
  }
  async function fetchLiveClassData(externalResourceId) {
    const data = await apiHelper.axiosCall(
      `${api.baseURL}${api.CloudLab.CloudLabGtDetails}/${externalResourceId}`,
      "get"
    );
    setLiveClassDetails(data);
  }
  async function fetchAssessmentData(externalResourceId) {
    const data = await apiHelper.axiosCall(
      `${api.baseURL}${api.userManagement.getUserAssessmentInfo}${externalResourceId}`,
      "get"
    );
    setAssessmentDetails(data);
  }

  useEffect(() => {
    if (showMedia.type === "practice lab" && showMedia.externalResourceId) {
      fetchPracticeData(showMedia.externalResourceId);
    }
    if (showMedia.type === "live class" && showMedia.externalResourceId) {
      fetchLiveClassData(showMedia.externalResourceId);
    }
    if (showMedia.type === "assessment" && showMedia.externalResourceId) {
      fetchAssessmentData(showMedia.externalResourceId);
    }
  }, [showMedia]);

  function checkAssessmentProgressUpdate() {
    if (
      assessmentDetails?.userAssessmentDetails &&
      showMedia.status != "Completed" &&
      assessmentDetails?.userAssessmentDetails?.totalMarks >=
        showMedia.completionCriteria
    ) {
      updateProgress(showMedia.resourceId, showMedia.moduleId);
    }
  }

  useEffect(() => {
    checkAssessmentProgressUpdate();
  }, [assessmentDetails]);

  const toggleDrawer = () => setIsDrawerOpen(ModalToggle);

  const handleAddCommentIcon = () => {
    // childDiscussions.current();
    setDiscussionsIcon(discussionsIcon);
    setMinimizeLeftMenu(!true);
    setTab(1);
  };

  const handleDescriptionIcon = (id) => {
    setCourseTypeForNotes(null);
    setNotes(true);
    const notesId = { id: id };
    setNotesId(notesId);
    setMinimizeLeftMenu(!true);
    setTab(2);
  };

  const toggleDrawerContent = () => setShowContent(ModalToggle);
  const handleProgress = (currentTime, duration) => {
    const percentageCompleted = 100 * (currentTime / duration);
    if (
      +percentageCompleted >= 80 &&
      !completedRefs.current.includes(showMedia.resourceId)
    ) {
      completedRefs.current.push(showMedia.resourceId);
      updateProgress(showMedia.resourceId, showMedia.moduleId);
    }
  };
  useEffect(() => setCurrentTimeCourse(moment().format()), [showMedia]);

  const handleResourceProcess = (data, sectionOrder) => {
    setCourseDataDetails({
      id: data?.id,
      modId: data?.courseModuleId,
      name: data?.name,
    });
    setNotesId(data.id);
    setDataName(data.name);
    setModuleData(data.courseModuleId);
    if (showMedia.moduleId && showMedia.resourceId) {
      lastViewedResourceApi(
        currentTimeCourse,
        showMedia.moduleId,
        showMedia.resourceId
      );
    } else {
      setShowMedia({
        state: true,
        type: data?.type ? data.type.toLowerCase() : "",
        name: data?.name,
        media: data?.media
          ? data.media.includes("youtube")
            ? YoutubeParser(data.media)
            : data.media
          : "",
        resourceId: data?.id,
        moduleId: data?.courseModuleId,
        resourceOrder: data?.resourceModuleOrder,
        externalResourceId: data?.externalResourceId
          ? data?.externalResourceId
          : "",
        completionCriteria: data?.completionPercentage,
        status: data?.status,
      });
    }
    getCourseResource(data.id);
    getResourceDiscussion(data.id);
    // getCourseResource(data.courseId);
    setCourseNotes(data);

    var resourceOrder = data?.resourceModuleOrder;
    if (coursedetails?.enableSkipContent && !coursedetails?.sectionRestrict) {
      setShowMedia({
        state: true,
        type: data?.type ? data.type.toLowerCase() : "",
        name: data?.name,
        media: data?.media
          ? data.media.includes("youtube")
            ? YoutubeParser(data.media)
            : data.media
          : "",
        resourceId: data?.id,
        moduleId: data?.courseModuleId,
        resourceOrder: data?.resourceModuleOrder,
        status: data?.status,
        lessonId: data?.id,
        externalResourceId: data?.externalResourceId
          ? data?.externalResourceId
          : "",
        completionCriteria: data?.completionPercentage,
        status: data?.status,
      });
    } else if (!coursedetails?.enableSkipContent) {
      if (sectionOrder == 0) {
        if (resourceOrder > 0) {
          var firstStatus =
            courseContent?.modify[0].resourceList[resourceOrder - 1].status;
          if (
            firstStatus == "Completed" ||
            completedResources.includes(data?.id)
          ) {
            setShowMedia({
              state: true,
              type: data?.type ? data.type.toLowerCase() : "",
              name: data?.name,
              media: data?.media
                ? data.media.includes("youtube")
                  ? YoutubeParser(data.media)
                  : data.media
                : "",
              resourceId: data?.id,
              moduleId: data?.courseModuleId,
              resourceOrder: data?.resourceModuleOrder,
              externalResourceId: data?.externalResourceId
                ? data?.externalResourceId
                : "",
              completionCriteria: data?.completionPercentage,
              status: data?.status,
            });
          } else {
            toast.warn("You can't skip the content", {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: false,
            });
          }
        } else {
          setShowMedia({
            state: true,
            type: data?.type ? data.type.toLowerCase() : "",
            name: data?.name,
            media: data?.media
              ? data.media.includes("youtube")
                ? YoutubeParser(data.media)
                : data.media
              : "",
            resourceId: data?.id,
            moduleId: data?.courseModuleId,
            resourceOrder: data?.resourceModuleOrder,
            externalResourceId: data?.externalResourceId
              ? data?.externalResourceId
              : "",
            completionCriteria: data?.completionPercentage,
            status: data?.status,
          });
        }
      } else {
        if (resourceOrder > 0) {
          var secondOrder =
            courseContent?.modify[sectionOrder].resourceList[resourceOrder - 1]
              .status;
          if (
            secondOrder == "Completed" ||
            completedResources.includes(data?.id)
          ) {
            setShowMedia({
              state: true,
              type: data?.type ? data.type.toLowerCase() : "",
              name: data?.name,
              media: data?.media
                ? data.media.includes("youtube")
                  ? YoutubeParser(data.media)
                  : data.media
                : "",
              resourceId: data?.id,
              moduleId: data?.courseModuleId,
              resourceOrder: data?.resourceModuleOrder,
              externalResourceId: data?.externalResourceId
                ? data?.externalResourceId
                : "",
              completionCriteria: data?.completionPercentage,
              status: data?.status,
            });
          }
        } else {
          var resourceLength =
            courseContent?.modify[sectionOrder - 1].resourceList.length;
          var secondContentOrder =
            courseContent?.modify[sectionOrder - 1].resourceList[
              resourceLength - 1
            ].status;
          if (
            secondContentOrder == "Completed" ||
            completedResources.includes(data?.id)
          ) {
            setShowMedia({
              state: true,
              type: data?.type ? data.type.toLowerCase() : "",
              name: data?.name,
              media: data?.media
                ? data.media.includes("youtube")
                  ? YoutubeParser(data.media)
                  : data.media
                : "",
              resourceId: data?.id,
              moduleId: data?.courseModuleId,
              resourceOrder: data?.resourceModuleOrder,
              externalResourceId: data?.externalResourceId
                ? data?.externalResourceId
                : "",
              completionCriteria: data?.completionPercentage,
              status: data?.status,
            });
          } else {
            toast.warn("You can't skip the section", {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: false,
            });
          }
        }
      }
    } else if (coursedetails?.sectionRestrict) {
      if (sectionOrder == 0) {
        setShowMedia({
          state: true,
          type: data?.type ? data.type.toLowerCase() : "",
          name: data?.name,
          media: data?.media
            ? data.media.includes("youtube")
              ? YoutubeParser(data.media)
              : data.media
            : "",
          resourceId: data?.id,
          moduleId: data?.courseModuleId,
          resourceOrder: data?.resourceModuleOrder,
          externalResourceId: data?.externalResourceId
            ? data?.externalResourceId
            : "",
          completionCriteria: data?.completionPercentage,
          status: data?.status,
        });
      } else {
        var resourceLength =
          courseContent?.modify[sectionOrder - 1].resourceList.length;
        var secondContentOrder =
          courseContent?.modify[sectionOrder - 1].resourceList[
            resourceLength - 1
          ].status;
        if (
          secondContentOrder == "Completed" ||
          completedResources.includes(data?.id)
        ) {
          setShowMedia({
            state: true,
            type: data?.type ? data.type.toLowerCase() : "",
            name: data?.name,
            media: data?.media
              ? data.media.includes("youtube")
                ? YoutubeParser(data.media)
                : data.media
              : "",
            resourceId: data?.id,
            moduleId: data?.courseModuleId,
            resourceOrder: data?.resourceModuleOrder,
            externalResourceId: data?.externalResourceId
              ? data?.externalResourceId
              : "",
            completionCriteria: data?.completionPercentage,
            status: data?.status,
          });
        } else {
          toast.warn("You can't skip the section", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: false,
          });
        }
      }
    }
    // toggleDrawer();
  };

  useEffect(() => {
    if (
      props?.location?.state?.returnfromPrepPrac &&
      props?.location?.state?.con == 1
    ) {
      handleResourceProcess.bind(
        this,
        props?.location?.state?.resourceData,
        props?.location?.state?.sectionOrder,
        props?.location?.state?.resourceDataID
      );
    }
    if (
      props?.location?.state?.returnfromPrepPrac &&
      props?.location?.state?.con == 2
    ) {
      handleResourceProcess.bind(
        this,
        props?.location?.state?.resourceData,
        props?.location?.state?.sectionOrder
      );
    }
  }, []);

  const getDetails = async () => {
    try {
      const data = await apiHelper.axiosCall(
        `${api.baseURL}${api.courseController.getCourseDetails}${userPrivileges.userId}/${courseId}`,
        "get"
      );
      if (data) {
        setCourseDetails(data.courseDetails);
        setModuleValue(data.modulesList);
        setLastViewedResource(data.lastViewedResource);
        setCourseContent({ real: data.modulesList, modify: data.modulesList });
        // setCourseSearch({ real: data.modulesList, modify: data.modulesList });
        setCertificate(data.courseDetails.certification);
        if (data.lastViewedResource.type === "study material") {
          setPdfMedia(data.lastViewedResource.media);
        }
        if (data.courseDetails.status === "NotStarted") {
          startCourse();
        }

        if (resourceID) {
          getResourceDetails();
        } else {
          if (
            data.lastViewedResource !== null &&
            data.lastViewedResource.media !== null
          ) {
            handleResourceProcess(data.lastViewedResource);
          } else {
            handleResourceProcess(data.modulesList[0].resourceList[0]);
          }
        }
      }
    } catch (e) {}
  };

  useEffect(() => {
    getDetails();
  }, [completedResources]);

  //////////// API CALL //////////////
  const getCourseResource = async (id) => {
    try {
      const data = await apiHelper.axiosCall(
        `${api.baseURL}${api.courseController.getCourseDiscussion}${id}`,
        "get"
      );
      if (data !== "") {
        setResourceDiscussion(data);
      }
    } catch (e) {}
  };

  const getResourceDiscussion = async (id) => {
    const data = await apiHelper.axiosCall(
      `${api.baseURL}${api.courseController.getCourseResourceDiscussion}${id}`
    );
    if (data) {
      setInitialValue(data);
    }
  };

  const startCourse = async () => {
    try {
      await apiHelper.axiosCall(
        `${api.baseURL}${api.courseController.startCourses}${courseId}`,
        "get"
      );
    } catch (e) {}
  };

  const getResourceDetails = async () => {
    try {
      const data = await apiHelper.axiosCall(
        `${api.baseURL}${api.CoursesManagement.getCourseResource}${resourceID}`,
        "get"
      );
      if (data) {
        handleResourceProcess(data);
      }
    } catch (e) {}
  };

  const lastViewedResourceApi = async (ResourceStartTime, modId, resId) => {
    try {
      const api_data = {
        courseId: `${courseId}`,
        moduleId: modId,
        resourceId: resId,
        startTime: `${ResourceStartTime}`,
        endTime: `${moment().format()}`,
        userId: `${userPrivileges.userName}`,
      };
      await apiHelper.axiosCallLCpost(
        `${api.baseURL}${api.courseController.resourceActivity}`,
        "post",
        api_data
      );
    } catch (e) {}
  };

  const updateProgress = async (resId, modId) => {
    try {
      const data = await apiHelper.axiosCall(
        `${api.baseURL}${api.courseController.updateProgress}${courseId}/${modId}/${resId}/`,
        "get"
      );
      if (data) {
        setCompletedResources((prev) => [...prev, resId]);
      }
    } catch (e) {}
  };

  const updateProgressPdf = async (resId, modId) => {
    try {
      const data = await apiHelper.axiosCall(
        `${api.baseURL}${api.courseController.updateProgress}${courseId}/${modId}/${resId}/`,
        "get"
      );
      if (data) {
        setCompletedResources((prev) => [...prev, resId]);
      }
    } catch (e) {}
  };
  const handleSearchChange = ({ target: { value } }) => {
    setSearchValues(value)
    const newFilter = courseContent.real.filter(
      ({ title, resourceList }) =>
        title.toLowerCase().includes(value.toLowerCase()) ||
        !!resourceList.find((reso) =>
          reso.name.toLowerCase().includes(value.toLowerCase())
        )
    );
  
    if (!value) {
      setCourseContent({ ...courseContent, modify: courseContent.real });
    }
    else {
    setCourseContent({ ...courseContent, modify: newFilter });
    }
  };

  const handleScroll = (e) => {
    const maxLength =
      ((e.target.scrollHeight - e.target.clientHeight) * 8) / 10;
    const bottom = e.target.scrollTop >= maxLength;
    if (bottom) {
      updateProgress(showMedia.resourceId, showMedia.moduleId);
    }
  };


  const mediaProps = {
    controls: true,
    style: {
      ...newSourceStyle,
    },
    ref: showMedia.type === "audio" ? audioRef : videoRef,
  };

  const mediaStyle = {
    style: {
      ...sourceStyle,
      ...(appBarRef.current && { width: appBarRef.current.clientWidth }),
    },
  };
  const mediaPropsAudio = {
    controls: true,
    ref: showMedia.type === "audio" ? audioRef : videoRef,
  };
  const sourceProps = {
    src: showMedia.media,
    type: showMedia.type === "audio" ? "audio/mp3" : "video/mp4",
  };

  const percentageCompleted = (pageNumber * 100) / numPages;
  useEffect(() => {
    if (percentageCompleted == 100) {
      updateProgressPdf(showMedia.resourceId, showMedia.moduleId);
    }
  }, [percentageCompleted]);
  const history = useHistory();
  const handlePageRedirect = (data) => history.push(`/course-overview/${data}`);
  const smallScreen = useMediaQuery("(max-width: 600px)");
  const mediumScreen = useMediaQuery("(max-width: 1781px)");
  const minScreen = useMediaQuery("(max-width: 1482px)");
  const medScreen = useMediaQuery("(max-width: 1230px)");
  const [userToken, setUserToken] = useState("");
  useEffect(() => {
    if (!userToken) {
      const cookies = new Cookies();
      setUserToken(cookies.get("userToken"));
    }
  }, []);
  const userDetails = useSelector((state) => state.UserProfileReducer.UserData);

  ////////////////////////////// Assessment///////////////////////////

  const [expanded, setExpanded] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [navMenuIsOpen, setNavMenuOpen] = useState(false);
  const [profileMenuAnchor, setProfileMenuAnchor] = useState(null);
  const [assessmentData, setAssessmentData] = useState({});
  const [loading, setLoading] = useState(false);
  const [sectionData, setSectionData] = useState([]);
  const [authDetails, setAuthDetails] = useState({
    passCode: "",
    imageEnabled: "",
    userVerified: "",
    userPhoto: "",
  });
  const [password, setPassword] = useState("");
  const [checkModal, setCheckModal] = useState(false);
  const [auth, setAuth] = useState(undefined);
  const [openSignin, setOpenSignin] = useState(false);

  const takeAssessment = (a) => {
    assessmentData.id !== a?.id ? setAssessmentData(a) : null;
    getResData(a);
    setShowModal(ModalToggle);
  };


  const handleAssessment = (assessment) => {
    if (assessment.courseId) {
      if (assessment.courseProgress === 100) {
        takeAssessment(assessment);
      } else {
        if (!whichCourse.name || whichCourse.name !== assessment.courseName) {
          setWhichCourse({
            name: assessment.courseName,
            reason: !whichCourse.reason,
          });
        } else {
          setWhichCourse({
            ...whichCourse,
            reason: !whichCourse.reason,
          });
        }
      }
    } else {
      takeAssessment(assessment);
    }
  };

  async function handlePassword() {
    let isAuth = await VerifyPasscode({
      passCode: password,
      assessmentId: assessmentData.id,
    });
    if (isAuth) {
      toast.success("Passcode is valid", {
        position: "bottom-center",
        style: { zIndex: 2000 },
      });
    } else {
      toast.error("Passcode is invalid", {
        position: "bottom-center",
        style: { backgroundColor: "#ff0000", color: "#fff", zIndex: 2000 },
      });
    }
    setAuth(isAuth);
  }

  function getResData(id) {
    apiHelper
      .axiosCall(`${api.baseURL}${api.userManagement.getInfo}${id}`, "get")
      .then((res) => {
        let temp = [];
        res.sectionList.forEach((r) => {
          temp.push({
            secName: r.name,
            secDescription: r.description,
            noOfQuestions: r.questionIdList.length,
          });
        });

        let defpassCode = false;
        defpassCode = getSafe(() => res.settings.passcodeEnabled, false);
        let imageEnabledD = getSafe(() => res.settings.imageEnabled, true);
        setAuthDetails({
          ...authDetails,
          passCode: defpassCode,
          imageEnabled: imageEnabledD,
          userVerified: !imageEnabledD,
          isSEB: !!res.settings?.secureBrowserEnabled,
          sebUrl: res.seburl,
        });
        setSectionData(temp);
        setAssessmentData(res);
      })
      .catch((e) => {});
  }

  function openProfileMenu(event) {
    setNavMenuOpen(false);
    setProfileMenuAnchor(event.currentTarget);
  }

  function closeProfileMenu() {
    setProfileMenuAnchor(null);
  }

  const handleSignInModal = () => setOpenSignin(ModalToggle);

  const [numPdfPages, setNumPdfPages] = useState(null);

  function onDocumentLoadSuccess({ numPdfPages }) {
    setNumPdfPages(numPdfPages);
  }

  const handleTab = (data) => {
    setTab(data);
    setNotes(false);
  };


  /*To Prevent right click on screen*/
  document.addEventListener("contextmenu", (event) => {
    event.preventDefault();
  });

  /*When document gets loaded successfully*/
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
    setPdfPageNumber(1);
  }

  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
    setPdfPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      pageSet();
    }
  };
  const pageSet = () => {
    setPageNumber(parseInt(pdfPageNumber));
    setPdfPageNumber(parseInt(pdfPageNumber));
  };
  const [open, setOpen] = useState(false);
  useEffect(() => {
    if (coursedetails?.progress == 100) {
      setOpen(true);
    }
  }, [coursedetails?.progress]);
  const handleClose = () => {
    setOpen(false);
  };

  // const downloadFile = () => {
  //   window.location.href = `https://corsanywhere.herokuapp.com/${showMedia?.media}`;
  // };

  let parseIdForYoutube = "";
  if (showMedia.type === "link" && showMedia.media.includes("https://youtu")) {
    const splitLink = showMedia?.media?.split("/");
    parseIdForYoutube = splitLink?.pop();
  }

  const getPdfBaseValue = async (payload) => {
    setLoading(true);
    try {
      const sectionData = await getBasePdf(payload);
      if(sectionData){
        setPdfEncoded(sectionData?.mediaEncoded)
      }
      setLoading(false);
    } catch (error) {}
  };
  useEffect(() => {
    if(showMedia.type === "study material" && showMedia?.media){
      const payload = {
        "media" : showMedia?.media
      }
      getPdfBaseValue(payload)
    }
  },[showMedia])

  const handleAccChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <>
      <SignInModal
        modal={openSignin}
        setModal={() => {
          handleSignInModal();
        }}
        fromAssessment
        assessmentId={assessmentData.id}
        openInstructions={() => {
          handleSignInModal();
          takeAssessment(assessmentData);
        }}
      />
      <CheckModal
        assessmentData={assessmentData}
        isOpen={checkModal}
        close={() => setCheckModal(false)}
        onTakeTest={() => {
          setShowModal(false);
          localStorage.setItem("AssessmentFromPrepare", courseId);
        }}
        authDetails={authDetails}
        callback={(j) =>
          (window.location.href = `/taketest?id=${showMedia.externalResourceId}&userId=${j.userId}`)
        }
        setUserVerifiedPhoto={(photo) => {
          setAuthDetails({
            ...authDetails,
            userVerified: true,
            userPhoto: photo,
          });
        }}
      />
      <InstructionModal
        showModal={showModal}
        toggle={() => {
          setAuth(false);
          setShowModal(!showModal);
        }}
        assessmentData={assessmentData}
        authDetails={authDetails}
        isSEB={authDetails.isSEB}
        sebUrl={authDetails.sebUrl}
        sectionData={sectionData}
        setPassword={(pas) => setPassword(pas)}
        handlePassword={handlePassword}
        auth={auth}
        setCheckModal={() => setCheckModal(!checkModal)}
      />
      <div className={classes.root}>
        <ReviewPopup
          courseId={coursedetails?.id}
          open={open}
          close={handleClose}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            overflow: "hidden",
            width: "100%",
            height: "100%",
          }}
        >
          <div className="header-Style">
            <div className="p-3 headerCourse">
              <Link to={"/prepare"}>
                <Typography className="bread-prepare" color="#fff">
                  <img
                    className={styles.customerLogo}
                    src={logoImage.productLogo}
                    style={{
                      display: "block",
                    }}
                  />
                </Typography>
              </Link>
              <Link to={`/course-overview/${coursedetails.id}`}>
                <Typography
                  className={
                    smallScreen ? "contentHeaderMobile" : "contentHeader"
                  }
                >
                  {coursedetails.name}
                </Typography>
              </Link>
              <div style={{ display: "flex" }}>
                <Box position="relative" display="inline-flex">
                  <BgCircularProgress
                    size={40}
                    thickness={4.5}
                    variant="determinate"
                    style={{ position: "absolute" }}
                    value={100}
                  />
                  <CircularProgress
                    size={40}
                    thickness={4.5}
                    variant="determinate"
                    value={coursedetails?.progress || 0}
                  />
                  <Box
                    top={2}
                    left={0}
                    bottom={0}
                    right={0}
                    position="absolute"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Typography
                      variant="caption"
                      component="div"
                      style={{
                        fontSize: "10px",
                        color: "#fff",
                        fontWeight: "500",
                      }}
                    >
                      {`${coursedetails?.progress || 0} %`}
                    </Typography>
                  </Box>
                </Box>
                <div
                  style={{ paddingRight: "20px", paddingLeft: "20px" }}
                  onClick={openProfileMenu}
                >
                  <a>
                    <img src={defaultAvatar} className="avatar" />
                  </a>
                </div>
                <ProfileMenu
                  anchorEl={profileMenuAnchor}
                  onClose={closeProfileMenu}
                  userDetails={userDetails}
                />
              </div>
            </div>
          </div>
          <div
            className={`sidebar-asdf ${minimizeLeftMenu ? "" : "open"}`}
            style={{ background: "#F6F6F7" }}
          >
            <div className="video-asdf">
              <div className="subTitleMenu-asdf">
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <h2 style={{ paddingLeft: "32px" }}>{dataName}</h2>
                  </Box>
                  <Box sx={{ flex: "1 1 0%" }} />

                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      gap: "16px",
                    }}
                  >
                    {coursedetails?.enableDiscussion && (
                      <Tooltip title="Add Discussions">
                        <IconButton aria-label="delete">
                          <AddCommentIcon onClick={handleAddCommentIcon} />
                        </IconButton>
                      </Tooltip>
                    )}
                    {coursedetails?.enableNotes && (
                      <Tooltip title="Add notes">
                        <IconButton aria-label="delete">
                          <DescriptionIcon
                            onClick={() => handleDescriptionIcon(showMedia.id)}
                          />
                        </IconButton>
                      </Tooltip>
                    )}
                    <IconButton
                      aria-label="delete"
                      onClick={() => setMinimizeLeftMenu(!minimizeLeftMenu)}
                    >
                      {minimizeLeftMenu == false ? (
                        <i className="fas fa-angle-double-right"></i>
                      ) : (
                        <i className="fas fa-angle-double-left"></i>
                      )}
                    </IconButton>
                  </Box>
                </Box>
              </div>

              <div
                className={
                  // showMedia?.type === "audio"
                  //   ? "course-continueAudio"
                  "course-continue"
                }
                onScroll={handleScroll}
              >
                <div
                  style={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  {showMedia.state && (
                    <>
                      {showMedia.type === "video" ||
                      showMedia.type === "link" ||
                      showMedia.type === "audio" ? (
                        <div
                          style={{
                            width: "100%",
                            height: "100%",
                            // paddingBottom: "56.76%",
                            flex: "1 1 0%",
                            position: "relative",
                            textAlign: showMedia.type === "link" ? "center" : "",
                            alignItems: showMedia.type === "link" ? "center" : "",
                            paddingTop: showMedia.type === "link" ? "12%" : "",
                          }}
                        >
                          {showMedia.type === "video" &&
                          moduleValue.length > 0 ? (
                            moduleValue.map(
                              (module) =>
                                module.id === showMedia.moduleId &&
                                module.resourceList &&
                                module.resourceList.length > 0 &&
                                module.resourceList.map(
                                  (res, index) => res.id === showMedia.resourceId && (
                                      <VideoQuiz
                                        key={`VideoQuiz${index}`}
                                        videoData={{
                                          duration:
                                            res.durationMin &&
                                            parseFloat(res.durationMin)
                                              ? parseFloat(res.durationMin)
                                              : 0,
                                          url: res.media,
                                        }}
                                        quizData={
                                          res.userVideoQUizzList &&
                                          res.userVideoQUizzList.length > 0
                                            ? res.userVideoQUizzList
                                            : []
                                        }
                                        userName={userPrivileges.userName}
                                        handleVideoDuration={handleVideoDuration}
                                        updateProgress={()=>updateProgress(showMedia.resourceId, showMedia.moduleId)}
                                      />
                                    )
                                )
                            )
                          ) : showMedia.type === "link" &&	
                          moduleValue.length > 0 ? (	
                            moduleValue.map(	
                              (module) =>	
                                module.id === showMedia.moduleId &&	
                                module.resourceList &&	
                                module.resourceList.length > 0 &&	
                                module.resourceList.map(  	
                               (res, index) => res.id === showMedia.resourceId && (	
                        <>	
                          {/* <YouTubePlayer	
                            videoId={parseIdForYoutube}	
                            onEnd={() =>	
                              updateProgress(	
                                showMedia.resourceId,	
                                showMedia.moduleId	
                              )	
                            box-shadow: 5px 10px #888888	
                            }	
                          /> */}	
                          <div style={{ paddingTop:"10%", 	
                                         display:'flex',	
                                         justifyContent:'center', 	
                                         gap:"3%" , }}>	
                           <a	
                           href={`${res.media}?token=${userToken}`}	
                           target="_blank"	
                           rel="noopener noreferrer"	
                           >	
                           <Button	
                           variant="contained"	
                           size="lg"	
                           style={{ backgroundColor: " #794DF5", color: "#ffffff",  width:"220px", height: "50px" }}	
                           onClick={() => {	
                            updateProgress(	
                              showMedia.resourceId,	
                              showMedia.moduleId	
                            );	
                          }}	
                           >	
                           {"Start"}	
                           </Button>	
                           </a>	
                          <CopyToClipboard	
                          text={`	
                          ${res.media}	
                          `}	
                          >	
                         <Button	
                         variant="contained"	
                         size="lg"	
                         style={{ backgroundColor: " #794DF5", color: "#ffffff", width:"80px" }}	
                         onClick={() => {	
                          updateProgress(	
                            showMedia.resourceId,	
                            showMedia.moduleId	
                          );	
                        }}	
                         >	
                        <span>	
                        {" "}	
                        <svg	
                          width="14"	
                          height="14"	
                          viewBox="0 0 14 14"	
                          fill="#ffffff"	
                          xmlns="http://www.w3.org/2000/svg"	
                        >	
                          <path	
                            d="M12.3333 0.333313H5.66659C4.93125 0.333313 4.33325 0.931313 4.33325 1.66665V4.33331H1.66659C0.931252 4.33331 0.333252 4.93131 0.333252 5.66665V12.3333C0.333252 13.0686 0.931252 13.6666 1.66659 13.6666H8.33325C9.06859 13.6666 9.66659 13.0686 9.66659 12.3333V9.66665H12.3333C13.0686 9.66665 13.6666 9.06865 13.6666 8.33331V1.66665C13.6666 0.931313 13.0686 0.333313 12.3333 0.333313ZM1.66659 12.3333V5.66665H8.33325L8.33459 12.3333H1.66659ZM12.3333 8.33331H9.66659V5.66665C9.66659 4.93131 9.06859 4.33331 8.33325 4.33331H5.66659V1.66665H12.3333V8.33331Z"	
                            fill="#ffffff"	
                          />	
                        </svg>	
                        </span>	
                        </Button>	
                        </CopyToClipboard>	
                          </div> 	
                            
                        </>	
                               )	
                                )	
                            )
                          ) : (
                            <div className="audioContainerresponsive">
                              <div className="pt-1">
                                {smallScreen ? (
                                  <svg
                                    width="330"
                                    height="200"
                                    viewBox="0 0 996 436"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <rect
                                      width="996"
                                      height="436"
                                      rx="10"
                                      fill="url(#paint0_linear_1659_522)"
                                      fill-opacity="0.5"
                                    />
                                    <rect
                                      x="398"
                                      y="118"
                                      width="200"
                                      height="200"
                                      rx="10"
                                      fill="#794DF5"
                                    />
                                    <rect
                                      x="398"
                                      y="118"
                                      width="200"
                                      height="200"
                                      rx="10"
                                      fill="black"
                                      fill-opacity="0.6"
                                    />
                                    <path
                                      d="M538 218.002V209.467C538 187.257 520.605 168.662 499.225 168.017C488.205 167.762 477.97 171.697 470.145 179.297C466.287 183.013 463.222 187.473 461.135 192.406C459.048 197.34 457.981 202.645 458 208.002V218.002C452.485 218.002 448 222.487 448 228.002V248.002C448 253.517 452.485 258.002 458 258.002H468V208.002C467.985 203.984 468.784 200.005 470.349 196.305C471.914 192.605 474.212 189.26 477.105 186.472C479.986 183.667 483.402 181.473 487.151 180.021C490.899 178.568 494.902 177.886 498.92 178.017C514.96 178.497 528 192.607 528 209.467V258.002H538C543.515 258.002 548 253.517 548 248.002V228.002C548 222.487 543.515 218.002 538 218.002Z"
                                      fill="#BDBBC3"
                                    />
                                    <path
                                      d="M473 218H483V258H473V218ZM513 218H523V258H513V218Z"
                                      fill="#BDBBC3"
                                    />
                                    <defs>
                                      <linearGradient
                                        id="paint0_linear_1659_522"
                                        x1="498"
                                        y1="0"
                                        x2="498"
                                        y2="436"
                                        gradientUnits="userSpaceOnUse"
                                      >
                                        <stop stopColor="#8E2DE2" />
                                        <stop offset="1" stopColor="#4A00E0" />
                                      </linearGradient>
                                    </defs>
                                  </svg>
                                ) : (
                                  <svg
                                    width="830"
                                    height="300"
                                    viewBox="0 0 996 436"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <rect
                                      width="996"
                                      height="436"
                                      rx="10"
                                      fill="url(#paint0_linear_1659_522)"
                                      fill-opacity="0.5"
                                    />
                                    <rect
                                      x="398"
                                      y="118"
                                      width="200"
                                      height="200"
                                      rx="10"
                                      fill="#794DF5"
                                    />
                                    <rect
                                      x="398"
                                      y="118"
                                      width="200"
                                      height="200"
                                      rx="10"
                                      fill="black"
                                      fill-opacity="0.6"
                                    />
                                    <path
                                      d="M538 218.002V209.467C538 187.257 520.605 168.662 499.225 168.017C488.205 167.762 477.97 171.697 470.145 179.297C466.287 183.013 463.222 187.473 461.135 192.406C459.048 197.34 457.981 202.645 458 208.002V218.002C452.485 218.002 448 222.487 448 228.002V248.002C448 253.517 452.485 258.002 458 258.002H468V208.002C467.985 203.984 468.784 200.005 470.349 196.305C471.914 192.605 474.212 189.26 477.105 186.472C479.986 183.667 483.402 181.473 487.151 180.021C490.899 178.568 494.902 177.886 498.92 178.017C514.96 178.497 528 192.607 528 209.467V258.002H538C543.515 258.002 548 253.517 548 248.002V228.002C548 222.487 543.515 218.002 538 218.002Z"
                                      fill="#BDBBC3"
                                    />
                                    <path
                                      d="M473 218H483V258H473V218ZM513 218H523V258H513V218Z"
                                      fill="#BDBBC3"
                                    />
                                    <defs>
                                      <linearGradient
                                        id="paint0_linear_1659_522"
                                        x1="498"
                                        y1="0"
                                        x2="498"
                                        y2="436"
                                        gradientUnits="userSpaceOnUse"
                                      >
                                        <stop stopColor="#8E2DE2" />
                                        <stop offset="1" stopColor="#4A00E0" />
                                      </linearGradient>
                                    </defs>
                                  </svg>
                                )}
                              </div>
                              <div className="pt-1">
                                <audio
                                  {...mediaPropsAudio}
                                  controlsList="nodownload"
                                  className={
                                    smallScreen
                                      ? "audioplayerSizeMobile"
                                      : "audioplayerSize"
                                  }
                                >
                                  <source {...sourceProps} />
                                </audio>
                              </div>
                            </div>
                          )}
                        </div>
                      ) : showMedia.type === "scrom" ? (
                        <div>
                          <iframe
                            src={showMedia.media}
                            width="988"
                            height="637"
                            frameborder="0"
                            allowfullscreen="allowfullscreen"
                            allow="autoplay ; geolocation ; microphone ; camera ; midi ; encrypted-media "
                          />
                        </div>
                      ) : showMedia.type === "study material" ? (
                        <div className={styles.pdfAlign}>
                          <div className={classes.scrollBar}>
                          {loading ? (
                           <div style={{paddingTop:"20%",
                           width: '100%',
      height: '100%',
      position:'absolute',
      overflow: 'auto',
      textAlign:"center",
      justifyContent:'center',}}>
                          <BgCircularProgress
                          size={40}
                          thickness={4.5}
                          value={100}
                        />
                        <div>Loading...</div>
                        </div>
                      ) : (
                        <div className="pdfDocs">
                            <Document
                              file={`data:application/pdf;base64,${pdfEncoded}`}
                              // file={`https://corsanywhere.herokuapp.com/${showMedia?.media}`}
                              onLoadSuccess={onDocumentLoadSuccess}
                            >
                              <Page pageNumber={pageNumber} />
                            </Document>
                            </div>
                      )}
                          </div>
                          <div>
                            <div>
                              <p style={{textAlign:'center'}}>
                                Page {pageNumber || (numPages ? 1 : "--")} of{" "}
                                {numPages || "--"}
                              </p>
                            </div>
                            <br />
                                  <div>
                                  <Tooltip title="previous page" aria-label="previousPage">
                                    <Button
                                      type="button"
                                      disabled={pageNumber <= 1}
                                      onClick={previousPage}
                                    >
                                      <svg
                                        width="41"
                                        height="40"
                                        viewBox="0 0 41 40"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <rect
                                          x="0.5"
                                          y="0.5"
                                          width="40"
                                          height="39"
                                          rx="2.5"
                                          fill="#F5FAFF"
                                        />
                                        <path
                                          d="M29.2031 18.9055H27.3047C27.1969 18.9055 27.0937 18.9523 27.0211 19.0344L21.8906 24.9477V11.75C21.8906 11.6469 21.8062 11.5625 21.7031 11.5625H20.2969C20.1937 11.5625 20.1094 11.6469 20.1094 11.75V24.9477L14.9789 19.0344C14.9086 18.9523 14.8055 18.9055 14.6953 18.9055H12.7969C12.6375 18.9055 12.5508 19.0953 12.6562 19.2148L20.4352 28.1797C20.5055 28.2608 20.5924 28.3259 20.6901 28.3705C20.7877 28.415 20.8938 28.4381 21.0012 28.4381C21.1085 28.4381 21.2146 28.415 21.3123 28.3705C21.4099 28.3259 21.4969 28.2608 21.5672 28.1797L29.3437 19.2148C29.4492 19.093 29.3625 18.9055 29.2031 18.9055Z"
                                          fill="black"
                                        />
                                        <rect
                                          x="0.5"
                                          y="0.5"
                                          width="40"
                                          height="39"
                                          rx="2.5"
                                          stroke="#D8D6DB"
                                        />
                                      </svg>
                                    </Button>
                                    </Tooltip>

                                    <Tooltip title="enter page number" aria-label="enterPage">
                                    <span onKeyDown={handleKeyDown}>
                                      <input
                                        type="number"
                                        min="0"
                                        max={numPages || 1}
                                        value={
                                          pdfPageNumber || (numPages ? 1 : "--")
                                        }
                                        onChange={(e) =>
                                          setPdfPageNumber(e.target.value)
                                        }
                                        style={{
                                          backgroundColor: "#F5FAFF",
                                          color: "black",
                                          border: "1px solid #D8D6DB",
                                          width: "60px",
                                          height: "41px",
                                        }}
                                      />
                                    </span>
                                    </Tooltip>

                                    <Tooltip title="Next page" aria-label="previousPage">
                                    <Button
                                      type="button"
                                      disabled={pageNumber >= numPages}
                                      onClick={nextPage}
                                    >
                                      <svg
                                        width="41"
                                        height="40"
                                        viewBox="0 0 41 40"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <rect
                                          x="0.5"
                                          y="0.5"
                                          width="40"
                                          height="39"
                                          rx="2.5"
                                          fill="#F5FAFF"
                                        />
                                        <path
                                          d="M29.3437 20.7852L21.5648 11.8203C21.4945 11.7392 21.4075 11.6741 21.3099 11.6296C21.2122 11.585 21.1061 11.5619 20.9988 11.5619C20.8914 11.5619 20.7853 11.585 20.6877 11.6296C20.59 11.6741 20.5031 11.7392 20.4328 11.8203L12.6562 20.7852C12.6327 20.8122 12.6175 20.8454 12.6123 20.8808C12.6071 20.9163 12.6123 20.9524 12.6271 20.985C12.6419 21.0176 12.6658 21.0453 12.6959 21.0647C12.726 21.0841 12.761 21.0945 12.7968 21.0945H14.6953C14.8031 21.0945 14.9062 21.0477 14.9789 20.9656L20.1093 15.0524V28.25C20.1093 28.3531 20.1937 28.4375 20.2968 28.4375H21.7031C21.8062 28.4375 21.8906 28.3531 21.8906 28.25V15.0524L27.021 20.9656C27.0914 21.0477 27.1945 21.0945 27.3046 21.0945H29.2031C29.3624 21.0945 29.4492 20.907 29.3437 20.7852Z"
                                          fill="black"
                                        />
                                        <rect
                                          x="0.5"
                                          y="0.5"
                                          width="40"
                                          height="39"
                                          rx="2.5"
                                          stroke="#D8D6DB"
                                        />
                                      </svg>
                                    </Button>
                                    </Tooltip>
                                    <Tooltip title="download PDF" aria-label="download">
                                    <a href={showMedia?.media} download style={{marginLeft:'10px'}}>
                                      <svg
                                        width="41"
                                        height="40"
                                        viewBox="0 0 41 40"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <rect
                                          x="0.5"
                                          y="0.5"
                                          width="40"
                                          height="39"
                                          rx="2.5"
                                          fill="#F5FAFF"
                                        />
                                        <g clip-path="url(#clip0_197_149)">
                                          <path
                                            d="M28 20V27H14V20H12V27C12 28.1 12.9 29 14 29H28C29.1 29 30 28.1 30 27V20H28ZM22 20.67L24.59 18.09L26 19.5L21 24.5L16 19.5L17.41 18.09L20 20.67V11H22V20.67Z"
                                            fill="black"
                                          />
                                        </g>
                                        <rect
                                          x="0.5"
                                          y="0.5"
                                          width="40"
                                          height="39"
                                          rx="2.5"
                                          stroke="#D8D6DB"
                                        />
                                        <defs>
                                          <clipPath id="clip0_197_149">
                                            <rect
                                              width="24"
                                              height="24"
                                              fill="white"
                                              transform="translate(9 8)"
                                            />
                                          </clipPath>
                                        </defs>
                                      </svg>
                                    </a>
                                    </Tooltip>
                                  </div>
                          </div>
                        </div>
                      ) : showMedia.type === "practice lab" ? (
                        <div className={styles.practiceContainer}>
                          <h2
                            className={styles.contentTitle}
                            style={{
                              marginTop: "1.5rem",
                              marginLeft: "3rem",
                            }}
                          >
                            Practice
                          </h2>
                          <div className={styles.practiceInfoContainer}>
                            <p className={styles.practiceTitle}>
                              {practiceDetails?.name}
                            </p>
                            <div
                              className={styles.practiceDesc}
                              dangerouslySetInnerHTML={{
                                __html: practiceDetails?.description,
                              }}
                            />
                            <div style={{ display: "flex" }}>
                              {bsxSpreadSheetIcon}
                              <p className={styles.practiceQuesStyle}>{`${
                                practiceDetails?.questionCount || 0
                              } Questions`}</p>
                            </div>
                            {showMedia.status == "Completed" ? (
                              <div
                                className={styles.completedSuccessContainer}
                                style={{
                                  display: "flex",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                <span style={{ marginRight: "8px" }}>
                                  {bxTickGreenIcon}
                                </span>
                                <span className={styles.completedSuccessText}>
                                  practice completed
                                </span>
                              </div>
                            ) : (
                              <TervButtonFilled
                                text="Go to practice"
                                width="8rem"
                                onClick={() => {
                                  history.push({
                                    params: {
                                      labId: showMedia.externalResourceId,
                                    },
                                    pathname: `/courselearning/practice/${courseId}`,
                                    state: {
                                      fromEdittrue: true,
                                      id: showMedia.resourceId,
                                      prepCourseId: coursedetails.id,
                                      prepCourseName: coursedetails.name,
                                      prepCoursePer: coursedetails?.progress,
                                      prepPracCompletionCriteria:
                                        showMedia.completionCriteria,
                                      prepPracResId: showMedia.resourceId,
                                      prepPracModId: showMedia.moduleId,
                                    },
                                  });
                                  updateProgress(showMedia.resourceId, showMedia.moduleId);
                                }}
                                type="button"
                              />
                            )}
                          </div>
                        </div>
                      ) : showMedia.type === "live class" ? (
                        <div className={styles.practiceContainer}>
                          <h2
                            className={styles.contentTitle}
                            style={{
                              marginTop: "1.5rem",
                              marginLeft: "3rem",
                            }}
                          >
                            Cloud lab
                          </h2>
                          <div className={styles.practiceInfoContainer}>
                            <p className={styles.practiceTitle}>
                              {showMedia.name}
                            </p>
                            <div
                              className={styles.practiceDesc}
                              dangerouslySetInnerHTML={{
                                __html: liveClassDetails?.description,
                              }}
                            />
                            <div style={{ display: "flex", marginTop: "2rem" }}>
                              <div style={{ display: "flex" }}>
                                {bxCalendar}
                                <p className={styles.practiceQuesStyle}>
                                  {new Date(
                                    liveClassDetails?.startDate
                                  ).toLocaleDateString("en-UK", {
                                    year: "numeric",
                                    month: "long",
                                    day: "numeric",
                                  })}
                                </p>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  "margin-left": "3rem",
                                }}
                              >
                                {bxTimeIcon}
                                <p
                                  className={styles.practiceQuesStyle}
                                >{`${new Date(
                                  liveClassDetails?.startDate
                                ).toLocaleTimeString("en-US", {
                                  hour: "numeric",
                                  minute: "numeric",
                                })} - ${new Date(
                                  liveClassDetails?.endDate
                                ).toLocaleTimeString("en-US", {
                                  hour: "numeric",
                                  minute: "numeric",
                                })}`}</p>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  "margin-left": "3rem",
                                }}
                              >
                                {bxMeetingPin}
                                <p
                                  className={styles.practiceQuesStyle}
                                >{`Pin:${liveClassDetails.cloudLabPin}`}</p>
                              </div>
                            </div>
                            {liveClassDetails.status == "active" ? (
                              <div>
                                <a
                                  href={`${liveClassDetails.meetingUrl}?token=${userToken}&firstName=${userDetails.firstName}&lastName=${userDetails.lastName}`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <TervButtonFilled
                                    text="Participate now"
                                    width="auto"
                                    type="button"
                                    style={{ "margin-top": "1rem" }}
                                    onClick={() => {
                                      updateProgress(
                                        showMedia.resourceId,
                                        showMedia.moduleId
                                      );
                                    }}
                                  />
                                </a>
                              </div>
                            ) : liveClassDetails.status == "Completed" ? (
                              <div
                                className={styles.completedSuccessContainer}
                                style={{
                                  display: "flex",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                <span style={{ marginRight: "8px" }}>
                                  {bxTickGreenIcon}
                                </span>
                                <span className={styles.completedSuccessText}>
                                  practice completed
                                </span>
                              </div>
                            ) : (
                              <div className={styles.meetingScheduledContainer}>
                                <span style={{ marginRight: "8px" }}>
                                  {bxMeetingScheduled}
                                </span>
                                <span className={styles.meetingScheduledText}>
                                  Meeting scheduled
                                </span>
                              </div>
                            )}
                          </div>
                        </div>
                      ) : showMedia.type === "assessment" ? (
                        <div className={styles.practiceContainer}>
                          <h2
                            className={styles.contentTitle}
                            style={{
                              marginTop: "1.5rem",
                              marginLeft: "3rem",
                            }}
                          >
                            Assessment
                          </h2>
                          <div className={styles.practiceInfoContainer}>
                            <p className={styles.practiceTitle}>
                              {showMedia.name}
                            </p>
                            <div>
                              <p
                                className={styles.practiceDesc}
                              >{`This assessment contains ${
                                assessmentDetails?.assessmentInfo
                                  ?.totalQuestion || 0
                              } questions , solve the questions in given time and minimum pass 
                            percentage is ${
                              showMedia?.completionCriteria || 100
                            }%, prepare according to that.`}</p>
                            </div>
                            <div style={{ display: "flex", marginTop: "2rem" }}>
                              <div style={{ display: "flex" }}>
                                {bsxSpreadSheetIcon}
                                <p className={styles.practiceQuesStyle}>{`${
                                  assessmentDetails?.assessmentInfo
                                    ?.totalQuestion || 0
                                } Questions`}</p>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  "margin-left": "3rem",
                                }}
                              >
                                {bxTimeIcon}
                                <p className={styles.practiceQuesStyle}>{`${
                                  assessmentDetails?.assessmentInfo
                                    ?.timeLimit || 0
                                } mins`}</p>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  "margin-left": "3rem",
                                }}
                              >
                                {bxChartIcon}
                                <p className={styles.practiceQuesStyle}>{`${
                                  showMedia?.completionCriteria || 100
                                } % to pass`}</p>
                              </div>
                              {assessmentDetails && assessmentDetails.assessmentInfo && assessmentDetails.assessmentInfo?.allowRetake && assessmentDetails.userAssessmentDetails !== null && (
                                <div
                                  style={{
                                    display: "flex",
                                    "margin-left": "3rem",
                                  }}
                                >
                                  {bxRefreshIcon}
                                  <p className={styles.practiceQuesStyle}>
                                    {`${parseInt(assessmentDetails.assessmentInfo.maxNoOfRetakeAttempts) - parseInt(assessmentDetails.userAssessmentDetails.retakeAttempt) > 0
                                    ? parseInt(assessmentDetails.assessmentInfo.maxNoOfRetakeAttempts) - parseInt(assessmentDetails.userAssessmentDetails.retakeAttempt) : 0} Retakes available`}</p>
                                </div>
                              )}
                            </div>
                            <div>
                              {assessmentDetails ? (
                                assessmentDetails?.assessmentInfo
                                  ?.allowRetake ? (
                                  assessmentDetails?.userAssessmentDetails
                                    ?.retakeAttempt >=
                                    assessmentDetails?.assessmentInfo
                                      ?.maxNoOfRetakeAttempts ||
                                  assessmentDetails?.userAssessmentDetails
                                    ?.totalMarks >=
                                    showMedia?.completionCriteria ? (
                                    <div>
                                      {assessmentDetails?.userAssessmentDetails
                                        ?.totalMarks <
                                        showMedia?.completionCriteria && (
                                        <div
                                          className={styles.failureContainer}
                                        >
                                          <span className={styles.failureText}>
                                            You didnt pass on previous attempt,
                                            Retake assessment and get required
                                            pass marks
                                          </span>
                                        </div>
                                      )}
                                      <div
                                        className={
                                          styles.completedSuccessContainer
                                        }
                                      >
                                        <span style={{ marginRight: "8px" }}>
                                          {bxTickGreenIcon}
                                        </span>
                                        <span
                                          className={
                                            styles.completedSuccessText
                                          }
                                        >
                                          Assessment completed
                                        </span>
                                      </div>
                                    </div>
                                  ) : (
                                    <>
                                      {assessmentDetails?.userAssessmentStatus ==
                                        "Complete" &&
                                        assessmentDetails?.userAssessmentDetails
                                          ?.totalMarks <
                                          showMedia?.completionCriteria && (
                                          <div
                                            className={styles.failureContainer}
                                          >
                                            <span
                                              className={styles.failureText}
                                            >
                                              You didnt pass on previous
                                              attempt, Retake assessment and get
                                              required pass marks
                                            </span>
                                          </div>
                                        )}
                                      <TervButtonFilled
                                        text={`${
                                          assessmentDetails.userAssessmentDetails
                                            ? "Retake Assessment"
                                            : "Take Assessment"
                                        }`}
                                        width="auto"
                                        type="button"
                                        style={{ marginTop: "1rem" }}
                                        onClick={() => {
                                          takeAssessment(
                                            showMedia.externalResourceId
                                          );
                                          //updateProgress(showMedia.resourceId, showMedia.moduleId);
                                        }}
                                      />
                                    </>
                                  )
                                ) : showMedia.status == null ||
                                  showMedia.status == "Started" ? (
                                  <TervButtonFilled
                                    text="Take Assessment"
                                    width="auto"
                                    type="button"
                                    style={{ marginTop: "1rem" }}
                                    onClick={() => {
                                      takeAssessment(
                                        showMedia.externalResourceId
                                      );
                                      updateProgress(
                                        showMedia.resourceId,
                                        showMedia.moduleId
                                      );
                                    }}
                                  />
                                ) : showMedia.status == "Completed" ? (
                                  <div
                                    className={styles.completedSuccessContainer}
                                  >
                                    <span style={{ marginRight: "8px" }}>
                                      {bxTickGreenIcon}
                                    </span>
                                    <span
                                      className={styles.completedSuccessText}
                                    >
                                      Assessment completed
                                    </span>
                                  </div>
                                ) : (
                                  <>Evalution in process</>
                                )
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </>
                  )}
                  <ToastContainer autoClose={5000} />
                </div>
              </div>
            </div>
            <div className="controls-side-asdf">
              <Box className="drawer">
                {/* <Typography className="p-3 h5 font-weight-bold">
                    Course content
                  </Typography> */}

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    overflow: "hidden",
                    height: "100%",
                  }}
                >
                  <AppBar
                    position="static"
                    className="detail-coursecontent"
                    ref={appBarRef}
                    elevation={0}
                  >
                    <Tabs
                      value={tab}
                      onChange={(_, newInd) => handleTab(newInd)}
                      indicatorColor="primary"
                      textColor="primary"
                      variant="scrollable"
                      scrollButtons="auto"
                    >
                      <Tab label="Content " />
                      {coursedetails?.enableDiscussion && (
                        <Tab label="Discussion" />
                      )}
                      {coursedetails?.enableNotes && <Tab label="Notes" />}
                      {/* {coursedetails?.review && <Tab label="Review" />}
                        <Tab label="Certificate" /> */}
                    </Tabs>
                  </AppBar>
                  <Box
                    sx={{
                      flex: "1 1 0%",
                      overflow: "hidden",
                      height: "100%",
                      position: "relative",
                      background: "#F6F6F7",
                    }}
                  >
                    {/* <TabPanel value={tab} index={0}>
                      <CourseAbout data={coursedetails} />
                    </TabPanel> */}

                    <TabPanel value={tab} index={0}>
                      <div>
                        <input
                          type="search"
                          placeholder="&#xf002; Search contents...."
                          className="form-control"
                          style={{
                            fontFamily: "FontAwesome, Poppins",
                          }}
                          onChange={handleSearchChange}
                        />
                      </div>
                      <div
                        style={{
                          background: "#FFFFFF",
                          padding: "0px 0px",
                          overflowY: "scroll",
                          overflowX: "hidden",
                          height: "95%",
                          border: "1px solid #F2F1F3",
                        }}
                      >
                        {courseContent.modify.length > 0 ? (
                        <div>
                          {courseContent.modify.map((data, index) => {
                            return (
                              <div>
                                  <Accordion 
                                  expanded={expanded === `panel${index}`}
                                  onChange={handleAccChange(`panel${index}`)}
                                  style={{ background: "#F6F6F7",
                                  borderBottom:  " 0.5px solid #D8D6DB", }}>
                                  {/* <Accordion> */}
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  id="panel1bh-header"
                                >
                                  <div>
                                    <h6
                                      style={{
                                        fontWeight: 500,
                                        fontSize: 14,
                                        color: "#323036",
                                      }}
                                    >
                                      Chapter {data.sectionOrder + 1} -{" "}
                                      {data.title}
                                    </h6>
                                    <div
                                      style={{
                                        display: "flex",
                                        gap: "0.3rem",
                                      }}
                                    >
                                      <p
                                        style={{
                                          margin: 0,
                                          fontSize: 12,
                                          color: " #63606C",
                                        }}
                                      >
                                        {data.resourceList.length} content(s)
                                      </p>
                                    </div>
                                  </div>
                                </AccordionSummary>
                                {data.resourceList.length > 0 && searchValues.length > 0 ? (
                                   <div>
                                    {data.resourceList.filter((e) => e.name.toLowerCase().includes(searchValues.toLowerCase())).map((resourceDatas, inds) => (
                                    <div style={{ background: "#FFFFFF" }}>
                                    <div>
                                      {coursedetails.paidPackage ? (
                                        coursedetails.purchased ? (
                                          <div
                                            className={
                                              showMedia.resourceId ===
                                              resourceDatas.id
                                                ? "contentHeighlight"
                                                : "contentlight"
                                            }
                                            style={{
                                              display: "flex",
                                              justifyContent: "space-between",
                                              alignItems: "center",
                                              padding: "0.8rem 2rem 1rem 2rem",
                                              cursor: "pointer",
                                              // backgroundColor:"blueviolet",
                                              ...(inds + 1 !==
                                                data.resourceList.length && {
                                                borderBottom:
                                                  "1px solid #F2F1F3",
                                              }),
                                            }}
                                            onClick={handleResourceProcess.bind(
                                              this,
                                              resourceDatas,
                                              data.sectionOrder,
                                              resourceDatas.id,
                                              coursedetails,
                                              showMedia
                                            )}
                                          >
                                            <div
                                              style={{
                                                display: "flex",
                                                alignItems: "center",
                                                gap: "0.5rem",
                                              }}
                                            >
                                              {getRightIcon(
                                                resourceDatas?.type.toLowerCase()
                                              )}
                                              <p
                                                style={{
                                                  margin: 0,
                                                  fontSize: 14,
                                                  color: "#323036",
                                                }}
                                              >
                                                {resourceDatas?.name}
                                              </p>
                                            </div>
                                            <div>
                                              {resourceDatas?.type.toLowerCase() ===
                                                "Video".toLowerCase() && (
                                                <span
                                                  style={{
                                                    fontSize: 14,
                                                    color: "#323036",
                                                  }}
                                                >
                                                  {resourceDatas?.durationMin}{" "}
                                                  min(s)
                                                </span>
                                              )}
                                              {resourceDatas?.type.toLowerCase() ===
                                                "Audio".toLowerCase() && (
                                                <span
                                                  style={{
                                                    fontSize: 14,
                                                    color: "#323036",
                                                  }}
                                                >
                                                  {resourceDatas?.durationMin}{" "}
                                                  min(s)
                                                </span>
                                              )}
                                              {resourceDatas?.status ===
                                                "Completed" ||
                                                completedResources.includes(
                                                  resourceDatas?.id
                                                )}
                                            </div>
                                          </div>
                                        ) : (
                                          <div
                                            style={{
                                              display: "flex",
                                              justifyContent: "space-between",
                                              alignItems: "center",
                                              padding: "0.8rem 2rem 1rem 2rem",
                                              cursor: "pointer",
                                              // backgroundColor:"blueviolet",
                                              ...(inds + 1 !==
                                                data.resourceList.length && {
                                                borderBottom:
                                                  "1px solid #F2F1F3",
                                              }),
                                            }}
                                            onClick={() =>
                                              handlePageRedirect(
                                                coursedetails.id
                                              )
                                            }
                                          >
                                            <div
                                              style={{
                                                display: "flex",
                                                alignItems: "center",
                                                gap: "0.5rem",
                                              }}
                                            >
                                              {getRightIcon(
                                                resourceDatas?.type.toLowerCase()
                                              )}
                                              <p
                                                style={{
                                                  margin: 0,
                                                  fontSize: 14,
                                                  color: "#323036",
                                                }}
                                              >
                                                {resourceDatas?.name}
                                              </p>
                                            </div>
                                            <div>
                                              {resourceDatas?.type.toLowerCase() ===
                                                "Video".toLowerCase() && (
                                                <span
                                                  style={{
                                                    fontSize: 14,
                                                    color: "#323036",
                                                  }}
                                                >
                                                  {resourceDatas?.durationMin}{" "}
                                                  min(s)
                                                </span>
                                              )}
                                              {resourceDatas?.type.toLowerCase() ===
                                                "Audio".toLowerCase() && (
                                                <span
                                                  style={{
                                                    fontSize: 14,
                                                    color: "#323036",
                                                  }}
                                                >
                                                  {resourceDatas?.durationMin}{" "}
                                                  min(s)
                                                </span>
                                              )}
                                              {(resourceDatas?.status ===
                                                "Completed" ||
                                                completedResources.includes(
                                                  resourceDatas?.id
                                                )) &&
                                                circleCheckSolid({
                                                  size: 16,
                                                  color: "#43B224",
                                                })}
                                            </div>
                                          </div>
                                        )
                                      ) : (
                                        <div
                                          className={
                                            showMedia.resourceId ===
                                            resourceDatas.id
                                              ? "contentHeighlight"
                                              : "contentlight"
                                          }
                                          style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                            padding: "0.8rem 2rem 1rem 2rem",
                                            cursor: "pointer",
                                            // backgroundColor:"blueviolet",
                                            ...(inds + 1 !==
                                              data.resourceList.length && {
                                              borderBottom: "1px solid #F2F1F3",
                                            }),
                                          }}
                                          onClick={handleResourceProcess.bind(
                                            this,
                                            resourceDatas,
                                            data.sectionOrder
                                          )}
                                        >
                                          <div
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                              gap: "0.5rem",
                                            }}
                                          >
                                            {getRightIcon(
                                              resourceDatas?.type.toLowerCase()
                                            )}
                                            <p
                                              style={{
                                                margin: 0,
                                                fontSize: 14,
                                                color: "#323036",
                                                width: "150px",
                                              }}
                                            >
                                              {resourceDatas?.name}
                                            </p>
                                          </div>
                                          <div>
                                            {resourceDatas?.type.toLowerCase() ===
                                              "Video".toLowerCase() && (
                                              <span
                                                style={{
                                                  fontSize: 14,
                                                  color: "#323036",
                                                }}
                                              >
                                                {resourceDatas?.durationMin}{" "}
                                                min(s)
                                              </span>
                                            )}
                                            {resourceDatas?.type.toLowerCase() ===
                                              "Audio".toLowerCase() && (
                                              <span
                                                style={{
                                                  fontSize: 14,
                                                  color: "#323036",
                                                }}
                                              >
                                                {resourceDatas?.durationMin}{" "}
                                                min(s)
                                              </span>
                                            )}

                                            {(resourceDatas?.status ===
                                              "Completed" ||
                                              completedResources.includes(
                                                resourceDatas?.id
                                              )) &&
                                              circleCheckSolid({
                                                size: 16,
                                                color: "#43B224",
                                              })}
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                    </div>
                                  ))}
                                   </div>
                                ):(
                                <div>
                                {data.resourceList.length > 0 && 
                                  data.resourceList.map((resourceData, ind) => (
                                    <div style={{ background: "#FFFFFF" }}>
                                    <div>
                                      {coursedetails.paidPackage ? (
                                        coursedetails.purchased ? (
                                          <div
                                            className={
                                              showMedia.resourceId ===
                                              resourceData.id
                                                ? "contentHeighlight"
                                                : "contentlight"
                                            }
                                            style={{
                                              display: "flex",
                                              justifyContent: "space-between",
                                              alignItems: "center",
                                              padding: "0.8rem 2rem 1rem 2rem",
                                              cursor: "pointer",
                                              // backgroundColor:"blueviolet",
                                              ...(ind + 1 !==
                                                data.resourceList.length && {
                                                borderBottom:
                                                  "1px solid #F2F1F3",
                                              }),
                                            }}
                                            onClick={handleResourceProcess.bind(
                                              this,
                                              resourceData,
                                              data.sectionOrder,
                                              resourceData.id,
                                              coursedetails,
                                              showMedia
                                            )}
                                          >
                                            <div
                                              style={{
                                                display: "flex",
                                                alignItems: "center",
                                                gap: "0.5rem",
                                              }}
                                            >
                                              {getRightIcon(
                                                resourceData?.type.toLowerCase()
                                              )}
                                              <p
                                                style={{
                                                  margin: 0,
                                                  fontSize: 14,
                                                  color: "#323036",
                                                }}
                                              >
                                                {resourceData?.name}
                                              </p>
                                            </div>
                                            <div>
                                              {resourceData?.type.toLowerCase() ===
                                                "Video".toLowerCase() && (
                                                <span
                                                  style={{
                                                    fontSize: 14,
                                                    color: "#323036",
                                                  }}
                                                >
                                                  {resourceData?.duration}
                                                </span>
                                              )}
                                              {resourceData?.type.toLowerCase() ===
                                                "Audio".toLowerCase() && (
                                                <span
                                                  style={{
                                                    fontSize: 14,
                                                    color: "#323036",
                                                  }}
                                                >
                                                  {resourceData?.duration}
                                                </span>
                                              )}
                                              {resourceData?.status ===
                                                "Completed" ||
                                                completedResources.includes(
                                                  resourceData?.id
                                                )}
                                            </div>
                                          </div>
                                        ) : (
                                          <div
                                            style={{
                                              display: "flex",
                                              justifyContent: "space-between",
                                              alignItems: "center",
                                              padding: "0.8rem 2rem 1rem 2rem",
                                              cursor: "pointer",
                                              // backgroundColor:"blueviolet",
                                              ...(ind + 1 !==
                                                data.resourceList.length && {
                                                borderBottom:
                                                  "1px solid #F2F1F3",
                                              }),
                                            }}
                                            onClick={() =>
                                              handlePageRedirect(
                                                coursedetails.id
                                              )
                                            }
                                          >
                                            <div
                                              style={{
                                                display: "flex",
                                                alignItems: "center",
                                                gap: "0.5rem",
                                              }}
                                            >
                                              {getRightIcon(
                                                resourceData?.type.toLowerCase()
                                              )}
                                              <p
                                                style={{
                                                  margin: 0,
                                                  fontSize: 14,
                                                  color: "#323036",
                                                }}
                                              >
                                                {resourceData?.name}
                                              </p>
                                            </div>
                                            <div>
                                              {resourceData?.type.toLowerCase() ===
                                                "Video".toLowerCase() && (
                                                <span
                                                  style={{
                                                    fontSize: 14,
                                                    color: "#323036",
                                                  }}
                                                >
                                                  {resourceData?.duration}
                                                </span>
                                              )}
                                              {resourceData?.type.toLowerCase() ===
                                                "Audio".toLowerCase() && (
                                                <span
                                                  style={{
                                                    fontSize: 14,
                                                    color: "#323036",
                                                  }}
                                                >
                                                  {resourceData?.duration}
                                                </span>
                                              )}
                                              {(resourceData?.status ===
                                                "Completed" ||
                                                completedResources.includes(
                                                  resourceData?.id
                                                )) &&
                                                circleCheckSolid({
                                                  size: 16,
                                                  color: "#43B224",
                                                })}
                                            </div>
                                          </div>
                                        )
                                      ) : (
                                        <div
                                          className={
                                            showMedia.resourceId ===
                                            resourceData.id
                                              ? "contentHeighlight"
                                              : "contentlight"
                                          }
                                          style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                            padding: "0.8rem 2rem 1rem 2rem",
                                            cursor: "pointer",
                                            // backgroundColor:"blueviolet",
                                            ...(ind + 1 !==
                                              data.resourceList.length && {
                                              borderBottom: "1px solid #F2F1F3",
                                            }),
                                          }}
                                          onClick={handleResourceProcess.bind(
                                            this,
                                            resourceData,
                                            data.sectionOrder
                                          )}
                                        >
                                          <div
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                              gap: "0.5rem",
                                            }}
                                          >
                                            {getRightIcon(
                                              resourceData?.type.toLowerCase()
                                            )}
                                            <p
                                              style={{
                                                margin: 0,
                                                fontSize: 14,
                                                color: "#323036",
                                                width: "150px",
                                              }}
                                            >
                                              {resourceData?.name}
                                            </p>
                                          </div>
                                          <div>
                                            {resourceData?.type.toLowerCase() ===
                                              "Video".toLowerCase() && (
                                              <span
                                                style={{
                                                  fontSize: 14,
                                                  color: "#323036",
                                                }}
                                              >
                                                {resourceData?.duration}
                                              </span>
                                            )}
                                            {resourceData?.type.toLowerCase() ===
                                              "Audio".toLowerCase() && (
                                              <span
                                                style={{
                                                  fontSize: 14,
                                                  color: "#323036",
                                                }}
                                              >
                                                {resourceData?.duration}
                                              </span>
                                            )}

                                            {(resourceData?.status ===
                                              "Completed" ||
                                              completedResources.includes(
                                                resourceData?.id
                                              )) &&
                                              circleCheckSolid({
                                                size: 16,
                                                color: "#43B224",
                                              })}
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                    </div>
                                  ))}
                                </div>
                                )}
                              </Accordion>  
                              </div>
                            );
                          })}
                          </div>
                        ):(
                          <div style={{textAlign:"center"}}>No results found</div>
                        )
                          }
                      </div>
                    </TabPanel>
                    {coursedetails?.enableDiscussion && (
                      <TabPanel value={tab} index={1}>
                      <div
                        style={{
                          background: "#FFFFFF",
                          padding: "0px 0px",
                          overflowY: "scroll",
                          overflowX: "hidden",
                          height: "100%",
                          border: "1px solid #F2F1F3",
                        }}
                      >
                        <CourseDiscussion
                          discussionId={notesId}
                          // discussionName={dataName}
                          // discussionModId={moduleData}
                          testModId={initialValue.courseModuleId}
                          testModName={initialValue.name}
                          discussionsIcon={discussionsIcon}
                          discussion_update={() =>
                            getCourseResource(resourceId)
                          }
                          data={resourceDiscussion}
                          resourceName={showMedia.name}
                          childDiscussions={childDiscussions}
                        />
                        </div>
                      </TabPanel>
                    )}

                    {coursedetails?.enableNotes && (
                      <TabPanel value={tab} index={2}>
                        <CourseNotes
                          // handleResourceProcess={handleResourceProcess}
                          courseDataDetails={courseDataDetails}
                          childNotes={childNotes}
                          handleNotesContent={notesContent}
                          handleNotesId={notesId}
                          courseType={showMedia.type}
                          data={courseNotes}
                          lastViewedResource={lastViewedResource}
                          cur_duration={currVideoDuration}
                          cur_durationAud={(
                            audioRef?.current?.currentTime / 100
                          ).toFixed(2)}
                          getDuration={getCurrentDur}
                          getDurationAudio={getCurrentAudDur}
                          setCourseTypeForNotes={setCourseTypeForNotes}
                          courseTypeForNotes={courseTypeForNotes}
                        />
                      </TabPanel>
                    )}
                  </Box>
                </Box>
              </Box>
            </div>
          </div>
        </Box>
      </div>
      <SwipeableDrawer
        anchor="right"
        open={isDrawerOpen}
        onClose={toggleDrawer}
      ></SwipeableDrawer>
      {/* </Drawer> */}
    </>
  );
}

const getRightIcon = (type) =>
  type === "Video".toLowerCase() ||
  type === "Link".toLowerCase() ||
  type === "IntractionVideo".toLowerCase()
    ? CirclePlay({ size: 20, color: "#7C7887" })
    : type === "study material".toLowerCase()
    ? documentView
    : type === "Audio".toLowerCase()
    ? micIcon({ size: 20, color: "#7C7887" })
    : type === "Practice lab".toLowerCase()
    ? PracIcon({ size: 20, color: "#7C7887" })
    : type === "Live class".toLowerCase()
    ? LiveClassIcon({ size: 20, color: "#7C7887" })
    : type === "Assessment".toLowerCase()
    ? AssessmentIcon({ size: 20, color: "#7C7887" })
    : () => {};