/** @format */

import { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import moment from "moment";
import GridItem from "../../../components/GridItem/GridItem";
import AssessmentListItem from "../../../components/AssessmentListItem/AssessmentListItem";
import SkeletonGridItem from "../../../components/SkeletonGridItem/SkeletonGridItem";
import DataTableContainer from "../../../components/DataTableContainer/DataTableAssessment";
import Tabs from "../../../components/Tabs/Tabs";
import { useGetDashboardInfo } from "../../../SWRhooks/index";
import { AssessmentsSample } from "pages/Dashboard/SampleData";
import { useTranslation } from "react-i18next";
import { sessionPrivileges } from "privileges";

export const Assessments = withRouter(({ showReal, isMobile, ...props }) => {
  const Priv = sessionPrivileges().userprivileges || [];
  const [dataIsLoading, setIfDataIsLoading] = useState(false);
  const [student_assessmentViewOption, setStudent_assessmentViewOption] =
    useState("pending");
  const [student_pendingAssessments, setStudent_pendingAssessments] = useState(
    []
  );
  const [student_completedAssessments, setStudent_completedAssessments] =
    useState([]);

  const apiResp = useGetDashboardInfo(
    "getStudentDashBoardAssessments",
    showReal
  );

  const setData = (data) => {
    setStudent_pendingAssessments(data?.assessments?.pending || []);
    setStudent_completedAssessments(data?.assessments?.completed || []);
  };
  const { t } = useTranslation();

  useEffect(() => {
    if (apiResp === "Fetching...") {
      setIfDataIsLoading(true);
    }
    if (apiResp && apiResp.hasOwnProperty("assessments")) {
      setData(apiResp);
      setIfDataIsLoading(false);
    }
  }, [apiResp]);

  useEffect(() => {
    setData(AssessmentsSample);
  }, []);

  return dataIsLoading ? (
    <SkeletonGridItem
      gridPosition={[isMobile ? [1, -1] : [3, 6], showReal ? [3, 5] : [4, 6]]}
    />
  ) : (
    <GridItem
      gridPosition={[
        isMobile ? [1, -1] : [3, 6],
        showReal ? (isMobile ? [5, 6] : [3, 5]) : isMobile ? [5, 6] : [4, 6],
      ]}
    >
      <DataTableContainer
        actionBox={
          Priv.includes("VIEW_ASSESSMENT") && (
            <Tabs
              color="black"
              activeColor="green"
              activeTabId={student_assessmentViewOption}
              tabs={[
                {
                  id: "pending",
                  text: "Pending",
                  onClick() {
                    setStudent_assessmentViewOption("pending");
                  },
                },
                {
                  id: "Completed",
                  text: "Completed",
                  onClick() {
                    setStudent_assessmentViewOption("Completed");
                  },
                },
              ]}
            />
          )
        }
        title="Assessments"
        footerLinkText={"View More"}
        footerLinkOnClick={() => {
          props.history.push("/assessment");
        }}
      >
        {Priv &&
          Priv.includes("VIEW_ASSESSMENT") &&
          student_assessmentViewOption === "pending" &&
          student_pendingAssessments.map(({ name, endingDate, id }, idx) => (
            <AssessmentListItem
              hideAction={!Priv.includes("START_ASSESSMENT")}
              actionButtonText="Start"
              actionButtonVariant="fill"
              key={idx}
              onActionButtonClick={() => {
                props.history.push({
                  pathname: "/assessment",
                  params: { assessmentId: id },
                });
              }}
              primaryInfo={name}
              secondaryInfo={`${
                moment(endingDate).format() === moment().startOf("day").format()
                  ? "1"
                  : moment(endingDate) < moment().startOf("day")
                  ? 0
                  : Math.abs(
                      moment(endingDate).diff(moment().startOf("day"), "days")
                    )
              } days left`}
              status="active"
            />
          ))}
        {Priv &&
          Priv.includes("VIEW_ASSESSMENT") &&
          student_assessmentViewOption === "Completed" &&
          student_completedAssessments.map(
            ({ id, name, gradingCompleted }, idx) => (
              <AssessmentListItem
                hideAction={
                  !Priv.includes("VIEW_ASSESSMENT_REPORT") || !gradingCompleted
                }
                actionButtonText="Report"
                actionButtonVariant="outlined"
                key={idx}
                onActionButtonClick={() => {
                  props.history.push({
                    pathname: "/assessment-report",
                    params: { assessmentId: id },
                  });
                }}
                primaryInfo={name}
              />
            )
          )}
      </DataTableContainer>
    </GridItem>
  );
});
