import useSWR from "swr";
import { GetCustomerLogo } from "./GetCustomerLogo";
import api from "../api/baseConfig";

export const useGetCustomerLogo = (type?: string | undefined) => {
  const { data, error } = useSWR(
    `${api.baseURL}${api.homePage.getCustomerLogo}`,
    GetCustomerLogo,
    { dedupingInterval: 600000, refreshInterval: 600000 }
  );
  return data
};