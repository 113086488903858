/** @format */

import React, { useState, useEffect, forwardRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import { Link, useHistory } from "react-router-dom";
import * as CustomIcons from "assets/icons/icons";
import styles from "../Prepare.module.scss";
import "../hackathons.css";
import cn from "classnames";
import { useMediaQuery } from "@material-ui/core";
// import { Tabs } from "../../components/tabs/tabsStudent";
import Typography from "@material-ui/core/Typography";
import { CapitalizeFirstLetter } from "pages/utils/CapitalizeFirstLetter";
import moment from "moment";
import { TervButtonOutlined, TervButtonFilled } from "components/TervButtons";
import { WinnerCup } from "../../../Prepare/SVGs";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles({
  root: {
    width: 300,
    boxShadow: "0px 0px 25px rgba(170, 170, 170, 0.25)",
    borderRadius: 5,
    position: "relative",
  },
  tabSize: {
    padding: "3px",
  },
  rootValue: {
    padding: "10px",
  },
  mobileRoot: {
    width: 325,
    boxShadow: "0px 0px 25px rgba(170, 170, 170, 0.25)",
    borderRadius: 5,
    position: "relative",
    height: 450,
  },
  media: {
    height: "150px",
  },
  progressRoot: {
    height: 7,
    borderRadius: 50,
  },
  colorPrimary: {
    backgroundColor: "#DFDFDF",
  },
  barColorPrimary: {
    backgroundColor: "#F56F18;",
  },
  buttonWidth: {
    width: "100%",
  },
  hours: {
    color: "#4A4851",
    marginBottom: "5px",
  },
  chapter: {
    marginRight: "12px",
  },
  intermediate: {
    marginRight: "10px",
  },
  completedChapter: {
    fontWeight: 500,
    fontSize: 11,
    color: "#7C7887",
    textAlign: "center",
    display: "block",
  },
  dividers: {
    margin: "10px 0",
  },
  percentText: {
    fontWeight: 500,
    fontSize: 15,
    color: "#19181B",
    margin: 0,
  },
});
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}
// const TabScrollButton = forwardRef((props, ref) => {
//   const { direction, ...other } = props;

//   return (
//     <ButtonBase
//       component="div"
//       ref={ref}
//       style={{ opacity: other.disabled ? 0 : 1 }}
//       {...other}
//     >
//       {direction === "left" ? (
//         <ArrowBackIosIcon
//           fontSize="small"
//           className="arrow-icons"
//           style={{ fill: "black" }}
//         />
//       ) : (
//         <ArrowForwardIosIcon
//           fontSize="small"
//           className="arrow-icons"
//           style={{ fill: "black" }}
//         />
//       )}
//     </ButtonBase>
//   );
// });

export default function All({
  ContinuePackages,
  exploreCourse,
  courseSearch,
  activeTab,
}) {
  const [searchValue, setSearchValue] = useState([]);
  const [value, setValue] = useState(2);
  const smallScreen = useMediaQuery("(max-width: 767px)");
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();

  // const handlePageRedirect = (data) => {
  //   if (data.status === "Inprogress" || data.status === "Completed")
  //     history.push(`/course-learning/${data.id}`);
  //   else if (data.status === "NotStarted")
  //     history.push(`/course-overview/${data.id}`);
  // };

  // const handleCourseExpand = (e) => {
  //     e.target.value
  // }

  useEffect(() => {
    setTimeout(() => {
      if (courseSearch)
        setSearchValue(
          ContinuePackages.reduce((acc, val) => {
            if (val.name.toLowerCase().includes(courseSearch)) acc.push(val);
            return acc;
          }, [])
        );
    }, [500]);
  }, [courseSearch]);

  return (
    <>
      <div>
        <TabPanel>
          {activeTab == "All" && (
            <Grid container>
              {(courseSearch ? searchValue : ContinuePackages).length > 0 &&
                (courseSearch ? searchValue : ContinuePackages).map(
                  (data, index) => {
                    return (
                      <>
                        <Grid item sm={12} md={6} lg={4} key={data.id}>
                          <div style={{ paddingTop: "40px" }}>
                            <Card
                              className={
                                smallScreen ? classes.mobileRoot : classes.root
                              }
                            >
                              <CardMedia
                                className={classes.media}
                                image={
                                  data?.media
                                    ? data.media.includes("default")
                                      ? `${data.media}`
                                      : data?.media
                                    : `https://d2ndap9rlps54r.cloudfront.net/DefaultUploadImages/default-12.jpeg`
                                }
                                title={data.name}
                              />
                              <span
                                className={cn([styles.tag], {
                                  [styles.tagPN]: data.status === "Completed",
                                  [styles.tagDF]: data.status === "Live",
                                  [styles.tagSD]: data.status === "Scheduled",
                                })}
                              >
                                {data.status === "Completed" &&
                                  CapitalizeFirstLetter("Completed")}
                                {data.status === "Live" &&
                                  CapitalizeFirstLetter("Live")}
                                {data.status === "Scheduled" &&
                                  CapitalizeFirstLetter("Scheduled")}
                              </span>
                              {(data.registration === "Registration open" ||
                                data.registration ===
                                  "Registration closed") && (
                                <span
                                  className={cn([styles.tag2], [styles.tagAP], {
                                    // [styles.tagAP]: data.status === "Completed",
                                    // [styles.tagDF]: data.status === "live",
                                    // [styles.tagPN]: data.status === "Scheduled",
                                  })}
                                >
                                  <div style={{ display: "flex" }}>
                                    <span className="pr-2">
                                      {data.registration ===
                                        "Registration open" && (
                                        <svg
                                          width="9"
                                          height="8"
                                          viewBox="0 0 9 8"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <circle
                                            cx="4.5"
                                            cy="4"
                                            r="4"
                                            fill="#1BA94C"
                                          />
                                        </svg>
                                      )}
                                      {data.registration ===
                                        "Registration closed" && (
                                        <svg
                                          width="8"
                                          height="8"
                                          viewBox="0 0 8 8"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <circle
                                            cx="4"
                                            cy="4"
                                            r="4"
                                            fill="#DE5445"
                                          />
                                        </svg>
                                      )}
                                    </span>
                                    {data.registration === "Registration open"
                                      ? CapitalizeFirstLetter(
                                          "Registration Open"
                                        )
                                      : data.registration ===
                                        "Registration closed"
                                      ? CapitalizeFirstLetter(
                                          "Registration closed"
                                        )
                                      : ""}
                                  </div>
                                </span>
                              )}
                              <CardContent>
                                <h2 className="course-name">{data.name}</h2>
                                <p style={{ fontSize: "12px" }}>
                                  {/* {data.status === "Scheduled" ?
                                    'Hackathon Starts'
                                    :
                                    'Hackathon Ends'
                                  } */}
                                  {data.status === "Completed" && (
                                    <p style={{ fontSize: "12px" }}>
                                      Hackathon Completed
                                    </p>
                                  )}
                                  {data.status === "Live" && (
                                    <p style={{ fontSize: "12px" }}>
                                      Hackathon Ends
                                    </p>
                                  )}
                                  {data.status === "Scheduled" && (
                                    <p style={{ fontSize: "12px" }}>
                                      Hackathon Start
                                    </p>
                                  )}
                                </p>
                                <Grid container spacing={2}>
                                  <Grid item xs={12}>
                                    <div className={classes.hours}>
                                      <svg
                                        width="18"
                                        height="20"
                                        viewBox="0 0 18 20"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M4 9H6V11H4V9ZM4 13H6V15H4V13ZM8 9H10V11H8V9ZM8 13H10V15H8V13ZM12 9H14V11H12V9ZM12 13H14V15H12V13Z"
                                          fill="#96939F"
                                        />
                                        <path
                                          d="M2 20H16C17.103 20 18 19.103 18 18V4C18 2.897 17.103 2 16 2H14V0H12V2H6V0H4V2H2C0.897 2 0 2.897 0 4V18C0 19.103 0.897 20 2 20ZM16 6L16.001 18H2V6H16Z"
                                          fill="#96939F"
                                        />
                                      </svg>
                                      <span className="duration pl-2">
                                        {data.status === "Live" ||
                                        data.status === "Completed"
                                          ? moment(
                                              data.hackathonEndDate
                                            ).format("DD MMM YYYY, hh:mm a ")
                                          : moment(
                                              data.hackathonStartDate
                                            ).format("DD MMM YYYY, hh:mm a ")}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                                <Grid container spacing={2}>
                                  <Grid item xs={12} sm={12}>
                                    <div className={classes.chapter}>
                                      <svg
                                        width="19"
                                        height="17"
                                        viewBox="0 0 19 17"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M14.6036 8.04797C15.2126 7.01005 15.4757 5.80528 15.3546 4.60797C15.1756 2.82397 14.1796 1.24697 12.5516 0.167969L11.4466 1.83397C12.5656 2.57597 13.2466 3.63297 13.3646 4.80797C13.419 5.35407 13.351 5.90543 13.1656 6.42195C12.9801 6.93847 12.6819 7.40718 12.2926 7.79397L11.1006 8.98597L12.7186 9.46097C16.9506 10.701 16.9996 14.957 16.9996 15H18.9996C18.9996 13.211 18.0436 9.71497 14.6036 8.04797Z"
                                          fill="#96939F"
                                        />
                                        <path
                                          d="M7.5 9C9.706 9 11.5 7.206 11.5 5C11.5 2.794 9.706 1 7.5 1C5.294 1 3.5 2.794 3.5 5C3.5 7.206 5.294 9 7.5 9ZM7.5 3C8.603 3 9.5 3.897 9.5 5C9.5 6.103 8.603 7 7.5 7C6.397 7 5.5 6.103 5.5 5C5.5 3.897 6.397 3 7.5 3ZM9 10H6C2.691 10 0 12.691 0 16V17H2V16C2 13.794 3.794 12 6 12H9C11.206 12 13 13.794 13 16V17H15V16C15 12.691 12.309 10 9 10Z"
                                          fill="#96939F"
                                        />
                                      </svg>
                                      <span className="duration">
                                        {" "}
                                        {`${data.registeredUsersCount} Registered`}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </CardContent>
                              <CardActions>
                                {data.status !== "Live" && (
                                  <Link
                                    to={{
                                      pathname: `/hackathoninfo/${data.id}`,
                                      state: data.id,
                                    }}
                                  >
                                    <TervButtonOutlined
                                      className="continue-button"
                                      text={
                                        data.status === "Completed"
                                          ? t("View Submissions")
                                          : data.userRegistered
                                          ? t("View Hackathon")
                                          : t("Register now")
                                      }
                                      variant="outlined"
                                      onClick={""}
                                      style={{ width: "15rem" }}
                                    />
                                  </Link>
                                )}
                                {data.status === "Live" && (
                                  <Link
                                    to={{
                                      pathname: `/hackathoninfo/${data.id}`,
                                      state: data.id,
                                    }}
                                  >
                                    {data.userRegistered ? (
                                      <TervButtonFilled
                                        text={t("Start Hackathon")}
                                        variant="outlined"
                                        onClick={""}
                                        style={{ width: "15rem" }}
                                      />
                                    ) : (
                                      <TervButtonOutlined
                                        className="continue-button"
                                        text={t("Register now")}
                                        variant="outlined"
                                        onClick={""}
                                        style={{ width: "15rem" }}
                                      />
                                    )}
                                  </Link>
                                )}
                              </CardActions>
                            </Card>
                          </div>
                        </Grid>
                      </>
                    );
                  }
                )}
            </Grid>
          )}
          {courseSearch && (
            <div className="centerAlign pt-5 pb-5">
              <Card
                style={{
                  width: "50%",
                  backgroundColor: "rgba(242, 241, 243, 0.5)",
                }}
                variant="outlined"
              >
                <CardContent>
                  <div className="centerAlign">
                    <WinnerCup />
                  </div>
                  <p className="cardHeader">No hackathon Found</p>
                </CardContent>
              </Card>
            </div>
          )}
          {(courseSearch ? searchValue : ContinuePackages).length == 0 &&
            activeTab == "All" &&
            !courseSearch && (
              <div className="centerAlign pt-5 pb-5">
                <Card
                  style={{
                    width: "50%",
                    backgroundColor: "rgba(242, 241, 243, 0.5)",
                  }}
                  variant="outlined"
                >
                  <CardContent>
                    <div className="centerAlign">
                      <WinnerCup />
                    </div>
                    <p className="cardHeader">No hackathon available</p>
                  </CardContent>
                </Card>
              </div>
            )}
        </TabPanel>
      </div>
    </>
  );
}
