import Cookies from "universal-cookie";

const axios = require("axios");
class ApiHelper {
  getToken() {
    const cookies = new Cookies();
    return cookies.get("userToken");
  }
  async axiosCallSWR(url, token) {
    var config = {
      url: url,
      headers: {
        ...(token && {
          authorization: `Bearer ${token}`,
        }),
      },
      method: "GET",
    };
    let val = await axios(config)
      .then((res) => {
        return res.data;
      })
      .catch((e) => {});
    return val;
  }
  async axiosCallNoUserId(url, methodType) {
    const college_id = sessionStorage.getItem("college_id");
    const userId = sessionStorage.getItem("user_id");
    const userToken = this.getToken();
    var config = {
      url: url,
      headers: {
        ...(userToken && {
          authorization: `Bearer ${userToken}`,
        }),
      },
      method: methodType ? methodType : "GET",
    };
    let val = await axios(config)
      .then((res) => {
        return res.data;
      })
      .catch((e) => {});
    return val;
  }
  async axiosCall(url, methodType) {
    const college_id = sessionStorage.getItem("college_id");
    const userId = sessionStorage.getItem("user_id");
    const userToken = this.getToken();
    var config = {
      url: url,
      headers: {
        ...(userToken && {
          authorization: `Bearer ${userToken}`,
        }),
      },
      method: methodType ? methodType : "GET",
      params: {
        ...(college_id && { college_id }),
        ...(userId && { userId }),
        // userId: sessionStorage.getItem("user_id"), //sessionStorage.getItem("applicable_authors"),
      },
    };
    let val = await axios(config)
      .then((res) => {
        return res.data;
      })
      .catch((e) => {});
    return val;
  }

  async axiosCallUpdate(url, methodType) {
    const college_id = sessionStorage.getItem("college_id");
    const userId = sessionStorage.getItem("user_id");
    const userToken = this.getToken();
    var config = {
      url: url,
      headers: {
        ...(userToken && {
          authorization: `Bearer ${userToken}`,
        }),
      },
      method: methodType ? methodType : "GET",
      // params: {
      //   ...(college_id && { college_id }),
      //   ...(userId && { userId }),
      //   // userId: sessionStorage.getItem("user_id"), //sessionStorage.getItem("applicable_authors"),
      // },
    };
    let val = await axios(config)
      .then((res) => {
        return res.data;
      })
      .catch((e) => {});
    return val;
  }
  
  async axiosCallTokenData(url, methodType, data) {
    const userToken = this.getToken();
    var config = {
      url: url,
      headers: {
        ...(userToken && {
          authorization: `Bearer ${userToken}`,
        }),
      },
      method: methodType ? methodType : "GET",
      data: data,
    };
    let val = await axios(config)
      .then((res) => {
        return res.data;
      })
      .catch((e) => {});
    return val;
  }
  async axiosCallSimpleData(url, methodType, data) {
    var config = {
      url: url,
      method: methodType ? methodType : "GET",
      data: data,
    };
    let val = await axios(config)
      .then((res) => {
        return res.data;
      })
      .catch((e) => {});
    return val;
  }

  async axiosCallAuth(url, methodType) {
    const college_id = sessionStorage.getItem("college_id");
    const userId = sessionStorage.getItem("user_id");
    const userToken = this.getToken();
    var config = {
      url: url,
      method: methodType,
      params: {
        ...(college_id && { college_id }),
        ...(userId && { userId }),
        // userId: sessionStorage.getItem("user_id"), //sessionStorage.getItem("applicable_authors"),
      },
    };
    let val = await axios(config)
      .then((res) => {
        return res.data;
      })
      .catch((e) => {});
    return val;
  }

  // async axiosCallDynamicUserId(url, userId) {
  //   try {
  //   const config = {
  //     method: "get",
  //     url,
  //     headers: {
  //         authorization: sessionStorage.getItem("userToken"),
  //     },
  //     params: {
  //       college_id: sessionStorage.getItem("college_id"),
  //       userId,
  //     },
  //   };
  //   const val = await axios(config)
  //   return val.res;
  //   } catch(e) {
  //     throw new Error(`some error occured ${e}`)
  //   }
  // }
  async axiosCallDynamicUserId(url, userId) {
    const userToken = this.getToken();
    const config = {
      method: "get",
      url,
      headers: {
        ...(userToken && {
          authorization: `Bearer ${userToken}`,
        }),
      },
      params: {
        college_id: sessionStorage.getItem("college_id"),
        userId,
      },
    };
    const val = await axios(config)
      .then((res) => {
        return res.data;
      })
      .catch((e) => {});
    return val;
  }
  async axiosCallLC(url, methodType) {
    var config = {
      method: methodType,
      url: url,
      headers: {
        authorization: sessionStorage.getItem("lc_token"),
        "content-type": "application/json",
      },
    };
    let val = await axios(config)
      .then((res) => {
        return res.data;
      })
      .catch((e) => {
        return "error";
      });
    return val;
  }
  async axiosCallLCpost(url, methodType, data) {
    var config = {
      method: methodType,
      url: url,
      headers: {
        authorization: sessionStorage.getItem("lc_token"),
        "content-type": "application/json",
      },
      data: data,
    };
    let val = await axios(config)
      .then((res) => {
        return res.data;
      })
      .catch((e) => {});
    return val;
  }
  async axiosCallPostsave(url, methodType, data) {
    const userToken = this.getToken();
    var config = {
      method: methodType,
      url: url,
      headers: {
        ...(userToken && {
          authorization: `Bearer ${userToken}`,
        }),
      },
      data: data,
    };
    let val = await axios(config)
      .then((res) => {
        return res.data;
      })
      .catch((e) => {});
    return val;
  }

  // async axiosCall(url, methodType, data = null) {
  //   const userToken = this.getToken();
  //   const config = {
  //     method: methodType,
  //     url: url,
  //     headers: {
  //       ...(userToken && {
  //         authorization: `Bearer ${userToken}`,
  //       }),
  //     },
  //     params: {
  //       college_id: sessionStorage.getItem("college_id"),
  //     },
  //   };
  
  //   // Include data only if it is provided
  //   if (data) {
  //     config.data = data;
  //   }
  
  //   try {
  //     const response = await axios(config);
  //     return response.data;
  //   } catch (e) {
  //     return { error: e };
  //   }
  // }
  
  async axiosCallPost(url, methodType, data) {
    const userToken = this.getToken();
    var config = {
      method: methodType,
      url: url,
      headers: {
        ...(userToken && {
          authorization: `Bearer ${userToken}`,
        }),
      },
      data: data,
      params: {
        college_id: sessionStorage.getItem("college_id"),
      },
    };
    let val = await axios(config)
      .then((res) => {
        return res.data;
      })
      .catch((e) => {
        return { error: e };
      });
    return val;
  }
  async axiosCallPostLab(url, methodType, data) {
    const userToken = this.getToken();
    var config = {
      method: methodType,
      url: url,
      headers: {
        ...(userToken && {
          authorization: `Bearer ${userToken}`,
        }),
      },
      data: data,
      params: {
        college_id: sessionStorage.getItem("college_id"),
        languages: null,
      },
    };
    let val = await axios(config)
      .then((res) => {
        return res.data;
      })
      .catch((e) => {});
    return val;
  }

  async login(url, data, authorizationHeader = {}) {
    var reqData =
      "grant_type=password&username=tervadmin@gmail.com&password=123456";
    let res = {};
    try {
      res = await axios.request({
        url: url,
        method: "post",
        // auth: {
        //   username: "exampleClient",
        //   password: "exampleSecret",
        // },
        data: data,

        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          ...authorizationHeader,
          // Authorization: "",
        },
      });
      return { type: "success", data: res };
    } catch (err) {
      return { type: "error", data: err.response };
    } finally {
    }
  }

  async signUpValidateUser(url, data) {
    var config = {
      method: "get",
      url: url,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    let val = await axios(config)
      .then((res) => {
        return res.data;
      })
      .catch((e) => {
        return { error: e };
      });
    return val;
  }

  async signup(url, data) {
    var config = {
      method: "post",
      url: url,
      data: data,
      headers: {
        "Content-Type": "application/json",
      },
    };
    let val = await axios(config)
      .then(function (res) {
        return res;
      })
      .catch(function (error) {});

    return val;
  }

  async customerNameValidation(url) {
    const userToken = this.getToken();
    var config = {
      method: "get",
      url: url,
      headers: {
        ...(userToken && {
          authorization: `Bearer ${userToken}`,
        }),
      },
    };
    let val = await axios(config)
      .then(function (res) {
        return res.data;
      })
      .catch(function (error) {});

    return val;
  }

  async roleValidation(url) {
    const userToken = this.getToken();
    var config = {
      method: "get",
      url: url,
      headers: {
        ...(userToken && {
          authorization: `Bearer ${userToken}`,
        }),
      },
    };
    let val = await axios(config)
      .then(function (res) {
        return res.data;
      })
      .catch(function (error) {});

    return val;
  }

  async axiosUploadFile(url, data) {
    const userToken = this.getToken();
    let val = axios
      .request({
        url: url,
        method: "post",
        headers: {
          ...(userToken && {
            authorization: `Bearer ${userToken}`,
          }),
          "Content-Type": "multipart/form-data",
        },

        data: data,
      })
      .then(function (res) {
        return res;
      });

    return val;
  }

  async uploadFile(url, data, config) {
    const userToken = this.getToken();
    return axios.post(url, data, {
      headers: {
        ...(userToken && {
          authorization: `Bearer ${userToken}`,
        }),
        "Content-Type": "multipart/form-data",
      },
      ...config,
    });
  }

  async getQuestionBankQues(url, methodType, data) {
    const userToken = this.getToken();
    var config = {
      method: methodType,
      url: url,
      headers: {
        ...(userToken && {
          authorization: `Bearer ${userToken}`,
        }),
      },
      data: data,
    };
    let val = await axios(config)
      .then((res) => {
        return res.data;
      })
      .catch((e) => {});
    return val;
  }

  async getQuestionBankFilter(url, methodType) {
    const userToken = this.getToken();
    var config = {
      method: methodType,
      url: url,
      headers: {
        ...(userToken && {
          authorization: `Bearer ${userToken}`,
        }),
      },
    };
    let val = await axios(config)
      .then((res) => {
        return res.data;
      })
      .catch((e) => {});
    return val;
  }

  async getAssessmentDetails(url, methodType) {
    const userToken = this.getToken();
    var config = {
      method: methodType,
      url: url,
      headers: {
        ...(userToken && {
          authorization: `Bearer ${userToken}`,
        }),
      },
    };
    let val = await axios(config)
      .then((res) => {
        return res.data;
      })
      .catch((e) => {});
    return val;
  }

  async getCoursesDetails(url, methodType) {
    const userToken = this.getToken();
    var config = {
      method: methodType,
      url: url,
      headers: {
        ...(userToken && {
          authorization: `Bearer ${userToken}`,
        }),
      },
    };
    let val = await axios(config)
      .then((res) => {
        return res.data;
      })
      .catch((e) => {});
    return val;
  }

  async getPackageDetails(url, methodType) {
    const userToken = this.getToken();
    var config = {
      method: methodType,
      url: url,
      headers: {
        ...(userToken && {
          authorization: `Bearer ${userToken}`,
        }),
      },
    };
    let val = await axios(config)
      .then((res) => {
        return res.data;
      })
      .catch((e) => {});
    return val;
  }

  async getCustomerDetails(url, methodType) {
    const userToken = this.getToken();
    var config = {
      method: methodType,
      url: url,
      headers: {
        ...(userToken && {
          authorization: `Bearer ${userToken}`,
        }),
      },
    };
    let val = await axios(config)
      .then((res) => {
        return res.data;
      })
      .catch((e) => {});
    return val;
  }

  async getExpireCustomer(url, methodType) {
    const userToken = this.getToken();
    var config = {
      method: methodType,
      url: url,
      headers: {
        ...(userToken && {
          authorization: `Bearer ${userToken}`,
        }),
      },
    };
    let val = await axios(config)
      .then((res) => {
        return res.data;
      })
      .catch((e) => {});
    return val;
  }
}
export default new ApiHelper();
