import cn from 'classnames';
import styles from './card.module.scss';

export default function Card({
  className,
  children,
  title,
  titleIcon,
  titleContainerClassName,
  titleClassName }) {
  return (
    <div className={cn(styles.card, className)}>
      <div className={cn(styles.titleContainer, titleContainerClassName)}>
        {titleIcon && <div className={styles.titleIcon}>{titleIcon}</div>}
        <div className={cn(styles.title, titleClassName)} >{title}</div>
      </div>
      <div className={styles.content} style={{height: 300}}>
        {children}
      </div>
    </div>
  );
}
