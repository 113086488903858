import { useEffect, useState } from "react";
import { ResponsiveBar } from "@nivo/bar";
import truncate from "truncate-html";
import GridItem from "../../../components/GridItem/GridItem";
import SkeletonGridItem from "../../../components/SkeletonGridItem/SkeletonGridItem";
import ChartContainer from "../../../components/ChartContainer/ChartContainer";
import { useGetDashboardInfo } from "pages/Dashboard/SWRhooks";
import { AdminAssessmentDetails } from "pages/Dashboard/SampleData";

const assessmentColor = {
  Completed: "#37B97A",
  "Not Completed": "#C63E53",
};
const getAssessmentColor = (bar) => assessmentColor[bar.id];

export function AssessmentDetails({ showReal, isMobile }) {
  const [dataIsLoading, setIfDataIsLoading] = useState(false);
  const [assessmentsDetails, setAssessmentsDetails] = useState([]);
  const [assessmentsMaxValue, setAssessmentsMaxValue] = useState(null);

  const apiResp = useGetDashboardInfo(
    "getAdminDashBoardAssessmentDetails",
    showReal
  );

  const setData = (data) => {
    setAssessmentsDetails(
      data.assessmentDetails.slice(0, 5).map((a) => ({
        Completed: a?.completed,
        "Not Completed": a?.notCompleted,
        assessment: a?.name,
      }))
    );

    const assessmentsDetailsMaxValue =
      Math.max(
        0,
        ...data.assessmentDetails
          .slice(0, 5)
          .map((a) => [a?.completed, a?.notCompleted])
          .flat()
      ) || 1;
    setAssessmentsMaxValue(
      assessmentsDetailsMaxValue + assessmentsDetailsMaxValue / 5
    );
  };

  useEffect(() => {
    if (apiResp === "Fetching...") {
      setIfDataIsLoading(true);
    }
    if (apiResp && apiResp.hasOwnProperty("assessmentDetails")) {
      setData(apiResp);
      setIfDataIsLoading(false);
    }
  }, [apiResp]);

  useEffect(() => {
    setData(AdminAssessmentDetails);
  }, []);

  return dataIsLoading ? (
    <SkeletonGridItem
      gridPosition={[isMobile ? [1, -1] : [1, 7], showReal ? [5, 6] : [5, 6]]}
    />
  ) : (
    <GridItem
      gridPosition={[isMobile ? [1, -1] : [1, 7], showReal ? [5, 6] : [5, 6]]}
    >
      <ChartContainer title={<strong>Assessment Details</strong>} restrictHeight >
        <ResponsiveBar
          animate={true}
          axisBottom={{
            legend: "Assessments name",
            legendOffset: 45,
            legendPosition: "middle",
            tickPadding: 8,
            tickRotation: 0,
            tickSize: 0,
            renderTick: ({
              textAnchor,
              textBaseline,
              textX,
              textY,
              value,
              x,
              y,
            }) => {
              return (
                <g transform={`translate(${x},${y + 10})`}>
                  <text
                    alignmentBaseline={textBaseline}
                    textAnchor={textAnchor}
                    transform={`translate(${textX},${textY})`}
                    fontSize={11}
                  >
                    <tspan>{truncate(value, 7)}</tspan>
                  </text>
                </g>
              );
            },
          }}
          axisLeft={{
            legend: "Students",
            legendOffset: -20,
            legendPosition: "middle",
            renderTick: () => <></>,
            tickValues: false,
          }}
          axisRight={null}
          axisTop={null}
          colors={getAssessmentColor}
          data={assessmentsDetails}
          enableGridX={false}
          enableGridY={false}
          groupMode="grouped"
          indexBy="assessment"
          innerPadding={5}
          isInteractive={false}
          keys={["Completed", "Not Completed"]}
          labelTextColor="#3A3A3A"
          labelFormat={(d) => <tspan y={-8}>{d}</tspan>}
          legends={[
            {
              anchor: "top-right",
              dataFrom: "keys",
              direction: "column",
              itemDirection: "left-to-right",
              itemHeight: 12,
              itemOpacity: 1,
              itemsSpacing: 8,
              itemWidth: 100,
              justify: false,
              symbolSize: 12,
              translateX: 30,
              translateY: -50,
            },
          ]}
          margin={{
            bottom: 65,
            left: 30,
            right: 30,
          }}
          minValue={0}
          maxValue={assessmentsMaxValue}
          motionDamping={15}
          motionStiffness={90}
          padding={0.3}
          theme={{
            legends: {
              text: {
                fontSize: 12,
              },
            },
            axis: {
              domain: {
                line: {
                  stroke: "#564848",
                  strokeWidth: 1,
                },
              },
              legend: {
                text: {
                  color: "#000",
                  fontSize: 14,
                },
              },
            },
          }}
          valueScale={{ type: "linear" }}
        />
      </ChartContainer>
    </GridItem>
  );
}
