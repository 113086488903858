import { Field } from "redux-form";

const flexStyle = { display: "flex", gap: "1rem",
 alignItems: "center", };

const Input = ({ input }) => (
  <input className="terv-form terv-form-md" {...input} />
);

const Checkbox = ({ input: { onChange, value } }) => (
  <div style={{width:"20px",paddingTop:"3px"}}>
  <input
    type="checkbox"
    checked={!!value}
    onChange={(e) => onChange(e.target.checked)}
  />
  </div>
);

const SecureExamBrowserSettings = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        marginBottom: "1rem",
        marginLeft: 17,
      }}
    >
      <div style={{ display: "flex", gap: "1rem" }}>
        <div style={flexStyle}>
          <Field name="sebTaskbar" component={Checkbox}/>
          <span style={{width:"220px"}}>Show task bar</span>
        </div>
        <div style={flexStyle}>
          <Field name="sebUserQuit" component={Checkbox} />
          <span style={{width:"220px"}}>Allow user to quit</span>
        </div>
        <div style={flexStyle}>
          <Field name="sebABIProctor" component={Checkbox} />
          <span style={{width:"220px"}}>Enable AI Proctoring</span>
        </div>
      </div>
    </div>
  );
};

export default SecureExamBrowserSettings;