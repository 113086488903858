import React from 'react'
import ReactHtmlParser from "html-react-parser";
import { useMediaQuery } from "@material-ui/core";

export default function HackathonJudgingCriteria(props) {
    const smallScreen = useMediaQuery("(max-width: 767px)");
return (
<div>
    <div>
        <div className={smallScreen ? '' : "pt-3"}>
            <div style={smallScreen ? {display:"flex"} : {display:"flex", paddingLeft: "3rem"}}>
                <h1 className="hackathonCardHeader" style={{fontSize: "20px"}}>Judging Criteria</h1>
                <svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M14 5L7 0V10L14 5ZM0 0V10L7 5L0 0Z" fill="#2170D8" />
                </svg>
            </div>
            <p className={smallScreen ? '' :"paragraphreg"}>{ReactHtmlParser(props.overview.judgingCriteria)}</p>
        </div>
    </div>
</div>
)
}