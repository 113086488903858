import Select from "react-select";
import { Input, Label } from "reactstrap";
import CustomSelect from "components/CustomSelect";
import { Controller } from "react-hook-form";
import Grid from "@material-ui/core/Grid";
import Badge from "@material-ui/core/Badge";
import { makeStyles } from "@material-ui/core/styles";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import { GetObjectFromString } from "pages/utils/GetObjectFromString";
import { RequiredIndicator } from "pages/utils/RequiredIndicator";
import { ReactSelectTervStyle } from "pages/utils/ReactSelectTervStyle";
import { GetObjectArray } from "pages/utils/GetObjectArray";
import { sessionPrivileges } from "privileges";

const useStyles = makeStyles({
  resetButton: {
    fontSize: 19,
    cursor: "pointer",
    borderRadius: "1.5rem",
    padding: 1,
    "&:hover": {
      backgroundColor: "#f8edeb",
    },
    transition: "background-color 0.3s",
  },
});

export default function HandleStudentSelection({
  hookFormControls: {
    control,
    register,
    formState: { errors },
    watch,
    setValue,
    value,
  },
  allFilterTypes,
  batchErrors,
  filterType,
  show,
  returnParsedData,
  resetFilter,
  departments,
  students,
  checkIfBatchNameExists,
  viewOnly,
  studentFieldName,
  studentsCount,
}) {
  const classes = useStyles();
  const userPrivileges = sessionPrivileges();
  const shouldDisableSelect =
    watch("filterType") &&
    watch("filterType") !== "custom_batch" &&
    watch("filterType") !== "all" &&
    watch("filterType") !== "only" &&
    watch("filterType") !== "except" &&
    watch("filterType") !== "new";

  return (
    <Grid container spacing={4}>
      <Grid item xs={6}>
        <label className="terv-label">
          {RequiredIndicator("Assign Students ")}
        </label>
        {show ? (
        <Controller
          name="filterType"
          control={control}
          rules={{ required: "Please choose one" }}
          render={({ field: { onChange, value } }) => (
            <Select
              options={allFilterTypes}
              onChange={(value) => {
                onChange(value.value);
                setValue(
                  studentFieldName,
                  returnParsedData(
                    value.value,
                    watch(studentFieldName),
                    students
                  )
                );
              }}
              styles={ReactSelectTervStyle("md")}
              maxMenuHeight={120}
              isDisabled={viewOnly}
            />
          )}
        />
        ):(
          <Controller
          name="filterType"
          control={control}
          rules={{ required: "Please choose one" }}
          render={({ field: { onChange, value } }) => (
            <Select
              options={allFilterTypes}
              onChange={(value) => {
                onChange(value.value);
                if(value.value != "all"){
                  setValue(studentFieldName,[]);
                }
                setValue(
                  studentFieldName,
                  returnParsedData(
                    value.value,
                    watch(studentFieldName),
                    students
                  )
                );
              }}
              value={GetObjectFromString(allFilterTypes, value)}
              styles={ReactSelectTervStyle("md")}
              maxMenuHeight={120}
              isDisabled={viewOnly}
            />
          )}
        />
        )}

        {errors.filterType && (
          <span style={{ color: "red" }}>{errors.filterType.message}</span>
        )}
      </Grid>
      {watch("filterType") && watch("filterType") === "custom_batch" && (
        <Grid item xs={6}>
          <label className="terv-label">
            {RequiredIndicator("Batch name ")}
          </label>
          <input
            {...register("batchName", {
              required: "Please enter a valid name",
            })}
            onBlur={(e) => checkIfBatchNameExists(e.target.value)}
            className="terv-form terv-form-md"
            disabled={userPrivileges.isAdmin || viewOnly}
          />
           {batchErrors.batchName && (
            <p style={{ color: "red"}}>{batchErrors.batchName}</p>
          )}
        </Grid>
      )}
      {watch("filterType") &&
        watch("filterType") !== "all" &&
        watch("filterType") !== "only" &&
        watch("filterType") !== "except" &&
        watch("filterType") !== "new" && (
          <Grid item xs={6}>
            <label className="terv-label">Department</label>
            <Controller
              name="studentsDepartment"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Select
                  options={departments}
                  onChange={(value) => onChange(value.value)}
                  value={GetObjectFromString(departments, value)}
                  styles={ReactSelectTervStyle("md")}
                  hideBadge={true}
                  isDisabled={shouldDisableSelect || viewOnly}
                />
              )}
            />
          </Grid>
        )}
      {watch("filterType") && watch("filterType") === "custom_batch" && (
        <Grid item xs={6}>
          <label className="terv-label">
            Filter by reg no&nbsp;&nbsp;
            <RotateLeftIcon
              classes={{ root: classes.resetButton }}
              onClick={resetFilter}
            />
          </label>
          <div style={{ display: "flex", gap: 20, alignItems: "center" }}>
            <input
              {...register("regNoFrom")}
              className="terv-form terv-form-sm"
              disabled={viewOnly}
            />
            <div>To</div>
            <input
              {...register("regNoTo")}
              className="terv-form terv-form-sm"
              disabled={viewOnly}
            />
          </div>
        </Grid>
      )}
      {watch("filterType") && watch("filterType") !== "all" && (
        <Grid item xs={watch("filterType") === "custom_batch" ? 12 : 6}>
          <label className="terv-label" style={{ position: "relative" }}>
            {RequiredIndicator("Select students ")}&nbsp;&nbsp;
            <Badge
              // badgeContent={studentFieldName.length}

              badgeContent={watch(studentFieldName).length}
              max={999}
              color="primary"
              style={{ marginTop: 10, marginLeft: 10, zIndex: 0 }}
            />
          </label>
          <Controller
            name={studentFieldName}
            rules={{ required: "Please select atleast one student" }}
            control={control}
            render={({ field: { onChange, value } }) => (
              <CustomSelect
                options={students}
                onChange={(val) =>
                  onChange(
                    returnParsedData(
                      watch("filterType"),
                      val ? val.map((it) => it.value) : [],
                      students
                    )
                  )
                }
                value={GetObjectArray(students, value)}
                styles={ReactSelectTervStyle(
                  watch("filterType") === "custom_batch" ? "lg" : "md",
                  true
                )}
                isDisabled={shouldDisableSelect}
              />
            )}
          />
          {errors[studentFieldName] && (
            <span style={{ color: "red" }}>
              {errors[studentFieldName].message}
            </span>
          )}
        </Grid>
      )}
    </Grid>
  );
}
