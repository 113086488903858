import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Checkbox,
} from "@material-ui/core";
import { CloseOutlined } from "@material-ui/icons";
import RichTextEditor from "components/RichTextEditor";
import { Button, Input, Label } from "reactstrap";

import styles from "../CustomerManagement/BulkUserUpload.module.scss";

export default function QuestionTypeModal(props) {
  return (
    <Dialog open={props.isOpen} onClose={props.close} fullWidth maxWidth="sm">
      <DialogTitle classes={{ root: styles.header }}>
        {props.header}
        <CloseOutlined
          classes={{ root: styles.closeButton }}
          onClick={props.close}
        />
      </DialogTitle>
      <DialogContent>
        <Label>{props.TextArea.label}</Label>
        {props.TextArea.label === "Option" ? (
          <RichTextEditor
            value={props.TextArea.value}
            onChange={(value) => {
              props.TextArea.onChange({ target: { value } });
            }}
          />
        ) : (
          <Input
            onChange={props.TextArea.onChange}
            value={props.TextArea.value}
            type="textarea"
            style={{
              backgroundColor: "#FAFBFC",
              border: "1px solid #DFE1E6",
            }}
          />
        )}
        {props.secondField.display && (
          <>
            <Label>{props.secondField.label}</Label>
            <Input
              type={props.secondField.type}
              onChange={props.TextField.onChange}
              value={props.TextField.value}
              style={{
                backgroundColor: "#FAFBFC",
                border: "1px solid #DFE1E6",
              }}
            />
          </>
        )}
        {props.checkbox.display && (
          <div style={{ marginTop: 10 }}>
            <Label>Is Answer</Label>
            &nbsp; &nbsp;
            <Checkbox
              disabled={props.checkbox.disabled}
              color="primary"
              checked={props.checkbox.value}
              onChange={props.checkbox.onChange}
              style={{ padding: 0 }}
            />
            {props.checkbox.disabled && (
              <span
                style={{
                  borderRadius: 5,
                  color: "#E6A812",
                  backgroundColor: "#FFF9CC",
                  padding: 4,
                  height: 20,
                }}
              >
                You've already selected 1 Option as the Answer
              </span>
            )}
          </div>
        )}
      </DialogContent>
      <DialogActions style={{ display: "flex", justifyContent: "center" }}>
        <Button
          color="primary"
          style={{ fontSize: 16 }}
          onClick={props.onSave}
          disabled={props.disabled}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
