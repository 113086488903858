/** @format */

import React from "react";
import { HomeContent } from "./HomeContent";
import { uid } from "react-uid";
import styles from "./BookACall.module.scss";
import { circleCheckSolid } from "assets/icons/icons";
import { BookACallBanner } from "./SVGs";
import { useForm } from "react-hook-form";
import { emailRegex, phoneNumberRegex } from "pages/utils/constants";
import { withStyles, Button } from "@material-ui/core";
import LetsTalk from "./LetsTalk.png";
import { useTranslation } from "react-i18next";
import sliderBannerBg from "../../../assets/Landing/vector3.png";

interface Form {
  name: string;
  email: string;
  number: string | number;
}

const CustomButton = withStyles({
  root: {
    backgroundColor: "#794df5",
    width: "100%",
    fontWeight: 600,
    fontSize: "1rem",
    color: "#fff",
    height: "3.1875rem",
    textTransform: "none",
    fontFamily: "Poppins",
    padding: "1rem 2rem",
    "&:hover": {
      backgroundColor: "#7348F0",
    },
  },
})(Button);

interface Props {
  isMobile: boolean;
  isTab: boolean;
}

const BookACall: React.FC<Props> = ({ isMobile, isTab }) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<Form>({ mode: "onBlur" });
  const { t } = useTranslation();

  return (
     <div className={styles.cover}>
       <div className={styles.carouselSlider} style={{paddingTop:"5%"  }}>
    <div className={styles.container} style={{ backgroundImage: `url(${sliderBannerBg})`, backgroundPosition: 'top left', backgroundRepeat: 'no-repeat' }}>
      <div className={styles.containerItemOne} >
        <p>{t(HomeContent.Success.tag)}</p>
        <h4 style={{color:"#ffffff"}}>{t(HomeContent.Success.header)}</h4>
        <ul>
          {HomeContent.Success.list.map((item: string, ind: number) => (
            <li key={uid(ind)} style={{color:"#ffffff"}}>
              {circleCheckSolid({ size: 16, color: "#794DF5" })}
              {t(item)}
            </li>
          ))}
        </ul>
      </div>
      <div className={ isMobile ? styles.MobcontainerItemTwo : styles.containerItemTwo} >
        
        {isMobile ? (
          <img
            src={LetsTalk}
            style={{
              width: "100%",
            }}
          />
        ) : (
          BookACallBanner
        )}
        <form onSubmit={handleSubmit((values) => {})}>
          <p>{t(HomeContent.Success.form.title)}</p>
          <div>
            <input
              {...register("name", {
                required: "Please enter a valid name",
                pattern: /^[a-zA-Z ]*$/,
              })}
              placeholder={t("Your name")}
            />
            <input
              {...register("email", {
                required: "Please enter a valid email",
                pattern: emailRegex,
              })}
              placeholder={t("Contact number")}
            />
            <input
              {...register("number", {
                required: "Please enter a valid number",
                pattern: phoneNumberRegex,
              })}
              placeholder={t("Email")}
            />
          </div>
          <p className={styles.terms}>{t(HomeContent.Success.form.terms)}</p>
          <CustomButton type="submit">
            {t(HomeContent.Success.form.buttonText)}
          </CustomButton>
        </form>
      </div>
    </div>
     </div>
    </div>
  );
};

export default BookACall;