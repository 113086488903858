import React from "react";
import api from "../../api/baseConfig";
import Apihelper from "../../api/apiHelper";
import ReactHtmlParser from "html-react-parser";
import { Link } from "react-router-dom";
import { Table } from "reactstrap";
import { uid } from "react-uid";
export default class ShowDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      response: "",
      sectionDetails: "",
    };
  }
  componentDidMount() {
    try {
      Apihelper.axiosCall(
        `${api.baseURL}${api.userManagement.getInfo}${this.props.location.state.id}`,
        "get"
      ).then((res) => {
        this.setState({ sectionDetails: res });
      });
    } catch (e) {}
  }
  render() {
    return (
      <>
        <div className="page-content">
          <h2> Hi, Student</h2>
          <div className="card" style={{ position: "relative" }}>
            <div style={{ padding: 10, listStyleType: "none" }}>
              {/* <li>
                  <strong>Title: </strong>
                  {ReactHtmlParser(
                    this.state.response && this.state.response.name
                      ? this.state.response.name
                      : ""
                  )}
                </li> */}
              &nbsp;
              <li>
                <strong>Description: </strong>
                {ReactHtmlParser(
                  this.state.sectionDetails &&
                    this.state.sectionDetails.description
                    ? this.state.sectionDetails.description
                    : ""
                )}
              </li>
              &nbsp;
              {this.state.sectionDetails &&
              Array.isArray(this.state.sectionDetails.instruction) &&
              this.state.sectionDetails.instruction.length > 0 ? (
                this.state.sectionDetails.instruction.map((ele, i) => (
                  <li key={uid(ele)}>{ReactHtmlParser(ele)}</li>
                ))
              ) : this.state.sectionDetails &&
                this.state.sectionDetails.instruction ? (
                <li>
                  <strong>Instructions: </strong>
                  {ReactHtmlParser(this.state.sectionDetails.instruction || "")}
                </li>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="card" style={{ position: "relative" }}>
            <div style={{ padding: 10, listStyleType: "none" }}>
              {/* <li>
                  <strong>Title: </strong>
                  {ReactHtmlParser(
                    this.state.response && this.state.response.name
                      ? this.state.response.name
                      : ""
                  )}
                </li> */}
              &nbsp;
              <li>
                <h4>SECTION DETAILS : </h4>
              </li>
              &nbsp;
              {this.state.sectionDetails !== "" ? (
                <Table>
                  <thead>
                    <tr>
                      <th>S.No</th>
                      <th>Section Name</th>
                      <th>Section Description</th>
                      <th>No. of Questions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.sectionDetails.sectionList.length !== 0
                      ? this.state.sectionDetails.sectionList.map((ele, i) => (
                          <tr key={uid(ele)}>
                            <th scope="row">{i + 1}</th>
                            <td>{ele.name}</td>
                            <td>{ele.description}</td>
                            <td>{ele.questionIdList.length}</td>
                          </tr>
                        ))
                      : ""}
                  </tbody>
                </Table>
              ) : (
                ""
              )}
            </div>
          </div>
          <Link
            to="/assessment"
            className="btn text-muted d-none d-sm-inline-block btn-link"
          >
            <i className="mdi mdi-arrow-left mr-1" /> Back to Assessments{" "}
          </Link>
        </div>
      </>
    );
  }
}
