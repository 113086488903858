import { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import GridItem from "../../../components/GridItem/GridItem";
import DataTableContainer from "../../../components/DataTableContainer/DataTableContainer";
import Tabs from "../../../components/Tabs/Tabs";
import { useHistory } from "react-router-dom";
import styles from "./QLinksAndRActivity.module.scss";
import { Grid } from "@material-ui/core";

export const QLinksAndRActivity = withRouter(
  ({ showReal, isMobile, userPrivileges, ...props }) => {
    const [tabOption, setTabOption] = useState("quickLinks");
    const history = useHistory();

    const dotSVG = (
      <svg
        width="6"
        height="6"
        viewBox="0 0 6 6"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="3" cy="3" r="3" fill="#089A79" />
      </svg>
    );

    return (
      <GridItem
        gridPosition={[
          isMobile ? [1, 1] : [1, 7],
          showReal ? (isMobile ? [6, 5] : [3, 4]) : isMobile ? [5, 6] : [3, 4],
        ]}
      >
        <DataTableContainer
          alignTabCenter
          actionBox={
            <Tabs
              color="black"
              activeColor="violet"
              activeTabId={tabOption}
              tabs={[
                {
                  id: "quickLinks",
                  text: "Quick links",
                  onClick() {
                    setTabOption("quickLinks");
                  },
                },
                {
                  id: "recentActivity",
                  text: "Recent activity",
                  onClick() {
                    setTabOption("recentActivity");
                  },
                },
              ]}
            />
          }
          //   title="Assessment Performance"
          //   description={
          //     <span style={{ color: "#666" }}>Categorized by overall mark</span>
          //   }
          //   footerLinkText="View more"
          //   footerLinkOnClick={() => {
          //     props.history.push("/assessments");
          //   }}
        >
          {tabOption === "quickLinks" ? (
            <div className={styles.linkContainer}>
              <Grid item xs={12} sm={12} md={10}>
              {(userPrivileges.showStudentsReport ||
                userPrivileges.showStudentReport) && (
                <div style={{ display: "flex" }}>
                  <p style={{ width: "22rem" }}>
                    {dotSVG}
                    <span className={styles.linkHeader}>Students Report</span>
                  </p>
                  <p
                    className={styles.linkButton}
                    onClick={() => {
                      history.push({
                        pathname: "/students-report",
                      });
                    }}
                  >
                    View
                  </p>
                </div>
              )}
              {userPrivileges.showActiveUserList && (
                <div style={{ display: "flex" }}>
                  <p style={{ width: "22rem" }}>
                    {dotSVG}
                    <span className={styles.linkHeader}>Active Users List</span>
                  </p>
                  <p
                    className={styles.linkButton}
                    onClick={() => {
                      history.push({
                        pathname: "/activeUsersList",
                      });
                    }}
                  >
                    View
                  </p>
                </div>
              )}
              {userPrivileges.showAssessments && (
                <div style={{ display: "flex" }}>
                  <p style={{ width: "22rem" }}>
                    {dotSVG}
                    <span className={styles.linkHeader}>Assessments</span>
                  </p>
                  <p
                    className={styles.linkButton}
                    onClick={() => {
                      history.push({
                        pathname: "/assessments",
                      });
                    }}
                  >
                    View
                  </p>
                </div>
              )}
              {userPrivileges.showPrepare && (
                <div style={{ display: "flex" }}>
                  <p style={{ width: "22rem" }}>
                    {dotSVG}
                    <span className={styles.linkHeader}>
                      Prepare Management
                    </span>
                  </p>
                  <p
                    className={styles.linkButton}
                    onClick={() => {
                      history.push({
                        pathname: "/view-prepare",
                      });
                    }}
                  >
                    View
                  </p>
                </div>
              )}
              {userPrivileges.showActiveUserList && (
                <div style={{ display: "flex" }}>
                  <p style={{ width: "22rem" }}>
                    {dotSVG}
                    <span className={styles.linkHeader}>Overall Report</span>
                  </p>
                  <p
                    className={styles.linkButton}
                    onClick={() => {
                      history.push({
                        pathname: "/overall-report",
                      });
                    }}
                  >
                    View
                  </p>
                </div>
              )}
              {(userPrivileges.showCustomerList ||
                userPrivileges.showUserList ||
                userPrivileges.showRoles) && (
                <div style={{ display: "flex" }}>
                  <p style={{ width: "22rem" }}>
                    {dotSVG}
                    <span className={styles.linkHeader}>Custom Batch</span>
                  </p>
                  <p
                    className={styles.linkButton}
                    onClick={() => {
                      history.push({
                        pathname: "/customBatchList",
                      });
                    }}
                  >
                    View
                  </p>
                </div>
              )}
              </Grid>
            </div>
          ) : (
            <>
              <div className={styles.comingSoonContainer}>
                <p className={styles.comingSoonText}>Coming Soon</p>
              </div>
            </>
          )}
        </DataTableContainer>
      </GridItem>
    );
  }
);
