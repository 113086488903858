import {useState} from "react";
import { Controller, useForm } from "react-hook-form";
import { TervButtonFilled } from "components/TervButtons";
import Divider from '@material-ui/core/Divider';
import { CourseSettings } from "./Content";
import { uid } from "react-uid";
import styles from "./TabForm4.module.scss";
import { CustomSwitch } from "./CustomSwitch";
import { UpdateSettings } from "./Apis";
import { useEffect } from "react";
import equal from "fast-deep-equal";
import { makeStyles } from '@material-ui/core/styles';
import { Button } from "@material-ui/core";
import StyledNumberInput from "components/StyledNumberInput";
import { RequiredIndicator } from "pages/utils/RequiredIndicator";
import "./Prepare.scss";
import { Table } from "reactstrap";
import { FormLayout } from "components/FormLayout";
import Modal from "@material-ui/core/Modal";

const useStyles = makeStyles((theme) => ({
  divider: {
    background: '#E3F2FF',
  },
  middle: {
    marginLeft: '2px',
    marginRight: '2px'
  },
    paper: {
      position: "absolute",
      width: 500,
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
}));
const GroupBlock = ({ key, set: { label, fields }, control, enabledBidyut, register }) => {
  const [modalStyle] = useState(getModalStyle);
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState("");

  const handleOpen = () => {
    setOpen(true);
  };

  function rand() {
    return Math.round(Math.random() * 20) - 10;
  }

  function getModalStyle() {
    const top = 50 + rand();
    const left = 50 + rand();

    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
    };
  }

  const handleClose = () => {
    setOpen(false);
  };
  const [tasks, setTasks] = useState([]);
  const addTask = (e) => {
    setTasks([...tasks, e]);
    setOpen(false);
  };
  const addTaskLabelName = (e) => {
    setValue({ ...value, [e.target.name]: e.target.value });
  };

  const toggleTask = (index) => {
    const newTasks = [...tasks];
    newTasks[index].isCompleted = !newTasks[index].isCompleted;
    setTasks(newTasks);
  };

  const removeTask = (index) => {
    const newTasks = [...tasks];
    newTasks.splice(index, 1);
    setTasks(newTasks);
  };
  const classes = useStyles();
  const getFaqDetails = (e) => {
    e.preventDefault();
    value && addTask(value);
    setValue("");
  };
  return(
  <div key={key} className={styles.groupBlock}>
    <p className={styles.groupBlockHeader}>{label}</p>
    {fields.map((field, i) => (
      <>
      <div className={styles.groupFlex} key={uid(i)}>
        <div className={styles.groupFlexOne}>
          <p className={styles.groupFieldHeader}>{field.fieldHeader}</p>
          <p className={styles.groupFieldDesc}>{field.fieldDesc}</p>
        </div>
        <div className={styles.switchContainer}>
          No{" "}
          <Controller
            name={field.name}
            control={control}
            render={({ field: { onChange, value } }) => (
              <CustomSwitch
                checked={!!value}
                onChange={(e, c) => onChange(c)}
              />
            )}
          />
          {" "}
          Yes{" "}
        </div>
      </div>
        {field.fieldHeader == 'Bidyut' && enabledBidyut &&
        <>
          <div style={{display:'flex'}}>
            <div style={{paddingTop:'10px'}}>
              <label className="terv-label">
                {RequiredIndicator("Bonus score")}
              </label>
              <Controller
                name="duration"
                control={control}
                disableNegative
                render={({ field: { onChange, value, onBlur } }) => (
                  <StyledNumberInput
                    onChange={onChange}
                    onBlur={onBlur}
                    value={+value || 0}
                    className="terv-form-with-icon"
                  />
                )}
              />
              {/* {errors.duration && (
                <ErrorBlock
                  eType="div"
                  errorText={errors.duration.message || ""}
                  maxWidth="12.5rem"
                />
              )} */}
            </div>
            <div style={{paddingTop:"10px", paddingLeft:'35px', paddingBottom:'10px'}}>
              <label className="terv-label">
                {RequiredIndicator("Order")}
              </label>
              <input
                {...register("name", { setValueAs: (v) => v.trim() })}
                className="terv-form terv-form-md"
              />
              {/* {errors.duration && (
                <ErrorBlock
                  eType="div"
                  errorText={errors.duration.message || ""}
                  maxWidth="12.5rem"
                />
              )} */}
            </div>
          </div>
          <div>
          <div className="terv-row">
            <div>
              <Table striped bordered>
                <thead style={{ backgroundColor: "#DAF2FD" }}>
                  <tr>
                    {/* {props?.labelName > 0 &&
              props?.labelName?.map((header, ind) => ( */}

                    {/* ))} */}
                    <th style={{ width: 500 }}>Subject</th>
                    <th style={{ width: 500 }}>Criteria id</th>
                    <th style={{ width: 100 }}>Action</th>
                  </tr>
                </thead>
                <tbody className="typeTableBody">
                  {tasks.map((task, index) => (
                    <tr
                      style={{ backgroundColor: "#F9FDFF" }}
                      className="TableRow"
                      key={index}
                    >
                      <td>
                        <p>{task?.question}</p>
                      </td>
                      <td>
                        <p>{task?.answer}</p>
                      </td>
                      <td style={{ width: 100 }}>
                        <div style={{ display: "flex" }}>
                          &nbsp; &nbsp; &nbsp;
                          <span
                            onClick={() => removeTask(index)}
                            style={{ color: "red", cursor: "pointer" }}
                          >
                            {CustomIcons.circleCrossIcon({
                              size: 19,
                              color: "#EB5757",
                            })}
                          </span>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
        </>
        }
      </>
    ))}
    <Divider classes={{root: classes.divider}} variant="middle" />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <form
          style={modalStyle}
          className={classes.paper}
          onSubmit={getFaqDetails}
        >
          <h1>Add Subject</h1>
          <div>
            <p id="simple-modal-description">Subject</p>
            <textarea
              name="question"
              type="text"
              value={value.question}
              onChange={(e) => addTaskLabelName(e)}
              style={{ width: "20rem", height: "108px" }}
            />
          </div>
          <div className="pt-2">
            <p id="simple-modal-description">Creteria id</p>
            <textarea
              name="answer"
              type="text"
              value={value.answer}
              onChange={(e) => addTaskLabelName(e)}
              style={{ width: "20rem", height: "108px" }}
            />
          </div>
          <Button
            type="submit"
            style={{ textTransform: "capitalize", backgroundColor: "#51B960" }}
          >
            <svg
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.75 5.25V0H5.25V5.25H0V6.75H5.25V12H6.75V6.75H12V5.25H6.75Z"
                fill="white"
              />
            </svg>
            <span
              className="pl-2"
              style={{ color: "#fff", fontWeight: "bold" }}
            >
              Add FAQs
            </span>
          </Button>
        </form>
      </Modal>
  </div>
);
}

const TabForm4 = ({
  goNext,
  courseId,
  courseData,
  goPrevious,
  formValue4,
  setFormValue4,
}) => {
  const {
    control,
    formState: { isSubmitting },
    handleSubmit,
    watch,
    reset,
    register,
  } = useForm();

  useEffect(() => {
    if (!equal(courseData, {}) || !equal(formValue4, {})) {
      let tempData = {};
      CourseSettings.forEach(({ fields }) => {
        fields.forEach(({ name }) => {
          tempData[name] = !!courseData[name] || !!formValue4[name];
        });
      });
      reset(tempData);
    }
  }, [reset, courseData]);

  const onSubmit = async (formValues) => {
    try {
      await UpdateSettings({ ...formValues, id: courseId });
      setFormValue4(formValues);
      goNext();
    } catch (error) {}
  };

  const handlePrevious = () => {
    goPrevious();
  };

  const subscription = watch()
  return (
    <form onSubmit={handleSubmit(onSubmit)} className="manage-prepare__form">
      <div className={styles.container}>
        <div>
          {CourseSettings.map((set, ind) => (
            <GroupBlock key={uid(ind)} set={set} control={control} register={register} enabledBidyut={subscription?.enableBidyut}/>
          ))}
        </div>
      </div>
      <div style={{paddingTop:'10px'}}>
        <div style={{ display: "flex", justifyContent: "center", gap: "5px"}}>
          <TervButtonFilled
            type="button"
            text="Previous"
            onClick={handlePrevious}
            width="6.125rem"
          />
          <TervButtonFilled
            text="Continue"
            type="submit"
            width="6.125rem"
            disabled={isSubmitting}
          />
        </div>
      </div>
    </form>
  );
};

export default TabForm4;
