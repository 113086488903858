import React, { useState, useEffect } from "react";
import { withStyles } from '@material-ui/core/styles';
import useStyles from  "./UserProfileStyles.js";
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import InputBase from '@material-ui/core/InputBase';
import Collapse from '@material-ui/core/Collapse';
import Slide from '@material-ui/core/Slide';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { DateOutlineIcon, DownOutlineIcon, CloseOutlineIcon } from "assets/icons/icons";
import moment from 'moment';

const StyledInput = withStyles((theme) => ({
  input: {
    borderRadius: 4,
    padding: '9px 26px 9px 12px',
    minHeight: 20,
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    lineHeight: 1.53,
    '&:focus': {
      borderColor: '#DFE1E6',
    },
  },
}))(InputBase);

const DateInputBox = React.forwardRef(({ value, onClick }, ref)  => (
  <div className="inputBox flex-box flex-100 flex-middle" onClick={onClick} ref={ref}>
    <div className="flex-1">
      <input
        type="text"
        value={value}
      />
    </div>
    <div className="flex-auto" style={{cursor:"pointer", marginRight:8}}>
      <DateOutlineIcon color="#41526E" />
    </div>
  </div>
));

export default function EditUserInput(props) {
  const errorShow = props.errorShow;
  const validation = props.validation;
  const [error, setError] = useState("");
  const [valInit, setValInit] = useState(false);
  const [val, setVal] = useState(props.type === "date" ? null : "");
  const [valMuti, setValMuti] = useState([]);
  const [valSearch, setValSearch] = useState("");
  const [valMenuList, setValMenuList] = useState([]);
  const space = /\s/g;
  const dSpace = /\s\s+/g;
  const textonly = /[^a-zA-Z\s]*$/;
  const number = /[^0-9\s]*$/;
  const numberonly = /[^0-9]*$/;
  const textandnumber = /[^0-9a-zA-Z\s]*$/;
  const email = /\S+@\S+\.\S+/;
  const css = useStyles();
  const [menuWidth, setMenuWidth] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorSelectChip, setAnchorSelectChip] = React.useState(false);
  const open = Boolean(anchorEl);
  const weekList = { 1:"Monday", 2:"Tuesday", 3:"Wednesday", 4:"Thursday", 5:"Friday", 6:"Saturday", 7:"Sunday"}

  const stopImmediatePropagation = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };
  const removeChip = (index) => {
    const tempValMuti = [...valMuti];
    tempValMuti.splice(index,1);
    setValMuti(tempValMuti);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleOpen = (event) => {
    if(!Boolean(props.disabled)){
      if(open)
        setAnchorEl(null);
      else
        setAnchorEl(event.currentTarget && event.currentTarget.nextElementSibling ? event.currentTarget.nextElementSibling : event.currentTarget);
    }
  };
  const selectMenu = (v) => {
    if(validation.type === "select-chip"){
      const tempValMuti = [...valMuti];
      const index = tempValMuti.indexOf(v);
      if(index > -1)
        tempValMuti.splice(index,1);
      else 
        tempValMuti.push(v);
      setValMuti(tempValMuti);
    } else if(validation.type === "select-single") {
      handleClose();
      setVal(v);
    } else
      setVal(v);
  };

  useEffect(()=>{
    let error = false;
    if(valInit) {
      if(validation.type === "select-chip") {
        if(!anchorSelectChip){
          if(props.value) {
            props.setValue(valMuti);
          } else {
            props.setValue([]);
          }
        } else setAnchorSelectChip(false);
      } else if(props.type === "date") {
        if(props.value !== moment(val).toDate().toISOString()) {
          props.setValue(moment(val).toDate().toISOString());
        }
      } else if(props.value !== val) props.setValue(val);
    }
    if (validation && validation.req) {
      if (val === "" || val === null || val === undefined) {
        setError(`${validation.name} required.`);
        error = true;
      } else if (validation.min > -1 && val.trim().length < validation.min) {
        if(validation.type === "numberonly")
          setError(`${validation.name} must be at least ${validation.min} digit.`);
        else
          setError(`Minimum length ${validation.min}.`);
        error = true;
      } else if (validation.max > -1 && val.trim().length > validation.max) {
        if(validation.type === "numberonly")
          setError(`Invalid ${validation.min}`);
        else
          setError(`Maximum length ${validation.max}.`);
        error = true;
      } else if (validation.type === "email" && !email.test(val)) {
        setError(`Enter valid ${`${validation.name}`.toLowerCase()}.`);
        error = true;
      } else {
        setError("");
      }
    }
    props.updateError(validation.key, error ? "add": "remove");
  },[val, valMuti]);

  useEffect(()=>{
    if(validation.type === "select-chip") {
      setAnchorSelectChip(true);
      if(props.value) {
        setValMuti(Array.isArray(props.value) ? props.value : []);
      } else {
        setValMuti([]);
      }
    } else if(validation.type === "select-single") {
      if(props.value && props.value !== val) {
        setVal(props.value);
      }
    } else if(props.type === "date") {
      if(moment(props.value).isValid() && val === null) {
        setVal(moment(props.value).toDate());
      }
    } else if(props.value !== val) setVal(props.value);
    setValInit(true);
  },[props.value]);

  useEffect(()=>{
    if(open)
      setValSearch("");
  },[open]);

  useEffect(()=>{
    if(anchorEl !== null)
      setMenuWidth(anchorEl.offsetWidth);
  },[anchorEl]);

  useEffect(()=>{
    if(validation.data && validation.data.length > 0 )
      if(valSearch === "")
        setValMenuList([...validation.data]);
      else
        setValMenuList(validation.data.filter((d)=>d.toLowerCase().search(valSearch.toLowerCase())>-1));
    else
      setValMenuList([]);
  },[valSearch, validation.data]);

  return props.type === "text" ? (
      <div className="flex-1">
        {validation && validation.placeholder === "outer" && (<h6>{validation.name} {validation.subName && (<span style={{fontWeight: "normal", fontSize: "90%"}}>{validation.subName}</span>)} {validation && validation.req && (<b className="red">*</b>)}</h6>)}
        <input
          type="text"
          value={val || ""}
          placeholder={validation && validation.placeholder === "inner" ? validation.name : ""}
          className={error && errorShow && error !== "" ? "error":""}
          onChange={(e)=>{
            let v = e.target.value.replace(dSpace, ' ');
            if(validation){
              if(validation.length){
                v = v.substring(0, validation.length);
              }
              if(validation.type === "textonly"){
                v = v.replace(textonly, '');
              } else if(validation.type === "textandnumber"){
                v = v.replace(textandnumber, '')
              } else if(validation.type === "email"){
                v = v.replace(space, '')
              } else if(validation.type === "numberonly"){
                v = v.replace(numberonly, '')
              }
            }
            setVal(v);
          }}
        />
        <div className="flex-box">
          <div className="flex-1">{error && errorShow && error !== "" && <p className="errorTxt">{error}</p>}</div>
          <div className="flex-auto">{validation.status && <p className={validation.min && validation.min > val.trim().length ? "errorTxtAlt" : validation.max && validation.max < val.trim().length ? "errorTxtAlt" : "noErrorTxt"}>{`${val.trim().length}/${validation.max}`}</p>}</div>
        </div>
      </div>
    ) : props.type === "number" ? (
      <div className="flex-1">
        {validation && validation.placeholder === "outer" && validation.name && (<h6>{validation.name} {validation.subName && (<span style={{fontWeight: "normal", fontSize: "90%"}}>{validation.subName}</span>)} {validation && validation.req && (<b className="red">*</b>)}</h6>)}
        <input
          type="number"
          value={val}
          placeholder={validation && validation.placeholder === "inner" ? validation.name : ""}
          className={error && errorShow && error !== "" ? "error":""}
          onChange={(e)=>{
            let v = e.target.value.replace(space, '');
            if(validation) {
              if(validation.maxValue && v > validation.maxValue){
                v = validation.maxValue;
              }
            }
            setVal(v);
          }}
          onBlur={(e)=>{
            let v = e.target.value;
            if(validation) {
              if(validation.minValue && v < validation.minValue){
                v = validation.minValue;
              } else if(validation.maxValue && v > validation.maxValue){
                v = validation.maxValue;
              }
              if(validation.type === "float"){
                if(v === "" || v === " ") v = parseFloat(validation.minValue ?  validation.minValue : 0).toFixed(2);
                else v = parseFloat(`${v}`).toFixed(2);
              }
              if(validation.type === "integer"){
                if(v === "" || v === " ") v = parseInt(validation.minValue ?  validation.minValue : 0);
                else v = parseInt(`${v}`);
              }
            }
            setVal(v);
          }}
        />
        <div className="flex-box">
          <div className="flex-1">{error && errorShow && error !== "" && <p className="errorTxt">{error}</p>}</div>
          <div className="flex-auto">{validation.status && <p className={validation.min && validation.min > val.trim().length ? "errorTxtAlt" : validation.max && validation.max < val.trim().length ? "errorTxtAlt" : "noErrorTxt"}>{`${val.trim().length}/${validation.max}`}</p>}</div>
        </div>
      </div>
    ) : props.type === "textArea" ? (
      <div className="flex-1">
        {validation && validation.placeholder === "outer" && (<h6>{validation.name} {validation && validation.req && (<b className="red">*</b>)}</h6>)}
        <textarea 
          rows="4"
          value={val || ""}
          placeholder={validation && validation.placeholder === "inner" ? validation.name : ""}
          className={error && errorShow && error !== "" ? "error":""}
          onChange={(e)=>{
            let v = e.target.value.replace(dSpace, ' ');
            if(validation){
              if(validation.length){
                v = v.substring(0, validation.length);
              }
              if(validation.type === "textonly"){
                v = v.replace(textonly, '');
              } else if(validation.type === "textandnumber"){
                v = v.replace(textandnumber, '')
              } else if(validation.type === "email"){
                v = v.replace(space, '')
              } else if(validation.type === "numberonly"){
                v = v.replace(numberonly, '')
              }
            }
            setVal(v);
          }}
        />
        <div className="flex-box">
          <div className="flex-1">{error && errorShow && error !== "" && <p className="errorTxt">{error}</p>}</div>
          <div className="flex-auto">{validation.status && <p className={validation.min && validation.min > val.trim().length ? "errorTxtAlt" : validation.max && validation.max < val.trim().length ? "errorTxtAlt" : "noErrorTxt"}>{`${val.trim().length}/${validation.max}`}</p>}</div>
        </div>
      </div>
    ) : props.type === "select" ? (
      <div className="flex-1">
        {validation.placeholder && validation.placeholder === "outer" && (<h6>{validation.name} {validation && validation.req && (<b className="red">*</b>)}</h6>)}
        <div 
          aria-controls={`fade-menu-${validation.name}`}
          aria-haspopup="true" 
          className={`inputBox pointer flex-box flex-100 flex-gap-1 flex-middle${props.disabled ? " disabled" : ""}${error && errorShow && error !== "" ? " error" : ""}`} onClick={handleOpen}>
          <div className="flex-1 ellipse-input with-split">
            {validation.type === "select-chip" ? (
              <div className="flex-box flex-gap-1 flex-wrap" style={{padding:"8px 8px",minHeight:40}}>
                {valMuti.length > 0 && valMuti.map((v,i)=>(
                  <div key={`chip-${validation.name}-${i}`} className={`chip flex-box flex-middle flex-gap-1by2`}>
                    <span>{v}</span>
                    <button 
                      onClickCapture={(event) => {
                        removeChip(i);
                        event.stopPropagation();
                      }}
                      onClick={()=>removeChip(i)}
                    >
                      <CloseOutlineIcon />
                    </button>
                  </div>
                ))}
              </div>
            ):(
              <div className="input">{val === "" ? validation.defaultvalue ? validation.defaultvalue : val : val }</div>
            )}
          </div>
          <div className="flex-auto" style={{cursor:"pointer", marginRight:8}}>
            <DownOutlineIcon color="#41526E" />
          </div>
        </div>
        <div className="flex-box flex-100" style={{minHeight:1}}></div>
        <Menu
          id={`fade-menu-${validation.name}`}
          elevation={0}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          transformOrigin={{ vertical: 'top', horizontal: 'center' }}
          classes={{ paper: css.dropdownMenu }} 
          PaperProps={{ style: { minWidth: menuWidth, maxHeight: 38 * 6 } }}
          MenuListProps={{ style: validation.search ? { padding : 0 } :{padding : 0} }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          getContentAnchorEl={null}
          transitionDuration={300}
        >
          <Collapse in={open}>
          <div id="profile-page" className="dropmenu-shadow">
            <div className="flex-box flex-100 flex-col dropmenu-container" style={{maxHeight:37.5 * 6,height:"100%",overflow:"hidden"}}>
              <div className="flex-auto">
                {validation.search && (
                    <div 
                      aria-controls={`fade-menu${validation.name}`}
                      aria-haspopup="true" 
                      className="inputBox menuSearch flex-box flex-100 flex-middle" onClick={handleOpen}>
                      <div className="flex-1">
                        <input
                          type="text"
                          value={valSearch || ""}
                          placeholder={`Search ${`${validation.name}`.toLowerCase()}...`}
                          onClickCapture={stopImmediatePropagation}
                          onKeyDown={(event) => {
                            event.stopPropagation();
                          }}
                          onChange={((e)=>{
                            e.preventDefault();
                            setValSearch(e.target.value)
                          })}
                          autoFocus
                        />
                      </div>
                    </div>
                )}
              </div>
              <div className="flex-1" style={{overflow:"auto"}}>
                <div className="MuiList-root MuiMenu-list MuiList-padding">
                  {valMenuList.map((d,i)=>(
                    <MenuItem key={`MenuItem${validation.key}${i}`} onClick={()=>selectMenu(d)} className={validation.type === "select-chip" ? valMuti.indexOf(d) > -1 ? "Mui-selected":"" : d===val ? "Mui-selected":""}>{d}</MenuItem>
                  ))}
                </div>
              </div>
            </div>
          </div>
          </Collapse>
        </Menu>
        <div className="flex-box">
          <div className="flex-1">{error && errorShow && error !== "" && <p className="errorTxt">{error}</p>}</div>
          <div className="flex-auto"></div>
        </div>
      </div>
    ) : props.type === "date" ? (
      <div className="flex-1">
        {validation.placeholder && validation.placeholder === "outer" && (<h6>{validation.name} {validation && validation.req && (<b className="red">*</b>)}</h6>)}
        <DatePicker
          renderCustomHeader={({
            date,
            changeYear,
            changeMonth,
            decreaseMonth,
            increaseMonth,
            prevMonthButtonDisabled,
            nextMonthButtonDisabled,
          }) => (
            <div className="flex-box flex-100 flex-middle react-datepicker__innerheader">
              <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled} className="flex-box flex-auto">
                <i class='bx bx-chevron-left'></i>
              </button>
              <div className="flex-1 flex-box flex-center flex-gap-1"><div className="date">{moment(date).format("DD MMM YYYY")}{", "}</div><div className="week">{weekList[moment(date).isoWeekday()]}</div></div>
              <button onClick={increaseMonth} disabled={nextMonthButtonDisabled} className="flex-box flex-auto">
                <i class='bx bx-chevron-right'></i>
              </button>
            </div>
          )}
          dateFormat="dd/MM/yyyy" selected={val} onChange={(date) => setVal(date)} customInput={<DateInputBox />} />
        <div className="flex-box">
          <div className="flex-1">{error && errorShow && error !== "" && <p className="errorTxt">{error}</p>}</div>
          <div className="flex-auto"></div>
        </div>
      </div>
    ) : (<></>);
};