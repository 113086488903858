import React from "react";
import { useTranslation } from "react-i18next";
import sliderBanner from "../../../assets/Landing/Desktop-2.png";

interface Props {
  isMobile: boolean;
}

const NewLearning: React.FC<Props> = ({ isMobile }) => {
  const svgProps = {
    width: isMobile ? "100%" : 1366,
    height: isMobile ? "100%" : 611,
  };
const { t } = useTranslation();

  return (
    <>
      {/* <ContentHeader
        description={t(HomeContent.Headers.Difference.Description)}
        title={t(HomeContent.Headers.Difference.Main)}
        sub={t(HomeContent.Headers.Difference.Sub)}
        titleRev
      /> */}
      <div style={{width:"100%", display:"flex", justifyContent:"center", paddingTop:"3%"}}>
      <img src={sliderBanner}  style={{ width: '100%', height: '100%', }}></img> 
      </div>
    </>
  );
};

export default NewLearning;
