import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
} from "@material-ui/core";
import styles from "./DeleteModule.module.scss";
import * as CustomIcons from "../assets/icons/icons";

const paraStyle = {
  fontSize: 15,
  fontWeight: 500,
  color: "#5B5A5F",
  marginTop: 12,
  textAlign: "center",
};

export function DeleteModulePre({
  Header,
  Close,
  Delete,
  isOpen,
  Name = "Item",
}) {
  return (
    <Dialog open={isOpen} onClose={Close}>
      <DialogContent classes={{ root: styles.dialogContent }}>
        <div className={styles.dialogMain}>
          <span>
            <svg
              width="40"
              height="40"
              viewBox="0 0 40 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="40" height="40" rx="5" fill="#F8F7F7" />
              <path
                d="M13 28C13 28.5304 13.2107 29.0391 13.5858 29.4142C13.9609 29.7893 14.4696 30 15 30H25C25.5304 30 26.0391 29.7893 26.4142 29.4142C26.7893 29.0391 27 28.5304 27 28V16H29V14H25V12C25 11.4696 24.7893 10.9609 24.4142 10.5858C24.0391 10.2107 23.5304 10 23 10H17C16.4696 10 15.9609 10.2107 15.5858 10.5858C15.2107 10.9609 15 11.4696 15 12V14H11V16H13V28ZM17 12H23V14H17V12ZM16 16H25V28H15V16H16Z"
                fill="#D42216"
              />
              <path d="M17 18H19V26H17V18ZM21 18H23V26H21V18Z" fill="#D42216" />
            </svg>
          </span>
          <div className={styles.dialogTitle}>Delete section?</div>
        </div>
        <p style={paraStyle}>
          <div>Are you sure want to delete section?</div>
          <div>You cannot recover once deleted</div>
        </p>
      </DialogContent>
      <DialogActions classes={{ root: styles.dialogActions }}>
        <Button
          variant="outlined"
          onClick={Close}
          disableElevation
          classes={{ root: styles.buttonNo }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            Delete();
            Close();
          }}
          disableElevation
          classes={{ root: styles.buttonYes }}
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export function DeleteModulePackage({
  Header,
  Close,
  Delete,
  isOpen,
  Name = "Item",
}) {
  return (
    <Dialog open={isOpen} onClose={Close}>
      <DialogContent classes={{ root: styles.dialogContent }}>
        <div className={styles.dialogMain}>
          <span>
            <svg
              width="40"
              height="40"
              viewBox="0 0 40 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="40" height="40" rx="5" fill="#F8F7F7" />
              <path
                d="M13 28C13 28.5304 13.2107 29.0391 13.5858 29.4142C13.9609 29.7893 14.4696 30 15 30H25C25.5304 30 26.0391 29.7893 26.4142 29.4142C26.7893 29.0391 27 28.5304 27 28V16H29V14H25V12C25 11.4696 24.7893 10.9609 24.4142 10.5858C24.0391 10.2107 23.5304 10 23 10H17C16.4696 10 15.9609 10.2107 15.5858 10.5858C15.2107 10.9609 15 11.4696 15 12V14H11V16H13V28ZM17 12H23V14H17V12ZM16 16H25V28H15V16H16Z"
                fill="#D42216"
              />
              <path d="M17 18H19V26H17V18ZM21 18H23V26H21V18Z" fill="#D42216" />
            </svg>
          </span>
          <div className={styles.dialogTitle}>Delete?</div>
        </div>
        <p style={paraStyle}>
          <div>Are you sure want to delete?</div>
          <div>You cannot recover once deleted</div>
        </p>
      </DialogContent>
      <DialogActions classes={{ root: styles.dialogActions }}>
        <Button
          variant="outlined"
          onClick={Close}
          disableElevation
          classes={{ root: styles.buttonNo }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            Delete();
            Close();
          }}
          disableElevation
          classes={{ root: styles.buttonYes }}
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export function ConformDelete({
  Header,
  Content,
  handleClose,
  handleDelete,
  isOpen,
}) {
  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogContent classes={{ root: styles.dialogContent }}>
        <div className={styles.dialogMain}>
          <span>
            <svg
              width="40"
              height="40"
              viewBox="0 0 40 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="40" height="40" rx="5" fill="#F8F7F7" />
              <path
                d="M13 28C13 28.5304 13.2107 29.0391 13.5858 29.4142C13.9609 29.7893 14.4696 30 15 30H25C25.5304 30 26.0391 29.7893 26.4142 29.4142C26.7893 29.0391 27 28.5304 27 28V16H29V14H25V12C25 11.4696 24.7893 10.9609 24.4142 10.5858C24.0391 10.2107 23.5304 10 23 10H17C16.4696 10 15.9609 10.2107 15.5858 10.5858C15.2107 10.9609 15 11.4696 15 12V14H11V16H13V28ZM17 12H23V14H17V12ZM16 16H25V28H15V16H16Z"
                fill="#D42216"
              />
              <path d="M17 18H19V26H17V18ZM21 18H23V26H21V18Z" fill="#D42216" />
            </svg>
          </span>
          <div className={styles.dialogTitle}>{Header}</div>
        </div>
        <div style={paraStyle}>
          <Content />
        </div>
      </DialogContent>
      <DialogActions classes={{ root: styles.dialogActions }}>
        <Button
          variant="outlined"
          onClick={handleClose}
          disableElevation
          classes={{ root: styles.buttonNo }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={handleDelete}
          disableElevation
          classes={{ root: styles.buttonYes }}
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
}
