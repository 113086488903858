import React from 'react';
import cn from 'classnames';
import { Icon } from '@iconify/react';
import penIcon from '@iconify/icons-fa-solid/pen';
import ProgressBar from '../ProgressBar/ProgressBar';
import styles from './AdminAssessmentListItem.module.scss';

function AdminAssessmentListItem({
  className,
  isTop,
  percentageCompleted,
  primaryInfo }) {
  return (
    <div className={cn(styles.listItem, className)}>
      <div className={styles.iconContainer}>
        <Icon icon={penIcon} style={{ color: '#fff', fontSize: 14 }} />
      </div>
      <div className={styles.primaryInfo}>
        {primaryInfo}
      </div>
      <div className={styles.progressContainer}>
        <ProgressBar red={!isTop} green={isTop} percentage={percentageCompleted} />
      </div>
      <div className={styles.percentageCompleted}>
        {percentageCompleted}%
      </div>
    </div>
  );
}

export default AdminAssessmentListItem;
