import React from "react";
import { sessionPrivileges } from "privileges";
import CloudLabAdmin from "./cloudLabAdmin";
import CloudLabStudent from "./cloudLabStudent";
import { connect, useSelector } from "react-redux";
export default function CloudLab() {
  const isLoggedIn = useSelector((state) => state.LoggedInReducer);
  const userPrivileges = sessionPrivileges();
  return (
    <>
    {userPrivileges.isSuperAdmin && (
    <div>
      {userPrivileges?.isSuperAdmin || userPrivileges?.isAdmin ? (
        <CloudLabAdmin />
      ) : (
        ""
      )}
      {!userPrivileges?.isSuperAdmin && !userPrivileges?.isAdmin && (
        <CloudLabStudent />
      )}
    </div>
    )}
    </>
  );
}
