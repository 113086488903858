import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import { FormControlLabel, Checkbox, Collapse } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import { FormLayout } from "components/FormLayout";
import { ErrorBlock } from "components/ErrorBlock";
import StyledNumberInput from "components/StyledNumberInput";
import StyledNumberInputReadOnly from "components/StyledNumberInputReadonly";
import { useHistory } from "react-router";
import { RequiredIndicator } from "pages/utils/RequiredIndicator";
import { GetObjectFromString } from "pages/utils/GetObjectFromString";
import { ReactSelectTervStyle } from "pages/utils/ReactSelectTervStyle";
import { TervButtonFilled } from "components/TervButtons";
import { useGetCustomers } from "hooks/useGetCustomers";
import { GetObjectArray } from "pages/utils/GetObjectArray";
import AdvancedStudentSelectionAlt from "./AdvancedStudentSelectionAlt";
import { sessionPrivileges } from "privileges";
import { UpdatePricing, GetCertificateTemplateList } from "./Apis";
import { CalculateDiscount } from "pages/utils/CalculateDiscount";
import { yupResolver } from "@hookform/resolvers/yup";
import { Form5Schema } from "./ValidationSchemas";
import { DefaultToast } from "components/DefaultToast";
import { COURSETAG, LEVELS } from "./Content";

const CustomCheckbox = withStyles({
  root: {
    color: "#794df5",
    "&$checked": {
      color: "#794df5",
    },
  },
})(Checkbox);

const CustomFormLabel = withStyles({
  label: {
    fontFamily: "Poppins",
    fontWeight: 500,
    fontSize: "0.9375rem",
    lineHeight: "1.375rem",
    color: "#323036",
  },
})(FormControlLabel);

const options = [
  { label: "Feature", value: "feature" },
  { label: "Premium", value: "premium" },
  { label: "Latest", value: "latest" },
];

const TabForm5 = ({
  courseId,
  courseData,
  goPrevious,
  formValue5,
  isEdit,
  props,
  customerId,
  customerName,
}) => {
  const methods = useForm({
    mode: "onBlur",
    resolver: yupResolver(Form5Schema),
  });
  const userPrivileges = sessionPrivileges();
  const allCustomers = useGetCustomers();
  const isSA = userPrivileges && userPrivileges.isSuperAdmin;

  const history = useHistory();

  const {
    control,
    formState: { isSubmitting, errors },
    handleSubmit,
    reset,
    watch,
    setValue,
    register,
  } = methods;

  const OP = watch("originalPrice");
  const SP = watch("strikingPrice");

  const [certificateTemplate, setCertificateTemplate] = useState([]);

  const getCertificateTemplate = async () => {
    try {
      const data = await GetCertificateTemplateList(customerId);
      if (data && data.length > 0) {
        setCertificateTemplate(data);
      }
    } catch (error) {}
  };

  useEffect(() => {
    getCertificateTemplate();
  }, []);

  useEffect(() => {
    if (courseData?.filterType || formValue5?.filterType) {
      const value = courseData?.filterType ? courseData : formValue5;
      reset({
        paidPackage: !!value?.paidPackage,
        originalPrice: value?.originalPrice ?? 0,
        strikingPrice: value?.strikingPrice ?? 0,
        discountAmount: value?.discountAmount ?? 0,
        couponsAccepted: value?.couponsAccepted ?? [],
        courseTags: value?.courseTags ?? "",
        ...(userPrivileges &&
          userPrivileges.isSuperAdmin && {
            customerId: value?.customerId ?? "",
          }),
        userIdList: value?.userIdList ?? [],
        filterType: value?.filterType ?? "",
        certificateTemplate: value?.certificateTemplate ?? "",
      });
    }
  }, [reset, courseData]);

  const handlePrevious = () => {
    goPrevious();
  };

  useEffect(() => {
    if (OP && SP !== undefined && SP !== null) {
      setValue("discountAmount", CalculateDiscount(OP, SP));
    }
  }, [OP, SP]);

  const onSubmit = async (formValues) => {
    try {
      await UpdatePricing({
        ...formValues,
        customerId:
          userPrivileges &&
          userPrivileges.isAdmin &&
          sessionStorage.getItem("customer_id"),
        id: courseId,
      });
      history.push({
        pathname: "/view-prepare",
        ...(userPrivileges &&
          userPrivileges.isSuperAdmin && {
            state:{ customer: customerId, customerName: customerName },
          }),
      });
      if(isEdit == true) {
      DefaultToast({
        message: "Successfully updated course!",
        style: {backgroundColor:"#1ba94c", color:"#ffffff"},
        position: "top-right",
      });
    }
    else {
      DefaultToast({
        message: "Successfully created Course!",
        style: {backgroundColor:"#1ba94c", color:"#ffffff"},
        position: "top-right",
      });
    }
    } catch (error) {
      error;
    }
  };

  return (
    <form className="manage-prepare__form" onSubmit={handleSubmit(onSubmit)}>
      <FormLayout
        title={isSA ? "Course Pricing" : "Course Tags"}
        description={
          isSA
            ? "If the course is paid enter the Course pricing"
            : "Please choose one of the course tags"
        }
      >
        {isSA && (
          <Controller
            name="paidPackage"
            control={control}
            render={({ field: { onChange, value } }) => (
              <CustomFormLabel
                control={
                  <CustomCheckbox
                    name="paidPackage"
                    color="default"
                    checked={!!value}
                    onChange={(_, c) => onChange(c)}
                  />
                }
                label="This course is paid"
              />
            )}
          />
        )}
        <Collapse in={watch("paidPackage")}>
          <div className="terv-row">
            <div>
              <label className="terv-label">
                {RequiredIndicator("Orginal Price ")}
              </label>
              <Controller
                name="originalPrice"
                control={control}
                render={({ field: { onChange, value, onBlur } }) => (
                  <StyledNumberInput
                    onChange={onChange}
                    value={value || 0}
                    onBlur={onBlur}
                    className="terv-form-with-icon-sm"
                  />
                )}
              />
              {errors.originalPrice && (
                <ErrorBlock
                  eType="div"
                  errorText={errors.originalPrice.message || ""}
                />
              )}
            </div>
          </div>
          <div className="terv-row">
            <div>
              <label className="terv-label">Striking Price</label>
              <Controller
                name="strikingPrice"
                control={control}
                render={({ field: { onChange, value, onBlur } }) => (
                  <StyledNumberInput
                    onChange={onChange}
                    value={value || 0}
                    onBlur={onBlur}
                    className="terv-form-with-icon-sm"
                  />
                )}
              />
              {errors.strikingPrice && (
                <ErrorBlock
                  eType="div"
                  errorText={errors.strikingPrice.message || ""}
                />
              )}
            </div>
            <div>
              <label className="terv-label">Discount Percentage</label>
              <Controller
                name="discountAmount"
                control={control}
                render={({ field: { onChange, value, onBlur } }) => (
                  <StyledNumberInputReadOnly
                    onChange={onChange}
                    value={SP === 0 ? 0 : Math.ceil((value *= 1) || 0)}
                    onBlur={onBlur}
                    className="terv-form-with-icon-sm"
                  />
                )}
              />
            </div>
          </div>
          {/* <div className="terv-row">
            <div>
              <label className="terv-label">Coupons accepted</label>
              <Controller
                name="couponsAccepted"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Select
                    onChange={(val) =>
                      onChange(val ? val.map((it) => it.value) : [])
                    }
                    value={GetObjectArray([], value)}
                    isMulti
                    styles={ReactSelectTervStyle("md", true)}
                  />
                )}
              />
            </div>
          </div> */}
        </Collapse>
        <div className="terv-row">
          <div>
            <label className="terv-label">Course Tags</label>
            <Controller
              name="courseTags"
              control={control}
              render={({ field: { onChange, value, onBlur } }) => {
                return (
                  <Select
                    value={GetObjectFromString(COURSETAG, value)}
                    onChange={(val) => onChange(val.value)}
                    options={COURSETAG}
                    styles={ReactSelectTervStyle("md")}
                    onBlur={onBlur}
                  />
                );
              }}
            />
          </div>
        </div>
      </FormLayout>
      <FormLayout
        title="Course Certificate"
        description="Please choose one of the certificate formats"
      >
        <div className="terv-row">
          <div>
            <label className="terv-label">Course Certificate</label>
            <Controller
              name="certificateTemplate"
              control={control}
              render={({ field: { onChange, value, onBlur } }) => {
                return (
                  <Select
                    value={GetObjectFromString(certificateTemplate, value)}
                    onChange={(val) => {
                      onChange(val.value);
                    }}
                    options={certificateTemplate}
                    styles={ReactSelectTervStyle("md")}
                    onBlur={onBlur}
                  />
                );
              }}
            />
          </div>
        </div>
      </FormLayout>
      {/* {sessionPrivileges.isSuperAdmin && (
        <FormLayout
          title="Select customer"
          description="Choose customer for this course, course will be visible to these customers only"
        >
          <div className="terv-row">
            <div>
              <label className="terv-label">
                {RequiredIndicator("Customer ")}
              </label>
              <Controller
                name="customerId"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Select
                    onChange={(val) => onChange(val.value)}
                    value={GetObjectFromString(allCustomers, value)}
                    styles={ReactSelectTervStyle("md")}
                    options={allCustomers}
                  />
                )}
              />
              {errors.customerId && (
                <ErrorBlock
                  eType="div"
                  errorText={errors.customerId.message || ""}
                />
              )}
            </div>
          </div>
        </FormLayout>
      )}
      <FormLayout
        title="Assign students"
        description="Assign students for this course, only assigned student can take the course"
      >
        { sessionPrivileges.isSuperAdmin &&
        <AdvancedStudentSelectionAlt
          customerId={watch("customerId")}
          hookFormControls={methods}
          viewOnly={false}
          studentFieldName="userIdList"
        /> }
        {sessionPrivileges.isAdmin &&
        <AdvancedStudentSelectionAlt
          customerId={sessionStorage.getItem("customer_id")}
          hookFormControls={methods}
          viewOnly={false}
          studentFieldName="userIdList"
        /> 
    }
      </FormLayout> */}
      <FormLayout hideDivider>
        <div style={{ display: "flex", justifyContent: "center", gap: "5px" }}>
          <TervButtonFilled
            type="button"
            text="Previous"
            onClick={handlePrevious}
            width="6.125rem"
          />
          <TervButtonFilled
            text={isEdit ? "Update a Course " : "Create a Course"}
            type="submit"
            width="9.125rem"
            disabled={isSubmitting}
          />
        </div>
      </FormLayout>
    </form>
  );
};

export default TabForm5;
