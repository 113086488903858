import RichTextEditor from "../../components/RichTextEditor";
import { Label } from "reactstrap";

function EditorField({
  input: { onChange, value },
  label,
  meta: { touched, error },
}) {
  return (
    <div style={{ overflow: "auto" }}>
      <Label className="terv-label">{label}</Label>
      <RichTextEditor
        style={{ marginBottom: 16 }}
        value={value}
        onChange={onChange}
      />
      {touched && error && (
        <span className="terv-formHelperTextNew" style={{ color: "red" }}>
          {error}
        </span>
      )}
    </div>
  );
}

export default EditorField;
