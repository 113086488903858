import React from "react";
import { Button, Row, Form, CardBody, Card } from "reactstrap";
import { InputField } from "../../QuestionWizard/InputField";
import { Link } from "react-router-dom";
import api from "../../../api/baseConfig";
import { Validation } from "./utils/Validation";
import Apihelper from "../../../api/apiHelper";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import styles from "./CreateCompetency.module.scss";
import { sessionPrivileges } from "privileges";

export default class CreateMaster extends React.Component {
  constructor(props) {
    super(props);
    this.userPrivileges = sessionPrivileges(true);
    this.userEmail = this.userPrivileges.userName;
    this.state = {
      masterData: {
        name: "",
        description: "",
        id: "",
      },
      errors: {
        name: "",
        description: "",
      },
    };
  }

  componentDidMount() {
    if (this.props?.location?.state?.edit) {
      const { name, description, id } = this.props.location.state.row;
      this.setState({
        masterData: {
          name,
          description,
          id,
        },
      });
    }
  }

  setErrors = (data) => {
    let tempErr = { ...this.state.errors };
    for (const key in data) {
      if (data[key] !== "Exists") {
        tempErr[key] = `Please enter a valid ${key}`;
      }
    }
    this.setState({
      errors: {
        ...this.state.errors,
        ...tempErr,
      },
    });
  };

  doApiCall = async () => {
    const resp = Validation(["name", "description"], this.state.masterData);

    if (resp.test) {
      const { name, description, id } = this.state.masterData;

      let body = {
        isActive: true,
        createdBy: this.userEmail,
        description,
        name,
      };
      if (id) {
        body["id"] = id;
      }
      if (this.props.sub) {
        const { parentId } = this.props.match.params;
        body["parentId"] = parentId;
      }
      try {
        await Apihelper.axiosCallPost(
          `${api.baseURL}${api.masterController[this.props.postLink]}`,
          "post",
          body
        ).then(() => {
          this.props.history.goBack();
        });
      } catch (e) {}
    } else {
      this.setErrors(resp.validated);
    }
  };

  render() {
    return (
      <>
      {this.userPrivileges?.isSuperAdmin && (
      <div className="page-content">
        <Breadcrumbs
          title="Master"
          breadcrumbItem={
            this.props.location.state.type === "viewOnly"
              ? `View ${this.props.label.breadcrumb}`
              : this.state.masterData.id
              ? `Edit ${this.props.label.breadcrumb}`
              : `Create ${this.props.label.breadcrumb}`
        }
        />
        <Card>
          <CardBody className={styles.cardBody}>
            <Form>
              <Row className={styles.fieldRow}>
                <InputField
                  value={this.state.masterData.name}
                  changeFunc={(val) =>
                    this.setState({
                      masterData: {
                        ...this.state.masterData,
                        name: val,
                      },
                    })
                  }
                  showValid
                  label="Name"
                  placeholder={`Enter ${this.props.label.breadcrumb} name`}
                  validation={(err) =>
                    this.setState({
                      errors: { ...this.state.errors, name: err },
                    })
                  }
                  validationMessage="Please enter a valid Name"
                  error={this.state.errors.name}
                  disabled={this.props.location.state.type === "viewOnly"}
                />
              </Row>
              <Row className={styles.fieldRow}>
                <InputField
                  value={this.state.masterData.description}
                  changeFunc={(val) =>
                    this.setState({
                      masterData: {
                        ...this.state.masterData,
                        description: val,
                      },
                    })
                  }
                  showValid
                  type="textarea"
                  label="Description"
                  placeholder={`Enter ${this.props.label.breadcrumb} description`}
                  validation={(err) =>
                    this.setState({
                      errors: { ...this.state.errors, description: err },
                    })
                  }
                  validationMessage="Please enter a valid Description"
                  error={this.state.errors.description}
                  disabled={this.props.location.state.type === "viewOnly"}
                />
              </Row>
              {this.props.location.state.type !=="viewOnly" && (
              <div className={styles.saveButtonDiv}>
                  <Button
                  color="primary"
                  onClick={() => {
                    this.doApiCall();
                  }}
                  className={styles.saveButton}
                >
                  Save
                </Button>
              </div>
               )}
            </Form>
          </CardBody>
        </Card>
        <Link
          to={{
            pathname: this.props.backLink,
            state: {
              activePage: this.props.activePage,
              itemsPerPage: this.props.itemsPerPage,
            },
          }}
          className="terv-back"
        >
          <i className="mdi mdi-arrow-left mr-1" /> Back to{" "}
          {this.props.label.back}{" "}
        </Link>
      </div>
      )}
      </>
    );
  }
}
