/** @format */

import React from "react";
import styles from "./SafePayments.module.scss";
import { HomeContent } from "./HomeContent";
import { Visa, Paypal, Upi, Razorpay } from "./SVGs";
import { Shield } from "../../../assets/icons/icons";
import { Dot, Heart, IndianFlag } from "assets/icons/icons";
import RazorpayImg from "../../../assets/Landing/RazorPay.png";
import { useLogoContext } from "../../../context/logoContext";



interface Props {
  isMobile: boolean;
}

const SafePayments: React.FC<Props> = ({isMobile}) => {
  const logoImage= useLogoContext();
  return (
    <div className={styles.safePayment}>
      <div className={`${styles.flex} ${styles.fields} ${styles.xsNoFlex}`}>
        <div className={`${styles.xsFlex}`} style={{color:"#FFFFFF"}}>
          {HomeContent.SafePayments.description}
        </div>
        <div className={`${styles.spacing} ${styles.xsHide}`} />
        <div className={`${styles.fields} ${styles.start}`}>
          <div className={`${styles.btn}`}>
            <Shield size="24" color="#fff" />
            <div className={`${styles.spacing}`} />
            {HomeContent.SafePayments.safe}
          </div>
        </div>
      </div>
      <div className={`${styles.flex} ${styles.fields}`}>
        <div className={`${styles.fields} ${styles.spaceBetween}`}>
          <div className={`${styles.spacing}`} />
          {Visa}
          <div className={`${styles.spacing}`} />
          {Paypal}
          <div className={`${styles.spacing}`} />
          {Upi}
          <div className={`${styles.spacing}`} />
          <img src={RazorpayImg} loading="lazy"></img> 
          <div className={`${styles.spacing}`} />
        </div>
      </div>
      {isMobile &&
     (
      <div className={ styles.footerFlex}>
        {/* <img src="/terv-logo.png" alt="terv-logo" /> */}
        {logoImage && (
               <img
               src={
                 logoImage.productLogo
               }
             />
            )}
        <div className={styles.made}>
          <span>Made with</span>
          <Heart size="16" color="#f13030" />
          <span>in</span>
          <IndianFlag size={{ width: 24, height: 18 }} />
        </div>
      </div>
     )
}
    </div>
  );
};

export default SafePayments;
