/** @format */

import { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import GridItem from "../../../components/GridItem/GridItem";
import SkeletonGridItem from "../../../components/SkeletonGridItem/SkeletonGridItem";
import AdminAssessmentListItem from "../../../components/AdminAssessmentListItem/AdminAssessmentListItem";
import DataTableContainer2 from "../../../components/DataTableContainer/DataTableContainer2";
import Tabs from "../../../components/Tabs/Tabs";
import { useGetDashboardInfo } from "pages/Dashboard/SWRhooks";
import { AdminAssessmentPerf } from "pages/Dashboard/SampleData";
import { useTranslation } from "react-i18next";

export const AssessmentPerformance = withRouter(
  ({ showReal, isMobile, ...props }) => {
    const [dataIsLoading, setIfDataIsLoading] = useState(false);
    const [assessmentViewOption, setAssessmentViewOption] = useState("top");
    const [assessmentPerformanceTop, setAssessmentPerformanceTop] = useState(
      [],
    );
    const [assessmentPerformanceLeast, setAssessmentPerformanceLeast] =
      useState([]);
    const { t } = useTranslation();

    const apiResp = useGetDashboardInfo(
      "getAdminDashBoardAssessmentPerformance",
      showReal,
    );

    const setData = (data) => {
      setAssessmentPerformanceTop(data.assessmentPerformance.top);
      setAssessmentPerformanceLeast(data.assessmentPerformance.least);
    };

    useEffect(() => {
      if (apiResp === "Fetching...") {
        setIfDataIsLoading(true);
      }
      if (apiResp && apiResp.hasOwnProperty("assessmentPerformance")) {
        setData(apiResp);
        setIfDataIsLoading(false);
      }
    }, [apiResp]);

    useEffect(() => {
      setData(AdminAssessmentPerf);
    }, []);

    const hasData =
      assessmentPerformanceTop.length || assessmentPerformanceLeast.length;

    return dataIsLoading ? (
      <SkeletonGridItem
        gridPosition={[
          isMobile ? [1, -1] : [1, 7],
          showReal ? (isMobile ? [4, 5] : [6, 7]) : isMobile ? [5, 6] : [6, 7],
        ]}
      />
    ) : (
      <GridItem
        gridPosition={[
          isMobile ? [1, -1] : [1, 7],
          showReal ? (isMobile ? [4, 5] : [6, 7]) : isMobile ? [5, 6] : [6, 7],
        ]}
      >
        <DataTableContainer2
          actionBox={
            <Tabs
              color="black"
              activeColor="violet"
              activeTabId={assessmentViewOption}
              tabs={[
                {
                  id: "top",
                  text: "Top",
                  onClick() {
                    setAssessmentViewOption("top");
                  },
                },
                {
                  id: "least",
                  text: "Least",
                  onClick() {
                    setAssessmentViewOption("least");
                  },
                },
              ]}
            />
          }
          title="Assessment Performance"
          description={
            <span style={{ color: "#666" }}>Categorized by overall mark</span>
          }
          footerLinkText={t("View more")}
          footerLinkOnClick={() => {
            props.history.push("/assessments");
          }}
        >
          {hasData && (
            <>
              {assessmentViewOption === "top"
                ? assessmentPerformanceTop.map(({ name, percentage }, idx) => (
                    <AdminAssessmentListItem
                      key={idx}
                      isTop
                      percentageCompleted={percentage}
                      primaryInfo={name}
                    />
                  ))
                : assessmentPerformanceLeast.map(
                    ({ name, percentage }, idx) => (
                      <AdminAssessmentListItem
                        key={idx}
                        percentageCompleted={percentage}
                        primaryInfo={name}
                      />
                    ),
                  )}
            </>
          )}
        </DataTableContainer2>
      </GridItem>
    );
  },
);
