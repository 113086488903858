import React, { Component, useState } from "react";
import Mcqreport from "./mcqReport";
import Codereport from "./codeReport";
import DESCreport from "./descReport";
import { toast } from "react-toastify";
import { Button } from "reactstrap";

import api from "../../../api/baseConfig";
import Apihelper from "../../../api/apiHelper";
import { sessionPrivileges } from "privileges";

export default function SectionReport(props) {
  const userPrivileges = sessionPrivileges();
  const [gradingObject, setGradingObject] = useState({});

  const updateGrade = async (event) => {
    event.preventDefault();

    let body = {
      userId: props.ids.user,
      assessmentId: props.ids.assessment,
      adminMarks: gradingObject,
      gradingUserId: userPrivileges.userName,
    };

    await Apihelper.axiosCallPost(
      `${api.baseURL}${api.QuestionPaperController.saveAdminAssessmentMarks}`,
      "post",
      body
    )
      .then((d) => {
        toast.success(
          <>
            Successfully Graded <i className="fas fa-check"></i>
          </>,
          {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: false,
          }
        );
        props.redirect();
      })
      .catch((e) => {});
  };

  const setGradingFields = () => {
    let tempQuestions = {};
    props.data.section &&
      props.data.section.forEach((sectionEach) => {
        props.data[sectionEach] &&
          props.data[sectionEach].questions.forEach((question) => {
            tempQuestions[question.id] = question.solutionMap?.marksGained;
          });
      });

    setGradingObject(tempQuestions);
  };
  React.useEffect(() => {
    setGradingFields();
  }, [props.data]);

  return (
    <div>
      {props.data.section &&
        props.data.section.map((sectionEach, sectionIndex) => (
          <div key={sectionIndex}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                padding: 10,
                backgroundColor: "#c2c2c2",
              }}
            >
              <strong>{sectionEach}</strong>
            </div>
            {props.data[sectionEach] &&
              props.data[sectionEach].questions.map(
                (eachQuestion, questionIndex) =>
                  eachQuestion.type === "MC" ? (
                    <Mcqreport
                      data={eachQuestion}
                      num={questionIndex}
                      grading={
                        props.data.gradingProcess
                          ? props.data.gradingProcess
                          : ""
                      }
                      from={props.from}
                      updateGrade={(newObj) => {
                        let temp = { ...gradingObject };
                        temp[eachQuestion.id] = newObj;

                        setGradingObject(temp);
                      }}
                      grade={gradingObject[eachQuestion.id]}
                      disabled={props.data.manualGradingCompleted}
                    />
                  ) : eachQuestion.type === "CD" ? (
                    <Codereport
                      grading={
                        props.data.gradingProcess
                          ? props.data.gradingProcess
                          : ""
                      }
                      data={eachQuestion}
                      num={questionIndex}
                      from={props.from}
                      updateGrade={(newObj) => {
                        let temp = { ...gradingObject };
                        temp[eachQuestion.id] = newObj;

                        setGradingObject(temp);
                      }}
                      grade={gradingObject[eachQuestion.id]}
                      disabled={props.data.manualGradingCompleted}
                    />
                  ) : eachQuestion.type === "DESC" ? (
                    <DESCreport
                      data={eachQuestion}
                      num={questionIndex}
                      grading={
                        props.data.gradingProcess
                          ? props.data.gradingProcess
                          : ""
                      }
                      from={props.from}
                      updateGrade={(newObj) => {
                        let temp = { ...gradingObject };

                        temp[eachQuestion.id] = newObj;

                        setGradingObject(temp);
                      }}
                      grade={gradingObject[eachQuestion.id]}
                      disabled={props.data.manualGradingCompleted}
                    />
                  ) : (
                    ""
                  )
              )}
          </div>
        ))}
      {props.from === "assessment" &&
        props.data.gradingProcess &&
        props.data.gradingProcess === "manual" &&
        !props.data.manualGradingCompleted && (
          <Button onClick={(e) => updateGrade(e)} color="primary">
            Submit Grade
          </Button>
        )}
    </div>
  );
}
