import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { FormLayout } from "components/FormLayout";
import { ErrorBlock } from "components/ErrorBlock";
import StyledNumberInput from "components/StyledNumberInput";
import { RequiredIndicator } from "pages/utils/RequiredIndicator";
import { FormControlLabel, Checkbox } from "@material-ui/core";
import "../../Prepare/Prepare.scss";
import { TervButtonOutlined, TervButtonFilled } from "components/TervButtons";
import { savePrize } from "../Apis";
import { HandleDefaultMedia } from "../../utils/HandleDefaultMedia";
import { useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Form4Schema } from "./ValidationSchemas";
import { withStyles } from "@material-ui/styles";
import { DefaultToast } from "components/DefaultToast";
import { sessionPrivileges } from "privileges";
import { useHistory } from "react-router";

const CustomCheckbox = withStyles({
  root: {
    color: "#794df5",
    "&$checked": {
      color: "#794df5",
    },
  },
})(Checkbox);

const CustomFormLabel = withStyles({
  label: {
    fontFamily: "Poppins",
    fontWeight: 500,
    fontSize: "0.9375rem",
    lineHeight: "1.375rem",
    color: "#323036",
  },
})(FormControlLabel);

const TabForm4 = ({
  goNext,
  setCourseId,
  courseData,
  allCategories,
  setFormValue1,
  formValue1,
  courseId,
  isEdit,
  customerId,
  goPrevious,
}) => {
  const {
    register,
    control,
    formState: { isSubmitting, errors },
    handleSubmit,
    reset,
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(Form4Schema),
  });
  const userPrivileges = sessionPrivileges();
  const [typeValue, setTypeValue] = useState(false);
  const [teams, setTeams] = useState(false);
  const [cash, setCash] = useState(false);
  const [product, setProduct] = useState(false);
  const [points, setPoints] = useState(false);

  useEffect(() => {
    if (courseData.name || formValue1?.participantType) {
      const value = courseData.name ? courseData : formValue1;
      reset({
        participantType: value?.participantType ?? "",
        teamSize: value?.teamSize ?? "",
        firstPrize: value?.prizes?.firstPrize ?? "",
        secondPrize: value?.prizes?.secondPrize ?? "",
        thirdPrize: value?.prizes?.thirdPrize ?? "",
        provideCertificate: value?.provideCertificate ?? false,
        prizeType: value?.prizeType ?? "",
        id: value?.id,
      });
    }
  }, [reset, courseData]);

  const onSubmit = async (formValues) => {
    try {
      await savePrize({
        ...formValues,
        prizes: {
          firstPrize: formValues.firstPrize,
          secondPrize: formValues.secondPrize,
          thirdPrize: formValues.thirdPrize,
        },
        id: courseId,
      });
      DefaultToast({ message: "Successfully created Course!" });
      history.push({
        pathname: "/hackathon",
        ...(userPrivileges.isSuperAdmin && {
          state: { customer: customerId },
        }),
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handlePrevious = () => {
    goPrevious();
  };
  const history = useHistory();

  return (
    <form className="manage-prepare__form" onSubmit={handleSubmit(onSubmit)}>
      <FormLayout
        description="Choose Hackathon participants type based on teams or individuals"
        title="Participants type"
      >
        <div
          style={{
            backgroundColor: "#F2F1F3",
            padding: 18,
            marginBottom: 10,
            borderRadius: 5,
          }}
        >
          <div style={{ display: "flex" }}>
            <div>
              <input
                value="Teams"
                name="participantType"
                {...register("participantType")}
                onClick={() => {
                  setTypeValue(true);
                }}
                style={{ transform: "scale(1.5)" }}
                type="radio"
              />
            </div>
            <div>
              <label
                className="pl-3"
                style={{ fontSize: "16px", color: "#323036" }}
              >
                Teams
              </label>
              <p
                style={{
                  margin: 0,
                  color: "#63606C",
                  fontSize: 14,
                  marginLeft: 30,
                }}
              >
                User can combine as teams and can participate in Hackathon
              </p>
            </div>
          </div>
        </div>
        <div
          style={{
            backgroundColor: "#F2F1F3",
            padding: 18,
            borderRadius: 5,
          }}
        >
          <div style={{ display: "flex" }}>
            <div>
              <input
                value="Individuals"
                name="participantType"
                {...register("participantType")}
                onClick={() => {
                  setTypeValue(false);
                }}
                style={{ transform: "scale(1.5)" }}
                type="radio"
              />
            </div>
            <div>
              <label
                className="pl-3"
                style={{ fontSize: "16px", color: "#323036" }}
              >
                Individuals
              </label>
              <p
                style={{
                  margin: 0,
                  color: "#63606C",
                  fontSize: 14,
                  marginLeft: 30,
                }}
              >
                User can participate in Hackathon as individuals
              </p>
            </div>
          </div>
        </div>
        {errors.participantType && (
          <ErrorBlock
            eType="div"
            errorText={errors.participantType.message || ""}
          />
        )}
        {typeValue ||
          (courseData.participantType == "Teams" && (
            <div>
              <label className="terv-label">
                {RequiredIndicator("Enter maximum team size ")}
              </label>
              <Controller
                name="teamSize"
                control={control}
                render={({ field: { onChange, value, onBlur } }) => (
                  <StyledNumberInput
                    onChange={onChange}
                    value={value || 0}
                    onBlur={onBlur}
                    className="terv-form-with-icon-sm"
                  />
                )}
              />
              <p>Max amount is 5</p>
              {errors.teamSize && (
                <ErrorBlock
                  eType="div"
                  errorText={errors.teamSize.message || ""}
                />
              )}
            </div>
          ))}
      </FormLayout>
      <FormLayout
        title="Prizes and certification"
        description="Choose Hackathon prizes and certification"
      >
        <div style={{ display: "flex" }}>
          <div
            style={{
              display: "flex",
              paddingRight: "1rem",
              alignItems: "baseline",
            }}
          >
            <input
              value="amount"
              name="prizeType"
              {...register("prizeType")}
              onClick={() => {
                setCash(true), setProduct(false), setPoints(false);
              }}
              style={{ transform: "scale(1.5)" }}
              type="radio"
            />
            <p style={{ paddingLeft: "1rem" }} className="Prize">
              Amount in rupees
            </p>
          </div>
          <div
            style={{
              display: "flex",
              paddingLeft: "2rem",
              paddingRight: "1rem",
              alignItems: "baseline",
            }}
          >
            <input
              value="product"
              name="prizeType"
              {...register("prizeType")}
              onClick={() => {
                setCash(false), setProduct(true), setPoints(false);
              }}
              style={{ transform: "scale(1.5)" }}
              type="radio"
            />
            <p style={{ paddingLeft: "1rem" }} className="Prize">
              Product prize
            </p>
          </div>
          <div
            style={{
              display: "flex",
              paddingLeft: "2rem",
              paddingRight: "1rem",
              alignItems: "baseline",
            }}
          >
            <input
              value="points"
              name="prizeType"
              {...register("prizeType")}
              onClick={() => {
                setCash(false), setProduct(false), setPoints(true);
              }}
              style={{ transform: "scale(1.5)" }}
              type="radio"
            />
            <p style={{ paddingLeft: "1rem" }} className="Prize">
              Points
            </p>
          </div>
        </div>
        {(cash || courseData.prizeType == "amount") && (
          <p className="prizeExplain">
            Enter prize amounts for winners (in Rupees)
          </p>
        )}
        {(product || courseData.prizeType == "product") && (
          <p className="prizeExplain">Enter Product prize for winners</p>
        )}
        {(points || courseData.prizeType == "points") && (
          <p className="prizeExplain">Enter Points for winners</p>
        )}
        {errors.prizeType && (
          <ErrorBlock eType="div" errorText={errors.prizeType.message || ""} />
        )}
        {(points ||
          cash ||
          courseData.prizeType == "amount" ||
          courseData.prizeType == "cash") && (
          <div className="terv-row">
            <div>
              <label className="terv-label">
                {RequiredIndicator("1st prize ")}
              </label>
              <Controller
                name="firstPrize"
                control={control}
                render={({ field: { onChange, value, onBlur } }) => (
                  <StyledNumberInput
                    onChange={onChange}
                    value={value || 0}
                    onBlur={onBlur}
                    className="terv-form-with-icon-sm"
                  />
                )}
              />
              {errors.firstPrize && (
                <ErrorBlock
                  eType="div"
                  errorText={errors.firstPrize.message || ""}
                />
              )}
            </div>
            <div>
              <label className="terv-label">
                {RequiredIndicator("2nd prize ")}
              </label>
              <Controller
                name="secondPrize"
                control={control}
                render={({ field: { onChange, value, onBlur } }) => (
                  <StyledNumberInput
                    onChange={onChange}
                    value={value || 0}
                    onBlur={onBlur}
                    className="terv-form-with-icon-sm"
                  />
                )}
              />
              {errors.secondPrize && (
                <ErrorBlock
                  eType="div"
                  errorText={errors.secondPrize.message || ""}
                />
              )}
            </div>
            <div>
              <label className="terv-label">
                {RequiredIndicator("3rd Prize")}
              </label>
              <Controller
                name="thirdPrize"
                control={control}
                render={({ field: { onChange, value, onBlur } }) => (
                  <StyledNumberInput
                    onChange={onChange}
                    value={value || 0}
                    onBlur={onBlur}
                    className="terv-form-with-icon-sm"
                  />
                )}
              />
              {errors.thirdPrize && (
                <ErrorBlock
                  eType="div"
                  errorText={errors.thirdPrize.message || ""}
                />
              )}
            </div>
          </div>
        )}
        {(product || courseData.prizeType == "product") && (
          <div style={{ display: "flex" }}>
            <div>
              <label className="terv-label">
                {RequiredIndicator("1st prize ")}
              </label>
              <input
                {...register("firstPrize")}
                className="terv-form terv-form-md"
              />
              {errors.name && (
                <ErrorBlock
                  eType="div"
                  errorText={errors.name.message || ""}
                  maxWidth="12.5rem"
                />
              )}
            </div>
            <div className="pl-2">
              <label className="terv-label">
                {RequiredIndicator("2nd prize ")}
              </label>
              <input
                {...register("secondPrize")}
                className="terv-form terv-form-md"
              />
              {errors.name && (
                <ErrorBlock
                  eType="div"
                  errorText={errors.name.message || ""}
                  maxWidth="12.5rem"
                />
              )}
            </div>
            <div className="pl-2">
              <label className="terv-label">
                {RequiredIndicator("3rd Prize ")}
              </label>
              <input
                {...register("thirdPrize")}
                className="terv-form terv-form-md"
              />
              {errors.name && (
                <ErrorBlock
                  eType="div"
                  errorText={errors.name.message || ""}
                  maxWidth="12.5rem"
                />
              )}
            </div>
          </div>
        )}
        <Controller
          name="provideCertificate"
          control={control}
          render={({ field: { onChange, value } }) => (
            <CustomFormLabel
              control={
                <CustomCheckbox
                  name="provideCertificate"
                  color="default"
                  checked={!!value}
                  onChange={(_, c) => onChange(c)}
                />
              }
              label="Certificate will be given to all participants(only for submitted users)"
            />
          )}
        />
      </FormLayout>

      <FormLayout hideDivider>
        <div
          style={{ display: "flex", justifyContent: "flexStart", gap: "5px" }}
        >
          <TervButtonOutlined
            type="button"
            text="Previous"
            onClick={handlePrevious}
            width="6.125rem"
          />
          <TervButtonFilled
            type="submit"
            text={isEdit ? "update Hackathon" : "Create Hackathon"}
            width="8.825rem"
            disabled={isSubmitting}
          />
        </div>
      </FormLayout>
    </form>
  );
};

export default TabForm4;
