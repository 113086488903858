module.exports = [
  {
    name: "Science",
    no_of_test: "3",
    description: "some Cool description",
    teacher: "venuGopal",
    id: "0",
  },
  {
    name: "Computer",
    no_of_test: "3",
    description: "some Cool description",

    teacher: "Ash",
    id: "1",
  },

  {
    name: "Physics",
    no_of_test: "3",
    description: "some Cool description",

    teacher: "Aditya",
    id: "2",
  },

  {
    name: "Maths",
    description: "some Cool description",

    no_of_test: "3",
    teacher: "Goku",
    id: "3",
  },
]
