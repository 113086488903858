import { useState, useEffect, useCallback, useMemo } from "react";
import cn from "classnames";
import { withRouter } from "react-router-dom";
import { Button } from "reactstrap";
import { Icon } from "@iconify/react";
import chevronDown from "@iconify/icons-akar-icons/chevron-down";
import bxFilter from "@iconify/icons-bx/bx-filter";
import {
  Checkbox,
  Typography,
  Collapse,
  Grid,
  Divider,
  MenuItem,
  MenuList,
} from "@material-ui/core";
import { Pagination } from "../../components/pagination/pagination";
import { Tabs } from "../../components/tabs/tabs";
import { MobileTabs } from "../../components/tabs/tabMobilePractice";
import PracticeCard from "./lab-components/card";
import PracticeList from "./lab-components/list";
import PageHeader from "./lab-components/page-header";
import api from "../../api/baseConfig";
import { trackActivity } from "../../api";
import Apihelper from "../../api/apiHelper";
import axios from "axios";
import styles from "./lab.module.scss";
import "../Tasks/AllQuestionBanks.scss";
import SignInModal from "components/VerticalLayout/SignInModal";
import { ModalToggle } from "pages/utils/ModalToggle";
import PracticeHeroCard from "./PracticeHeroCard";
import { useMediaQuery } from "@material-ui/core";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

function CollapsibleFilterGroup({ name, children }) {
  const [isCollapsed, setCollapse] = useState(false);

  return (
    <>
      <MenuList
        onClick={() => {
          setCollapse((c) => !c);
        }}
      >
        <MenuItem>
          <Grid
            container
            alignItems="center"
            wrap="nowrap"
            justifyContent="space-between"
          >
            <Typography style={{ marginRight: "auto", fontWeight: 600 }} noWrap>
              {name}
            </Typography>
            <Icon
              icon={chevronDown}
              style={{
                transform: `${isCollapsed ? "rotate(-90deg)" : "rotate(0deg)"}`,
                transitionDuration: 200,
              }}
            />
          </Grid>
        </MenuItem>
      </MenuList>
      <Collapse in={!isCollapsed}>
        <div style={{ padding: "0 0 12px 0" }}>{children}</div>
      </Collapse>
      <Divider />
    </>
  );
}

function FilterGroupItem({ name, isChecked, setCheck, badge }) {
  return (
    <MenuItem
      onClick={() => {
        setCheck(!isChecked);
      }}
    >
      <Grid
        container
        alignItems="center"
        wrap="nowrap"
        justifyContent="space-between"
      >
        <Checkbox
          color="primary"
          style={{
            margin: "0 8px 0 0",
            padding: 0,
          }}
          // checked={isChecked}
        />
        <Typography
          style={{
            color: "#3A3A3A",
            fontSize: 14,
          }}
          noWrap
        >
          {name}
        </Typography>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <Typography
          style={{
            backgroundColor: "#F1F1F5",
            color: "#4F4F4F",
            fontSize: 14,
            marginLeft: "auto",
            padding: "0 4px",
          }}
        >
          {badge}
        </Typography>
      </Grid>
    </MenuItem>
  );
}

function Lab(props) {
  const { t } = useTranslation();
  const userId = sessionStorage.getItem("user_id");
  const [practicePackages, setPracticePackages] = useState([]);
  const [enrolledPracticePackages, setEnrolledPracticePackages] = useState([]);
  const [premiumPracticePackages, setPremiumPracticePackages] = useState([]);
  const [freePracticePackages, setFreePracticePackages] = useState([]);
  const [paidPracticePackages, setPaidPracticePackages] = useState([]);
  const [publicPracticePackages, setPublicPracticePackages] = useState([]);
  const [searchedPracticePackages, setSearchedPracticePackages] =
    useState(null);
  const [activeTab, setActiveTab] = useState("all");
  const [activePage, setActivePage] = useState(1);
  const [filterIsVisibile, setFilterVisibility] = useState(null);
  const [sessionCart, setSessionCart] = useState({});
  const [openSignIn, setOpenSignIn] = useState(false);
  const [signInPkg, setSignInPkg] = useState(null);
  const [signInType, setSignInType] = useState("package");
  const itemsPerPage = 6;

  const isLoggedIn = useSelector((state) => state.LoggedInReducer);

  async function addToCart(id) {
    return Apihelper.axiosCallPost(
      `${api.baseURL}${api.cart.add("package", id)}?userId=${userId}`,
      "post"
    ).catch(() => false);
  }
  const fetchData = async () => {
    try {
      let data;
      if (sessionStorage.getItem("viewPkgWithoutSignIn") == "true" && !userId) {
        data = await axios
          .get(`${api.baseURL}${api.lab.getAllLab}`)
          .then((data) => data?.data);
      } else {
        data = await Apihelper.axiosCall(
          `${api.baseURL}${api.lab.userPracticeLabList}${userId || ""}`,
          "get"
        );

        trackActivity(
          "-",
          "All Practice Packages",
          "viewed-all-practice-packages"
        );
      }

      if (data?.length > 0) {
        const pcs = data.map((d, index) => {
          if (!d.media) {
            d.imageIndex = index + 1 > 10 ? 10 % (index + 1) : index + 1;
          }

          return d;
        });

        setPracticePackages(
          pcs.sort(({ endDate: e1, tags: t1 }, { endDate: e2, tags: t2 }) => {
            const f1 = t1 === "featured";
            const f2 = t2 === "featured";

            return f1 === f2 ? 0 : f1 ? -1 : 1;
          })
        );
      }
    } catch (e) {}
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const enrolledPracticePackagesList = [];
    const premiumPracticePackagesList = [];
    const freePracticePackagesList = [];
    const paidPracticePackagesList = [];
    const publicPracticePackagesList = [];

    practicePackages.forEach((practicePackage) => {
      if (practicePackage?.paidPackage) {
        paidPracticePackagesList.push(practicePackage);
      }
      if (!practicePackage?.paidPackage) {
        freePracticePackagesList.push(practicePackage);
      }

      if (practicePackage?.tags === "premium") {
        premiumPracticePackagesList.push(practicePackage);
      }

      if (typeof practicePackage?.progress === "number") {
        enrolledPracticePackagesList.push(practicePackage);
      }

      if (practicePackage?.typePublic) {
        publicPracticePackagesList.push(practicePackage);
      }
    });

    setEnrolledPracticePackages(enrolledPracticePackagesList);
    setPremiumPracticePackages(premiumPracticePackagesList);
    setFreePracticePackages(freePracticePackagesList);
    setPaidPracticePackages(paidPracticePackagesList);
    setPublicPracticePackages(publicPracticePackagesList);
  }, [practicePackages]);

  const handlesearch = useCallback(
    (query) => {
      if (query) {
        setSearchedPracticePackages(
          practicePackages.filter(({ name }) =>
            name.toLowerCase().includes(query.toLowerCase())
          )
        );
      } else {
        setSearchedPracticePackages(null);
      }
    },
    [practicePackages]
  );

  const currentPracticeCards = useMemo(
    () =>
      searchedPracticePackages !== null
        ? searchedPracticePackages?.length
          ? searchedPracticePackages
          : []
        : activeTab === "premium"
        ? premiumPracticePackages
        : activeTab === "enrolled"
        ? enrolledPracticePackages
        : activeTab === "free"
        ? freePracticePackages
        : activeTab === "paid"
        ? paidPracticePackages
        : activeTab === "public"
        ? publicPracticePackages
        : practicePackages,
    [
      searchedPracticePackages,
      practicePackages,
      enrolledPracticePackages,
      premiumPracticePackages,
      freePracticePackages,
      paidPracticePackages,
      activeTab,
      publicPracticePackages,
    ]
  );
  const [grid, setGrid] = useState(true);
  const gridView = () => {
    setGrid(true);
  };
  const listView = () => {
    setGrid(false);
  };
  const smallScreen = useMediaQuery("(max-width: 767px)");

  return (
    <div
      className={cn(
        "page-content",
        smallScreen ? styles.pageMobile : styles.page
      )}
    >
      <PracticeHeroCard />
      <PageHeader onSearch={handlesearch} searchPlaceholder="Search Practice" />
      {smallScreen ? (
        <MobileTabs
          actions={
            <Button
              outline
              color="secondary"
              onClick={(e) => {
                if (filterIsVisibile) {
                  setFilterVisibility(false);
                } else {
                  setFilterVisibility(true);
                }
              }}
              style={{
                fontSize: 16,
                fontWeight: 500,
                height: "calc(100% - 12px)",
                padding: "0 10px",
              }}
            >
              <Icon icon={bxFilter} style={{ fontSize: 24 }} />
              &nbsp;
              {"Filter"}
            </Button>
          }
          activeTab={activeTab}
          filterMenuWidth={240}
          changeLayout={gridView}
          changeLayoutList={listView}
          viewListButton={[
            {
              gridButton: "grid",
              listButton: "list",
            },
          ]}
          filterMenu={
            <div
              className={cn(styles.filter, {
                [styles.visible]: filterIsVisibile,
              })}
            >
              <Grid
                style={{ paddingBottom: 8, width: "100%" }}
                direction="row"
                container
                alignItems="center"
                justifyContent="center"
              >
                <Button
                  color="link"
                  style={{
                    fontSize: 16,
                    color: "#0377D1",
                    display: "inline-block",
                    maxWidth: "100%",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "noWrap",
                  }}
                >
                  {"Clear All"}
                </Button>
              </Grid>
              <Divider />
              <CollapsibleFilterGroup name="Topic">
                <FilterGroupItem
                  badge={10}
                  isChecked
                  setCheck={() => {}}
                  name="Coding"
                />
                <FilterGroupItem
                  badge={10}
                  isChecked
                  setCheck={() => {}}
                  name="Aptitude"
                />
                <FilterGroupItem
                  badge={10}
                  isChecked
                  setCheck={() => {}}
                  name="Descriptive"
                />
              </CollapsibleFilterGroup>
              <Grid
                style={{ padding: "12px 0 4px 0", width: "100%" }}
                direction="row"
                container
                alignItems="center"
                justifyContent="center"
              >
                <Button color="primary" style={{ fontSize: 16 }}>
                  {"Save"}
                </Button>
              </Grid>
            </div>
          }
          filterMenuIsVisible={filterIsVisibile}
          onChange={(newTab) => {
            setActiveTab(newTab);
            setActivePage(1);
          }}
          tabs={[
            { name: "All", badge: practicePackages.length, id: "all" },
            { name: "Free", badge: freePracticePackages.length, id: "free" },
            { name: "Paid", badge: paidPracticePackages.length, id: "paid" },
            {
              name: "Enrolled",
              badge: enrolledPracticePackages.length,
              id: "enrolled",
            },
            {
              name: "Premium",
              badge: premiumPracticePackages.length,
              id: "premium",
            },
            {
              name: "Public",
              badge: publicPracticePackages.length,
              id: "public",
            },
          ]}
        >
          {grid ? (
            <div className={styles.cardsContainer}>
              {currentPracticeCards.slice(
                (activePage - 1) * itemsPerPage,
                activePage * itemsPerPage
              ).length ? (
                currentPracticeCards
                  .slice(
                    (activePage - 1) * itemsPerPage,
                    activePage * itemsPerPage
                  )
                  .map((practiceCard) => (
                    <PracticeCard
                      completedQuestions={practiceCard?.questionCount}
                      description={practiceCard?.description}
                      expired={practiceCard.labExpired}
                      featured={practiceCard?.featured}
                      id={practiceCard.id}
                      imageIndex={practiceCard?.imageIndex}
                      imageUrl={practiceCard?.media}
                      key={practiceCard.id}
                      discountAmount={practiceCard?.discountAmount}
                      onAddToCartButtonClick={addToCart}
                      onPracticeButtonClick={() => {
                        if (!isLoggedIn.state) {
                          setOpenSignIn(ModalToggle);
                          if (signInType !== "lab") {
                            setSignInType("lab");
                          }
                          setSignInPkg(practiceCard.id);
                        } else {
                          props.history.push({
                            params: { labId: practiceCard.id },
                            pathname: "/view-practice-questions",
                            state: { fromEdittrue: true, id: practiceCard.id },
                          });
                        }
                      }}
                      onTryButtonClick={() => {
                        trackActivity(
                          practiceCard.id,
                          practiceCard.name,
                          "tried-practice-package"
                        );
                        props.history.push({
                          params: { labId: practiceCard.id },
                          pathname: "/view-practice-questions",
                          state: { fromEdittrue: true, id: practiceCard.id },
                        });
                      }}
                      onBuyButtonClick={() => {
                        setOpenSignIn(ModalToggle);
                        if (signInType !== "package") {
                          setSignInType("package");
                        }
                      }}
                      originalPrice={practiceCard?.originalPrice}
                      paidPackage={practiceCard?.paidPackage}
                      inCart={practiceCard?.addedCart}
                      progress={parseInt(practiceCard?.progress)}
                      purchased={practiceCard?.purchased}
                      sessionCart={sessionCart}
                      setSessionCart={setSessionCart}
                      strikingPrice={practiceCard?.strikingPrice}
                      tag={practiceCard?.tags}
                      title={practiceCard?.name}
                      totalQuestions={practiceCard?.questionCount}
                      isLoggedIn={isLoggedIn}
                    />
                  ))
              ) : (
                <div className={styles.noPackagesContainer}>
                  <div className={styles.noPackages}>
                    <svg
                      width="136"
                      height="85"
                      viewBox="0 0 136 85"
                      fill="none"
                    >
                      <path
                        d="M68 72C95.062 72 117 67.0751 117 61C117 54.9249 95.062 50 68 50C40.938 50 19 54.9249 19 61C19 67.0751 40.938 72 68 72Z"
                        fill="#CAC7C7"
                      />
                      <path
                        d="M103.292 32.4106L87.7421 14.9137C86.9958 13.7211 85.9061 13 84.7582 13H51.3265C50.1786 13 49.0889 13.7211 48.3425 14.9122L32.793 32.4121V46.4665H103.292V32.4106Z"
                        stroke="#BBBBBB"
                      />
                      <path
                        d="M82.775 37.2343C82.775 34.7928 84.2984 32.7772 86.1881 32.7756H103.292V60.3657C103.292 63.5952 101.269 66.2421 98.7706 66.2421H37.3141C34.816 66.2421 32.793 63.5937 32.793 60.3657V32.7756H49.8966C51.7862 32.7756 53.3096 34.7882 53.3096 37.2297V37.2632C53.3096 39.7047 54.8499 41.6762 56.738 41.6762H79.3466C81.2348 41.6762 82.775 39.6865 82.775 37.2449V37.2343Z"
                        fill="#F0F0F0"
                        stroke="#B9B9B9"
                      />
                    </svg>
                    <span>No Packages</span>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div style={{ paddingBottom: "10vh" }}>
              {currentPracticeCards.slice(
                (activePage - 1) * itemsPerPage,
                activePage * itemsPerPage
              ).length ? (
                currentPracticeCards
                  .slice(
                    (activePage - 1) * itemsPerPage,
                    activePage * itemsPerPage
                  )
                  .map((practiceCard) => (
                    <PracticeList
                      completedQuestions={practiceCard?.questionCount}
                      description={practiceCard?.description}
                      expired={practiceCard.labExpired}
                      featured={practiceCard?.featured}
                      id={practiceCard.id}
                      imageIndex={practiceCard?.imageIndex}
                      imageUrl={practiceCard?.media}
                      key={practiceCard.id}
                      discountAmount={practiceCard?.discountAmount}
                      onAddToCartButtonClick={addToCart}
                      onPracticeButtonClick={() => {
                        if (!isLoggedIn.state) {
                          setOpenSignIn(ModalToggle);
                          if (signInType !== "lab") {
                            setSignInType("lab");
                          }
                          setSignInPkg(practiceCard.id);
                        } else {
                          props.history.push({
                            params: { labId: practiceCard.id },
                            pathname: "/view-practice-questions",
                            state: { fromEdittrue: true, id: practiceCard.id },
                          });
                        }
                      }}
                      onTryButtonClick={() => {
                        trackActivity(
                          practiceCard.id,
                          practiceCard.name,
                          "tried-practice-package"
                        );
                        props.history.push({
                          params: { labId: practiceCard.id },
                          pathname: "/view-practice-questions",
                          state: { fromEdittrue: true, id: practiceCard.id },
                        });
                      }}
                      originalPrice={practiceCard?.originalPrice}
                      paidPackage={practiceCard?.paidPackage}
                      inCart={practiceCard?.addedCart}
                      progress={parseInt(practiceCard?.progress)}
                      purchased={practiceCard?.purchased}
                      sessionCart={sessionCart}
                      setSessionCart={setSessionCart}
                      strikingPrice={practiceCard?.strikingPrice}
                      tag={practiceCard?.tags}
                      title={practiceCard?.name}
                      totalQuestions={practiceCard?.questionCount}
                      gridViews={grid}
                      listViews={grid}
                    />
                  ))
              ) : (
                <div className={styles.noPackagesContainer}>
                  <div className={styles.noPackages}>
                    <svg
                      width="136"
                      height="85"
                      viewBox="0 0 136 85"
                      fill="none"
                    >
                      <path
                        d="M68 72C95.062 72 117 67.0751 117 61C117 54.9249 95.062 50 68 50C40.938 50 19 54.9249 19 61C19 67.0751 40.938 72 68 72Z"
                        fill="#CAC7C7"
                      />
                      <path
                        d="M103.292 32.4106L87.7421 14.9137C86.9958 13.7211 85.9061 13 84.7582 13H51.3265C50.1786 13 49.0889 13.7211 48.3425 14.9122L32.793 32.4121V46.4665H103.292V32.4106Z"
                        stroke="#BBBBBB"
                      />
                      <path
                        d="M82.775 37.2343C82.775 34.7928 84.2984 32.7772 86.1881 32.7756H103.292V60.3657C103.292 63.5952 101.269 66.2421 98.7706 66.2421H37.3141C34.816 66.2421 32.793 63.5937 32.793 60.3657V32.7756H49.8966C51.7862 32.7756 53.3096 34.7882 53.3096 37.2297V37.2632C53.3096 39.7047 54.8499 41.6762 56.738 41.6762H79.3466C81.2348 41.6762 82.775 39.6865 82.775 37.2449V37.2343Z"
                        fill="#F0F0F0"
                        stroke="#B9B9B9"
                      />
                    </svg>
                    <span>No Packages</span>
                  </div>
                </div>
              )}
            </div>
          )}
          {currentPracticeCards.length > itemsPerPage && (
            <Pagination
              activePage={activePage}
              itemsCountPerPage={itemsPerPage}
              onChange={setActivePage}
              pageRangeDisplayed={5}
              totalItemsCount={currentPracticeCards.length}
            />
          )}
        </MobileTabs>
      ) : (
        <Tabs
          actions={
            <Button
              outline
              color="secondary"
              onClick={(e) => {
                if (filterIsVisibile) {
                  setFilterVisibility(false);
                } else {
                  setFilterVisibility(true);
                }
              }}
              style={{
                fontSize: 16,
                fontWeight: 500,
                height: "calc(100% - 12px)",
                padding: "0 10px",
              }}
            >
              <Icon icon={bxFilter} style={{ fontSize: 24 }} />
              &nbsp;
              {"Filter"}
            </Button>
          }
          activeTab={activeTab}
          filterMenuWidth={240}
          changeLayout={gridView}
          changeLayoutList={listView}
          viewListButton={[
            {
              gridButton: "grid",
              listButton: "list",
            },
          ]}
          filterMenu={
            <div
              className={cn(styles.filter, {
                [styles.visible]: filterIsVisibile,
              })}
            >
              <Grid
                style={{ paddingBottom: 8, width: "100%" }}
                direction="row"
                container
                alignItems="center"
                justifyContent="center"
              >
                <Button
                  color="link"
                  style={{
                    fontSize: 16,
                    color: "#0377D1",
                    display: "inline-block",
                    maxWidth: "100%",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "noWrap",
                  }}
                >
                  {"Clear All"}
                </Button>
              </Grid>
              <Divider />
              <CollapsibleFilterGroup name="Topic">
                <FilterGroupItem
                  badge={10}
                  isChecked
                  setCheck={() => {}}
                  name="Coding"
                />
                <FilterGroupItem
                  badge={10}
                  isChecked
                  setCheck={() => {}}
                  name="Aptitude"
                />
                <FilterGroupItem
                  badge={10}
                  isChecked
                  setCheck={() => {}}
                  name="Descriptive"
                />
              </CollapsibleFilterGroup>
              <Grid
                style={{ padding: "12px 0 4px 0", width: "100%" }}
                direction="row"
                container
                alignItems="center"
                justifyContent="center"
              >
                <Button color="primary" style={{ fontSize: 16 }}>
                  {"Save"}
                </Button>
              </Grid>
            </div>
          }
          filterMenuIsVisible={filterIsVisibile}
          onChange={(newTab) => {
            setActiveTab(newTab);
            setActivePage(1);
          }}
          tabs={[
            { name: "All", badge: practicePackages.length, id: "all" },
            { name: "Free", badge: freePracticePackages.length, id: "free" },
            { name: "Paid", badge: paidPracticePackages.length, id: "paid" },
            {
              name: "Enrolled",
              badge: enrolledPracticePackages.length,
              id: "enrolled",
            },
            {
              name: "Premium",
              badge: premiumPracticePackages.length,
              id: "premium",
            },
            {
              name: "Public",
              badge: publicPracticePackages.length,
              id: "public",
            },
          ]}
        >
          {grid ? (
            <div className={styles.cardsContainer}>
              {currentPracticeCards.slice(
                (activePage - 1) * itemsPerPage,
                activePage * itemsPerPage
              ).length ? (
                currentPracticeCards
                  .slice(
                    (activePage - 1) * itemsPerPage,
                    activePage * itemsPerPage
                  )
                  .map((practiceCard) => (
                    <PracticeCard
                      completedQuestions={practiceCard?.questionCount}
                      description={practiceCard?.description}
                      expired={practiceCard.labExpired}
                      featured={practiceCard?.featured}
                      id={practiceCard.id}
                      imageIndex={practiceCard?.imageIndex}
                      imageUrl={practiceCard?.media}
                      key={practiceCard.id}
                      discountAmount={practiceCard?.discountAmount}
                      onAddToCartButtonClick={addToCart}
                      onPracticeButtonClick={() => {
                        if (!isLoggedIn.state) {
                          setOpenSignIn(ModalToggle);
                          if (signInType !== "lab") {
                            setSignInType("lab");
                          }
                          setSignInPkg(practiceCard.id);
                        } else {
                          props.history.push({
                            params: { labId: practiceCard.id },
                            pathname: "/view-practice-questions",
                            state: { fromEdittrue: true, id: practiceCard.id },
                          });
                        }
                      }}
                      onTryButtonClick={() => {
                        trackActivity(
                          practiceCard.id,
                          practiceCard.name,
                          "tried-practice-package"
                        );
                        props.history.push({
                          params: { labId: practiceCard.id },
                          pathname: "/view-practice-questions",
                          state: { fromEdittrue: true, id: practiceCard.id },
                        });
                      }}
                      onBuyButtonClick={() => {
                        setOpenSignIn(ModalToggle);
                        if (signInType !== "package") {
                          setSignInType("package");
                        }
                      }}
                      originalPrice={practiceCard?.originalPrice}
                      paidPackage={practiceCard?.paidPackage}
                      inCart={practiceCard?.addedCart}
                      progress={parseInt(practiceCard?.progress)}
                      purchased={practiceCard?.purchased}
                      sessionCart={sessionCart}
                      setSessionCart={setSessionCart}
                      strikingPrice={practiceCard?.strikingPrice}
                      tag={practiceCard?.tags}
                      title={practiceCard?.name}
                      totalQuestions={practiceCard?.questionCount}
                      isLoggedIn={isLoggedIn}
                    />
                  ))
              ) : (
                <div className={styles.noPackagesContainer}>
                  <div className={styles.noPackages}>
                    <svg
                      width="136"
                      height="85"
                      viewBox="0 0 136 85"
                      fill="none"
                    >
                      <path
                        d="M68 72C95.062 72 117 67.0751 117 61C117 54.9249 95.062 50 68 50C40.938 50 19 54.9249 19 61C19 67.0751 40.938 72 68 72Z"
                        fill="#CAC7C7"
                      />
                      <path
                        d="M103.292 32.4106L87.7421 14.9137C86.9958 13.7211 85.9061 13 84.7582 13H51.3265C50.1786 13 49.0889 13.7211 48.3425 14.9122L32.793 32.4121V46.4665H103.292V32.4106Z"
                        stroke="#BBBBBB"
                      />
                      <path
                        d="M82.775 37.2343C82.775 34.7928 84.2984 32.7772 86.1881 32.7756H103.292V60.3657C103.292 63.5952 101.269 66.2421 98.7706 66.2421H37.3141C34.816 66.2421 32.793 63.5937 32.793 60.3657V32.7756H49.8966C51.7862 32.7756 53.3096 34.7882 53.3096 37.2297V37.2632C53.3096 39.7047 54.8499 41.6762 56.738 41.6762H79.3466C81.2348 41.6762 82.775 39.6865 82.775 37.2449V37.2343Z"
                        fill="#F0F0F0"
                        stroke="#B9B9B9"
                      />
                    </svg>
                    <span>No Packages</span>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div style={{ paddingBottom: "10vh" }}>
              {currentPracticeCards.slice(
                (activePage - 1) * itemsPerPage,
                activePage * itemsPerPage
              ).length ? (
                currentPracticeCards
                  .slice(
                    (activePage - 1) * itemsPerPage,
                    activePage * itemsPerPage
                  )
                  .map((practiceCard) => (
                    <PracticeList
                      completedQuestions={practiceCard?.questionCount}
                      description={practiceCard?.description}
                      expired={practiceCard.labExpired}
                      featured={practiceCard?.featured}
                      id={practiceCard.id}
                      imageIndex={practiceCard?.imageIndex}
                      imageUrl={practiceCard?.media}
                      key={practiceCard.id}
                      discountAmount={practiceCard?.discountAmount}
                      onAddToCartButtonClick={addToCart}
                      onPracticeButtonClick={() => {
                        if (!isLoggedIn.state) {
                          setOpenSignIn(ModalToggle);
                          if (signInType !== "lab") {
                            setSignInType("lab");
                          }
                          setSignInPkg(practiceCard.id);
                        } else {
                          props.history.push({
                            params: { labId: practiceCard.id },
                            pathname: "/view-practice-questions",
                            state: { fromEdittrue: true, id: practiceCard.id },
                          });
                        }
                      }}
                      onTryButtonClick={() => {
                        trackActivity(
                          practiceCard.id,
                          practiceCard.name,
                          "tried-practice-package"
                        );
                        props.history.push({
                          params: { labId: practiceCard.id },
                          pathname: "/view-practice-questions",
                          state: { fromEdittrue: true, id: practiceCard.id },
                        });
                      }}
                      originalPrice={practiceCard?.originalPrice}
                      paidPackage={practiceCard?.paidPackage}
                      inCart={practiceCard?.addedCart}
                      progress={parseInt(practiceCard?.progress)}
                      purchased={practiceCard?.purchased}
                      sessionCart={sessionCart}
                      setSessionCart={setSessionCart}
                      strikingPrice={practiceCard?.strikingPrice}
                      tag={practiceCard?.tags}
                      title={practiceCard?.name}
                      totalQuestions={practiceCard?.questionCount}
                      gridViews={grid}
                      listViews={grid}
                    />
                  ))
              ) : (
                <div className={styles.noPackagesContainer}>
                  <div className={styles.noPackages}>
                    <svg
                      width="136"
                      height="85"
                      viewBox="0 0 136 85"
                      fill="none"
                    >
                      <path
                        d="M68 72C95.062 72 117 67.0751 117 61C117 54.9249 95.062 50 68 50C40.938 50 19 54.9249 19 61C19 67.0751 40.938 72 68 72Z"
                        fill="#CAC7C7"
                      />
                      <path
                        d="M103.292 32.4106L87.7421 14.9137C86.9958 13.7211 85.9061 13 84.7582 13H51.3265C50.1786 13 49.0889 13.7211 48.3425 14.9122L32.793 32.4121V46.4665H103.292V32.4106Z"
                        stroke="#BBBBBB"
                      />
                      <path
                        d="M82.775 37.2343C82.775 34.7928 84.2984 32.7772 86.1881 32.7756H103.292V60.3657C103.292 63.5952 101.269 66.2421 98.7706 66.2421H37.3141C34.816 66.2421 32.793 63.5937 32.793 60.3657V32.7756H49.8966C51.7862 32.7756 53.3096 34.7882 53.3096 37.2297V37.2632C53.3096 39.7047 54.8499 41.6762 56.738 41.6762H79.3466C81.2348 41.6762 82.775 39.6865 82.775 37.2449V37.2343Z"
                        fill="#F0F0F0"
                        stroke="#B9B9B9"
                      />
                    </svg>
                    <span>No Packages</span>
                  </div>
                </div>
              )}
            </div>
          )}
          {currentPracticeCards.length > itemsPerPage && (
            <Pagination
              activePage={activePage}
              itemsCountPerPage={itemsPerPage}
              onChange={setActivePage}
              pageRangeDisplayed={5}
              totalItemsCount={currentPracticeCards.length}
            />
          )}
        </Tabs>
      )}
      <SignInModal
        modal={openSignIn}
        setModal={() => {
          setOpenSignIn(ModalToggle);
        }}
        fromLab={signInType === "lab"}
        buyPkg={signInType === "package"}
        openInstructions={() => {
          props.history.push({
            params: { labId: signInPkg },
            pathname: "/view-practice-questions",
            state: { fromEdittrue: true, id: signInPkg },
          });
        }}
        pkgId={signInPkg}
      />
    </div>
  );
}

export default withRouter(Lab);
