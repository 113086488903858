import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
} from "@material-ui/core";
import { CloseOutlined } from "@material-ui/icons";
import {
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  Button,
} from "reactstrap";
import NetworkCheckIcon from "@material-ui/icons/NetworkCheck";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import CancelIcon from "@material-ui/icons/Cancel";
import * as BsIcons from "react-icons/bs";
import CircularProgress from "@material-ui/core/CircularProgress";
import penLogo from "../../assets/images/assessmenttest/pen-box.svg";
import ReactHtmlParser from "html-react-parser";
import { browserCheck } from "../Taketest/common/testHelper";
import styles from "../CustomerManagement/BulkUserUpload.module.scss";
import { uid } from "react-uid";
import { InternetSpeedChecker } from "pages/utils/InternetSpeedChecker";
import SebInstructions from "./SebInstructions";
import AssessmentChecks from "./AssessmentChecks";
import {useMediaQuery} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
const onSEB = navigator.userAgent.includes("SEB/");

export function InstructionModal({ isSEB, sebUrl, ...props }) {
  const smallScreen = useMediaQuery("(max-width: 767px)");
  const [userCheck, setUserCheck] = useState(false);
  const [browserCompatibility, setBrowserCompatibility] = useState(false);
  const [speed, setSpeed] = useState({});
  const [speedLevel, setSpeedLevel] = useState("");
  const [showSebIns, setShowSebIns] = useState({ type: "", state: false });
  const [validateCheck, setValidateCheck] = useState(false);

  let tQuestions = [];
  props?.sectionData?.forEach((totalQuestions) => {
    tQuestions.push(totalQuestions.noOfQuestions);
  });
  let TQ = tQuestions.reduce((a, b) => a + b, 0);
  let TT = props?.assessmentData?.timeLimit;

  const handleCheck = async () => {
    if (!userCheck) {
      let value = await browserCheck();
      await InternetSpeedChecker({ setSpeed });
      if (value) {
        setBrowserCompatibility(!userCheck);
      }
    }
    setUserCheck(!userCheck);
  };

  useEffect(() => {
    if (speed.hasOwnProperty("bps") && !speedLevel) {
      const kbps = +speed.speedInKbps.split(".")[0].slice(0, 3);
      if (kbps < 60) {
        setSpeedLevel("Bad");
      } else {
        setSpeedLevel("Ok");
      }
    }
  }, [speed]);

  const handleClose = () => {
    if (showSebIns.state) {
      setShowSebIns({ state: false, type: "" });
    }
    props.toggle();
  };
  const useStyles = makeStyles(() => ({
    paper: {maxWidth: "730px" },
  }));

  return (
    <>
    { smallScreen ? (
      <Dialog fullScreen open={props.showModal}>
        <DialogTitle classes={{ root: styles.header }}>
        Start Assessment
          {props?.toggle && (
            <CloseOutlined
              onClick={() => {
                handleClose();
                if (userCheck) setUserCheck(false);
              }}
              classes={{ root: styles.closeButton }}
            />
          )}
        </DialogTitle>
        <DialogContent>
          {isSEB ? (
            <>
              {validateCheck ? (
                <AssessmentChecks
                  sebUrl={sebUrl}
                  setCheckModal={props.setCheckModal}
                  type={showSebIns.type}
                />
              ) : (
                <SebInstructions
                  closeModal={handleClose}
                  sebUrl={sebUrl}
                  setValidateCheck={() => setValidateCheck(true)}
                  type={showSebIns.type}
                />
              )}
            </>
          ) : (
            <>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    backgroundColor: "#53f3cb",
                    padding: 5,
                    borderRadius: 5,
                    width: 45,
                    height: 45,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img src={penLogo} width={20} height={20} />
                </div>
                <div
                  style={{
                    paddingLeft: 8,
                  }}
                >
                  <div
                    style={{
                      alignItems: "left",
                      justifyContent: "left",
                      maxWidth: "auto",
                      height: "25px",
                    }}
                  >
                    <h4
                      style={{
                        marginTop: "2px",
                        fontFamily: "Poppins",
                        fontStyle: "normal",
                        fontWeight: "600",
                        fontSize: "14px",
                        color: "#00000",
                      }}
                    >
                      {props.assessmentData.name}
                    </h4>
                    <div style={{display: "flex"}}>
                    <span>
                      <BsIcons.BsDot
                        style={{
                          color: "#63606C",
                          width: "17px",
                          height: "17px",
                        }}
                      />
                    </span>
                    <span>
                      <p
                        style={{
                          fontFamily: "Poppins",
                          fontSize: "10px",
                          fontWeight: 500,
                          color: "#63606C",
                        }}
                      >
                        {TQ} questions
                      </p>
                    </span>
                    <span>
                      <BsIcons.BsDot
                        style={{
                          color: "#63606C",
                          width: "17px",
                          height: "17px",
                        }}
                      />
                    </span>
                    <span>
                      <p
                        style={{
                          fontFamily: "Poppins",
                          fontSize: "10px",
                          fontWeight: 500,
                          color: "#63606C",
                        }}
                      >
                        {TT} minutes
                      </p>
                    </span>
                  </div>
                  <div>
                    <span
                      style={{
                        fontFamily: "Poppins",
                        fontSize: "10px",
                        fontWeight: 500,
                        color: "#63606C",
                        paddingLeft: "10px"
                      }}
                    >
                      {props.assessmentData.description}
                    </span>
                  </div>
                  </div>
                </div>
              </div>
              <div
                style={{
                  backgroundColor: "#FFF9CC",
                  borderRadius: 5,
                  padding: 0,
                  margin: "55px auto",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    color: "#000",
                    fontSize: 16,
                    marginBottom: 5,
                  }}
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12 1.5C6.20156 1.5 1.5 6.20156 1.5 12C1.5 17.7984 6.20156 22.5 12 22.5C17.7984 22.5 22.5 17.7984 22.5 12C22.5 6.20156 17.7984 1.5 12 1.5ZM12.75 17.0625C12.75 17.1656 12.6656 17.25 12.5625 17.25H11.4375C11.3344 17.25 11.25 17.1656 11.25 17.0625V10.6875C11.25 10.5844 11.3344 10.5 11.4375 10.5H12.5625C12.6656 10.5 12.75 10.5844 12.75 10.6875V17.0625ZM12 9C11.7056 8.99399 11.4253 8.87282 11.2192 8.6625C11.0132 8.45218 10.8977 8.16945 10.8977 7.875C10.8977 7.58055 11.0132 7.29782 11.2192 7.0875C11.4253 6.87718 11.7056 6.75601 12 6.75C12.2944 6.75601 12.5747 6.87718 12.7808 7.0875C12.9868 7.29782 13.1023 7.58055 13.1023 7.875C13.1023 8.16945 12.9868 8.45218 12.7808 8.6625C12.5747 8.87282 12.2944 8.99399 12 9Z"
                      fill="#FDBC1F"
                    />
                  </svg>
                  &nbsp; Instructions
                </div>
                <div>{typeof instruction === 'string' ? instruction : ''}</div>
              </div>

              <div>
                <table style={{ width: "100%" }}>
                  <tr>
                    <th>S.No</th>
                    <th style={{ textAlign: "left" }}>Section Name</th>
                    <th style={{ textAlign: "left" }}>Section Description</th>
                    <th>No of Questions</th>
                  </tr>
                  {!!props.sectionData.length &&
                    props.sectionData.map((e, i) => (
                      <tr key={uid(e)}>
                        <td>{i + 1}</td>
                        <td style={{ textAlign: "left" }}>{e.secName}</td>
                        <td style={{ textAlign: "left" }}>
                          {e.secDescription}
                        </td>
                        <td>{e.noOfQuestions}</td>
                      </tr>
                    ))}
                </table>
              </div>

              {!!props.authDetails.passCode && (
                <InputGroup style={{ marginTop: 15 }}>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>Enter Passcode</InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Passcode"
                    type="password"
                    disabled={props.auth}
                    onChange={(e) => {
                      props.setPassword(e.target.value);
                    }}
                  />
                  <Button
                    color="primary"
                    disabled={props.auth}
                    style={{
                      paddingRight: 20,
                      paddingLeft: 20,
                      marginLeft: 30,
                    }}
                    onClick={async () => {
                      await props.handlePassword();
                    }}
                  >
                    Verify
                  </Button>
                </InputGroup>
              )}
            </>
          )}
        </DialogContent>
        {!showSebIns.state && (
          <DialogActions
            style={{
              display: "flex",
              flexDirection: "column",
              position: "relative",
            }}
          >
            {userCheck ? (
            !!speedLevel && (
              <div
                style={{
                  display:"flex",
                  fontWeight: 400,
                  transition: "display 5s",
                  display: "flex",
                  flexDirection: "row",
                  gap: 5,
                  paddingBottom:"12px"
                }}
              >

                <div>
                  {browserCompatibility ? (
                    <CheckCircleOutlineIcon
                      style={{
                        color: "#64bc26",
                      }}
                    />
                  ) : (
                    <CancelIcon style={{ color: "#ea1601" }} />
                  )}
                  &nbsp; Browser
                  {browserCompatibility ? " compatible" : " incompatible"}
                </div>
                <div>
                  <NetworkCheckIcon
                    style={{
                      color: speedLevel === "Ok" ? "#64bc26" : "#ea1601",
                    }}
                  />
                  &nbsp; Internet Speed: {speedLevel}
                </div>
              </div>
            )) : ''}
            <label
              style={{
                backgroundColor: "#F5F6F8",
                border: "1px solid #CECECE",
                borderRadius: 3,
                display: "flex",
                alignItems: "center",
                padding: "8px 16px",
                margin: "0 auto",
              }}
            >
              <input
                type="checkbox"
                style={{ marginRight: 8}}
                checked={userCheck}
                onChange={handleCheck}
              />
              I have read all the above instructions and agree to start test
            </label>
            <div style={{ margin: "30px auto" }}>
              <Button
                color="primary"
                onClick={() => {
                  isSEB
                    ? setShowSebIns({
                        state: true,
                        type: onSEB ? "launch" : "download",
                      })
                    : props.setCheckModal();
                }}
                style={{
                  fontSize: 15,
                  width: 170,
                  display: typeof isSEB === "boolean" ? "block" : "none",
                }}
                disabled={
                  !browserCompatibility ||
                  speedLevel === "Bad" ||
                  speedLevel === "" ||
                  (props.authDetails.passCode
                    ? !userCheck || !props.auth
                    : !userCheck)
                }
              >
                {userCheck && !speed?.bps ? (
                  <CircularProgress size={18} style={{ color: "#fff" }} />
                ) : isSEB ? (
                  "Continue"
                ) : (
                  "Start Assessment"
                )}
              </Button>
            </div>
          </DialogActions>
        )}
      </Dialog>
    ) : (
      <Dialog open={props.showModal} fullWidth maxWidth="md">
        <DialogTitle classes={{ root: styles.header }}>
          Start Assessment
          {props?.toggle && (
            <CloseOutlined
              onClick={() => {
                handleClose();
                if (userCheck) setUserCheck(false);
              }}
              classes={{ root: styles.closeButton }}
            />
          )}
        </DialogTitle>
        <DialogContent>
          {isSEB ? (
            <>
              {validateCheck ? (
                <AssessmentChecks
                  sebUrl={sebUrl}
                  setCheckModal={props.setCheckModal}
                  type={showSebIns.type}
                />
              ) : (
                <SebInstructions
                  closeModal={handleClose}
                  sebUrl={sebUrl}
                  setValidateCheck={() => setValidateCheck(true)}
                  type={showSebIns.type}
                />
              )}
            </>
          ) : (
            <>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    backgroundColor: "#53f3cb",
                    padding: 5,
                    borderRadius: 5,
                    width: 45,
                    height: 45,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img src={penLogo} width={20} height={20} />
                </div>
                <div
                  style={{
                    paddingLeft: 8,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "left",
                      justifyContent: "left",
                      maxWidth: "auto",
                      height: "25px",
                    }}
                  >
                    <h4
                      style={{
                        marginTop: "2px",
                        fontFamily: "Poppins",
                        fontStyle: "normal",
                        fontWeight: "600",
                        fontSize: "14px",
                        color: "#00000",
                      }}
                    >
                      {props.assessmentData.name}
                    </h4>
                    <span>
                      <BsIcons.BsDot
                        style={{
                          color: "#63606C",
                          width: "17px",
                          height: "17px",
                        }}
                      />
                    </span>
                    <span>
                      <p
                        style={{
                          fontFamily: "Poppins",
                          fontSize: "14px",
                          fontWeight: 500,
                          color: "#63606C",
                        }}
                      >
                        {TQ} {TQ === 1 ? 'question' : 'questions'}
                      </p>
                    </span>
                    <span>
                      <BsIcons.BsDot
                        style={{
                          color: "#63606C",
                          width: "17px",
                          height: "17px",
                        }}
                      />
                    </span>
                    <span>
                      <p
                        style={{
                          fontFamily: "Poppins",
                          fontSize: "14px",
                          fontWeight: 500,
                          color: "#63606C",
                        }}
                      >
                        {TT} {TT === 1 ? 'minute' : 'minutes'}
                      </p>
                    </span>
                  </div>

                  <div>
                    <span
                      style={{
                        fontSize: 11,
                      }}
                    >
                      {props.assessmentData.description}
                    </span>
                  </div>
                </div>
              </div>

              <div
                style={{
                  backgroundColor: "#FFF9CC",
                  borderRadius: 5,
                  padding: "20px 10px",
                  margin: "15px auto",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    color: "#000",
                    fontSize: 16,
                    marginBottom: 5,
                  }}
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12 1.5C6.20156 1.5 1.5 6.20156 1.5 12C1.5 17.7984 6.20156 22.5 12 22.5C17.7984 22.5 22.5 17.7984 22.5 12C22.5 6.20156 17.7984 1.5 12 1.5ZM12.75 17.0625C12.75 17.1656 12.6656 17.25 12.5625 17.25H11.4375C11.3344 17.25 11.25 17.1656 11.25 17.0625V10.6875C11.25 10.5844 11.3344 10.5 11.4375 10.5H12.5625C12.6656 10.5 12.75 10.5844 12.75 10.6875V17.0625ZM12 9C11.7056 8.99399 11.4253 8.87282 11.2192 8.6625C11.0132 8.45218 10.8977 8.16945 10.8977 7.875C10.8977 7.58055 11.0132 7.29782 11.2192 7.0875C11.4253 6.87718 11.7056 6.75601 12 6.75C12.2944 6.75601 12.5747 6.87718 12.7808 7.0875C12.9868 7.29782 13.1023 7.58055 13.1023 7.875C13.1023 8.16945 12.9868 8.45218 12.7808 8.6625C12.5747 8.87282 12.2944 8.99399 12 9Z"
                      fill="#FDBC1F"
                    />
                  </svg>
                  &nbsp; Instructions
                </div>
                <div>{typeof instruction === 'string' ? instruction : ''}</div>
                {props?.authDetails?.isProctoringAI &&
                <ul>
                  <li><b>This assessment is protected by AI proctoring</b> below actions are not allowed during this assessment.</li>
                  <li>Mobile phones are prohibited during assessment.</li>
                  <li>Laptop are prohibited during assessment.</li>
                  <li>Books are not allowed during assessment</li>
                  <li>Always show your face in camera</li>
                  <li>Warning popup will appear once above actions are detected</li>
                </ul>
              }
              </div>

              <div style={{paddingTop:"20px"}}>
                <table style={{ width: "100%" }}>
                  <tr>
                    <th>S.No</th>
                    <th style={{ textAlign: "left" }}>Section name</th>
                    <th style={{ textAlign: "left" }}>Section description</th>
                    <th>No of questions</th>
                  </tr>
                  {!!props.sectionData.length &&
                    props.sectionData.map((e, i) => (
                      <tr key={uid(e)}>
                        <td>{i + 1}</td>
                        <td style={{ textAlign: "left" }}>{e.secName}</td>
                        <td style={{ textAlign: "left" }}>
                          {e.secDescription}
                        </td>
                        <td>{e.noOfQuestions}</td>
                      </tr>
                    ))}
                </table>
              </div>

              {!!props.authDetails.passCode && (
                <InputGroup style={{ marginTop: 15 }}>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>Enter Passcode</InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Passcode"
                    type="password"
                    disabled={props.auth}
                    onChange={(e) => {
                      props.setPassword(e.target.value);
                    }}
                  />
                  <Button
                    color="primary"
                    disabled={props.auth}
                    style={{
                      paddingRight: 20,
                      paddingLeft: 20,
                      marginLeft: 30,
                    }}
                    onClick={async () => {
                      await props.handlePassword();
                    }}
                  >
                    Verify
                  </Button>
                </InputGroup>
              )}
            </>
          )}
        </DialogContent>
        {!showSebIns.state && (
          <DialogActions
            style={{
              display: "flex",
              flexDirection: "column",
              position: "relative",
            }}
          >
            {userCheck ? (
            !!speedLevel && (
              <div
                style={{
                  position: "absolute",
                  left: 20,
                  top: 70,
                  fontWeight: 600,
                  transition: "display 5s",
                  display: "flex",
                  flexDirection: "column",
                  gap: 10,
                }}
              >
                <div>
                  {browserCompatibility ? (
                    <CheckCircleOutlineIcon
                      style={{
                        color: "#64bc26",
                      }}
                    />
                    
                  ) : (
                    <CancelIcon style={{ color: "#ea1601" }} />
                  )}
                  &nbsp; Browser
                  {browserCompatibility ? " compatible" : " incompatible"}
                </div>
                <div>
                  <NetworkCheckIcon
                    style={{
                      color: speedLevel === "Ok" ? "#64bc26" : "#ea1601",
                    }}
                  />
                  &nbsp; Internet Speed: {speedLevel}
                </div>
              </div>
            )) : ''}
            {!isSEB &&
          <div style={{paddingTop:"20px"}}>
            <label
              style={{
                backgroundColor: "#F5F6F8",
                border: "1px solid #CECECE",
                borderRadius: 3,
                display: "flex",
                alignItems: "center",
                padding: "8px 16px",
                margin: "0 auto",
              }}
            >
              <input
                type="checkbox"
                style={{ marginRight: 8, width:'15px', height:'15px' }}
                checked={userCheck}
                onChange={handleCheck}
              />
              I have read all the above instructions and agree to start test
            </label>
            </div>
            }
            {!isSEB &&
            <div style={{ margin: "20px auto" }}>
              <Button
                color="primary"
                onClick={() => {
                  isSEB
                    ? setShowSebIns({
                        state: true,
                        type: onSEB ? "launch" : "download",
                      })
                    : props.setCheckModal();
                }}
                style={{
                  fontSize: 15,
                  width: 170,
                  display: typeof isSEB === "boolean" ? "block" : "none",
                }}
                disabled={
                  !browserCompatibility ||
                  speedLevel === "Bad" ||
                  speedLevel === "" ||
                  (props.authDetails.passCode
                    ? !userCheck || !props.auth
                    : !userCheck)
                }
              >
                {userCheck && !speed?.bps ? (
                  <CircularProgress size={18} style={{ color: "#fff" }} />
                ) : isSEB ? (
                  "Continue"
                ) : (
                  "Start Assessment "
                )}
              </Button>
            </div>
          }
          </DialogActions>
        )}
      </Dialog>
    )}
    </>
  );
}
