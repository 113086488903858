import cn from 'classnames';
import TimeFormat from 'hh-mm-ss';
import {
  Grid,
  LinearProgress,
  Typography,
} from '@material-ui/core';
import AccessAlarmIcon from '@material-ui/icons/AccessAlarm';
import { TProps } from './timer.props';
import styles from './timer.module.scss';

export function Timer(props: TProps) {
  const currQuesTime = props.currentQuestion?.timeLimit, timeType = props.timeType;
  const elapsedTime = props.elapsedTime,
    totalTime = (timeType === "time" || timeType === "duration") ?
      props.totalTime : (timeType === "question" && currQuesTime) ?
      Math.round(currQuesTime * 60) : 0;
  const remainingTime = totalTime - elapsedTime;
  const progressPercentage = (elapsedTime / totalTime) * 100;
  return (
    <Grid
      alignItems="center"
      classes={{
        root: cn(styles.timer, props?.smallScreen && styles.smallScreen)
      }}
      container
      justifyContent="center">
      <div className={styles.remainingTimeBarContainer}>
        <LinearProgress
          classes={{
            root: styles.linearProgressBg,
            bar: cn(
              styles.linearProgressBar,
              (progressPercentage < 50)
                ? styles.green
                : (progressPercentage < 80)
                  ? styles.orange
                  : styles.red,
            ),
          }}
          variant="determinate"
          value={progressPercentage}
        />
        <LinearProgress
          classes={{
            root: styles.linearProgress,
            bar: cn(
              styles.linearProgressBar,
              (progressPercentage < 50)
                ? styles.green
                : (progressPercentage < 80)
                  ? styles.orange
                  : styles.red,
            ),
          }}
          variant="determinate"
          value={progressPercentage}
        />
      </div>
      <AccessAlarmIcon
        className={styles.icon}
      />
      <Typography
        className={styles.text}
        >
        <span className={styles.timeSection}>
          {TimeFormat.fromS(remainingTime, 'hh:mm:ss').split(':')[0]}
        </span>
        <span className={styles.colon}>:</span>
        <span className={styles.timeSection}>
          {TimeFormat.fromS(remainingTime, 'hh:mm:ss').split(':')[1]}
        </span>
        <span className={styles.colon}>:</span>
        <span className={styles.timeSection}>
          {TimeFormat.fromS(remainingTime, 'hh:mm:ss').split(':')[2]}
        </span>
      </Typography>
    </Grid>
  );
}
