import React, { useEffect, useState, useRef } from "react";
import styles from "./EditLesson.module.scss";
import { makeStyles } from "@material-ui/core/styles";
import FullscreenIcon from "@material-ui/icons/Fullscreen";
import FullscreenExitIcon from "@material-ui/icons/FullscreenExit";
import VolumeUpIcon from "@material-ui/icons/VolumeUp";
import VolumeOffIcon from "@material-ui/icons/VolumeOff";
import Replay10Icon from "@material-ui/icons/Replay10";
import Forward10Icon from "@material-ui/icons/Forward10";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import PauseIcon from "@material-ui/icons/Pause";
import PlayCircleFilledIcon from "@material-ui/icons/PlayCircleFilled";
import PauseCircleFilledIcon from "@material-ui/icons/PauseCircleFilled";
import {
  Dialog,
  Button,
  DialogContent,
  DialogActions,
  IconButton,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import SelectQuestionsQPModalNew from "pages/virtualLab/SelectQuestionsModal/SelectQuestionsQPModalNew";
import VideoPlayer from "./videoPlayer";
import { LessonIcons } from "./Content";
import { ErrorBlock } from "components/ErrorBlock";
import { ConformDelete } from "../../components/DeleteModelePre";
import { RequiredIndicator } from "pages/utils/RequiredIndicator";
import { PlusIcon, BXedit, BXTrash } from "assets/icons/icons";
import { animated, useSpring, config } from "react-spring";
import { TervButtonOutlined, TervButtonFilled, TervButtonOutlined2, TervButtonFilled2 } from "components/TervButtons";

const paraStyle = {
  fontSize: 15,
  fontWeight: 500,
  color: "#5B5A5F",
  marginTop: 12,
  textAlign: "center",
};
const timePad = (val) => ("0" + Math.floor(val)).slice(-2);
function timeFormat(time) {
  const sec = time % 60;
  const hr = Math.floor(time / 3600);
  return `${hr > 0 ? `${timePad(hr)}:` : ""}${timePad(
    (time % 3600) / 60
  )}:${timePad(time % 60)}`;
}
function timeObjectFormat(time) {
  return {hr: Math.floor(time / 3600), min: Math.floor((time % 3600) / 60), sec: Math.floor(time % 60)}
}
const TimeInput = ({ min, max, value, onChange, onFocus, onBlur }) => {
  return (
    <input
      type="number"
      min={min}
      max={max}
      value={value}
      onBlur={onBlur}
      onFocus={onFocus}
      onChange={(e) => onChange(e.target.value)}
    />
  );
};
const useStyles = makeStyles({
  Dialog: {
    diplay: "flex",
    width: "100%",
    height: "100%",
    flexDirection: "column",
    "& .DialogHead": {
      width: "100%",
      padding: 16,
      borderBottom: "1px solid #dee0e3",
      "& h3": {
        lineHeight: 1,
        fontSize: 16,
        textalign: "left",
        margin: 0,
      },
    },
    "& .DialogBody": {
      display: "flex",
      flexDirection: "column",
      flex: "1 1 0%",
      width: "100%",
      height: "100%",
      padding: 16,
      justifyContent: "center",
      alignItems: "center",
    },
    "& .DialogFooter": {
      display: "flex",
      width: "100%",
      gap: 16,
      padding: 16,
      background: "#f6f6f7",
      borderTop: "1px solid #dee0e3",
      alignItems: "center",
      justifyContent: "center",
    },
    "& .timeBox": {
      border: "1px solid #dee0e3",
      background: "#ffffff",
      display: "flex",
      borderRadius: 6,
      justifyContent: "center",
      alignItems: "center",
      maxWidth: 200,
      "&.focus": {
        border: "1px solid #5d29fd",
      },
      "&.error": {
        border: "1px solid #eb3941",
      },
      "& > *": {
        fontSize: 20,
        lineHeight: 1,
        fontWeight: 500,
        textalign: "center",
      },
      "& > div > input": {
        fontSize: 20,
        lineHeight: 1,
        textAlign: "center",
        maxWidth: 64,
        background: "transparent",
        border: "0px solid transparent",
        outline: "none",
        "&:focus": {
          outline: "none",
        },
        "&:active": {
          outline: "none",
        },
      },
    },
    "& .timerNote": {
      margin: "16px 0",
      fontSize: 11,
      opacity: 0.8,
    },
  },
});
const EditTimer = ({ time, min, max, name, update, close }) => {
  const css = useStyles();
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(false);
  const [focus, setFocus] = useState(false);
  const [mediaTime, setMediaTime] = useState({ hr: 0, min: 0, sec: 0 });
  const [initialState, setInitialState] = useState(true);

  const handleClose = () => {
    setOpen(false);
    close()
  };
  const updateTime = () => {
    const latestTime = parseInt(mediaTime.hr) * 3600 + parseInt(mediaTime.min) * 60 + parseInt(mediaTime.sec);
    if (latestTime <= max) {
      setError(false);
      update(latestTime);
      setInitialState(false);
    } else {
      setError(true);
    }
    update();
  };
  useEffect(() => {
    if (name !== undefined && name !== "") {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [name]);
  useEffect(() => {
    if (time > 0) {
      setMediaTime({
        hr: Math.floor(time / 3600),
        min: Math.floor((time % 3600) / 60),
        sec: Math.floor(time % 60),
      });
    } else {
      setMediaTime({ hr: 0, min: 0, sec: 0 });
      if (!initialState) {
        setInitialState(true);
      }
    }
  }, [time, initialState]);
  return (
    <Dialog onClose={handleClose} aria-labelledby="set-time" open={open}>
      <div className={css.Dialog}>
        <div className="DialogHead" id="set-time">
          <h3>Set time for {name}</h3>
        </div>
        <div className="DialogBody">
          <div
            className={`timeBox ${focus ? "focus" : ""} ${
              error ? "error" : ""
            }`}
          >
            <div>
              <TimeInput
                value={mediaTime.hr}
                onFocus={() => setFocus(true)}
                onBlur={() => setFocus(false)}
                onChange={(hr) => {
                  setError(false);
                  setMediaTime({ ...mediaTime, hr });
                }}
              />
            </div>
            <span>:</span>
            <div>
              <TimeInput
                value={mediaTime.min}
                onFocus={() => setFocus(true)}
                onBlur={() => setFocus(false)}
                onChange={(min) => {
                  setError(false);
                  setMediaTime({ ...mediaTime, min });
                }}
              />
            </div>
            <span>:</span>
            <div>
              <TimeInput
                value={mediaTime.sec}
                onFocus={() => setFocus(true)}
                onBlur={() => setFocus(false)}
                onChange={(sec) => {
                  setError(false);
                  setMediaTime({ ...mediaTime, sec });
                }}
              />
            </div>
          </div>
          <div className="timerNote">{`Please choose time between 00:00:00 to ${timeFormat(
            max
          )}`}</div>
        </div>
        <div className="DialogFooter">
          <TervButtonOutlined text="Cancel" onClick={handleClose} />
          <TervButtonFilled2
            text="Save"
            onClick={updateTime}
            disabled={error}
          />
        </div>
      </div>
    </Dialog>
  );
};

const VideoQuiz = ({sectionValues, selectedLesson, setSectionValues, updateSelectedLesson, removeLesson, listQB }) => {
  const [currentLesson, setCurrentLesson] = useState({});
  const [isStateLoaded, setIsStateLoaded] = useState("false");
  const [aName, setAName] = useState("");
  const [allowEditName, setAllowEditName] = useState({name:"", status:false});
  const [duration, setDuration] = useState(0);
  const [mediaName, setMediaName] = useState("");
  const [mediaDelete, setMediaDelete] = useState({ type: "", index: null });
  const [videoQuizz, setVideoQuizz] = useState([{ quizzName: "", timeLine: "0", questionId: []}]);
  const [errorNameList, setErrorNameList] = useState([{ index: -1, errorNames: [] }]);
  const [showError, setShowError] = useState(false);
  const [timerEdit, SetTimerEdit] = useState({ time: 0, min: 0, max: 0, name: "", update: () => null, close: () => null });
  
  const videoContainer = useRef(null);
  const videoElement = useRef(null);
  
  const [loading, setLoading] = useState(true);
  const [toRemove, setToRemove] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const [currBankQues, setCurrBankQues] = useState([]);
  const [currQuesBankTC, setCurrQuesBankTC] = useState("");
  const [questionBanks, setQuestionBanks] = useState([]);
  const [openAddQuestion, setOpenAddQuestion] = useState(false);

  const [selectedQuizName, setSelectedQuizName] = useState("");
  const [selectedQuizIndex, setSelectedQuizIndex] = useState(-1);
  const [quizTimeIndicator, setQuizTimeIndicator] = useState([]);
  const [selectedQuestionId, setSelectedQuestionId] = useState([]);
  const [selectedQuestionList, setSelectedQuestionList] = useState([]);
  const [selectedQB, setSelectedQB] = useState({ label: "All question bank", value: "all" });
  const [initialValues, setInitialValues] = useState({});

  const updateDuration = (time) => {
    const arrIndicator = [];
    if (videoQuizz && videoQuizz.length > 0) {
      videoQuizz.map((q) => {
        arrIndicator.push({
          position: Math.round((q.timeLine / time) * 10000) / 100,
          color: "#1BA94C",
        });
      });
    }
    setDuration(parseFloat(time));
    setQuizTimeIndicator(arrIndicator);
  };
  const animatedStyles = useSpring({
    from: { x: 200, right: 0, opacity: 0 },
    to: { x: 0, right: "auto", opacity: 1 },
    config: config.gentle,
    reverse: allowEditName.status,
  });
  const {
    isLoading,
    playerState,
    playerCtrls,
    togglePlay,
    toggleMute,
    getProgress,
    setProgress,
    onLoadedData,
    setProgressEvent,
    setVolumeEvent,
    toggleFullScreen,
    useStyles,
  } = VideoPlayer(videoContainer, videoElement, updateDuration);
  const css = useStyles();
  
  const toggleAllowEditName = () => setAllowEditName({name: mediaName, status: !allowEditName.status});
  const handleOpenAddQuestion = () => {
    setOpenAddQuestion(!openAddQuestion);
  };
  const addRemoveQuestion = (quesIds, quizName, i) => {
    handleOpenAddQuestion();
    setSelectedQuizIndex(i);
    setSelectedQuizName(quizName);
    setSelectedQuestionId(quesIds);
    setSelectedQuestionList([]);
  };
  const addNewQuiz = () => {
    const vQuizz = [...videoQuizz];
    vQuizz.push({
      quizzName: `Quiz ${vQuizz.length + 1}`,
      timeLine: playerState.currentTime,
      questionId: [],
    });
    setVideoQuizz(vQuizz);
  };
  const removeQuiz = (index) => {
    if (videoQuizz && videoQuizz.length > 0) {
      const vQuizz = [];
      videoQuizz.map((q, i) => {
        if (i !== index) vQuizz.push(videoQuizz[i]);
      });
      setVideoQuizz(vQuizz);
    }
  };
  const submitForm = () =>{
    const tempSection = [ ...sectionValues ];
    const i1 = selectedLesson.sectionIndex, i2 = selectedLesson.lessonIndex, s1 = "courseResource";
    if(tempSection && tempSection[i1] && tempSection[i1][s1] && tempSection[i1][s1][i2]){
      const {hr, min, sec} = timeObjectFormat(parseFloat(duration));
      tempSection[i1][s1][i2].name = mediaName;
      tempSection[i1][s1][i2].duration = timeFormat(parseFloat(duration));
      tempSection[i1][s1][i2].videoQuizz = [...videoQuizz];
      tempSection[i1][s1][i2].durationHour = hr;
      tempSection[i1][s1][i2].durationMin = min;
      tempSection[i1][s1][i2].durationSec = sec;
    }
    setSectionValues(tempSection);
    updateSelectedLesson({ type: "", lessonId:"", lessonIndex: "", sectionIndex:""});
  }
  const closeForm = () =>{
    updateSelectedLesson({ type: "", lessonId:"", lessonIndex: "", sectionIndex:""});
  }
  const updateQuizTime = (t, i) => {
    if (t && videoQuizz && videoQuizz.length > 0) {
      const vQuizz = [...videoQuizz];
      vQuizz[i].timeLine = parseInt(t);
      SetTimerEdit({ time: 0, min: 0, max: 0, name: "", close: () => null, close: () => null });
      setVideoQuizz(vQuizz);
    }
  };
  useEffect(() => {
    if (openAddQuestion) {
      const vQuizz = [...videoQuizz];
      if (selectedQuizIndex !== -1 && vQuizz[selectedQuizIndex]) {
        vQuizz[selectedQuizIndex].questionId = selectedQuestionId;
      }
      setVideoQuizz(vQuizz);
    }
  }, [selectedQuestionId]);

  useEffect(() => {
    const currSection = sectionValues[selectedLesson.sectionIndex];
    const currLesson = currSection.courseResource && currSection.courseResource[selectedLesson.lessonIndex];
    if(currLesson) {
      setCurrentLesson(currLesson);
      const tempVidQuiz = currLesson.videoQuizz;
      tempVidQuiz && tempVidQuiz.length > 0 && tempVidQuiz.map((q, i) => {
      tempVidQuiz[i].quizzName =
        (q.quizzName !== undefined && q.quizzName !== null) || q.quizzName === "" ? q.quizzName : `Quiz ${i + 1}`;
      });
      setMediaName(currLesson.name ? currLesson.name : "");
      setVideoQuizz(tempVidQuiz && tempVidQuiz.length > 0 ? tempVidQuiz : []);
      updateDuration(duration);
    }
  }, [sectionValues]);

  useEffect(() => {
    updateDuration(duration);
    const quizError = [], tempAllQuizData = {};
    videoQuizz.map((q, i) => {
      if (q.quizzName) {
        const questionList = [];
        if (q.questionId.length > 0) {
          q.questionId.map((id) => questionList.push({ id }));
        }
        tempAllQuizData[q.quizzName] = {
          name: q.quizzName,
          questionList,
        };
      }
      if (!(q.questionId && q.questionId.length > 0)) {
        const errorNames = ["questionLength"];
        quizError[i] = { index: i, errorNames };
      }
      if (!q.quizzName || (q.quizzName && q.quizzName === "")) {
        const errorNames = quizError[i].errorNames
          ? quizError[i].errorNames
          : [];
        errorNames.push("quizName");
        quizError[i] = { index: i, errorNames };
      }
    });
    setInitialValues(tempAllQuizData);
    setErrorNameList(quizError);
  }, [videoQuizz]);

  useEffect(() => {
    if (mediaDelete.type === "videoDeleteConform") {
      setMediaDelete({ type: "", index: null });
      removeLesson(selectedLesson.sectionIndex, selectedLesson.lessonIndex);
      updateSelectedLesson({ type: "", lessonId:"", lessonIndex: "", sectionIndex:""});
    }
    if (
      mediaDelete.type === "quizDeleteConform" &&
      mediaDelete.index !== null
    ) {
      removeQuiz(mediaDelete.index);
      setMediaDelete({ type: "", index: null });
    }
  }, [mediaDelete]);
  useEffect(() => {
    setQuestionBanks(listQB? listQB: [])
  }, [listQB]);

  return (
    <>
      <div className={styles.videoQuiz__popup}>
      <div className={styles.videoQuiz__container}>
        <div className={styles.vQuizBody}>
          {currentLesson.media && currentLesson.media !== "" && (
            <div className={styles.fullSize}>
              <div
                className={
                  css.video +
                  ` ${playerCtrls.isFullScreen ? "fullScreen" : ""}`
                }
                ref={videoContainer}
              >
                <div className="player">
                  <video
                    className="video"
                    ref={videoElement}
                    // controls
                    onTimeUpdate={getProgress}
                    onLoadedData={onLoadedData}
                  >
                    <source src={currentLesson.media} type="video/mp4"></source>
                  </video>
                  <div
                    className={`loading ${isLoading}`}
                    onClick={() => togglePlay}
                  >
                    {isLoading ? (
                      <CircularProgress />
                    ) : !playerCtrls.isPlaying ? (
                      <PauseCircleFilledIcon className={css.zoomInOut} />
                    ) : (
                      <PlayCircleFilledIcon className={css.zoomInOut} />
                    )}
                  </div>
                  <div className="controls">
                    <div className="range">
                      <input
                        type="range"
                        min="0"
                        max="100"
                        step="0.1"
                        value={playerState.progress}
                        onChange={(e) => setProgressEvent(e)}
                      />
                      <div
                        className="rageTrack"
                        style={{
                          backgroundSize: `${playerState.progress}% 100%`,
                        }}
                      >
                        {quizTimeIndicator.length > 0 &&
                          quizTimeIndicator.map((indicator, i) => (
                            <div
                              key={`indicator${i}`}
                              style={{
                                left: `${indicator.position}%`,
                                backgroundColor: indicator.color,
                              }}
                              className="indicator"
                            ></div>
                          ))}
                      </div>
                    </div>
                    <div className="actions">
                      <div className="group">
                        <button
                          onClick={(e) => {
                            e.preventDefault();
                            setProgress(-10);
                          }}
                          disabled={playerState.currentTime - 10 < 0}
                        >
                          <Replay10Icon />
                        </button>
                        <button
                          onClick={(e) => {
                            e.preventDefault();
                            togglePlay();
                          }}
                        >
                          {!playerCtrls.isPlaying ? (
                            <PlayArrowIcon />
                          ) : (
                            <PauseIcon />
                          )}
                        </button>
                        <button
                          onClick={(e) => {
                            e.preventDefault();
                            setProgress(10);
                          }}
                          disabled={
                            playerState.currentTime + 10 >
                            playerCtrls.duration
                          }
                        >
                          <Forward10Icon />
                        </button>
                        <div className="playerTime">
                          <i>{timeFormat(playerState.currentTime)}</i>{" "}
                          <span> / </span>
                          <i>{timeFormat(duration)}</i>
                        </div>
                      </div>
                      <div className="group">
                        <div className="volumeCtrl">
                          <div className="track">
                            <input
                              type="range"
                              min="0"
                              max="100"
                              step="1"
                              value={playerCtrls.volume}
                              onChange={(e) => setVolumeEvent(e)}
                            />
                            <div className="rageTrack" style={{ backgroundSize: `${playerCtrls.volume}% 100%` }}></div>
                          </div>
                          <button
                            onClick={(e) => {
                              e.preventDefault();
                              toggleMute();
                            }}
                          > 
                            {playerCtrls.isMuted ? <VolumeOffIcon /> : <VolumeUpIcon /> }
                          </button>
                        </div>
                        <button
                          onClick={(e) => {
                            e.preventDefault();
                            toggleFullScreen();
                          }}
                        >
                          {!playerCtrls.isFullScreen ? (
                            <FullscreenIcon />
                          ) : (
                            <FullscreenExitIcon />
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.addNewQuiz}>
                <h2>Add Quiz</h2>
                <p>
                  Click add quiz to create a new quiz section and add time
                  for the quiz section
                </p>
                <div className={styles.button__Group}>
                  <TervButtonFilled
                    text="Add quiz"
                    icon={<PlusIcon size="16" color="#1ba94c" />}
                    onClick={(e) => {
                      e.preventDefault();
                      addNewQuiz();
                    }}
                  />
                </div>
              </div>
              <div className={styles.form__Group}>
                <div className={styles.sec__sectionContainer}>
                  <div>
                    <div className={styles.sec__sectionItem}>
                      <div
                        className={
                          allowEditName.status
                            ? styles.sec__sectionItemEditable
                            : styles.sec__sectionItemEditableWithCtrl
                        }
                      >
                        <div
                          className={styles.sec__sectionItemEditableInput}
                        >
                          <span className={styles.sec__name}>
                            Video Name
                          </span>
                          <div className={styles.sec__inputFeild}>
                            <input
                              disabled={!allowEditName.status}
                              value={mediaName}
                              onChange={(e)=>setMediaName(e.target.value)}
                            />
                          </div>
                        </div>
                        {!allowEditName.status && (
                          <div className={styles.sec__ctrls}>
                            <span className={styles.sec__quizCount}>
                              {videoQuizz.length} Quiz
                            </span>

                            <button
                              className={styles.sec__duration}
                              disabled={true}
                            >
                              {timeFormat(duration)} min
                            </button>
                            <animated.span
                              onClick={(e) => {
                                e.preventDefault();
                                toggleAllowEditName();
                              }}
                              className={styles.sec__sectionItemEditIcon}
                              style={animatedStyles}
                            >
                              <BXedit size="24" color="#63606C" />
                            </animated.span>
                            <IconButton
                              className={
                                styles.sec__sectionItemEditIcon
                              }
                              aria-label={`Delete video`}
                              component="span"
                              onClick={(e) => {
                                setMediaDelete({
                                  type: "videoDelete",
                                  index: null,
                                });
                                e.preventDefault();
                              }}
                            >
                              <BXTrash size="24" color="#7C7887" />
                            </IconButton>
                          </div>
                        )}
                      </div>
                      {allowEditName.status ? (
                        <div className={styles.button__Group}>
                          <TervButtonFilled2
                            text="Update"
                            onClick={(e) => {
                              e.preventDefault();
                              toggleAllowEditName();
                            }}
                          />
                          <TervButtonOutlined2
                            text="Cancel"
                            onClick={(e) => {
                              e.preventDefault();
                              setMediaName(allowEditName.name);
                              toggleAllowEditName();
                            }}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    {/* {errors.name && (
                      <ErrorBlock
                        style={{ marginTop: 0 }}
                        eType="div"
                        errorText={errors.name.message || ""}
                      />
                    )} */}
                  </div>
                </div>
                {videoQuizz && videoQuizz.length > 0 && (
                  <div className={styles.innerform__Group}>
                    <div className={styles.sec__sectionContainer}>
                      <div className={styles.sec__innerSectionItem}>
                        {videoQuizz.map((q, i) => (
                          <React.Fragment key={`videoQuizz${i}`}>
                            <div
                              className={
                                styles.sec__sectionItemEditableWithCtrl
                              }
                            >
                              <div
                                className={
                                  styles.sec__sectionItemEditableInput
                                }
                              >
                                <span className={styles.sec__no}>{`${
                                  i + 1
                                }.`}</span>
                                <div className={styles.sec__inputFeild}>
                                  <input
                                    onChange={(e) => {
                                      const tempVidQuiz = [...videoQuizz];
                                      tempVidQuiz[i].quizzName =
                                        e.target.value;
                                      setVideoQuizz(tempVidQuiz);
                                    }}
                                    value={q.quizzName}
                                  />
                                </div>
                              </div>
                              <span className={styles.sec__quesCount}>
                                {`${q.questionId.length} Question${
                                  q.questionId.length > 1 ? "s" : ""
                                }`}
                              </span>
                              <button
                                className={styles.sec__duration}
                                onClick={(e) => {
                                  e.preventDefault();
                                  SetTimerEdit({
                                    time: q.timeLine,
                                    min: 0,
                                    max: duration,
                                    name: q.quizzName
                                      ? q.quizzName
                                      : `Quiz ${i + 1}`,
                                    update: (t) => updateQuizTime(t, i),
                                    close: () => SetTimerEdit({ time: 0, min: 0, max: 0, name: "", update: () => null }),
                                  });
                                }}
                              >
                                {timeFormat(q.timeLine)} min
                              </button>
                              <IconButton
                                className={
                                  styles.sec__sectionItemEditIcon
                                }
                                aria-label={`Edit quiz ${i + 1}`}
                                component="span"
                                onClick={(e) => {
                                  e.preventDefault();
                                  addRemoveQuestion(
                                    q.questionId,
                                    q.quizzName
                                      ? q.quizzName
                                      : `Quiz ${i + 1}`,
                                    i
                                  );
                                }}
                              >
                                <BXedit size="24" color="#63606C" />
                              </IconButton>
                              <IconButton
                                className={
                                  styles.sec__sectionItemEditIcon
                                }
                                aria-label={`Delete quiz ${i + 1}`}
                                component="span"
                                onClick={(e) => {
                                  setMediaDelete({
                                    type: "quizDelete",
                                    index: i,
                                  });
                                  e.preventDefault();
                                }}
                              >
                                <BXTrash size="24" color="#7C7887" />
                              </IconButton>
                            </div>
                            {showError && errorNameList[i] && (
                              <span>
                                <ErrorBlock
                                  style={{ marginTop: "-8px" }}
                                  eType="div"
                                  errorText={`Please ${
                                    errorNameList[i].errorNames.includes(
                                      "questionLength"
                                    ) &&
                                    errorNameList[i].errorNames.includes(
                                      "quizName"
                                    )
                                      ? "enter name & add questions"
                                      : errorNameList[
                                          i
                                        ].errorNames.includes(
                                          "questionLength"
                                        )
                                      ? "add quiz questions"
                                      : "enter quiz name"
                                  }`}
                                />
                              </span>
                            )}
                          </React.Fragment>
                        ))}
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className={styles.addNewQuiz}></div>
            </div>
          )}
        </div>
        <div className={styles.vQuizFooter}>
          <div className="lt"></div>
          <div className="ce"></div>
          <div className="rt">
            <TervButtonOutlined text="Cancel" onClick={closeForm} />
            {errorNameList.length > 0 ? (
              <TervButtonFilled
                text="Add Content"
                disabled={duration === 0}
                onClick={(e) => {
                  e.preventDefault();
                  setShowError(true);
                }}
              />
            ) : (
              <TervButtonFilled
                text="Add Content"
                disabled={duration === 0}
                onClick={(e) => {
                  e.preventDefault();
                  submitForm();
                }}
              />
            )}
          </div>
        </div>
      </div>
      <EditTimer {...timerEdit} />
      {openAddQuestion && (
        <SelectQuestionsQPModalNew
          open={openAddQuestion}
          handleOpenClose={() => {
            handleOpenAddQuestion();
            setCurrBankQues([]);
          }}
          listQB={questionBanks}
          selectedQB={selectedQB}
          changeSelectedQB={(qb) => setSelectedQB(qb)}
          selectedQuestionId={selectedQuestionId}
          setSelectedQuestionId={(q) => setSelectedQuestionId(q)}
          selectedQuestionList={selectedQuestionList}
          setSelectedQuestionList={(list) =>
            setSelectedQuestionList(list)
          }
          initialValues={initialValues}
          sectionIndex={selectedQuizName}
          defaultQuestionType="MC"
        />
      )}
      <ConformDelete
        Header={
          mediaDelete.type === "videoDelete"
            ? "Delete video"
            : mediaDelete.type === "quizDelete"
            ? "Delete quiz"
            : ""
        }
        Content={() =>
          mediaDelete.type === "videoDelete" ? (
            <p style={paraStyle}>
              <div>Are you sure want to delete video?</div>
              <div>You cannot recover once deleted</div>
            </p>
          ) : mediaDelete.type === "quizDelete" ? (
            <p style={paraStyle}>
              <div>Are you sure want to delete quiz?</div>
              <div>You cannot recover once deleted</div>
            </p>
          ) : (
            ""
          )
        }
        isOpen={
          mediaDelete.type === "videoDelete" ||
          mediaDelete.type === "quizDelete"
        }
        handleClose={() => {
          setMediaDelete({
            type: "",
            index: null,
          });
        }}
        handleDelete={() => {
          if (mediaDelete.type === "videoDelete")
            setMediaDelete({
              type: "videoDeleteConform",
              index: mediaDelete.index,
            });
          if (mediaDelete.type === "quizDelete")
            setMediaDelete({
              type: "quizDeleteConform",
              index: mediaDelete.index,
            });
        }}
      />
    </div>
    </>
  );
};
export default VideoQuiz;