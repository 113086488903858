import React from 'react';
import styles from './SkeletonGridItem.module.scss';
import { CircularProgress } from '@material-ui/core';

function SkeletonGridItem({ gridPosition }) {
  return (
    <div
      className={styles.gridItem}
      style={{
        gridColumn: `${gridPosition?.[0]?.[0]} / ${gridPosition?.[0]?.[1]}`,
        gridRow: `${gridPosition?.[1]?.[0]} / ${gridPosition?.[1]?.[1]}`,
      }}>
         <CircularProgress className={styles.skeleton}/>
    </div>
  );
}

export default SkeletonGridItem;
