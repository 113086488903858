import React, { useState } from "react";
import {
  Card,
  CardBody,
  Container,
  Input,
  Button,
  Row,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Table,
} from "reactstrap";
import Breadcrumbs from "components/Common/Breadcrumb";
import Pie from "./ReportPie";
import moment from "moment";

const allNames = ["Assessments", "Assignments", "Courses"];

export default function ConsolidatedReport(props) {
  const [data, setData] = useState(props.location?.state?.data);

  const [assessmentCount, setAssessmentCount] = useState(data.ASSESSMENT_LIST);

  const [courseCount, setCourseCount] = useState(data.COURSE_LIST);
  const [assignmentCount, setAssignmentCount] = useState(data.ASSIGNMENT_LIST);

  const [allData, setallData] = useState([
    [
      { id: "Total", value: data.ASSESSMENT_COUNT.TotalNoOfAssessments },
      { id: "Completed", value: data.ASSESSMENT_COUNT.CompletedCount },
      { id: "Started", value: data.ASSESSMENT_COUNT.StartedCount },
      { id: "Not Started", value: data.ASSESSMENT_COUNT.NotStartedCount },
    ],
    [
      { id: "Total", value: data.ASSIGNMENT_COUNT.TotalNoOfAssignments },
      { id: "Submitted", value: data.ASSIGNMENT_COUNT.SubmittedCount },
      { id: "Declared", value: data.ASSIGNMENT_COUNT.DeclaredCount },
      { id: "Evaluated", value: data.ASSIGNMENT_COUNT.EvaluatedCount },
      { id: "Not Started", value: data.ASSIGNMENT_COUNT.NotStartedCount },
    ],
    [
      { id: "Total", value: data.COURSE_COUNT.TotalNoOfCourses },
      { id: "Completed", value: data.COURSE_COUNT.CompletedCount },
      { id: "In Progress", value: data.COURSE_COUNT.InProgressCount },
      { id: "Not Started", value: data.COURSE_COUNT.NotStartedCount },
    ],
  ]);

  const [modal, setModal] = useState(false);
  const toggle = () => {
    setModal(!modal);
  };
  const [modalData, setModalData] = useState({});

  const setCurrentData = (label, index) => {
    let tempData = {};
    switch (index) {
      case 0:
        tempData["name"] = "Assessments";
        tempData["label"] = label;
        if (label === "Total") {
          tempData["list"] = assessmentCount;
        } else if (label === "Not Started") {
          tempData["list"] = assessmentCount.filter(
            (a) => a.status === "NotStarted"
          );
        } else if (label === "Completed") {
          tempData["list"] = assessmentCount.filter(
            (a) => a.status === "Complete"
          );
        } else {
          tempData["list"] = assessmentCount.filter((a) => a.status === label);
        }
        break;
      case 1:
        tempData["name"] = "Assignments";
        tempData["label"] = label;
        if (label === "Total") {
          tempData["list"] = assignmentCount;
        } else if (label === "Not Started") {
          tempData["list"] = assignmentCount.filter(
            (a) => a.status === "NotStarted"
          );
        } else {
          tempData["list"] = assignmentCount.filter((a) => a.status === label);
        }
        break;
      case 2:
        tempData["name"] = "Courses";
        tempData["label"] = label;

        if (label === "Total") {
          tempData["list"] = courseCount;
        } else if (label === "In Progress") {
          tempData["list"] = courseCount.filter(
            (a) => a.status === "Inprogress"
          );
        } else {
          tempData["list"] = courseCount.filter((a) => a.status === label);
        }
        break;
    }
    setModalData(tempData);
    toggle();
  };

  return (
    <div className="page-content">
      <Container fluid>
        <Modal isOpen={modal} toggle={toggle} centered size="lg">
          <ModalHeader>
            {modalData.name} - {modalData.label}
          </ModalHeader>
          <ModalBody style={{ padding: 0 }}>
            <Table striped style={{ marginBottom: 0 }}>
              <thead>
                <tr>
                  <th style={{ textAlign: "center" }}>Name</th>
                  {modalData.name !== "Courses" && (
                    <th style={{ textAlign: "center" }}>Marks</th>
                  )}
                  {modalData.name === "Courses" && (
                    <>
                      <th style={{ textAlign: "center" }}>Progress</th>
                      <th style={{ textAlign: "center" }}>Duration(Hrs.)</th>
                    </>
                  )}

                  {modalData.name === "Courses" ||
                  modalData.name === "Assessments" ? (
                    <>
                      <th style={{ textAlign: "center" }}>
                        Start {modalData.name === "Courses" ? "Date" : "Time"}
                      </th>
                      <th style={{ textAlign: "center" }}>
                        End {modalData.name === "Courses" ? "Date" : "Time"}
                      </th>
                    </>
                  ) : (
                    ""
                  )}
                  {modalData.name === "Assessments" && (
                    <th style={{ textAlign: "center" }}>Time Taken (Mins.)</th>
                  )}
                  {modalData.name === "Assignments" && (
                    <>
                      <th style={{ textAlign: "center" }}>Files Submitted</th>
                      <th style={{ textAlign: "center" }}>Submitted Date</th>
                    </>
                  )}
                </tr>
              </thead>

              {modalData.list &&
                modalData.list.length > 0 &&
                modalData.list.map((res, ind) => (
                  <tbody>
                    <tr>
                      <td style={{ textAlign: "center" }}>{res.name}</td>
                      {modalData.name !== "Courses" && (
                        <td style={{ textAlign: "center" }}>{res.marks}</td>
                      )}
                      {modalData.name === "Courses" && (
                        <>
                          <td style={{ textAlign: "center" }}>
                            {res.progress}%
                          </td>
                          <td style={{ textAlign: "center" }}>
                            {res.courseDuration}
                          </td>
                        </>
                      )}

                      {modalData.name === "Courses" ||
                      modalData.name === "Assessments" ? (
                        <>
                          <td style={{ textAlign: "center" }}>
                            {res.startDate
                              ? moment(res.startDate).format("LLL")
                              : "Not Yet Started"}
                          </td>
                          <td style={{ textAlign: "center" }}>
                            {res.endDate
                              ? moment(res.endDate).format("LLL")
                              : "Not Yet Completed"}
                          </td>
                        </>
                      ) : (
                        ""
                      )}
                      {modalData.name === "Assessments" && (
                        <td style={{ textAlign: "center" }}>
                          {res.assessmentTimeTaken}
                        </td>
                      )}
                      {modalData.name === "Assignments" && (
                        <>
                          <td style={{ textAlign: "center" }}>
                            {res.numberOfFilesSubmitted}
                          </td>
                          <td style={{ textAlign: "center" }}>
                            {res.submittedDate
                              ? moment(res.submittedDate).format("LLL")
                              : "Not Yet Submitted"}
                          </td>
                        </>
                      )}
                    </tr>
                  </tbody>
                ))}
            </Table>
          </ModalBody>
          <ModalFooter>
            <Button onClick={toggle}>Close</Button>
          </ModalFooter>
        </Modal>
        <Breadcrumbs
          breadcrumbItem={props?.location?.state?.studentName}
          title="Overall Report"
        />
        <Card>
          <CardBody style={{ position: "relative" }}>
            <Button
              onClick={() => props.history.push("/ConsolidatedReportLogin")}
              outline
              color="link"
              style={{
                color: "blue",
                position: "absolute",
                top: 10,
                right: 10,
                border: "2px solid #a191be",
                borderRadius: "1.5em",
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-person"
                viewBox="0 0 16 16"
              >
                <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z" />
              </svg>{" "}
              Change Student
            </Button>
            <div style={{ display: "flex", alignItems: "center" }}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="50"
                height="50"
                fill="currentColor"
                className="bi bi-person-badge"
                viewBox="0 0 16 16"
              >
                <path d="M6.5 2a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zM11 8a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                <path d="M4.5 0A2.5 2.5 0 0 0 2 2.5V14a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2.5A2.5 2.5 0 0 0 11.5 0h-7zM3 2.5A1.5 1.5 0 0 1 4.5 1h7A1.5 1.5 0 0 1 13 2.5v10.795a4.2 4.2 0 0 0-.776-.492C11.392 12.387 10.063 12 8 12s-3.392.387-4.224.803a4.2 4.2 0 0 0-.776.492V2.5z" />
              </svg>
              <span style={{ marginLeft: 14 }}>
                {props?.location?.state?.studentName}
              </span>
            </div>
            <hr></hr>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {allData.map((j, i) => (
                <div style={{ height: 200, marginBottom: 150 }}>
                  <h4 style={{ color: "#a191be", textAlign: "center" }}>
                    {allNames[i]}
                  </h4>

                  <Pie data={j} viewDetails={setCurrentData} index={i}></Pie>
                </div>
              ))}
            </div>
          </CardBody>
        </Card>
      </Container>
    </div>
  );
}
