import { Button } from "reactstrap";
import * as CustomIcons from "../../assets/icons/icons";
import Style from "./ImageMobCropper.module.scss";
export function DropZonetextMob() {
  return (
    <>
      <p className={Style.dropzoneText}>Drag and drop an image here or click</p>
      <Button color="primary" className={Style.uploadNew}>
        {CustomIcons.uploadIcon} Upload new
      </Button>
    </>
  );
}
