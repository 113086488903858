import { useState, useEffect } from "react";
import { ResponsiveRadar } from "@nivo/radar";
import cn from "classnames";
import GridItem from "../../../components/GridItem/GridItem";
import SkeletonGridItem from "../../../components/SkeletonGridItem/SkeletonGridItem";
import ChartContainer from "../../../components/ChartContainer/ChartContainer";
import styles from "./Topics.module.scss";
import { useGetDashboardInfo } from "pages/Dashboard/SWRhooks";
import { TopicSample } from "./../../../SampleData";

const pointsViewOptions = [
  { name: "This Week", value: "this-week" },
  { name: "Past Week", value: "past-week" },
];

export function Topics({ showReal, isMobile }) {
  const [dataIsLoading, setIfDataIsLoading] = useState(false);
  const [pointsViewType, setPointsViewType] = useState("coding");
  const [codingPointsThisWeek, setCodingPointsThisWeek] = useState([]);
  const [codingPointsLastWeek, setCodingPointsLastWeek] = useState([]);
  const [aptitudePointsThisWeek, setAptitudePointsThisWeek] = useState([]);
  const [aptitudePointsLastWeek, setAptitudePointsLastWeek] = useState([]);
  const [pointsViewOption, setPointsViewOption] = useState(
    pointsViewOptions[0]
  );

  const apiResp = useGetDashboardInfo("getStudentDashBoardTopics", showReal);

  const setData = (data) => {
    setCodingPointsThisWeek(
      (data?.pointsScored?.["this-week"] || []).map(
        ({ coding, topicName }) => ({
          points: coding,
          topic: topicName,
        })
      )
    );
    setCodingPointsLastWeek(
      (data?.pointsScored?.["past-week"] || []).map(
        ({ coding, topicName }) => ({
          points: coding,
          topic: topicName,
        })
      )
    );
    setAptitudePointsThisWeek(
      (data?.pointsScored?.["this-week"] || []).map(
        ({ aptitude, topicName }) => ({
          points: aptitude,
          topic: topicName,
        })
      )
    );
    setAptitudePointsLastWeek(
      (data?.pointsScored?.["past-week"] || []).map(
        ({ aptitude, topicName }) => ({
          points: aptitude,
          topic: topicName,
        })
      )
    );
  };

  useEffect(() => {
    if (apiResp === "Fetching...") {
      setIfDataIsLoading(true);
    }
    if (apiResp && apiResp.hasOwnProperty("pointsScored")) {
      setData(apiResp);
      setIfDataIsLoading(false);
    }
  }, [apiResp]);

  useEffect(() => {
    setData(TopicSample);
  }, []);

  const hasData =
    !!codingPointsThisWeek.length ||
    !!codingPointsLastWeek.length ||
    !!aptitudePointsThisWeek.length ||
    !!aptitudePointsLastWeek.length;

  return dataIsLoading ? (
    <SkeletonGridItem
      gridPosition={[[1, isMobile ? -1 : 5], showReal ? [2, 3] : [3, 4]]}
    />
  ) : (
    <GridItem
      gridPosition={[[1, isMobile ? -1 : 5], showReal ? [2, 3] : [3, 4]]}
    >
      <ChartContainer
        currentOption={pointsViewOption.value}
        options={
          (pointsViewType === "coding"
            ? codingPointsLastWeek
            : aptitudePointsLastWeek
          ).length
            ? pointsViewOptions
            : undefined
        }
        onOptionChange={
          (pointsViewType === "coding"
            ? codingPointsLastWeek
            : aptitudePointsLastWeek
          ).length
            ? setPointsViewOption
            : undefined
        }
        title={
          <>
            <strong>Topics</strong>
          </>
        }
        contentClassName={styles.studentPointsChart}
      >
        <div className={styles.studentPointsViewChooser}>
          <button
            className={cn(styles.studentPointsViewButton, {
              [styles.active]: pointsViewType === "coding",
            })}
            onClick={() => {
              setPointsViewType("coding");
            }}
          >
            {"Coding"}
          </button>
          <button
            className={cn(styles.studentPointsViewButton, {
              [styles.active]: pointsViewType === "aptitude",
            })}
            onClick={() => {
              setPointsViewType("aptitude");
            }}
          >
            {"Aptitude"}
          </button>
        </div>
        <ResponsiveRadar
          data={
            pointsViewOption.value === "this-week"
              ? pointsViewType === "coding"
                ? codingPointsThisWeek
                : aptitudePointsThisWeek
              : pointsViewType === "coding"
              ? codingPointsLastWeek
              : aptitudePointsLastWeek
          }
          indexBy="topic"
          keys={["points"]}
          maxValue="auto"
          curve="linearClosed"
          colors={["#794DF5"]}
          borderWidth={2}
          borderColor="#794df5"
          gridLevels={2}
          gridShape="linear"
          gridLabelOffset={10}
          enableDots={true}
          dotSize={8}
          dotColor="#ffffff"
          dotBorderWidth={1}
          dotBorderColor="#794df5"
          enableDotLabel={false}
          fillOpacity={0.2}
          blendMode="multiply"
          animate={true}
          motionConfig="wobbly"
          isInteractive={true}
          margin={{ bottom: 20, top: 30 }}
        />
      </ChartContainer>
    </GridItem>
  );
}