import MasterForm from "./MasterForm";

export default function CreateSubDomain(props) {
  return (
    <MasterForm
      sub
      postLink="createDomain"
      label={{
        breadcrumb: "Sub Domain",
        back: "Sub Domains",
      }}
      backLink="/domain"
      location={{
        ...props.location,
      }}
      history={{
        ...props.history,
      }}
      match={{
        ...props.match,
      }}
      activePage={props?.location?.state?.activePage}
      itemsPerPage={props?.location?.state?.itemsPerPage}
    />
  );
}
