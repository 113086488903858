import { useMemo } from 'react';
import cn from 'classnames';
import RJSPagination from 'react-js-pagination';
import styles from './pagination.module.scss';
import {
  backWdIcon,
  fwdIcon,
} from "./../../assets/icons/icons";


export function Pagination({
  activePage,
  hideLastButton,
  itemsCountPerPage,
  noLegend,
  onChange,
  pageRangeDisplayed,
  showNextButton,
  totalItemsCount,
}) {
  const from = useMemo(() => (
    (activePage === 1) ? activePage : (((activePage - 1) * itemsCountPerPage) + 1)
  ), [activePage, itemsCountPerPage]);
  const to = useMemo(() => (
    (activePage === 1)
      ? itemsCountPerPage
      : ((activePage * itemsCountPerPage) > totalItemsCount)
        ? totalItemsCount
        : (activePage * itemsCountPerPage)
  ), [activePage, itemsCountPerPage]);

  return (
    <div className={styles.container}>
      <RJSPagination
        activeClass={styles.active}
        activePage={activePage}
        firstPageText={backWdIcon}
        // innerClass={cn(styles.pagination, {
        //   [styles.hideNext]: showNextButton,
        //   [styles.hideLast]: hideLastButton,
        // })}
        itemClass={styles.item}
        itemsCountPerPage={itemsCountPerPage}
        lastPageText={fwdIcon}
        nextPageText="Next"
        onChange={onChange}
        pageRangeDisplayed={pageRangeDisplayed}
        prevPageText="Back"
        totalItemsCount={totalItemsCount}
      />
      {/* {!noLegend && (
        <p className={styles.info}>
          {`Showing ${from} to ${to} of total ${totalItemsCount} entries`}
        </p>
      )} */}
    </div>
  );
}
