import React, { useState, useEffect } from "react";
import ComposeMailDumb from "./ComposeMailDumb";
import { Dialog, DialogContent } from "@material-ui/core";
import DialogContentText from "@material-ui/core/DialogContentText";
import { makeStyles } from "@material-ui/core/styles";
import SendMailDumb from "./SendMailDumb";
import api from "../../../api/baseConfig";
import apiHelper from "../../../api/apiHelper";
import { CloseOutlined } from "@material-ui/icons";
import { sessionPrivileges } from "privileges";
import Backdrop from "@material-ui/core/Backdrop";
import { GetAllUsers } from "../../CustomerManagement/utils/GetUsers";
import { parseRawStudentData } from "../../utils/ParseRawStudentData";
import { useSelector, connect } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import tickIcon from "./tickIcon.png";
import xIcon from "./xIcon.png";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "none",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  closeButton: {
    cursor: "pointer",
    position: "absolute",
    right: "8px",
    top: "8%",
    transform: "translateY(-50%)",
    "&:hover": {
      transform: "translateY(-50%) scale(1.5)",
    },
  },
}));

function ComposeMailSmart(props) {
  const userPrivileges = sessionPrivileges();
  const [customerStudents, setCustomerStudents] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [customerName, setCustomerName] = useState("");
  const [customBatchName, setCustomBatchName] = useState("");
  const [customer, setCustomer] = useState(props?.location?.state?.customer);
  const [type, setType] = useState("");
  const [scheduledTime, setScheduledTime] = useState("");
  const [scheduled, setScheduled] = useState(false);
  const [draft, setDraft] = useState(false);
  const [templates, setTemplates] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [showMailLoading, setShowMailLoading] = useState(false);
  const [attachmentName, setAttachmentName] = useState("");
  const [filType, setFilType] = useState("");
  const [showMailLoadingMsg, setShowMailLoadingMsg] = useState("");
  const [showCloseButton, setShowCloseButton] = useState(false);
  const [failed, setFailed] = useState(false);
  const [success, setSuccess] = useState(false);

  const classes = useStyles();

  const [activePart, setActivePart] = useState(1);

  const commType = [{ label: "Mail", value: "mail" }];

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [allFilterTypes, setAllFilterTypes] = useState([
    { label: "All", value: "all" },
    { label: "Only", value: "only" },
    { label: "Except", value: "except" },
    {
      label: "Custom Batch",
      value: "custom_batch",
    },
  ]);

  const activePage = props?.location?.state?.activePage;
  const itemsPerPage = props?.location?.state?.itemsPerPage;

  const adminStudents = useSelector((state) =>
    state.StudentAndCustomerReducer.UserStudents
      ? parseRawStudentData(
          state.StudentAndCustomerReducer.UserStudents.students
        )
      : []
  );

  const allCustomers = useSelector((state) =>
    state.StudentAndCustomerReducer.Customers
      ? state.StudentAndCustomerReducer.Customers.map((cus) => ({
          label: cus.name,
          value: cus.id,
        }))
      : []
  );

  useEffect(() => {
    if (userPrivileges.isSuperAdmin && customer) {
      getTemplates();
      // if(props?.location?.state?.edit){
      //   setActivePart(1)
      // }
    }
  }, [customer]);

  // useEffect(() => {
  //   if(scheduled === true) {
  //     sendMail();
  //   }
  // }, [scheduled])

  useEffect(() => {
    window.scrollTo(0, 0);

    if (userPrivileges.isSuperAdmin && props?.location?.state?.customer) {
      getStudentsByCustomer(props?.location?.state?.customer);
    }

    if (customerId) {
      getDepartments(customerId);
      getCustomerBatches(customerId);
    }

    if (userPrivileges.isAdmin) {
      getTemplates();
    }

    if (userPrivileges.isAdmin) {
      setCustomerName(sessionStorage.getItem("customer_name"));
    }

    if (props?.location?.state?.customerName) {
      setCustomerName(props?.location?.state?.customerName);
    }
  }, []);

  const getStudentsByCustomer = async (id) => {
    const users = await GetAllUsers(id);
    setCustomerStudents(parseRawStudentData(users));
  };

  const customerId = userPrivileges.isAdmin
    ? sessionStorage.getItem("customer_id")
    : props?.location?.state?.customer;

  const composeMail = async ({
    type,
    customerId,
    filterType,
    batchName,
    department,
    regNoFrom,
    regNoTo,
    userIdList,
  }) => {
    setActivePart(2);
    setType(type);
    setFilType(filterType);
    if (filterType === "custom_batch" || filterType === "only") {
      setSelectedStudents(userIdList);
    } else if (
      filterType !== "all" &&
      filterType !== "only" &&
      filterType !== "custom_batch" &&
      filterType !== "only"
    ) {
      setSelectedStudents(userIdList);
    }
    if (filterType === "custom_batch") {
      setCustomBatchName(batchName);
    }
  };

  const sendMail = async ({
    carbonCopy,
    updateStudentList,
    subject,
    mailMessage,
    mailAttachment,
  }) => {
    setShowMailLoading(true);
    let istdate = new Date(new Date().getTime() + (5 * 60 + 30) * 60000);
    let [date, restdata] = istdate.toISOString().split("T");
    let time = restdata.split(".")[0];
    let dateTime = date + "T" + time;

    const payLoad = {
      customer: customerName,
      type: type,
      to: "",
      cc: carbonCopy,
      bcc: updateStudentList?.length > 0 ? updateStudentList : selectedStudents,
      subject: subject,
      message: mailMessage,
      emailAttachment: [
        {
          filePath: mailAttachment,
          fileName: mailAttachment ? attachmentName : "",
        },
      ],
      html: true,
      send: !draft && !scheduled ? true : false,
      draft: draft ? true : false,
      scheduled: scheduled ? true : false,
      scheduledTime: scheduled ? scheduledTime : "",
      createdDate: dateTime,
      batchName: customBatchName,
      filterType: filType,
      // department:
      createdby: userPrivileges.userName,
    };
    setActivePart(2);
    try {
      const res = await apiHelper.axiosCallPost(
        `${api.baseURL}${api.communicationModule.sendEmail}`,
        "post",
        payLoad
      );
      if (res.success === true) {
        setSuccess(true);
        if (res.send === true) {
          setShowMailLoadingMsg("Your mail has been sent successfully");
          setTimeout(() => {
            setShowMailLoading(false);
            props.history.push({
              pathname: "/communication",
              state: { customer: props?.location?.state?.customer || "" },
            });
          }, 2000);
        }
        if (res.draft === true) {
          setShowMailLoadingMsg(
            "Your mail has been saved as draft successfully"
          );
          setTimeout(() => {
            setShowMailLoading(false);
            props.history.push({
              pathname: "/communication",
              state: { customer: props?.location?.state?.customer || "" },
            });
          }, 2000);
        }
        if (res.scheduled === true) {
          setShowMailLoadingMsg("Your mail has been scheduled successfully");
          setTimeout(() => {
            setShowMailLoading(false);
            props.history.push({
              pathname: "/communication",
              state: { customer: props?.location?.state?.customer || "" },
            });
          }, 2000);
        }
      }
      if (res.success === false) {
        setFailed(true);
        setShowMailLoadingMsg(res.responseId);
        setShowCloseButton(true);
      }
    } catch (error) {}
  };

  const checkDuplicate = async (name) => {
    if (props?.location?.state?.row?.name === name) return true;
    try {
      const res = await apiHelper.axiosCall(
        `${api.baseURL}${api.customBatchMgmt.checkDuplicate}${name}/${customerId}`
      );
      return !res || "This name is already in use. Please choose another name.";
    } catch (e) {
      return true;
    }
  };

  const getTemplates = async () => {
    try {
      const data = await apiHelper.axiosCall(
        userPrivileges.isSuperAdmin
          ? `${api.baseURL}${api.communicationModule.getTemplate}/?customerId=${customer}`
          : `${api.baseURL}${api.communicationModule.getTemplate}`
      );
      if (data) {
        setTemplates(data);
      }
    } catch (error) {}
  };

  const getDepartments = async (id) => {
    try {
      const data = await apiHelper.axiosCall(
        `${api.baseURL}${api.userManagement.getDepartments}${id}`
      );
      if (data) {
        setDepartments(
          data.reduce((acc, dep) => {
            if (dep)
              acc.push({
                label: dep,
                value: dep,
              });
            return acc;
          }, [])
        );
      }
    } catch (error) {}
  };

  const getCustomerBatches = async (customer) => {
    try {
      const data = await apiHelper.axiosCall(
        `${api.baseURL}${api.customerManagement.getBatches}${customer}`
      );
      const temp = allFilterTypes.filter(
        (item) =>
          item.value === "all" ||
          item.value === "only" ||
          item.value === "except" ||
          item.value === "custom_batch"
      );
      if (data && data.length > 0) {
        data.forEach((datum) => {
          if (datum?.batchName) {
            temp.push({
              label: datum.batchName,
              value: datum.batchName,
            });
          }
        });
      }
      setAllFilterTypes(temp);
    } catch (err) {}
  };

  return (
    <>
      {activePart === 1 && (
        <ComposeMailDumb
          checkDuplicate={checkDuplicate}
          composeMail={composeMail}
          // updateBatch={updateBatch}
          students={
            userPrivileges.isSuperAdmin ? customerStudents : adminStudents
          }
          adminStudents={adminStudents}
          editData={props?.location?.state}
          departments={departments}
          customer={customer}
          allCustomers={allCustomers}
          activePage={activePage}
          itemsPerPage={itemsPerPage}
          getStudentsByCustomer={getStudentsByCustomer}
          allFilterTypes={allFilterTypes}
          getCustomerBatches={getCustomerBatches}
          commType={commType}
          setCustomerName={setCustomerName}
          setCustomer={setCustomer}
          setSelectedStudents={setSelectedStudents}
          viewOnly={props?.location?.state?.type === "viewOnly"}
        />
      )}
      {activePart === 2 && (
        <SendMailDumb
          checkDuplicate={checkDuplicate}
          sendMail={sendMail}
          customerName={customerName}
          // updateBatch={updateBatch}
          students={
            userPrivileges.isSuperAdmin ? customerStudents : adminStudents
          }
          selectedStudents={selectedStudents}
          adminStudents={adminStudents}
          studentsCount={selectedStudents?.length}
          editData={props?.location?.state}
          departments={departments}
          customer={customer}
          allCustomers={allCustomers}
          activePage={activePage}
          itemsPerPage={itemsPerPage}
          getStudentsByCustomer={getStudentsByCustomer}
          allFilterTypes={allFilterTypes}
          getCustomerBatches={getCustomerBatches}
          commType={commType}
          scheduledTime={scheduledTime}
          setScheduledTime={setScheduledTime}
          setDraft={setDraft}
          setScheduled={setScheduled}
          viewOnly={props?.location?.state?.type === "viewOnly"}
          templates={templates}
          setAttachmentName={setAttachmentName}
          setShowMailLoadingMsg={setShowMailLoadingMsg}
        />
      )}
      {showMailLoading ? (
        <Dialog
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          onClose={handleClose}
          open={showMailLoading}
          closeAfterTransition
          BackdropComponent={Backdrop}
        >
          {showCloseButton && (
            <CloseOutlined
              classes={{ root: classes.closeButton }}
              onClick={() => setShowMailLoading(!showMailLoading)}
            />
          )}
          <DialogContent className={classes.paper}>
            <img
              src={
                success
                  ? tickIcon
                  : failed
                  ? xIcon
                  : "https://d2ndap9rlps54r.cloudfront.net/DefaultUploadImages/export.gif"
              }
              width="150px"
              style={{
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            />
            <DialogContentText id="alert-dialog-description">
              {failed ? (
                <div>
                  <p
                    style={{
                      fontSize: "18px",
                      fontWeight: "600",
                      textAlign: "center",
                      color: "#323036",
                    }}
                  >
                    Your action has been failed
                  </p>
                  <p
                    style={{
                      textAlign: "center",
                      maxWidth: "395px",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "16px",
                        fontWeight: "600",
                        color: "#323036",
                      }}
                    >
                      {"Reason: "}
                    </span>
                    <span
                      style={{
                        fontSize: "16px",
                        fontWeight: "600",
                      }}
                    >
                      {showMailLoadingMsg}
                    </span>
                  </p>
                </div>
              ) : (
                <p
                  style={{
                    fontSize: "18px",
                    fontWeight: "600",
                    textAlign: "center",
                  }}
                >
                  {showMailLoadingMsg}
                </p>
              )}
              <p style={{ fontSize: "16px", textAlign: "center" }}>
                We acknowledge for your valuable time.
              </p>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      ) : (
        ""
      )}
      <ToastContainer />
    </>
  );
}

export default connect(null, null)(ComposeMailSmart);
