import api from "../../../api/baseConfig";
import apiHelper from "../../../api/apiHelper";

export const findCustomerByDomainName = async (name) => {
  const url = `${api.baseURL}${api.homePage.findCustomerByDomainName}${name}`;
  const res = await apiHelper.axiosCall(url, "get");
  return res;
};

export const getServicesListWithSubService = async (customerId) => {
  const url = `${api.baseURL}${api.service.getServicesListWithSubService}${customerId}`;
  const res = await apiHelper.axiosCall(url, "get");
  return res;
};

export const getForm = async (formId) => {
  const url = `${api.baseURL}${api.service.getForm}${formId}`;
  const res = await apiHelper.axiosCall(url, "get");
  return res;
};

export const loginStudent = async (email, password) => {
  const url = `${api.baseURL}${api.service.studentLogin}username=${email}&password=${password}&grant_type=password`;
  const res = await apiHelper.login(url, {});
  return res;
};

export const regStudent = async (data) => {
  const url = `${api.baseURL}${api.service.studentRegister}`;
  const res = await apiHelper.axiosCall(url, "POST", data);
  return res;
};

export const verifyStudent = async (email, otp) => {
  const url = `${api.baseURL}${api.service.studentVerify}${email}/${otp}`;
  const res = await apiHelper.axiosCall(url, "POST");
  return res;
};

export const getUserApi = async (email) => {
  const url = `${api.baseURL}${api.service.getUserInfo}${email}`;
  const res = await apiHelper.axiosCall(url, "GET");
  return res;
};

export const saveUserRequest = async (data) => {
  const url = `${api.baseURL}${api.service.saveUserRequest}`;
  const res = await apiHelper.axiosCall(url, "POST", data);
  return res;
};

export const getServicePayment = async (data) => {
  const url = `${api.baseURL}${api.service.getPaymentAmount}${data}`;
  const res = await apiHelper.axiosCall(url, "GET");
  return res;
};

export const orderPayment = async (data) => {
  const url = `${api.baseURL}${api.service.paymentOrder}`;
  const res = await apiHelper.axiosCall(url, "POST", data);
  return res;
};

export const transactionPayment = async (data) => {
  const url = `${api.baseURL}${api.service.paymentTransaction}`;
  const res = await apiHelper.axiosCall(url, "POST", data);
  return res;
};

export const docsUpload = async (college, user, id, file) => {
  const url = `${api.baseURL}${api.service.docsUpload}customerName=${college}&moduleName=${user}&id=${id}`;
  const res = await apiHelper.axiosPostFile(url, file);
  return res;
};

export const resendOTP = async (userId) => {
  const url = `${api.baseURL}${api.user.resendOTP}${userId}`;
  const res = await apiHelper.axiosCall(url, "POST");
  return res;
};
export const getServiceRequest = async (id) => {
  const url = `${api.baseURL}${api.service.getServiceRequest}${id}`;
  const res = await apiHelper.axiosCall(url, "get");
  return res;
};

export const getBasePdf = async (data) => {
  const url = `${api.baseURL}${api.Prepare.getBasePdf}`;
  const res = await apiHelper.axiosCallPost(url, "POST", data);
  return res;
};
