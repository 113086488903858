import React from "react"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import {
  Container,
  Row,
  Col,
  Label,
  Input,
  FormGroup,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Card,
  CardBody,
  CardTitle,
  CardText,
} from "reactstrap"
import api from "../../api/baseConfig"
import Apihelper from "../../api/apiHelper"
import ReactHtmlParser from "html-react-parser"

export default class LabQuestions extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      question: [],
      labId: "",
      userId: sessionStorage.getItem("user_id")
        ? sessionStorage.getItem("user_id")
        : "10001",
    }
  }

  async componentDidMount() {
    if (
      this.props &&
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.fromEdittrue === true
    ) {
      this.setState({
        labId: this.props.location.state.id,
      })
    }

    let questions = []
    try {
      Apihelper.axiosCallPostLab(
        `${api.baseURL}${api.lab.userPracticeLab}${this.props.location.state.id}/${this.state.userId}`,
        "get"
      ).then(data => {
        let d = []
        data.questionsList.forEach(element => {
          d.push(element)
        })
        this.setState({
          question: d,
          labId: data.id,
        })
      })
    } catch (e) {}
  }
  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs title="Assessments" breadcrumbItem="Lab Questions" />
            <div>
              {this.state.question.map((q, ind) => (
                <Card key={ind}>
                  <CardBody>
                    <CardTitle tag="h5">
                      {ReactHtmlParser(q.question || "")}
                    </CardTitle>
                    <CardText>
                      <strong>Description: </strong>
                      {ReactHtmlParser(q.description || "")}
                    </CardText>
                    <CardText>
                      <strong>Type: </strong>
                      {ReactHtmlParser(q.type || "")}
                    </CardText>

                    <Button
                      style={{ backgroundColor: "#EAB543", border: "none" }}
                      onClick={() => {
                        q.labId = this.state.labId
                        this.props.history.push({
                          pathname: "/codeLab",
                          state: q,
                        })
                      }}
                    >
                      Select
                    </Button>
                  </CardBody>
                </Card>
              ))}
            </div>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}
