import React from "react";
import cn from "classnames";
import styles from "./DataTablePractice.module.scss";
import { useTranslation } from "react-i18next";

function DataTablePractice({
  actionBox,
  children,
  alignTabCenter,
  className,
  description,
  footerLinkOnClick,
  footerLinkText,
  title,
  isDisabled,
}) {
  const { t } = useTranslation();
  return (
    <div
      className={cn(styles.container, className, {
        [styles.containerDisabled]: isDisabled,
      })}
    >
      <div
        className={cn(styles.header, {
          [styles.hasDescription]: !!description,
        })}
      >
        <div className={styles.headerTextContainer}>
          <h3 className={styles.title}>{title}</h3>
          {description && (
            <div className={styles.description}>{description}</div>
          )}
        </div>
      </div>
      <div className={styles.content2}>{children}</div>
      {footerLinkText && footerLinkOnClick && (
        <div className={styles.footer2}>
          <button
            className={styles.footerLink}
            onClick={() => {
              footerLinkOnClick();
            }}
          >
            {t(footerLinkText)}
          </button>
        </div>
      )}
    </div>
  );
}

export default DataTablePractice;
