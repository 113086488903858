import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import questions from "./question/reducer";
import StudentAndCustomerReducer from "./StudentAndCustomer/reducers";
import UserProfileReducer from "./UserProfile/reducer";
import LoggedInReducer from "./loggedIn/reducer";
import SideBarReducer from "./SideMenu/reducer";
import TourReducer from "./Tour/reducer";

const rootReducer = combineReducers({
  StudentAndCustomerReducer,
  questions,
  UserProfileReducer,
  LoggedInReducer,
  SideBarReducer,
  TourReducer,
  form: formReducer,
});
export default rootReducer;
