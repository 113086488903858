import api from "api/baseConfig";
import apiHelper from "api/apiHelper";
import { sessionPrivileges } from "privileges";

export const GetAllCourses = async (customerId) => {
  const url = `${api.baseURL}${api.Prepare.getAllByCustomer}${customerId}`;
  try {
    const data = await apiHelper.axiosCall(url, "get");
    return data;
  } catch (error) {
    throw new Error(`Some error occurred: ${error}`);
  }
};

export const getAllPractices = async (customerId) => {
  const url = `${api.baseURL}${api.lab.getFreePracticeLabByCustomer}${customerId}`;
  try {
    const data = await apiHelper.axiosCall(url, "get");
    if (data) {
      const temp = data.reduce((acc, dep) => {
        if (dep)
          acc.push({
            label: dep.name,
            value: dep.id,
          });

        return acc;
      }, []);
      return temp;
    }
  } catch (error) {
    throw new Error(`Some error occurred: ${error}`);
  }
};

export const getAssessmentsByCustomer = async (id) => {
  try {
    const data = await apiHelper.axiosCall(
      `${api.baseURL}${api.assessmentController.getAssessmentbyCustomer}${id}`,
      "get"
    );
    if (data) {
      const temp = data.reduce((acc, dep) => {
        if (dep)
          acc.push({
            label: dep.name,
            value: dep.id,
          });
        return acc;
      }, []);
      return temp;
    }
  } catch (error) {
    console.log(`${error} error`);
  }
};

export const CheckIfCourseNameExists = async (name, customerId) => {
  // const Priv = sessionPrivileges().userprivileges || [];
  // const customerId = Priv.includes("MANAGE_CUSTOMER")
  //   ? values?.collegeId
  //   : sessionStorage.getItem("customer_id");
  // ...(userPrivileges.isAdmin && {
  //   customerId: sessionStorage.getItem("customer_id"),
  // }),
  const userPrivileges = sessionPrivileges();
  const customer_id = sessionStorage?.getItem("customer_id") ;
  const encodedName = window.btoa(name);
  const url = `${api.baseURL}${
    api.Prepare.courseNameCheck
  }${encodedName}/${ userPrivileges.isAdmin ? customer_id  : customerId}`;

  try {
    const res = await apiHelper.axiosCall(url, "get");
    return res;
  } catch (error) {}
};

export const GetCourse = async (url) => {
  try {
    const data = await apiHelper.axiosCall(url, "get");
    return data;
  } catch (error) {
    throw new Error(`Some error occurred: ${error}`);
  }
};

export const DeleteCourse = async (courseId) => {
  try {
    await apiHelper.axiosCallPost(
      `${api.baseURL}${api.Prepare.deleteCourse}${courseId}`,
      "delete"
    );
  } catch (error) {
    throw new Error(`Some error occurred: ${error}`);
  }
};

export const GetCourseModules = async (courseId) => {
  const url = `${api.baseURL}${api.Prepare.getAllModules}${courseId}`;
  try {
    const data = await apiHelper.axiosCall(url, "get");
    return data;
  } catch (error) {
    throw new Error(`Some error occurred: ${error}`);
  }
};

export const CreateOrUpdate = async (formValues) => {
  const url = `${api.baseURL}${api.Prepare.create}`;
  const userPrivileges = sessionPrivileges();
  const payload = {
    ...formValues,
    active: true,
    createdBy: userPrivileges?.userName,
    updatedBy: userPrivileges?.userName,
    createdDate: new Date(),
    updatedDate: new Date(),
    category: formValues?.category?.label,
    categoryId: formValues?.category?.value,
  };
  try {
    const data = await apiHelper.axiosCallPost(url, "post", payload);
    return data;
  } catch (error) {
    throw new Error(`Some error occurred: ${error}`);
  }
};

export const SaveCourseSections = async (payload) => {
  const url = `${api.baseURL}${api.Prepare.saveSections}`;

  try {
    const data = await apiHelper.axiosCallPost(url, "post", payload);
    return data;
  } catch (error) {
    throw new Error(`Some error occurred: ${error}`);
  }
};

export const UpdateOverviews = async (formValues) => {
  const url = `${api.baseURL}${api.Prepare.saveOverview}`;
  const userPrivileges = sessionPrivileges();
  const payload = {
    ...formValues,
    updatedBy: userPrivileges.userId,
    updatedDate: new Date(),
  };
  try {
    const data = await apiHelper.axiosCallPost(url, "post", payload);
    return data;
  } catch (error) {
    throw new Error(`Some error occurred: ${error}`);
  }
};

export const UpdateSettings = async (formValues) => {
  const url = `${api.baseURL}${api.Prepare.saveSettings}`;
  const userPrivileges = sessionPrivileges();
  const payload = {
    ...formValues,
    updatedBy: userPrivileges.userId,
    updatedDate: new Date(),
  };
  try {
    const data = await apiHelper.axiosCallPost(url, "post", payload);
    return data;
  } catch (error) {
    throw new Error(`Some error occurred: ${error}`);
  }
};

export const UpdatePricing = async (formValues) => {
  const url = `${api.baseURL}${api.Prepare.savePricings}`;
  const userPrivileges = sessionPrivileges();
  const payload = {
    ...formValues,
    updatedBy: userPrivileges.userId,
    ...(userPrivileges.isAdmin && {
      customerId: sessionStorage.getItem("customer_id"),
    }),
  };
  try {
    const data = await apiHelper.axiosCallPost(url, "post", payload);
    return data;
  } catch (error) {
    throw new Error(`Some error occurred: ${error}`);
  }
};

export const UpdateStatus = async (payload) => {
  const url = `${api.baseURL}${api.Prepare.updateStatus}`;

  try {
    const data = await apiHelper.axiosCallPost(url, "post", payload);
    return data;
  } catch (error) {
    throw new Error(`Some error occurred: ${error}`);
  }
};

export const GetCertificateTemplateList = async (customerId) => {
  let options = [];
  const url = `${api.baseURL}${api.Prepare.getCertificateTemplateList}${customerId}`;

  try {
    await apiHelper.axiosCall(url, "get").then((data) => {
      if (data) {
        data.forEach((element) => {
          options.push({
            label: element.name,
            value: element.media,
          });
        });
      }
    });
    return options;
  } catch (error) {
    throw new Error(`Some error occurred: ${error}`);
  }
};
