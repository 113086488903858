import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import { TervButtonFilled } from "components/TervButtons";

const DeleteModal = ({ isOpen, onClose, onInvoke }) => {
  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogContent style={{minHeight: 100, fontSize: 16, }}>Are you sure you want to delete this?</DialogContent>
      <DialogActions>
        <TervButtonFilled text="Cancel" onClick={onClose} />
        <TervButtonFilled
          text="Done"
          onClick={() => {
            onInvoke();
            onClose();
          }}
        />
      </DialogActions>
    </Dialog>
  );
};

export default DeleteModal;
