import * as CustomIcons from "../../../assets/icons/icons";
import Style from "./AllManualGrading.module.scss";
import Avatar from "../../../assets/images/users/default-avatar.svg";
import moment from "moment";

const GradingHeader = ({ student, status }) => {
  const currentStudent = student?.allStudents?.find((stu) => (
    stu.id === student.id
  ))
  return (
    <div className={Style.topSection}>
      <div className={Style.flexOne}>
        <h2 className={Style.title}>{student.assessmentName}</h2>
        <span className={Style.sub}>
          &nbsp;{CustomIcons.bull}&nbsp;{student.department}
        </span>
        <span className={Style.sub}>
          &nbsp;Completed on {moment.utc(student.completedOn).format("LLL")}
        </span>
      </div>
      <div style={{ backgroundColor: "#eee", height: "0.04rem" }} />

      <div className={Style.flexTwo}>
        <img src={Avatar} alt="profile-dp" className={Style.avatar} />
        <span className={Style.name}>{student.name}</span>
        <span className={Style.sub}>
          &nbsp;{CustomIcons.bull}&nbsp;Grading status -
        </span>
        {
          status ? (
            <span className={Style.published}>Completed</span>
            ) : (
            <span className={Style.pending}>Pending</span>
          )
        }
      </div>
    </div>
  );
};

export default GradingHeader;
