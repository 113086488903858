import React, { useState, useEffect, useCallback } from "react";
import cn from "classnames";
import moment from "moment";
import { ResponsiveBar } from "@nivo/bar";
import { ResponsivePie } from "@nivo/pie";
import { Button } from "reactstrap";
import {
  Collapse,
  Grid,
  CircularProgress as MuiCircularProgress,
  withStyles,
} from "@material-ui/core";
import {
  ArrowBack,
  AccountBalance,
  SettingsEthernet,
  People,
  SignalCellularAlt,
} from "@material-ui/icons";
import { Skeleton } from "@material-ui/lab";
import { Icon } from "@iconify/react";
import treeStructure from "@iconify/icons-flat-color-icons/tree-structure";
import electroDevices from "@iconify/icons-flat-color-icons/electro-devices";
import alarmClock from "@iconify/icons-flat-color-icons/alarm-clock";
import ideaIcon from "@iconify/icons-flat-color-icons/idea";
import roundDateRange from "@iconify/icons-ic/round-date-range";
import Apihelper from "../../api/apiHelper";
import { trackActivity } from "../../api";
import api from "../../api/baseConfig";
import Card from "./report-components/card";
import Table from "./report-components/table";
import ReportIcon from "./report-assets/report-icon";
import styles from "./AssessmentReport.module.scss";
import SummaryTable from "./report-components/summaryTable";
import ViewSolutionModal from "./report-components/viewSolutionModal";
import { useMediaQuery } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  select: {
    padding: "5px 0",
    "&:before": {
      borderBottom: "3px solid #794DF5",
    },
    "&:after": {
      borderBottom: "3px solid #794DF5",
    },
  },
}));

const correctIcon = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 10C0 7.34784 1.05357 4.8043 2.92893 2.92893C4.8043 1.05357 7.34784 0 10 0C12.6522 0 15.1957 1.05357 17.0711 2.92893C18.9464 4.8043 20 7.34784 20 10C20 12.6522 18.9464 15.1957 17.0711 17.0711C15.1957 18.9464 12.6522 20 10 20C7.34784 20 4.8043 18.9464 2.92893 17.0711C1.05357 15.1957 0 12.6522 0 10H0ZM9.42933 14.28L15.1867 7.08267L14.1467 6.25067L9.23733 12.3853L5.76 9.488L4.90667 10.512L9.42933 14.2813V14.28Z"
      fill="#5AC62F"
    />
  </svg>
);
const wrongIcon = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.99977 0.759964C7.54917 0.759964 5.19894 1.73346 3.4661 3.4663C1.73326 5.19913 0.759766 7.54936 0.759766 9.99996C0.759766 12.4506 1.73326 14.8008 3.4661 16.5336C5.19894 18.2665 7.54917 19.24 9.99977 19.24C12.4504 19.24 14.8006 18.2665 16.5334 16.5336C18.2663 14.8008 19.2398 12.4506 19.2398 9.99996C19.2398 7.54936 18.2663 5.19913 16.5334 3.4663C14.8006 1.73346 12.4504 0.759964 9.99977 0.759964ZM15.2677 13.3671L13.3658 15.269L9.99977 11.9019L6.63267 15.2679L4.73077 13.366L8.09897 9.99996L4.73187 6.63286L6.63377 4.73206L9.99977 8.09806L13.3669 4.73096L15.2688 6.63286L11.9006 9.99996L15.2677 13.3671Z"
      fill="#E22B12"
    />
  </svg>
);
const emptyIcon = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="1"
      y="1"
      width="18"
      height="18"
      rx="4"
      stroke="#848484"
      strokeWidth="2"
    />
  </svg>
);

const circularProgressRootStyles = {
  left: "50%",
  position: "absolute",
  top: "50%",
  transform: "rotate(-90deg) translate(50%, -50%) !important",
};

const BgCircularProgress = withStyles({
  root: circularProgressRootStyles,
  circle: {
    stroke: "#F0EBFF",
  },
})(MuiCircularProgress);
const TotalScoreCircularProgress = withStyles({
  root: circularProgressRootStyles,
  circle: {
    stroke: "#3548D3",
    strokeLinecap: "round",
  },
})(MuiCircularProgress);
const RightScoreCircularProgress = withStyles({
  root: circularProgressRootStyles,
  circle: {
    stroke: "#5AC62F",
    strokeLinecap: "round",
  },
})(MuiCircularProgress);
const WrongScoreCircularProgress = withStyles({
  root: circularProgressRootStyles,
  circle: {
    stroke: "#E22B12",
    strokeLinecap: "round",
  },
})(MuiCircularProgress);

function InsightBox({ title, green, red, blue, description }) {
  return (
    <div
      className={cn(styles.scoreSummaryInsightBox, {
        [styles.green]: green,
        [styles.red]: red,
        [styles.blue]: blue,
      })}
    >
      <h3>{title}</h3>
      <h1>{description}</h1>
    </div>
  );
}
function InsightBoxMobile({ title, green, red, blue, description }) {
  return (
    <div
      className={cn(styles.scoreSummaryInsightBoxMobile, {
        [styles.green]: green,
        [styles.red]: red,
        [styles.blue]: blue,
      })}
    >
      <h3>{title}</h3>
      <h1>{description}</h1>
    </div>
  );
}

function ValueOutside({ bars }) {
  return bars.map((bar) => {
    const {
      key,
      width,
      height,
      x,
      y,
      data: { value },
    } = bar;

    return (
      <g key={key} transform={`translate(${x}, ${y})`}>
        <text
          color="#3A3A3A"
          fontSize="14px"
          textAnchor="middle"
          transform={`translate(${width + 25}, ${height / 1.65})`}
        >
          {value}
        </text>
      </g>
    );
  });
}

function ImprovementBox({
  title,
  icon,
  buttonText,
  onButtonClick,
  description,
  type,
}) {
  const [isCollapsed, setCollapse] = useState(true);
  const smallScreen = useMediaQuery("(max-width: 767px)");

  return (
    <div
      className={cn(styles.improvementBox, {
        [styles.collapsed]: isCollapsed,
        [styles.poor]: type === "poor",
        [styles.moderate]: type === "medium",
        [styles.good]: type === "Good",
      })}
    >
      <div
        className={
          smallScreen ? styles.titleContainerMobile : styles.titleContainer
        }
        onClick={() => {
          setCollapse((c) => !c);
        }}
      >
        <div className={styles.titleTextContainer}>
          <div className={styles.titleIconContainer}>
            <Icon className={styles.titleIcon} icon={icon} />
          </div>
          <h2 className={styles.improvementBoxTitleText}>{title}</h2>
        </div>

        <div className={styles.type}>{type}</div>
      </div>
      <Collapse in={!isCollapsed}>
        <div className={styles.content}>
          <div className={styles.description}>{description}</div>
          <Button color="primary" onClick={onButtonClick}>
            {buttonText}
          </Button>
        </div>
      </Collapse>
    </div>
  );
}

export default function AssessmentReport(props) {
  const [data, setData] = useState(null);
  const [currentTab, setCurrentTab] = useState("score-summary");
  const [sectionDetails, setSectionDetails] = useState([]);
  const [friendsHeaderDetails, setFriendsHeaderDetails] = useState([]);
  const [friendsDetails, setFriendsDetails] = useState([]);
  const [collegeDetails, setCollegeDetails] = useState([]);
  const [sectionTab, setSectionTab] = useState({});
  const [questionList, setQuestionList] = useState([]);
  const [viewModal, setViewModal] = useState(false);
  const [questionData, setQuestionData] = useState({});
  const [loading, setLoading] = useState(false);
  const [sectionSumaryloading, setSectionSumaryloading] = useState(false);
  const [sectionPerformanceLoading, setSectionPerformanceLoading] =
    useState(false);
  const [performanceLoading, setPerformanceLoading] = useState(false);
  const [improvementLoading, setImprovementLoading] = useState(false);
  const [sectionPerformance, setSectionPerformance] = useState(true);
  const [tabName, setTabName] = useState();
  const [inputData, setInputData] = useState();
  const [assessmentScoreSummary, setAssessmentScoreSummary] = useState();
  const [sectionPerformanceData, setSectionPerformanceData] = useState();
  const [performanceSummary, setPerformanceSummary] = useState();
  const [improvementAreaData, setImprovementAreaData] = useState();

  const handleChangeNew = (event) => {
    setInputData(event.target.value);
    setCurrentTab(event.target.value);
  };
  const handleChangeData = (event) => {
    setQuestionList(event.target.value);
  };

  const smallScreen = useMediaQuery("(max-width: 767px)");
  const tableData = (questionList = []) => {
    const row = [];
    questionList.forEach((list, i) => {
      row.push({
        data: [
          i + 1,
          list.questionName,
          list.status === "wrong"
            ? wrongIcon
            : list.status === "correct"
            ? correctIcon
            : emptyIcon,
          list.difficulty,
          list.completedTime,
          `${list.average}%`,
        ],
        modal: list,
      });
    });
    setQuestionList(row);
  };

  const handleChange = (event) => {
    setTabName(event.target.value);
  };

  const getAssessmentScoreSummary = async () => {
    const userId = sessionStorage.getItem("user_id");
    setSectionSumaryloading(true);
    async function fetch() {
      await Apihelper.axiosCall(
        `${api.baseURL}${api.assessmentController.getAssessmentSectionSummary}${props.location?.params?.assessmentId}/${userId}${props?.location?.search}`,
        "get"
      ).then((d) => {
        trackActivity(
          props.location?.params?.assessmentId,
          d?.assessmentName,
          "viewed-assessment-report"
        );
        setSectionSumaryloading(false);
        setData(d);
        //question-summary
        if (d && d.sectionSummary && d.sectionSummary.length) {
          const apiQuestionList = d?.sectionSummary;
          const questionList = apiQuestionList[0]?.questionSummary;
          tableData(questionList);
          setSectionTab(0);
        }
      });
    }
    fetch();
  };

  useEffect(() => {
    setLoading(true);
    async function fetch() {
      await Apihelper.axiosCall(
        `${api.baseURL}${api.assessmentController.getAssessmentScoreSummary}${props.location?.params?.assessmentId}${props?.location?.search}`,
        "get"
      ).then((d) => {
        getAssessmentScoreSummary();
        trackActivity(
          props.location?.params?.assessmentId,
          d?.assessmentName,
          "viewed-assessment-report"
        );
        setLoading(false);
        setData(d);

        //question-summary
        if (d && d.sectionSummary && d.sectionSummary.length) {
          const apiQuestionList = d?.sectionSummary;
          const questionList = apiQuestionList[0]?.questionSummary;
          tableData(questionList);
          setSectionTab(0);
        }
      });
    }
    fetch();
  }, []);

  const getAssessmentSectionPerformance = async () => {
    if (sectionPerformanceData == undefined) {
      setSectionPerformanceLoading(true);
      async function fetch() {
        await Apihelper.axiosCall(
          `${api.baseURL}${api.assessmentController.getAssessmentSectionPerformance}${props.location?.params?.assessmentId}${props?.location?.search}`,
          "get"
        ).then((d) => {
          trackActivity(
            props.location?.params?.assessmentId,
            d?.assessmentName,
            "viewed-assessment-report"
          );
          setSectionPerformanceLoading(false);
          setSectionPerformanceData(d);
          //question-summary
          if (d && d.sectionSummary && d.sectionSummary.length) {
            const apiQuestionList = d?.sectionSummary;
            const questionList = apiQuestionList[0]?.questionSummary;
            tableData(questionList);
            setSectionTab(0);
          }
        });
      }
      fetch();
    }
  };

  const getAssessmentPerformanceSummary = async () => {
    if (performanceSummary == undefined) {
      setPerformanceLoading(true);
      async function fetch() {
        await Apihelper.axiosCall(
          `${api.baseURL}${api.assessmentController.getAssessmentPerformanceSummary}${props.location?.params?.assessmentId}${props?.location?.search}`,
          "get"
        ).then((d) => {
          trackActivity(
            props.location?.params?.assessmentId,
            d?.assessmentName,
            "viewed-assessment-report"
          );
          setPerformanceLoading(false);
          setPerformanceSummary(d);
          //section-list
          const apiSectionList = d?.sections || [];
          const sectionDetailsList = [];
          const sectionDetailsInfo = [];
          const totalSectionDetails = {
            data: [null, "Total", 0, 0, 0, 0, 0, 0],
            highlight: true,
          };
          apiSectionList.forEach(
            (
              {
                id,
                numberOfQuestions,
                rightAnswerCount,
                wrongAnswerCount,
                time,
                score,
                outOf,
                name,
              },
              idx
            ) => {
              sectionDetailsInfo.push({ id, name });
              sectionDetailsList.push({
                data: [
                  idx + 1,
                  name,
                  numberOfQuestions,
                  rightAnswerCount,
                  wrongAnswerCount,
                  ((time || 0) / 60)?.toFixed(0),
                  `${parseFloat(score)?.toFixed(2)}%`,
                  `${outOf?.toFixed(0)}%`,
                ],
              });
              totalSectionDetails.data[2] += numberOfQuestions;
              totalSectionDetails.data[3] += rightAnswerCount;
              totalSectionDetails.data[4] += wrongAnswerCount;
              totalSectionDetails.data[5] += (parseFloat(time) || 0) / 60;
              totalSectionDetails.data[6] += score;
              totalSectionDetails.data[7] += outOf;
            }
          );
          totalSectionDetails.data[5] = parseFloat(
            totalSectionDetails.data[5]
          )?.toFixed(0);
          totalSectionDetails.data[6] = `${parseFloat(
            totalSectionDetails.data[6]
          )?.toFixed(2)}%`;
          totalSectionDetails.data[7] = `${parseFloat(
            totalSectionDetails.data[7]
          )?.toFixed(0)}%`;
          sectionDetailsInfo
            .sort(({ id: id1 }, { id: id2 }) => id2 > id1)
            .sort(({ name: n1 }, { name: n2 }) => n2 > n1);
          setSectionDetails([...sectionDetailsList, totalSectionDetails]);

          const apiFriendsList = d?.topperScore || [];
          const friendsDetailsList = [];
          let friendsDetailsHeaderList = ["Rank", "Name"];
          apiFriendsList
            .sort(({ rank: r1 }, { rank: r2 }) => r1 - r2)
            .forEach(
              ({
                rank,
                currentStudent,
                firstName,
                lastName,
                sectionReport,
                completedTime,
                score,
              }) => {
                friendsDetailsList.push({
                  data: [
                    rank,
                    `${firstName || ""} ${lastName || ""}`,
                    ...(sectionReport || [])
                      .sort(({ id: id1 }, { id: id2 }) => id2 > id1)
                      .sort(({ name: n1 }, { name: n2 }) => n2 > n1)
                      .map(({ score: sectionScore }) => {
                        return parseFloat(sectionScore)?.toFixed(2);
                      }),
                    completedTime ? parseFloat(completedTime / 60) : null,
                    parseFloat(score)?.toFixed(2),
                  ],
                  highlight: currentStudent,
                });
              }
            );
          friendsDetailsHeaderList = friendsDetailsHeaderList
            .concat(sectionDetailsInfo.map(({ name }) => name))
            .concat(["Completed time(min)", "Score"]);
          setFriendsHeaderDetails(friendsDetailsHeaderList);
          setFriendsDetails(friendsDetailsList);
          setCollegeDetails(
            (d?.collegeScore || []).map(
              ({ rank, collegeName, score, currentCollege }) => ({
                data: [
                  rank,
                  currentCollege ? "Your College" : collegeName,
                  `${parseFloat(score)?.toFixed(2)}%`,
                ],
                highlight: currentCollege,
              })
            )
          );
        });
      }
      fetch();
    }
  };

  const getAssessmentImprovementArea = async () => {
    if (improvementAreaData == undefined) {
      setImprovementLoading(true);
      async function fetch() {
        await Apihelper.axiosCall(
          `${api.baseURL}${api.assessmentController.getAssessmentImprovementAreas}${props.location?.params?.assessmentId}${props?.location?.search}`,
          "get"
        ).then((d) => {
          trackActivity(
            props.location?.params?.assessmentId,
            d?.assessmentName,
            "viewed-assessment-report"
          );
          setImprovementLoading(false);
          setImprovementAreaData(d);
        });
      }
      fetch();
    }
  };

  const toggleViewModal = () => setViewModal((p) => !p);
  const classes = useStyles();
  const totalRightAnswerAverage =
    (data?.totalRightAnswer / data?.totalNumberOfQuestion) * 100;
  const totalWrongAnswerAverage =
    (data?.totalWrongAnswer / data?.totalNumberOfQuestion) * 100;
  return (
    <div className={styles.page}>
      {loading ? (
        <Skeleton
          animation="wave"
          height={105}
          width="426px"
          style={{ margin: "0 auto" }}
        />
      ) : (
        <div className={styles.titleBox}>
          <div className={styles.title}>
            <ReportIcon />
            <span className={styles.titleText}>{data?.assessmentName}</span>
          </div>
          {data?.assessmentCompletedOn && (
            <div className={styles.completionDetailsContainer}>
              <Icon
                icon={roundDateRange}
                style={{ color: "#5c5f65", fontSize: "16px" }}
              />
              <div className={styles.completionDetails}>
                {`Completed on ${moment(data?.assessmentCompletedOn).format(
                  "DD/MM/YYYY, hh:mmA"
                )}`}
              </div>
            </div>
          )}
        </div>
      )}

      {loading ? (
        <Skeleton
          animation="wave"
          height={52}
          width="858px"
          style={{ margin: "0 auto" }}
        />
      ) : smallScreen ? (
        <FormControl
          className={classes.formControl}
          style={{ padding: "10px 0px" }}
        >
          <Select
            native
            value={inputData}
            onChange={handleChangeNew}
            className={classes.select}
            inputProps={{
              name: "age",
              id: "age-native-simple",
            }}
          >
            <option value="score-summary">{"Score summary"}</option>
            <option value="section-performance">{"Section performance"}</option>
            <option value="performance-summary">{"performance Summary"}</option>
            <option value="improvement-areas">{"improvement Areas"}</option>
          </Select>
        </FormControl>
      ) : (
        <div className={styles.tabbar}>
          <Button
            color={currentTab === "score-summary" ? "primary" : "default"}
            className={cn(styles.tabButton, {
              [styles.currentTab]: currentTab === "score-summary",
            })}
            onClick={() => {
              setCurrentTab("score-summary");
            }}
          >
            {"Score summary"}
          </Button>
          <Button
            color={currentTab === "section-performance" ? "primary" : "default"}
            className={cn(styles.tabButton, {
              [styles.currentTab]: currentTab === "section-performance",
            })}
            onClick={() => {
              setCurrentTab("section-performance");
              getAssessmentSectionPerformance();
            }}
          >
            {"Section performance"}
          </Button>
          <Button
            color={currentTab === "performance-summary" ? "primary" : "default"}
            className={cn(styles.tabButton, {
              [styles.currentTab]: currentTab === "performance-summary",
            })}
            onClick={() => {
              setCurrentTab("performance-summary");
              getAssessmentPerformanceSummary();
            }}
          >
            {"Performance summary"}
          </Button>
          <Button
            color={currentTab === "improvement-areas" ? "primary" : "default"}
            className={cn(styles.tabButton, {
              [styles.currentTab]: currentTab === "improvement-areas",
            })}
            onClick={() => {
              setCurrentTab("improvement-areas");
              getAssessmentImprovementArea();
            }}
          >
            {"Improvement areas"}
          </Button>
        </div>
      )}

      {data && currentTab === "score-summary" && (
        <>
          <ViewSolutionModal
            close={() => {
              toggleViewModal();
            }}
            isOpen={viewModal}
            questionData={questionData}
          />
          {loading ? (
            <Skeleton
              animation="wave"
              height={547}
              width="1000px"
              style={{ margin: "0 auto" }}
            />
          ) : (
            <Card title="Score Summary">
              <div className={styles.scoreSummaryProgress}>
                <BgCircularProgress
                  size={300}
                  thickness={3}
                  value={100}
                  variant="determinate"
                />
                <BgCircularProgress
                  size={240}
                  thickness={3.25}
                  value={100}
                  variant="determinate"
                />
                <BgCircularProgress
                  size={190}
                  thickness={3.5}
                  value={100}
                  variant="determinate"
                />
                <TotalScoreCircularProgress
                  size={300}
                  thickness={3}
                  value={data?.totalScore}
                  variant="determinate"
                />
                <RightScoreCircularProgress
                  size={240}
                  thickness={3.25}
                  value={totalRightAnswerAverage}
                  variant="determinate"
                />
                <WrongScoreCircularProgress
                  size={190}
                  thickness={3.5}
                  value={totalWrongAnswerAverage}
                  variant="determinate"
                />
                <div className={styles.scoreSummaryProgressPercentageContainer}>
                  <div className={styles.scoreSummaryProgressScorePercentage}>
                    {parseFloat(data?.totalScore || 0).toFixed(1)}%
                  </div>
                  <div className={styles.scoreSummaryProgressTotalPercentage}>
                    Out of 100
                  </div>
                </div>
              </div>
              <br />
              <br />
              {smallScreen ? (
                <div className={styles.scoreSummaryInsightBoxContainerMobile}>
                  <InsightBoxMobile
                    title="No of Section"
                    description={data?.numberOfSection}
                  />
                  <InsightBoxMobile
                    title="Total questions"
                    description={data?.totalNumberOfQuestion}
                  />
                  <InsightBoxMobile
                    title="Un-attempted"
                    description={data?.totalUnattemptedQuestion}
                  />
                  <InsightBoxMobile
                    title="Right answer"
                    green
                    description={data?.totalRightAnswer}
                  />
                  <InsightBoxMobile
                    title="Wrong answer"
                    red
                    description={data?.totalWrongAnswer}
                  />
                  <InsightBoxMobile
                    title="Total Score"
                    blue
                    description={`${parseFloat(data?.totalScore || 0).toFixed(
                      1
                    )}%`}
                  />
                </div>
              ) : (
                <div className={styles.scoreSummaryInsightBoxContainer}>
                  <InsightBox
                    title="No of Section"
                    description={data?.numberOfSection}
                  />
                  <InsightBox
                    title="Total questions"
                    description={data?.totalNumberOfQuestion}
                  />
                  <InsightBox
                    title="Right answer"
                    green
                    description={data?.totalRightAnswer}
                  />
                  <InsightBox
                    title="Wrong answer"
                    red
                    description={data?.totalWrongAnswer}
                  />
                  <InsightBox
                    title="Un-attempted"
                    description={data?.totalUnattemptedQuestion}
                  />
                  <InsightBox
                    title="Total Score"
                    blue
                    description={`${parseFloat(data?.totalScore || 0).toFixed(
                      1
                    )}%`}
                  />
                </div>
              )}
            </Card>
          )}

          {sectionSumaryloading ? (
            <Skeleton
              animation="wave"
              height={547}
              width="1000px"
              style={{ margin: "0 auto" }}
            />
          ) : (
            <div>
              {data?.showSummary && (
                <div style={{ marginTop: 32 }}>
                  <Card>
                    {smallScreen ? (
                      <div>
                        <FormControl
                          className={classes.formControl}
                          style={{ width: "100%" }}
                        >
                          <InputLabel htmlFor="age-native-simple">
                            Select Question Summary
                          </InputLabel>
                          <Select
                            value={tabName}
                            displayEmpty
                            className={classes.select}
                            inputProps={{ style: { borderColor: "#794DF5" } }}
                            onChange={handleChange}
                          >
                            {data?.sectionSummary.map((j, id) => (
                              <MenuItem
                                value={id}
                                className={styles.sectionTabBtn}
                                onClick={() => {
                                  setSectionTab(id);
                                  tableData(j.questionSummary);
                                }}
                              >
                                {" "}
                                {j.sectionName}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        <SummaryTable
                          tableHeader={[
                            "Q.no",
                            "Question name",
                            "Status",
                            "Difficulty",
                            "Completed time(sec)",
                            "Avg",
                            "Solution",
                          ]}
                          tableData={questionList || []}
                          viewModal={(data) => {
                            toggleViewModal();
                            setQuestionData(data);
                          }}
                          assessmentData={data}
                        />
                      </div>
                    ) : (
                      <div>
                        <span className={styles.sectionSummary}>
                          Section summary
                        </span>
                        <div className={styles.sectionTab}>
                          {data?.sectionSummary?.map((j, id) => (
                            <Button
                              color={sectionTab === id ? "primary" : "default"}
                              className={styles.sectionTabBtn}
                              onClick={() => {
                                setSectionTab(id);
                                tableData(j?.questionSummary);
                              }}
                            >
                              <span
                                style={{
                                  fontWeight: 600,
                                  fontSize: 16,
                                  textTransform: "capitalize",
                                }}
                              >
                                {j?.sectionName}
                              </span>
                              <span style={{ fontSize: 16 }}>
                                {j?.noOfQuestions} questions
                              </span>
                            </Button>
                          ))}
                        </div>
                        <SummaryTable
                          tableHeader={[
                            "Q.no",
                            "Question name",
                            "Status",
                            "Difficulty",
                            "Completed time(sec)",
                            "Avg",
                            "Solution",
                          ]}
                          tableData={questionList || []}
                          viewModal={(data) => {
                            toggleViewModal();
                            setQuestionData(data);
                          }}
                          assessmentData={data}
                        />
                      </div>
                    )}
                  </Card>
                </div>
              )}
            </div>
          )}
        </>
      )}

      {sectionPerformanceLoading ? (
        <Skeleton
          animation="wave"
          height={547}
          width="1000px"
          style={{ margin: "0 auto" }}
        />
      ) : (
        <div>
          {sectionPerformanceData && currentTab === "section-performance" && (
            <div
              className={
                smallScreen
                  ? styles.sectionPerformanceMobile
                  : styles.sectionPerformance
              }
            >
              <Card title="Score distribution">
                <Grid container style={{ height: "100%", flex: 1 }}>
                  <Grid
                    container
                    style={{ height: "100%", flex: 1, width: "60%" }}
                  >
                    <ResponsivePie
                      colors={["#41526E", "#2697FC"]}
                      data={[
                        {
                          id: "Coding",
                          label: "Coding",
                          value: sectionPerformanceData?.codingTotalScore,
                        },
                        {
                          id: "Aptitude",
                          label: "Aptitude",
                          value: sectionPerformanceData?.aptitudeTotalScore,
                        },
                      ]}
                      isInteractive={false}
                      enableArcLabels={false}
                      enableArcLinkLabels={false}
                      innerRadius={0.65}
                      layers={[
                        "arcs",
                        ({ centerX, centerY }) => (
                          <>
                            <text
                              x={centerX}
                              y={centerY - 16}
                              textAnchor="middle"
                              dominantBaseline="central"
                              style={{
                                color: "#000",
                                fontFamily: "Poppins, sans-serif",
                                fontSize: smallScreen ? "15px" : "30px",
                                fontWeight: "500",
                              }}
                            >
                              {parseFloat(
                                sectionPerformanceData?.codingTotalScore +
                                  sectionPerformanceData?.aptitudeTotalScore ||
                                  0
                              ).toFixed(1)}
                              %
                            </text>
                            <text
                              x={centerX}
                              y={centerY + 16}
                              textAnchor="middle"
                              dominantBaseline="central"
                              style={{
                                color: "#515151",
                                fontFamily: "Poppins, sans-serif",
                                fontSize: smallScreen ? "15px" : "20px",
                                opacity: "0.75",
                                fontWeight: "400",
                              }}
                            >
                              out of 100
                            </text>
                          </>
                        ),
                      ]}
                      margin={{ top: 40, right: 30, bottom: 40, left: 40 }}
                    />
                  </Grid>
                  <Grid
                    alignItems="flex-start"
                    justifyContent="center"
                    container
                    direction="column"
                    style={{ width: "40%" }}
                  >
                    <div
                      className={
                        smallScreen
                          ? styles.sectionPerformanceCodingLegendMobile
                          : styles.sectionPerformanceCodingLegend
                      }
                    >
                      Coding (
                      {parseFloat(
                        sectionPerformanceData?.codingTotalScore || 0
                      ).toFixed(1)}
                      %)
                    </div>
                    <div
                      className={
                        smallScreen
                          ? styles.sectionPerformanceAptitudeLegendMobile
                          : styles.sectionPerformanceAptitudeLegend
                      }
                    >
                      Aptitude (
                      {parseFloat(
                        sectionPerformanceData?.aptitudeTotalScore || 0
                      ).toFixed(1)}
                      %)
                    </div>
                  </Grid>
                </Grid>
              </Card>
              <Card title="Accuracy report (by difficulty)">
                <div style={{ height: "360px" }}>
                  <ResponsiveBar
                    axisBottom={{
                      tickSize: 0,
                      tickPadding: 5,
                      tickValues: [0, 100],
                      legend: "% Accuracy",
                      legendPosition: "middle",
                      legendOffset: 16,
                    }}
                    axisLeft={{
                      tickSize: 0,
                      tickPadding: 8,
                    }}
                    axisRight={null}
                    axisTop={null}
                    colors={["#43B224", "#EEB60C", "#D42216"]}
                    data={[
                      ...(sectionPerformanceData?.codingEasyAccuracy ||
                      sectionPerformanceData?.codingMediumAccuracy ||
                      sectionPerformanceData?.codingHardAccuracy
                        ? [
                            {
                              category: "Coding",
                              Easy: Math.floor(
                                sectionPerformanceData?.codingEasyAccuracy || 0
                              ).toFixed(1),
                              Medium: Math.floor(
                                sectionPerformanceData?.codingMediumAccuracy ||
                                  0
                              ).toFixed(1),
                              Hard: Math.floor(
                                sectionPerformanceData?.codingHardAccuracy || 0
                              ).toFixed(1),
                            },
                          ]
                        : []),
                      ...(sectionPerformanceData?.aptitudeEasyAccuracy ||
                      sectionPerformanceData?.aptitudeMediumAccuracy ||
                      sectionPerformanceData?.aptitudeHardAccuracy
                        ? [
                            {
                              category: "Aptitude",
                              Easy: Math.floor(
                                sectionPerformanceData?.aptitudeEasyAccuracy ||
                                  0
                              ).toFixed(1),
                              Medium: Math.floor(
                                sectionPerformanceData?.aptitudeMediumAccuracy ||
                                  0
                              ).toFixed(1),
                              Hard: Math.floor(
                                sectionPerformanceData?.aptitudeHardAccuracy ||
                                  0
                              ).toFixed(1),
                            },
                          ]
                        : []),
                    ]}
                    enableGridY={false}
                    enableLabel={false}
                    groupMode="grouped"
                    indexBy="category"
                    indexScale={{ type: "band", round: true }}
                    innerPadding={2}
                    isInteractive={false}
                    keys={["Easy", "Medium", "Hard"]}
                    labelTextColor="#3A3A3A"
                    layout="horizontal"
                    legends={[
                      {
                        dataFrom: "keys",
                        anchor: "bottom",
                        direction: "row",
                        justify: false,
                        translateY: 65,
                        itemsSpacing: 10,
                        itemWidth: 100,
                        itemHeight: 20,
                        itemDirection: "left-to-right",
                        symbolSize: 16,
                        effects: [
                          {
                            on: "hover",
                            style: {
                              itemOpacity: 1,
                            },
                          },
                        ],
                      },
                    ]}
                    margin={{ top: 20, right: 100, bottom: 100, left: 100 }}
                    maxValue={100}
                    padding={0.2}
                    theme={{
                      color: "#32333A",
                      fontSize: 18,
                      fontWeight: 500,
                      axis: {
                        ticks: {
                          text: {
                            fontSize: 14,
                            fontWeight: 500,
                          },
                        },
                        domain: {
                          line: {
                            stroke: "#000",
                            strokeWidth: 1,
                          },
                        },
                        legend: {
                          text: {
                            color: "#000",
                            fontSize: 14,
                            fontWeight: 500,
                          },
                        },
                      },
                    }}
                    layers={[
                      "grid",
                      "axes",
                      "bars",
                      "markers",
                      "legends",
                      "annotations",
                      (props) => <ValueOutside {...props} />,
                    ]}
                    valueScale={{ type: "linear" }}
                  />
                </div>
              </Card>
              <Card title="Average time taken (by attempt type)">
                <div style={{ height: "360px" }}>
                  <ResponsiveBar
                    axisBottom={{
                      legend: "Average time (in minutes)",
                      legendOffset: 16,
                      legendPosition: "middle",
                      renderTick: () => <></>,
                      tickPadding: 5,
                      tickSize: 0,
                    }}
                    axisLeft={{
                      tickPadding: 8,
                      tickSize: 0,
                    }}
                    axisRight={null}
                    axisTop={null}
                    colors={["#43B224", "#D42216", "#8D8D8D"]}
                    data={[
                      ...(sectionPerformanceData?.codingCorrectTime ||
                      sectionPerformanceData?.codingWrongTime ||
                      sectionPerformanceData?.codingUnattemptedTime
                        ? [
                            {
                              category: "Coding",
                              Correct: Math.floor(
                                sectionPerformanceData?.codingCorrectTime /
                                  60 || 0
                              ).toFixed(0),
                              Wrong: Math.floor(
                                sectionPerformanceData?.codingWrongTime / 60 ||
                                  0
                              ).toFixed(0),
                            },
                          ]
                        : []),
                      ...(sectionPerformanceData?.aptitudeCorrectTime ||
                      sectionPerformanceData?.aptitudeWrongTime ||
                      sectionPerformanceData?.aptitudeUnattemptedTime
                        ? [
                            {
                              category: "Aptitude",
                              Correct: Math.floor(
                                sectionPerformanceData?.aptitudeCorrectTime /
                                  60 || 0
                              ).toFixed(0),
                              Wrong: Math.floor(
                                sectionPerformanceData?.aptitudeWrongTime /
                                  60 || 0
                              ).toFixed(0),
                            },
                          ]
                        : []),
                    ]}
                    enableGridY={false}
                    enableLabel={false}
                    groupMode="grouped"
                    indexBy="category"
                    indexScale={{ type: "band", round: true }}
                    innerPadding={2}
                    isInteractive={false}
                    keys={["Correct", "Wrong"]}
                    labelTextColor="#3A3A3A"
                    layout="horizontal"
                    legends={[
                      {
                        dataFrom: "keys",
                        anchor: "bottom",
                        direction: "row",
                        justify: false,
                        translateY: 65,
                        itemsSpacing: 10,
                        itemWidth: 100,
                        itemHeight: 20,
                        itemDirection: "left-to-right",
                        symbolSize: 16,
                        effects: [
                          {
                            on: "hover",
                            style: {
                              itemOpacity: 1,
                            },
                          },
                        ],
                      },
                    ]}
                    margin={{ top: 20, right: 100, bottom: 100, left: 100 }}
                    padding={0.2}
                    theme={{
                      color: "#32333A",
                      fontSize: 18,
                      fontWeight: 500,
                      axis: {
                        ticks: {
                          text: {
                            fontSize: 14,
                            fontWeight: 500,
                          },
                        },
                        domain: {
                          line: {
                            stroke: "#000",
                            strokeWidth: 1,
                          },
                        },
                        legend: {
                          text: {
                            color: "#000",
                            fontSize: 14,
                            fontWeight: 500,
                          },
                        },
                      },
                    }}
                    layers={[
                      "grid",
                      "axes",
                      "bars",
                      "markers",
                      "legends",
                      "annotations",
                      (props) => <ValueOutside {...props} />,
                    ]}
                    valueScale={{ type: "linear" }}
                  />
                </div>
              </Card>
              <Card title="Average time (by difficulty)">
                <div style={{ height: "360px" }}>
                  <ResponsiveBar
                    axisBottom={{
                      legend: "Average time (in minutes)",
                      legendOffset: 16,
                      legendPosition: "middle",
                      renderTick: () => <></>,
                      tickPadding: 5,
                      tickSize: 0,
                    }}
                    axisLeft={{
                      tickPadding: 8,
                      tickSize: 0,
                    }}
                    axisRight={null}
                    axisTop={null}
                    colors={["#43B224", "#EEB60C", "#D42216"]}
                    data={[
                      ...(sectionPerformanceData?.codingEasyTime ||
                      sectionPerformanceData?.codingMediumTime ||
                      sectionPerformanceData?.codingHardTime
                        ? [
                            {
                              category: "Coding",
                              Easy: Math.floor(
                                sectionPerformanceData?.codingEasyTime / 60 || 0
                              ).toFixed(0),
                              Medium: Math.floor(
                                sectionPerformanceData?.codingMediumTime / 60 ||
                                  0
                              ).toFixed(0),
                              Hard: Math.floor(
                                sectionPerformanceData?.codingHardTime / 60 || 0
                              ).toFixed(0),
                            },
                          ]
                        : []),

                      ...(sectionPerformanceData?.aptitudeEasyTime ||
                      sectionPerformanceData?.aptitudeMediumTime ||
                      sectionPerformanceData?.aptitudeHardTime
                        ? [
                            {
                              category: "Aptitude",
                              Easy: Math.floor(
                                sectionPerformanceData?.aptitudeEasyTime / 60 ||
                                  0
                              ).toFixed(0),
                              Medium: Math.floor(
                                sectionPerformanceData?.aptitudeMediumTime /
                                  60 || 0
                              ).toFixed(0),
                              Hard: Math.floor(
                                sectionPerformanceData?.aptitudeHardTime / 60 ||
                                  0
                              ).toFixed(0),
                            },
                          ]
                        : []),
                    ]}
                    enableGridY={false}
                    enableLabel={false}
                    groupMode="grouped"
                    indexBy="category"
                    indexScale={{ type: "band", round: true }}
                    innerPadding={2}
                    isInteractive={false}
                    keys={["Easy", "Medium", "Hard"]}
                    labelTextColor="#3A3A3A"
                    layout="horizontal"
                    legends={[
                      {
                        dataFrom: "keys",
                        anchor: "bottom",
                        direction: "row",
                        justify: false,
                        translateY: 65,
                        itemsSpacing: 10,
                        itemWidth: 100,
                        itemHeight: 20,
                        itemDirection: "left-to-right",
                        symbolSize: 16,
                        effects: [
                          {
                            on: "hover",
                            style: {
                              itemOpacity: 1,
                            },
                          },
                        ],
                      },
                    ]}
                    margin={{ top: 20, right: 100, bottom: 100, left: 100 }}
                    padding={0.2}
                    theme={{
                      color: "#32333A",
                      fontSize: 18,
                      fontWeight: 500,
                      axis: {
                        ticks: {
                          text: {
                            fontSize: 14,
                            fontWeight: 500,
                          },
                        },
                        domain: {
                          line: {
                            stroke: "#000",
                            strokeWidth: 1,
                          },
                        },
                        legend: {
                          text: {
                            color: "#000",
                            fontSize: 14,
                            fontWeight: 500,
                          },
                        },
                      },
                    }}
                    layers={[
                      "grid",
                      "axes",
                      "bars",
                      "markers",
                      "legends",
                      "annotations",
                      (props) => <ValueOutside {...props} />,
                    ]}
                    valueScale={{ type: "linear" }}
                  />
                </div>
              </Card>
            </div>
          )}
        </div>
      )}

      {performanceLoading ? (
        <Skeleton
          animation="wave"
          height={547}
          width="1000px"
          style={{ margin: "0 auto" }}
        />
      ) : (
        <div>
          {performanceSummary && currentTab === "performance-summary" && (
            <>
              <Card
                titleIcon={<SettingsEthernet style={{ fontSize: 24 }} />}
                title="Section wise performance"
              >
                <Table
                  headerCells={[
                    "No",
                    "Section name",
                    "Total questions",
                    "Right",
                    "Wrong",
                    "Completed time(min)",
                    "Score",
                    "Out of",
                  ]}
                  bodyRows={sectionDetails}
                />
              </Card>
              {!!friendsDetails?.length && (
                <>
                  <br />
                  <br />
                  <Card
                    titleIcon={<People style={{ fontSize: 20 }} />}
                    title="You vs Friends"
                  >
                    <Table
                      headerCells={friendsHeaderDetails}
                      bodyRows={friendsDetails}
                    />
                  </Card>
                </>
              )}
              {!!collegeDetails?.length && (
                <>
                  <br />
                  <br />
                  <Card
                    titleIcon={<AccountBalance style={{ fontSize: 16 }} />}
                    title="College wise performance"
                  >
                    <Table
                      headerCells={["Rank", "College Name", "Average"]}
                      bodyRows={collegeDetails}
                    />
                  </Card>
                </>
              )}
            </>
          )}
        </div>
      )}

      {improvementLoading ? (
        <Skeleton
          animation="wave"
          height={547}
          width="1000px"
          style={{ margin: "0 auto" }}
        />
      ) : (
        <div>
          {improvementAreaData && currentTab === "improvement-areas" && (
            <Card
              titleIcon={<SignalCellularAlt style={{ fontSize: 24 }} />}
              title="Improvement areas"
            >
              <br />
              <div className={styles.improvementsSectionContainer}>
                <div
                  className={
                    smallScreen
                      ? styles.performanceMeterMobile
                      : styles.performanceMeter
                  }
                >
                  <div>Poor</div>
                  <div>Moderate</div>
                  <div>Good</div>
                  <div
                    className={
                      smallScreen
                        ? styles.tooltipContainerMobile
                        : styles.tooltipContainer
                    }
                  >
                    <span
                      className={styles.tooltip}
                      style={{
                        left: `${
                          improvementAreaData?.totalScore?.toFixed(0) || 0
                        }%`,
                      }}
                    >
                      <span>
                        {improvementAreaData?.totalScore?.toFixed(0) || 0}%
                      </span>
                    </span>
                  </div>
                </div>
                <br />
                <br />
                <br />
                <br />
                <div className={styles.performanceInfo}>
                  {"Your performance is so far "}
                  {data?.totalScore < 50 && (
                    <span className={styles.poorType}>Poor</span>
                  )}
                  {data?.totalScore >= 50 &&
                    data?.totalScore < 76 && (
                      <span className={styles.moderateType}>Moderate</span>
                    )}
                  {data?.totalScore >= 76 && (
                    <span className={styles.goodType}>Good</span>
                  )}
                  {", and needs to improve"}
                </div>
                <br />
                <br />
                <div
                  className={
                    smallScreen
                      ? styles.improvementsContainerMobile
                      : styles.improvementsContainer
                  }
                >
                  <ImprovementBox
                    title="Time management"
                    description="Manage your time and increase your problem solving speed, you can enroll in our courses to increase your skill"
                    buttonText="Enroll course"
                    onButtonClick={() => {
                      props.history.push("/lab");
                    }}
                    type={improvementAreaData?.timeManagement}
                    icon={alarmClock}
                  />
                  <ImprovementBox
                    title="Accuracy management"
                    description="Increase your accuracy by practicing more, buy more practice packages and improve your accuracy skill"
                    buttonText="Buy packages"
                    onButtonClick={() => {
                      props.history.push("/lab");
                    }}
                    type={improvementAreaData?.accuracyManagement}
                    icon={electroDevices}
                  />
                  {improvementAreaData?.codingPerformance == null ||
                  undefined ||
                  "" ? (
                    ""
                  ) : (
                    <ImprovementBox
                      title="Coding performance"
                      description="Your our coding performance is poor but, improve Coding performance by doing practice in our platform"
                      buttonText="Go to practice"
                      onButtonClick={() => {
                        props.history.push("/lab");
                      }}
                      type={improvementAreaData?.codingPerformance}
                      icon={treeStructure}
                    />
                  )}
                  {improvementAreaData?.aptitudePerformance == null ||
                  undefined ||
                  "" ? (
                    ""
                  ) : (
                    <ImprovementBox
                      title="Aptitude performance"
                      description="Your our Aptitude performance is moderate and needs to improve. Improve your aptitude performance by doing practice in our platform"
                      buttonText="Go to practice"
                      onButtonClick={() => {
                        props.history.push("/lab");
                      }}
                      type={improvementAreaData?.aptitudePerformance}
                      icon={ideaIcon}
                    />
                  )}
                </div>
                <br />
                <br />
                <br />
              </div>
            </Card>
          )}
        </div>
      )}
      <p />
      <p />
      <div>
        <Button
          outline
          style={{ display: "inline-block !important", fontSize: 16 }}
          onClick={() => {
            props.history.goBack();
          }}
        >
          <ArrowBack />
          &nbsp;&nbsp;
          {"Go Back"}
        </Button>
      </div>
    </div>
  );
}
