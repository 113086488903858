import api from "../../../api/baseConfig";
import Apihelper from "../../../api/apiHelper";
export function getSafe(fn, def) {
  try {
    if (fn() === undefined || fn() === null) {
      return def;
    } else {
      return fn();
    }
  } catch {
    return def;
  }
}
export async function getURL(media) {
  let url = "";
  try {
    await Apihelper.axiosCallLC(
      `${api.lcBaseURL}${api.lcUserManagement.getAttachment}?attachment=${media}`,
      "get"
    ).then((data) => {
      url = data.attachment_url;
    });
  } catch (e) {}
  return url ? url : "";
}
export async function b64toBlob(b64Data) {
  let contentType = "";
  let sliceSize = 512;
  const byteCharacters = atob(b64Data);
  const byteArrays = [];
  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);
    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }
  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
}
export function browserCheck() {
  var isOpera =
    (!!window.opr && !!opr.addons) ||
    !!window.opera ||
    navigator.userAgent.indexOf(" OPR/") >= 0;
  var isFirefox = typeof InstallTrigger !== "undefined";
  // var isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })
  //   (!window['safari'] || (typeof safari !== 'undefined' && window['safari'].pushNotification));
  // var ua = navigator.userAgent;
  // var iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
  // var webkitUa = !!ua.match(/WebKit/i);
  var result =/iPad|iPhone|iPod/.test(navigator.userAgent) & !window.MSStream

  var isIphoneChrome = result == 1;
  var isSafari = !!window.navigator.userAgent.match(/Version\/[\d\.]+.*Safari/); 
  var isIE = false || !!document.documentMode;
  var isEdge = !isIE && !!window.StyleMedia;
  var isChrome = !!window.chrome;
  var isEdgeChromium = isChrome && navigator.userAgent.indexOf("Edg") != -1;
  var isBlink = (isChrome || isOpera) && !!window.CSS;
  var output = "Detecting browsers by ducktyping:<hr>";
  output += "isFirefox: " + isFirefox + "<br>";
  output += "isChrome: " + isChrome + "<br>";
  output += "isIphoneChrome: " + isIphoneChrome + "<br>";
  output += "isSafari: " + isSafari + "<br>";
  output += "isOpera: " + isOpera + "<br>";
  output += "isIE: " + isIE + "<br>";
  output += "isEdge: " + isEdge + "<br>";
  output += "isEdgeChromium: " + isEdgeChromium + "<br>";
  output += "isBlink: " + isBlink + "<br>";
  if (isFirefox || isChrome || isIphoneChrome || isSafari || isEdgeChromium || isEdge) {
    return true;
  } else {
    return false;
  }
}
export async function uploadFile(files, state) {
  let attachment = "";
  if (files && files.length != 0) {
    let val = await b64toBlob(files);
    var bodyFormData = new FormData();
    bodyFormData.append("file", val);
    try {
      await Apihelper.axiosUploadFile(
        `${api.baseURL}${api.userManagement.fileUpload}`,
        bodyFormData
      ).then((data) => {
        attachment = data.attachment;
        try {
          let body = {
            userId: state.userId,
            assessmentId: state.qpId,
            mediaId: data.attachment,
          };
          Apihelper.axiosCallPost(
            `${api.baseURL}${api.assessmentController.saveImage}`,
            "POST",
            body
          ).then((data) => {});
        } catch (e) {}
      });
    } catch (e) {}
  }
  return attachment;
}
