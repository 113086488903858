import api from "../../../api/baseConfig";
import ApiHelper from "../../../api/apiHelper";

export async function GetAllUsersID(id) {
  let data;
  const url = `${api.baseURL}${api.userManagement.getAllUsersByCustomerId}${id}`;
  const params = {
    customerId: id,
  };
  
  try {
    await ApiHelper.axiosCallUpdate(url, "get").then((res) => {
      data = res;
    });
  } catch (e) {
    console.error(e);
  }
  
  return data;
};
