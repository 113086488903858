import { Col, Label, Input } from "reactstrap";
import VisibilityIcon from '@material-ui/icons/Visibility';
import moment from "moment";
import { useState } from "react";

const validIndicator = <span style={{ color: "red" }}>*</span>;
const validateEmail = (email) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};
const validateLetters = (letters) => {
  const re = /^[a-zA-Z ]*$/;
  return re.test(String(letters).toLowerCase());
};

export const InputField = ({
  len,
  showValid,
  label,
  value,
  onInput = undefined,
  changeFunc = undefined,
  onBlur = undefined,
  validation,
  type,
  validationMessage,
  error,
  id,
  disabled,
  pwdValue,
  placeholder,
  allData,
  height,
  onKeyDown,
  maxLength,
}) => {
  const determineError = () => {
    let message = "";
    if (validation) {
      switch (id) {
        case "percentage": {
          if (!value) {
            message = validationMessage;
          } else if (value > 100) {
            message = "Percentage should be 0 and 100"
          } else if (value < 0) {
            message = "Percentage should be 0 and 100"
          }
          break;
        }
        case "cgpa": {
          if (!value) {
            message = validationMessage;
          } else if (value > 10) {
            message = "CGPA should be 0 and 10"
          } else if (value < 0) {
            message = "CGPA should be 0 and 10"
          }
          break;
        }
        case "pwd" || "oldpwd": {
          if (!value) {
            message = validationMessage;
          } else if (value.length < 6) {
            message = "Password must contain a minimum of 6 characters";
          }
          break;
        }
        case "cnpwd": {
          if (value !== pwdValue) {
            message = validationMessage;
          }
          break;
        }
        case "email": {
          if (!value || (value && !validateEmail(value))) {
            message = validationMessage;
          }
          break;
        }
        case "letters": {
          if (!value || (value && !validateLetters(value))) {
            message = validationMessage;
          }
          break;
        }
        case "startDate": {
          if (!value) {
            message = validationMessage;
          } else if (moment(value).diff(moment(), "days") < 0) {
            message = "Start Date cannot be before today";
          }
          break;
        }
        case "endDate": {
          if (!value) {
            message = validationMessage;
          } else if (
            moment(value).diff(moment(allData.startDate), "days") < 0
          ) {
            message = "End Date cannot be before the Start Date";
          }
          break;
        }
        default: {
          if (!value) {
            message = validationMessage;
          }
          break;
        }
      }
      validation(message);
    }
  };

  const [passShow, setPassShow] = useState(true);

  const toggleVisible = () => {
    setPassShow(passShow ? false : true);
  };



  return (
    <Col lg={len && len}>
      <Label>
        {label}
        {showValid && validIndicator}
      </Label>
      <div style={{display: "flex", alignItems: "center", border: "1px solid #DFE1E6", borderRadius: 5, position: "relative"}}>
      <Input
        autoComplete="off"
        style={{ backgroundColor: "#FAFBFC", border: "none", minHeight: height,
        paddingRight: type === "password" ? 40 : 10 }}
        value={value}
        onInput={onInput}
        onChange={(e) => {changeFunc && changeFunc(e.target.value)}}
        onBlur={() => {
          determineError();
          if (onBlur) {
            onBlur();
          }
        }}
        type={type === "password" ? passShow ? "password" : "text" : type ? type : "text"}
        disabled={disabled}
        placeholder={placeholder}
        onKeyDown={onKeyDown}
        maxLength={maxLength}
      />
      {type === "password" ?
      <VisibilityIcon
        onClick={toggleVisible}
        style={{position: "absolute", right: "1%",}}
        color={passShow ? "disabled" : "primary"}
      /> : null}
      </div>
      {error && <span style={{ color: "red" }}>{error}</span>}
    </Col>
  );
};
