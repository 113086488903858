import { useState, useCallback, useEffect } from "react";
import { TervButtonFilled } from "components/TervButtons";
import { TervButtonFilled2 } from "components/TervButtons";
import { TervButtonOutlined } from "components/TervButtons";
import { PlusIcon } from "assets/icons/icons";
import Alert from "@material-ui/lab/Alert";
import { DragDropContext } from "react-beautiful-dnd";
import styles from "./SectionCreation.module.scss";  
import { ArraySwap } from "pages/utils/ArraySwap";
import LessonCreation from "./LessonCreation";
import LessonType from "./LessonType";
import EditLesson from "./EditLesson";
import { ModalToggle } from "pages/utils/ModalToggle";
import { LessonIcons } from "./Content";
import { SectionList } from "./SectionList";
import { Collapse } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import {
  GetCourseModules,
  SaveCourseSections,
  getAllPractices,
  getAssessmentsByCustomer,
} from "./Apis";
import { useParams } from "react-router";
import { sessionPrivileges } from "privileges";
import VideoQuiz from "./videoQuiz";

const CustomAlert = withStyles({
  root: {
    fontFamily: "Poppins",
    fontWeight: 500,
  },
})(Alert);

const SectionCreation = ({
  goNext,
  goPrevious,
  courseId,
  setFormValue2,
  formValue2,
  itemIndex,
  setItemIndex,
  customerId,
  customerName,
  loadEdit,
  // selectedUserList,
  // sDate,
  // eDate,
  prepPracticeId,
  selSec,
  setSelSec,
  selLes,
  setSelLes,
  secVal,
  createdFromPrepare,
  selCurr,
  prepPracticeRes,
  setPrepPracticeRes,
  // cName,
  // cPer,
  courseData,
  listQB,
}) => {
  const userPrivileges = sessionPrivileges();
  const USER_ID = userPrivileges.userName;
  const [editableSections, setEditableSections] = useState({});
  const [sectionValues, setSectionValues] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [submitError, setSubmitError] = useState({ state: false, message: "" });
  const [currentStep, setCurrentStep] = useState(loadEdit || "lessonType");
  const [currentSection, setCurrentSection] = useState(null);
  const [currentLesson, setCurrentLesson] = useState({});
  const [expanded, setExpanded] = useState({});

  const [practiceLabs, setPracticeLabs] = useState();
  const [selectedPracticeName, setSelectedPracticeName] = useState();
  const [assessmentList, setAssessmentList] = useState();
  const [selectedLesson, setSelectedLesson] = useState({ type: "", lessonId:"", lessonIndex: "", sectionIndex:""});

  const hasId = useParams();

  const updateSelectedLesson = (obj) =>  setSelectedLesson(obj);

  useEffect(() => {
    if (formValue2.length > 0) {
      setSectionValues(formValue2);
    }
  }, []);

  const getSectionsById = async (courseId) => {
    try {
      const sectionData = await GetCourseModules(courseId);
      if (sectionData && sectionData.length > 0) {
        setSectionValues(sectionData);
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (hasId?.id) {
      getSectionsById(hasId.id);
    }
  }, []);

  const toggleEditable = (index) => {
    setEditableSections({
      ...editableSections,
      [index]: !editableSections[index],
    });
  };

  const handleField = (index, value) => {
    const newArray = [...sectionValues];
    const existingNameIndex = newArray.findIndex(
      (sec) => sec.title && sec.title === value
    );
    if (existingNameIndex !== -1) {
      handleSubmitAlert("duplName", existingNameIndex);
    } else {
      newArray[index].title = value;
      setSectionValues(newArray);
    }
  };

  const addField = () =>
    setSectionValues([
      ...sectionValues,
      {
        courseId,
        isActive: true,
        previewAvailable: true,
        createdBy: USER_ID,
        updatedBy: USER_ID,
        title: "",
        sectionOrder: sectionValues.length,
        courseResource: [],
      },
    ]);

  const addLesson = useCallback(
    (value) => {
      console.log(currentSection)
      const newArray = [...sectionValues];
      if ( currentLesson.resourceModuleOrder === newArray[currentSection]["courseResource"]?.length ) {
        newArray[currentSection]["courseResource"]?.push(value);
      } else if(selectedLesson.lessonIndex !== ""){
        newArray[currentSection]["courseResource"][selectedLesson.lessonIndex] = value;
      }
      setSectionValues(newArray);
      setCurrentLesson({});
      setCurrentStep("lessonType");
      handleExpanded(currentSection);
      // }
    },
    [sectionValues, currentSection, currentLesson]
  );

  const getPackagesByCustomer = async (customerId) => {
    try {
      const practicePackages = await getAllPractices(customerId);
      if (practicePackages && practicePackages.length > 0) {
        setPracticeLabs(practicePackages);
      }
    } catch (error) {}
  };

  const getAllAssessmentsByCustomer = async (customerId) => {
    try {
      const assessments = await getAssessmentsByCustomer(customerId);
      if (assessments && assessments.length > 0) {
        setAssessmentList(assessments);
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (currentLesson.type === "Practice lab") {
      getPackagesByCustomer(customerId);
    }
    if (currentLesson.type === "Assessment") {
      getAllAssessmentsByCustomer(customerId);
    }
  }, [currentLesson, customerId]);

  const removeField = (index) =>
    setSectionValues(sectionValues.filter((_, ind) => ind !== index));

  const removeLesson = useCallback(
    (secInd, lesInd) => {
      const temp = [...sectionValues];
      temp[secInd].courseResource = temp[secInd].courseResource.filter(
        (_, ind) => ind !== lesInd
      );
      setSectionValues(temp);
    },
    [sectionValues]
  );

  const Sort = useCallback(
    ({ type, draggableId, source, destination }) => {
      if (!destination) return;
      if (
        destination.droppableId === source.droppableId &&
        destination.index === source.index
      )
        return;

      if (type === "SECTIONS") {
        setSectionValues(
          ArraySwap(sectionValues, source.index, destination.index)
        );
      } else {
        let temp = [...sectionValues];
        temp[draggableId.slice(0, draggableId.indexOf("-"))].courseResource =
          ArraySwap(
            sectionValues[draggableId[0]].courseResource,
            source.index,
            destination.index
          );
        setSectionValues(temp);
      }
    },
    [sectionValues]
  );

  const handleModal = () => setModalOpen(ModalToggle);

  useEffect(() => {
    if (secVal && createdFromPrepare) {
      handleModal();
      setSectionValues([...secVal]);
      setCurrentLesson({ ...selCurr });
    }
    if (createdFromPrepare) {
      setCurrentSection(selSec);
    }
  }, []);

  const handleExpanded = (ind) => {
    setExpanded({ [ind]: !expanded[true] });
  };

  const handleSubmitAlert = (type, errorSec) => {
    setSubmitError({
      state: true,
      message:
        type === "no sections"
          ? "Please add atleast one section"
          : type === "noName"
          ? `Please enter a name for section ${errorSec + 1}`
          : type === "duplName"
          ? `This title is already used for section ${
              errorSec + 1
            }. Please enter a different title`
          : `Please add at-least one lesson in section ${errorSec}`,
    });
    setTimeout(() => {
      setSubmitError(false);
    }, 3000);
  };

  const handleSubmit = () => {
    const errorSec = sectionValues.find(
      (sec) => sec.courseResource.length === 0
    );

    if (sectionValues.length === 0 || errorSec)
      handleSubmitAlert(
        sectionValues.length === 0 ? "no sections" : "no lessons",
        errorSec?.title
      );
    else if (sectionValues.find((sec) => !sec.title))
      handleSubmitAlert(
        "noName",
        sectionValues.findIndex((sec) => !sec.title)
      );
    else {
      SaveCourseSections(sectionValues);
      setFormValue2(sectionValues);
      goNext();
    }
  };

  const handlePrevious = () => {
    goPrevious();
  };

  return (
    <>
      <LessonCreation
        currentStep={currentStep}
        currentLesson={currentLesson}
        open={modalOpen}
        close={handleModal}
      >
        {currentStep === "lessonType" ? (
          <LessonType
            currentLesson={currentLesson}
            setCurrentLesson={setCurrentLesson}
            goToNext={() => setCurrentStep("editLesson")}
            LessonIcons={LessonIcons}
          />
        ) : currentStep === "editLesson" ? (
          <EditLesson
            currentSection={currentSection}
            currentLesson={currentLesson}
            addLesson={(data) => {
              addLesson(data);
              handleModal();
            }}
            LessonIcons={LessonIcons}
            setFormValue2={setFormValue2}
            formValue2={formValue2}
            setSectionValues={setSectionValues}
            handleChangeContent={() => setCurrentStep("lessonType")}
            customerName={customerName}
            customerId={customerId}
            courseId={courseId}
            practiceLabs={practiceLabs}
            selectedPracticeName={selectedPracticeName}
            setSelectedPracticeName={setSelectedPracticeName}
            // selectedUserList={selectedUserList}
            // sDate={sDate}
            // eDate={eDate}
            selSec={selSec}
            prepPracticeRes={prepPracticeRes}
            setPrepPracticeRes={setPrepPracticeRes}
            createdFromPrepare={createdFromPrepare}
            sectionValues={sectionValues}
            prepPracticeId={prepPracticeId}
            // cName={cName}
            // cPer={cPer}
            selCurr={selCurr}
            courseData={courseData}
            assessmentList={assessmentList}
            close={handleModal}
          />
        ) : (
          ""
        )}
      </LessonCreation>
      {selectedLesson.type && selectedLesson.type === "videoQuiz" && (
        <VideoQuiz
          sectionValues={sectionValues}
          setSectionValues={setSectionValues}
          selectedLesson={selectedLesson}
          updateSelectedLesson={updateSelectedLesson}
          removeLesson={removeLesson}
          listQB={listQB}
        />
      )}
      <div style={{display:selectedLesson.lessonId !== "" ? "none" :""}}>
        <Collapse in={submitError.state}>
          <CustomAlert severity="error">{submitError.message}</CustomAlert>
        </Collapse>
        <div className={styles.sec__main}>
          <h4 className={styles.sec__header}>Course Content</h4>
          <p className={styles.sec__desc}>
            Click add section to create a new section and then add content into
            the section
          </p>
          <TervButtonFilled2
            text="Add Section"
            icon={<PlusIcon size="16" color="#fff" />}
            justifyCenter
            onClick={addField}
          />
          <DragDropContext onDragEnd={Sort}>
            <SectionList
              sectionValues={sectionValues}
              setSectionValues={setSectionValues}
              editableSections={editableSections}
              toggleEditable={toggleEditable}
              handleField={handleField}
              removeField={removeField}
              removeLesson={removeLesson}
              handleModal={handleModal}
              itemIndex={itemIndex}
              setItemIndex={setItemIndex}
              expanded={expanded}
              handleExpanded={handleExpanded}
              setCurrentSection={setCurrentSection}
              setCurrentLesson={setCurrentLesson}
              currentLesson={currentLesson}
              courseId={courseId}
              USER_ID={USER_ID}
              setFormValue2={setFormValue2}
              formValue2={formValue2}
              selSec={selSec}
              setSelSec={setSelSec}
              selLes={selLes}
              setSelLes={setSelLes}
              updateSelectedLesson={updateSelectedLesson}
              useDragHandle
            />
          </DragDropContext>
          <div style={{ display: "flex", justifyContent: "center", gap: "5px" }}>
            <TervButtonOutlined
              type="button"
              text="Previous"
              onClick={handlePrevious}
              width="6.125rem"
            />
            <TervButtonFilled
              text="Continue"
              onClick={handleSubmit}
              width="6.125rem"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default SectionCreation;
