import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import { AddImageComponent } from "components/AddImageComponent";
import { AddMediaComponent } from "components/AddMediaComponent";
import { FormLayout } from "components/FormLayout";
import { ErrorBlock } from "components/ErrorBlock";
import StyledNumberInput from "components/StyledNumberInput";
import { RequiredIndicator } from "pages/utils/RequiredIndicator";
import { FormControlLabel, Checkbox } from "@material-ui/core";
import { GetObjectFromString } from "pages/utils/GetObjectFromString";
import { ReactSelectTervStyle } from "pages/utils/ReactSelectTervStyle";
import { LANGUAGES, LEVELS } from "../../Prepare/Content";
import "../../Prepare/Prepare.scss";
import { TervButtonFilled } from "components/TervButtons";
import { CreateOrUpdate } from "../Apis";
import { HandleDefaultMedia } from "../../utils/HandleDefaultMedia";
import { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Form1Schema } from "./ValidationSchemas";
import moment from "moment";
import { Input, InputGroupAddon } from "reactstrap";
import { withStyles } from "@material-ui/styles";
import { parseRawStudentData } from "../../utils/ParseRawStudentData";
import { useSelector, connect } from "react-redux";
import { sessionPrivileges } from "privileges";
import api from "api/baseConfig";
import apiHelper from "api/apiHelper";
import AdvancedStudentSelectionAlt from "../../Prepare/AdvancedStudentSelectionAlt";
import AdvancedStudentSelection from "pages/Tasks/Components/AdvancedStudentSelection";
import { useGetCustomers } from "hooks/useGetCustomers";

const CustomCheckbox = withStyles({
  root: {
    color: "#794df5",
    "&$checked": {
      color: "#794df5",
    },
  },
})(Checkbox);

const CustomFormLabel = withStyles({
  label: {
    fontFamily: "Poppins",
    fontWeight: 500,
    fontSize: "0.9375rem",
    lineHeight: "1.375rem",
    color: "#323036",
  },
})(FormControlLabel);

const TabForm1 = ({
  goNext,
  setCourseId,
  courseData,
  allCategories,
  setFormValue1,
  formValue1,
  setUserIdList,
  userIdList,
  isEdit,
  customerId,
}) => {
  const userPrivileges = sessionPrivileges();
  const [regType, setRegType] = useState(false);
  const [regPublic, setRegPublic] = useState("test");
  const [assessments, setAssessments] = useState([]);
  const [project, setProject] = useState("test");
  const [studentData, setStudentData] = useState({});

  const methods = useForm({
    mode: "onBlur",
    resolver: yupResolver(Form1Schema(isEdit, courseData, regPublic, project)),
  });

  const {
    register,
    control,
    setValue,
    formState: { isSubmitting, errors },
    handleSubmit,
    reset,
    watch,
  } = methods;

  useEffect(() => {
    getAssessmentsByCustomer(
      userPrivileges.isSuperAdmin
        ? customerId
        : sessionStorage.getItem("customer_id")
    );
  }, []);

  const allCustomers = useGetCustomers();

  const getAssessmentsByCustomer = async (id) => {
    try {
      const data = await apiHelper.axiosCall(
        `${api.baseURL}${api.assessmentController.getAssessmentbyCustomer}${id}`,
        "get"
      );
      if (data) {
        setAssessments(
          data.reduce((acc, dep) => {
            if (dep)
              acc.push({
                label: dep.name,
                value: dep.id,
              });
            return acc;
          }, [])
        );
      }
    } catch (error) {
      console.log(`${error} error`);
    }
  };
  useEffect(() => {
    if (courseData?.name || formValue1?.name) {
      const value = courseData?.name ? courseData : formValue1;
      setStudentData({
        data: { filterType: value?.filterType, userIdList: value?.userIdList },
      });
      setProject(value?.type == "Project" ? "true" : "false");
      setRegPublic(value?.registrationType == "Public" ? "true" : "false");
      reset({
        name: value?.name ?? "",
        description: value?.description ?? "",
        hackathonStartDate: value?.hackathonStartDate ?? "",
        hackathonEndDate: value?.hackathonEndDate ?? "",
        registrationStartDate: value?.registrationStartDate ?? "",
        registrationEndDate: value?.registrationEndDate ?? "",
        type: value?.type ?? "",
        cardImage: value?.cardImage ?? "",
        coverImage: value?.coverImage ?? "",
        pdfSubmission: value.pdfSubmission ?? "",
        pptSubmission: value.pptSubmission ?? "",
        videoSubmission: value.videoSubmission ?? "",
        portfolioSubmission: value.portfolioSubmission ?? "",
        githubSubmission: value.githubSubmission ?? "",
        articleSubmission: value.articleSubmission ?? "",
        assessmentId: value.assessmentId ?? null,
        customerId: value.customerId ?? "",
        promotionalVideo: value?.promotionalVideo ?? "",
        assessmentTime: value?.assessmentTime ?? "",
        registrationType: value?.registrationType ?? "",
        userIdList: value?.userIdList ?? "",
        filterType: value?.filterType,
        id: value?.id,
      });
    }
  }, [reset, courseData]);

  const onSubmit = async (formValues) => {
    try {
      const res = await CreateOrUpdate({
        ...formValues,
        customerId: userPrivileges.isSuperAdmin
          ? formValues?.customerId
          : sessionStorage.getItem("customer_id"),
        filterType:
          formValues?.registrationType == "Public"
            ? null
            : formValues?.filterType ?? studentData?.data?.filterType,
        userIdList:
          formValues?.registrationType == "Public"
            ? null
            : formValues?.userIdList ?? studentData?.data?.userIdList,
        hackathonStartDate: moment(formValues.hackathonStartDate).format(
          "yyyy-MM-DDTHH:mm:ss"
        ),
        hackathonEndDate: moment(formValues.hackathonEndDate).format(
          "yyyy-MM-DDTHH:mm:ss"
        ),
        registrationStartDate: moment(formValues.registrationStartDate).format(
          "yyyy-MM-DDTHH:mm:ss"
        ),
        registrationEndDate: moment(formValues.registrationEndDate).format(
          "yyyy-MM-DDTHH:mm:ss"
        ),
        ...(customerId && { customerId }),
      });
      setCourseId(res.id);
      setFormValue1({
        ...formValues,
        filterType:
          formValues?.registrationType == "public"
            ? null
            : studentData?.data?.filterType,
        userIdList:
          formValues?.registrationType == "public"
            ? null
            : studentData?.data?.userIdList,
        hackathonStartDate: moment(formValues.hackathonStartDate).format(
          "yyyy-MM-DDTHH:mm:ss"
        ),
        hackathonEndDate: moment(formValues.hackathonEndDate).format(
          "yyyy-MM-DDTHH:mm:ss"
        ),
        registrationStartDate: moment(formValues.registrationStartDate).format(
          "yyyy-MM-DDTHH:mm:ss"
        ),
        registrationEndDate: moment(formValues.registrationEndDate).format(
          "yyyy-MM-DDTHH:mm:ss"
        ),
        id: res.id,
      });

      goNext();
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <form className="manage-prepare__form" onSubmit={handleSubmit(onSubmit)}>
      <FormLayout
        description="Enter details of the Hackathon, 
        and dates"
        title="Hackathon details"
      >
        <div className="terv-row">
          <div>
            <label className="terv-label">
              {RequiredIndicator("Name of the Hackathon")}
            </label>
            <input
              {...register("name", { setValueAs: (v) => v.trim() })}
              className="terv-form terv-form-lg"
            />
            {errors.name && (
              <ErrorBlock
                eType="div"
                errorText={errors.name.message || ""}
                maxWidth="12.5rem"
              />
            )}
          </div>
        </div>
        <div className="terv-row">
          <div>
            <label className="terv-label">
              {RequiredIndicator("Short description ")}
            </label>
            <textarea
              {...register("description", { setValueAs: (v) => v.trim() })}
              className="terv-form terv-form-lg"
              style={{ height: "100px" }}
            />
            {errors.description && (
              <ErrorBlock
                eType="div"
                errorText={errors.description.message || ""}
                maxWidth="12.5rem"
              />
            )}
          </div>
        </div>
        <p>Registration start and end date</p>
        <div className="terv-row">
          <div>
            <label className="terv-label">
              {RequiredIndicator("Start Date ")}
            </label>
            <input
              type="datetime-local"
              {...register("registrationStartDate")}
              className="terv-form terv-form-md"
            />
            {errors.registrationStartDate && (
              <ErrorBlock
                eType="div"
                errorText={errors.registrationStartDate.message || ""}
                maxWidth="12.5rem"
              />
            )}
          </div>
          <div>
            <label className="terv-label">
              {RequiredIndicator("End Date ")}
            </label>
            <input
              type="datetime-local"
              {...register("registrationEndDate")}
              className="terv-form terv-form-md"
            />
            {errors.registrationEndDate && (
              <ErrorBlock
                eType="div"
                errorText={errors.registrationEndDate.message || ""}
                maxWidth="12.5rem"
              />
            )}
          </div>
        </div>
        <p>Hackathon start and end date</p>
        <div className="terv-row">
          <div>
            <label className="terv-label">
              {RequiredIndicator("Start Date")}
            </label>
            <input
              type="datetime-local"
              {...register("hackathonStartDate")}
              className="terv-form terv-form-md"
            />
            {errors.hackathonStartDate && (
              <ErrorBlock
                eType="div"
                errorText={errors.hackathonStartDate.message || ""}
                maxWidth="12.5rem"
              />
            )}
          </div>
          <div>
            <label className="terv-label">
              {RequiredIndicator("End Date")}
            </label>
            <input
              type="datetime-local"
              {...register("hackathonEndDate")}
              className="terv-form terv-form-md"
            />
            {errors.hackathonEndDate && (
              <ErrorBlock
                eType="div"
                errorText={errors.hackathonEndDate.message || ""}
                maxWidth="12.5rem"
              />
            )}
          </div>
        </div>
      </FormLayout>
      <FormLayout title="Hackathon type" description="Choose Hackathon type">
        <div
          style={{
            backgroundColor: "#F2F1F3",
            padding: 18,
            marginBottom: 10,
            borderRadius: 5,
          }}
        >
          <div style={{ display: "flex" }}>
            <div className="terv-pt-6">
              <input
                value="Project"
                name="type"
                {...register("type")}
                style={{ transform: "scale(1.5)", backgroudColor: "#323036" }}
                onClick={() => {
                  setProject("true");
                }}
                type="radio"
              />
            </div>
            <div>
              <label
                className="pl-3"
                style={{ fontSize: "16px", color: "#323036" }}
              >
                Project based Hackathon
              </label>
              <p
                style={{
                  margin: 0,
                  color: "#63606C",
                  fontSize: 14,
                  marginLeft: 30,
                }}
              >
                Participants can submit their projects online, winners will be
                decided by judges
              </p>
            </div>
          </div>
        </div>

        <div
          style={{
            backgroundColor: "#F2F1F3",
            padding: 18,
            marginBottom: 10,
            borderRadius: 5,
          }}
        >
          <div style={{ display: "flex" }}>
            <div className="terv-pt-6">
              <input
                value="Challenge"
                name="type"
                {...register("type")}
                onClick={() => {
                  setProject("false");
                }}
                style={{ transform: "scale(1.5)" }}
                type="radio"
              />
            </div>
            <div>
              <label
                className="pl-3"
                style={{ fontSize: "16px", color: "#323036" }}
              >
                Challenge Hackathon
              </label>
              <p
                style={{
                  margin: 0,
                  color: "#63606C",
                  fontSize: 14,
                  marginLeft: 30,
                }}
              >
                Quiz will be conducted to participants, winners will be decided
                according to marks
              </p>
            </div>
          </div>
        </div>
        {errors.type && (
          <ErrorBlock
            eType="div"
            errorText={errors.type.message || ""}
            maxWidth="12.5rem"
          />
        )}
        {project == "false" && (
          <div>
            <div>
              <Controller
                name="assessmentId"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Select
                    options={assessments}
                    onChange={(val) => {
                      onChange(val.value);
                    }}
                    value={GetObjectFromString(assessments, value)}
                    styles={ReactSelectTervStyle("smd")}
                  />
                )}
              />
              {errors.assessmentId && (
                <ErrorBlock
                  eType="div"
                  errorText={errors.assessmentId.message || ""}
                  maxWidth="12.5rem"
                />
              )}
            </div>
            <div className="pt-5 pb-5">
              <div className="pt-2 pb-3" style={{ display: "flex" }}>
                <input
                  name="assessmentTime"
                  value="anyTime"
                  {...register("assessmentTime")}
                  type="radio"
                />
                <p
                  style={{
                    margin: 0,
                    color: "#63606C",
                    fontSize: 14,
                    marginLeft: 30,
                  }}
                >
                  Assessment starts for all participants at same time
                </p>
              </div>
              <div className="pt-4" style={{ display: "flex" }}>
                <input
                  name="assessmentTime"
                  value="sameTime"
                  {...register("assessmentTime")}
                  type="radio"
                />
                <p
                  style={{
                    margin: 0,
                    color: "#63606C",
                    fontSize: 14,
                    marginLeft: 30,
                  }}
                >
                  Participants can take assessment anytime between Hackathon
                  starts and end date
                </p>
              </div>
            </div>
          </div>
        )}
        {project == "true" && (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Controller
              name="pdfSubmission"
              control={control}
              render={({ field: { onChange, value } }) => (
                <CustomFormLabel
                  control={
                    <CustomCheckbox
                      name="pdfSubmission"
                      color="default"
                      checked={!!value}
                      onChange={(_, c) => onChange(c)}
                    />
                  }
                  label="PDF"
                />
              )}
            />
            <Controller
              name="pptSubmission"
              control={control}
              render={({ field: { onChange, value } }) => (
                <CustomFormLabel
                  control={
                    <CustomCheckbox
                      name="pptSubmission"
                      color="default"
                      checked={!!value}
                      onChange={(_, c) => onChange(c)}
                    />
                  }
                  label="Powerpoint presentation"
                />
              )}
            />
            <Controller
              name="videoSubmission"
              control={control}
              render={({ field: { onChange, value } }) => (
                <CustomFormLabel
                  control={
                    <CustomCheckbox
                      name="videoSubmission"
                      color="default"
                      checked={!!value}
                      onChange={(_, c) => onChange(c)}
                    />
                  }
                  label="Videos mp4"
                />
              )}
            />
            <Controller
              name="portfolioSubmission"
              control={control}
              render={({ field: { onChange, value } }) => (
                <CustomFormLabel
                  control={
                    <CustomCheckbox
                      name="portfolioSubmission"
                      color="default"
                      checked={!!value}
                      onChange={(_, c) => onChange(c)}
                    />
                  }
                  label="Portfolio"
                />
              )}
            />
            <Controller
              name="githubSubmission"
              control={control}
              render={({ field: { onChange, value } }) => (
                <CustomFormLabel
                  control={
                    <CustomCheckbox
                      name="githubSubmission"
                      color="default"
                      checked={!!value}
                      onChange={(_, c) => onChange(c)}
                    />
                  }
                  label="Github links"
                />
              )}
            />
            <Controller
              name="articleSubmission"
              control={control}
              render={({ field: { onChange, value } }) => (
                <CustomFormLabel
                  control={
                    <CustomCheckbox
                      name="articleSubmission"
                      color="default"
                      checked={!!value}
                      onChange={(_, c) => onChange(c)}
                    />
                  }
                  label="Articles"
                />
              )}
            />
          </div>
        )}
      </FormLayout>
      <FormLayout
        title="Registration type"
        description="Choose Registration type based on Participants"
      >
        <div
          style={{
            backgroundColor: "#F2F1F3",
            padding: 18,
            marginBottom: 10,
            borderRadius: 5,
          }}
        >
          <div style={{ display: "flex" }}>
            <div className="terv-pt-6">
              <input
                value="Public"
                name="registrationType"
                {...register("registrationType")}
                style={{ transform: "scale(1.5)", backgroudColor: "#323036" }}
                onClick={() => {
                  setRegPublic("true");
                }}
                type="radio"
              />
            </div>
            <div>
              <label
                className="pl-3"
                style={{ fontSize: "16px", color: "#323036" }}
              >
                Public Hackathon
              </label>
              <p
                style={{
                  margin: 0,
                  color: "#63606C",
                  fontSize: 14,
                  marginLeft: 30,
                }}
              >
                Public users can register and participate in Hackathons
              </p>
            </div>
          </div>
        </div>
        <div
          style={{
            backgroundColor: "#F2F1F3",
            padding: 18,
            marginBottom: 10,
            borderRadius: 5,
          }}
        >
          <div style={{ display: "flex" }}>
            <div className="terv-pt-6">
              <input
                value="Private"
                name="registrationType"
                {...register("registrationType")}
                onClick={() => {
                  setRegPublic("false");
                }}
                style={{ transform: "scale(1.5)" }}
                type="radio"
              />
            </div>
            <div>
              <label
                className="pl-3"
                style={{ fontSize: "16px", color: "#323036" }}
              >
                Inside organization/Institution
              </label>
              <p
                style={{
                  margin: 0,
                  color: "#63606C",
                  fontSize: 14,
                  marginLeft: 30,
                }}
              >
                Only users inside college/Organization can register and
                participate in Hackathon
              </p>
            </div>
          </div>
        </div>
        {errors.registrationType && (
          <ErrorBlock
            eType="div"
            errorText={errors.registrationType.message || ""}
          />
        )}
        {!userPrivileges.isAdmin && (
          <div>
            <label className="terv-label">
              {RequiredIndicator("Customer ")}
            </label>
            <Controller
              name="customerId"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Select
                  onChange={(val) => onChange(val.value)}
                  value={GetObjectFromString(allCustomers, value)}
                  styles={ReactSelectTervStyle("md")}
                  options={allCustomers}
                />
              )}
            />
            <p
              style={{
                margin: 0,
                color: "#63606C",
                fontSize: 14,
                marginTop: 10,
              }}
            >
              Hackathon will be available for only users under this customer for
              registration
            </p>
            {errors.customerId && (
              <ErrorBlock
                eType="div"
                errorText={errors.customerId.message || ""}
              />
            )}
          </div>
        )}
        {regPublic == "false" && (
          <div>
            <div className="pt-2">
              <AdvancedStudentSelectionAlt
                customerId={watch("customerId")}
                hookFormControls={methods}
                viewOnly={false}
                studentFieldName="userIdList"
              />
              <p
                style={{
                  margin: 0,
                  color: "#63606C",
                  fontSize: 14,
                  marginTop: 10,
                }}
              >
                Hackathon will be available for only users under this customer
                for registration
              </p>
              {errors.userIdList && (
                <ErrorBlock
                  eType="div"
                  errorText={errors.userIdList.message || ""}
                />
              )}
            </div>
            {/* {errors.registrationType && (
              <ErrorBlock
                eType="div"
                errorText={errors.registrationType.message || ""}
              />
            )} */}
          </div>
        )}
      </FormLayout>
      <FormLayout
        description="Upload Hackathon card image, cover image and promotional video"
        title="Hackathon uploads"
      >
        <div className="terv-row">
          <div>
            <label className="terv-label">
              {RequiredIndicator("Upload card image")}
            </label>
            <Controller
              name="cardImage"
              control={control}
              render={({ field: { onChange, value } }) => (
                <AddImageComponent
                  handleCroppedImage={onChange}
                  media={HandleDefaultMedia(value)}
                />
              )}
            />
            {errors.cardImage && (
              <ErrorBlock
                eType="div"
                errorText={errors.cardImage.message || ""}
                maxWidth="12.5rem"
              />
            )}
          </div>
        </div>
        <div className="terv-row">
          <div>
            <label className="terv-label">Upload Cover image</label>
            <Controller
              name="coverImage"
              control={control}
              render={({ field: { onChange, value } }) => (
                <AddImageComponent
                  handleCroppedImage={onChange}
                  media={HandleDefaultMedia(value)}
                />
              )}
            />
            {errors.media && (
              <ErrorBlock
                eType="div"
                errorText={errors.media.message || ""}
                maxWidth="12.5rem"
              />
            )}
          </div>
        </div>
        <div className="terv-row">
          <div>
            <label className="terv-label">Upload Promotional video</label>
            <p style={{ color: "#63606C" }}>Video must below 10MB</p>
            <Controller
              name="promotionalVideo"
              control={control}
              render={({ field: { onChange, value } }) => (
                <AddMediaComponent
                  handleChange={onChange}
                  value={value}
                  mediaType="video/*"
                />
              )}
            />
          </div>
        </div>
      </FormLayout>

      <FormLayout hideDivider>
        <TervButtonFilled
          text="Continue"
          type="submit"
          width="6.125rem"
          disabled={isSubmitting}
        />
      </FormLayout>
    </form>
  );
};

export default TabForm1;
