import { useState, useRef } from "react";
import { Skeleton } from "@material-ui/lab";
import { circleCheckSolid, circleCrossIcon } from "assets/icons/icons";
import { uid } from "react-uid";
import Style from "../../Ecommerce/EcommerceOrders/GradingCenter.module.scss";
import GradingCD from "../../Ecommerce/EcommerceOrders/GradingCD";
import ReactHtmlParser from "html-react-parser";
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Typography } from "@material-ui/core";
import { TervButtonOutlined, TervButtonFilled } from "components/TervButtons";
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    backgroundColor:"#ffffff"
  },
  drawerPaper: {
    zIndex: 0,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  submission: {
    fontWeight: "600",
    fontSize: "20px",
    lineHeight: "32px",
    color: "#19181B",
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontWeight: "600",
      fontSize: "16px",
      lineHeight: "26px",
      color: "#323036"
    },
    paragraph: {
      fontWeight: "600",
      fontSize: "14px",
      lineHeight: "26px",
      color: "#63606C",
      width:"35rem"
    },
    pos: {
      marginBottom: 12,
    },
    hackathonList: {
      width:"30rem"
    },
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
}));

const GradingCenter = ({ loading, projectDetails, studentName}) => {
  const classes = useStyles();
  const [fileName, setFileName] = useState()
  const [linkData, setLinkData] = useState('')
  const [open, setOpen] = useState(false);
  const videoRef = useRef();
    const mediaProps = {
        controls: true,
        ref: videoRef,
      };
      
      const sourceProps = {
        src: linkData,
        type:"video/mp4",
      };

  const handleOpen = (a) => {
    var re = /(?:\.([^.]+))?$/;
     var ext = re.exec(a)[1]
    setFileName(ext)
    setLinkData(a)
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div
      style={{
        backgroundColor: "#fff",
        padding: "1rem 2rem",
        boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.15)",
        gridRow: "2/-1",
        gridColumn: "1/10",
        margin: "1rem 1rem 0 1rem",
      }}
      
    >
      {loading ? (
        <Skeleton animation="wave" height={30} width="80%" />
      ) : (
        <div>
          <div style={{ fontSize: "1.2rem", fontWeight: "500", color: "#000" }}>
            {`${studentName}'s Projects Submission`}
          </div>
        </div>
      )}
      <hr />
      {loading ? (
        <Skeleton animation="wave" height={150} width="80%" />
      ) : (
        projectDetails?.projectName ?
        <>
           <p>Name of the project</p>
          <div style={{ fontSize: "1.2rem", fontWeight: "500", color: "#000" }}>
            {`${projectDetails?.projectName}`}
          </div>
          <div className="pt-3">
            <p>Project description</p>
            <p style={{ fontSize: "1rem", color: "#000" }}>{ReactHtmlParser(projectDetails?.description || "")}</p>
          </div>
          <div className="pt-3">
            <p>Submitted files</p>
            {projectDetails?.submittedFiles?.length > 0 &&
          projectDetails?.submittedFiles?.map((data, index) => (
         <div className="pt-2 pb-2">
          <Card className={classes.hackathonList} variant="outlined" key={index}>
            <div style={{display:"flex", justifyContent: "space-between", alignItems: "center"}}>
          <CardContent >
            <Typography className={classes.title}>
                {data?.slice(0,30)}
            </Typography>
          </CardContent>
            <CardActions>
              <div style={{display:"flex"}}>
                <TervButtonOutlined
                  text={"view"}
                  variant="outlined"
                  outline="none"
                  onClick={() => handleOpen(data)}
                  style={{width:"5rem",color:"#2170D8"}}
                />
              </div>
            </CardActions>
        </div>
        </Card>
        </div>
        ))}
        <div className="pt-3">
            <p>Links</p>
            {projectDetails?.links?.length > 0 ?
          projectDetails?.links?.map((data, index) => (
            <div className="pt-1">
              {typeof(data) === 'object' ?
                <a href={`${data?.value}`} style={{ fontSize: "1rem" , color:"#2170D8",}} key={index}><u>{data?.value}</u></a> 
                :
                <a href={`${data}`} style={{ fontSize: "1rem" , color:"#2170D8",}} key={index}><u>{data}</u></a>
              }   
            </div>
          )) : "No links given"}
          </div>
          </div>
        </>
        : <p style={{fontWeight: "bold", textAlign:"center"}}>No Submission Available</p>
      )}

      {/* {loading ? (
        <Skeleton animation="wave" height={250} width="80%" />
      ) : currentQuestion?.type === "MC" ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            width: "35rem",
          }}
        >
          {(currentQuestion?.optionsList || []).map((ele, ind) => (
            <div
              key={uid(ind)}
              className={`${styles.option}
            ${
              currentQuestion?.solutionMap?.selectedOption &&
              currentQuestion?.solutionMap?.selectedOption.replace(
                /[^a-z0-9]/g,
                ""
              ) === ele?.id
                ? currentQuestion?.solutionMap?.isCorrectOption
                  ? styles.correctOption
                  : styles.wrongOption
                : ""
            } ${ele.answer && styles.correctOption}
            `}
            >
              <p
                dangerouslySetInnerHTML={{ __html: ele?.optionValue }}
                style={{ padding: 0, margin: 0, maxWidth: "25rem" }}
              />
              {currentQuestion?.solutionMap?.selectedOption &&
                currentQuestion?.solutionMap?.selectedOption.replace(
                  /[^a-z0-9]/g,
                  ""
                ) === ele?.id && (
                  <div>
                    {currentQuestion?.solutionMap?.isCorrectOption
                      ? circleCheckSolid({ size: 16, color: "#5AC62F" })
                      : circleCrossIcon({ size: 16, color: "#EB5757" })}{" "}
                    Student's choice
                  </div>
                )}
            </div>
          ))}
        </div>
      ) : currentQuestion?.type === "CD" ? (
        <GradingCD currentQuestion={currentQuestion} />
      ) : (
        ""
      )} */}
      <Modal
         aria-labelledby="transition-modal-title"
         aria-describedby="transition-modal-description"
         className={classes.modal}
         open={open}
         onClose={handleClose}
         closeAfterTransition
         BackdropComponent={Backdrop}
      >
        {(fileName == 'mp4' || fileName == 'webm') ?
          <video {...mediaProps} controlsList="nodownload" style={{width:"45rem"}}>
            <source {...sourceProps} />
          </video>
          :
          (fileName == 'ppt' || fileName == 'pptx') ?
          <iframe src={`https://view.officeapps.live.com/op/embed.aspx?src=${linkData}`} width="70%" height="70%" frameborder='0'  />
          :
          <iframe src={`${linkData}#toolbar=0`} width="70%" height="90%"  />
        }
      </Modal>
    </div>
  );
};

export default GradingCenter;
