import { SET_USER_PROFILE } from "./actionTypes";
import api from "../../api/baseConfig";
import ApiHelper from "../../api/apiHelper";

export const setUserProfile = (userData) => {
  return {
    type: SET_USER_PROFILE,
    payload: userData,
  };
};

export function getUserProfile(userId) {
  return async (dispatch) => {
    try {
      const data = await ApiHelper.axiosCallAuth(
        `${api.baseURL}${api.userManagement.getUserByEmail}${userId}`,
        "get"
      );
      sessionStorage.setItem("customer_id", data.customer.id);
      sessionStorage.setItem("customer_name", data.customer.name);
      sessionStorage.setItem("customer_Logo", data.customer.logo)
      dispatch(setUserProfile(data));
    } catch (err) {
      if (!window.location.href?.includes("/login")) {
        const url = cookies.get("returnLink", {path:'/'})
        cookies.remove("userToken", { path: "/" });
        cookies.remove("refreshToken", {
          path: "/",
        });
        cookies.remove("userId", { path: "/" });
        localStorage.clear();
        sessionStorage.clear();
        cookies.remove("returnLink", {path:'/'})
        window.location.assign(url ? url : "/login");
      }
    }
  };
}
