export const parsePublishDetails = (res) => {
  try {
    let temp = {
      questionsAnswered: 0,
      questionsGraded: 0,
      studentName: res?.studentName || "",
      studentId: res?.studentId || "",
      assessmentId: res?.assessmentId || "",
    };
    res.section.forEach((sec) => {
      res[sec].questions.forEach((ques) => {
        if (
          (ques.type === "MC" && ques.solutionMap?.selectedOption) ||
          (ques.type === "CD" && ques.solutionMap?.codeSolution)
        ) {
          temp["questionsAnswered"]++;
        }

        if (ques?.manualGradingCompleted) {
          temp["questionsGraded"]++;
        }
      });
    });
    return temp;
  } catch (e) {}
};
