import React, { useState, useEffect } from "react";
import { DropzoneArea } from "material-ui-dropzone";
import { Label, Col } from "reactstrap";

const SVG = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="#fff"
    className="bi bi-trash"
    viewBox="0 0 16 16"
  >
    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
    <path
      fillRule="evenodd"
      d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
    />
  </svg>
);

export default function QuestionMedia(props) {
  const { label, input, len } = props;

  const [error, setError] = useState("");

  const [uploadedMedia, setUploadedMedia] = useState(input.value);

  const handleError = () => {
    setError("Error");
  };

  useEffect(() => {
    setUploadedMedia(input.value);
  }, [input.value]);

  return (
    <Col
      lg={len}
      style={{
        paddingLeft: 0,
      }}
    >
      <Label htmlFor="fileName">{label}</Label>
      <DropzoneArea
        dropzoneText={
          <p>
            <div style={{ fontFamily: "Poppins" }}>Click to upload an file</div>
            <div
              style={{
                fontFamily: "Poppins",
                fontSize: "15px",
              }}
            >
              (max size upto 10mb)
            </div>
            <div style={{ fontSize: "12px", fontFamily: "Poppins" }}>
              Accepted video format: MP4
            </div>
          </p>
        }
        showAlerts
        acceptedFiles={props.type}
        getFileLimitExceedMessage={(filesLimit) =>
          `Maximum allowed number of files exceeded. Only ${filesLimit} allowed`
        }
        filesLimit={1}
        maxFileSize={props.sizeLimit}
        onChange={(file) => {
          input.onChange(file);
        }}
      />
      {uploadedMedia === props.media && props.media ? (
        <div
          style={{
            width: "200px",
            height: "100px",
            margin: "auto",
            position: "relative",
          }}
        >
          <video
            src={props.media}
            style={{ display: error === "Error" ? "none" : "" }}
            onError={handleError}
            width="220px"
            height="100px"
            controls
            type="video/*"
          />
          <button
            onClick={(e) => {
              e.preventDefault();
              input.onChange("");
            }}
            style={{
              position: "absolute",
              top: 10,
              left: 10,
              backgroundColor: "#784df5",
              border: 0,
              boxShadow:
                "0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 4px 10px 0 rgba(0, 0, 0, 0.19)",
              borderRadius: "2px",
            }}
          >
            {SVG}
          </button>
        </div>
      ) : (
        ""
      )}
    </Col>
  );
}
