import { Tabs } from "../../components/tabs/tabs";
import { useState, useEffect, useMemo } from "react";
import { Button } from "reactstrap";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Tooltip from "@material-ui/core/Tooltip";
import CardMedia from "@material-ui/core/CardMedia";
import Select from "react-select";
import Pagination from "react-js-pagination";
import { paginationClasses } from "../utils/constants";
import { DeleteModule } from "../../components/DeleteModule.jsx";
import CustomSearch from "../Tasks/CustomSearch";
import { ToastContainer } from "react-toastify";
import { Icon } from "@iconify/react";
import crownIcon from "@iconify/icons-fa-solid/crown";
import styles from "../CardStyles.module.scss";
import { EmptyState } from "components/EmptyState";
import { CardButtons } from "components/CardButtons";
import { NewHeader } from "components/NewHeader";
import { GetObjectFromString } from "pages/utils/GetObjectFromString";
import { useHistory } from "react-router";
import { sessionPrivileges } from "privileges";

const customStyles = {
  control: (styles) => ({
    ...styles,
    width: "207px",
    backgroundColor: "#fafbfc",
    zIndex: 9999,
  }),
};

const PracticePackages = ({
  customerId,
  handleSearch,
  toggleDeleteModal,
  deleteModal,
  deleteLabId,
  deletePracticeLab,
  allCustomers,
  setDeleteLabId,
  setCustomerId,
  customerName,
  setCustomerName,
  firstIndex,
  lastIndex,
  practicePackages,
  activeTab,
  activePage,
  setActivePage,
  setActiveTab,
  isSA,
  UserId,
}) => {
  const history = useHistory();
  const userPrivileges = sessionPrivileges();
  const Priv = userPrivileges.userprivileges || [];
  useEffect(() => {
    if (userPrivileges.isSuperAdmin) {
      const hasVarsity = Array.isArray(allCustomers)
        ? allCustomers.find((cus) =>
            cus.label.toLowerCase().includes("varsity")
          )
        : null;
      if (
        !customerId &&
        hasVarsity &&
        !location?.state?.customer &&
        userPrivileges.isSuperAdmin
      ) {
        setCustomerId(hasVarsity.value);
        setCustomerName(hasVarsity.label);
      }
    }
  }, [allCustomers]);
  return (
    <div className="page-content" style={{ position: "relative" }}>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        pauseOnHover
      />
      <NewHeader name="Practice Packages" />
      {Priv.includes("ADD_PRACTICE_PACKAGE") && (
        <Button
          color="primary"
          onClick={() =>
            history.push({
              pathname: "/manage-lab",
              state: {
                customer: customerId,
                customerName: customerName,
              },
            })
          }
          style={{
            position: "absolute",
            top: 25,
            right: 10,
            boxShadow:
              "0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 4px 10px 0 rgba(0, 0, 0, 0.19)",
            borderRadius: 5,
            fontSize: 13,
            fontWeight: 500,
          }}
        >
          <i className="fas fa-plus" />
          &nbsp; Create New
        </Button>
      )}
      <CustomSearch
        handlesearch={handleSearch}
        boxshadow
        placeholder="Search Packages"
        primary
        style={{ position: "absolute", top: 20, left: "31.7%" }}
      />
      <DeleteModule
        Header="Practice Package"
        Close={toggleDeleteModal}
        isOpen={deleteModal}
        Delete={deletePracticeLab}
      />
      <div style={{ position: "relative" }}>
        {isSA && (
          <div
            style={{
              position: "absolute",
              top: 10,
              right: 18,
              zIndex: 12,
            }}
          >
            <Select
              options={allCustomers}
              onChange={(val) => {
                setCustomerId(val.value);
                setCustomerName(val.label);
                setActivePage(1);
                setActiveTab("all");
              }}
              value={GetObjectFromString(allCustomers, customerId)}
              styles={customStyles}
              placeholder="Choose a Customer"
            />
          </div>
        )}
        <Tabs
          onChange={(e) => {
            setActiveTab(e), setActivePage(1);
          }}
          activeTab={activeTab}
          tabs={[
            {
              name: "All",
              badge: practicePackages.real.all.length,
              id: "all",
            },
            {
              name: "Premium",
              badge: practicePackages.real.premium.length,
              id: "premium",
            },
            {
              name: "Latest",
              badge: practicePackages.real.latest.length,
              id: "latest",
            },
            {
              name: "Special",
              badge: practicePackages.real.special.length,
              id: "special",
            },
            {
              name: "Featured",
              badge: practicePackages.real.featured.length,
              id: "featured",
            },
          ]}
        >
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              minHeight: "60vh",
              paddingTop: 10,
              paddingBottom: 5,
            }}
          >
            {practicePackages.modify[activeTab].length > 0 ? (
              practicePackages.modify[activeTab]
                .slice(firstIndex, lastIndex)
                .map((item, index) => (
                  <Card key={index} classes={{ root: styles.CARD_NEW }}>
                    <CardActionArea>
                      <CardMedia
                        style={{ height: 150 }}
                        image={item.media}
                        title={item.media}
                      />
                      <CardContent>
                        <p className={styles.CardHeader}>{item.name}</p>
                        <div className={styles.CardDesc}>
                          <Tooltip
                            interactive
                            placement="top"
                            classes={{ tooltip: styles.cardDescTooltip }}
                            title={
                              <p
                                className={styles.cardDescPara}
                                dangerouslySetInnerHTML={{
                                  __html: item.description,
                                }}
                              />
                            }
                          >
                            <p
                              dangerouslySetInnerHTML={{
                                __html: item.description,
                              }}
                            />
                          </Tooltip>
                        </div>
                        <span
                          style={{
                            position: "absolute",
                            top: 8,
                            right: 8,
                            borderRadius: "5px",
                            maxWidth: 105,
                            color: "#fff",
                            textAlign: "center",
                            fontWeight: 500,
                            padding: 8,
                            paddingTop: 2,
                            height: 25,
                            background: item?.tags
                              ? item.tags === "featured"
                                ? "linear-gradient(90deg, #FF512F 0%, #F09819 100%)"
                                : item.tags === "special"
                                ? "linear-gradient(90deg, #EE0979 0%, #FF6A00 100%)"
                                : item.tags === "premium"
                                ? "linear-gradient(90deg, #870000 0%, #190A05 100%)"
                                : item.tags === "latest"
                                ? "linear-gradient(90deg, #CC2B5E 0%, #753A88 100%)"
                                : ""
                              : "",
                          }}
                        >
                          {item?.tags === "premium" && (
                            <>
                              <Icon
                                icon={crownIcon}
                                style={{
                                  color: "#ffcc4d",
                                  fontSize: "12px",
                                  marginBottom: 3,
                                }}
                              />
                              &nbsp;
                            </>
                          )}

                          {item?.tags
                            ? item.tags.charAt(0).toUpperCase() +
                              item.tags.slice(1)
                            : ""}
                        </span>
                      </CardContent>
                    </CardActionArea>
                    <CardActions
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <CardButtons
                        showEdit={userPrivileges.canEditPracticePackages}
                        showDelete={userPrivileges.canDeletePracticePackages}
                        openReport={() => {
                          history.push({
                            pathname: "/practice-report",
                            state: {
                              practiceLabId: item.id,
                              customerId: customerId,
                              activePage: activePage,
                              activeTab: activeTab,
                            },
                          });
                        }}
                        editHistory={{
                          pathname: "/manage-lab",
                          state: {
                            fromEdittrue: true,
                            id: item.id,
                            customer: customerId,
                            activePage: activePage,
                            activeTab: activeTab,
                            customerName: customerName,
                          },
                        }}
                        handleDelete={() => {
                          toggleDeleteModal();
                          if (deleteLabId !== item.id) {
                            setDeleteLabId(item.id);
                          }
                        }}
                      />
                    </CardActions>
                  </Card>
                ))
            ) : (
              <h4>
                {isSA ? (
                  customerId ? (
                    <EmptyState label="No Practice Packages" />
                  ) : (
                    "Choose a Customer from the list above."
                  )
                ) : (
                  <EmptyState label="No Practice Packages" />
                )}
              </h4>
            )}
          </div>
          {practicePackages.real[activeTab].length > 0 && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "5rem",
              }}
            >
              <Pagination
                activePage={activePage}
                itemsCountPerPage={6}
                {...paginationClasses}
                totalItemsCount={practicePackages.modify[activeTab].length}
                pageRangeDisplayed={5}
                onChange={setActivePage}
              />
            </div>
          )}
        </Tabs>
      </div>
    </div>
  );
};

export default PracticePackages;
