import { Button, Menu, Zoom, makeStyles } from "@material-ui/core";
import cn from "classnames";
import { Icon } from "@iconify/react";
import infoCircleFilled from "@iconify/icons-ant-design/info-circle-filled";
import circleWithCross from "@iconify/icons-entypo/circle-with-cross";

const styles = makeStyles(() => ({
  paper: {
    boxShadow: "rgba(0, 0, 0, 0.2) 0 0 20px 0px !important",
    backgroundColor: "#fff !important",
    borderRadius: 8,
    padding: "12px 20px !important",
    maxWidth: 480,
    width: "calc(100% - 48px) !important",
  },
  infoPaper: {
    maxWidth: 412,
  },
  header: {
    alignItems: "center",
    color: "#262626",
    display: "flex",
    fontFamily: "Poppins, sans-serif",
    fontSize: 18,
    fontWeight: 500,
    lineHeight: 0,
  },
  content: {
    color: "#5B5A5F",
    fontFamily: "Poppins, sans-serif",
    fontSize: 15,
    fontWeight: 400,
    lineHeight: 1.5,
    marginTop: 18,
  },
  divider: {
    backgroundColor: "#E8E8E8",
    border: "none",
    height: 1,
    margin: "18px 0 !important",
  },
  buttonsContainer: {
    alignItems: "center",
    display: "flex",
  },
  confirmationButtonsContainer: {
    justifyContent: "flex-end",
  },
  infoButtonsContainer: {
    justifyContent: "center",
    marginTop: 18,
  },
}));

export default function Confirmation({
  anchor,
  confirmation,
  description,
  onContinue,
  setAnchor,
  title,
}) {
  const classes = styles();

  return (
    <Menu
      anchorEl={anchor}
      anchorOrigin={{
        horizontal: "center",
        vertical: "top",
      }}
      onClose={() => {
        setAnchor(null);
      }}
      open={Boolean(anchor)}
      PaperProps={{
        className: cn(
          classes.paper,
          confirmation ? classes.confirmationPaper : classes.infoPaper
        ),
      }}
      TransitionComponent={Zoom}
    >
      <div className={classes.header} style={{ color: "#000" }}>
        <Icon
          icon={confirmation ? infoCircleFilled : circleWithCross}
          style={{
            color: confirmation ? "#a19e9e" : "#e22b12",
            fontSize: 20,
          }}
        />
        &nbsp;&nbsp;
        <span>{title}</span>
      </div>
      <div className={classes.content}>{description}</div>
      {confirmation && <hr className={classes.divider} />}
      <div
        className={cn(
          classes.buttonsContainer,
          confirmation
            ? classes.confirmationButtonsContainer
            : classes.infoButtonsContainer
        )}
        style={{
          color: "#000",
        }}
      >
        <Button
          color="inherit"
          variant="outlined"
          onClick={() => {
            setAnchor(null);
          }}
        >
          {confirmation ? "Cancel" : "Close"}
        </Button>
        {confirmation && (
          <div>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <Button
              color="primary"
              onClick={() => {
                if (onContinue) {
                  onContinue();
                }

                setAnchor(null);
              }}
              variant="contained"
            >
              {"View"}
            </Button>
          </div>
        )}
      </div>
    </Menu>
  );
}
