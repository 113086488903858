import { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import sheriffBadge from "@iconify/icons-foundation/sheriff-badge";
import GridItem from "../../../components/GridItem/GridItem";
import LeaderboardCard from "../../../components/LeaderboardCard/LeaderboardCard";
import SkeletonGridItem from "../../../components/SkeletonGridItem/SkeletonGridItem";
import data from "./data.json";
import styles from "./Leaderboard.module.scss";
import { useLogoContext } from "context/logoContext";

export function Leaderboard({ showReal, isMobile }) {
  const [dataIsLoading, setIfDataIsLoading] = useState(false);
  const [viewAll, setViewAll] = useState(false);
  const [admin_studentLeaderboard, setAdmin_studentLeaderboard] = useState([]);
  const [admin_questionsSolvedToday, setAdmin_questionsSolvedToday] = useState(
    []
  );
  const logoImage = useLogoContext();
  useEffect(() => {
    setAdmin_studentLeaderboard(
      data.leaderboard.leaderboardList
        .map((l) => ({
          department: l.department,
          name: l.name,
          photo: l.photoUrl,
          place: l.leaderboardRank,
          pts: l.totalPoints,
          year: l.year,
        }))
        .sort(({ place: p1 }, { place: p2 }) => p1 - p2)
    );
    setAdmin_questionsSolvedToday(data.leaderboard.totalQuestionsSolvedToday);
  }, []);

  return dataIsLoading ? (
    <SkeletonGridItem
      gridPosition={[
        [1, 8],
        showReal ? (isMobile ? [8, 9] : [7, 8]) : isMobile ? [8, 9] : [7, 8],
      ]}
    />
  ) : (
    <GridItem
      gridPosition={[
        [1, 8],
        showReal ? (isMobile ? [8, 9] : [7, 8]) : isMobile ? [8, 9] : [7, 8],
      ]}
    >
      <div className={styles.leaderboard}>
        <div>
          <div className={styles.leaderboardTitle}>
            <Icon
              icon={sheriffBadge}
              style={{ color: "#FFB636", fontSize: 26 }}
            />
            &nbsp;&nbsp;
            <span>{`${logoImage?.productName} Leaderboard`}</span>
          </div>
          <div className={styles.leaderboardDescription1}>
            Questions solved today
          </div>
          <div className={styles.leaderboardDescription2}>
            {admin_questionsSolvedToday} questions has been solved
          </div>
        </div>
        {!viewAll ? (
        <div className={styles.leaderboardTop3}>
          {admin_studentLeaderboard.slice(0, 3).map(
            ({ department, name, photo, place, pts, year }, idx) => (
              <LeaderboardCard
                adminSide
                key={idx}
                department={department}
                name={name}
                photo={photo}
                place={place}
                pts={pts}
                year={year}
              />
            )
          )}
        </div>
        ):(
          <div className={styles.leaderboardTop3}>
          {admin_studentLeaderboard.map(
            ({ department, name, photo, place, pts, year }, idx) => (
              <LeaderboardCard
                adminSide
                key={idx}
                department={department}
                name={name}
                photo={photo}
                place={place}
                pts={pts}
                year={year}
              />
            )
          )}
        </div>
        )}
        {admin_studentLeaderboard?.length > 3 && (
        <div>
        {!viewAll ? (
        <div className={styles.leaderboardFooter}>
          <button
            className={styles.leaderboardViewAllButton}
            onClick={() => {setViewAll(true)}}
          >
            {"View All"}
          </button>
        </div>
        ):(
          <div className={styles.leaderboardFooter}>
          <button
            className={styles.leaderboardViewAllButton}
            onClick={() => {setViewAll(false)}}
          >
            {"View Less"}
          </button>
        </div>
        )}
        </div>
        )}
      </div>
    </GridItem>
  );
}
