import React, { useEffect, useState } from "react";
import Select from "react-select";
import api from "../../api/baseConfig";
import Apihelper from "../../api/apiHelper";

const customStyles = {
  menu: (styles) => ({ ...styles, zIndex: 9999 }),
};

function LanguagesDropdown({ value, onChange }) {
  const [languages, setLanguages] = useState([]);
  useEffect(() => {
    getLanguages();
  }, []);
  async function getLanguages() {
    if (!languages.length) {
      await Apihelper.axiosCall(
        `${api.baseURL}${api.LanguageController.currentLanguage}`,
        "get"
      ).then((data) => {
        let option = [];
        data?.forEach?.((element) => {
          option.push({
            label: element.languageNotation,
            value: element.id,
          });
        });
        setLanguages(option);
      });
    }
  }
  return (
    <Select
      options={languages}
      isMulti
      styles={customStyles}
      value={value}
      onChange={onChange}
      placeholder="Choose a Language"
    />
  );
}

export default LanguagesDropdown;
