/** @format */

import { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import GridItem from "../../../components/GridItem/GridItem";
import SkeletonGridItem from "../../../components/SkeletonGridItem/SkeletonGridItem";
import DataTableContainer from "../../../components/DataTableContainer/DataTablePractice";
import PracticePackageListItem from "../../../components/PracticePackageListItem/PracticePackageListItem";
import { useGetDashboardInfo } from "pages/Dashboard/SWRhooks";
import { PackagesSample } from "pages/Dashboard/SampleData";
import { useTranslation } from "react-i18next";
import { sessionPrivileges } from "privileges";

export const PracticePackages = withRouter(
  ({ showReal, isMobile, ...props }) => {
    const Priv = sessionPrivileges().userprivileges || [];
    const { t } = useTranslation();
    const [dataIsLoading, setIfDataIsLoading] = useState(false);
    const [student_practicePackageData, setStudent_practicePackageData] =
      useState({
        total: null,
        data: [],
      });

    const apiResp = useGetDashboardInfo(
      "getStudentDashBoardPackages",
      showReal
    );

    const setData = (data) => {
      setStudent_practicePackageData(
        data?.practicePackage || { total: 0, data: [] }
      );
    };

    useEffect(() => {
      if (apiResp === "Fetching...") {
        setIfDataIsLoading(true);
      }
      if (apiResp && apiResp.hasOwnProperty("practicePackage")) {
        setData(apiResp);
        setIfDataIsLoading(false);
      }
    }, [apiResp]);

    useEffect(() => {
      setData(PackagesSample);
    }, []);

    return dataIsLoading ? (
      <SkeletonGridItem
        gridPosition={[
          isMobile ? [1, -1] : [6, 9],
          showReal ? (isMobile ? [6, 7] : [3, 5]) : isMobile ? [6, 7] : [4, 6],
        ]}
      />
    ) : (
      <GridItem
        gridPosition={[
          isMobile ? [1, -1] : [6, 9],
          showReal ? (isMobile ? [6, 7] : [3, 5]) : isMobile ? [6, 7] : [4, 6],
        ]}
      >
        <DataTableContainer
          description={`You enrolled in ${student_practicePackageData.total} packages`}
          title="Practice Packages"
          footerLinkText="View More"
          footerLinkOnClick={() => {
            props.history.push("/lab");
          }}
        >
          {student_practicePackageData.data.map(
            ({ id, practiceName: name, practiceProgress: progress }, idx) => (
              <PracticePackageListItem
                key={idx}
                onActionButtonClick={() => {
                  props.history.push({
                    pathname: "/view-practice-questions",
                    params: { labId: id },
                  });
                }}
                percentageCompleted={progress}
                name={name}
                hidePPView={Priv.includes("VIEW_PRACTICE_PACKAGE")}
              />
            )
          )}
        </DataTableContainer>
      </GridItem>
    );
  }
);
