import React, { Component } from "react";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "react-datepicker/dist/react-datepicker.css";
import { connect } from "react-redux";
import { saveQuestion } from "../../store/question/actions";
import CreateQueComponent from "./QuestionForm";
import { ParseCodingQuestion } from "./utils/SetQuestion/CD";
import Axios from "axios";
import Apihelper from "../../api/apiHelper";
import api from "../../api/baseConfig";
import { SendMediaHof } from "pages/utils/SendMediaHOF";
import Cookies from "universal-cookie";
import { sessionPrivileges } from "privileges";
import { toast } from "react-toastify";

class EditQuestionComponent extends Component {
  constructor(props) {
    super(props);
    let qId = this.props.location?.state?.id;
    this.userPrivileges = sessionPrivileges(true);
    this.UserEmail = this.userPrivileges.userName;
    this.state = {
      saveState: "Save",
      startDate: new Date(),
      endDate: new Date(),
      editableQuestion: {},
      questionBankId: qId,
      typeValues: [],
      hints: [],
      solutions: [],
      defaultSolutions: [],
      qbId: this.props.location?.state?.id,
      customerName: this.props.location?.state?.customerName,
      customCheckerData: [],
      categoryName: "",
    };
  }

  componentDidMount() {
    const cookies = new Cookies();
    const {
      match: { params },
    } = this.props;
    let questionId = params.questionId;
    var config = {
      method: "post",
      url: `${api.baseURL}${api.questionController.getQuestionById}`,
      headers: {
        authorization: cookies.get("userToken"),
        "Content-Type": "application/json",
      },
      params: {
        id: questionId,
      },
    };
    Axios(config).then(async (res) => {
      let response = res.data;
      if (response && response.hints && response.hints.length > 0) {
        let temp = [];

        response.hints.forEach((hint, ind) => {
          for (const key in hint) {
            let tempHint = {};
            tempHint["desc"] = hint[key];
            tempHint["id"] = ind;
            temp.push(tempHint);
          }
        });
        this.setState({ hints: temp });
      }
      if (response && response.type === "MC") {
        let temp = [];
        response.optionsList.forEach((element, i) => {
          temp.push({
            optionIdentifier: element.optionValue,
            answer: element.answer,
            id: i,
          });
        });
        this.setState({ editableQuestion: response, typeValues: temp });
      } else if (response && response.type === "CD") {
        let languages = await this.getLanguages();
        const { Solutions, TestCases, DefaultSolutions } = ParseCodingQuestion(
          languages,
          response.solutionList,
          response.defaultSolutionList,
          response.testCaseList
        );
        this.setState({
          editableQuestion: response,
          typeValues: TestCases,
          solutions: Solutions,
          defaultSolutions: DefaultSolutions,
        });
      } else if (response && response.type === "DESC" && response.answerKey) {
        let tempAns = [];
        for (let key in response.answerKey) {
          let i = 0;
          tempAns.push({
            answerKey: key,
            answerKeyPoints: response.answerKey[key],
            id: i,
          });
          i++;
        }
        response.answerKey = tempAns;
        this.setState({ editableQuestion: response, typeValues: tempAns });
      } else {
        this.setState({ editableQuestion: response });
      }
    });
  }

  getLanguages = async () => {
    let languages = [];
    await Apihelper.axiosCall(
      `${api.baseURL}${api.LanguageController.currentLanguage}`,
      "get"
    ).then((data) => {
      data?.forEach?.((element) => {
        languages.push({
          label: element.languageNotation,
          value: element.id,
        });
      });
    });
    return languages;
  };

  parseHints = (hints) => {
    let tempHints = [];

    hints.forEach((item, index) => {
      let hintObject = {};
      hintObject[`hint ${index + 1}`] = item.desc;
      tempHints.push(hintObject);
    });
    return tempHints;
  };

  parseSolutions = (solutions) => {
    let tempSols = solutions.reduce((acc, item) => {
      acc.push({
        solution: item.val,
        languageId: item.lang.value,
      });
      return acc;
    }, []);
    return tempSols;
  };

  removeId = (array) => {
    let temp = array;
    temp.forEach((item) => {
      if (item.hasOwnProperty("id")) {
        delete item.id;
      }
    });
    return temp;
  };
  onSubmit = async (val) => {
    this.setState({ saveState: "Saving..." });
    val.sourceQuestionId = this.state.editableQuestion.sourceQuestionId;
    val.updatedBy = this.UserEmail;
    let spliced = this.removeId(this.state.typeValues);
    switch (val.type) {
      case "MC": {
        val.optionsList = spliced;
        break;
      }
      case "CD": {
        val.testCaseList = spliced;
        break;
      }
      case "DESC": {
        val.answerKey = spliced;
        break;
      }
      case "AUDIO": {
        const formData = new FormData();
        
        if(val.questionSubType != "3"){
          formData.append("audiofile", val.audiofile[0]);
        }

        delete val.audiofile;

        formData.append("data", JSON.stringify(val));
  
        const audioUrl = `${api.baseURL}${api.questionController.createQuestionAB}`; // Define your audio endpoint
        try {
          const audioResponse = await Apihelper.axiosCallPost(audioUrl, "post", formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
              'Allow-Control-Allow-Origin': '*',
            },
          });

          toast.success("Audio question successfully set", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          this.props.history.push({
            pathname: `/view-questions`,
            state: this.props.location?.state,
          });
          return; // Exit after handling audio question
        } catch (e) {
          console.error("Error while submitting audio question:", e);
          toast.error("Failed to set audio question", {
            position: "top-right",
            autoClose: 5000,
          });
        }
        return; // Exit after handling audio question
      }
    }
    if (this.state.solutions.length > 0) {
      val["solutionList"] = this.state.solutions[0].hasOwnProperty("lang")
        ? this.parseSolutions(this.state.solutions)
        : this.state.solutions;
    } else {
      val["solutionList"] = [];
    }

    if (this.state.defaultSolutions.length > 0) {
      val["defaultSolutionList"] =
        this.state.defaultSolutions[0].hasOwnProperty("lang")
          ? this.parseSolutions(this.state.defaultSolutions)
          : this.state.defaultSolutions;
    } else {
      val["defaultSolutionList"] = [];
    }

    if (this.state.hints.length > 0) {
      val.hints = this.state.hints[0].hasOwnProperty("desc")
        ? this.parseHints(this.state.hints)
        : this.state.hints;
    } else {
      val.hints = [];
    }
    if (val?.solutionMedia && typeof val?.solutionMedia !== "string") {
      val.solutionMedia = await SendMediaHof(
        val?.solutionMedia,
        "questionBank",
        this.state.customerName,
        this.state.qbId
      );
    }
    const response = await this.props.saveQuestion(val);
    let url =
      response.payload.type === "CD"
        ? `${api.baseURL}${api.questionController.createQuestionCoding}`
        : `${api.baseURL}${api.questionController.createQuestionMCQ}`;
    let customCheckerData = { customCheckerData: this.state.customCheckerData };
    let body = {
      ...response.payload,
      category: this.state.categoryName,
      customCheckerData,
    };
    try {
      Apihelper.axiosCallPost(url, "post", body).then((data) => {
        const { history } = this.props;
        history.push({
          pathname: `/view-questions`,
          state: this.props.location?.state,
        });
      });
    } catch (e) {}
  };
  render() {
    return (
      <CreateQueComponent
        onSubmit={this.onSubmit}
        initialValues={this.state.editableQuestion}
        idAndName={this.props.location?.state}
        saveState={this.state.saveState}
        handleCallBack={(data) => {
          this.setState({ customCheckerData: data });
        }}
        setCategoryName={(name) => {
          this.setState({ categoryName: name });
        }}
        setTypeValues={(list) => {
          this.setState({ typeValues: list });
        }}
        typeValues={this.state.typeValues}
        setHints={(hints) => {
          const tempHints = this.parseHints(hints);
          this.setState({ hints: tempHints });
        }}
        hints={this.state.hints}
        setSols={(sols) => {
          const tempSols = this.parseSolutions(sols);
          this.setState({ solutions: tempSols });
        }}
        solutions={this.state.solutions}
        setDefSols={(sols) => {
          const tempSols = this.parseSolutions(sols);
          this.setState({ defaultSolutions: tempSols });
        }}
        defaultSolutions={this.state.defaultSolutions}
      />
    );
  }
}
const mapStateToProps = () => ({});
export default connect(mapStateToProps, { saveQuestion })(
  EditQuestionComponent
);
