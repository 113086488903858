import api from "../../api/baseConfig";
import Apihelper from "../../api/apiHelper";

export const getQuestionsByIds = async (questionIds) => {
  const body = { questionIds };
  return await Apihelper.axiosCallSimpleData(
    `${api.baseURL}${api.Prepare.getQuestionList}`,
    "POST",
    body
  ).then((res) => (res && res.length > 0 ? res : []));
};

export const getVideoQuizReport = async (id) => {
  return await Apihelper.axiosCallTokenData(
    `${api.baseURL}${api.Prepare.studentVideoQuizReport}${id}`,
    "GET",
  ).then((res) => res);
};

export const saveVideoQuizQuestionAnswer = async (data) => {
  return await Apihelper.axiosCallTokenData(
    `${api.baseURL}${api.Prepare.videoQuizzSolution}`,
    "POST",
    data
  ).then((res) => res);
};

export const getAllQuestionBanks = async () => {
  const allFilterTypes = [{ label: "All question bank", value: "all" }];
  let tempList = allFilterTypes.filter((item) => item.value === "all");
  await Apihelper.axiosCall(
    `${api.baseURL}${api.questionBankController.ApproveBank}`,
    "GET"
  ).then((datum) => {
    if (datum) {
      datum.forEach((i) => {
        tempList.push({ value: i.id, label: i.name });
      });
    }
  });
  return tempList;
};

export const getLanguages = async () => {
  let options = [];

  await Apihelper.axiosCall(
    `${api.baseURL}${api.LanguageController.currentLanguage}`,
    "get"
  ).then((data) => {
    data?.forEach?.((element) => {
      options.push({
        label: element.languageNotation,
        value: element.id,
      });
    });
  });

  return options;
};
