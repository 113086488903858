import api from "../../api/baseConfig";
import Apihelper from "../../api/apiHelper";

class MasterAPIs {
  async ALL_CATEGORIES() {
    let options = [];
    await Apihelper.axiosCall(
      `${api.baseURL}${api.masterController.getAllCategory}`,
      "get"
    ).then((data) => {
      if (data) {
        data.forEach((element) => {
          options.push({
            label: element.name,
            value: element.id,
          });
        });
      }
    });
    return options;
  }

  async ALL_COURSE_CATEGORIES() {
    let options = [];
    await Apihelper.axiosCall(
      `${api.baseURL}${api.masterController.getAllCourseCategory}`,
      "get"
    ).then((data) => {
      if (data) {
        data.forEach((element) => {
          options.push({
            label: element.name,
            value: element.id,
          });
        });
      }
    });
    return options;
  }

  ALL_TAXONOMIES = async () => {
    let options = [];

    await Apihelper.axiosCall(
      `${api.baseURL}${api.questionController.getTaxonomies}`,
      "get"
    ).then((data) => {
      data.forEach((element) => {
        options.push({
          label: element.name,
          value: element.id,
        });
      });
    });
    return options;
  };

  ALL_SUB_TAXONOMIES = async (tax) => {
    0;
    let options = [];

    if (!tax.hasOwnProperty("value")) {
      tax = { value: tax };
    }
    await Apihelper.axiosCall(
      `${api.baseURL}${api.questionController.getSubTaxonomy}${tax.value}`,
      "get"
    ).then((data) => {
      data.forEach((element) => {
        options.push({
          label: element.name,
          value: element.id,
        });
      });
    });
    return options;
  };

  ALL_PROFICIENCIES = async () => {
    let options = [];
    await Apihelper.axiosCall(
      `${api.baseURL}${api.questionController.getProficiencies}`,
      "get"
    ).then((data) => {
      data.forEach((element) => {
        options.push({
          label: element.name,
          value: element.id,
        });
      });
    });
    return options;
  };

  async ALL_TOPICS(id) {
    let options = [];
    await Apihelper.axiosCall(
      `${api.baseURL}${api.masterController.getAllTopic}${id}`,
      "get"
    ).then((data) => {
      if (data) {
        data.forEach((element) => {
          options.push({
            label: element.name,
            value: element.id,
          });
        });
      }
    });
    return options;
  }

  async ALL_SUB_TOPICS() {
    let options = [];
    await Apihelper.axiosCall(
      `${api.baseURL}${api.masterController.getAllCategory}`,
      "get"
    ).then((data) => {
      data.forEach((element) => {
        options.push({
          label: element.name,
          value: element.id,
        });
      });
    });
    return options;
  }
}

export default new MasterAPIs();
