import styles from "./AssessmentChecks.module.scss";
import { BXMic, BXCamera, BXChrome } from "../../assets/icons/icons";
import { MicCheck } from "./MicCheck";
import { circleCheckSolid, circleCrossFilled } from "assets/icons/icons";
import { useCallback, useRef, useState } from "react";
import Webcam from "react-webcam";
import { TervButtonFilled } from "components/TervButtons";
import { CamCheck } from "./CamCheck";
import { browserCheck } from "pages/Taketest/common/testHelper";
import { uid } from "react-uid";
import { ErrorBlock } from "components/ErrorBlock";

const openSeb = (link) => {
  const sebLink = `SEB://${link.slice(8)}`;
  window.location.href = sebLink;
};

const CheckDiv = ({ value, icon, access, uniqKey }) => (
  <div
    className={`${styles.checkDiv} ${!access && styles.redBorder}`}
    key={uniqKey}
  >
    <div>
      {icon}
      {value}
    </div>
    <div>
      {access
        ? circleCheckSolid({ size: 24, color: "#5AC62F" })
        : circleCrossFilled({ size: 24, color: "#E22B12" })}
    </div>
  </div>
);

const AssessmentChecks = ({ setCheckModal, sebUrl, type }) => {
  const [micAccess, setMicAccess] = useState(false);
  const [camAccess, setCamAccess] = useState(false);

  MicCheck((boo) => setMicAccess(boo));
  CamCheck((boo) => setCamAccess(boo));

  const webcamRef = useRef(null);
  const [userPhoto, setUserPhoto] = useState(null);

  const captureUserPhoto = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setUserPhoto(imageSrc);
  }, [webcamRef, setUserPhoto]);

  const allChecks = [
    {
      label: "Camera check",
      icon: <BXCamera size="24" color="#7C7887" />,
      access: camAccess,
    },
    {
      label: "Microphone check",
      icon: <BXMic size="24" color="#96939F" />,
      access: micAccess,
    },
    {
      label: "Browser check",
      icon: <BXChrome size="24" color="#96939F" />,
      access: browserCheck(),
    },
  ];

  return (
    <div className={styles.main}>
      <div className={styles.proctoringBox}>
        <div className={styles.webcamContainer}>
          <p className={styles.title}>Webcam verification</p>
          <div className={styles.webcamItem}>
            <Webcam
              audio={false}
              ref={webcamRef}
              screenshotFormat="image/jpeg"
            />
          </div>
          <TervButtonFilled
            text="Take photo"
            onClick={captureUserPhoto}
            secondary
          />
        </div>
        <div>
          <p className={styles.title}>System check</p>
          <p className={styles.desc}>
            Once all checks are completed you can take the assessment
          </p>
          {allChecks.map((it, ind) => (
            <CheckDiv
              value={it.label}
              icon={it.icon}
              allChecks={allChecks}
              access={it.access}
              uniqKey={uid(ind)}
            />
          ))}
          {!micAccess || !camAccess || !browserCheck() ? (
            <ErrorBlock
              eType="div"
              errorText="You cant Proceed to test, make sure are checks are completed"
            />
          ) : (
            ""
          )}
          <TervButtonFilled
            text="Proceed to test"
            onClick={() =>
              type === "launch" ? setCheckModal() : openSeb(sebUrl)
            }
            justifyCenter
            disabled={!micAccess || !camAccess || !browserCheck()}
          />
        </div>
      </div>
    </div>
  );
};

export default AssessmentChecks;
