import axios from "axios"

let createQuestionTemplate = {
  skillWeightage: "",
  knowledgeWeightage: "",
  marks: "",
  negativeMarks: "",
  duration: "",
  proficiencyId: "",
  taxonomyId: "",
  subTaxonomyId: "",
  active: true,
  category: "",
  complexity: "",
  createdBy: "",
  class: "",
  createdDate: "",
  description: "",
  helpText: "",
  inputConstraints: "",
  inputFormat: "",
  languages: [],
  media: "",
  name: "",
  answerKey: [],
  optionsList: [
    {
      active: true,
      answer: true,
      createdBy: {},
      createdDate: "",
      id: {},
      media: "",
      optionIdentifier: "",
      optionValue: "",
      questionId: {},
      updatedBy: {},
      updatedDate: "",
    },
  ],
  outputFormat: "",
  private: true,
  multiSelect: false,
  question: "",
  questionNotation: "",
  sampleInput: "",
  sampleOutput: "",
  // inputFormat: "",
  // outputFormat: "",
  // inputConstraint: "",
  source: "",
  testCaseList: [],
  type: "string",
  updatedBy: "",
  updatedDate: "",
  questionBankId: "",
  
}
export const getQuestions = () => ({
  type: "GET_QUESTIONS",
})

export const getQuestionsSuccess = Questions => ({
  type: "GET_QUESTIONS_SUCCESS",
  payload: Questions,
})

export const getQuestionsFail = error => ({
  type: "GET_QUESTIONS_FAIL",
  payload: error,
})

export const saveQuestion = val => {
  let actualPayload = { ...createQuestionTemplate, ...val }
  let finalPayload = {}
  let { category, type } = actualPayload
  let options = []
  let testcases = []
  let answerKeyList = {}
  if (type == "MC") {
    let optionSchema = {
      active: true,
      answer: true,
      createdBy: "",
      createdDate: "",
      id: {},
      media: "",
      optionIdentifier: "",
      optionValue: "",
      questionId: {},
      updatedBy: "",
      updatedDate: "",
    }
    let { optionsList } = actualPayload
    optionsList.map((o, i) => {
      let temp = { ...optionSchema, ...o }
      if (temp.optionValue === "") {
        temp.optionValue = temp.optionIdentifier
        
      }
      temp.id = temp.id ? temp.id : {}
      options.push(temp)
      if (i == o.length - 1) {
        finalPayload = { ...actualPayload, optionsList: options }
      }
    })
  } else if (type == "CD") {
    let testcaseSchema = {
      active: true,
      createdBy: "",
      created_at: "",
      id: {},
      inputText: "",
      languageId: {},
      marks: 0,
      outputText: "",
      questionId: {},
      updatedBy: "",
      updated_at: "",
    }
    
    

    let { testCaseList } = actualPayload
    testCaseList.map((t, i) => {
      let temp = { ...testcaseSchema, ...t }
      testcases.push(temp)
      temp.id = temp.id ? temp.id : {}
    })
  } else if (type == "DESC") {
    let descriptiveSchema = {
      active: true,
      createdBy: "",
      created_at: "",
      id: {},

      answerKey: {},
      marks: 0,

      questionId: {},
      updatedBy: "",
      updated_at: "",
    }

    let answerKey = {}
    actualPayload.answerKey.forEach(a => {
      answerKey[a.answerKey] = a.answerKeyPoints
    })
    descriptiveSchema.answerKey = answerKey
    answerKeyList = descriptiveSchema
  }
  return {
    type: "SAVE_QUESTION",
    payload: {
      ...actualPayload,
      optionsList: options,
      testCaseList: testcases,
      answerKey: answerKeyList.answerKey,
      
      
    },
  }
}
