export function ParseCodingQuestion(
  languages,
  solutions,
  defaultSolutions,
  testCases
) {
  let Solutions = [],
    TestCases = [],
    DefaultSolutions = [];

  testCases.forEach((tc, i) => {
    TestCases.push({
      id: i,
      inputText: tc.inputText,
      outputText: tc.outputText,
    });
  });
  if (solutions && solutions.length) {
    solutions.forEach((sol, id) => {
      Solutions.push({
        val: sol.solution,
        lang: languages.filter((l) => l.value === sol.languageId)[0],
        id,
      });
    });
  }
  if (defaultSolutions && defaultSolutions.length) {
    defaultSolutions.forEach((sol, id) => {
      DefaultSolutions.push({
        val: sol.solution,
        lang: languages.filter((l) => l.value === sol.languageId)[0],
        id,
      });
    });
  }
  return { Solutions, TestCases, DefaultSolutions };
}
