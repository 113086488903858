/** @format */

import {
  CompanyLogos,
  featuresIcons,
  MobileSlideThree,
  MobileSlideFour,
} from "./SVGs";

type Obj = {
  [key: string]: any;
};

export const HomeContent: Obj = {
  Headers: {
    Prospects: {
      Main: "#TERVTRIBE",
      Sub: "makes a difference at",
      Description: "Tech Unicorns, Soonicorns, and MNCs..",
    },
    Trusted: {
      Main: "Trusted by  200+ Enterprises",
      Sub: "",
      Description: "Tech Unicorns, Soonicorns, and MNCs..",
    },
    Learning: {
      Main: "LEARNING",
      Sub: "Defining the future of",
      Description: "Fuelled by Technology, Powered for Tomorrow.",
    },
    Courses: {
      Main: "LOVE",
      Sub: "Find a course you really",
      Description: "Explore over 100+ free courses and company specific tests.",
    },
    Difference: {
      Main: "DIFFERENT",
      Sub: "Learning with us is",
      Description: "Everything you need, in one streamlined platform.",
    },
    Group: {
      Sub: "We provide you with ultimate",
      Main: "UPDATES",
      Description: "Get latest updates on your fingertips, Join terv group",
    },
    Resources: {
      Main: "inspired",
      Sub: "Resources for our learners, Get",
      Description: "Top curated resources to boost your knowledge and skills",
    },
    Career: {
      Sub: "Don't just land a job, launch a",
      Main: "career",
      Description: "and over 12000+ satisfied students professionals",
    },
  },
  Carousel: {
    SlideOne: {
      header: {
        Sub1: "Dont draft it, craft it",
        Main1: ["Future", "Careers"],
        Bracket1: "{",
        Bracket2: "}",
        Main2: "HACK",
        Sub2: "A Dream Job Recipe",
      },
      buttons: {
        one: "Enroll",
        two: "Try for free",
      },
      companies: {
        amazon: {
          role: "Software Engineer",
          pkg: "20 LPA",
          logo: CompanyLogos.amazon,
        },
        facebook: {
          role: "Full Stack developer",
          pkg: "24 LPA",
          logo: CompanyLogos.facebook,
        },
        apple: {
          role: "Full Stack developer",
          pkg: "30 LPA",
          logo: CompanyLogos.apple,
        },
      },
      logoBackground:
        "https://d2ndap9rlps54r.cloudfront.net/Home/Celebration.png",
      //  logo: "https://d2ndap9rlps54r.cloudfront.net/Home/GetPaidStock1.png",
      // logo:CompanyLogos.banner4girl,
        // logo: "./Banner2NewGirl.png",
        
    },
    SlideTwo: {
      header: {
        Sub1: "Get access to 200+ courses and get educated",
        Main1: "Terv partnered with ",
        Main2: "Harappa Education",
        Main3: "partnered With:",
        logo: CompanyLogos.harappa,
      },
      buttons: {
        one: "Enroll Now",
        two: "Try for free",
      },
       //banner: CompanyLogos.girl,
      bannerDots: "https://d2ndap9rlps54r.cloudfront.net/Home/Group392.png",
      logo: "https://d2ndap9rlps54r.cloudfront.net/Home/tcs-nqt-logo1.png",
      //logo1: CompanyLogos.girl,
    },
    SlideThree: {
      header: {
        Main1: "Weave your future, Loom with",
        Main2: "MICROTHREAD",
        Main3: "series",
        logo: "",
        Sub1: "A Certificate Course",
      },
      backgroundLine: "https://d2ndap9rlps54r.cloudfront.net/Home/lines.png",
      halfCircle: "assets/Home/HalfCircle1.png",
      buttons: {
        one: "Coming Soon",
      },
      mobileHalfCircle1: MobileSlideThree.halfCircle1,
      mobileHalfCircle2: MobileSlideThree.halfCircle2,
      mobileDots: MobileSlideThree.dots,
      logo: "https://d2ndap9rlps54r.cloudfront.net/Home/Certificate.png",
    },
    SlideFour: {
      header: {
        Main: "Terv partnered with ",
        Main1: "Godaddy Academy",
        Main2: "Get access to 200+ courses",
        Main3: "Partnered with:",
        // vector: "./GoDaddy.svg",
        Subs: ["Wipro", "Infosys", "TCS", "Cognizant", "HCL"],
        logo: CompanyLogos.godaddy,
        mobileLogo: MobileSlideFour.mobileTervLogo,
      },
      buttons: {
        one: "Enroll",
        two: "Try for free",
      },
      upperCircle: MobileSlideFour.upperCircle,
      logo: "https://d2ndap9rlps54r.cloudfront.net/Home/witch.png",
    },
    SlideFive: {
      header: {
        logo: "/terv-logo.png",
        Main: "is proud to partner with",
        Main1: "FutureSkills PRIME",
        Main2:"The National Platform for Digital Skilling",
        Main3:"Elevate your career by practicing on industry-relevent programming langauages like python, java, javascript, C++, css & HTML",
        Main4:"Start learning on these Virtual Labs & earn industry-recognised certificate endorsing yours skills",
        Main5:"on FutureSkills Prime",
        mobileLogo: MobileSlideFour.mobileTervLogo,
      },
      buttons: {
        one: "Sign up",
      },
      upperCircle: MobileSlideFour.upperCircle,
    },
  },
  LearningCards: [
    {
      title: "PREPARE",
      sub: "Master a new skill",
      src: "https://d2ndap9rlps54r.cloudfront.net/Home/prepareCard.svg",
      description:
        "Certificate courses and preparatory materials developed by industry leaders are integrated with video series, quizzes and assignments",
    },
    {
      title: "PRACTICE",
      sub: "Outsmart competition",
      src: "https://d2ndap9rlps54r.cloudfront.net/Home/practiceCard.svg",
      description:
        "Curated questions at varied difficulty levels and from previous year tests are guided with hints, explanation videos, test cases and solutions in a state-of-the-art environment.",
    },
    {
      title: "PERFORM",
      sub: "Launch a career",
      src: "https://d2ndap9rlps54r.cloudfront.net/Home/performCard.svg",
      description:
        "Try our daily contests, hackathons, company-specific mock tests. With Aptitude, Coding, Written tests and 6 other modules on a single platform, we've got you covered.",
    },
  ],
  CoursesTabs: ["COURSES","COMPANY TEST PAPERS"],
  // CoursesTabs: ["COMPANY TEST PAPERS"],
  CoursesMock: [
    {
      name: "Introduction to artificial intelligence",
      media:
        "https://d2ndap9rlps54r.cloudfront.net/DefaultUploadImages/default-6.jpeg",
      duration: "4",
      chapters: "12",
      rating: 4.0,
      level: "Intermediate",
      originalPrice: "999",
      strikingPrice: "499",
      tag: "Premium",
    },
    {
      name: "Introduction to artificial intelligence",
      media:
        "https://d2ndap9rlps54r.cloudfront.net/DefaultUploadImages/default-6.jpeg",
      duration: "4",
      chapters: "12",
      rating: 4.0,
      level: "Intermediate",
      originalPrice: "999",
      strikingPrice: "499",
      tag: "Premium",
    },
    {
      name: "Introduction to artificial intelligence",
      media:
        "https://d2ndap9rlps54r.cloudfront.net/DefaultUploadImages/default-6.jpeg",
      duration: "4",
      chapters: "12",
      rating: 4.0,
      level: "Intermediate",
      originalPrice: "999",
      strikingPrice: "499",
      tag: "Premium",
    },
  ],
  PerfectCourse: {
    Main: "Find your perfect course!",
    Sub: "Answer a few questions & our program matching tool will do the rest",
  },
  Features: [
    {
      header: "Perfect Practice Environment",
      description:
        "Become a Super-Hero with a fully integrated, new-age learning platform with 10x efficiency and multi-lingual programming features in a seamless execution environment, bringing you close to the real world with assistance to solve every question with hints, test cases, video explanations and expert-led solutions. All in one workspace with no installation or setup time.",
      image: featuresIcons.environment,
    },
    {
      header: "Curated Question Sets",
      description:
        "We work with our vast global network of subject matter experts &  engineers to ensure our updated question sets prepare you to meet today's job demands. Our curated question sets of  interview questions are as close as the real test, truly preparing you for the future you ever wanted.",
      image: featuresIcons.questionSets,
    },
    {
      header: "Rich performance analytics to progress & get certified",
      description:
        "See your progress with rich analytics & data points for improvement. Get awarded with points, badges, and rank up against other candidates. Not just that, completing all the challenges will earn you a certificate of preparedness for your dream job.",
      image: featuresIcons.certified,
    },
    {
      header: "Crash Courses ",
      description:
        "Learn new skills in just a few hours alongside industry experts. Meet a series of milestones to gain micro-credentials that set you ahead with the comfort of learning at your own pace. TERV's most sophisticated LMS features real-time collaboration in a state-of-the-art practice environment",
      image: featuresIcons.crashCourses,
    },
    {
      header: "Time - Space Complexity Analysis",
      description:
        "We drop the constants and make your solution work efficiently that no input is arbitrarily large.",
      image: featuresIcons.complexity,
    },

    {
      header: "Job Support & vast employer network",
      description:
        "Don't settle. Our global community of hiring partners includes the best companies across a range of hot industries. You get to engage with potential employer ahead of completion through 1-1 mock interview with feedback session and frequent networking sessions.",
      image: featuresIcons.support,
    },
  ],
  JoinGroup: {
    Perks: {
      header: "Perks of joining our Community",
      content: [
        "Receive latest update of On-campus and Off-campus placement",
        "Various coupons for courses and Practice packages",
        "Free webinars, resources and question papers",
        "Various job updates and placement guides ",
        "Get job preparation resources, interview tips and tricks",
      ],
    },
    Box: {
      description: "Click the below button to get updates",
      whatsapp: "Join Whatsapp group",
      telegram: "Join Telegram channel",
    },
  },
  Resources: [
    {
      tag: "BLOG",
      title: "The Art of Communication",
      description:
        "Communication is a work of art. And your ability to communicate will sit somewhere",
      link: "https://blog.terv.pro/interview_prep/the-art-of-communication/",
      image:
        "https://blog.terv.pro/wp-content/uploads/2021/08/Frame-1417-1.png",
    },
    {
      tag: "BLOG",
      title: "What not to talk about during your first date!",
      description:
        "First dates can cause so much anxiety. Talking back and forth, trying to create some sort of",
      link: "https://blog.terv.pro/college_life/what-not-to-talk-about-during-your-first-date/",
      image: "https://blog.terv.pro/wp-content/uploads/2021/08/Frame-1418.png",
    },

    {
      tag: "BLOG",
      title: "Writing Effectively for Business",
      description:
        "Business writing is a type of writing that is used in a professional setting. It is a purposeful piece",
      link: "https://blog.terv.pro/career_hacks/writing-effectively-for-business/",
      image: "https://blog.terv.pro/wp-content/uploads/2021/08/Frame-1234.png",
    },
    {
      tag: "BLOG",
      title: "An hour before your Exam | Effective Exam Tips",
      description:
        "The most anxiety-inducing countdown will begin before your exam starts. That last one",
      link: "https://blog.terv.pro/interview_prep/an-hour-before-your-exam-effective-exam-tips/",
      image: "https://blog.terv.pro/wp-content/uploads/2021/08/Frame-1416.png",
    },
    // {
    //   tag: "BLOG",
    //   title:
    //     "An hour before your Job Interview | What do you need to do with it?",
    //   description:
    //     "We have got you a surprise. Are you ready for that? Let's explore our hours before a job",
    //   link: "https://blog.terv.pro/interview_prep/an-hour-before-your-job-interview-what-do-you-need-to-do-with-it/",
    //   image: "https://blog.terv.pro/wp-content/uploads/2021/08/Frame-1415.png",
    // },
    // {
    //   tag: "BLOG",
    //   title:
    //     "Chennai is turning into SaaS Talent Pool | A Silent SaaS Revolution",
    //   description:
    //     "Gone are the days for Bengaluru getting compared with the US Silicon Valley. Chennai is",
    //   link: "https://blog.terv.pro/career_hacks/chennai-is-turning-into-saas-talent-pool-a-silent-saas-revolution/",
    //   image:
    //     "https://blog.terv.pro/wp-content/uploads/2021/08/software-computer-code-1940x900_35196.jpg",
    // },
  ],
  Testimonials: [
    {
      description:
        "“Free, fun way to learn a lot of different important coding concepts. The challenges appeal to people of all skill levels, the lessons help beginners and the forums are a great place to learn more”",
      author: "Balasuppiramaniyan V",
      designation: "Mu-sigma",
      image:
        "https://d2ndap9rlps54r.cloudfront.net/DefaultUploadImages/balu.jpg",
    },
    {
      description:
        "“Online code processing is fast, allows for own user input, To be honest the design is also quite good. A lot of how a “challenge” goes is dependent on how the author writes it, but i find a lot of them to be designed well”",
      author: "Arun kumar R",
      designation: "Infoview, Trainee engineer",
      image:
        "https://d2ndap9rlps54r.cloudfront.net/DefaultUploadImages/arun.jpg",
    },
    {
      description:
        "“A great coding platform, it's easy to use with a clean interface. I've enjoyed the excercises available. The problem statement and examples have great clarity without being too wordy or short”",
      author: "Ramanan A",
      designation: "Cognizant",
      image:
        "https://d2ndap9rlps54r.cloudfront.net/DefaultUploadImages/ramanan.jpg",
    },
  ],
  Success: {
    tag: "GET STARTED",
    header: "Ready to become next success story?",
    list: [
      "Discover new courses & preparatory materials",
      "Practice with our company specific mock tests",
      "Improve your performance using our Advanced Analytics",
      "Ace the interviews and Launch a Career",
      "Get access to special courses and programms",
    ],
    form: {
      title: "Drop your details below",
      terms:
        "By clicking book a call you accept our terms will also recieve exclusive offers, updates about new courses, packages",
      buttonText: "Book a call",
    },
  },
  Footer: {
    headers: [
      "PRODUCT",
      "IMPORTANT LINKS",
      "RESOURCES",
      "PLANS",
      "Course subjects",
      "Free resources",
      "Prepare resources",
      "CONTACT US",
      "ADDRESS",
    ],
    sub: {
      resources: ["Blogs", "Cheatsheets", "Podcasts"],
      plans: ["For institutions", "For students"],
      subjects: [
        "Web development",
        "Data science",
        "C language",
        "Web design",
        "Coding courses",
        "Aptitude courses",
      ],
      freeResources: [
        "Free courses",
        "Free question banks",
        "Free practice packages",
        "Free assessments",
      ],
      prepareResources: [
        "TCS question paper",
        "Infosys question bank",
        "CTS latest question banks",
        "TCS coding ninja pack",
      ],
    },
    address: {
      line1:
        "149, 1C & 1D, 2nd Floor, (Above Axis Bank), Mount Poonamalle High Rd, Ramapuram, Chennai, Tamil Nadu 600089",
      landLine: " +91 44-48538323",
    },
  },
  Terms: {
    titles: [
      { label: "Terms and conditions", to: "/termsAndConditions" },
      { label: "Privacy", to: "/privacy" },
      { label: "Legal", to: "/legal" },
    ],
    desc: "2021 © terv. All rights reserved",
    logo: "%PUBLIC_URL%/chittilogo.svg",
  },
  SafePayments: {
    description: "Our accepted payment methods:",
    safe: "100% safe and secure payment",
  },
  Cookie: {
    description: `By clicking "Accept all cookies" you agree to storing of cookies on your
    device to enhance site navigation, analyze site usage and assist in out
    marketing efforts.`,
  },
};
