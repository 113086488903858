import { useEffect, useState } from "react";
import { ResponsiveBar } from "@nivo/bar";
import truncate from "truncate-html";
import GridItem from "../../../components/GridItem/GridItem";
import SkeletonGridItem from "../../../components/SkeletonGridItem/SkeletonGridItem";
import ChartContainer from "../../../components/ChartContainer/ChartContainer";
import { useGetDashboardInfo } from "pages/Dashboard/SWRhooks";
import { AdminStudentEnrolled } from "pages/Dashboard/SampleData";

const color = {
  count: "#3F09D6",
};
const getColor = (bar) => color[bar.id];

export function StudentEnrollment({ showReal, isMobile }) {
  const [dataIsLoading, setIfDataIsLoading] = useState(false);
  const [packageEnrollmentData, setPackageEnrollmentData] = useState([]);
  const [packageEnrollmentMaxValue, setPackageEnrollmentMaxValue] =
    useState(null);
  const [packageEnrollmentViewOption, setPackageEnrollmentViewOption] =
    useState({
      name: "",
      value: "",
    });
  const [packageEnrollmentViewOptions, setPackageEnrollmentViewOptions] =
    useState([]);
  const [packageEnrollmentDetails, setPackageEnrollmentDetails] = useState({});

  useEffect(() => {
    if (packageEnrollmentViewOption.value) {
      const data = packageEnrollmentDetails[packageEnrollmentViewOption.value];
      const maxValue =
        Math.max(0, ...(data || []).map((a) => [a?.count]).flat()) || 1;

      setPackageEnrollmentData(data);
      setPackageEnrollmentMaxValue(maxValue + maxValue / 5);
    }
  }, [packageEnrollmentViewOption]);

  const apiResp = useGetDashboardInfo(
    "getAdminDashBoardStudentEnrolled",
    showReal
  );

  const setData = (data) => {
    const studentPackageEnrollmentGroups = [];
    const studentPackageEnrollmentDetails = {};

    data.studentPackageEnrollmentDetails.slice(0, 5).forEach((g, idx) => {
      studentPackageEnrollmentGroups.push({
        name: g.name,
        value: `SEP-${idx + 1}`,
      });
      studentPackageEnrollmentDetails[`SEP-${idx + 1}`] = (g.data || []).map(
        (d) => ({
          package: d?.name,
          count: d.count,
        })
      );
    });

    setPackageEnrollmentDetails(studentPackageEnrollmentDetails);
    setPackageEnrollmentViewOptions(studentPackageEnrollmentGroups);
    setPackageEnrollmentViewOption(studentPackageEnrollmentGroups?.[0] || {});

    if (studentPackageEnrollmentGroups?.[0]?.value) {
      setPackageEnrollmentData(
        studentPackageEnrollmentDetails[studentPackageEnrollmentGroups[0].value]
      );
    } else {
      setPackageEnrollmentData([]);
    }
  };

  useEffect(() => {
    if (apiResp === "Fetching...") {
      setIfDataIsLoading(true);
    }
    if (apiResp && apiResp.hasOwnProperty("studentPackageEnrollmentDetails")) {
      setData(apiResp);
      setIfDataIsLoading(false);
    }
  }, [apiResp]);

  useEffect(() => {
    setData(AdminStudentEnrolled);
  }, []);

  return dataIsLoading ? (
    <SkeletonGridItem
      gridPosition={[
        isMobile ? [1, -1] : [7, 13],
        showReal ? (isMobile ? [3, 4] : [5, 6]) : isMobile ? [4, 5] : [5, 6],
      ]}
    />
  ) : (
    <GridItem
      gridPosition={[
        isMobile ? [1, -1] : [7, 13],
        showReal ? (isMobile ? [3, 4] : [5, 6]) : isMobile ? [4, 5] : [5, 6],
      ]}
    >
      {packageEnrollmentData && (
        <ChartContainer
          currentOption={packageEnrollmentViewOption.value}
          options={packageEnrollmentViewOptions}
          onOptionChange={setPackageEnrollmentViewOption}
          title={<strong>Students enrolled in packages</strong>}
        >
          <ResponsiveBar
            animate={true}
            axisBottom={{
              legend: "Packages name",
              legendOffset: 45,
              legendPosition: "middle",
              tickPadding: 8,
              tickRotation: 0,
              tickSize: 0,
              renderTick: ({
                textAnchor,
                textBaseline,
                textX,
                textY,
                value,
                x,
                y,
              }) => {
                return (
                  <g transform={`translate(${x},${y + 10})`}>
                    <text
                      alignmentBaseline={textBaseline}
                      textAnchor={textAnchor}
                      transform={`translate(${textX},${textY})`}
                      fontSize={11}
                    >
                      <tspan>{truncate(value, 7)}</tspan>
                    </text>
                  </g>
                );
              },
            }}
            axisLeft={{
              legend: "Students enrolled",
              legendOffset: -20,
              legendPosition: "middle",
              renderTick: () => <></>,
              tickValues: false,
            }}
            axisRight={null}
            axisTop={null}
            colors={getColor}
            data={packageEnrollmentData}
            enableGridX={false}
            enableGridY={false}
            groupMode="grouped"
            indexBy="package"
            innerPadding={5}
            isInteractive={false}
            keys={["count"]}
            labelTextColor="#3A3A3A"
            labelFormat={(d) => <tspan y={-8}>{d}</tspan>}
            margin={{
              bottom: 65,
              left: 30,
              right: 10,
            }}
            maxValue={packageEnrollmentMaxValue}
            minValue={0}
            motionDamping={15}
            motionStiffness={90}
            padding={0.3}
            theme={{
              legends: {
                text: {
                  fontSize: 12,
                },
              },
              axis: {
                domain: {
                  line: {
                    stroke: "#564848",
                    strokeWidth: 1,
                  },
                },
                legend: {
                  text: {
                    color: "#000",
                    fontSize: 14,
                  },
                },
              },
            }}
            valueScale={{ type: "linear" }}
          />
        </ChartContainer>
      )}
    </GridItem>
  );
}
