import { useEffect, useState } from "react";
import { ResponsiveLine } from "@nivo/line";
import GridItem from "../../../components/GridItem/GridItem";
import SkeletonGridItem from "../../../components/SkeletonGridItem/SkeletonGridItem";
import ChartContainer from "../../../components/ChartContainer/ChartContainer";
import styles from "./QuestionsSolved.module.scss";
import { useGetDashboardInfo } from "pages/Dashboard/SWRhooks";
import { QuestionsSolvedSample } from "pages/Dashboard/SampleData";

const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

const questionsSolvedViewOptions = [
  { name: "This Week", value: "this-week" },
  { name: "Past Week", value: "past-week" },
];

function getQuestionsSolvedData(data = []) {
  const returnData = [];
  const sortedData = data.sort(({ day: d1 }, { day: d2 }) => d1 - d2);
  const currentDayIndex = sortedData.findIndex(({ currentDay }) => currentDay);

  const questionsSolvedList = sortedData
    .slice(currentDayIndex + 1, 7)
    .concat(
      currentDayIndex === 0
        ? sortedData[0]
        : sortedData.slice(0, currentDayIndex)
    )
    .concat(currentDayIndex === 0 ? [] : sortedData[currentDayIndex]);

  questionsSolvedList.forEach(({ coding, aptitude, day }) => {
    returnData.push({
      x: days[day],
      y: coding + aptitude || 0,
    });
  });

  return returnData;
}

export function QuestionsSolved({ showReal, isMobile }) {
  const [dataIsLoading, setIfDataIsLoading] = useState(false);
  const [questionsSolvedThisWeek, setQuestionsSolvedThisWeek] = useState([]);
  const [questionsSolvedLastWeek, setQuestionsSolvedLastWeek] = useState([]);
  const [questionsSolvedThisWeekAvg, setQuestionsSolvedThisWeekAvg] =
    useState(0);
  const [questionsSolvedLastWeekAvg, setQuestionsSolvedLastWeekAvg] =
    useState(0);

  const [questionsSolvedViewOption, setQuestionsSolvedViewOption] = useState(
    questionsSolvedViewOptions[0]
  );

  const apiResp = useGetDashboardInfo(
    "getStudentDashBoardQuestionsSolved",
    showReal
  );

  const setData = (data) => {
    setQuestionsSolvedThisWeek(
      getQuestionsSolvedData(data?.questionsSolved?.["this-week"])
    );
    setQuestionsSolvedLastWeek(
      getQuestionsSolvedData(data?.questionsSolved?.["past-week"])
    );
    setQuestionsSolvedThisWeekAvg(
      Math.ceil(
        (data?.questionsSolved?.["this-week"] || [])
          .map(({ coding, aptitude }) => coding + aptitude)
          .reduce((a, b) => a + b, 0) /
          (data?.questionsSolved?.["this-week"] || []).length
      )
    );
    setQuestionsSolvedLastWeekAvg(
      Math.ceil(
        (data?.questionsSolved?.["past-week"] || [])
          .map(({ coding, aptitude }) => coding + aptitude)
          .reduce((a, b) => a + b, 0) /
          (data?.questionsSolved?.["this-week"] || []).length
      )
    );
  };

  useEffect(() => {
    if (apiResp === "Fetching...") {
      setIfDataIsLoading(true);
    }
    if (apiResp && apiResp.hasOwnProperty("questionsSolved")) {
      setData(apiResp);
      setIfDataIsLoading(false);
    }
  }, [apiResp]);

  useEffect(() => {
    setData(QuestionsSolvedSample);
  }, []);

  const hasData =
    !!questionsSolvedThisWeek.length ||
    !!questionsSolvedLastWeek.length ||
    !!questionsSolvedThisWeekAvg.length ||
    !!questionsSolvedLastWeekAvg.length;

  return dataIsLoading ? (
    <SkeletonGridItem
      gridPosition={[
        isMobile ? [1, -1] : [5, 9],
        showReal ? (isMobile ? [3, 4] : [2, 3]) : isMobile ? [4, 5] : [3, 4],
      ]}
    />
  ) : (
    <GridItem
      gridPosition={[
        isMobile ? [1, -1] : [5, 9],
        showReal ? (isMobile ? [3, 4] : [2, 3]) : isMobile ? [4, 5] : [3, 4],
      ]}
    >
      <ChartContainer
        currentOption={questionsSolvedViewOption.value}
        description={`${
          questionsSolvedViewOption.value === "this-week"
            ? questionsSolvedThisWeekAvg
            : questionsSolvedLastWeekAvg
        } average questions solved`}
        options={
          questionsSolvedLastWeek?.length
            ? questionsSolvedViewOptions
            : undefined
        }
        onOptionChange={
          questionsSolvedLastWeek?.length
            ? setQuestionsSolvedViewOption
            : undefined
        }
        contentClassName={styles.questionsSolved}
        title={<strong>Questions Solved</strong>}
      >
        <ResponsiveLine
          data={[
            {
              id: "Questions Solved",
              data:
                questionsSolvedViewOption.value === "this-week"
                  ? questionsSolvedThisWeek
                  : questionsSolvedLastWeek,
            },
          ]}
          margin={{ top: 20, right: 32.5, bottom: 40, left: 25 }}
          xScale={{ type: "point" }}
          yScale={{
            type: "linear",
            min: "0",
            max: "auto",
            stacked: true,
            reverse: false,
          }}
          curve="catmullRom"
          axisTop={null}
          axisRight={null}
          axisBottom={{
            orient: "bottom",
            tickPadding: 10,
            tickRotation: 0,
            tickSize: 0,
          }}
          axisLeft={null}
          colors={["#794DF5"]}
          enableGridX={false}
          enableGridY={false}
          lineWidth={3}
          pointSize={10}
          pointColor="#fff"
          pointBorderWidth={3}
          pointBorderColor="#794DF5"
          pointLabelYOffset={-12}
          enableArea={true}
          enableSlices="x"
          useMesh={true}
          theme={{
            legends: {
              text: {
                fontSize: 16,
              },
            },
            labels: {
              text: {
                fontSize: 16,
              },
            },
          }}
        />
      </ChartContainer>
    </GridItem>
  );
}
