import MasterForm from "./MasterForm";

export default function CreateTaxonomy(props) {
  return (
    <MasterForm
      postLink="createTaxonomy"
      label={{
        breadcrumb: "Taxonomy",
        back: "Taxonomies",
      }}
      backLink="/taxonomy"
      location={{
        ...props.location,
      }}
      history={{
        ...props.history,
      }}
      activePage={props?.location?.state?.activePage}
      itemsPerPage={props?.location?.state?.itemsPerPage}
    />
  );
}
