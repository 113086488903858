import { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  zoomInOut: {
    opacity: 0,
    animation: `$zoomInOut 800ms ${theme.transitions.easing.easeOut}`,
  },
  "@keyframes zoomInOut": {
    "0%": { opacity: 0, transform: "scale(0.5, 0.5)" },
    "50%": { opacity: 1, transform: "scale(1.2, 1.2)" },
    "100%": { opacity: 0, transform: "scale(1.8, 1.8)" },
  },
  video: {
    width: "100%",
    height: "100%",
    maxHeight: 320,
    position: "relative",
    background: "#000",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&.fullScreen": {
      width: "100%",
      height: "100%",
      position: "fixed",
      top: 0,
      left: 0,
      paddingTop: "0",
      zIndex: 1000000,
    },
    "& .player": {
      width: "100%",
      height: "100%",
      position: "absolute",
      top: 0,
      left: 0,
    },
    "& .video": {
      width: "100%",
      height: "100%",
      maxWidth: "100%",
      maxHeight: "100%",
    },
    "& .loading": {
      width: "100%",
      height: "100%",
      position: "absolute",
      top: 0,
      left: 0,
      zIndex: 10,
      justifyContent: "center",
      margin: 0,
      "& .MuiSvgIcon-root": {
        fontSize: 64,
        color: "#ffffff",
      },
      "& .MuiCircularProgress-root": {
        opacity: 0,
      },
      "&.true": {
        "& .MuiCircularProgress-root": {
          opacity: 1,
        },
      },
    },
    "& .controls": {
      position: "absolute",
      bottom: 0,
      width: "100%",
      background:
        "linear-gradient(0deg, rgba(0,0,0,0.5) 0%, rgba(92,92,92,0.8) 100%)",
      backdropFilter: "blur(4px)",
      WebkitBackdropFilter: "blur(4px)",
      border: "0px solid transparent",
      transition: "all 0.3s ease-in-out",
      minHeight: 48,
      zIndex: 100,
      "& .range": {
        width: "100%",
        overflow: "visible",
        position: "absolute",
        top: -12,
        minHeight: 24,
        "&:hover": {
          "& input[type='range']": {
            height: "8px",
            "&::-webkit-slider-thumb": {
              height: "20px",
              width: "20px",
              borderRadius: "50%",
            },
            "&::-moz-range-progress": {
              height: "20px",
              width: "20px",
              borderRadius: "50%",
            },
            "&::-ms-thumb": {
              height: "20px",
              width: "20px",
              borderRadius: "50%",
            },
          },
          "& .rageTrack": {
            height: 8,
            "& .indicator": {
              height: 8,
            },
            "&:before": {
              height: 8,
            },
          },
        },
        "& .rageTrack": {
          width: "100%",
          height: "4px",
          bottom: 10,
          left: 0,
          position: "absolute",
          background: "transparent",
          backgroundImage: "linear-gradient(#794DF5, #794DF5)",
          backgroundSize: "0% 100%",
          backgroundRepeat: "no-repeat",
          "& .indicator": {
            position: "absolute",
            left: "50%",
            bottom: 0,
            height: 4,
            width: 8,
            backgroundColor: "#1BA94C",
            boxShadow: "0 0 2px 0 #555",
          },
          "&:before": {
            content: "' '",
            width: "100%",
            height: "4px",
            bottom: 0,
            left: 0,
            position: "absolute",
            zIndex: -1,
            background: "#D8D6DB",
          },
        },
        "& input[type='range']": {
          zIndex: 1,
          margin: 0,
          padding: 0,
          width: "100%",
          height: "4px",
          borderWidth: 0,
          position: "relative",
          WebkitAppearance: "none",
          background: "transparent",
          "&::-webkit-slider-thumb": {
            WebkitAppearance: "none",
            height: "4px",
            width: "4px",
            background: "#794DF5",
            cursor: "ew-resize",
            boxShadow: "0 0 2px 0 #555",
            transition: "background .3s ease-in-out",
            "&:hover": { background: "#794DF5" },
          },
          "&::-moz-range-progress": {
            WebkitAppearance: "none",
            height: "2px",
            width: "4px",
            background: "#794DF5",
            cursor: "ew-resize",
            boxShadow: "0 0 2px 0 #555",
            transition: "background .3s ease-in-out",
            "&:hover": { background: "#794DF5" },
          },
          "&::-ms-thumb": {
            WebkitAppearance: "none",
            height: "2px",
            width: "6px",
            background: "#794DF5",
            cursor: "ew-resize",
            boxShadow: "0 0 2px 0 #555",
            transition: "background .3s ease-in-out",
            "&:hover": { background: "#794DF5" },
          },
        },
        "& input[type='range']::-webkit-slider-runnable-track": {},
        "& input[type='range']::-moz-range-track": {},
        "& input[type='range']::-ms-track": {},
      },
      "& .actions": {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "12px 16px 14px 16px",
        "& .group": {
          display: "flex",
          alignItems: "center",
          gap: 12,
          "& .volumeCtrl": {
            display: "flex",
            alignItems: "center",
            gap: 8,
            "& .track": { position: "relative", maxWidth: 56 },
            "& .rageTrack": {
              width: "100%",
              height: "4px",
              borderRadius: 2,
              bottom: 6,
              left: 0,
              position: "absolute",
              background: "transparent",
              backgroundImage: "linear-gradient(#f2f2f2, #fbfbfb)",
              backgroundSize: "0% 100%",
              backgroundRepeat: "no-repeat",
              "&:before": {
                content: "' '",
                width: "100%",
                height: "4px",
                bottom: 0,
                left: 0,
                position: "absolute",
                zIndex: -1,
                background: "rgba(255,255,255,0.4)",
                borderRadius: 2,
              },
            },
            "& input[type='range']": {
              zIndex: 1,
              margin: 0,
              padding: 0,
              width: "100%",
              height: "4px",
              borderWidth: 0,
              position: "relative",
              WebkitAppearance: "none",
              background: "transparent",
              "&::-webkit-slider-thumb": {
                WebkitAppearance: "none",
                height: "12px",
                width: "12px",
                background: "#FFFFFF",
                borderRadius: 6,
                cursor: "ew-resize",
                boxShadow: "0 0 2px 0 #555",
                transition: "background .3s ease-in-out",
                "&:hover": { background: "#FFFFFF" },
              },
              "&::-moz-range-progress": {
                WebkitAppearance: "none",
                height: "2px",
                width: "4px",
                background: "#FFFFFF",
                cursor: "ew-resize",
                boxShadow: "0 0 2px 0 #555",
                transition: "background .3s ease-in-out",
                "&:hover": { background: "#FFFFFF" },
              },
              "&::-ms-thumb": {
                WebkitAppearance: "none",
                height: "2px",
                width: "6px",
                background: "#FFFFFF",
                cursor: "ew-resize",
                boxShadow: "0 0 2px 0 #555",
                transition: "background .3s ease-in-out",
                "&:hover": { background: "#FFFFFF" },
              },
            },
            "& input[type='range']::-webkit-slider-runnable-track": {},
            "& input[type='range']::-moz-range-track": {},
            "& input[type='range']::-ms-track": {},
          },
        },
        "& .playerTime": {
          color: "#FFFFFF",
          marginTop: "3px",
          "& i": { fontStyle: "normal", minWidth: 40, display: "inline-block" },
        },
        "& button": {
          background: "transparent",
          border: "0 solid transparent",
          fontSize: 24,
          lineHeight: 1,
          padding: 0,
          color: "#FFFFFF",
          width: 24,
          height: 24,
        },
      },
    },
  },
}));

export default function VideoPlayer(
  videoContainer,
  videoElement,
  updateDuration
) {
  const [init, setInit] = useState("");
  const [videoLoaded, setIsVideoLoaded] = useState("");
  const [selectedQuiz, setSelectedQuiz] = useState({
    timeline: 0,
    labId: "",
    status: "",
  });
  const [playerState, setPlayerState] = useState({
    currentTime: 0,
    progress: 0,
  });
  const [playerCtrls, setPlayerCtrls] = useState({
    volume: 100,
    duration: 0,
    isMuted: false,
    isLoading: true,
    isPlaying: false,
    isFullScreen: false,
    // speed: 1,
  });
  const [isLoading, setIsLoading] = useState(true);
  
  const togglePlay = () => setPlayerCtrls({ ...playerCtrls, isPlaying: !playerCtrls.isPlaying });
    
  const toggleMute = () => {
    let volume = videoElement.current.volume;
    let isMuted = !playerCtrls.isMuted;
    if( isMuted ) videoElement.current.volume = volume = 0
    else {
      volume = playerCtrls.volume > 0 ? playerCtrls.volume : 100;
      videoElement.current.volume = volume / 100;
    }
    setPlayerCtrls({ ...playerCtrls, volume, isMuted });
  }

  const toggleFullScreen = () => setPlayerCtrls({ ...playerCtrls, isFullScreen: !playerCtrls.isFullScreen });

  const getProgress = () => {
    let duration = videoElement.current.duration,
      currentTime = videoElement.current.currentTime;
    let progress = Math.round((currentTime / duration) * 10000) / 100;
    setPlayerState({ currentTime, progress });
  };
  const onLoadedData = (e) => {
    setIsLoading(false);
    setIsVideoLoaded("loaded");
    updateDuration(videoElement.current.duration);
  };
  const setProgressEvent = (e) => {
    const progress = Number(e.target.value);
    var duration = videoElement.current.duration,
      currentTime = videoElement.current.currentTime;
    duration = videoElement.current.currentTime = (duration / 100) * progress;
    setPlayerState({ currentTime, progress });
  };
  const setProgress = (val) => {
    const duration = videoElement.current.duration,
      currentTime = videoElement.current.currentTime + val;
    videoElement.current.currentTime = currentTime;
    const progress = Math.round((currentTime / duration) * 10000) / 100;
    setPlayerState({ currentTime, progress });
  };
  const setVolumeEvent = (e) => {
    const volume = Number(e.target.value);
    videoElement.current.volume = volume / 100;
    setPlayerCtrls({ ...playerCtrls, volume, isMuted : volume === 0 });
  };
  function openFullscreen(elem) {
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.msRequestFullscreen) {
      elem.msRequestFullscreen();
    } else if (elem.mozRequestFullScreen) {
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) {
      elem.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
    }
  }
  function closeFullscreen() {
    const fullscreenElement =
      document.fullscreenElement ||
      document.msFullScreenElement ||
      document.mozFullScreenElement ||
      document.webkitFullscreenElement;
    if (fullscreenElement !== null) {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      }
    }
  }

  useEffect(() => {
    if (selectedQuiz.labId !== "") {
      const currentTime = selectedQuiz.timeLine,
        duration = videoElement.current.duration;
      videoElement.current.currentTime = currentTime;
      const progress = Math.round((currentTime / duration) * 10000) / 100;
      setPlayerState({ currentTime, progress });
      setPlayerCtrls({ ...playerCtrls, isPlaying: false });
      updateQuizLabId(selectedQuiz.labId);
    }
  }, [selectedQuiz]);

  useEffect(() => {
    if (init === "") {
      setInit("loaded");
      return;
    }
    playerCtrls.isPlaying
      ? videoElement.current.play()
      : videoElement.current.pause();
  }, [playerCtrls.isPlaying]);

  useEffect(() => {
    var timeOut = null;
    if (videoLoaded !== "") {
      const currentTime = videoElement.current.currentTime,
        duration = videoElement.current.duration;
      const progress = Math.round((currentTime / duration) * 10000) / 100;
      setPlayerCtrls({ ...playerCtrls, duration, isPlaying: false });
      setPlayerState({ currentTime, progress });
    }
  }, [videoLoaded]);

  useEffect(() => {
    if (videoContainer && videoContainer.current) {
      playerCtrls.isFullScreen
        ? openFullscreen(videoContainer.current)
        : closeFullscreen();
    }
  }, [playerCtrls.isFullScreen]);

  useEffect(() => {
    var ele = null;
    const waitingHandler = () => {
      setIsLoading(true);
    };
    const playHandler = () => {
      setIsLoading(false);
    };
    const pauseHandler = () => {
      setIsLoading(false);
    };
    if (videoElement) {
      ele = videoElement.current;
      if (ele) {
        ele.addEventListener("pause", pauseHandler);
        ele.addEventListener("play", playHandler);
        ele.addEventListener("playing", playHandler);
        ele.addEventListener("waiting", waitingHandler);
        if (ele.duration) {
          const duration = ele.duration,
            currentTime = ele.currentTime;
          const progress = Math.round((currentTime / duration) * 10000) / 100;
          setPlayerState({ ...playerState, duration, currentTime, progress });
        }
      }
    }
    return () => {
      if (ele) {
        ele.removeEventListener("pause", pauseHandler);
        ele.removeEventListener("play", playHandler);
        ele.removeEventListener("playing", playHandler);
        ele.removeEventListener("waiting", waitingHandler);
      }
    };
  }, [videoElement.current]);

  useEffect(() => {
    function onFullScreenChange() {
      const fullscreenElement =
        document.fullscreenElement ||
        document.msFullScreenElement ||
        document.mozFullScreenElement ||
        document.webkitFullscreenElement;
      if (fullscreenElement === null)
        setPlayerState({
          ...playerState,
          isFullScreen: false,
        });
    }
    document.addEventListener("fullscreenchange", onFullScreenChange);
    document.addEventListener("msfullscreenchange", onFullScreenChange);
    document.addEventListener("mozfullscreenchange", onFullScreenChange);
    document.addEventListener("webkitfullscreenchange", onFullScreenChange);
    return () => {
      document.removeEventListener("fullscreenchange", onFullScreenChange);
      document.removeEventListener("msfullscreenchange", onFullScreenChange);
      document.removeEventListener("mozfullscreenchange", onFullScreenChange);
      document.removeEventListener(
        "webkitfullscreenchange",
        onFullScreenChange
      );
    };
  }, []);

  return {
    isLoading,
    playerState,
    playerCtrls,
    togglePlay,
    toggleMute,
    getProgress,
    setProgress,
    onLoadedData,
    setProgressEvent,
    setVolumeEvent,
    toggleFullScreen,
    useStyles,
  };
}
