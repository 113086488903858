import React, { useEffect, useState } from "react";
import api from "../../../api/baseConfig";
import ApiHelper from "../../../api/apiHelper";
import "react-toastify/dist/ReactToastify.css";
import "../EcommerceOrders/toastStyles.css";
import CustomTable from "../../CustomerManagement/CustomListTable";
import { sessionPrivileges } from "privileges";

export default function AllProficiencies(props) {
  const userPrivileges = sessionPrivileges();
  const Priv = sessionPrivileges().userprivileges || [];
  const [data, setData] = useState([]);

  const activePage = props?.location?.state?.activePage;
  const itemsPerPage = props?.location?.state?.itemsPerPage;

  useEffect(() => {
    getAllProficiencies();
    window.scrollTo(0, 0);
  }, []);

  const getAllProficiencies = () => {
    ApiHelper.axiosCall(
      `${api.baseURL}${api.masterController.getAllProficiency}`,
      "get"
    )
      .then((data) => {
        let tempData = [];
        data.forEach((datum) => {
          tempData.push({
            name: datum.name,
            description: datum.description,
            id: datum.id,
          });
        });
        setData(tempData);
      })
      .catch((e) => {});
  };

  const deleteProficiencies = (id) => {
    ApiHelper.axiosCall(
      `${api.baseURL}${api.masterController.deleteProficiency}${id}`,
      "post"
    )
      .then((d) => {
        getAllProficiencies();
      })
      .catch((e) => {});
  };

  return (
    <>
    {userPrivileges.isSuperAdmin && (
    <div className="page-content">
      <CustomTable
        header="Proficiencies"
        createLink="/create-proficiency"
        data={data}
        dataLength={data.length}
        tableHeaders={[
          { label: "Proficiency", val: "name" },
          { label: "Description", val: "description" },
        ]}
        searchPlaceholder="Search Proficiency"
        deleteDatum={(id) => deleteProficiencies(id)}
        passEditFromRow
        editData={["name", "description", "id"]}
        showAdd={Priv.includes("ADD_PROFICIENCY")}
        hideEdit={!Priv.includes("EDIT_PROFICIENCY")}
        hideDelete={!Priv.includes("DELETE_PROFICIENCY")}
        hoverable
        activePage={activePage}
        itemsPerPage={itemsPerPage}
      />
    </div>
    )}
    </>
  );
}
