import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import cn from 'classnames';
import {
  Button as MuiButton,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import Webcam from "react-webcam";
import {
  CameraAltOutlined,
  MicNone,
  WebAsset,
  CheckCircle,
  Cancel,
} from '@material-ui/icons';
import { withStyles } from '@material-ui/styles';
import { TervButtonFilled } from "components/TervButtons";
import { TProps } from './proctor-detector.props';
import styles from './proctor-detector.module.scss';
import { Button } from 'reactstrap';

const PrimaryButton = withStyles({
  root: {

  },
})(MuiButton);

const doesHaveMicAccess = async (): Promise<boolean> => {
  return await navigator.mediaDevices
    .getUserMedia({ audio: true })
    .then(() => true)
    .catch(() => false);
};

const doesHaveCamAccess = async (): Promise<boolean> => {
  return await navigator.mediaDevices
    .getUserMedia({ video: true })
    .then(() => true)
    .catch(() => false);
};

const isBrowserCompatible = () => {
  let browserIsCompatible = false;
  const sUsrAg = navigator.userAgent;

  if (sUsrAg.indexOf("Firefox") > -1) {
    browserIsCompatible = true;
  } else if (sUsrAg.indexOf("Chrome") > -1) {
    browserIsCompatible = true;
  } else if (sUsrAg.indexOf("Safari") > -1) {
    browserIsCompatible = true;
  }

  return browserIsCompatible;
};

function ProctorCheckItem({ Icon, text, hasAccess }: any) {
  return (
    <Grid
      container
      wrap="nowrap"
      alignItems="center"
      style={{
        backgroundColor: '#fff',
        border: '1px solid #F2F1F3',
        boxShadow: '0px 6px 12px -6px rgba(24, 39, 75, 0.12), 0px 8px 24px -4px rgba(24, 39, 75, 0.08)',
        display: 'grid',
        gridTemplateColumns: '32px 1fr 32px',
        gap: 16,
        margin: '12px 0',
        padding: '16px 12px 16px 20px',
        borderRadius: 8,
      }}>
      <Icon />
      <Typography noWrap>
        {text}
      </Typography>
      {hasAccess
        ? <CheckCircle style={{ color: '#5AC62F' }} />
        : <Cancel style={{ color: '#ff0000' }} />
      }
    </Grid>
  );
}

export function ProctorDetector(props: TProps) {
  const smallScreen = useMediaQuery("(max-width: 896px)");
  const [camAccess, setCamAccess] = useState(false);
  const [micAccess, setMicAccess] = useState(false);
  const [browserIsCompatible, setIfBrowserIsCompatible] = useState(false);
  const [userPhoto, setUserPhoto] = useState(null);
  const webcamRef: any = useRef(null);

  const allChecksPassed = useMemo(() => (
    camAccess && micAccess && browserIsCompatible && userPhoto
  ), [camAccess, micAccess, browserIsCompatible, userPhoto]);

  async function getAccessInfo() {
    setCamAccess(await doesHaveCamAccess());
    setMicAccess(await doesHaveMicAccess());
    setIfBrowserIsCompatible(isBrowserCompatible());
  }

  const captureUserPhoto = useCallback(() => {
    const imageSrc = webcamRef?.current?.getScreenshot?.();

    setUserPhoto(imageSrc);
  }, [webcamRef, setUserPhoto]);

  useEffect(() => {
    getAccessInfo();

    const accessInfoInterval = setTimeout(getAccessInfo, 3000);

    return () => {
      clearInterval(accessInfoInterval);
    };
  }, []);

  return (
    <Dialog
      classes={{
        root: cn(styles.root, smallScreen && styles.smallScreen),
        container: cn(styles.container, smallScreen && styles.smallScreen),
        paper: cn(styles.popup, smallScreen && styles.smallScreen),
      }}
      open>
      <DialogTitle
        style={{
          backgroundColor: '#fff',
          borderBottom: '1px solid #ccc',
          textAlign: 'center',
        }}>
        Proctoring Check
      </DialogTitle>
      <DialogContent
        style={{
          backgroundColor: '#fff',
          padding: '32px',
        }}>
        <Grid
          container
          wrap="nowrap"
          style={{
            ...smallScreen
              ? {
                display: 'block',
              }
              : {
                display: 'grid',
                gap: 28,
                gridTemplateColumns: '1fr 1px 1fr',
              },
          }}>
          <Grid
            alignItems="center"
            justifyContent="space-between"
            direction="column"
            container>
            <Typography
              align="center"
              style={{
                fontWeight: 500,
              }}>
              Verification Photo
            </Typography>
            <div className={styles.webCam}>
              {userPhoto
                ? (
                  <img
                    src={userPhoto || ''}
                    style={{
                      height: '100%',
                      objectFit: 'contain',
                      objectPosition: 'center',
                      width: '100%',
                    }}
                  />
                )
                : (
                  <Webcam
                    audio={false}
                    ref={webcamRef}
                    screenshotQuality={100}
                    screenshotFormat="image/jpeg"
                  />
                )}
            </div>
            {userPhoto
              ? (
                <Button
                  color="success"
                  outline
                  onClick={() => {
                    setUserPhoto(null);
                  }}>
                  Retake
                </Button>
              )
              : (
                <Button
                  color="success"
                  disabled={!camAccess}
                  onClick={captureUserPhoto}>
                  Take photo
                </Button>
              )}
          </Grid>
          {!smallScreen
            ? (
              <Divider
                orientation="vertical"
                style={{
                  height: '100%',
                }}
              />
            )
            : (
              <>
                <br />
                <br />
                <br />
              </>
            )}
          <Grid justifyContent="space-between" direction="column" container>
            <Typography
              align="center"
              style={{
                fontWeight: 500,
              }}>
              System Check
            </Typography>
            <Grid direction="column" alignItems="center" container>
              <Typography
                align="center"
                variant="body2"
                style={{
                  color: '#888',
                  marginTop: 16,
                }}>
                Once all the checks are completed you can take the assessment
              </Typography>
              <Grid
                direction="column"
                container
                style={{
                  margin: '32px 0 48px 0',
                }}>
                <ProctorCheckItem
                  Icon={CameraAltOutlined}
                  text="Camera check"
                  hasAccess={camAccess}
                />
                <ProctorCheckItem
                  Icon={MicNone}
                  text="Microphone check"
                  hasAccess={micAccess}
                />
                <ProctorCheckItem
                  Icon={WebAsset}
                  text="Browser check"
                  hasAccess={browserIsCompatible}
                />
              </Grid>
              <TervButtonFilled
                disabled={!allChecksPassed}
                onClick={props.startTest}
                text="Proceed to test"
              />
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
