import { useEffect, useState } from "react";
import { Pie } from "@nivo/pie";
import GridItem from "../../../components/GridItem/GridItem";
import SkeletonGridItem from "../../../components/SkeletonGridItem/SkeletonGridItem";
import ChartContainer from "../../../components/ChartContainer/ChartContainer";
import styles from "./AvgAssessmentMarks.module.scss";
import { useGetDashboardInfo } from "pages/Dashboard/SWRhooks";
import { AdminAvgMarks } from "pages/Dashboard/SampleData";

const adminAvgAssessmentMarksColor = {
  avg: "#F56F18",
  rest: "#F2F2F2",
};

const getAdminAvgAssessmentMarksColor = (bar) =>
  adminAvgAssessmentMarksColor[bar.id];

function AdminAvgAssessmentMarksMetric({ dataWithArc, centerX, centerY }) {
  return (
    <text
      x={centerX}
      y={centerY - 20}
      textAnchor="middle"
      dominantBaseline="central"
      style={{
        fontFamily: "Poppins, sans-serif",
        fontSize: "30px",
        fontWeight: "600",
      }}
    >
      {parseFloat(dataWithArc[0].value)?.toFixed(2)}%
    </text>
  );
}

export function AvgAssessmentMarks({ showReal, isMobile }) {
  const [dataIsLoading, setIfDataIsLoading] = useState(false);
  const [
    admin_avgAssessmentMarksViewOption,
    setAdmin_avgAssessmentMarksViewOption,
  ] = useState({
    name: "",
    value: "",
  });
  const [
    admin_avgAssessmentMarksViewOptions,
    setAdmin_avgAssessmentMarksViewOptions,
  ] = useState([]);
  const [admin_avgAssessmentMarksData, setAdmin_avgAssessmentMarksData] =
    useState({});
  const [admin_avgAssessmentMarksDetails, setAdmin_avgAssessmentMarksDetails] =
    useState({});

  useEffect(() => {
    if (admin_avgAssessmentMarksViewOption.value) {
      const data =
        admin_avgAssessmentMarksDetails[
          admin_avgAssessmentMarksViewOption.value
        ];

      setAdmin_avgAssessmentMarksData(data);
    }
  }, [admin_avgAssessmentMarksViewOption]);

  const apiResp = useGetDashboardInfo(
    "getAdminDashBoardAvgAssessmentMarks",
    showReal
  );

  const setData = (data) => {
    const avgAssessmentMarksGroups = [];
    const avgAssessmentMarksDetails = {};
    data.avgAssessmentMarkDetails.slice(0, 160).forEach((g, idx) => {
      avgAssessmentMarksGroups.push({
        name: g.name,
        value: `ASM-${idx + 1}`,
      });
      avgAssessmentMarksDetails[`ASM-${idx + 1}`] = g.data;
    });
    setAdmin_avgAssessmentMarksViewOptions(avgAssessmentMarksGroups);
    setAdmin_avgAssessmentMarksDetails(avgAssessmentMarksDetails);

    if (avgAssessmentMarksGroups?.[0]) {
      setAdmin_avgAssessmentMarksViewOption(avgAssessmentMarksGroups[0]);
      setAdmin_avgAssessmentMarksData(avgAssessmentMarksDetails[0]);
    }
  };

  useEffect(() => {
    if (apiResp === "Fetching...") {
      setIfDataIsLoading(true);
    }
    if (apiResp && apiResp.hasOwnProperty("avgAssessmentMarkDetails")) {
      setData(apiResp);
      setIfDataIsLoading(false);
    }
  }, [apiResp]);

  useEffect(() => {
    setData(AdminAvgMarks);
  }, []);

  const hasData = !!admin_avgAssessmentMarksViewOptions.length;

  return dataIsLoading ? (
    <SkeletonGridItem
      gridPosition={[
        isMobile ? [1, -1] : [8, 13],
        showReal ? (isMobile ? [7, 8] : [7, 8]) : isMobile ? [7, 8] : [7, 8],
      ]}
    />
  ) : (
    <GridItem
      gridPosition={[
        isMobile ? [1, -1] : [8, 13],
        showReal ? (isMobile ? [7, 8] : [7, 8]) : isMobile ? [7, 8] : [7, 8],
      ]}
    >
      <ChartContainer
        className={styles.chartContainer}
        contentClassName={styles.chart}
        currentOption={admin_avgAssessmentMarksViewOption.value}
        onOptionChange={setAdmin_avgAssessmentMarksViewOption}
        options={admin_avgAssessmentMarksViewOptions}
        optionsContainerClassName={styles.options}
        title={<strong>Average assessment marks</strong>}
        titleContainerClassName={styles.title}
      >
        <div className={styles.content}>
          <div className={styles.chartContent}>
            <div className={styles.pieChart}>
              <Pie
                colors={getAdminAvgAssessmentMarksColor}
                data={[
                  {
                    id: "avg",
                    label: "avg",
                    value: admin_avgAssessmentMarksData?.avgMarks || 0,
                  },
                  {
                    id: "rest",
                    label: "rest",
                    value: 100 - (admin_avgAssessmentMarksData?.avgMarks || 0),
                  },
                ]}
                enableArcLabels={false}
                enableArcLinkLabels={false}
                endAngle={90}
                innerRadius={0.65}
                isInteractive={false}
                layers={["arcs", AdminAvgAssessmentMarksMetric]}
                margin={{ top: 0, right: 30, bottom: 10, left: 30 }}
                startAngle={-90}
                width={280}
                height={160}
              />
            </div>
            <div className={styles.point}>
              {admin_avgAssessmentMarksData?.totalAssessments}{" "}
              assessment{admin_avgAssessmentMarksData?.totalAssessments !== 1 ? "s" : ""} completed in total
            </div>
            <div className={styles.point}>
              {admin_avgAssessmentMarksData?.totalStudents}{" "}
              student{admin_avgAssessmentMarksData?.totalStudents !== 1 ? "s" : ""} participated
            </div>            
          </div>
        </div>
      </ChartContainer>
    </GridItem>
  );
}
