import { useState, useEffect } from "react";
import Apihelper from "../../../api/apiHelper";
import api from "../../../api/baseConfig";
import { connect, useSelector } from "react-redux";
import { GetAllUsers } from "pages/CustomerManagement/utils/GetUsers";
import HandleStudentSelectionAlt from "./HandleStudentSelectionAlt";
import { sessionPrivileges } from "privileges";
import { parseRawStudentData } from "./../../utils/ParseRawStudentData";

const customStyles = {
  menu: (styles) => ({ ...styles, zIndex: 9999 }),
};

const returnParsedData = (filterType, selectedStudents, allStudents) => {
  let studentIds = [];
  selectedStudents = selectedStudents || [];
  if (filterType === "all") {
    studentIds =
      allStudents.length > 0 ? allStudents.map((stu) => stu.value) : [];
  } else if (filterType === "except") {
    studentIds =
      selectedStudents.length > 0
        ? allStudents.reduce((acc, val) => {
            if (!selectedStudents.find((stu) => stu.value === val.value)) {
              acc.push(val.value);
            }
            return acc;
          }, [])
        : [];
  } else {
    studentIds =
      selectedStudents.length > 0
        ? selectedStudents.map((stu) => stu.value || stu)
        : [];
  }

  return studentIds;
};

const defaultFilterTypes = [
  { label: "All", value: "all" },
  { label: "Only", value: "only" },
  { label: "Except", value: "except" },
  {
    label: "Custom Batch",
    value: "custom_batch",
  },
];

const AdvancedStudentSelection = ({
  customerId = "",
  hookFormControls,
  viewOnly,
  studentFieldName = "",
  labelName = "",
}) => {
  const userPrivileges = sessionPrivileges();
  const isSA = userPrivileges.isSuperAdmin;
  const customerID = sessionStorage.getItem("customer_id");
  const { watch, setValue } = hookFormControls;
  const [customerStudents, setCustomerStudents] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [filteredStudents, setFilteredStudents] = useState([]);
  const [allFilterTypes, setAllFilterTypes] = useState(defaultFilterTypes);

  const adminStudentsFromRedux = useSelector((state) =>
    parseRawStudentData(state.StudentAndCustomerReducer?.UserStudents?.students)
  );

  const getRightStudents = () =>
    isSA ? customerStudents : adminStudentsFromRedux;

  const regNoLength = () =>
    getRightStudents()[0] && getRightStudents()[0]["registrationNo"]
      ? getRightStudents()[0]["registrationNo"].length
      : "";

  const getFilteredStudents = (students, from, to) =>
    students.filter(
      (stu) => +stu.registrationNo >= from && +stu.registrationNo <= to
    );

  useEffect(() => {
    if (
      watch("regNoFrom") &&
      watch("regNoTo") &&
      watch("regNoFrom").length === regNoLength() &&
      watch("regNoTo").length === regNoLength() &&
      watch("regNoFrom") !== watch("regNoTo") &&
      typeof +watch("regNoFrom") === "number" &&
      typeof +watch("regNoTo") === "number"
    ) {
      const filtered = getFilteredStudents(
        getRightStudents(),
        watch("regNoFrom"),
        watch("regNoTo")
      );
      setFilteredStudents(filtered);
    }
  }, [watch("regNoFrom"), watch("regNoTo")]);

  useEffect(() => {
    if (
      watch("filterType") &&
      watch("filterType") !== "all" &&
      watch("filterType") !== "only" &&
      watch("filterType") !== "except"
    ) {
      if (!isSA) {
        getDepartments(customerID);
      }
      if (watch("filterType") !== "custom_batch") {
        getStudentsByBatch(watch("filterType"));
      }
    }
  }, [watch("filterType")]);

  useEffect(() => {
    if (isSA && customerId) {
      getCustomerStudents(customerId);
    }
  }, [customerId]);

  useEffect(() => {
    if (watch("studentsDepartment")) {
      filterByDepartment(watch("studentsDepartment"));
    }
  }, [watch("studentsDepartment")]);

  const filterByDepartment = (department) => {
    setFilteredStudents(
      getRightStudents().reduce((acc, val) => {
        if (val?.department === department) {
          acc.push(val);
        }
        return acc;
      }, [])
    );
  };

  const checkIfBatchNameExists = async (batchName) => {
    const CUSTOMER = isSA ? customerId : customerID;
    if (CUSTOMER) {
      try {
        const boo = await Apihelper.axiosCall(
          `${api.baseURL}${api.customerManagement.checkIfBatchExists}${CUSTOMER}/${batchName}`
        );
        return boo;
      } catch (err) {}
    }
  };
  const getStudentsByBatch = async (batch) => {
    const encodedBatchName = window.btoa(batch);
    try {
      const data = await Apihelper.axiosCall(
        `${api.baseURL}${api.customerManagement.getStudentsByBatch}${encodedBatchName}/${
          isSA ? customerId : customerID
        }`
      );
      if (data && data[0].batchUserList && data[0].batchUserList.length > 0) {
        setValue(
          studentFieldName,
          data[0].batchUserList.reduce((acc, item) => {
            if (item?.firstName && item?.emailId) {
              acc.push(item?.emailId);
            }
            return acc;
          }, [])
        );
        setValue("studentsDepartment", data[0].department);
        setValue("batchName", batch);
      }
    } catch (err) {}
  };

  const getCustomerStudents = async (id) => {
    const allUsers = await GetAllUsers(id);
    setCustomerStudents(parseRawStudentData(allUsers));
  };

  const resetFilter = () => {
    if (watch("regNoFrom") || watch("regNoTo")) {
      setValue("regNoFrom", "");
      setValue("regNoTo", "");
      setFilteredStudents([]);
    }
  };

  return (
    <HandleStudentSelectionAlt
      students={
        filteredStudents.length > 0 ? filteredStudents : getRightStudents()
      }
      hookFormControls={hookFormControls}
      returnParsedData={returnParsedData}
      departments={departments}
      style={customStyles}
      allFilterTypes={allFilterTypes}
      checkIfBatchNameExists={checkIfBatchNameExists}
      resetFilter={resetFilter}
      viewOnly={viewOnly}
      studentFieldName={studentFieldName}
      labelName={labelName}
    />
  );
};

export default connect(null, null)(AdvancedStudentSelection);
