import React, { useState, useEffect, useRef, useCallback } from "react";
import { Button, Input } from "reactstrap";
import cn from "classnames";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Popover,
  Badge,
  Paper,
  CircularProgress,
} from "@material-ui/core";
import { getRandom } from "../../utils/ArrayGetRandom";
import { CloseOutlined } from "@material-ui/icons";
import Select from "react-select";
import CustomSearch from "../../Tasks/CustomSearch";
import * as CustomIcons from "../../../assets/icons/icons.js";
import styles from "./SelectQuestionsModal.module.scss";
import { handleSearch } from "pages/utils/HandleSearch";
import api from "../../../api/baseConfig";
import Apihelper from "../../../api/apiHelper";
import { GetObjectFromString } from "pages/utils/GetObjectFromString";
import ModalTable from "./Table";
import { FilterMenu } from "./FilterMenu";
import { FilterMenuPP } from "./FilterMenuPP";
import { uid } from "react-uid";

const tabs = ["availableQuestions", "selectedQuestions"];
const tabNames = ["Available Questions", "Selected Questions"];

const entriesCount = ["All", 2, 6, 10, 15];

const customStyles = {
  control: (styles) => ({
    ...styles,
    width: "315px",
    backgroundColor: "#fafbfc",
    zIndex: 9999,
  }),
  menu: (styles) => ({ ...styles, width: "315px" }),
};

var idx = 0;

export function SelectQuestionsModal({
  multiSections,
  setCheckedQuestions,
  sectionIndex = null,
  findQuestion = null,
  canPreview = false,
  previewQuestions,
  setPreviewQuestions,
  setCategoriesState,
  ...props
}) {
  const [activeTab, setActiveTab] = useState("availableQuestions");
  const [questionBank, setQuestionBank] = useState("");
  const [randomSelect, setRandomSelect] = useState("");
  const [currBankSelQues, setCurrBankSelQues] = useState([]);
  const [tabState, setTabState] = useState([]);
  const [lastIndex, setLastIndex] = useState(0);
  const [popoverEl, setPopoverEl] = useState(null);
  const [newQuestionType, setNewQuestionType] = useState("all");
  const [isAll, setIsALL] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [selFilters, setSelFilters] = useState();
  const [topicFilters, setTopicFilters] = useState();
  const [selFilterTopic, setSelFilterTopic] = useState([]);
  const [selFilterCategory, setSelFilterCategory] = useState([]);
  const [selFilterType, setSelFilterType] = useState([]);
  const [infinityScroll, setInfinityScroll] = useState(true);
  const [selFilterComplexity, setSelFilterComplexity] = useState([]);
  const [loadCircularProgress, setLoadCircularProgress] = useState(false);
  const [badgeTrue, setBadgeTrue] = useState(false);

  const trigAPI = () => {
    idx++;
    props.setCurrQuestionBank(questionBank.value, idx, "");
    return idx;
  };
  useEffect(() => {
    let categoriesNewValue = [];
    tabState.map((e) => {
      categoriesNewValue.push({
        topic: e?.topic,
        category: e?.category,
      });
    });
    let validationTopicsTemp = categoriesNewValue.some(
      (e) => e?.topic === null
    );
    setCategoriesState(validationTopicsTemp);
  }, [tabState]);
  const observer = useRef();
  const lastBookElementRef = useCallback(
    (node) => {
      if (questionBank.value === "all" && infinityScroll) {
        if (tabState.length >= 10) {
          setPageNumber((prevPageNumber) => prevPageNumber + 1);
        }
        if (props.loading) return;
        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver((entries) => {
          if (entries[0].isIntersecting && props.hasMore) {
            trigAPI();
          }
        });
        if (node) {
          observer.current.observe(node);
        }
      }
    },
    [props.loading, props.hasMore]
  );

  const handlePopover = (event) => {
    setPopoverEl(event.currentTarget);
  };

  const resetSQM = () => {
    setQuestionBank("");
    setCurrBankSelQues([]);
    setTabState([]);
    if (randomSelect) setRandomSelect("");
    if (lastIndex) setLastIndex(0);

    props.setReset(false);
  };

  const setCurrQuestionBankFilter = async (currI) => {
    let userId = sessionStorage.getItem("user_id"); //sessionStorage.getItem("applicable_authors")
    await Apihelper.getQuestionBankFilter(
      props.isCopy
        ? `${api.baseURL}${api.questionBankController.getQBUnselectedFilterList}${userId}?qbId=${props?.quesBankId}&selectedQBId=${currI}`
        : `${api.baseURL}${api.questionBankController.getQBFilterList}${userId}?qbId=${currI}`,
      "get"
    )
      .then((datum) => {
        setSelFilters(datum);
        setTopicFilters(datum?.topicList);
      })
      .catch((e) => {});
  };

  useEffect(() => {
    setQuestionsType(props.questionsType);
  }, [props?.questionsType]);

  useEffect(() => {
    if (props.reset) {
      resetSQM();
    }
  }, [props?.reset]);

  useEffect(() => {
    if (activeTab === "availableQuestions") {
      setQuestionsType(
        props?.disableType ? props.questionsType : newQuestionType
      );
    }
  }, [props.currBankQues]);

  useEffect(() => {
    if (questionBank.value === "all") {
      setTabState((prev) => {
        return [...new Set([...tabState, ...prev])];
      });
    }
  }, [props.currBankQues]);

  useEffect(() => {
    if (
      props.existingQuestions &&
      props.existingQuestions.length > 0 &&
      currBankSelQues.length === 0
    ) {
      setCurrBankSelQues(props.existingQuestions);
    }
  }, [props?.existingQuestions]);

  useEffect(() => {
    setLastIndex(tabState.length);
  }, [tabState]);
  const handleSingleCheck = (id) => {
    const q = tabState.find((item) => item.id === id);
    if (activeTab === "availableQuestions") {
      if (q !== undefined) {
        if (
          currBankSelQues.length > 0 &&
          currBankSelQues.find((item) => item.id === q.id)
        ) {
          let temp = [...currBankSelQues];
          temp.splice(
            currBankSelQues.findIndex((item) => item.id === q.id),
            1
          );
          setCurrBankSelQues(temp);
        } else {
          setCurrBankSelQues([...currBankSelQues, q]);
        }
      }
    } else {
      let checked = currBankSelQues.reduce((acc, val) => {
        if (val.id === id) {
          acc.push({ ...val, remChecked: !val.remChecked });
        } else {
          acc.push(val);
        }
        return acc;
      }, []);
      setCurrBankSelQues(checked);
      setTabState(checked);
    }
  };
  const handleFilterNew = () => {
    setTabState([]);
    setInfinityScroll(false);
    props.setCurrQuestionBank(
      questionBank.value,
      0,
      "",
      selFilterTopic,
      selFilterCategory,
      selFilterType,
      selFilterComplexity
    );
  };

  const handleClearAll = () => {
    // setTabState([]);
    setInfinityScroll(true);
    setSelFilterTopic([]);
    setSelFilterCategory([]);
    setSelFilterType([]);
    setSelFilterComplexity([]);
    if (questionBank.value === "all") {
      props.setCurrQuestionBank(questionBank.value, 0, "");
    } else {
      props.setCurrQuestionBank(questionBank.value);
    }
  };

  const shouldDisable = (id) =>
    multiSections
      ? findQuestion(id)
        ? +findQuestion(id) + 1 !== sectionIndex + 1
          ? +findQuestion(id) + 1
          : false
        : false
      : false;

  const handleDeSelectAll = () =>
    setCurrBankSelQues(
      currBankSelQues.filter((q) => !tabState.find((r) => r.id === q.id))
    );

  const selectAll = () => {
    if (activeTab === "availableQuestions") {
      let avQuestions = tabState;
      if (multiSections) {
        avQuestions = tabState.filter((q) => !shouldDisable(q.id));
      }
      if (
        avQuestions.every((q) =>
          currBankSelQues.map((val) => val.id).includes(q.id)
        )
      ) {
        handleDeSelectAll();
      } else {
        let tempSelQues = [...currBankSelQues];
        tabState.forEach((element) => {
          if (
            !tempSelQues.find((q) => q.id === element.id) &&
            (multiSections ? !shouldDisable(element.id) : true)
          ) {
            tempSelQues.push(element);
          }
        });
        setCurrBankSelQues(tempSelQues);

        // setCurrBankSelQues([
        //   ...currBankSelQues,
        //   ...tabState.filter((q) =>
        //     !currBankSelQues.map((val) => val.id).includes(q.id) &&
        //     multiSections
        //       ? !shouldDisable(q.id)
        //       : true
        //   ),
        // ]);
      }
    } else if (currBankSelQues.length > 0) {
      const condition = currBankSelQues.every((item) => item.remChecked);

      const newList = currBankSelQues.reduce((acc, val) => {
        acc.push({ ...val, remChecked: !condition });
        return acc;
      }, []);

      setCurrBankSelQues(newList);
      setTabState(newList);
    }
  };

  const saveQuestions = (todo = "def") => {
    if (activeTab === "availableQuestions" || todo === "add") {
      setLoadCircularProgress(true);
      setBadgeTrue(true);
      let qids = [];
      currBankSelQues.forEach((i) => {
        if (
          props?.disableType &&
          (props.questionsType === "all" || i.type === props.questionsType)
        ) {
          qids.push(i.id);
        } else if (newQuestionType === "all" || i.type === newQuestionType) {
          qids.push(i.id);
        }
      });
      props.setQIds(qids);
      // handleSQMClose();
      setActiveTab("selectedQuestions", setTab());
      setLoadCircularProgress(false);
    } else {
      removeQuestions();
    }
  };

  const removeQuestions = () => {
    let toRemove = [];
    let balQuestion = [];
    const newList = currBankSelQues.reduce((acc, val) => {
      if (!val.remChecked) {
        acc.push(val);
        balQuestion.push(val.id);
      } else {
        toRemove.push(val.id);
      }
      return acc;
    }, []);
    setCurrBankSelQues(newList);
    setTabState(newList);
    props?.setNewQuestion(newList, props.remove(toRemove, balQuestion));
    if (multiSections) {
      setQuestionsWatch();
    }
  };

  const searchQuestions = (query) => {
    if (questionBank && questionBank?.value === "all") {
      if (query.length > 3) {
        setTabState([]);
        props.setCurrQuestionBank(
          questionBank.value,
          "",
          query,
          selFilterTopic,
          selFilterCategory,
          selFilterType,
          selFilterComplexity
        );
      }
      if (query.length === 0) {
        setTabState([]);
        props.setCurrQuestionBank(
          questionBank.value,
          0
          // "",
          // selFilterTopic,
          // selFilterCategory,
          // selFilterType,
          // selFilterComplexity
        );
      }
    } else {
      const result = handleSearch(query, tabState);
      if (result.length > 0) {
        setTabState(result);
      } else {
        if (activeTab === "availableQuestions") {
          setTabState(props.currBankQues);
        } else {
          setTabState(currBankSelQues);
        }
      }
    }
  };

  const setQuestionsType = (type) => {
    if (type === "all") {
      if (activeTab === "availableQuestions") {
        setTabState(props.currBankQues);
      }
    } else {
      if (activeTab === "availableQuestions") {
        setTabState(props.currBankQues.filter((q) => q.type === type));
      } else {
        setTabState(currBankSelQues.filter((q) => q.type === type));
      }
    }
  };
  const setTab = (index) => {
    let tempTabState;
    if (tabs[index] === "availableQuestions") {
      if (props.questionsType === "all") {
        tempTabState = [...props.currBankQues];
      } else {
        tempTabState = props.currBankQues.filter(
          (question) => question.type === props.questionsType
        );
      }
    } else {
      tempTabState = [...currBankSelQues];
    }
    setActiveTab(tabs[index]);
    setTabState(tempTabState);
  };

  const setQuestionsWatch = () => {
    setCheckedQuestions(sectionIndex, currBankSelQues);
  };

  const handleSQMClose = () => {
    props.setModal();
    if (multiSections) {
      setQuestionsWatch();
      setQuestionBank("");
    }
  };
  const popoverOpen = Boolean(popoverEl);
  const id = popoverOpen ? "randomPopover" : undefined;
  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={props.modalState}
        onClose={handleSQMClose}
      >
        <DialogTitle classes={{ root: styles.header }}>
          Question Selection
          <CloseOutlined
            classes={{ root: styles.closeButton }}
            onClick={handleSQMClose}
          />
        </DialogTitle>
        <DialogContent>
          <div className={styles.tabsWrapper}>
            <Paper style={{ position: "relative" }}>
              {tabNames.map((item, ind) => (
                <>
                  <button
                    className={cn(styles.tabButton, {
                      [styles.tabButtonActive]: activeTab === tabs[ind],
                    })}
                    onClick={() => setTab(ind)}
                    key={uid(item)}
                  >
                    {item}
                  </button>
                  {ind === 1 && badgeTrue && currBankSelQues.length > 0 && (
                    <Badge
                      badgeContent={currBankSelQues.length}
                      color="primary"
                      max={999}
                      showZero
                      style={{
                        fontFamily: "Poppins",
                      }}
                    />
                  )}
                </>
              ))}
            </Paper>
          </div>
          <div className={styles.tabSettings}>
            {activeTab === "availableQuestions" && (
              <Select
                options={props.QBlist}
                value={questionBank}
                onChange={(value) => {
                  if (questionBank.value !== value.value) {
                    setTabState([]);
                    setSelFilterTopic([]);
                    setSelFilterCategory([]);
                    setSelFilterType([]);
                    setSelFilterComplexity([]);
                    setQuestionBank(value);
                    setCurrQuestionBankFilter(value.value);
                    if (value.value === "all") {
                      setIsALL(true);
                      setInfinityScroll(true);
                      props.setCurrQuestionBank(value.value, 0, "");
                    } else {
                      props.setCurrQuestionBank(value.value);
                      setInfinityScroll(false);
                      idx = 0;
                      return idx;
                    }
                  }
                }}
                styles={customStyles}
                placeholder="Select a Question Bank"
                maxMenuHeight={150}
              />
            )}
            {/* <Select
              isDisabled={props?.disableType}
              options={props.questionTypes}
              value={
                props?.disableType
                  ? GetObjectFromString(
                    props.questionTypes,
                    props.questionsType
                  )
                  : GetObjectFromString(props.questionTypes, newQuestionType)
              }
              onChange={(type) => {
                setQuestionsType(type.value);
                if (!props?.disableType) {
                  setNewQuestionType(type.value);
                }
              }}
              styles={customStyles}
            /> */}
          </div>
          <hr className={styles.divider} />
          <div className={styles.tabFilters}>
            <div className={styles.searchBarWrapper}>
              <CustomSearch
                placeholder="Search Questions"
                secondary
                handlesearch={(e) => searchQuestions(e)}
              />
            </div>
            {loadCircularProgress && (
              <CircularProgress
                style={{
                  width: "60px",
                  height: "60px",
                  position: "absolute",
                  left: "28rem",
                }}
              />
            )}
            {questionBank.value !== "all" && (
              <Button
                id="randomPopover"
                color="primary"
                outline
                style={{ marginRight: 10, fontWeight: 500 }}
                onClick={(e) => handlePopover(e)}
                disabled={
                  activeTab === "availableQuestions" &&
                  props.currBankQues.length
                    ? false
                    : true
                }
              >
                Random Select
              </Button>
            )}
            <FilterMenuPP
              questions={
                activeTab === "availableQuestions"
                  ? questionBank.value === "all"
                    ? tabState
                    : props.currBankQues
                  : currBankSelQues
              }
              tabState={tabState}
              setTabState={setTabState}
              selFilters={selFilters}
              setSelFilters={setSelFilters}
              topicFilter={topicFilters}
              selFilterTopic={selFilterTopic}
              setSelFilterTopic={setSelFilterTopic}
              selFilterCategory={selFilterCategory}
              setSelFilterCategory={setSelFilterCategory}
              selFilterType={selFilterType}
              setSelFilterType={setSelFilterType}
              selFilterComplexity={selFilterComplexity}
              setSelFilterComplexity={setSelFilterComplexity}
              handleFilterNew={handleFilterNew}
              handleClearAll={handleClearAll}
              activeTab={activeTab}
              setCurrBankSelQues={setCurrBankSelQues}
              setQuestionsWatch={setQuestionsWatch}
              remove={props.remove}
              multiSections={multiSections}
            />
          </div>
          <ModalTable
            handleSingleCheck={handleSingleCheck}
            currBankSelQues={currBankSelQues}
            selectAll={selectAll}
            activeTab={activeTab}
            tabState={tabState}
            isAll={isAll}
            lastBookElementRef={lastBookElementRef}
            lastIndex={lastIndex}
            multiSections={multiSections}
            sectionIndex={sectionIndex}
            findQuestion={findQuestion}
            shouldDisable={shouldDisable}
            canPreview={canPreview}
            previewQuestions={previewQuestions}
            setPreviewQuestions={setPreviewQuestions}
            setCategoriesState={setCategoriesState}
          />
        </DialogContent>
        <DialogActions
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "20px 25px 20px 25px",
          }}
        >
          <div disabled={true}>
            {questionBank.value === "all"
              ? `Showing ${tabState.length} `
              : "Showing All "}
            {/* <select
              style={{ height: 30, width: 50, borderRadius: 5 }}
              onChange={(e) =>
                setLastIndex(
                  e.target.value === "All"
                  ? activeTab === "availableQuestions"
                  ? props.currBankQues.length
                  : currBankSelQues.length
                  : e.target.value
                )
              }
            >
              {entriesCount.map((count) => (
                <option key={uid(count)}>{count}</option>
              ))}
            </select>{" "} */}
            {`of ${
              activeTab === "availableQuestions"
                ? props.currBankQuesTotalCount
                : tabState.length
            } questions`}
          </div>
          <div style={{ display: "flex", gap: 10 }}>
            {/* {activeTab === "selectedQuestions" && (
              <Button
                color="primary"
                outline
                disabled={currBankSelQues.length === 0}
                onClick={() => saveQuestions("add")}
              >
                <CustomIcons.PlusIcon size="17" color="#fff" /> Add Questions
              </Button>
            )} */}
            <Button
              color="primary"
              onClick={() => {
                if (activeTab !== "availableQuestions") {
                  props.onRemove(() => {
                    saveQuestions();
                  });
                } else {
                  saveQuestions();
                }
              }}
              disabled={
                activeTab === "availableQuestions"
                  ? currBankSelQues.length === 0
                  : !currBankSelQues.some((c) => c.remChecked)
              }
            >
              {
                CustomIcons[
                  activeTab === "availableQuestions"
                    ? "plusIcon"
                    : "crossIconDefault"
                ]
              }{" "}
              {activeTab === "availableQuestions"
                ? "Add Selected"
                : "Remove Selected"}
            </Button>
          </div>
        </DialogActions>
      </Dialog>
      <Popover
        id={id}
        open={popoverOpen}
        anchorEl={popoverEl}
        onClose={() => setPopoverEl(null)}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        classes={{
          paper: styles.randomPopover,
          root: styles.randomPopoverRoot,
        }}
      >
        <Input
          value={randomSelect}
          onChange={(e) => setRandomSelect(e.target.value)}
          style={{ marginBottom: 10 }}
          placeholder="Enter a Number"
        />
        <Button
          color="primary"
          onClick={() => {
            let toGetRandom = tabState;
            if (multiSections) {
              toGetRandom = toGetRandom.filter(
                (question) => !shouldDisable(question.id)
              );
            }

            setCurrBankSelQues(getRandom(toGetRandom, randomSelect));
          }}
          disabled={
            !randomSelect ||
            randomSelect === "0" ||
            randomSelect >= tabState.length ||
            (multiSections
              ? randomSelect >=
                tabState.filter((question) => !shouldDisable(question.id))
                  .length
              : false)
          }
          style={{
            marginRight: 10,
          }}
        >
          Randomize
        </Button>
        <Button
          onClick={handleDeSelectAll}
          disabled={
            !tabState.some((it) =>
              currBankSelQues.find((ele) => ele.id === it.id)
            ) || activeTab === "selectedQuestions"
          }
          color="primary"
          outline
        >
          Reset
        </Button>
      </Popover>
    </>
  );
}
