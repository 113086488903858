import MasterForm from "./MasterForm";

export default function CreateProficiency(props) {
  return (
    <MasterForm
      postLink="createProficiency"
      label={{
        breadcrumb: "Proficiency",
        back: "Proficiencies",
      }}
      backLink="/proficiency"
      location={{
        ...props.location,
      }}
      history={{
        ...props.history,
      }}
      activePage={props?.location?.state?.activePage}
      itemsPerPage={props?.location?.state?.itemsPerPage}
    />
  );
}
