import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@material-ui/core";
import Zoom from "@material-ui/core/Zoom";
import { CloseOutlined } from "@material-ui/icons";
import styles from "../CustomerManagement/BulkUserUpload.module.scss";
import StyledDate from "./StyledDate";
import { useHistory } from "react-router-dom";
// import { sessionPrivileges } from "privileges";

const CourseModal = ({ ref, isOpen, toggle, data }) => {
  const history = useHistory();
  const MuiDialog = React.forwardRef((props, ref) => {
    return <Dialog ref={ref} {...props} />;
  });

  const ZoomTransition = React.forwardRef((props, ref) => {
    return <Zoom ref={ref} {...props} />;
  });
  const Item = React.forwardRef((props, ref) => {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          gap: "4px 12px",
        }}
        {...props}
      />
    );
  });

  const LeftItem = React.forwardRef((props, ref) => {
    return <div style={{}} {...props} />;
  });
  const RightItem = React.forwardRef((props, ref) => {
    return <div style={{ textTransform: "capitalize" }} {...props} />;
  });

  return (
    <MuiDialog
      ref={ref}
      open={isOpen}
      onClose={() => toggle(false)}
      TransitionComponent={ZoomTransition}
    >
      <DialogTitle classes={{ root: styles.headerCourse }}>
        Course Details
        <CloseOutlined
          classes={{ root: styles.closeButton }}
          onClick={() => toggle(false)}
        />
      </DialogTitle>
      <DialogContent classes={{ root: styles.contentCourse }}>
        <div>
          <h4
            style={{ color: "#212121", fontWeight: "bold", fontSize: "18px" }}
          >
            {data.name ? data.name : ""}
          </h4>
          <div style={{ fontSize: "13px" }}>
            {data.description ? data.description : ""}
          </div>
        </div>
        <table
          className={styles.featureCourseTable}
          style={{
            borderSpacing: "28px 16px",
            margin: "0 auto",
            textAlign: "left",
            fontSize: "14px",
            maxWidth: "100%",
          }}
        >
          <tbody>
            <tr>
              <td>
                <Item>
                  <LeftItem>
                    <b>Language : </b>
                  </LeftItem>
                  <RightItem>{data.languages ? data.languages : ""}</RightItem>
                </Item>
              </td>
              <td>
                <Item>
                  <LeftItem>
                    <b>Category : </b>
                  </LeftItem>
                  <RightItem>{data.category ? data.category : ""}</RightItem>
                </Item>
              </td>
            </tr>
            <tr>
              <td>
                <Item>
                  <LeftItem>
                    <b>Duration : </b>
                  </LeftItem>
                  <RightItem>
                    {data.duration}
                    {data.duration <= 1 ? ":00 Hr" : ":00 Hrs"}
                  </RightItem>
                </Item>
              </td>
              <td>
                <Item>
                  <LeftItem>
                    <b>Status : </b>
                  </LeftItem>
                  <RightItem>{data.status ? data.status : ""}</RightItem>
                </Item>
              </td>
            </tr>
            <tr>
              <td>
                <Item>
                  <LeftItem>
                    <b>Level : </b>
                  </LeftItem>
                  <RightItem>{data.level ? data.level : ""}</RightItem>
                </Item>
              </td>
              <td>
                <Item>
                  <LeftItem>
                    <b>Price : </b>
                  </LeftItem>
                  <RightItem>
                  {data.paidPackage ? (
                      <>
                        Rs.{" "}
                    {Number(
                      data.originalPrice ? data.originalPrice : "0"
                    ).toFixed(2)}
                      </>
                    ) : (
                      "Free"
                    )}
                  </RightItem>
                </Item>
              </td>
            </tr>
          </tbody>
        </table>
        <div
          style={{
            margin: "8px 0 24px 0",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <StyledDate SD={data.startDate} ED={data.endDate} />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "32px 0 8px 0",
          }}
        >
          <Button
            variant="outlined"
            onClick={() => history.push(`/course-overview/${data.id}`)}
            className="buy-button"
          >
            Preview
          </Button>
        </div>
      </DialogContent>
    </MuiDialog>
  );
};

export default CourseModal;
