import { useState, useEffect } from "react";
import {
  Popover,
  Chip,
  Button,
  Checkbox,
  FormGroup,
  FormControlLabel,
} from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";
import ClearIcon from "@material-ui/icons/Clear";
import { makeStyles } from "@material-ui/core/styles";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import TuneIcon from "@material-ui/icons/Tune";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { CapitalizeFirstLetter } from "pages/utils/CapitalizeFirstLetter";
import styles from "./SelectQuestionsModal.module.scss";
import { TervButtonFilled } from "./../../../components/TervButtons";
import { uid } from "react-uid";

const allFilters = [
  { label: "Category", value: "categoryList" },
  {
    label: "Topic",
    value: "topicList",
  },
  {
    label: "Type",
    value: "typeList",
  },
  {
    label: "Complexity",
    value: "complexityList",
  },
];

const defaultFilterState = {
  topicsList: [],
  categoryList: [],
  typeList: [],
  complexityList: [],
};

let dumStr = [];

const useStyles = makeStyles((theme) => ({
  selectDialogContainer: {
    "& .MuiPaper-root": {
      "&::-webkit-scrollbar": {
        width: "5px",
      },
      "&::-webkit-scrollbar-thumb": {
        background: "#BDBBC3",
        borderRadius: "10px",
        margin: "0 10px",
      },
      "&::-webkit-scrollbar-track": {
        background: "#fff",
      },
    },
  },
}));

export const FilterMenuQB = ({
  questions = [],
  tabState,
  setTabState,
  activeTab,
  selFilters,
  setSelFiltersComplex,
  selFiltersComplex,
  selFilterType,
  setSelFilterType,
  selFilterComplexity,
  setSelFilterComplexity,
  handleFilterNew,
  handleClearAll,
  setCurrBankSelQues,
  remove,
  setQuestionsWatch,
  multiSections,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const classes = useStyles();

  const applyFilters = () => {
    setAnchorEl(null);
    if (activeTab === "availableQuestions") {
      handleFilterNew();
    } else {
      const toRemove = [];

      const temp = questions.reduce((acc, val) => {
        if (selFilterType.length > 0) {
          for (let i = 0; i < selFilterType.length; i++) {
            if (selFilterType[i].includes(val?.type)) {
              if (!acc.find((q) => q.id === val.id)) {
                acc.push(val);
              }
            } else if (!toRemove?.includes(val.id)) {
              toRemove.push(val.id);
            }
          }
        }
        if (selFilterComplexity.length > 0) {
          for (let i = 0; i < selFilterComplexity.length; i++) {
            if (selFilterComplexity[i].includes(val?.complexity)) {
              if (!acc.find((q) => q.id === val.id)) {
                acc.push(val);
              }
            } else if (!toRemove?.includes(val.id)) {
              toRemove.push(val.id);
            }
          }
        }
        return acc;
      }, []);

      setTabState(temp);
      if (activeTab === "selectedQuestions") {
        setCurrBankSelQues(temp);
        remove(toRemove);
        if (multiSections) {
          setQuestionsWatch();
        }
      }
    }
  };

  return (
    <>
      <div
        className={styles.filterWrapperQB}
        onClick={(event) => setAnchorEl(event.currentTarget)}
      >
        <div className={styles.filterButton}>
          <span className={styles.filterIcon}>
            <TuneIcon />
          </span>
          <button className={styles.filterInnerButton}>Filter</button>
        </div>
      </div>
      <Popover
        id="simple-menu"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        style={{ marginRight: 40, height: "25rem" }}
        classes={{ root: classes.selectDialogContainer }}
      >
        <p
          className={styles.clearButton}
          onClick={() => {
            handleClearAll();
            // setTabState(questions);
            // setFilters(defaultFilterState);
          }}
          disabled={
            tabState.length === questions.length &&
            selFilterType.length === 0 &&
            selFilterComplexity.length === 0
          }
        >
          Clear all
        </p>
        <Accordion key={3} style={{ width: 220 }}>
          <AccordionSummary
            classes={{ root: styles.accItem, expanded: styles.accExpanded }}
            expandIcon={<ExpandMoreIcon />}
            style={{
              color: "#000",
              fontWeight: 600,
              fontSize: "16px",
              backgroundColor: "transparent",
            }}
          >
            Type
          </AccordionSummary>
          <AccordionDetails
            style={{
              padding: "10px 10px",
              display: "flex",
              // flexWrap: "wrap",
              flexDirection: "column",
              // gap: ,
            }}
          >
            {selFilters?.map((element, i) => (
              <FormGroup key={`typeList${i}`}>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      checked={
                        selFilterType.length > 0
                          ? selFilterType.includes(element)
                          : false
                      }
                      onChange={(e) => {
                        setSelFilterType(
                          e.target.checked
                            ? [...selFilterType, element]
                            : selFilterType.filter((pr) => pr !== element)
                        );
                      }}
                    />
                  }
                  label={
                    element === "MC"
                      ? "Multi Choice"
                      : element === "CD"
                      ? "Coding"
                      : element === "DESC"
                      ? "Descriptive"
                      : element === "all"
                      ? "All"
                      : element
                  }
                />
              </FormGroup>
            ))}
          </AccordionDetails>
        </Accordion>
        <Accordion key={4} style={{ width: 220 }}>
          <AccordionSummary
            classes={{ root: styles.accItem, expanded: styles.accExpanded }}
            expandIcon={<ExpandMoreIcon />}
            style={{
              color: "#000",
              fontWeight: 600,
              fontSize: "16px",
              backgroundColor: "transparent",
            }}
          >
            Complexity
          </AccordionSummary>
          <AccordionDetails
            style={{
              padding: "10px 10px",
              display: "flex",
              // flexWrap: "wrap",
              flexDirection: "column",
              // gap: ,
            }}
          >
            {selFiltersComplex?.map((element, i) => (
              <FormGroup key={`typeList${i}`}>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      checked={
                        selFilterComplexity.length > 0
                          ? selFilterComplexity.includes(element)
                          : false
                      }
                      onChange={(e) => {
                        setSelFilterComplexity(
                          e.target.checked
                            ? [...selFilterComplexity, element]
                            : selFilterComplexity.filter((pr) => pr !== element)
                        );
                      }}
                    />
                  }
                  label={CapitalizeFirstLetter(element)}
                />
              </FormGroup>
            ))}
          </AccordionDetails>
        </Accordion>
        <div style={{ textAlign: "right", padding: 10, marginLeft: "4rem" }}>
          <TervButtonFilled
            text={"Save"}
            onClick={applyFilters}
            disabled={
              selFilterType?.length === 0 &&
              selFilterComplexity?.length === 0
            }
          />
        </div>
      </Popover>
    </>
  );
};
