import React, { useEffect, useState, useMemo } from "react";
import { withRouter } from "react-router-dom";
import * as CustomIcons from "../../../assets/icons/icons";
import cn from "classnames";
import { Button } from "@material-ui/core"
import { Tabs } from "../../../components/tabs/tabs";
import CustomTable from "../../CustomerManagement/CustomListTable";
import api from "../../../api/baseConfig";
import Apihelper from "../../../api/apiHelper";
import { connect } from "react-redux";
import { getCustomers } from "../../../store/StudentAndCustomer/actions";
import { useGetCustomers } from "../../../hooks/useGetCustomers";
import { GetAllUsers } from "../../../pages/CustomerManagement/utils/GetUsers";
import styles from "./index.module.scss";
import { GetObjectFromString } from "../../../pages/utils/GetObjectFromString";
import Select from "react-select";
import CustomSearch from "../../Tasks/CustomSearch";
import { sessionPrivileges } from "privileges";

function UserActivityLog(props) {
    const userPrivileges = sessionPrivileges();
    const customSearchPlaceHolder = "Search...";
    const [customSearchValue, setCustomSearchValue] = useState("");

    const [customerId, setCustomerId] = useState("");
    const allCustomers = useGetCustomers();
    const [userId, setUserId] = useState("");
    const [allUsers, setAllUsers] = useState([]);

    const parseRawData = (data) => {
        let tempParsed = [];
        data.forEach((datum) => {
          tempParsed.push({
            label: datum.firstName + " " + datum.lastName,
            value: datum.id
          });
        });
        return tempParsed;
      };

    const getUserByCustomer = async (customer) => {
        const users = await GetAllUsers(customer);
        if (users) {
            let tempData = parseRawData(users);
            setAllUsers(tempData);
        }
    };


    useEffect(() => {
        window.scrollTo(0, 0);
        if (props?.location?.state?.customer) {
          getUserByCustomer(props?.location?.state?.customer);
        }
    }, []);

    const activePage = props?.location?.state?.activePage;
  const itemsPerPage = props?.location?.state?.itemsPerPage;

    return (
        <>
        {userPrivileges?.isSuperAdmin && (
        <div className={cn("page-content", styles.page)}>
            <div>
                <div className={styles.dashboardInfoBox}>
                    <div className={styles.dashboardInfoBoxTexts}>
                        <div className={styles.dashboardInfoBoxTitle}>User activity log</div>
                        <div className={styles.dashboardSearchBoxContainer}>
                            <div className={styles.searchBoxContainer}>
                                <CustomSearch
                                    placeholder={customSearchPlaceHolder}
                                    secondary
                                    value={customSearchValue}
                                    handlesearch={(e) => {}}
                                />
                            </div>
                            <div className={styles.dashboardExportButtonContainer}>
                                <div
                                    style={{
                                    border: "1px solid #D7D7D7",
                                    backgroundColor: "#F9F9F9",
                                    color: "#3A3A3A",
                                    display: "flex",
                                    alignItems: "center",
                                    height: 36,
                                    padding: "3px 16px",
                                    borderRadius: 5,
                                    }}
                                >
                                    <span style={{ float: "left", marginRight: 6 }}>
                                        {CustomIcons.uploadIconTwo}
                                    </span>
                                    <button style={{ border: 0, background: "none" }}>
                                        Export
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* {sessionStorage.getItem('privileges').includes('MANAGE_CUSTOMER') && ( */}
                <>
                    <div className={styles.customerSelectContainer}>
                        <div className={styles.chooseOptionsContainer}>
                            <div>
                                <p>Choose option:</p>
                            </div>
                            <div className={styles.chooseCustomerAndUserContainer}>
                                <div style={{ width: 207 }}>
                                    <Select
                                        placeholder="Choose a customer"
                                        value={GetObjectFromString(allCustomers, customerId)}
                                        onChange={(e) => {
                                            setCustomerId(e.value);
                                            getUserByCustomer(e.value);
                                        //   setActivePage(1);
                                        //   setItemsPerPage(10);
                                        }}
                                        options={allCustomers}
                                        maxMenuHeight={200}
                                    />
                                </div>
                                <div style={{ width: 207, marginLeft: 20 }}>
                                    <Select
                                        placeholder="Choose a user"
                                        value={GetObjectFromString(allUsers, userId)}
                                        onChange={(e) => {
                                            setUserId(e.value);
                                          setActivePage(1);
                                          setItemsPerPage(10);
                                        }}
                                        options={allUsers}
                                        // maxMenuHeight={120}
                                    />
                                </div>
                            </div>  
                        </div>
                        <div className={styles.chooseRangeContainer}>
                            <div>
                                <p>Choose range:</p>
                            </div>
                            <div className={styles.chooseStartDateContainer}>
                                <div style={{ width: 207 }}>
                                    <input
                                        type="datetime-local"
                                        className={styles.date}
                                    />
                                </div>
                                <div style={{ width: 207, marginLeft: 20 }}>
                                    <input
                                        type="datetime-local"
                                        className={styles.date}
                                    />
                                </div>
                                <p className={styles.resetFilter}>
                                    Reset filters
                                </p>
                            </div>  
                        </div>
                    </div>
                    <br />
                    <br />
                </>
            {/* )} */}
            {/* {sessionStorage.getItem('privileges').includes('MANAGE_CUSTOMER') && ( */}
                <CustomTable
                    header="Assessment list"
                    data={allUsers}
                    dataLength={allUsers?.length}
                    tableHeaders={[
                        { label: "Time", val: "time" },
                        { label: "Log name", val: "logName" },
                        { label: "IP address", val: "ipAddress" }
                    ]}
                    searchPlaceholder="Search..."
                    // overAllReport={overAllReport}
                    customerId={customerId}
                    activePage={activePage}
                    itemsPerPage={itemsPerPage}
                />
            {/* )} */}
        </div>
        )}
        </>
    );
}

export default UserActivityLog;