import React, {useState} from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button as Button1,
} from "@material-ui/core";
import { CloseOutlined } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import styles from "./mailTemplate.module.scss";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import { uid } from "react-uid";
import Tooltip from "@material-ui/core/Tooltip";
import { TervButtonFilled, TervButtonOutlined } from "./../../../components/TervButtons";

const useStyles = makeStyles({
    selectDialogContainer: {
      "& .MuiPaper-root": {
          width: "700px",
        "&::-webkit-scrollbar": {
          width: "5px",
        },
        "&::-webkit-scrollbar-thumb": {
          background: "#BDBBC3",
          borderRadius: "10px",
          margin: "0 10px",
        },
        "&::-webkit-scrollbar-track": {
          background: "#fff",
        },
      },
    },
    expandIcon: {
      "& .MuiButtonBase-root": {
        position: "absolute",
        borderRadius: "0%",
        width: "10rem",
        height: "2rem",
      },
      "& .MuiAccordionSummary-expandIcon.Mui-expanded": {
        transform: "rotate(0deg)",
      }
    },
  });

const MailTemplateModal = (props) => {
  const { 
    isOpen, 
    toggle, 
    templates,
    handleSetTemplate,
    summary, 
    customerId, 
    activePage, 
    activeTab, 
  } = props;

  const history = useHistory();

  const classes = useStyles();

  const [expanded, setExpanded] = useState(false)

  const viewTemp = (
      <p style={{
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "14px",
        color: "#63606C",
        marginTop: "13px",        
      }}>
          View Template
      </p>
  );

  const hideTemp = (
    <p style={{
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "14px",
      lineHeight: "23px",
      color: "#63606C",        
    }}>
        Hide
    </p>
);

const removeHTML = (str) => {
  const regex = /(<([^>]+)>)/gi;
  return str.replace(regex, "");
};
  return (
    <Dialog open={isOpen} onClose={toggle} classes={{ root: classes.selectDialogContainer }}>
      <DialogTitle classes={{ root: styles.templateHeader }}>
        Mail templates
        <CloseOutlined
          classes={{ root: styles.closeButton }}
          onClick={toggle}
        />
      </DialogTitle>
      <DialogContent>
        <>
        <p
          className={styles.templateInfo}
        >
          {Object.keys(templates)?.length}{" "}templates available
        </p>
        <div>
        {templates.map((mod, index) => (
            <MuiAccordion 
              classes={{ root: styles.mailAccordion }} 
              // expanded={expanded === 'panel1'} 
              onChange={() => {setExpanded(!expanded)}}
              key={uid(index)}
            >
                <MuiAccordionSummary
                    classes={{
                        content: styles.accordionSumm,
                        root: `${styles.accordionSummRoot} ${classes.expandIcon}`,
                    }}
                    expandIcon={viewTemp}
                    // expandIcon={chevronDown({ size: 14, color: "#794DF5" })}
                >
                  <Tooltip
                    title={mod?.mailDetails?.name}
                    arrow
                    placement="top-start"
                  >
                    <p
                        className={styles.templateNamestyle}
                    >
                      {mod?.mailDetails?.name}
                    </p>
                  </Tooltip>  
                    <TervButtonOutlined
                        text={"Use this template"}
                        type="button"
                        onClick={() => {
                          handleSetTemplate(mod?.mailDetails?.name, mod?.mailDetails?.message);
                          toggle();
                        }}
                    />
                </MuiAccordionSummary>
                <MuiAccordionDetails classes={{ root: styles.accordionDet }}>
                  <div>
                    <p
                      style={{
                        fontFamily: "Poppins",
                        fontStyle: "normal",
                        fontWeight: 500,
                        fontSize: "12px",
                        lineHeight: "20px",
                        textDecorationLine: "underline",
                        color: "#96939F",
                      }}
                    >
                      TEMPLATE
                    </p>
                    <p className={styles.messageContainer}
                      dangerouslySetInnerHTML={{
                        __html: mod?.mailDetails?.message || "",
                      }}
                    >
                      {/* {removeHTML(mod.template.message)} */}
                    </p>
                  </div>            
                </MuiAccordionDetails>
            </MuiAccordion>
          ))}
          </div>
         </>
      </DialogContent>
    </Dialog>
  );
};

export default MailTemplateModal;
