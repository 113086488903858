import React, { useState, useEffect } from "react";
import Paper from "@material-ui/core/Paper";
import { ViewState } from "@devexpress/dx-react-scheduler";
import {
  Scheduler,
  WeekView,
  MonthView,
  Appointments,
  ViewSwitcher,
  Toolbar,
  DayView,
  DateNavigator,
  TodayButton,
  AppointmentTooltip,
  AppointmentForm,
  ConfirmationDialog,
} from "@devexpress/dx-react-scheduler-material-ui";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { sessionPrivileges } from "privileges";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import styles from "../UserAssessmentMarks.module.scss";
import { connect, useSelector } from "react-redux";
import Tooltip from "@material-ui/core/Tooltip";
import { Link } from "react-router-dom";
import { penIcon, trashFilled, clipboardIcon, copy } from "assets/icons/icons";
import { DeleteModule } from "../../../components/DeleteModule.jsx";
import { ModalToggle } from "pages/utils/ModalToggle";
import { DeleteCourse } from "./Apis";
import { CopyToClipboard } from "react-copy-to-clipboard";
import moment from "moment";
import { CapitalizeFirstLetter } from "pages/utils/CapitalizeFirstLetter";
import Cookies from "universal-cookie";

const Appointment = ({ children, style, ...restProps }) => (
  <Appointments.Appointment
    {...restProps}
    style={{
      ...style,
      backgroundColor: "#DE5445",
      borderRadius: "8px",
    }}
  >
    {children}
  </Appointments.Appointment>
);

const Header = ({ children, appointmentData, classes, ...restProps }) => {
  const Priv = sessionPrivileges().userprivileges || [];
  const [customerId, setCustomerId] = useState(() =>
    Priv?.includes("MANAGE_CUSTOMER")
      ? ""
      : sessionStorage.getItem("customer_id") || ""
  );
  const [deleteCourseId, setDeleteCourseId] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const toggleDeleteModal = () => setDeleteModal(ModalToggle);
  const deleteCourse = async () => {
    try {
      await DeleteCourse(deleteCourseId);
    } catch (error) {
      console.log(`something went wrong ${error}`);
    }
  };

  const time = moment(appointmentData.startDate).format("DD,MMM-yyyy hh:mm a");
  return (
    <AppointmentTooltip.Header {...restProps} appointmentData={appointmentData}>
      <div className={styles.cardButtons} style={{ padding: "6px 0px" }}>
        <DeleteModule
          Header="Course"
          Close={toggleDeleteModal}
          isOpen={deleteModal}
          Delete={deleteCourse}
        />
        {appointmentData.status !== "completed" && (
          <Tooltip title="Edit course" arrow placement="top-start">
            <Link
              to={{
                pathname: `/cloudLabForm/${appointmentData.id}`,
                state: { fromEdit: true, customerId },
              }}
            >
              <div style={{ width: "45px" }}>{penIcon("#784df4")}</div>
            </Link>
          </Tooltip>
        )}
        {appointmentData.status !== "active" && (
          <Tooltip title="Delete course" arrow placement="top-start">
            <div
              onClick={() => {
                setDeleteCourseId(appointmentData.id);
                toggleDeleteModal();
              }}
              style={{ width: "45px" }}
            >
              {trashFilled}
            </div>
          </Tooltip>
        )}
        {appointmentData.status !== "completed" && (
          <Tooltip
            title="Copy Meeting"
            arrow
            placement="top-start"
            className="pr-1"
          >
            <CopyToClipboard
              text={`
                  Meeting Name: ${appointmentData.title}
                  Date: ${time}
                  meeting pin: ${appointmentData.cloudLabPin}
                  meeting url: ${appointmentData.meetingUrl}
                `}
            >
              <div style={{ width: "45px" }}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="#784df4"
                >
                  <path
                    d="M20 2H10C9.46957 2 8.96086 2.21071 8.58579 2.58579C8.21071 2.96086 8 3.46957 8 4V6H16C16.5304 6 17.0391 6.21071 17.4142 6.58579C17.7893 6.96086 18 7.46957 18 8V16H20C20.5304 16 21.0391 15.7893 21.4142 15.4142C21.7893 15.0391 22 14.5304 22 14V4C22 3.46957 21.7893 2.96086 21.4142 2.58579C21.0391 2.21071 20.5304 2 20 2Z"
                    fill="#784df4"
                  />
                  <path
                    d="M4 22H14C15.103 22 16 21.103 16 20V10C16 8.897 15.103 8 14 8H4C2.897 8 2 8.897 2 10V20C2 21.103 2.897 22 4 22ZM6 12H12V14H6V12ZM6 16H12V18H6V16Z"
                    fill="#784df4"
                  />
                </svg>
              </div>
            </CopyToClipboard>
          </Tooltip>
        )}
      </div>
      {appointmentData.status == "active" && (
        <div className="pt-2 pl-1">
          <Button
            variant="contained"
            disableElevation
            style={{ backgroundColor: "#EB5757", color: "#ffffff" }}
          >
            Live
          </Button>
        </div>
      )}
    </AppointmentTooltip.Header>
  );
};

const Content = ({ children, appointmentData, classes, ...restProps }) => {
  const [userToken, setUserToken] = useState("");
  useEffect(() => {
    if (!userToken) {
      const cookies = new Cookies();
      setUserToken(cookies.get("userToken"));
    }
  }, []);
  const userDetails = useSelector((state) => state.UserProfileReducer.UserData);
  return (
    <AppointmentTooltip.Content
      {...restProps}
      appointmentData={appointmentData}
    >
      <Grid container alignItems="center" justifyContent="center">
        {appointmentData.status == "active" ? (
          <div className={styles.cardButtons} style={{ paddingBottom: "5px" }}>
            <a
              href={`${appointmentData.meetingUrl}?token=${userToken}&firstName=${userDetails.firstName}&lastName=${userDetails.lastName}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button
                variant="contained"
                size="lg"
                style={{ backgroundColor: " #794DF5", color: "#ffffff" }}
              >
                {CapitalizeFirstLetter("start meeting")}
              </Button>
            </a>
          </div>
        ) : appointmentData.status == "completed" ? (
          <div className={styles.cardButtons} style={{ padding: "6px 0px" }}>
            <Button
              variant="contained"
              disableElevation
              size="lg"
              style={{ backgroundColor: "#F5FFFD", color: "#ffffff" }}
            >
              <Typography
                style={{ color: "#089A79", textTransform: "capitalize" }}
              >
                Meeting Completed
              </Typography>
            </Button>
          </div>
        ) : (
          <div className={styles.cardButtons} style={{ padding: "6px 0px" }}>
            <Button
              variant="contained"
              disableElevation
              size="lg"
              style={{ backgroundColor: "#f5e8d5", color: "#ffffff" }}
            >
              <Typography
                style={{ color: "#f29b1b", textTransform: "capitalize" }}
              >
                Meeting yet to start
              </Typography>
            </Button>
          </div>
        )}
      </Grid>
    </AppointmentTooltip.Content>
  );
};

export default function CalenderStudent({ cloudLabList, currDate }) {
  var schedular = [];
  if (cloudLabList) {
    let modifyLength = Object.keys(cloudLabList.modify);
    for (let i = 0; i < modifyLength.length; i++) {
      schedular.push({
        startDate: cloudLabList?.modify[`${i}`]?.startDate || "",
        endDate: cloudLabList?.modify[`${i}`]?.endDate || "",
        title: cloudLabList?.modify[`${i}`]?.meetingName || "",
        status: cloudLabList?.modify[`${i}`]?.status || "",
        meetingUrl: cloudLabList?.modify[`${i}`]?.meetingUrl || "",
        id: cloudLabList?.modify[`${i}`]?.id || "",
        cloudLabPin: cloudLabList?.modify[`${i}`]?.cloudLabPin || "",
      });
    }
  }

  return (
    <Paper>
      <Scheduler data={schedular}>
        <ViewState currentDate={schedular.startDate} />
        <Toolbar />
        <ViewState currentDate={currDate ? currDate : schedular.startDate} />
        <DayView />
        <WeekView />
        <MonthView />
        <Appointments />
        <AppointmentTooltip
          headerComponent={Header}
          contentComponent={Content}
          showCloseButton
        />
        <ViewSwitcher />
        <TodayButton />

        <DateNavigator />
      </Scheduler>
    </Paper>
  );
}
