import styles from "./CardsViewHeader.module.scss";
import CustomSearch from "./../pages/Tasks/CustomSearch";
import { Link } from "react-router-dom";
import { TervButtonFilled } from "./TervButtons";
import { PlusIcon } from "assets/icons/icons";

interface Props {
  header: string;
  searchPlaceholder?: string;
  handleSearch: (s: string) => void;
  createLink: string | { pathname: string; [key: string]: any };
  handleClick: (s: string) => void;
}

function CardsView({
  header,
  handleSearch,
  handleClick,
  searchPlaceholder,
  createLink,
}: Props) {

  return (
    <div className={styles.main}>
      <h4 className={styles.header}>{header}</h4>
      <CustomSearch
        handlesearch={handleSearch}
        placeholder={`Search ${searchPlaceholder || ""}`}
        value={undefined}
        style={{}}
        secondary={false}
        handleClick={handleClick}
      />
      <Link to={typeof createLink === "string" ? createLink : createLink.pathname}>
        <TervButtonFilled
          text="Create New"
          icon={<PlusIcon size="16" color="#fff" />}
        />
      </Link>
    </div>
  );
}

export default CardsView;
