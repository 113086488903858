import { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Button } from "reactstrap";
import cn from "classnames";
import { ResponsiveBar } from "@nivo/bar";
import { Dialog, Grow, Tooltip, useMediaQuery } from "@material-ui/core";
import { convertFromHTML } from "draft-js";
import {
  ArrowBack,
  CheckCircle,
  Info,
  Close,
  Cancel,
  CheckBoxOutlineBlank,
} from "@material-ui/icons";
import Apihelper from "../../api/apiHelper";
import { trackActivity } from "../../api";
import api from "../../api/baseConfig";
import Card from "./report-components/card";
import { withStyles } from '@material-ui/core/styles';
import styles from "./PracticeSummary.module.scss";

const getDifficultyChartColor = (bar) => bar.data?.color;

const htmlTagPattern = /(<([^>]+)>)/gi;

const DarkTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#252525',
    color: '#FFFFFF',
    padding: "10px"
  },
}))(Tooltip);

const scoreCalculation = (
  <div
    style={{
      fontFamily: "Poppins",
      color: "#FFFFFF",
      fontSize: "14px",
    }}
  >
    <p
      style={{
        fontWeight: "normal"
      }}
    >
      Scores are calculated based on these marks
    </p>
    <span
      style={{
        lineHeight: "10px"
      }}
    >
      <p
        style={{
          fontWeight: 600
        }}
      >
        Aptitude Questions
      </p>
      <span
        style={{
          fontWeight: 300
        }}
      >
        <p>Easy - 5</p>
        <p>Medium - 10</p>
        <p>Hard - 25</p>
      </span>
    </span>
    <span
      style={{
        lineHeight: "10px"
      }}
    >
      <p
        style={{
          fontWeight: 600
        }}
      >
        Coding Questions
      </p>
      <span
        style={{
          fontWeight: 300
        }}
      >
        <p>Easy - 25</p>
        <p>Medium - 50</p>
        <p>Hard - 100</p>
      </span>
    </span>
  </div>
);

function htmlToString(html = "") {
  const text = (html || "")
    .replace(htmlTagPattern, "")
    ?.replace(/&nbsp;/g, " ");

  return text.length > 40 ? `${text.slice(0, 40)}...` : text;
}

function InsightBox({ title, description }) {
  return (
    <div className={styles.insightBox}>
      <h3>{title}</h3>
      <h1>{description}</h1>
    </div>
  );
}

const categoryClasses = [
  styles.category1,
  styles.category2,
  styles.category3,
  styles.category4,
  styles.category5,
  styles.category6,
  styles.category7,
  styles.category8,
  styles.category9,
  styles.category10,
];

function PracticeSummary(props) {
  const [data, setData] = useState(null);
  const [questionDetails, setQuestionDetails] = useState({
    visible: false,
  });

  useEffect(() => {
    if (props.location?.params?.labId) {
      window.sessionStorage.setItem("labId", props.location.params.labId);
    }

    const fetchData = async () => {
      await Apihelper.axiosCall(
        `${api.baseURL}${api.lab.getPracticeSummary
        }${window.sessionStorage.getItem("labId")}/${sessionStorage.getItem(
          "user_id"
        )}`,
        "get"
      )
        .then((d) => {
          trackActivity(d?.id, d?.name, 'viewed-practice-package-report');
          const pageData = d;
          const pageDataQuestions = [];
          const categoryClassNames = {};

          (pageData?.questions || []).forEach((q) => {
            const totalCategoryClassNames =
              Object.values(categoryClassNames).length;

            let categoryClassName = "";

            if (!categoryClassNames?.[q?.category]) {
              categoryClassName =
                categoryClasses[
                totalCategoryClassNames % categoryClasses.length
                ];
              categoryClassNames[q?.category] = categoryClassName;
            } else {
              categoryClassName = categoryClassNames?.[q?.category];
            }

            pageDataQuestions.push({
              ...q,
              categoryClassName,
            });
          });

          setData({ ...pageData, questions: pageDataQuestions });
        })
        .catch(() => { });
    };

    fetchData();
  }, []);

  const getOnlyStrings = (str) =>
    convertFromHTML(str) &&
      convertFromHTML(str).contentBlocks &&
      convertFromHTML(str).contentBlocks.length > 0
      ? convertFromHTML(str).contentBlocks[0].text
      : str;
      const smallScreen = useMediaQuery("(max-width: 420px)");
  return (
    <div className={smallScreen ? styles.pageMobile : styles.page}>
      <div className={styles.pageContent}>
        {data && (
          <>
            <Card title={data?.packageName}>
              <div className={smallScreen ? styles.chartContainerMobile : styles.chartContainer}>
                <Card
                  className={styles.chartBox}
                  titleClassName={styles.chartBoxTitle}
                  title="Accuracy Report (by difficulty)"
                >
                  <ResponsiveBar
                    animate={true}
                    axisBottom={{
                      legend: "Question Difficulty",
                      legendOffset: 45,
                      legendPosition: "middle",
                      tickPadding: 8,
                      tickRotation: 0,
                      tickSize: 0,
                    }}
                    axisLeft={{
                      legend: "% Accuracy",
                      legendOffset: -50,
                      legendPosition: "middle",
                      tickSize: 0,
                      tickValues: [0, 20, 40, 60, 80, 100],
                    }}
                    axisRight={null}
                    axisTop={null}
                    data={[
                      {
                        difficulty: "Easy",
                        Accuracy: data?.easyQuestionsAccuracy,
                        color: "#43B224",
                      },
                      {
                        difficulty: "Medium",
                        Accuracy: data?.mediumQuestionsAccuracy,
                        color: "#EEB60C",
                      },
                      {
                        difficulty: "Hard",
                        Accuracy: data?.hardQuestionsAccuracy,
                        color: "#D42216",
                      },
                    ]}
                    enableGridX={false}
                    groupMode="grouped"
                    gridYValues={[0, 20, 40, 60, 80, 100]}
                    indexBy="difficulty"
                    colors={getDifficultyChartColor}
                    isInteractive={false}
                    keys={["Accuracy"]}
                    labelTextColor="#000"
                    labelFormat={(d) => (
                      <tspan y={-12} style={{ fontSize: 16 }}>
                        {d}
                      </tspan>
                    )}
                    margin={{
                      bottom: 80,
                      left: 85,
                      right: 85,
                    }}
                    minValue={0}
                    maxValue={115}
                    padding={0.55}
                    theme={{
                      fontSize: "14px",
                      color: "#32333A",
                      axis: {
                        domain: {
                          line: {
                            stroke: "#000",
                            strokeWidth: 1,
                          },
                        },
                        legend: {
                          text: {
                            color: "#000",
                            fontSize: 18,
                            fontWeight: 500,
                          },
                        },
                      },
                    }}
                    valueScale={{ type: "linear" }}
                  />
                </Card>
                <Card
                  className={styles.chartBox}
                  titleClassName={styles.chartBoxTitle}
                  title="Accuracy Report (by topics)"
                >
                  <ResponsiveBar
                    animate={true}
                    axisBottom={{
                      legend: "Question Topics",
                      legendOffset: 45,
                      legendPosition: "middle",
                      tickPadding: 8,
                      tickRotation: 0,
                      tickSize: 0,
                      renderTick: () => <></>,
                    }}
                    axisLeft={{
                      legend: "% Accuracy",
                      legendOffset: -50,
                      legendPosition: "middle",
                      tickSize: 0,
                      tickValues: [0, 20, 40, 60, 80, 100],
                    }}
                    axisRight={null}
                    axisTop={null}
                    data={Object.entries(data.topicAccuracy || [])
                      .map((d) => ({
                        category: d?.[0],
                        Accuracy: d?.[1],
                      }))
                      .filter(({ Accuracy }) => Accuracy)}
                    enableGridX={false}
                    groupMode="grouped"
                    gridYValues={[0, 20, 40, 60, 80, 100]}
                    indexBy="category"
                    colors="#5048E5"
                    // isInteractive={false}
                    keys={["Accuracy"]}
                    labelTextColor="#000"
                    labelFormat={(d) => (
                      <tspan y={-12} style={{ fontSize: 16 }}>
                        {d}
                      </tspan>
                    )}
                    margin={{
                      bottom: 80,
                      left: 85,
                      right: 85,
                    }}
                    minValue={0}
                    maxValue={115}
                    padding={0.45}
                    theme={{
                      fontSize: "14px",
                      color: "#32333A",
                      axis: {
                        domain: {
                          line: {
                            stroke: "#000",
                            strokeWidth: 1,
                          },
                        },
                        legend: {
                          text: {
                            color: "#000",
                            fontSize: 18,
                            fontWeight: 500,
                          },
                        },
                      },
                    }}
                    valueScale={{ type: "linear" }}
                  />
                </Card>
              </div>
            </Card>
            <br />
            <br />
            <br />
            <Card title="Questions Summary">
              <div 
                style={{
                  position: "absolute",
                  right: "1.5rem", 
                  top: "-3rem"
                }}
              >
                <DarkTooltip title={scoreCalculation} arrow>
                  <div
                    style={{
                      display: "flex",
                      width: "294px",
                      height: "38px",
                      border: "1px solid #EEB60C",
                      boxSizing: "border-box",
                      borderRadius: "5px",
                      backgroundColor: "#FFFBEF",
                    }}
                  >
                    <span
                      style={{
                        marginTop: 5,
                        marginLeft: 10,
                      }}
                    >
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12 1.5C6.20156 1.5 1.5 6.20156 1.5 12C1.5 17.7984 6.20156 22.5 12 22.5C17.7984 22.5 22.5 17.7984 22.5 12C22.5 6.20156 17.7984 1.5 12 1.5ZM12.75 17.0625C12.75 17.1656 12.6656 17.25 12.5625 17.25H11.4375C11.3344 17.25 11.25 17.1656 11.25 17.0625V10.6875C11.25 10.5844 11.3344 10.5 11.4375 10.5H12.5625C12.6656 10.5 12.75 10.5844 12.75 10.6875V17.0625ZM12 9C11.7056 8.99399 11.4253 8.87282 11.2192 8.6625C11.0132 8.45218 10.8977 8.16945 10.8977 7.875C10.8977 7.58055 11.0132 7.29782 11.2192 7.0875C11.4253 6.87718 11.7056 6.75601 12 6.75C12.2944 6.75601 12.5747 6.87718 12.7808 7.0875C12.9868 7.29782 13.1023 7.58055 13.1023 7.875C13.1023 8.16945 12.9868 8.45218 12.7808 8.6625C12.5747 8.87282 12.2944 8.99399 12 9Z" fill="#EEB60C"/>
                      </svg>
                    </span>
                    <span
                      style={{
                        marginTop: 5,
                        marginLeft: 10,
                        fontFamily: "Poppins",
                        fontStyle: "normal",
                        fontWeight: 500,
                        fontSize: "16px",
                      }}
                    >
                      <p>How scores are calculated?</p>
                    </span>
                  </div>
                </DarkTooltip>
              </div>
              <div className={smallScreen ? styles.insightBoxContainerMobile : styles.insightBoxContainer}>
                <InsightBox
                  title="Total Questions"
                  description={data.numberOfQuestion}
                />
                <InsightBox
                  title="Attempted"
                  description={data.attemptedQuestion}
                />
                <InsightBox
                  title="Un-Attempted"
                  description={data.unattemptedQuestion}
                />
                <InsightBox
                  title="Right Answer"
                  description={data.rightAnswer}
                />
                <InsightBox
                  title="Wrong Answer"
                  description={data.wrongAnswer}
                />
                <InsightBox
                  title="Score"
                  description={`${data?.totalMarks}/${ data.allocatedMarks}`}
                />
              </div>
              <br />
              <br />
              <div className={styles.tableContainer}>
                <table className={smallScreen ?  styles.tableMobile : styles.table}>
                  <thead>
                    <tr>
                      <th>Q.No</th>
                      <th>Questions</th>
                      <th>Status</th>
                      <th>Difficulty</th>
                      <th>Category</th>
                      <th>Points</th>
                      <th>Details</th>
                    </tr>
                  </thead>
                  <tbody>
                    {(data?.questions || []).map((q, idx) => (
                      <tr key={idx}>
                        <td>{idx + 1}</td>
                        <td className={styles.questionCell}>
                          <span>{getOnlyStrings(q?.name || "")}</span>
                        </td>
                        <td>
                          {q?.status === "correct" ? (
                            <CheckCircle style={{ color: "#5AC62F" }} />
                          ) : q?.status === "wrong" ? (
                            <Cancel style={{ color: "#E22B12" }} />
                          ) : q?.status === "unAttempted" ? (
                            <CheckBoxOutlineBlank
                              style={{ color: "#848484" }}
                            />
                          ) : (
                            <></>
                          )}
                        </td>
                        <td>{q?.complexity}</td>
                        <td>
                          <div className={q?.categoryClassName}>
                            {q?.category}
                          </div>
                        </td>
                        <td>{q?.marks}/100</td>
                        <td>
                          <button
                            onClick={() => {
                              setQuestionDetails({
                                difficulty: q?.complexity,
                                hintViewReductionPercentage:
                                  q?.hintViewReductionPercentage,
                                marks: q?.marks,
                                no: idx + 1,
                                solutionViewReductionPercentage:
                                  q?.solutionViewReductionPercentage,
                                testCaseViewReductionPercentage:
                                  q?.testCaseViewReductionPercentage,
                                visible: true,
                              });
                            }}
                          >
                            <Info style={{ color: "#717070" }} />
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <br />
              <div className={smallScreen ? styles.legendMobile : styles.legend}>
                <div>
                  <CheckCircle style={{ color: "#5AC62F" }} /> Right
                </div>
                <div>
                  <Cancel style={{ color: "#E22B12" }} /> Wrong
                </div>
                <div>
                  <CheckBoxOutlineBlank style={{ color: "#848484" }} />{" "}
                  Un-attempted
                </div>
              </div>
            </Card>
            <p />
            <p />
            <p />
            <div>
              <Button
                outline
                style={{ display: "inline-block !important", fontSize: 16 }}
                onClick={() => {
                  props.history.goBack();
                }}
              >
                <ArrowBack />
                &nbsp;&nbsp;
                {"Go Back"}
              </Button>
            </div>
          </>
        )}
      </div>
      <Dialog
        TransitionComponent={Grow}
        classes={{ paper: styles.modal }}
        open={questionDetails?.visible}
        onClose={() => {
          setQuestionDetails((d) => ({ ...d, visible: false }));
        }}
      >
        <div className={styles.modalHeader}>
          <>Details</>
          <button
            className={styles.modalCloseButton}
            onClick={() => {
              setQuestionDetails((d) => ({ ...d, visible: false }));
            }}
          >
            <Close />
          </button>
        </div>
        <div className={styles.modalContent}>
          <div className={styles.questionInfo}>
            <div>
              <span>Question no:</span>
              <span>{questionDetails?.no}</span>
            </div>
            <div>
              <span>Difficulty:</span>
              <span>{questionDetails?.difficulty}</span>
            </div>
          </div>
          <table className={styles.marksTable}>
            <thead>
              <tr>
                <th>Category</th>
                <th>Marks</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Total marks alloted</td>
                <td>100%</td>
              </tr>
              {!!questionDetails?.hintViewReductionPercentage && (
                <tr>
                  <td>Hints opened</td>
                  <td className={styles.green}>
                    -{questionDetails?.hintViewReductionPercentage}%
                  </td>
                </tr>
              )}
              {!!questionDetails?.testCaseViewReductionPercentage && (
                <tr>
                  <td>Testcases opened</td>
                  <td className={styles.red}>
                    -{questionDetails?.testCaseViewReductionPercentage}%
                  </td>
                </tr>
              )}
              {!!questionDetails?.solutionViewReductionPercentage && (
                <tr>
                  <td>Solution opened</td>
                  <td className={styles.red}>
                    -{questionDetails?.solutionViewReductionPercentage}%
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          <div
            className={cn(styles.marks, {
              [styles.good]: questionDetails?.marks > 75,
              [styles.medium]:
                questionDetails?.marks > 40 && questionDetails?.marks <= 75,
              [styles.poor]: questionDetails?.marks <= 40,
            })}
          >
            <div>Marks</div>
            <div>{questionDetails?.marks}/100</div>
          </div>
        </div>
      </Dialog>
    </div>
  );
}

export default withRouter(PracticeSummary);
