import React, { useState, useEffect, useRef } from "react";
import { Icon } from '@iconify/react';
import crossIcon from '@iconify/icons-akar-icons/cross';
import checkIcon from '@iconify/icons-akar-icons/check';

import VideoPlayer from "./videoPlayer";
import FullscreenIcon from "@material-ui/icons/Fullscreen";
import FullscreenExitIcon from "@material-ui/icons/FullscreenExit";
import VolumeUpIcon from "@material-ui/icons/VolumeUp";
import VolumeOffIcon from "@material-ui/icons/VolumeOff";
import Replay10Icon from "@material-ui/icons/Replay10";
import Forward10Icon from "@material-ui/icons/Forward10";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import PauseIcon from "@material-ui/icons/Pause";
import PlayCircleFilledIcon from "@material-ui/icons/PlayCircleFilled";
import PauseCircleFilledIcon from "@material-ui/icons/PauseCircleFilled";
import CircularProgress from "@material-ui/core/CircularProgress";

import { Button, Grid, IconButton } from "@material-ui/core";
import DoneRoundedIcon from "@material-ui/icons/DoneRounded";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";

import apiHelper from "api/apiHelper";
import api from "api/baseConfig";

import { getQuestionsByIds, saveVideoQuizQuestionAnswer, getVideoQuizReport } from "pages/utils/CommonAPIs";

function timeFormat(time) {
  const timePad = (val) => ("0" + Math.floor(val)).slice(-2);
  const sec = time % 60;
  const hr = Math.floor(time / 3600);
  return `${hr > 0 ? `${timePad(hr)}:` : ""}${timePad(
    (time % 3600) / 60
  )}:${timePad(time % 60)}`;
}

function elementToReact(ele) {
  const tagName = ele.tagName?.toLowerCase();
  const childNode = Array.from(ele.childNodes);
  if (
    childNode.length > 0 &&
    !(tagName === "script" || tagName === "style" || tagName === "meta")
  ) {
    const childReactElements = childNode
      .map((node) => elementToReact(node))
      .filter((el) => {
        return el !== null || el !== undefined;
      });
    return React.createElement(tagName, null, ...childReactElements);
  } else if (
    !(tagName === "script" || tagName === "style" || tagName === "meta")
  ) {
    const nodeType = ele.nodeType;
    if (nodeType === Node.TEXT_NODE) {
      return ele.textContent;
    } else {
      return null;
    }
  }
}

function unescapeHTML(html) {
  const ele = document.createElement("div");
  ele.innerHTML = html;
  const reactEle = elementToReact(ele);
  return reactEle;
}

export default function VideoQuiz({ videoData, quizData, userName, handleVideoDuration, updateProgress }) {
  const videoElement = useRef(null);
  const videoContainer = useRef(null);
  const alphabet = ["A","B","C","D","E","F","G","H","I","J","K","L","M","N","O","P","Q","R","S","T","U","V","W","X","Y","Z"];
  const [selectedQuiz, setSelectedQuiz] = useState({
    timeLine: 0,
    id: "",
    status: "",
    questionId: [],
    questions: [],
  });
  const [selectedQuestion, setSelectedQuestion] = useState({
    id: "",
    optionsList: [],
    question: "",
  });
  const [selectedQuesSolution, setSelectedQuesSolution] = useState({
    id: "",
    videoQuizId: "",
    questionId: "",
    optionId: [],
    marks: 0,
    correctOption: false,
  });
  const [quizId, setQuizId] = useState("");
  const [quiz, setQuiz] = useState([
    {
      id: "",
      timeLine: "",
      questionId: [],
      videoQuizId: null,
      active: true,
      solutionDTO: [
        {
          id: "",
          videoQuizId: "",
          questionId: "",
          optionId: [],
          marks: 0,
          correctOption: false,
        },
      ],
      status: "",
    },
  ]);
  const [quizIndex, setQuizIndex] = useState(null);
  const [quesIndex, setQuesIndex] = useState({ no: 0 });
  const [quesSolutionIndex, setQuesSolutionIndex] = useState(null);
  const [quesNav, setQuesNav] = useState("");
  const [quizLoading, setQuizLoading] = useState(true);
  const [quizTimeIndicator, setQuizTimeIndicator] = useState([]);
  const [userAnswerList, setUserAnswerList] = useState([]);
  const updateQuizId = (id, index) => {
    if (id !== "") {
      setQuizIndex(index);
      setQuizId(id);
    }
  };
  const [quesReportList, setQuesReportList] = useState([]);
  const [totalCorrectQues, setTotalCorrectQues] = useState(0);

  const {
    isLoading,
    playerState,
    playerCtrls,
    togglePlay,
    toggleMute,
    getProgress,
    setProgress,
    onLoadedData,
    setProgressEvent,
    setVolumeEvent,
    toggleFullScreen,
    useStyles,
  } = VideoPlayer(videoContainer, videoElement, quiz, quizId, updateQuizId, updateProgress);

  const css = useStyles();

  const moveNextQues = () => {
    submitAnswer("next");
  };
  const movePrevQues = () => {
    submitAnswer("prev");
  };
  const SkipQuizz = () => {
    setQuizId("");
  };
  const showReport = async () => {
  };

  const handleSelectOption = (id) => {
    let array = [...userAnswerList];
    let index = array.indexOf(id);
    if (index > -1) {
      array.splice(index, 1);
    } else {
      array.push(id);
    }
    setUserAnswerList(array);
  };
  const submitAnswer = async (navType) => {
    const id =
      selectedQuesSolution.id && selectedQuesSolution.id !== ""
        ? selectedQuesSolution.id
        : null;
    const param = {
      ...(id && { id }),
      userId: userName,
      videoQuizId: selectedQuiz.id,
      questionId: selectedQuestion.id,
      optionId: userAnswerList,
    };
    await saveVideoQuizQuestionAnswer(param).then((res) => {
      if (res.id && res.id !== "") {
        const tempQuizData = [...quiz];
        tempQuizData[quizIndex].solutionDTO[quesSolutionIndex] = res;
        setQuiz(tempQuizData);
        setQuesNav(navType);
      }
      setQuesSolutionIndex(null);
    });
  };

  useEffect(() => {
    if (quesNav !== "") {
      if (
        quesNav === "next" &&
        selectedQuiz.questions &&
        selectedQuiz.questions.length > quesIndex.no
      ) {
        setQuesIndex({ no: quesIndex.no + 1 });
      }
      if (quesNav === "prev" && selectedQuiz.questions && 0 < quesIndex.no) {
        setQuesIndex({ no: quesIndex.no - 1 });
      }
      setQuesNav("");
    }
  }, [quesNav]);

  useEffect(() => {
    if (quizId !== "") {
      const quizClone = [...quiz];
      if (quiz.length > 0)
        quiz.map((q, i) => {
          if (q.id == quizId && q.timeLine === playerState.currentTime) {
            quizClone[i]["status"] = "inProgress";
            setSelectedQuiz(quizClone[i]);
          }
        });
      setQuiz(quizClone);
    } else {
      setSelectedQuiz({
        timeLine: 0,
        id: "",
        status: "",
        questionId: [],
        questions: [],
      });
      setSelectedQuestion({
        id: "",
        optionsList: [],
        question: "",
      });
      setQuesIndex({ no: 0 });
    }
  }, [quizId]);

  useEffect(() => {
    setQuizLoading(true);
    const getQuestionsById = async (ids) => {
      try {
        await getQuestionsByIds(ids).then((res) => {
          const quizClone = { ...selectedQuiz };
          quizClone["questions"] = res;
          setQuesIndex({ no: 0 });
          setSelectedQuiz(quizClone);
          setQuizLoading(false);
        });
      } catch (e) {
        console.log({ getQuestionsByIds: e });
        setQuizLoading(false);
      }
    };
    if (selectedQuiz.questionId && selectedQuiz.questionId.length > 0) {
      if (
        selectedQuiz.questions === undefined ||
        (selectedQuiz.questions &&
          selectedQuiz.questions.length !== selectedQuiz.questionId.length)
      ) {
        getQuestionsById(selectedQuiz.questionId);
      } else {
        setSelectedQuestion(selectedQuiz.questions[quesIndex.no]);
        setQuizLoading(false);
      }
    } else {
      setQuizLoading(false);
    }
  }, [selectedQuiz]);

  useEffect(async() => {
    if (selectedQuiz.questionId && selectedQuiz.questionId.length > 0) {
      if(quesIndex.no === selectedQuiz.questionId.length) {
        await getVideoQuizReport(quizId).then((res) => {
          setQuesReportList(res.reportDTO ? res.reportDTO : []);
          setTotalCorrectQues(res.totalCorrectquestioncount ? res.totalCorrectquestioncount : 0)
        });
      } else if (selectedQuiz.questions && selectedQuiz.questions[quesIndex.no]) {
        let solutionIndex = null,
          isSolutionAvilable = false;
        if (quiz && quiz.length > 0 && quiz[quizIndex]) {
          const qz = quiz[quizIndex];
          if (qz.id === selectedQuiz.id) {
            if (qz.solutionDTO && qz.solutionDTO.length > 0) {
              qz.solutionDTO.map((solution, i) => {
                if (
                  solution.questionId ===
                  selectedQuiz.questions[quesIndex.no].id
                ) {
                  solutionIndex = i;
                  setQuesSolutionIndex(i);
                  isSolutionAvilable = true;
                }
              });
            }
          }
        }
        if (!isSolutionAvilable) {
          const tempQuizData = [...quiz];
          if (
            tempQuizData[quizIndex].solutionDTO === null ||
            tempQuizData[quizIndex].solutionDTO === undefined
          ) {
            tempQuizData[quizIndex].solutionDTO = [];
          }
          const solution = {
            id: "",
            videoQuizId: selectedQuiz.id,
            questionId: selectedQuiz.questionId[quesIndex.no].id,
            optionId: [],
            marks: 0,
            correctOption: false,
          };
          setQuesSolutionIndex(tempQuizData[quizIndex].solutionDTO.length);
          tempQuizData[quizIndex].solutionDTO.push(solution);
          setUserAnswerList([]);
          setSelectedQuesSolution(solution);
          setQuiz(tempQuizData);
        } else if (solutionIndex !== null) {
          setUserAnswerList(
            quiz[quizIndex].solutionDTO[solutionIndex].optionId
          );
          setSelectedQuesSolution(quiz[quizIndex].solutionDTO[solutionIndex]);
        }
        setSelectedQuestion(selectedQuiz.questions[quesIndex.no]);
      }
    }
  }, [quesIndex, selectedQuiz]);

  useEffect(() => {
    const arrIndicator = [];
    if (quiz && quiz.length > 0) {
      quiz.map((q) => {
        arrIndicator.push({
          position:
            Math.round(
              (q.timeLine /
                (playerCtrls.duration > 0
                  ? playerCtrls.duration
                  : videoData.duration)) *
                10000
            ) / 100,
          color:
            q.status === "" || q.status === null || q.status === undefined
              ? "#1BA94C"
              : q.status === "Completed"
              ? "#ef7207"
              : q.status === "inProgress"
              ? "#ef7207"
              : "#ef07e8",
        });
      });
      setQuizTimeIndicator(arrIndicator);
    }
  }, [quiz, playerCtrls.duration]);

  useEffect(() => {
    if (quizData && quizData.length > 0) {
      setQuiz(quizData);
    }
  }, [quizData]);

  useEffect(() => {
    handleVideoDuration(playerState.currentTime);
  }, [playerState.currentTime]);

  return (
    <>
      <div
        className={
          css.video + ` ${playerCtrls.isFullScreen ? "fullScreen" : ""}`
        }
        ref={videoContainer}
      >
        <div className="player">
          <video
            className="video"
            ref={videoElement}
            // controls
            onTimeUpdate={getProgress}
            onLoadedData={onLoadedData}
          >
            <source src={videoData.url} type="video/mp4"></source>
          </video>
          {quizId !== "" && (
            <div className={`quiz ${quizId !== ""}`}>
              <div className="ques">
                <div className={`questionLoading ${quizLoading}`}>
                  <CircularProgress />
                </div>
                <div className="containers">
                  <div className="content">
                    {quesIndex.no === selectedQuiz.questionId.length ? (
                      <div className="report">
                          <div className="header">
                            <div className="flex col">
                            <h3>Review the course material to  expand your learning.</h3>
                            <p>You got {`${totalCorrectQues}`} out of {`${selectedQuiz.questionId.length}`} correct.</p>
                            </div>
                          </div>
                          <div className="body">
                            {totalCorrectQues !== 0 && (
                            <div className="flex">
                              <div className="icon success">
                                <Icon icon={checkIcon} />
                              </div>
                              <div className="bodyContent">
                                <h3>What you know</h3>
                                {quesReportList.length > 0 && quesReportList.map((q, i) => q.iscorrectOption === true && (<p key={`success${i}`}>{q.questionName}</p>))}
                              </div>
                            </div>
                            )}
                            {totalCorrectQues !== selectedQuiz.questionId.length && (
                            <div className="flex">
                              <div className="icon error">
                                <Icon icon={crossIcon} />
                              </div>
                              <div className="bodyContent">
                                <h3>What you should review</h3>
                                {quesReportList.length > 0 && quesReportList.map((q,i) => q.iscorrectOption === false && (<p key={`error${i}`}>{q.questionName}</p>))}
                              </div>
                            </div>
                            )}
                          </div>
                      </div>
                    ):(<div className="question">
                      <h3>Question {quesIndex.no + 1}</h3>
                      {selectedQuestion &&
                        selectedQuestion.question &&
                        unescapeHTML(selectedQuestion.question)}
                      <h3>Answer</h3>
                      {quiz[quizIndex] &&
                        quiz[quizIndex].solutionDTO &&
                        quiz[quizIndex].solutionDTO[quesSolutionIndex] &&
                        quiz[quizIndex].solutionDTO[quesSolutionIndex].id && (
                          <p className="questionInfoSmall">
                            You are already attented this question
                          </p>
                        )}

                      {selectedQuestion.optionsList &&
                        selectedQuestion.optionsList.length > 0 && (
                          <div className="choice">
                            <ol>
                              {selectedQuestion.optionsList.map((option, i) => (
                                <li
                                  key={`choiceOption${i}`}
                                  className={`${
                                    userAnswerList.indexOf(option.id) > -1
                                      ? "active"
                                      : ""
                                  }`}
                                  onClick={() => handleSelectOption(option.id)}
                                >
                                  <div className="dr">
                                    <div className="dt">
                                      <div className="dtId">{alphabet[i]}</div>
                                    </div>
                                    <div className="dd">
                                      {unescapeHTML(option.optionValue)}
                                    </div>
                                  </div>
                                </li>
                              ))}
                            </ol>
                          </div>
                        )}
                      </div>)
                    }
                  </div>
                  {selectedQuiz.questions && (
                  <div className="footer">
                    <div className="action lf">
                      {quesIndex.no + 1 <= selectedQuiz.questions.length && (
                        <Button color="primary" variant="info" onClick={SkipQuizz} style={{fontWeight:700}} >
                          Skip Quiz
                        </Button>
                      )}
                    </div>
                    <div className="action center"><> </></div>
                    <div className="action rt">
                      {quesIndex.no !== 0 && quesIndex.no !== selectedQuiz.questions.length && (
                        <Button color="primary" variant="outlined" onClick={movePrevQues} >
                          Prev
                        </Button>
                      )}
                      {quesIndex.no + 1 < selectedQuiz.questions.length ? (
                        <Button color="primary" variant="contained" onClick={moveNextQues}
                          disabled={userAnswerList.length === 0}
                        >
                          Next
                        </Button>
                      ) : quesIndex.no + 1 === selectedQuiz.questions.length? (
                        <Button color="primary" variant="contained" onClick={moveNextQues}
                          disabled={userAnswerList.length === 0}
                        >
                          Submit
                        </Button>
                      ) : (
                        <Button color="primary" variant="contained" onClick={SkipQuizz} >
                          Continue Video
                        </Button>
                      )}
                    </div>
                  </div>
                  )}
                </div>
              </div>
            </div>
          )}
          <div className={`loading ${isLoading}`} onClick={togglePlay}>
            {isLoading ? (
              <CircularProgress />
            ) : !playerCtrls.isPlaying ? (
              <PauseCircleFilledIcon className={css.zoomInOut} />
            ) : (
              <PlayCircleFilledIcon className={css.zoomInOut} />
            )}
          </div>
          <div className="controls">
            {quizId !== "" && (<div className="disableRange"></div>)}
            <div className="range">
              <input
                type="range"
                min="0"
                max="100"
                step="0.1"
                value={playerState.progress}
                onChange={(e) => setProgressEvent(e)}
              />
              <div
                className="rageTrack"
                style={{ backgroundSize: `${playerState.progress}% 100%` }}
              >
                {quizTimeIndicator.length > 0 &&
                  quizTimeIndicator.map((indicator, i) => (
                    <div
                      key={`indicator${i}`}
                      style={{
                        left: `${indicator.position}%`,
                        backgroundColor: indicator.color,
                      }}
                      className="indicator"
                    ></div>
                  ))}
              </div>
            </div>
            <div className="actions">
              <div className="group">
                <button
                  onClick={() => setProgress(-10)}
                  disabled={playerState.currentTime - 10 < 0}
                >
                  <Replay10Icon />
                </button>
                <button onClick={togglePlay}>
                  {!playerCtrls.isPlaying ? <PlayArrowIcon /> : <PauseIcon />}
                </button>
                <button
                  onClick={() => setProgress(10)}
                  disabled={playerState.currentTime + 10 > playerCtrls.duration}
                >
                  <Forward10Icon />
                </button>
                <div className="playerTime">
                  <i>{timeFormat(playerState.currentTime)}</i> <span> / </span>
                  <i>{timeFormat(playerCtrls.duration)}</i>
                </div>
                {quizId !== "" && (<div className="disableAction"></div>)}
              </div>
              <div className="group">
                <div className="volumeCtrl">
                  <div className="track">
                    <input
                      type="range"
                      min="0"
                      max="100"
                      step="1"
                      value={playerCtrls.volume}
                      onChange={(e) => setVolumeEvent(e)}
                    />
                    <div className="rageTrack" style={{ backgroundSize: `${playerCtrls.volume}% 100%` }}></div>
                  </div>
                  <button onClick={toggleMute}>
                    {playerCtrls.isMuted ? <VolumeOffIcon /> : <VolumeUpIcon /> }
                  </button>
                </div>
                <button onClick={toggleFullScreen}>
                  {!playerCtrls.isFullScreen ? (
                    <FullscreenIcon />
                  ) : (
                    <FullscreenExitIcon />
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
