import { useEffect, useState } from "react";
import InsightBox from "../../../components/InsightBox/InsightBox";
import PocketIcon from "../../../icons/pocket";
import CheckCircleIcon from "../../../icons/check-circle";
import EditIcon from "../../../icons/edit";
import ActivityIcon from "../../../icons/activity";
import { useGetDashboardInfo } from "pages/Dashboard/SWRhooks";
import styles from "./Cards.module.scss";
import { Skeleton } from "@material-ui/lab";
import { Link } from "react-router-dom";

export function Cards() {
  const [dataIsLoading, setIfDataIsLoading] = useState(false);

  const [insights, setInsights] = useState({
    numQuestionsSolved: null,
    numCoursesEnrolled: null,
    numAssessmentsPending: null,
    avgAssessmentMarks: null,
  });

  const apiResp = useGetDashboardInfo("getStudentDashBoardCountCards", true);

  useEffect(() => {
    if (apiResp === "Fetching...") {
      setIfDataIsLoading(true);
    }
    if (apiResp && apiResp.hasOwnProperty("numQuestionsSolved")) {
      setInsights({
        numQuestionsSolved: apiResp?.numQuestionsSolved,
        numCoursesEnrolled: apiResp?.numCoursesEnrolled,
        numAssessmentsPending: apiResp?.numAssessmentsPending,
        avgAssessmentMarks: apiResp?.avgAssessmentMarks,
      });
      setIfDataIsLoading(false);
    }
  }, [apiResp]);

  return dataIsLoading ? (
    <div className={styles.cards}>
      <Skeleton style={{ height: "12rem" }} />

      <Skeleton style={{ height: "12rem" }} />

      <Skeleton style={{ height: "12rem" }} />

      <Skeleton style={{ height: "12rem" }} />
    </div>
  ) : (
    <>
      <div className={styles.cards}>
        <Link
          to={{
            pathname: "/lab",
          }}
        >
        <InsightBox
          color="ink"
          description="Questions Solved"
          Icon={PocketIcon}
          title={insights.numQuestionsSolved}
        />
        </Link>
        <Link
          to={{
            pathname: "/lab",
          }}
        >
        <InsightBox
          color="green"
          description="Practice Package Enrolled"
          Icon={CheckCircleIcon}
          title={insights.numCoursesEnrolled}
        />
        </Link>
        <Link
          to={{
            pathname: "/assessment",
          }}
        >
        <InsightBox
          color="red"
          description="Assessment Pending"
          Icon={EditIcon}
          title={insights.numAssessmentsPending}
        />
        </Link>
        <Link
          to={{
            pathname: "/assessment",
          }}
        >
        <InsightBox
          color="blue"
          description="Avg Assessment Marks"
          Icon={ActivityIcon}
          title={`${insights.avgAssessmentMarks}%`}
        />
        </Link>
      </div>
    </>
  );
}
