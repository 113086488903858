export const ReactSelectTervStyle = (size = "lg", isMulti) => ({
  control: (prev, state) => ({
    ...prev,
    ...(!isMulti
      ? {
          height: "2.5rem",
        }
      : { maxHeight: "8rem", overflowY: "auto" }),
    width:
      size === "lg"
        ? "32rem"
        : size === "smd"
        ? "19.6875rem"
        : size === "nmd"
        ? "17.6875rem"
        : size === "xlg"
        ? "41.5rem"
        : "15rem",

    background: "#FAFBFC",
    border: "1px solid #DFE1E6",
    borderRadius: 3,
    boxShadow: state.isFocused ? "0 0 0 1px #794df5" : 0,
    "&:hover": {
      border: "1px solid #DFE1E6",
    },
  }),
  dropdownIndicator: (prev) => ({ ...prev, color: "#41526E" }),
  indicatorSeparator: (prev) => ({ ...prev, background: "#DFE1E6" }),
});
