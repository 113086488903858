import { Table, Button } from "reactstrap";
import { EditAction } from "./utils/EditAction";
import { DeleteAction } from "./utils/DeleteAction";
import { ResetPassword } from "./utils/ResetPassword";
import Styles from "./CustomTable.module.scss";
import SubTable from "./SubTable";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import * as CustomIcons from "../../assets/icons/icons.js";
import { useEffect, useState } from "react";
import { SortData } from "pages/utils/SortData";
import { uid } from "react-uid";
import { Checkbox, Chip, Tooltip } from "@material-ui/core";
import { sessionPrivileges } from "privileges";
import CustomSearch from "../Tasks/CustomSearch";
import Select from "react-select";

const arrowStyle = {
  color: "#adb5bd",
  cursor: "pointer",
};

const TervTable = (props) => {
  const {
    report,
    header,
    tableHeaders,
    setAllData,
    allData,
    createLink,
    history,
    accord,
    hoverable,
    // pagination: { firstIndex, lastIndex },
    sub: {
      getAllSubs,
      subTitle,
      subData,
      createSubLink,
      tableSubHeaders,
      editSubData,
    },
    collapse: { collapse, handleCollapse },
    delete: { deleteId, setDeleteId, handleDeleteModal, hideDelete },
    edit: { passEditFromRow, setEditObject, hideEdit },
    view: { hideShowView },
    resetPass: { hideResetPass },
    customer,
    activePage,
    itemsPerPage,
    overAllReport,
    hideUseTemp,
    checkedId,
    setChecked,
    checkedAll,
    setCheckedAll,
    setCustomSearchUser,
    customSearchUser,
    setCustomSearchEmail,
    customSearchEmail,
    setCustomSearchNum,
    customSearchNum,
    setCustomSearchProd,
    customSearchProd,
    setCustomSearchTyp,
    customSearchTyp,
    setQpModal,
  } = props;

  const [downArrow, setDownArrow] = useState(false);
  const Priv = sessionPrivileges().userprivileges || [];
  const isAdmin = Priv && Priv.includes("MANAGE_CUSTOMER");
  const [item, setItem] = useState([]);
  const [items, setItems] = useState('');

  const toggleArrow = () => setDownArrow((prev) => !prev);

  const sortData = (value, sortType) => {
    if (value) {
      const sortArray = SortData(allData, value, sortType);
      if (sortArray && sortArray.length > 0) {
        setAllData(sortArray);
      }
    }
  };

  //selecAllCheckBox
  useEffect(() => {
    setChecked([]);
    setCheckedAll(false);
  }, [customer]);

  const handleChange = (e) => {
    if (e.target.checked) {
      if (checkedId.length > 0) {
        setChecked([]);
      }

      allData.forEach((e) => {
        item.push(e.id);
      });
      let tempArr = [...new Set([...item])];
      setChecked(tempArr);
    } else {
      setChecked([]);
    }
  };

  const handleCheck = (e) => {
    if (allData.length === checkedId.length) {
      setCheckedAll(e.target.checked);
    } else {
      setCheckedAll(false);
    }
  };

  const options = [
    { value: 'course', label: 'course' },
    { value: 'package', label: 'package'},
  ]

  const Go = () => {
    props.setGetDataValue(true);
  }

  return (
    <Table striped hover={hoverable} style={{ overflowX: "auto" }}>
      <thead>
        <tr className={Styles.tableHeader}>
          {/* {window.location.pathname === "/userList" && (
            <th>
              <Checkbox
                color="primary"
                style={{ padding: 0 }}
                onChange={(e) => {
                  setCheckedAll(e.target.checked);
                  handleChange(e);
                }}
                checked={checkedAll}
              />
            </th>
           )} */}
          <th className={Styles.tableHeaderColor}>S.No</th>
          {tableHeaders.map((item, ind) => (
            <th key={uid(item.label, ind)} className={Styles.tableHeaderColor}>
              {item.label}&nbsp;
              {/* {downArrow ? (
                <KeyboardArrowDownIcon
                  style={arrowStyle}
                  onClick={() => {
                    sortData(item.val, "dsc");
                    toggleArrow();
                  }}
                />
              ) : (
                <KeyboardArrowUpIcon
                  style={arrowStyle}
                  onClick={() => {
                    sortData(item.val, "asc");
                    toggleArrow();
                  }}
                />
              )} */}
            </th>
          ))}
          {/* {window.location.pathname === "/activeUsersList" ? null : (
            <th
              style={{
                border: "none",
              }}
              className={Styles.tableHeaderColor}
            >
              Actions
            </th>
          )} */}
          {!hideResetPass && window.location.pathname === "/userList" ? (
            <th
              style={{
                border: "none",
              }}
              className={Styles.tableHeaderColor}
            >
              Reset password
            </th>
          ) : null}
        </tr>
      </thead>
      <tbody>
      {props.customSearchOn && (
      <tr className={Styles.tableHeaderColor}>
      <td>
        <Button
        color="success"
        size="lg"
                        // color="link"
                        onClick={() => {
                          Go();
                        }}
                      >
                        Go
                      </Button>
      </td>
      <td>
        <input
        placeholder="Name"
        value={customSearchUser}
        onChange={(e) => {
          setCustomSearchUser(e.target.value);
          props.setGetDataValue(false);
          
        }}
        // autoComplete="off"
      />
      </td>
      <td>
      <input
        placeholder="Email"
        value={customSearchEmail}
        onChange={(e) => {
          setCustomSearchEmail(e.target.value);
          props.setGetDataValue(false);
          
        }}
        autoComplete="off"
        />
      </td>
      <td>
      <input
        placeholder="Mobile"
        value={customSearchNum}
        onChange={(e) => {
          setCustomSearchNum(e.target.value);
          props.setGetDataValue(false);
          
        }}
        autoComplete="off"
        />
      </td>
      <td>
      <input
        placeholder="Package Name"
        value={customSearchProd}
        onChange={(e) => {
          setCustomSearchProd(e.target.value);
          props.setGetDataValue(false);
          
        }}
        autoComplete="off"
        />
      </td>
      <td>
      <Select
                    options={options}
                    onChange={(val) => {
                      setCustomSearchTyp(val.value);
                      props.setGetDataValue(false);
                    }}
                    placeholder="Package Type"
                    maxMenuHeight={300}
                  />
      </td>
      <td>
      </td>
      <td>
      </td>
      <td>
      </td>
      <td>
      </td>
        </tr>
      )}
      {allData.map((datum, index) => (
          <>
            <tr className={Styles.tableHeaderColor} key={uid(index)}>
              <td>{index + 1}</td>
              {tableHeaders.map((value, ind) => (
                <>
                  {value?.own ? (
                    <td key={uid(ind)}>
                      <Button
                        color="link"
                        onClick={() => {
                          getAllSubs(datum.id);
                          handleCollapse(datum.id);
                        }}
                      >
                        Show more {CustomIcons.showMoreIcon}
                      </Button>
                    </td>
                  ) : (
                    <td key={ind}>
                    {datum[value.val] == false ? (
                      "false" ):
                      datum[value.val] == true ? (
                         "true" ):
                         datum[value.val] == null ?  (
                          "-" ): (
                         datum[value.val]
                      )}
                    {/* {console.log(datum[value.val])}
                      {datum[value.val]} */}
                      </td>
                  )}
                </>
              ))}
              <td
                style={{
                  display:
                    window.location.pathname === "/overall-report"
                      ? "block"
                      : "none",
                }}
                onClick={() =>
                  history.push({
                    pathname: `${report ? "/practice-report" : "/report"}`,
                    state: {
                      assessmentId: datum.id,
                      name: datum.name,
                      customerId: props.customerId,
                      customer: customer,
                      overAllReport: overAllReport,
                      activePage: activePage,
                      itemsPerPage: itemsPerPage,
                      practiceLabId: datum.id,
                    },
                  })
                }
              >
                <button className={Styles.viewMoreBtn}>View more</button>
              </td>
              {/* <td
                style={{
                  display:
                    window.location.pathname === "/activeUsersList" ||
                    window.location.pathname === "/overall-report"
                      ? "none"
                      : "flex",
                }}
              >
                {(createLink === "/create-qp" && !datum.editable) ||
                !hideShowView ? (
                  <span
                    style={{
                      color: "#EB5757",
                      cursor: "pointer",
                      marginRight: 10,
                    }}
                    onClick={() =>
                      history.push({
                        pathname: createLink,
                        state: {
                          edit: true,
                          id: datum.id,
                          emailId: datum.email,
                          type: "viewOnly",
                          customer: customer,
                          activePage: activePage,
                          itemsPerPage: itemsPerPage,
                          row: passEditFromRow && setEditObject(datum),
                        },
                      })
                    }
                  >
                    {CustomIcons.outlinedEye}
                  </span>
                ) : (
                  ""
                )}
                <div style={{ display: "flex" }}>
                  {!hideEdit && (
                    <EditAction
                      editLink={createLink}
                      id={datum.id}
                      emailId={datum?.email}
                      desc={datum.name}
                      fromRow={passEditFromRow}
                      data={passEditFromRow && setEditObject(datum)}
                      editable={datum?.editable ? true : false}
                      customer={customer}
                      activePage={activePage}
                      itemsPerPage={itemsPerPage}
                    />
                  )}
                  {!hideDelete && (
                    <DeleteAction
                      desc={datum.name}
                      delete={() => {
                        handleDeleteModal();
                        if (deleteId !== datum.id) setDeleteId(datum.id);
                      }}
                    />
                  )}
                </div>
              </td> */}
           
            </tr>
            {/* {accord && (
              <tr
                style={{
                  display: collapse[datum.id],
                  transition: "max-height 0.2s ease-out",
                }}
              >
                <td
                  colSpan={6}
                  style={{
                    padding: 0,
                    transition: "max-height 0.2s ease-out",
                  }}
                  className={Styles.greenBorder}
                >
                  <SubTable
                    subTitle={subTitle}
                    subData={subData}
                    createSubLink={`${createSubLink}${datum.id}`}
                    tableSubHeaders={tableSubHeaders}
                    deleteSubDatum={(id) => {
                      handleDeleteModal();
                      if (deleteId !== id) setDeleteId(id);
                    }}
                    editSubData={editSubData}
                    passSubEditFromRow
                    header={header}
                    hideSubAdd={props.hideSubAdd}
                    hideSubEdit={props.hideSubEdit}
                    hideSubDelete={props.hideSubDelete}
                    activePage={activePage}
                    itemsPerPage={itemsPerPage}
                  />
                </td>
              </tr>
            )} */}
          </>
        ))}
      </tbody>
    </Table>
  );
};

export default TervTable;
