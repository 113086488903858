import Select from "react-select";
import { Col, Label } from "reactstrap";
const customStyles = {
  menu: (styles) => ({ ...styles, zIndex: 9999 }),
};

const setObjectFromString = (array, string) => {
  let obj = "";
  if (array && array.length > 0) {
    array.forEach((element) => {
      if (element.value === string) {
        obj = element;
      }
    });
  }

  return obj;
};

const SelectInput = ({
  label,
  input,
  placeholder,
  options,
  meta: { touched, error },
  allOptions,
  setOptions,
  setPropsOptions,
  returnObject,
  disabled,
  needName,
  setCustName
}) => 
{
  return(
  <Col lg="6">
    <Label>{label}</Label>
    <Select
      value={
        returnObject ? input.name : setObjectFromString(options, input.value)
      }
      onChange={(option) => {
        input.onChange(returnObject ? option : option.value);
        if (input.name === "type" && allOptions && allOptions.length > 0) {
          setOptions();
          setPropsOptions();
        }
        if (needName) {
          setCustName(option.label)
        }
      }}
      options={options}
      placeholder={placeholder}
      styles={customStyles}
      isDisabled={disabled}
    />
    {touched && error && <span style={{ color: "red" }}>{error}</span>}
  </Col>
)};

export default SelectInput;