/** @format */

import React from "react";
import { ContentHeader } from "./ContentHeader";
import { HomeContent } from "./HomeContent";
import styles from "./JoinGroup.module.scss";
import { uid } from "react-uid";
import { Campaign, TGicon, WAicon } from "./SVGs";
import { RadioChecked } from "./../../../assets/icons/icons";
import { withStyles, Button } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import sliderBannerBg from "../../../assets/Landing/Vector2.png";
import { instaNew, linkedinNew, fbNew, youtubeNew } from "./SVGs";

const socialMedia = [
  {
    name: "instagram",
    icon: instaNew,
    links: "https://www.instagram.com/terv.pro?igsh=MTAybGkzM3RmMnJnNQ==",
  },
  {
    name: "facebook",
    icon: fbNew,
    links: "https://www.facebook.com/tervpro.edu",
  },
  {
    name: "linkedin",
    icon: linkedinNew,
    links: "https://www.linkedin.com/company/tervpro/mycompany/",
  },
  {
    name: "youtube",
    icon: youtubeNew,
    links: "https://www.youtube.com/@TervProTech",
  },
];

const SocialMediaItem = ({
  icon,
  pageLink,
}: {
  icon: JSX.Element;
  pageLink: string;
}) => (
  <div className={styles.socialMediaItem} onClick={() => window.open(pageLink)}>
    {icon}
  </div>
);


const WAButton = withStyles({
  root: {
    border: "1px solid #1BA94C",
    borderRadius: 5,
    color: "#1BA94C",
    padding: "0.75rem 1rem",
    textTransform: "none",
    fontFamily: "Poppins",
    "&:hover": {
      backgroundColor: "#fff",
    },
  },
})(Button);

const TGButton = withStyles({
  root: {
    border: "1px solid #0C81B4",
    borderRadius: 5,
    color: "#0C81B4",
    fontFamily: "Poppins",
    textTransform: "none",
    padding: "0.75rem 1rem",
    "&:hover": {
      backgroundColor: "#fff",
    },
  },
})(Button);

interface Props {
  whatsAppModal?: () => void;
  telegramAppModal?: () => void;
  isMobile: boolean;
  isTab: boolean;
}

const JoinGroup: React.FC<Props> = ({ isMobile, isTab }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.main}>
      <ContentHeader
        title={t(HomeContent.Headers.Group.Main)}
        description={t(HomeContent.Headers.Group.Description)}
        sub={t(HomeContent.Headers.Group.Sub)}
        titleRev
      />
      <div className={styles.joinGroupContainer}>
        <div
          style={{
            marginLeft: isMobile ? "0.5rem" : "0rem",
          }}
        >
          <h4 className={styles.joinGroupHeader}>
            {t(HomeContent.JoinGroup.Perks.header)}
          </h4>
          <ul className={styles.joinGroupList}>
            {HomeContent.JoinGroup.Perks.content.map(
              (p: string, ind: number) => (
                <li key={uid(ind)} className={styles.listItem}>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12 5C8.141 5 5 8.141 5 12C5 15.859 8.141 19 12 19C15.859 19 19 15.859 19 12C19 8.141 15.859 5 12 5ZM12 17C9.243 17 7 14.757 7 12C7 9.243 9.243 7 12 7C14.757 7 17 9.243 17 12C17 14.757 14.757 17 12 17Z" fill="#663DD3"/>
<path d="M12 9C10.373 9 9 10.373 9 12C9 13.627 10.373 15 12 15C13.627 15 15 13.627 15 12C15 10.373 13.627 9 12 9Z" fill="#663DD3"/>
</svg>

                  <span>{t(p)}</span>
                </li>
              ),
            )}
          </ul>
        </div>
        <div className={styles.joinGroupActions} style={{ backgroundImage: `url(${sliderBannerBg})`, backgroundPosition: 'top right', backgroundRepeat: 'no-repeat'  }}>
          {Campaign}
          <p className={styles.joinGroupBoxDesc}>
            {t(HomeContent.JoinGroup.Box.description)}
          </p>
          <div className={styles.buttonDiv}>
            {/* <TGButton onClick={() => telegramAppModal()}>
              <TGicon size={{ width: 24, height: 24 }} />
              &nbsp;&nbsp;
              {HomeContent.JoinGroup.Box.telegram}
            </TGButton> */}
            {/* <TGButton
              onClick={() => {
                window.open("https://t.me/tervtribe");
              }}
            >
              <TGicon size={{ width: 24, height: 24 }} />
              &nbsp;&nbsp;
              {t(HomeContent.JoinGroup.Box.telegram)}
            </TGButton>
            <WAButton >
              <WAicon size={{ width: 24, height: 24 }} />
              &nbsp;&nbsp;
              {HomeContent.JoinGroup.Box.whatsapp}
            </WAButton> */}
            {socialMedia.map((it, ind) => (
            <SocialMediaItem
              key={`socialMedia${ind}`}
              icon={it.icon}
              pageLink={it.links}
            />
          ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default JoinGroup;
