import { useContext } from "react";
import cn from "classnames";
import {
  Box,
  Divider,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { Button } from '@material-ui/core';
import { Pagination, PaginationItem } from "@material-ui/lab";
import MenuOpenIcon from "@material-ui/icons/MenuOpen";
import { TestContext } from "../../../../context/test.context";
import { Section } from "../../../section/section";
import { Timer } from "../../../timer/timer";
import { SectionSelector } from "../section-selector/section-selector";
import { SubmitButton } from "../submit-button/submit-button";
import { BackButton } from "../back-button/back-button";
import { TProps } from "./large-header.props";
import styles from "./large-header.module.scss";
import logo from "pages/LoginPage/New/chittilogo.svg";

export function LargeHeader(props: TProps) {
  const { mediumScreen, largeScreen } = useContext(TestContext);

  return (
    <Section
      className={cn(styles.wrapper, {
        [styles.mediumScreen]: mediumScreen,
        [styles.largeScreen]: largeScreen,
      })}
      contentClassName={styles.container}
      gridPosition={[
        [1, 2],
        [1, 2],
      ]}
    >
      <div className={styles.header}>
        <div className={cn(styles.headerBar, styles.bar1)}>
          <div style={{ display: "flex" }}>
            {props && props.customerLogo && props.customerLogo.productLogo && (
              <div className="pl-2">
                <img src={props.customerLogo.productLogo} alt={props.customerLogo.productName ? props.customerLogo.productName : "logo"} height="44px" />
              </div>
            )}
            <div className="pl-2">
              <Divider
                orientation="vertical"
                style={{
                  height: 42,
                }}
              />
            </div>
            <div className="pl-2">
              <img src={props?.assessmentLogo} height="44px" />
            </div>
            <Box mx="auto" />
          </div>
          <Grid alignItems="center" container>
            <Box mx="auto" />
            <Typography noWrap variant="h6">
              {props?.title}
            </Typography>
            <Box mx="auto" />
          </Grid>
          <Grid alignItems="center" container wrap="nowrap" style={{overflow:"visible"}} >
            <Box mx="auto" />
            <Tooltip title='confirm your answers before timer runs' arrow placement="bottom-start" style={{overflow:"visible"}} >
              <span>
              <Timer
                disabled={props.isAdmin === "true" ? true : false}
                elapsedTime={props.elapsedTime}
                totalTime={props.totalTime}
                timeType={props.timeType}
                currentQuestion={props.currentQuestion}
              />
              </span>
            </Tooltip>
            <Box mx={1.5} />
            {props.isAdmin === "true" ? (
              <BackButton submitAssessment={props.backToAssessment} />
            ) : (
              <div>
              {props.checkAllQuestions ? (
                <div>
                {props.numQuestions === props.numAnsweredQuestions ? (
                  <SubmitButton
                  disabled={props.disabled || props?.switchingDisable}
                  submitAssessment={props.submitAssessment}
                  />
                ):( 
                <Button variant="contained" disabled>
                  Submit Test
                </Button>
                )}
                </div>
              ):(
               <SubmitButton
               disabled={props.disabled || props?.switchingDisable}
               submitAssessment={props.submitAssessment}
               />
              )}
              </div>
            )}
          </Grid>
        </div>
        <Divider
          style={{
            backgroundColor: "#F2F1F3",
          }}
        />
        <div className={cn(styles.headerBar, styles.bar2)}>
          <div>
            {props?.sectionIds?.length && (
              <Grid alignItems="center" container wrap="nowrap">
                <Grid
                  container
                  direction="column"
                  style={{
                    display: "inline-flex",
                    marginRight: 16,
                    width: "auto",
                  }}
                >
                  <Typography
                    className={styles.sectionInfo}
                    noWrap
                    style={{
                      fontWeight: 600,
                    }}
                  >
                    {`Section ${props?.currentSection.num} of ${props?.sectionIds?.length}`}
                  </Typography>
                  <Typography className={styles.questionInfo} noWrap>
                    {`${props?.currentSectionQuestionIds?.length} questions`}
                  </Typography>
                </Grid>
                <SectionSelector
                  currentSection={props.currentSection}
                  disabled={props.disabled || props?.switchingDisable}
                  sectionIds={props.sectionIds}
                  setCurrentSectionDetails={props.setCurrentSectionDetails}
                  setCurrentSectionDetailsCB={props.setCurrentSectionDetailsCB}
                />
              </Grid>
            )}
          </div>
          <Grid alignItems="center" justifyContent="center" container>
            <Pagination
              disabled={props.disabled}
              classes={{
                ul: styles.paginationList,
              }}
              count={props?.currentSectionQuestionIds?.length}
              onChange={(_e, page: number) => {
                if(props?.timeType !== "question"){
                  props.navToQuestion(props.currentSection.id, page);
                }
              }}
              page={props?.currentQuestion.num}
              renderItem={(itemProps) => (
                <PaginationItem
                  className={cn({
                    ...(itemProps?.type === "page" &&
                      props?.currentSectionFlaggedQuestionIds?.includes?.(
                        props?.currentSectionQuestionIds?.[itemProps?.page - 1]
                      ) && {
                        [styles.flaggedQuestion]: true,
                      }),
                    ...(itemProps?.type === "page" &&
                      props?.currentSectionAnsweredQuestionIds?.includes?.(
                        props?.currentSectionQuestionIds?.[itemProps?.page - 1]
                      ) && {
                        [styles.answeredQuestion]: true,
                      }),
                  })}
                  {...itemProps}
                />
              )}
              shape="rounded"
            />
          </Grid>
          <Grid
            alignItems="center"
            justifyContent="flex-end"
            container
            wrap="nowrap"
          >
            <Box mx="auto" />
            <Grid direction="column" style={{ margin: "0 16px" }}>
              <Typography variant="h6" align="center">
                {`${props.numAnsweredQuestions}`} 
              </Typography>
              <Typography
                style={{ display: "flex", alignItems: "center" }}
                variant="caption"
              >
                <span
                  style={{
                    height: 8,
                    display: "block",
                    width: 8,
                    marginTop: 2,
                    marginRight: 6,
                    borderRadius: 6,
                    backgroundColor: "#43B224",
                  }}
                />
                Answered
              </Typography>
            </Grid>
            <Divider
              orientation="vertical"
              style={{
                height: 32,
              }}
            />
            <Grid direction="column" style={{ margin: "0 16px" }}>
              <Typography variant="h6" align="center">
                {`${props.numFlaggedQuestions}`}
              </Typography>
              <Typography
                style={{ display: "flex", alignItems: "center" }}
                variant="caption"
              >
                <span
                  style={{
                    height: 8,
                    display: "block",
                    width: 8,
                    marginTop: 2,
                    marginRight: 6,
                    borderRadius: 6,
                    backgroundColor: "#EEB60C",
                  }}
                />
                Flagged
              </Typography>
            </Grid>
            <Divider
              orientation="vertical"
              style={{
                height: 32,
              }}
            />
            <Grid direction="column" style={{ margin: "0 16px" }}>
              <Typography variant="h6" align="center">
                {`${props.numQuestions - props.numAnsweredQuestions}`}
              </Typography>
              <Typography variant="caption">Unanswered</Typography>
            </Grid>
            <Divider
              orientation="vertical"
              style={{
                height: 32,
              }}
            />
            <Box mx={1} />
            {!props.switchingDisable &&
            <Tooltip title="Expand Questions">
              <IconButton onClick={props.openSectionDrawer}>
                <MenuOpenIcon />
              </IconButton>
            </Tooltip>
            }
          </Grid>
        </div>
      </div>
    </Section>
  );
}
