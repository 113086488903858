import { Box, Grid } from '@material-ui/core';
import { Timer } from '../../../timer/timer';
import { SectionSelector } from '../section-selector/section-selector';
import { SubmitButton } from '../submit-button/submit-button';
import { TProps } from './small-header.props';
import styles from './small-header.module.scss';
import { BackButton } from '../back-button/back-button';

export function SmallHeader(props: TProps) {
  return (
    <>
      <div
        className={styles.smallHeader}>
        <Timer
          elapsedTime={props.elapsedTime}
          disabled={props.isAdmin === "true" ? true : false}
          smallScreen
          totalTime={props.totalTime}
          timeType={props.timeType}
          currentQuestion={props.currentQuestion}
        />
        {!((props.currentQuestion.isCodingType || props.currentQuestion.type  === "DESC") && props.currentTab.tab === 'solution') && (
          <Grid
            alignItems="center"
            className={styles.smallHeaderContent}
            container
            wrap="nowrap">
            <SectionSelector
              currentSection={props.currentSection}
              disabled={props.disabled}
              openSectionDrawer={props.openSectionDrawer}
              sectionIds={props.sectionIds}
              smallScreen
            />
            <Box
              mx="auto"
            />
            <Box
              mx={1}
            />
            {props.isAdmin === "true" ? (
              <BackButton
                submitAssessment={props.backToAssessment}
              />
            ) : (
              <SubmitButton
                submitAssessment={props.submitAssessment}
                disabled={props.disabled}
                smallScreen
              />
            )}
          </Grid>
        )}
      </div>
    </>
  );
}
