import { reduxForm, FieldArray, getFormValues, change } from "redux-form";
import { useSelector, useDispatch, connect } from "react-redux";
import { useState, useEffect } from "react";
import api from "../../api/baseConfig";
import Apihelper from "../../api/apiHelper";
import { SectionsArray } from "./SectionsArray";
// import { SectionArrayTest } from "./SectionsArrayTest";
import { SectionArrayTest } from "./SectionsArrayTestNew";
import { toast } from "react-toastify";
import { getAllQuestionBanks } from "pages/utils/CommonAPIs";
// import { sessionPrivileges } from "privileges";

const QPSectionNew = ({
  totalSections,
  questionBanks,
  setQuestionBanks,
  assessmentQuestionPaperId,
  gradingProcess,
  disabled,
  setNoOfSections,
  initialValues,
  handleInitialValues,
  updateInitialValues,
  sessionPrivileges,
  ...props
}) => {
  const allQBCall = async () => {
    try {
      const allQBs = await getAllQuestionBanks();
      setQuestionBanks(allQBs);
    } catch (e) {
      console.log({ allQBs: e });
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    allQBCall();
  }, []);
  return (
    <SectionArrayTest
      name="sections"
      initialValues={initialValues}
      handleInitialValues={handleInitialValues}
      updateInitialValues={updateInitialValues}
      questionBanks={questionBanks}
      setQuestionBanks={setQuestionBanks}
      sessionPrivileges={sessionPrivileges}
      assessmentQuestionPaperId={assessmentQuestionPaperId}
      gradingProcess={gradingProcess}
    />
  );
};

const questionPaperSectionForm = reduxForm({
  form: "questionPaperSectionForm",
  enableReinitialize: true,
})(QPSectionNew);

export default connect(null, null)(questionPaperSectionForm);
