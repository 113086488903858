// import Axios from "axios";
// import api from "../../api/baseConfig";
// import Apihelper from "../../api/apiHelper";
// import { useHistory } from "react-router-dom";
const INIT_STATE = {
  questions: [],
  error: {},
};
const questions = (state = INIT_STATE, action) => {
  switch (action.type) {
    case "GET_QUESTIONS":
      return {
        ...state,
        questions: action?.payload || "",
      };
    case "GET_QUESTIONS_FAIL":
      return {
        ...state,
        questions: action?.payload || "",
      };
      case "SAVE_QUESTION": {
      }
    default:
      return state;
  }
};
export default questions;
