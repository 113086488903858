import React, { useState, useEffect } from "react"
import { Container, Card, CardBody } from "reactstrap"
import SectionReport from "../../Assessment/userReport/sectionLevel"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import api from "../../../api/baseConfig"
import Apihelper from "../../../api/apiHelper"
import { Link } from "react-router-dom"
import moment from "moment"
export default function ViewResult(props) {
  const [details, setDetails] = useState({})
  useEffect(() => {
    Apihelper.axiosCall(
      `${api.baseURL}${api.userManagement.getAssementReview}${props.location.state.id.user}/${props.location.state.id.assessment}`,
      "get"
    )
      .then(data => {
        setDetails(data)
      })
      .catch(e => {})
  }, [])

  const redirect = () => {
    props.history.push({
      pathname: "/report",
      state: props.location?.state?.reportState,
    })
  }
  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs
          title="Results"
          breadcrumbItem={props.location?.state?.name}
        />
        <div
          style={{
            fontSize: 14,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div>
            <strong>Marks Obtained:</strong>
            &nbsp;{props.location?.state?.marks}
          </div>
          <div>
            <strong>Grading: </strong>

            {details.gradingProcess == "auto" ? "Auto" :
                (details.manualGradingCompleted
                  ? "Completed"
                  : "Pending"
                )
              
              }
            
          </div>
          <div>
            <strong>Start Time:</strong>&nbsp;
            {props.location?.state?.time?.startTime
              ? moment(props.location?.state?.time?.startTime).format("LLL")
              : "Not specified"}
          </div>
          <div>
            <strong>End Time:</strong>
            &nbsp;
            {props.location?.state?.time?.endTime
              ? moment(props.location?.state?.time?.endTime).format("LLL")
              : "Not specified"}
          </div>
        </div>
        <Card>
          <CardBody>
            <SectionReport
              data={details}
              ids={props.location?.state?.id}
              from={"assessment"}
              redirect={redirect}
            />
          </CardBody>
        </Card>
      </Container>
      <Link
        to="/assessments"
        className="btn text-muted d-none d-sm-inline-block btn-link"
      >
        <i className="mdi mdi-arrow-left mr-1" /> Back to Assessments{" "}
      </Link>
    </div>
  )
}
