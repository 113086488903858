export function Validation(toCheck, currentState, expectedState) {
  let validated = {};
  let test = true;
  toCheck.forEach((value) => {
    if (currentState[value] === undefined) {
      validated[value] = "Undefined";
      test = false;
    } else if (currentState[value] === null || !currentState[value].length) {
      validated[value] = "Null or empty string";
      test = false;
    } else {
      validated[value] = "Exists";
    }
  });
  return { validated, test };
}
