import React, { useEffect, useMemo, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
} from "@material-ui/core";
import { Draggable } from "react-beautiful-dnd";
import styles from "./SectionCreation.module.scss";
import { uid } from "react-uid";
import { DragHandle } from "./DragHandle";
import { animated, useSpring, config } from "react-spring";
import { TervButtonOutlined2, TervButtonOutlined, TervButtonFilled, TervButtonFilled2 } from "components/TervButtons";
import { PlusIcon, BXedit, BXTrash } from "assets/icons/icons";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Collapse from "@material-ui/core/Collapse";
import { LessonsList } from "./LessonsList";
import { RequiredIndicator } from "pages/utils/RequiredIndicator";
import { DeleteModulePre, ConformDelete } from "../../components/DeleteModelePre";

const paraStyle = {
  fontSize: 15,
  fontWeight: 500,
  color: "#5B5A5F",
  marginTop: 12,
  textAlign: "center",
};

export const SectionButtonGroup = ({ remove, toggleExpand, toggleExpanded, isExpanded, handle }) => {
  const [shows,setShows] = useState(true);
  return (
  <div>
  
  <div className={styles.sectionIconGroup}>
    <span onClick={remove}>
      <BXTrash size="24" color="#7C7887" />
    </span>
    <DragHandle handle={handle} />
    
    {shows && (
    <span
      onClick = {() => {setShows(!shows)}}
      className={`${styles.expandableSection} ${
        isExpanded 
      }`}
    >
      <ExpandMoreIcon style={{ fontSize: 26 }} onClick={toggleExpand} />
    </span>
     )} 
    {!shows && (
    <span
      onClick = {() => {setShows(!shows)}}
      className={`${styles.expandedSection} ${
        !isExpanded 
      }`}
    >
      <ExpandMoreIcon style={{ fontSize: 26 }} onClick={toggleExpanded} />
    </span>)}

  </div>
  </div>
)};

export const SectionItem = ({
  editableSections,
  itemIndex,
  toggleEditable,
  field,
  handleField,
  removeField,
  removeLesson,
  handleModal,
  setCurrentSection,
  sectionValues,
  setSectionValues,
  courseId,
  USER_ID,
  setCurrentLesson,
  currentLesson,
  expanded,
  handleExpanded,
  selSec,
  setSelSec,
  selLes,
  setSelLes,
  updateSelectedLesson,
}) => {
  const animatedStyles = useSpring({
    from: { x: 200 },
    to: { x: 0 },
    config: config.gentle,
    reverse: editableSections[itemIndex],
  });

  const [open, setOpen] = useState(false);
  const [show,setShow] = useState({})
  const [mediaDelete, setMediaDelete] = useState({ type: "", cb: null });
  const handleDeleteConformation = (obj) =>{
    setMediaDelete(obj)
  }

  const toggleDeleteModals = () => setOpen(false);

  return (
    <div>
   
    <Draggable
      key={`section-${itemIndex}`}
      draggableId={`section-${itemIndex}`}
      index={itemIndex}
    >
      {(provided) => (
        <div
          className={styles.sec__sectionContainer}
          key={uid(itemIndex)}
          ref={provided.innerRef}
          {...provided.draggableProps}
        >
          <label className="terv-label">
            {RequiredIndicator("Section Name")}
          </label>
          <div className={styles.sec__sectionItem}>
            <div className={styles.sec__sectionItemEditable}>
              <span>section {itemIndex + 1}</span>
              <div>
                <input
                  disabled={!editableSections[itemIndex]}
                  value={field.title}
                  onChange={(ev) => handleField(itemIndex, ev.target.value)}
                />
                {!editableSections[itemIndex] && (
                  <animated.span
                    onClick={() => toggleEditable(itemIndex)}
                    className={styles.sec__sectionItemEditIcon}
                    style={animatedStyles}
                  >
                    <BXedit size="24" color="#63606C" />
                  </animated.span>
                )}
              </div>
            </div>
            {editableSections[itemIndex] ? (
              <>
                <TervButtonOutlined2
                  text="Cancel"
                  onClick={() => {
                    toggleEditable(itemIndex);
                    handleField(itemIndex, "");
                  }}
                />
                <TervButtonFilled2
                  text="Add"
                  onClick={() => toggleEditable(itemIndex)}
                />
              </>
            ) : (
              <>
              <div className={styles.ContentBox}>
                <TervButtonOutlined2
                  text="Add Content"
                  icon={<PlusIcon size="16" color="#1ba94c" />}
                  onClick={() => {
                    handleModal();
                    setCurrentSection(itemIndex);
                    setSelSec(itemIndex);
                    setCurrentLesson({
                      resourceModuleOrder:
                        sectionValues[itemIndex].courseResource.length,
                      isActive: true,
                      courseId,
                      createdBy: USER_ID,
                      updatedBy: USER_ID,
                      name: "",
                      type: "",
                      duration: "" || 0,
                      documentType: "",
                      media: "",
                    });
                  }}
                />
                </div>
                <span style={{paddingTop:"7px"}}>
                <SectionButtonGroup
                  remove={() => setOpen(true)}
                  // remove={() => toggleDeleteModal()}
                  itemIndex={itemIndex}
                  toggleExpand={() => setShow(itemIndex)}
                  toggleExpanded={() => setShow(!itemIndex)}
                  isExpanded={!!expanded[itemIndex]}
                  handle={provided.dragHandleProps}
                />
                </span>
              </>
            )}
          </div>
          <DeleteModulePre
            Header="Course"
            Close={toggleDeleteModals}
            isOpen={open}
            Delete={() => removeField(itemIndex)}
          />

            <ConformDelete
              Header={
                mediaDelete.type === "deleteContent"
                  ? "Delete course content":""
              }
              Content={() =>
                mediaDelete.type === "deleteContent" ? (
                  <p style={paraStyle}>
                    <div>Are you sure want to delete course content?</div>
                    <div>You cannot recover once deleted</div>
                  </p>
                ) : (
                  ""
                )
              }
              isOpen={
                mediaDelete.type === "deleteContent"
              }
              handleClose={() => setMediaDelete({type: "",cb: null})}
              handleDelete={(cb) => {
                if (mediaDelete.type === "deleteContent") {
                  if (mediaDelete.cb !== null) mediaDelete.cb();
                  setMediaDelete({type: "",cb: null})
                }
              }}
            />
          {/* <Collapse in={!!expanded[itemIndex]}> */}
          {show == itemIndex ?
            <LessonsList
              sectionValues={sectionValues}
              setSectionValues={setSectionValues}
              itemIndex={itemIndex}
              setCurrentLesson={setCurrentLesson}
              handleModal={handleModal}
              setCurrentSection={setCurrentSection}
              removeLesson={removeLesson}
              currentLesson={currentLesson}
              selSec={selSec}
              setSelSec={setSelSec}
              selLes={selLes}
              setSelLes={setSelLes}
              handleDeleteConformation={handleDeleteConformation}
              updateSelectedLesson={updateSelectedLesson}
            />: null }
          {/* </Collapse> */}
        </div>
      )}
    </Draggable>
    </div>
  );
};
