import { useState } from "react";
import cn from "classnames";
import { Slider, useMediaQuery } from "@material-ui/core";
import { Section } from "../section/section";
import { QuestionSection } from "../question-section/question-section";
import { SolutionSection } from "../solution-section/solution-section";
import { TProps } from "./content.props";
import styles from "./content.module.scss";

export function Content(props: TProps) {
  const smallScreen = useMediaQuery("(max-width: 767px)");
  const largeScreen = useMediaQuery("(min-width: 1024px)");

  const maxQuestionSectionWidth = 50;
  const minQuestionSectionWidth = 30;

  const [questionSectionWidth, setQuestionSectionWidth] = useState(50);

  return (
    <>
      {largeScreen && (
        <div style={{ display: "flex", gridColumn: "1 / 2", gridRow: "2 / 3" }}>
          <Section
            className={styles.questionSection}
            gridPosition={[
              [1, 2],
              [1, 2],
            ]}
            style={{ width: `calc(${questionSectionWidth}% - 10px)` }}
          />
          <Section
            className={styles.solutionSection}
            gridPosition={[
              [3, 4],
              [1, 2],
            ]}
            style={{ width: `calc(${100 - questionSectionWidth}% - 10px)` }}
          />
        </div>
      )}
      <div
        className={cn(
          styles.content,
          smallScreen && styles.smallScreen,
          largeScreen && styles.largeScreen,
          props.currentQuestion.isCodingType && styles.codingQuestion
        )}
        style={{
          ...(!smallScreen && { gridColumn: "1 / 2", gridRow: "2 / 3" }),
          ...(smallScreen && { backgroundColor: "#fff" }),
          ...(largeScreen && { overflow: "hidden" }),
        }}
      >
        {largeScreen && (
          <div className={styles.sliderContainer}>
            <Slider
              max={100}
              min={0}
              onChange={(_e, width: any) => {
                if (width < minQuestionSectionWidth) {
                  setQuestionSectionWidth(minQuestionSectionWidth);
                } else if (width > maxQuestionSectionWidth) {
                  setQuestionSectionWidth(maxQuestionSectionWidth);
                } else {
                  setQuestionSectionWidth(width);
                }
              }}
              step={0.00001}
              ThumbComponent={(thumbProps) => (
                <span {...thumbProps}>
                  <span className={styles.widthSliderDot} />
                  <span className={styles.widthSliderDot} />
                  <span className={styles.widthSliderDot} />
                </span>
              )}
              value={questionSectionWidth}
            />
          </div>
        )}
        {(smallScreen &&
        (props.currentQuestion.isCodingType ||
          props.currentQuestion.type === "DESC")
          ? props.currentTab === "question"
          : true) && (
          <QuestionSection
            switchingDisable={props?.switchingDisable}
            elapsedTime={props?.elapsedTime}
            totalTime={props?.totalTime}
            currentQuestion={props.currentQuestion}
            currentQuestionData={props.currentQuestionData}
            currentSectionReportedQuestionsIds={props.currentSectionReportedQuestionsIds}
            currentSectionReportedMsgQuestionsIds={props.currentSectionReportedMsgQuestionsIds}
            currentSection={props.currentSection}
            disabled={props.disabled}
            assessmentId = {props.assessmentId}
            setAllSectionQuestionArray = {props.setAllSectionQuestionArray}
            allSectionQuestionArray = {props.allSectionQuestionArray}
            labId = {props.labId}
            flagQuestion={props.flagQuestion}
            navNextQuestion={props.navNextQuestion}
            navPrevQuestion={props.navPrevQuestion}
            questionSectionWidth={questionSectionWidth}
            isAdmin={props.isAdmin}
            reportProblem={props.reportProblem}
            setReportMessage={props.setReportMessage}
            reportMessage={props.reportMessage}
          />
        )}
        {(smallScreen && props.currentQuestion.isCodingType
          ? props.currentTab === "solution"
          : true) && (
          <SolutionSection
            setAllSectionQuestionArray = {props.setAllSectionQuestionArray}
            allSectionQuestionArray = {props.allSectionQuestionArray}
            reportProblem={props.reportProblem}
            setReportMessage={props.setReportMessage}
            reportMessage={props.reportMessage}
            currentSection={props.currentSection}
            clearSelection={props.clearSelection}
            currentTab={props.currentTab}
            codeEditorIsInLightMode={props.codeEditorIsInLightMode}
            codeLanguages={props.codeLanguages}
            codeSolution={props.codeSolution}
            confirmSolution={props.confirmSolution}
            currentQuestion={props.currentQuestion}
            currentQuestionData={props.currentQuestionData}
            customInput={props.customInput}
            descriptiveAnswer={props.descriptiveAnswer}
            disabled={props.disabled}
            flagQuestion={props.flagQuestion}
            handleRestrictedAction={props.handleRestrictedAction}
            navNextQuestion={props.navNextQuestion}
            navPrevQuestion={props.navPrevQuestion}
            // navNextSection={props.navNextSection}
            output={props.output}
            isAdmin={props.isAdmin}
            outputIsVisible={props.outputIsVisible}
            outputTab={props.outputTab}
            runCode={props.runCode}
            runningCode={props.runningCode}
            selectedOptions={props.selectedOptions}
            setCustomInput={props.setCustomInput}
            setDescriptiveAnswers={props.setDescriptiveAnswers}
            setIfCodeEditorIsInLightMode={props.setIfCodeEditorIsInLightMode}
            setIfOutputIsVisible={props.setIfOutputIsVisible}
            setOutput={props.setOutput}
            setOutputTab={props.setOutputTab}
            setSelectedOption={props.setSelectedOption}
            setSelectedOptions={props.setSelectedOptions}
            submittingSolution={props.submittingSolution}
            updateCodeSolutions={props.updateCodeSolutions}
            width={questionSectionWidth}
            // setCurrentSection={props.currentSection}
          />
        )}
      </div>
    </>
  );
}
