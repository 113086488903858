const baseUrl = "https://api.terv.pro/api/";

export const apiList = {
  baseUrl,
  practiceLab: {
    getData(labId: string, userId: string) {
      return `${baseUrl}lab/getUserPracticeLab/${labId}/${userId}`;
    },
    getDataFor3rdParty(externalLabId: string, userId: string) {
      return `${baseUrl}lab/getUserPracticeLabForThirdParty/${externalLabId}/${userId}`;
    },
    getQuestionInfo(questionId: string, labId: string, userId: string) {
      return `${baseUrl}lab/getUserLabQuestionDetails/${questionId}/${labId}/${userId}`;
    },
    getSolutionInfo(questionId: string, type: string) {
      return `${baseUrl}lab/getUserPracticeLabGuide/${questionId}/${type}`;
    },
    getCodeExecStatus(execId: string, questionId: string) {
      return `${baseUrl}lab/getTestCaseStatus/${execId}/${questionId}`;
    },
    getLearningPathUpdate(labId: string, userId: string){
      return `${baseUrl}lab/getUserLabPathStatus/${labId}/${userId}`
    },
    execCode: `${baseUrl}userAssessment/executeCodingResults`,
    // execCode: `${baseUrl}lab/dynamicCheck`,
    logActivity: `${baseUrl}lab/saveUserLabActivityInfo`,
    execCodeTestCases: `${baseUrl}lab/saveCodingQuestionSolution`,
    saveMCQSolution: `${baseUrl}lab/saveUserLabSolution`,
    questionCustomCondition: `${baseUrl}lab/questionCustomCondition`,
  },
  assessment: {
    getData(assessmentId: string, labId: string) {
      return `${baseUrl}userAssessment/getAssessmentQuestions/${assessmentId}${labId ? `/?labId=${labId}` : ''}`;
    },
    getStatus(reqId: string, assessmentId: string, labId: string) {
      return `${baseUrl}userAssessment/getUserAssessmentStatus/${reqId}/${assessmentId}${labId ? `?labId=${labId}` : ''}`;
    },
    getCodeLanguages(assessmentId: string) {
      return `${baseUrl}api/languages/${assessmentId}`;
    },
    lastViewed(questionId: any){
      `${baseUrl}userAssessment/saveLatestQuestionId/${questionId}`
    },
    saveAssessmentStatus: `${baseUrl}userAssessment/saveUserAssessmentStatus/`,
    saveSolution: `${baseUrl}userAssessment/saveUserSolution`,
    executeCode: `${baseUrl}userAssessment/executeCodingResults`,
    saveUserNavigateCount: `${baseUrl}userAssessment/saveUserNavigateCount`,
    saveAssessmentTimer:`${baseUrl}userAssessment/saveAssessmentTimer`,
    saveFlag:`${baseUrl}userAssessment/saveQuestionFlag`,
    clearSelection:`${baseUrl}userAssessment/clearUserSolution`,
    reportProblem:`${baseUrl}userAssessment/reportAProblem`,
    
  },
  dashboard: {
    admin: {
      cards(userId = sessionStorage.getItem("user_id")) {
        return `${baseUrl}dashboard/getAdminDashBoardCountCards/${userId}`;
      },
      assessment(userId = sessionStorage.getItem("user_id")) {
        return `${baseUrl}dashboard/getAdminDashBoardAssessmentDetails/${userId}`;
      },
      studentEnrollment(userId = sessionStorage.getItem("user_id")) {
        return `${baseUrl}dashboard/getAdminDashBoardStudentEnrolled/${userId}`;
      },
      assessmentPerformance(userId = sessionStorage.getItem("user_id")) {
        return `${baseUrl}dashboard/getAdminDashBoardAssessmentPerformance/${userId}`;
      },
      assessmentMarks(userId = sessionStorage.getItem("user_id")) {
        return `${baseUrl}dashboard/getAdminDashBoardStudentAssessmentMarks/${userId}`;
      },
      avgAssessmentMarks(userId = sessionStorage.getItem("user_id")) {
        return `${baseUrl}dashboard/getAdminDashBoardAvgAssessmentMarks/${userId}`;
      },
    },
    student: {
      cards(userId = sessionStorage.getItem("user_id")) {
        return `${baseUrl}dashboard/getStudentDashBoardCountCards/${userId}`;
      },
      topics(userId = sessionStorage.getItem("user_id")) {
        return `${baseUrl}dashboard/getStudentDashBoardTopics/${userId}`;
      },
      questionsSolved(userId = sessionStorage.getItem("user_id")) {
        return `${baseUrl}dashboard/getStudentDashBoardQuestionsSolved/${userId}`;
      },
      level(userId = sessionStorage.getItem("user_id")) {
        return `${baseUrl}dashboard/getStudentDashBoardLevel/${userId}`;
      },
      assessments(userId = sessionStorage.getItem("user_id")) {
        return `${baseUrl}dashboard/getStudentDashBoardAssessments/${userId}`;
      },
      practicePackages(userId = sessionStorage.getItem("user_id")) {
        return `${baseUrl}dashboard/getStudentDashBoardPackages/${userId}`;
      },
    },
  },
  activityTracker: `${baseUrl}terv/saveUserActivity`,
};
