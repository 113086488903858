
import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  buttonPrimary: {
    backgroundColor: "#794DF5",
    padding: "12px 32px",
    color: "#ffffff",
    fontWeight: 500,
    fontSize: 16,
    lineHeight: 1,
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#642dfb",
      color: "#ffffff",
    },
    "&:active, &:foucs": {
      backgroundColor: "#794DF5",
      color: "#ffffff",
    },
    "&.MuiButton-outlined": {
      backgroundColor: "transparent",
      borderColor: "#794DF5",
      color: "#794DF5",
      "&:hover": {
        backgroundColor: "transparent",
        borderColor: "#642dfb",
        color: "#642dfb",
      },
      "&:active, &:foucs": {
        backgroundColor: "transparent",
        borderColor: "#794DF5",
        color: "#794DF5",
      },
    },
  },
  dropdownMenu: {
    "&.MuiPaper-root": {
      overflow: "visible",
      boxShadow: "none",
      backgroundColor: "#ffffff",
      border: `1px solid #E1E1E1`,
      filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.00))",
      "& .MuiList-root > .MuiListItem-root": {
        color: "#20222F",
        fontSize: "13px",
        borderBottom: `1px solid #E1E1E1`,
        paddingTop: 8,
        paddingBottom: 8,
        "&:last-child": {
          borderBottom: `0px solid transparent`,
        },
      },
      "& .MuiListItem-root": {
        "&:hover": {
          background: "#E4DBFD",
        },
        "&.Mui-selected": {
          color: "#ffffff",
          background: "#9471F7",
          "&:hover": {
            background: "#9471F7",
          },
        },
      },
    },
  },
  formSelect: {
    "& .MuiSelect-root": {
      border: "1px solid #DFE1E6",
      backgroundColor: "#FAFBFC",
      fontSize: 13,
      color: "#20222F",
      borderRadius: 4,
      "&:foucs": {
        border: "1px solid #d5c8f9",
        boxShadow: "0 0 2px -1px #d5c8f9",
      },
    },
    "& .error": {
      "& .MuiSelect-root": {
        border: "1px solid #ffbcbc",
        boxShadow: "0 0 2px -1px #ffbcbc",
      },
    },
  },
  buttonDeleteIcon: {
    color: "#1BA94C",
    padding: "8px 8px",
    fontSize: 14,
    fontWeight: 500,
    textTransform: "none",
    lineHeight: 1,
    borderRadius: 4,
    "& svg": {
      "& path": {
        fill:"#41526E"
      },
    },
    "&:hover": {
      "& svg": {
        "& path": {
          fill:"#E50000"
        },
      },
    },
  },
  buttonSmSuccess: {
    border: "1px solid #1BA94C",
    color: "#1BA94C",
    padding: "8px 8px",
    fontSize: 14,
    fontWeight: 500,
    textTransform: "none",
    minWidth: 128,
    lineHeight: 1,
    borderRadius: 4,
  },
  buttonSuccess: {
    border: "1px solid #1BA94C",
    color: "#1BA94C",
    padding: "12px 32px",
    fontSize: 16,
    fontWeight: 500,
    textTransform: "none",
    minWidth: 164,
    lineHeight: 1,
  },
  buttonText: {
    color: "#0377D1",
    padding: 0,
    fontSize: 16,
    fontWeight: 500,
    textTransform: "none",
    minWidth: 164,
    lineHeight: 1,
    textDecoration: "underline",
  },
}));