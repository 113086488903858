import React, { useState, useEffect } from "react";
import Paper from "@material-ui/core/Paper";
import { ViewState } from "@devexpress/dx-react-scheduler";
import {
  Scheduler,
  WeekView,
  MonthView,
  Appointments,
  ViewSwitcher,
  Toolbar,
  DayView,
  DateNavigator,
  TodayButton,
  AppointmentTooltip,
} from "@devexpress/dx-react-scheduler-material-ui";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import styles from "../UserAssessmentMarks.module.scss";
import { useSelector } from "react-redux";
import Cookies from "universal-cookie";

const Appointment = ({ children, style, ...restProps }) => (
  <Appointments.Appointment
    {...restProps}
    style={{
      ...style,
      backgroundColor: "#DE5445",
      borderRadius: "8px",
    }}
  >
    {children}
  </Appointments.Appointment>
);

const Header = ({ children, appointmentData, classes, ...restProps }) => {
  const time = appointmentData.startDate;
  var countDownDate = new Date(time).getTime();
  var now = new Date().getTime();
  var distance = countDownDate - now;
  var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
  var seconds = Math.floor((distance % (1000 * 60)) / 1000);
  return (
    <AppointmentTooltip.Header {...restProps} appointmentData={appointmentData}>
      {appointmentData.status == "active" && (
        <div className="pt-1">
          <Button
            variant="contained"
            disableElevation
            size="small"
            style={{ backgroundColor: "#EB5757", color: "#ffffff" }}
          >
            Live
          </Button>
        </div>
      )}
      {appointmentData.status == "upcoming" && (
        <div className="pt-1" style={{ display: "flex" }}>
          <div className="pt-2 pr-2">
            <Typography style={{ textTransform: "capitalize" }}>
              start within{" "}
            </Typography>
          </div>
          <Button
            variant="contained"
            disableElevation
            size="lg"
            style={{ backgroundColor: "#089A79", color: "#ffffff" }}
          >
            {` ${hours}hrs ${minutes}mins ${seconds}sec`}
          </Button>
        </div>
      )}
    </AppointmentTooltip.Header>
  );
};

const Content = ({ children, appointmentData, classes, ...restProps }) => {
  const [userToken, setUserToken] = useState("");
  useEffect(() => {
    if (!userToken) {
      const cookies = new Cookies();
      setUserToken(cookies.get("userToken"));
    }
  }, []);
  const userDetails = useSelector((state) => state.UserProfileReducer.UserData);

  return (
    <AppointmentTooltip.Content
      {...restProps}
      appointmentData={appointmentData}
    >
      <Grid container alignItems="center" justifyContent="center">
        {appointmentData.status == "active" ? (
          <div className={styles.cardButtons} style={{ paddingBottom: "5px" }}>
            <a
              href={`${appointmentData.meetingUrl}?token=${userToken}&firstName=${userDetails.firstName}&lastName=${userDetails.lastName}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button
                variant="contained"
                size="lg"
                style={{ backgroundColor: " #794DF5", color: "#ffffff" }}
              >
                {"Join Meeting"}
              </Button>
            </a>
          </div>
        ) : appointmentData.status == "completed" ? (
          <div className={styles.cardButtons} style={{ padding: "6px 0px" }}>
            <Button
              variant="contained"
              disableElevation
              size="lg"
              style={{ backgroundColor: "#F5FFFD", color: "#ffffff" }}
            >
              <Typography
                style={{ color: "#089A79", textTransform: "capitalize" }}
              >
                Meeting Completed
              </Typography>
            </Button>
          </div>
        ) : (
          <div className={styles.cardButtons} style={{ padding: "6px 0px" }}>
            <Button
              variant="contained"
              disableElevation
              size="lg"
              style={{ backgroundColor: "#f5e8d5", color: "#ffffff" }}
            >
              <Typography
                style={{ color: "#f29b1b", textTransform: "capitalize" }}
              >
                Meeting yet to start
              </Typography>
            </Button>
          </div>
        )}
      </Grid>
    </AppointmentTooltip.Content>
  );
};

export default function CalenderStudent({ cloudLabList }) {
  var schedular = [];
  if (cloudLabList) {
    let modifyLength = Object.keys(cloudLabList.modify);
    for (let i = 0; i < modifyLength.length; i++) {
      schedular.push({
        startDate: cloudLabList?.modify[`${i}`]?.startDate || "",
        endDate: cloudLabList?.modify[`${i}`]?.endDate || "",
        title: cloudLabList?.modify[`${i}`]?.meetingName || "",
        status: cloudLabList?.modify[`${i}`]?.status || "",
        meetingUrl: cloudLabList?.modify[`${i}`]?.meetingUrl || "",
      });
    }
  }

  return (
    <Paper>
      <Scheduler data={schedular}>
        <Toolbar />
        <ViewState />
        <DayView />
        <WeekView />
        <MonthView />
        <Appointments />
        <AppointmentTooltip
          headerComponent={Header}
          contentComponent={Content}
          showCloseButton
        />
        <ViewSwitcher />
        <TodayButton />
        <DateNavigator />
      </Scheduler>
    </Paper>
  );
}
