import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import { Button } from "reactstrap";
import { CloseOutlined } from "@material-ui/icons";
import styles from "./QuestionBankActions.module.scss";

const QuestionBankModal = ({ open, onClose, Status, Name, ...props }) => (
  <Dialog open={open} onClose={onClose}>
    <DialogTitle classes={{ root: styles.header }}>
      {props.headerFor[Status]}
      <CloseOutlined classes={{ root: styles.closeButton }} onClick={onClose} />
    </DialogTitle>
    <DialogContent classes={{ root: styles.content }}>
      <span className={styles.contentText}>{props.contentFor[Status]}</span>
    </DialogContent>
    <DialogActions>
      {Status &&
        props.buttonsFor[Status].map((button) => (
          <Button
            color="primary"
            onClick={() => {
              onClose();
              props.Action(button.action);
            }}
            className={styles.buttons}
          >
            {button.label}
          </Button>
        ))}

      <Button
        onClick={onClose}
        color="primary"
        outline
        className={styles.buttons}
      >
        Cancel
      </Button>
    </DialogActions>
  </Dialog>
);

export default QuestionBankModal;
