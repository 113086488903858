import { LinearProgress, makeStyles } from '@material-ui/core';
import cn from 'classnames';

const progressBarStyles = makeStyles(() => ({
  root: {
    borderRadius: 5,
    width: '100% !important',
  },
  thick: {
    height: 10,
  },
  thin: {
    height: 8,
  },
  barColorGreen: {
    backgroundColor: '#039618',
  },
  barColorRed: {
    backgroundColor: '#DA3208',
  },
  barColorOrange: {
    backgroundColor: '#F56F18',
  },
  barDefaults: {
    borderRadius: 5,
  },
  bar: {
    backgroundColor: '#F3F3F3',
  },
}));

function ProgressBar({
  thin,
  red,
  green,
  orange,
  percentage }) {
  const progressBarClasses = progressBarStyles();

  return (
    <LinearProgress
      classes={{
        bar: cn(
          progressBarClasses.barDefaults,
          green
            ? progressBarClasses.barColorGreen
            : red
              ? progressBarClasses.barColorRed
              : orange
                ? progressBarClasses.barColorOrange
                : null,
        ),
        colorPrimary: progressBarClasses.bar,
        root: cn(
          progressBarClasses.root,
          thin ? progressBarClasses.thin : progressBarClasses.thick,
        ),
      }}
      variant="determinate"
      value={percentage}
    />
  );
}

export default ProgressBar;
