import { Label, Input, Button, Row } from "reactstrap";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Paper from "@material-ui/core/Paper";
import { connect } from "react-redux";
import api from "../../api/baseConfig";
import Apihelper from "../../api/apiHelper";
import { Field, reduxForm, getFormValues } from "redux-form";
import SelectInput from "../Tasks/SelectInput";
import EditorField from "../Tasks/EditorField";
import { AddImageComponent } from "components/AddImageComponent";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { sessionPrivileges } from "privileges";

const nameField = ({ input, label, type, meta: { touched, error } }) => (
  <div>
    <Label>{label}</Label>
    <Input {...input} type={type} />
    {touched && error && <span style={{ color: "red" }}>{error}</span>}
  </div>
);

const _NAME = (value) => {
  if (!value || value?.trim()?.length < 1 ) {
    return "Please enter a valid Name";
  }
};
const _DESC = (value) => {
  if (!value || value.replace(/<(.|\n)*?>/g, "").trim().length < 1 ) {
    return "Please enter a valid Description";
  }
};
const _COMP = (value) => {
  if (!value) {
    return "Please choose a Competency";
  }
};

const _CUST = (value) => {
  if (!value) {
    return "Please choose a Customer";
  }
};

const _SUBCOMP = (value) => {
  if (!value) {
    return "Please choose a Sub Competency";
  }
};
const _DOM = (value) => {
  if (!value) {
    return "Please  choose a Domain";
  }
};

const _SUBDOM = (value) => {
  if (!value) {
    return "Please choose a Sub Domain";
  }
};

let initialValues = {};

const asyncValidate = async (values) => {
  const encodedName = window.btoa(values.name);
  if (
    initialValues &&
    (!initialValues.hasOwnProperty("name") ||
      initialValues.name !== values.name)
  ) {
    await Apihelper.axiosCall(
      `${api.baseURL}${api.questionBankController.checkName}${encodedName}`,
      "get"
    ).then((boo) => {
      if (boo) {
        throw {
          name: "That name is already taken. Please choose a different name",
        };
      }
    });
  }
};

const QuestionBankForm = (props) => {
  const {
    handleSubmit,
    formValues,
    submitting,
    competencies,
    domains,
    subDomains,
    subCompetencies,
    getSubDomains,
    getSubCompetencies,
    media,
    setMedia,
    customer,
    activeTab,
    activePage,
    customerName,
    currentId,
  } = props;

  const [currentPage, setCurrentPage] = useState({});

  const [custName, setCustName] = useState(customerName || "");
  const [currId, setCurrId] = useState(currentId || "");
  const [initialValue, setInitialValue] = useState("");
  const [descValue, setDescValue] = useState("");

  if (props.initialValues.hasOwnProperty("id")) {
    initialValues = props.initialValues;
  }
  const Priv = sessionPrivileges().userprivileges || [];

  const allCustomers = props.Customers
    ? props.Customers.reduce((acc, val) => {
        acc.push({
          label: val.name,
          value: val.id,
        });
        return acc;
      }, [])
    : [];

  useEffect(() => {
    if (activePage) {
      for (const pageProperty in activePage) {
        if (pageProperty === activeTab + "List") {
          setCurrentPage({
            tabName: pageProperty,
            pageNo: activePage[pageProperty],
          });
        }
      }
    }

    if (formValues?.competencyId) {
      getSubCompetencies(formValues?.competencyId);
    }
  }, [formValues?.competencyId]);

  useEffect(() => {
    if (formValues?.domainId) {
      getSubDomains(formValues?.domainId);
    }
  }, [formValues?.domainId]);

  const validIndicator = (value) => (
    <>
      {value}
      <span style={{ color: "red" }}>*</span>
    </>
  );

  const handleClick = () => {
    if (initialValue?.length < 1){
      window.scrollTo(0, 50);
    }
  };

  return (
    <div className="page-content" style={{ marginBottom: 20 }}>
      <Breadcrumbs
        title="Question Bank"
        breadcrumbItem="Create Question Bank"
      />
      <Paper elevation={0} style={{ padding: 20, border: "1px solid #f2f1f3" }}>
        <form onSubmit={handleSubmit}>
          <div style={{ display: "flex", marginBottom: 20 }}>
            <div style={{ flex: 2, width: "50%" }}>
              <h5>Information</h5>
              <p style={{ color: "#A6A6AA" }}>
                Enter the necessary Question Bank Details
              </p>
            </div>
            <div
              style={{
                flex: 3,
                display: "flex",
                flexDirection: "column",
                gap: 20,
                width: "50%",
              }}
            >
              <Field
                name="name"
                component={nameField}
                label={validIndicator("Name")}
                validate={[_NAME]}
                onChange={(e) => {
                  setInitialValue(e.target.value);
                }}
              />
              <Field
                name="description"
                component={EditorField}
                label={validIndicator("Description")}
                validate={[_DESC]}
                style={{ flexGrow: 0 }}
              />
            </div>
          </div>
          <hr style={{ backgroundColor: "#E3F2FF" }} />
          {Priv.includes("MANAGE_CUSTOMER") && (
            <>
              <div style={{ display: "flex", marginBottom: 20 }}>
                <div style={{ flex: 2 }}>
                  <h5>Customer</h5>
                  <p style={{ color: "#A6A6AA" }}>Choose a Customer</p>
                </div>
                <div
                  style={{
                    flex: 3,
                    display: "flex",
                    flexDirection: "column",
                    gap: 20,
                  }}
                >
                  <Row>
                    <Field
                      name="collegeId"
                      component={SelectInput}
                      label={validIndicator("Customer")}
                      placeholder="Choose Customer"
                      options={allCustomers}
                      validate={[_CUST]}
                      needName={true}
                      setCustName={setCustName}
                    />
                  </Row>
                </div>
              </div>
              <hr style={{ backgroundColor: "#E3F2FF" }} />{" "}
            </>
          )}
          <div style={{ display: "flex" }}>
            <div style={{ flex: 2 }}>
              <h5>Image</h5>
              <p style={{ color: "#A6A6AA" }}> Upload an image</p>
            </div>
            <div
              style={{
                flex: 3,
                display: "flex",
                flexDirection: "column",
                gap: 20,
                justifyContent: "center",
              }}
            >
              <AddImageComponent
                handleCroppedImage={setMedia}
                media={
                  media.includes("default")
                    ? `https://d2ndap9rlps54r.cloudfront.net/DefaultUploadImages/${media}.jpeg`
                    : media
                }
                moduleName={"questionBank"}
                customerName={custName}
                currentId={currId}
              />
            </div>
          </div>
          <hr style={{ backgroundColor: "#E3F2FF" }} />
          <div style={{ display: "flex" }}>
            <div style={{ flex: 2 }}>
              <h5>Parameters</h5>
              <p style={{ color: "#A6A6AA" }}> Choose relevant parameters</p>
            </div>
            <div
              style={{
                flex: 3,
                display: "flex",
                flexDirection: "column",
                gap: 20,
              }}
            >
              <Row>
                <Field
                  name="competencyId"
                  component={SelectInput}
                  label={validIndicator("Competency")}
                  placeholder="Choose Competency"
                  options={competencies}
                  validate={[_COMP]}
                />
                <Field
                  name="subCompetencyId"
                  component={SelectInput}
                  label={validIndicator("Sub Competency")}
                  placeholder="Choose Sub Competency"
                  options={subCompetencies}
                  validate={[_SUBCOMP]}
                />
              </Row>
              <Row>
                <Field
                  name="domainId"
                  component={SelectInput}
                  label={validIndicator("Domain")}
                  placeholder="Choose Domain"
                  options={domains}
                  validate={[_DOM]}
                />
                <Field
                  name="subDomainId"
                  component={SelectInput}
                  label={validIndicator("Sub Domain")}
                  placeholder="Choose Sub Domain"
                  options={subDomains}
                  validate={[_SUBDOM]}
                />
              </Row>
            </div>
          </div>
          <hr style={{ backgroundColor: "#E3F2FF" }} />
          <div style={{ marginLeft: "40%" }}>
            <Button
              color="primary"
              type="submit"
              disabled={submitting}
              style={{ fontSize: 16 }}
              onClick={handleClick}
            >
              Submit
            </Button>
          </div>
        </form>
      </Paper>
      <Link
        to={{
          pathname: "/create-question-bank",
          state: {
            customer: customer,
            activeTab: activeTab,
            activePage: currentPage,
          },
        }}
        className="terv-back"
      >
        <i className="mdi mdi-arrow-left mr-1" /> Back to Question Banks{" "}
      </Link>
    </div>
  );
};

const mapStateToProps = (state) => ({
  formValues: getFormValues("questionBankForm")(state),
  Customers: state.StudentAndCustomerReducer.Customers,
});

const questionBankForm = reduxForm({
  form: "questionBankForm",
  asyncValidate,
  asyncBlurFields: ["name"],
  enableReinitialize: true,
})(QuestionBankForm);
export default connect(mapStateToProps, null)(questionBankForm);