import useSWR from "swr";
import { GET_CALL } from "../Apis";
import api from "../../../api/baseConfig";

export const useGetDashboardInfo = (url: string, shouldFetch: boolean) => {
  const USER_ID = sessionStorage.getItem("user_id");
  const { data, error, isValidating } = useSWR(
    shouldFetch ? `${api.baseURL}dashboard/${url}/${USER_ID}` : null,
    GET_CALL,
    { dedupingInterval: 600000, refreshInterval: 600000 }
  );

  return error ? `Error occured ${error}` : isValidating ? "Fetching..." : data;
};
