import { uid } from "react-uid";
import styles from "./LessonType.module.scss";
import { TervButtonFilled } from "components/TervButtons";

const LessonType = ({
  setCurrentLesson,
  currentLesson,
  goToNext,
  LessonIcons,
  
}) => {
  const handleChange = (value) =>
    setCurrentLesson({ ...currentLesson, type: value });

  return (
    <div style={{ display: "flex" }}>
      <div className={styles.main}>
        <div className={styles.list}>
          {LessonIcons.map((item, ind) => (
            <span
              key={uid(ind)}
              onClick={() => handleChange(item.label)}
              className={`${styles.item} ${
                currentLesson.type === item.label && styles.selected
              }`}
            >
              {item.icon({
                color: currentLesson.type === item.label ? "#FFF"  : "#63606C",
                size: 40,
              })}
              {item.label}
            </span>
          ))}
        </div>
        <TervButtonFilled
          text="Next"
          justifyCenter
          disabled={!currentLesson.type}
          onClick={goToNext}
        />
      </div>
    </div>
  );
};

export default LessonType;
