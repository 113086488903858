import MasterForm from "./MasterForm";

export default function CreateSubTaxonomy(props) {
  return (
    <MasterForm
      sub
      postLink="createTaxonomy"
      label={{
        breadcrumb: "Sub Taxonomy",
        back: "Sub Taxonomies",
      }}
      backLink="/taxonomy"
      location={{
        ...props.location,
      }}
      history={{
        ...props.history,
      }}
      match={{
        ...props.match,
      }}
      activePage={props?.location?.state?.activePage}
      itemsPerPage={props?.location?.state?.itemsPerPage}
    />
  );
}
