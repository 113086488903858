import moment from "moment";

const dateContainerStyle = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  startAndEndStyle = {
    textTransform: "uppercase",
    textAlign: "center",
    fontSize: 10,
    fontWeight: 600,
  },
  containerEffects = {
    backgroundColor: "#fff",
    borderRadius: 7,
    padding: 5,
    boxShadow: "0 2px 2px 0px rgba(0,0,0,0.2)",
    display: "flex",
    gap: 6,
  },
  dayStyle = {
    fontSize: 16,
    fontWeight: "bold",
    textAlign: "center",
    color: "#000000",
  },
  yearStyle = {
    fontWeight: "550px",
    textAlign: "center",
    color: "#000000",
  },
  dividerStyle = {
    height: 40,
    width: 50,
    backgroundImage:
      "linear-gradient(90deg, hsla(340, 80%, 69%, 1) 0%, hsla(246, 82%, 82%, 0.3) 0%)",
    alignSelf: "center",
    marginTop: 14,
  },
  columnItemsStyle = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  };

const noTimeCheck = (time) => (time && time !== "00:00" ? true : false);

export default function StyledDate({ SD, ED }) {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        marginBottom: 8,
      }}
    >
      <div style={dateContainerStyle}>
        <div style={startAndEndStyle}>start date</div>
        <div
          style={{
            ...containerEffects,
            borderTop: "4px solid #5DE0B4",
          }}
        >
          <div
            style={{
              ...columnItemsStyle,
              paddingRight: noTimeCheck(
                moment(SD?.replace("+00:00", "+05:30")).format("HH:mm")
              )
                ? 5
                : 0,
              borderRight: noTimeCheck(
                moment(SD?.replace("+00:00", "+05:30")).format("HH:mm")
              )
                ? "1px solid #F1CAF6"
                : "",
            }}
          >
            <div style={dayStyle}>
              {moment(SD?.replace("+00:00", "+05:30")).format("DD")}
            </div>
            <div style={yearStyle}>
              {moment(SD?.replace("+00:00", "+05:30")).format("MMM YY")}
            </div>
          </div>
          {noTimeCheck(
            moment(SD?.replace("+00:00", "+05:30")).format("HH:mm")
          ) && (
            <div
              style={{ ...columnItemsStyle, fontSize: 14, fontWeight: "bold" }}
            >
              {moment(SD?.replace("+00:00", "+05:30")).format("HH:mm")}
            </div>
          )}
        </div>
      </div>
      <div style={dividerStyle} />

      <div style={dateContainerStyle}>
        <div style={startAndEndStyle}>end date</div>
        <div style={{ ...containerEffects, borderTop: "4px solid #EA4B65" }}>
          <div
            style={{
              ...columnItemsStyle,
              paddingRight: noTimeCheck(
                moment(ED?.replace("+00:00", "+05:30")).format("HH:mm")
              )
                ? 5
                : 0,
              borderRight: noTimeCheck(
                moment(ED?.replace("+00:00", "+05:30")).format("HH:mm")
              )
                ? "1px solid #F1CAF6"
                : "",
            }}
          >
            <div style={dayStyle}>
              {moment(ED?.replace("+00:00", "+05:30")).format("DD")}
            </div>
            <div style={yearStyle}>
              {moment(ED?.replace("+00:00", "+05:30")).format("MMM YY")}
            </div>
          </div>
          {noTimeCheck(
            moment(ED?.replace("+00:00", "+05:30")).format("HH:mm")
          ) && (
            <div
              style={{
                ...columnItemsStyle,
                fontSize: 14,
                fontWeight: "bold",
              }}
            >
              {moment(ED?.replace("+00:00", "+05:30")).format("HH:mm")}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
