import { useState, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { FormLayout } from "components/FormLayout";
import { ErrorBlock } from "components/ErrorBlock";
import { RequiredIndicator } from "pages/utils/RequiredIndicator";
import RichTextEditor from "components/RichTextEditor";
import { TervButtonOutlined, TervButtonFilled } from "components/TervButtons";
import { UpdateOverviews } from "../Apis";
import { yupResolver } from "@hookform/resolvers/yup";
import { Form3Schema } from "./ValidationSchemas";
import { TypeTables } from "../components/TypeTables";
import { Button } from "@material-ui/core";
import { ModalToggle } from "pages/utils/ModalToggle";
import QuestionTypeModal from "../../Tasks/QuestionTypeModal";
import Modal from "@material-ui/core/Modal";
import { makeStyles } from "@material-ui/core/styles";
import { values } from "pages/Assessment/mocksubject";
import { Table } from "reactstrap";
import * as CustomIcons from "../../../assets/icons/icons";

const defaultValues = {
  opValue: {
    MC: {
      optionIdentifier: "",
      answer: false,
      id: 0,
    },
    CD: {
      inputText: "",
      outputText: "",
      id: 0,
    },
    DESC: {
      answerKey: "",
      answerKeyPoints: "0",
      id: 0,
    },
  },
  hintValue: {
    desc: "",
    id: 0,
  },
  solValue: {
    val: "",
    lang: {
      label: "",
      value: "",
    },
    id: 0,
  },
  defSolValue: {
    val: "",
    lang: {
      label: "",
      value: "",
    },
    id: 0,
  },
  modalToggle: (prevState) => !prevState,
};

const ModalLabels = {
  HINT: {
    header: "Add Hint",
    FF: "Hint",
  },
};
const returnTypeModalTextAreaValue = () => {
  let value = "";
  return value;
};
const handleTypeModalTextArea = (event) => {
  const value = event.target.value;
  if (hintModal) {
    setValue("HN", "desc", "", value);
  }
};

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 500,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const Overview = ({
  courseId,
  goNext,
  goPrevious,
  courseData,
  formValue2,
  setFormValue2,
  formValue1,
}) => {  
  const methods = useForm({
  mode: "onBlur",
  resolver: yupResolver(Form3Schema( courseData, formValue1?.type)),
});
  const {
    register,
    control,
    formState: { isSubmitting, errors },
    handleSubmit,
    reset,
    watch,
  } = methods;
  const [hintModal, setHintModal] = useState(false);
  const [options, setOptions] = useState([]);
  const [TypeModal, setTypeModal] = useState(false);
  const [hints, setHints] = useState([]);
  const [modalStyle] = useState(getModalStyle);
  const [open, setOpen] = useState(false);
  const [labelName, setLabelName] = useState("");
  const [value, setValue] = useState("");
  const [faqAnswer, setFaqAnswer] = useState("");

  const handleModalClose = () => {
    if (editState) {
      setEditState(false);
    }
    setHintModal(defaultValues.modalToggle);
    setHintValue(defaultValues.hintValue);
  };

  function rand() {
    return Math.round(Math.random() * 20) - 10;
  }

  function getModalStyle() {
    const top = 50 + rand();
    const left = 50 + rand();

    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
    };
  }

  useEffect(() => {
    if (courseData?.overview || formValue2?.overview) {
      const value = courseData?.overview ? courseData : formValue2;
      setTasks(value?.faq)
      reset({
        overview: value?.overview ?? "",
        details: value?.details ?? "",
        rules: value?.rules ?? "",
        submissionFormat: value?.submissionFormat ?? "",
        judgingCriteria: value?.judgingCriteria ?? "",
        challengeDescription: value?.challengeDescription ?? "",
        challengeFormat: value?.challengeFormat ?? "",
        faq: value?.faq ?? {},
      });
    }
  }, [reset, courseData]);
  const onSubmit = async (formValues) => { 
    let input = { ...formValues, id: courseId, faq: tasks };
      try {
      await UpdateOverviews(input);
      // setFormValue2(formValues);
      setFormValue2(input);
      goNext();
    } catch (error) {}
}


  const handlePrevious = () => {
    goPrevious();
  };

  const set = (name) => {
    return ({ target: { value } }) => {
      setLabelName((oldValues) => ({ ...oldValues, [name]: value }));
    };
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const classes = useStyles();

  const getFaqDetails = (e) => {
    e.preventDefault();
    value && addTask(value);
    setValue("");
  };
  const [tasks, setTasks] = useState([]);
  const addTask = (e) => {
    setTasks([...tasks, e]);
    setOpen(false);
  };
  const addTaskLabelName = (e) => {
    setValue({ ...value, [e.target.name]: e.target.value });
  };

  const toggleTask = (index) => {
    const newTasks = [...tasks];
    newTasks[index].isCompleted = !newTasks[index].isCompleted;
    setTasks(newTasks);
  };

  const removeTask = (index) => {
    const newTasks = [...tasks];
    newTasks.splice(index, 1);
    setTasks(newTasks);
  };
  return (
    <div>
      <form className="manage-prepare__form" onSubmit={handleSubmit(onSubmit)}>
        <FormLayout
          title="Hackathon overview"
          description="Enter Hackathon overview"
        >
          <div className="terv-row">
            <div>
              <label className="terv-label">
                {RequiredIndicator("Hackathon overview")}
              </label>
              <p>Overview of the Hackathon</p>
              <Controller
                name="overview"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <RichTextEditor onChange={onChange} value={value || ""} />
                )}
              />
            
              {errors.overview && (
              <ErrorBlock
                eType="div"
                errorText={errors.overview.message || ""}
                maxWidth="12.5rem"
              />
            )}
            </div>
          </div>
        </FormLayout>
        {formValue1?.type !== "Challenge" && (
          <FormLayout
            title="Hackathon Details and rules"
            description="Enter Hackathon details and Rules"
          >
            <div className="terv-row">
              <div>
                <label className="terv-label">
                  {RequiredIndicator("What to build in Hackathon")}
                </label>
                <p>What are the things participants must build in Hackathon</p>
                <Controller
                  name="details"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <RichTextEditor onChange={onChange} value={value || ""} />
                  )}
                />
                {errors.details && (
                  <ErrorBlock
                    eType="span"
                    errorText={errors.details.message || ""}
                  />
                )}
              </div>
            </div>
            <div className="terv-row">
              <div>
                <label className="terv-label">Hackathon rules</label>
                <p>What are the rules of Hackathon</p>
                <Controller
                  name="rules"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <RichTextEditor onChange={onChange} value={value || ""} />
                  )}
                />
              </div>
            </div>
          </FormLayout>
        )}
        {formValue1?.type == "Challenge" && (
          <FormLayout
            title="Challenge description and Format"
            description="Enter Challenge description and format"
          >
            <div className="terv-row">
              <div>
                <label className="terv-label">
                  {RequiredIndicator("Challenge description")}
                </label>
                <p>What are the things participants must build in hackthon</p>
                <Controller
                  name="challengeDescription"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <RichTextEditor onChange={onChange} value={value || ""} />
                  )}
                />
                {errors.challengeDescription && (
                  <ErrorBlock
                    eType="span"
                    errorText={errors.challengeDescription.message || ""}
                  />
                )}
              </div>
            </div>
            <div className="terv-row">
              <div>
                <label className="terv-label">Challenge format</label>
                <p>What are the format of this challenge</p>
                <Controller
                  name="challengeFormat"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <RichTextEditor onChange={onChange} value={value || ""} />
                  )}
                />
              </div>
            </div>
          </FormLayout>
        )}
        {formValue1?.type !== "Challenge" && (
          <FormLayout
            title="Submission format and 
        Judging criteria"
            description="Enter Hackathon submission format and criteria"
          >
            <div className="terv-row">
              <div>
                <label className="terv-label">
                  Hackathon submission format
                </label>
                <p>Participants submission format for the Hackathon</p>
                <Controller
                  name="submissionFormat"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <RichTextEditor onChange={onChange} value={value || ""} />
                  )}
                />
              </div>
            </div>
            <div className="terv-row">
              <div>
                <label className="terv-label">Hackathon judging criteria</label>
                <p>Project judging criteria for Hackathon</p>
                <Controller
                  name="judgingCriteria"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <RichTextEditor onChange={onChange} value={value || ""} />
                  )}
                />
              </div>
            </div>
          </FormLayout>
        )}
        <FormLayout>
          <div className="terv-row">
            <Button
              style={{
                textTransform: "capitalize",
                backgroundColor: "#51B960",
              }}
              onClick={handleOpen}
            >
              <svg
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.75 5.25V0H5.25V5.25H0V6.75H5.25V12H6.75V6.75H12V5.25H6.75Z"
                  fill="white"
                />
              </svg>
              <span
                className="pl-2"
                style={{ color: "#fff", fontWeight: "bold" }}
              >
                Add FAQs
              </span>
            </Button>
            <div>
              <Table striped bordered>
                <thead style={{ backgroundColor: "#DAF2FD" }}>
                  <tr>
                    {/* {props?.labelName > 0 &&
              props?.labelName?.map((header, ind) => ( */}

                    {/* ))} */}
                    <th style={{ width: 500 }}>FAQs Questions</th>
                    <th style={{ width: 500 }}>FAQs Answer</th>
                    <th style={{ width: 100 }}>Action</th>
                  </tr>
                </thead>
                <tbody className="typeTableBody">
                
                  {tasks.map((task, index) => (
                    <tr
                      style={{ backgroundColor: "#F9FDFF" }}
                      className="TableRow"
                      key={index}
                    >
                      <td>
                        <p>{task?.question}</p>
                      </td>
                      <td>
                        <p>{task?.answer}</p>
                      </td>
                      <td style={{ width: 100 }}>
                        <div style={{ display: "flex" }}>
                          &nbsp; &nbsp; &nbsp;
                          <span
                            onClick={() => removeTask(index)}
                            style={{ color: "red", cursor: "pointer" }}
                          >
                            {CustomIcons.circleCrossIcon({
                              size: 19,
                              color: "#EB5757",
                            })}
                          </span>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </div>
        </FormLayout>
        <FormLayout hideDivider>
          <div
            style={{ display: "flex", justifyContent: "flexStart", gap: "5px" }}
          >
            <TervButtonOutlined
              type="button"
              text="Previous"
              onClick={handlePrevious}
              width="6.125rem"
            />
            <TervButtonFilled
              type="submit"
              text="Continue"
              width="6.125rem"
              disabled={isSubmitting}
            />
          </div>
        </FormLayout>
      </form>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <form
          style={modalStyle}
          className={classes.paper}
          onSubmit={getFaqDetails}
        >
          <div>
            <p id="simple-modal-description">Add FAQ questions</p>
            <textarea
              name="question"
              type="text"
              value={value.question}
              onChange={(e) => addTaskLabelName(e)}
              style={{ width: "20rem", height: "108px" }}
            />
          </div>
          <div className="pt-2">
            <p id="simple-modal-description">Add FAQ Answer</p>
            <textarea
              name="answer"
              type="text"
              value={value.answer}
              onChange={(e) => addTaskLabelName(e)}
              style={{ width: "20rem", height: "108px" }}
            />
          </div>
          <Button
            type="submit"
            style={{ textTransform: "capitalize", backgroundColor: "#51B960" }}
          >
            <svg
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.75 5.25V0H5.25V5.25H0V6.75H5.25V12H6.75V6.75H12V5.25H6.75Z"
                fill="white"
              />
            </svg>
            <span
              className="pl-2"
              style={{ color: "#fff", fontWeight: "bold" }}
            >
              Add FAQs
            </span>
          </Button>
        </form>
      </Modal>
    </div>
  );
};

export default Overview;