import { useState, useEffect } from 'react';
import Lottie from 'react-lottie-player';
import recording from './assets/recording.json';
import recordingImg from './assets/recording.png';
import './index.css';

const VoiceToText = ({ props }) => {
  const [transcript, setTranscript] = useState(props.descriptiveAnswer || "");
  const [isListening, setIsListening] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!('webkitSpeechRecognition' in window)) {
      setError('Your browser does not support speech recognition.');
      return;
    }

    const recognition = new window.webkitSpeechRecognition();
    recognition.continuous = true;
    recognition.interimResults = true;
    recognition.lang = 'en-US'; // Set the language, can be customized

    recognition.onstart = () => {
      setIsListening(true);
    };

    recognition.onresult = (event) => {
      let finalTranscript = '';

      for (let i = event.resultIndex; i < event.results.length; i++) {
        const currentResult = event.results[i];
        if (currentResult.isFinal) {
          finalTranscript += currentResult[0].transcript + ' '; // Append final transcript
        }
      }

      // Update the transcript and set the answer in one go
      setTranscript((prev) => prev + finalTranscript);
      
      props.setDescriptiveAnswers((cs) => ({
        ...cs,
        [props.currentQuestion.id]: (cs[props.currentQuestion.id] || '') + finalTranscript // Append to the current answer
      }));
    };

    recognition.onerror = (event) => {
      setError(`Error occurred in recognition: ${event.error}`);
    };

    recognition.onend = () => {
      setIsListening(false);
    };

    if (isListening) {
      recognition.start();
    } else {
      recognition.stop();
    }

    return () => {
      recognition.stop();
    };
  }, [isListening]);

  const toggleListening = () => {
    if (!isListening) {
      setTranscript('');
      props.setDescriptiveAnswers((cs) => ({
        ...cs,
        [props.currentQuestion.id]: ''
      }));
    }
    setIsListening((prev) => !prev);
  };

  return (
    <div className="voice-to-text-container">
      <span style={{ margin: "-20px 0px -20px 0px" }}>
        {"Press the mic below to start & stop recording"}
      </span>
      <div className="button-container">
        <button onClick={toggleListening} style={{ backgroundColor: "transparent" }}>
          {isListening ? (
            <Lottie
              loop
              animationData={recording}
              play
              style={{ width: 150, height: 150 }}
            />
          ) : (
            <div className="mic-container">
              <img src={recordingImg} alt="mic" />
            </div>
          )}
        </button>
      </div>
      <p className="transcript-box">
        {transcript}
      </p>
      {error && <p className="error-message">{error}</p>}
    </div>
  );
};

export default VoiceToText;
