import React from "react";
import { IconButton } from "@material-ui/core";
import { ChevronLeft, ChevronRight } from "@material-ui/icons";
import { useState } from "react";
import styles from "./Carousel.module.scss";

interface Props {
  children: React.ReactNode[];
  itemsPerSlide: number;
}
export const Carousel: React.FC<Props> = ({
  children = [],
  itemsPerSlide = 1,
}) => {
  const [activeSlide, setActiveSlide] = useState(1);

  const lastIndex = activeSlide * itemsPerSlide;
  const firstIndex = lastIndex - itemsPerSlide;

  const handleSlideChange = (direction: string) => {
    const lastIndexTemp = (activeSlide + 1) * itemsPerSlide;
    const firstIndexTemp = lastIndexTemp - itemsPerSlide;

    direction === "left"
      ? activeSlide !== 1 && setActiveSlide((prevSlide) => prevSlide - 1)
      : children.slice(firstIndexTemp, lastIndexTemp).length > 0 &&
        setActiveSlide((prevSlide) => prevSlide + 1);
  };

  return (
    <div className={styles.carouselContainer}>
      <IconButton
        onClick={handleSlideChange.bind(this, "left")}
        classes={{ root: styles.iconButton }}
      >
        <ChevronLeft fontSize="inherit" />
      </IconButton>
      <div className={styles.carouselWrapper}>
        {children.slice(firstIndex, lastIndex).map((child, i) => (
          <React.Fragment key={`carouselWrapper${i}`}>{child}</React.Fragment>
        ))}
      </div>
      <IconButton
        onClick={handleSlideChange.bind(this, "right")}
        classes={{ root: styles.iconButton }}
      >
        <ChevronRight fontSize="inherit" />
      </IconButton>
    </div>
  );
};
