import React from "react";
import { ContentHeader } from "./ContentHeader";
import { HomeContent } from "./HomeContent";
import styles from "./Testimonials.module.scss";
import { Carousel } from "./Carousel";
import { useTranslation } from "react-i18next";
import sliderBanner from "../../../assets/Landing/Banner1-new.png";
import sliderBannerBg from "../../../assets/Landing/TestimonialBG.png";
import { Button } from "reactstrap";

type TestiObj = {
  description: string;
  image: string;
  author: string;
  designation: string;
};
//isTab = { isTab };
interface Props {
  isMobile: boolean;
  isTab: boolean;
  onCliclk: () => void;
}


const Testimonials: React.FC<Props> = ({ isMobile, isTab, onCliclk }) => {
  const { t } = useTranslation();

  
  const scrollToHalf = () => {
    const yOffset = window.innerHeight * 6;

    window.scrollTo({
      top: yOffset,
      behavior: 'smooth' 
    });
  };

  return (
    <div className={styles.main}>
      {/* <ContentHeader
        title={t(HomeContent.Headers.Career.Main)}
        description={t(HomeContent.Headers.Career.Description)}
        sub={t(HomeContent.Headers.Career.Sub)}
        titleRev
      /> */}
       <div className={styles.content__header__container}>
    <p className={styles.content__header__title_wrap}>
    
        <>
        <span className={styles.content__header__sub} >{t(HomeContent.Headers.Resources.Sub)}</span>{" "}
          <span className={styles.content__header__title} >{t(HomeContent.Headers.Resources.Main)}</span>{" "}
        </>
    </p>
    <p className={styles.content__header__description}>{t(HomeContent.Headers.Resources.Description)}</p>
  </div>
      <Carousel itemsPerSlide={isMobile ? 1 : isTab ? 2 : 3}>
        {HomeContent.Testimonials.map((testi: TestiObj, ind: number) => (
          <div key={`HomeTestimonial${ind}`} className={styles.card}>

            <div style={{display:"flex",paddingTop:"3%", paddingLeft:"5%", gap:"2%"}}>

            <div>
            <img src={testi.image} alt={testi.author} />
            </div>

            <div>
            <p className={styles.authorName}>{testi.author}</p>
            <p className={styles.authorDesig}><svg width="151" height="16" viewBox="0 0 151 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8.69775 0L10.4939 5.52786H16.3062L11.6039 8.94427L13.4 14.4721L8.69775 11.0557L3.99547 14.4721L5.79158 8.94427L1.0893 5.52786H6.90164L8.69775 0Z" fill="#EA7800"/>
<path d="M32.6978 0L34.4939 5.52786H40.3062L35.6039 8.94427L37.4 14.4721L32.6978 11.0557L27.9955 14.4721L29.7916 8.94427L25.0893 5.52786H30.9016L32.6978 0Z" fill="#EA7800"/>
<path d="M56.6978 0L58.4939 5.52786H64.3062L59.6039 8.94427L61.4 14.4721L56.6978 11.0557L51.9955 14.4721L53.7916 8.94427L49.0893 5.52786H54.9016L56.6978 0Z" fill="#EA7800"/>
<path d="M80.6978 0L82.4939 5.52786H88.3062L83.6039 8.94427L85.4 14.4721L80.6978 11.0557L75.9955 14.4721L77.7916 8.94427L73.0893 5.52786H78.9016L80.6978 0Z" fill="#EA7800"/>
<path d="M104.698 0L106.494 5.52786H112.306L107.604 8.94427L109.4 14.4721L104.698 11.0557L99.9955 14.4721L101.792 8.94427L97.0893 5.52786H102.902L104.698 0Z" fill="#EA7800"/>
</svg>
</p>
            </div>

            </div>
            <p className={styles.desc}>{testi.description}</p>

            <div style={{paddingLeft:"5%", paddingBottom:"2%"}}>
            <p className={styles.authorName}>New York, NY, USA</p>
            </div>
            {/* <div className={styles.authorInfo}>
              <img src={testi.image} alt={testi.author} />
              <p className={styles.authorName}>{testi.author}</p>
              <p className={styles.authorDesig}>{testi.designation}</p>
            </div> */}
          </div>
        ))}
      </Carousel>

      <div style={{paddingTop:"8%" , width:"100%",}}>
      <div style={{ paddingTop:"3%", paddingBottom:"2%", backgroundColor:"#6B41E0",  backgroundImage: `url(${sliderBannerBg})`, backgroundPosition: 'right', backgroundSize: 'cover', backgroundRepeat: 'no-repeat',  }}>
      
      <div style={isMobile ? {justifyContent:"center"} : {display:"flex", justifyContent:"space-evenly"}}>

       <div>
       <p className={styles.head}>Find your perfect course!</p>
       <p className={styles.para}>Answer a few questions & our program matching tool will do the rest</p>
       </div>

       <div style={{paddingTop:"2%"}}>
       <button
                // color="primary"
                // style={{
                //   width: "150px",
                //   height: "52px",
                //   color:"#323036",
                //   fontWeight: 600,
                //   backgroundColor:"#ffffff"
                // }}
                className={styles.btn}
                onClick={onCliclk}
              >
                Start Matching
              </button>
       </div>

  </div>

        </div>
        </div>
    </div>
  );
};

export default Testimonials;
