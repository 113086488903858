/** @format */

import * as BsIcons from "react-icons/bs";
import { Link as NavLink } from "react-router-dom";
import {
  Grid as MuiGrid,
  Link as MuiLink,
  useMediaQuery,
  withStyles,
} from "@material-ui/core";
import styles from "./Footer.module.scss";
import { useTranslation } from "react-i18next";
import { useLogoContext } from "context/logoContext";

const Grid = withStyles({
  root: {
    height: "100%",
  },
})(MuiGrid);

const Link = withStyles({
  root: {
    fontSize: 16,
    display: "block",
    color: "#606060 !important",
    "&:hover": {
      textDecoration: "underline !important",
    },
  },
})(MuiLink);

const Footer = () => {
  const isCompact = useMediaQuery("(max-width: 1023px)");
  const smallScreen = useMediaQuery("(max-width: 767px)");
  const { t } = useTranslation();
  const logoImage = useLogoContext();
  return (
    <footer
      className={styles.footer}
      style={{
        paddingTop: 20,
      }}
    >
      <Grid
        alignItems="center"
        direction={isCompact ? "column" : "row"}
        container
        justifyContent="space-between"
        style={{
          height: "auto",
          flexWrap: "nowrap",
          alignItems: "center",
          paddingBottom: 16,
        }}
      >
        {!isCompact && (
          <Grid
            alignItems={smallScreen ? "left" : "center"}
            direction={isCompact ? "column" : "row"}
            item
            style={{
              display: "flex",
            }}
          >
            <Link
              href="/termsAndConditions"
              style={{
                marginTop: isCompact ? 16 : 0,
              }}
            >
              {t("Terms and conditions")}
            </Link>

            {!isCompact && (
              <BsIcons.BsDot
                style={{
                  margin: "0 16px",
                  fontSize: 24,
                  color: "#96939F",
                }}
              />
            )}

            <Link
              href="/privacy"
              style={{
                marginTop: isCompact ? 16 : 0,
              }}
            >
              {t("Privacy")}
            </Link>

            {!isCompact && (
              <BsIcons.BsDot
                style={{
                  margin: "0 16px",
                  fontSize: 24,
                  color: "#96939F",
                }}
              />
            )}

            <Link
              href="/legal"
              style={{
                marginTop: isCompact ? 16 : 0,
              }}
            >
              {t("Legal")}
            </Link>
          </Grid>
        )}
        <Grid
          alignItems="center"
          item
          style={{
            display: "flex",
          }}
        >
          <div>
            <div style={{ color: "#606060" }}>
              {new Date().getFullYear()} © {logoImage?.productName}
            </div>
          </div>
        </Grid>
      </Grid>
      {/* {smallScreen ? (
        <Grid
          alignItems="center"
          direction={smallScreen ? "row" : "column"}
          container
          justifyContent="space-between"
          style={{
            height: "auto",
            minHeight: 64,
            flexWrap: "nowrap",
          }}
          className="pl-2"
        >
          <Grid
            alignItems={smallScreen ? "left" : "center"}
            direction={isCompact ? "column" : "row"}
            container
            style={{
              display: "flex",
            }}
          >
            <NavLink to="/termsAndConditions">
              <Link
                href="/termsAndConditions"
                style={{
                  marginTop: isCompact ? 16 : 0,
                }}
              >
                {t("Terms and conditions")}
              </Link>
            </NavLink>
            {!isCompact && (
              <BsIcons.BsDot
                style={{
                  margin: "0 16px",
                  fontSize: 24,
                  color: "#96939F",
                }}
              />
            )}
            <NavLink to="/privacy">
              <Link
                href="/privacy"
                style={{
                  marginTop: isCompact ? 16 : 0,
                }}
              >
                {t("Privacy")}
              </Link>
            </NavLink>
            {!isCompact && (
              <BsIcons.BsDot
                style={{
                  margin: "0 16px",
                  fontSize: 24,
                  color: "#96939F",
                }}
              />
            )}
            <NavLink to="/legal">
              <Link
                href="/legal"
                style={{
                  marginTop: isCompact ? 16 : 0,
                }}
              >
                {t("Legal")}
              </Link>
            </NavLink>
          </Grid>
          <Grid
            alignItems="center"
            item
            style={{
              display: "flex",
              marginTop: isCompact ? 32 : 0,
            }}
          >
            <div className="pr-3">
              <p style={{ color: "#606060" }}>
                {new Date().getFullYear()} © Chitti.ai
              </p>
            </div>
          </Grid>
        </Grid>
      ) : (
      )} */}
    </footer>
  );
};

export default Footer;
