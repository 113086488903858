import { useState, useEffect, useMemo, useCallback } from "react";
import { trackActivity } from "../../api";
import cn from "classnames";
import styles from "./assessment-list.module.scss";
import PageHeader from "../virtualLab/lab-components/page-header";
import { CapitalizeFirstLetter } from "pages/utils/CapitalizeFirstLetter";
import { Tabs } from "../../components/tabs/tabs";
import { MobileTabs } from "../../components/tabs/tabMobile";
import { TabContent } from "reactstrap";
import CardModal from "./cardModal";
import { useGetAssessments } from "./SWR/useGetAssessments";
import AssessmentHeroCard from "./assessmentHeroCard";
import equal from "fast-deep-equal";
import { useMediaQuery } from "@material-ui/core";
import { sessionPrivileges } from "privileges";

const AssessmentList = (props) => {
  const userPrivileges = sessionPrivileges();
  const smallScreen = useMediaQuery("(max-width: 767px)");
  const userId = sessionStorage.getItem("user_id");
  userId
    ? sessionStorage.setItem("viewPkgWithoutSignIn", false)
    : sessionStorage.setItem("viewPkgWithoutSignIn", true);
  const isWithoutSignIn = sessionStorage.getItem("viewPkgWithoutSignIn") === "true";

  const [activeTab, setActiveTab] = useState(
    isWithoutSignIn ? "all" : "active"
  );
  const [assessments, setAssessments] = useState(defaultAssessments);
  const [activePages, setActivePages] = useState(defaultPages);
  const [toFetch, setToFetch] = useState({
    all: isWithoutSignIn,
    active: !isWithoutSignIn,
    inactive: false,
    completed: false,
    upcoming: false,
  });
  const allAssessments = useGetAssessments(null, toFetch.all, userId);
  const activeAssessments = useGetAssessments("active", toFetch.active, userId);
  const upcomingAssessments = useGetAssessments(
    "upcoming",
    toFetch.upcoming,
    userId
  );
  const inactiveAssessments = useGetAssessments(
    "inactive",
    toFetch.inactive,
    userId
  );
  const completedAssessments = useGetAssessments(
    "completed",
    toFetch.completed,
    userId
  );
  const itemsPerPage = 6;

  useEffect(() => {
    const currentData =
      activeTab === "active"
        ? activeAssessments
        : activeTab === "inactive"
        ? inactiveAssessments
        : activeTab === "completed"
        ? completedAssessments
        : activeTab === "upcoming"
        ? upcomingAssessments
        : activeTab === "all"
        ? allAssessments
        : {};

    const currentString = `${activeTab.toUpperCase()}_LIST`;
    if (currentData?.[currentString] || activeTab === "all") {
      const currentDatum =
        activeTab === "all" ? allAssessments : currentData[currentString];
      if (
        Array.isArray(currentDatum)
        //  && !equal(currentDatum, assessments[`real${activeTab}`])
      ) {
        setAssessments({
          ...assessments,
          [activeTab]: currentDatum,
          [`real${activeTab}`]: currentDatum,
          counts: {
            ACTIVE_LIST_COUNT: currentData.ACTIVE_LIST_COUNT,
            INACTIVE_LIST_COUNT: currentData.INACTIVE_LIST_COUNT,
            COMPLETED_LIST_COUNT: currentData.COMPLETED_LIST_COUNT,
            UPCOMING_LIST_COUNT: currentData.UPCOMING_LIST_COUNT,
            ALL_LIST_COUNT:
              currentData.ACTIVE_LIST_COUNT +
              currentData.INACTIVE_LIST_COUNT +
              currentData.COMPLETED_LIST_COUNT +
              currentData.UPCOMING_LIST_COUNT,
          },
        });
      }
    }
  }, [
    activeAssessments,
    upcomingAssessments,
    inactiveAssessments,
    completedAssessments,
    allAssessments,
  ]);

  useEffect(() => {
    trackActivity("-", "All Assessments", "viewed-all-assessments");
  }, []);

  const handleSearch = (query) => {
    let arr = [];
    assessments[`real${activeTab}`].forEach((e) => {
      if (e.name.toLowerCase().includes(query.toLowerCase())) {
        arr.push(e);
      }
    });
    if (arr.length > 0) {
      setAssessments({
        ...assessments,
        [activeTab]: arr,
      });
      setActivePages({ ...activePages, [activeTab]: 1 });
    }
  };

  useCallback(handleSearch, [activeTab]);

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      if (!toFetch[tab]) {
        setToFetch((pre) => ({ ...pre, [tab]: true }));
      }
      setActiveTab(tab);
    }
  };

  const handlePageChange = (pageNo, from) => {
    
    setActivePages({ ...activePages, [from]: +pageNo });
  };

  const getTabsCounts = () => {
    let countList = [];
    for (const key in assessments.counts) {
      if (key !== "ALL_LIST_COUNT") {
        const currentKey = key.slice(0, key.indexOf("_")).toLowerCase();
        countList.push({
          name: CapitalizeFirstLetter(currentKey),
          badge: assessments.counts[key],
          id: currentKey,
        });
      }
    }
    return countList;
  };

  const allTabs = isWithoutSignIn
    ? [
        {
          name: "All Assessments",
          badge: assessments.counts.ALL_LIST_COUNT,
          id: "all",
        },
      ]
    : getTabsCounts();

  useMemo(() => allTabs, [assessments]);

  const lastIndices = {
    active: activePages.active * itemsPerPage,
    inactive: activePages.inactive * itemsPerPage,
    upcoming: activePages.upcoming * itemsPerPage,
    completed: activePages.completed * itemsPerPage,
    all: activePages.all * itemsPerPage,
  };
  const firstIndices = {
    active: lastIndices.active - itemsPerPage,
    inactive: lastIndices.inactive - itemsPerPage,
    upcoming: lastIndices.upcoming - itemsPerPage,
    completed: lastIndices.completed - itemsPerPage,
    all: lastIndices.all - itemsPerPage,
  };

  const [grid, setGrid] = useState(true);

  const gridView = () => {
    setGrid(true);
  };
  const listView = () => {
    setGrid(false);
  };

  return (
    <div
      className={cn(
        "page-content",
        smallScreen ? styles.pageMobile : styles.page
      )}
    >
      <AssessmentHeroCard />
      <PageHeader
        onSearch={handleSearch}
        searchPlaceholder="Search Assessments"
      />
      <span
        type="text"
        id="txtName"
        readonly="readonly"
        style={{ display: "none" }}
      />
      {smallScreen ? (
        <div>
          <MobileTabs
            onChange={toggleTab}
            activeTab={activeTab}
            tabs={allTabs}
            changeLayout={gridView}
            changeLayoutList={listView}
            viewListButton={[
              {
                gridButton: "grid",
                listButton: "list",
              },
            ]}
          >
            <TabContent
              activeTab={activeTab}
              style={{ padding: 10, backgroundColor: "#fff" }}
            >
              <CardModal
                itemsPerPage={6}
                activePage={activePages[activeTab]}
                tabId={activeTab}
                data={{
                  ...assessments,
                  userId,
                }}
                callback={(j) =>
                  (window.location.href = `/taketest?id=${j.id}&userId=${j.userId}`)
                }
                handlePageChange={handlePageChange}
                initialAssessmentId={props.location?.params?.assessmentId}
                firstIndices={firstIndices}
                lastIndices={lastIndices}
                gridViews={grid}
                listViews={grid}
                notSignedIn={isWithoutSignIn}
              />
            </TabContent>
          </MobileTabs>
        </div>
      ) : (
        <Tabs
          onChange={toggleTab}
          activeTab={activeTab}
          tabs={allTabs}
          changeLayout={gridView}
          changeLayoutList={listView}
          viewListButton={[
            {
              gridButton: "grid",
              listButton: "list",
            },
          ]}
        >
          <TabContent
            activeTab={activeTab}
            style={{ padding: 10, backgroundColor: "#fff" }}
          >
            <CardModal
              itemsPerPage={6}
              activePage={activePages[activeTab]}
              tabId={activeTab}
              data={{
                ...assessments,
                userId,
              }}
              callback={(j) =>
                (window.location.href = `/taketest?id=${j.id}&userId=${j.userId}`)
              }
              handlePageChange={handlePageChange}
              initialAssessmentId={props.location?.params?.assessmentId}
              firstIndices={firstIndices}
              lastIndices={lastIndices}
              gridViews={grid}
              listViews={grid}
              notSignedIn={isWithoutSignIn}
            />
          </TabContent>
        </Tabs>
      )}
    </div>
  );
};

export default AssessmentList;

const defaultAssessments = {
  upcoming: [],
  realupcoming: [],
  realall: [],
  all: [],
  realcompleted: [],
  completed: [],
  realinactive: [],
  inactive: [],
  realactive: [],
  active: [],
  counts: {
    ACTIVE_LIST_COUNT: 0,
    INACTIVE_LIST_COUNT: 0,
    COMPLETED_LIST_COUNT: 0,
    UPCOMING_LIST_COUNT: 0,
    ALL_LIST_COUNT: 0,
  },
};

const defaultPages = {
  upcoming: 1,
  all: 1,
  completed: 1,
  inactive: 1,
  active: 1,
};
