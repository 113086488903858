import { ResponsivePie, defaultProps } from "@nivo/pie"

export default function MyResponsivePie({ data,viewDetails,index }) {
    
  return (
    <ResponsivePie
      onClick={(node) => viewDetails(node.label,index)}
      data={data}
      width={500}
      height={360}
      margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
      innerRadius={0.5}
      padAngle={0.7}
      cornerRadius={3}
      colors={["#ff9f43","#784df5","#a191be","#70b678",]}
      borderWidth={2}
      borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
      radialLabel={d => `${d.id} (${d.formattedValue})`}
      layers={["slices", "sliceLabels", "radialLabels", "legends"]}
      radialLabelsSkipAngle={10}
      radialLabelsLinkColor={{
        from: "color",
      }}
      radialLabelsLinkStrokeWidth={3}
      radialLabelsTextColor={{
        from: "color",
        modifiers: [["darker", 1.2]],
      }}
      sliceLabelsSkipAngle={10}
      sliceLabelsTextColor="#333333"
      defs={[
        {
          id: "dots",
          type: "patternDots",
          background: "inherit",
          color: "rgba(255, 255, 255, 0.3)",
          size: 4,
          padding: 1,
          stagger: true,
        },
        {
          id: "lines",
          type: "patternLines",
          background: "inherit",
          color: "rgba(255, 255, 255, 0.3)",
          rotation: -45,
          lineWidth: 6,
          spacing: 10,
        },
      ]}
      fill={[
        {
          match: {
            id: "dummy",
          },
          id: "lines",
        },
        {
          match: {
            id: "dummy",
          },
          id: "dots",
        },
      ]}
    />
  )
}
