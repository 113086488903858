import React, { Component } from "react";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "react-datepicker/dist/react-datepicker.css";
import CreateAssessmentComponent from "./AssessmentForm";
import api from "../../api/baseConfig";
import Apihelper from "../../api/apiHelper";
import { toast } from "react-toastify";
import { sessionPrivileges } from "privileges";

export default class CreateAssessment extends Component {
  constructor(props) {
    super(props);
    this.userPrivileges = sessionPrivileges();
    this.UserEmail = this.userPrivileges.userName;
    this.state = {
      startDate: new Date(),
      endDate: new Date(),
      questions: [],
      media: "",
      queLable:"",
      studentData: {},
      assessmentQuestionPaperIdprops: "",
      canSubmit: false,
      customer: props?.location?.state?.customerId,
      customerName: props?.location?.state?.customerName,
    };
  }

  onSubmit = (val, assessmentUrl, assessmentUrlIsAvailable) => {
    if (
      val?.isMock ||
      ((val?.isPublicAssessment ? true : this.state.canSubmit) &&
        assessmentUrlIsAvailable)
    ) {
      let assessmentSchema = {
        active: true,
        assessmentQuestionPaperId: this.state.assessmentQuestionPaperIdprops,
        createdDate: Date.now(),
        id: {},
        media: this.state.media,
        protoringEnabled: true,
        showReview: true,
      };

      const {
        isMock,
        isPublicAssessment,
        userDetailsAddress,
        userDetailsEmail,
        userDetailsGender,
        userDetailsName,
        userDetailsPhone,
        ...remainingVal
      } = val;
      let sum = 0;
      if(val?.sectionTimeLimit){
        const timeLimitParse = Object.values(val?.sectionTimeLimit)
        var result = timeLimitParse?.map(function (x) { 
          return parseInt(x, 10); 
        });
        for (let i = 0; i < result.length; i++) {
          sum += result[i];
        }
      }
      let actualPayload = {
        ...assessmentSchema,
        ...remainingVal,
        ...this.state.studentData,
        mock: isMock,
        timeLimit: val?.timeType == "question" ? sum : val?.timeLimit,
        questionPaperName: this.state.queLable,
        customDetails: isMock
          ? {}
          : isPublicAssessment
          ? {
              userName: true,
              emailId: true,
              phoneNumber: true,
              address: !!userDetailsAddress,
              gender: true,
              assessmentUrl,
            }
          : {},
        assessmentType: isPublicAssessment ? "Public" : "Private",
      };

      if (
        actualPayload.filterType === "all" ||
        actualPayload.filterType === "except" ||
        actualPayload.filterType === "only"
      ) {
        delete actualPayload.batchName;
        delete actualPayload.department;
        delete actualPayload.regNoTo;
        delete actualPayload.regNoFrom;
      } else {
        actualPayload.filterType = actualPayload.batchName;
      }

      if (isPublicAssessment) {
        actualPayload.userIdList = [];
      }
      
      actualPayload.createdBy = this.UserEmail;
      actualPayload.updatedBy = this.UserEmail;

      actualPayload.imageInterval = actualPayload.imageEnabled
        ? actualPayload.imageInterval
        : null;
      actualPayload.passcodeType = actualPayload.passcodeEnabled
        ? actualPayload.passcodeType
        : null;
      actualPayload.allowedExitAttempts = actualPayload.noNavigation
        ? actualPayload.allowedExitAttempts
        : null;
      Apihelper.axiosCallPost(
        `${api.baseURL}${api.assessmentController.assessment}`,
        "post",
        actualPayload
      )
        .then(() => {
          toast.success("Sucessfully Created Assessment", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: false,
          });

          this.props.history.push({
            pathname: "/assessments",
            state: { customer: val?.collegeId || "" },
          });
        })
        .catch(function () {});
    } else {
    }
  };

  render() {
    return (
      <>
        <CreateAssessmentComponent
          onSubmit={this.onSubmit}
          initialValues={
            this.props?.location?.state?.customerId
              ? {
                  collegeId: this.props.location.state.customerId,
                  userDetailsAddress: false,
                }
              : {}
          }
          canSubmit={this.state.canSubmit}
          media={this.state.media}
          setMedia={(media) => this.setState({ media })}
          setQueLable={(queLable) => this.setState({ queLable : queLable })}
          queLable={this.state.queLable}
          setStudentData={(studentData) => this.setState({ studentData })}
          setCanSubmit={(canSubmit) => this.setState({ canSubmit })}
          customer={this.state.customer}
          customerName={this.state.customerName}
        />
      </>
    );
  }
}
