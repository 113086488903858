import { useState, useEffect } from "react";
import {
  Popover,
  Chip,
  Button,
  Checkbox,
  FormGroup,
  FormControlLabel,
} from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";
import ClearIcon from "@material-ui/icons/Clear";
import { makeStyles } from "@material-ui/core/styles";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import TuneIcon from "@material-ui/icons/Tune";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { CapitalizeFirstLetter } from "pages/utils/CapitalizeFirstLetter";
import styles from "./SelectQuestionsModal.module.scss";
import { TervButtonFilled } from "./../../../components/TervButtons";
import { uid } from "react-uid";

const allFilters = [
  { label: "Category", value: "categoryList" },
  {
    label: "Topic",
    value: "topicList",
  },
  {
    label: "Type",
    value: "typeList",
  },
  {
    label: "Complexity",
    value: "complexityList",
  },
];

const defaultFilterState = {
  topicsList: [],
  categoryList: [],
  typeList: [],
  complexityList: [],
};

let dumStr = [];

const useStyles = makeStyles((theme) => ({
  selectDialogContainer: {
    "& .MuiPaper-root": {
      "&::-webkit-scrollbar": {
        width: "5px",
      },
      "&::-webkit-scrollbar-thumb": {
        background: "#BDBBC3",
        borderRadius: "10px",
        margin: "0 10px",
      },
      "&::-webkit-scrollbar-track": {
        background: "#fff",
      },
    },
  },
}));

export const FilterMenu = ({
  questions = [],
  tabState,
  setTabState,
  activeTab,
  selFilters,
  setSelFilters,
  selFilterTopic,
  setSelFilterTopic,
  selFilterCategory,
  setSelFilterCategory,
  selFilterType,
  setSelFilterType,
  selFilterComplexity,
  setSelFilterComplexity,
  handleFilterNew,
  handleClearAll,
  setCurrBankSelQues,
  remove,
  setQuestionsWatch,
  multiSections,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [filters, setFilters] = useState(defaultFilterState);
  const [topicsList, setTopicsList] = useState();

  const classes = useStyles();

  const getAllTopicAttributes = () => {
    dumStr = [];
    Object.keys(selFilters?.topicList).forEach(function eachKey(key) {
      for (let i = 0; i < selFilterCategory.length; i++) {
        if (key === selFilterCategory[i]) {
          dumStr.push(...new Set([...dumStr, ...selFilters?.topicList[key]]));
        }
      }
    });
    let rm = [...new Set([...dumStr])];
    setTopicsList(rm);
  };

  useEffect(() => {
    if (selFilterCategory.length > 0) {
      getAllTopicAttributes();
    }
    if (selFilterCategory.length === 0) {
      setTopicsList([]);
      setSelFilterTopic([]);
    }
  }, [selFilterCategory]);

  const handleFilter = (selectedFilter, element) => {
    if (filters[selectedFilter]?.includes(element)) {
      setFilters({
        ...filters,
        [selectedFilter]: filters[selectedFilter].filter(
          (item) => item !== element
        ),
      });
    } else {
      setFilters({
        ...filters,
        [selectedFilter]: [...filters[selectedFilter], element],
      });
    }
  };

  const applyFilters = () => {
    if (activeTab === "availableQuestions") {
      handleFilterNew();
    } else {
      const toRemove = [];

      const temp = questions.reduce((acc, val) => {
        if (selFilterTopic.length > 0) {
          for (let i = 0; i < selFilterTopic.length; i++) {
            if (selFilterTopic[i].includes(val?.topicId)) {
              if (!acc.find((q) => q.id === val.id)) {
                acc.push(val);
              }
            } else if (!toRemove?.includes(val.id)) {
              toRemove.push(val.id);
            }
          }
        }
        if (selFilterCategory.length > 0) {
          for (let i = 0; i < selFilterCategory.length; i++) {
            if (selFilterCategory[i].includes(val?.categoryId)) {
              if (!acc.find((q) => q.id === val.id)) {
                acc.push(val);
              }
            } else if (!toRemove?.includes(val.id)) {
              toRemove.push(val.id);
            }
          }
        }
        if (selFilterType.length > 0) {
          for (let i = 0; i < selFilterType.length; i++) {
            if (selFilterType[i].includes(val?.type)) {
              if (!acc.find((q) => q.id === val.id)) {
                acc.push(val);
              }
            } else if (!toRemove?.includes(val.id)) {
              toRemove.push(val.id);
            }
          }
        }
        if (selFilterComplexity.length > 0) {
          for (let i = 0; i < selFilterComplexity.length; i++) {
            if (selFilterComplexity[i].includes(val?.complexity)) {
              if (!acc.find((q) => q.id === val.id)) {
                acc.push(val);
              }
            } else if (!toRemove?.includes(val.id)) {
              toRemove.push(val.id);
            }
          }
        }
        // if (
        //   filters.topic?.includes(val?.topic) ||
        //   filters.category?.includes(val?.category) ||
        //   filters.type?.includes(val?.type) ||
        //   filters.complexity?.includes(val?.complexity)
        // ) {
        //   if (!acc.find((q) => q.id === val.id)) {
        //     acc.push(val);
        //   }
        // } else if (!toRemove?.includes(val.id)) {
        //   toRemove.push(val.id);
        // }
        return acc;
      }, []);

      setTabState(temp);
      if (activeTab === "selectedQuestions") {
        setCurrBankSelQues(temp);
        remove(toRemove);
        if (multiSections) {
          setQuestionsWatch();
        }
      }
    }
  };

  return (
    <>
      <div
        className={styles.filterWrapper}
        onClick={(event) => setAnchorEl(event.currentTarget)}
      >
        <div className={styles.filterButton}>
          <span className={styles.filterIcon}>
            <TuneIcon />
          </span>
          <button className={styles.filterInnerButton}>Filter</button>
        </div>
      </div>
      <Popover
        id="simple-menu"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        style={{ marginRight: 40, height: "25rem" }}
        classes={{ root: classes.selectDialogContainer }}
      >
        <p
          className={styles.clearButton}
          onClick={() => {
            handleClearAll();
            // setTabState(questions);
            // setFilters(defaultFilterState);
          }}
          disabled={
            tabState.length === questions.length &&
            selFilterTopic.length === 0 &&
            selFilterCategory.length === 0 &&
            selFilterType.length === 0 &&
            selFilterComplexity.length === 0
          }
        >
          Clear all
        </p>
        <Accordion key={1} style={{ width: 220 }}>
          <AccordionSummary
            classes={{ root: styles.accItem, expanded: styles.accExpanded }}
            expandIcon={<ExpandMoreIcon />}
            style={{
              color: "#000",
              fontWeight: 600,
              fontSize: "16px",
              backgroundColor: "transparent",
            }}
          >
            Category
          </AccordionSummary>
          <AccordionDetails
            style={{
              padding: "10px 10px",
              display: "flex",
              // flexWrap: "wrap",
              flexDirection: "column",
              // gap: ,
            }}
          >
            {selFilters?.categoryList?.map((element, i) => (
              <FormGroup key={`categoryList${i}`}>
                {element.categoryName !== null && (
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      checked={
                        selFilterCategory.length > 0
                          ? selFilterCategory.includes(element.categoryId)
                          : false
                      }
                      onChange={(e) => {
                        setSelFilterCategory(
                          e.target.checked
                            ? [...selFilterCategory, element.categoryId]
                            : selFilterCategory.filter(
                                (pr) => pr !== element.categoryId
                              )
                        );
                      }}
                    />
                  }
                  label={element.categoryName}
                />
                )}
              </FormGroup>
            ))}
          </AccordionDetails>
        </Accordion>
        <Accordion key={2} style={{ width: 220 }}>
          <AccordionSummary
            classes={{ root: styles.accItem, expanded: styles.accExpanded }}
            expandIcon={<ExpandMoreIcon />}
            style={{
              color: "#000",
              fontWeight: 600,
              fontSize: "16px",
              backgroundColor: "transparent",
            }}
          >
            Topic
          </AccordionSummary>
          <AccordionDetails
            style={{
              padding: "10px 10px",
              display: "flex",
              // flexWrap: "wrap",
              flexDirection: "column",
              // gap: ,
            }}
          >
            {selFilters?.topicsList?.map((element, index) => (
              <FormGroup key={`topicsList${index}`}>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      checked={
                        selFilterTopic.length > 0
                          ? selFilterTopic.includes(element.id)
                          : false
                      }
                      onChange={(e) => {
                        setSelFilterTopic(
                          e.target.checked
                            ? [...selFilterTopic, element.id]
                            : selFilterTopic.filter((pr) => pr !== element.id)
                        );
                      }}
                    />
                  }
                  label={element.name}
                />
              </FormGroup>
            ))}
          </AccordionDetails>
        </Accordion>
        <Accordion key={3} style={{ width: 220 }}>
          <AccordionSummary
            classes={{ root: styles.accItem, expanded: styles.accExpanded }}
            expandIcon={<ExpandMoreIcon />}
            style={{
              color: "#000",
              fontWeight: 600,
              fontSize: "16px",
              backgroundColor: "transparent",
            }}
          >
            Type
          </AccordionSummary>
          <AccordionDetails
            style={{
              padding: "10px 10px",
              display: "flex",
              // flexWrap: "wrap",
              flexDirection: "column",
              // gap: ,
            }}
          >
            {selFilters?.typeList?.map((element, i) => (
              <FormGroup key={`typeList${i}`}>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      checked={
                        selFilterType.length > 0
                          ? selFilterType.includes(element)
                          : false
                      }
                      onChange={(e) => {
                        setSelFilterType(
                          e.target.checked
                            ? [...selFilterType, element]
                            : selFilterType.filter((pr) => pr !== element)
                        );
                      }}
                    />
                  }
                  label={
                    element === "MC"
                      ? "Multi Choice"
                      : element === "CD"
                      ? "Coding"
                      : element === "DESC"
                      ? "Descriptive"
                      : element
                  }
                />
              </FormGroup>
            ))}
          </AccordionDetails>
        </Accordion>
        <Accordion key={4} style={{ width: 220 }}>
          <AccordionSummary
            classes={{ root: styles.accItem, expanded: styles.accExpanded }}
            expandIcon={<ExpandMoreIcon />}
            style={{
              color: "#000",
              fontWeight: 600,
              fontSize: "16px",
              backgroundColor: "transparent",
            }}
          >
            Complexity
          </AccordionSummary>
          <AccordionDetails
            style={{
              padding: "10px 10px",
              display: "flex",
              // flexWrap: "wrap",
              flexDirection: "column",
              // gap: ,
            }}
          >
            {selFilters?.complexityList?.map((element, i) => (
              <FormGroup key={`typeList${i}`}>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      checked={
                        selFilterComplexity.length > 0
                          ? selFilterComplexity.includes(element)
                          : false
                      }
                      onChange={(e) => {
                        setSelFilterComplexity(
                          e.target.checked
                            ? [...selFilterComplexity, element]
                            : selFilterComplexity.filter((pr) => pr !== element)
                        );
                      }}
                    />
                  }
                  label={CapitalizeFirstLetter(element)}
                />
              </FormGroup>
            ))}
          </AccordionDetails>
        </Accordion>
        <div style={{ textAlign: "right", padding: 10, marginLeft: "4rem" }}>
          {/* <Button
            color="primary"
            variant="outlined"
            style={{ textTransform: "none", fontFamily: "Poppins" }}
            onClick={() => {
              setTabState(questions);
              setFilters(defaultFilterState);
            }}
            disabled={tabState.length === questions.length}
          >
            Reset
          </Button>
          &nbsp;&nbsp;
          <Button
            color="primary"
            variant="outlined"
            style={{ textTransform: "none", fontFamily: "Poppins" }}
            onClick={applyFilters}
            disabled={
              filters.topic?.length === 0 &&
              filters.category?.length === 0 &&
              filters.type?.length === 0 &&
              filters.complexity?.length === 0
            }
          >
            Apply
          </Button> */}
          <TervButtonFilled
            text={"Save"}
            onClick={applyFilters}
            disabled={
              selFilterTopic?.length === 0 &&
              selFilterCategory?.length === 0 &&
              selFilterType?.length === 0 &&
              selFilterComplexity?.length === 0
            }
          />
        </div>
      </Popover>
    </>
  );
};
