import useSWR from "swr";
import { GetCollege } from "./GetCollege";
import api from "../api/baseConfig";

export const useGetGraduate = (type?: string | undefined) => {
  const { data, error } = useSWR(
    `${api.baseURL}${api.assessmentController.getGraduate}`,
    GetCollege,
    { dedupingInterval: 600000, refreshInterval: 600000 }
  );

  return error
    ? `Error occured ${error}`
    : !data
    ? "Fetching..."
    : type === "raw"
    ? data
    : data
};