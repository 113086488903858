import React from "react"
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap"
import "./DelConfirmModal.scss";

const DeleteConfirmation = props => {
  const { isOpen, toggle, data } = props
  return (
    <Modal isOpen={isOpen} className="modal-dialog" >
      <ModalHeader>{data.title}</ModalHeader>
      <ModalBody>{data.body}</ModalBody>
      <ModalFooter>
        {data.button === "No" ? (
          <Button color="primary" onClick={() => toggle(true)}>
            Yes
          </Button>
        ) : (
          ""
        )}{" "}
        <Button color="secondary" onClick={() => toggle(false)}>
          {data.button}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default DeleteConfirmation
