import { useState } from "react";
import CreateIcon from "@material-ui/icons/Create";
import DeleteIcon from '@material-ui/icons/Delete';
import { IconButton } from "@material-ui/core";
import ImageCropper from "components/ImageCropper";
import ImageMobCropper from "components/ImageMobCropper";
import styles from "./AddImageComponent.module.scss";
import { DummyUpload } from "./DummyUpload";

export const AddImageComponent = ({ 
  media, 
  handleCroppedImage, 
  moduleName,
  customerName,
  viewOnly,
  currentId}) => {
  const [imageCropperState, setImageCropperState] = useState(false);

  const toggleImageCropper = () => setImageCropperState(!imageCropperState);
  return (
    <>
      <ImageCropper
        isOpen={imageCropperState}
        close={toggleImageCropper}
        handleCroppedImage={handleCroppedImage}
        moduleName={moduleName}
        customerName={customerName} 
        currentId={currentId}
      />
      <div className={styles.addImageContainer}>
        {media ? (
          <div className={styles.mediaContainer}>
            <img src={media} />
            <div>
              <IconButton onClick={toggleImageCropper} disabled={viewOnly}>
                <CreateIcon />
              </IconButton>
              <IconButton onClick={() => handleCroppedImage("")} disabled={viewOnly}>
                <DeleteIcon />
              </IconButton>
            </div>
          </div>
        ) : (
          <>
            <div className={styles.boxCenter} onClick={toggleImageCropper} disabled={viewOnly}>
              <p className={styles.paraOne}>Click to upload an image</p>
              <p className={styles.paraTwo}>Max size - 10MB</p>
              {!viewOnly && (
              <DummyUpload />
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export const AddMobImageComponent = ({ 
  media, 
  handleCroppedImage, 
  moduleName,
  customerName,
  viewOnly,
  currentId}) => {
  const [imageCropperState, setImageCropperState] = useState(false);

  const toggleImageCropper = () => setImageCropperState(!imageCropperState);
  return (
    <>
      <ImageMobCropper
        isOpen={imageCropperState}
        close={toggleImageCropper}
        handleCroppedImage={handleCroppedImage}
        moduleName={moduleName}
        customerName={customerName} 
        currentId={currentId}
      />
      <div className={styles.addImageContainer}>
        {media ? (
          <div className={styles.mediaContainer}>
            <img src={media} />
            <div>
              <IconButton onClick={toggleImageCropper} disabled={viewOnly}>
                <CreateIcon />
              </IconButton>
              <IconButton onClick={() => handleCroppedImage("")} disabled={viewOnly}>
                <DeleteIcon />
              </IconButton>
            </div>
          </div>
        ) : (
          <>
            <div className={styles.boxCenter} onClick={toggleImageCropper} disabled={viewOnly}>
              <p className={styles.paraOne}>Click to upload an image</p>
              <p className={styles.paraTwo}>Max size - 10MB</p>

              <DummyUpload />
            </div>
          </>
        )}
      </div>
    </>
  );
};

