import { useState, useEffect } from "react";
import Apihelper from "../../../api/apiHelper";
import api from "../../../api/baseConfig";
import { connect, useSelector } from "react-redux";
import { GetAllUsers } from "pages/CustomerManagement/utils/GetUsers";
import HandleHostSelectionAlt from "./HandleHostSelectionAlt";
import { sessionPrivileges } from "privileges";
import { parseRawHostData } from "../../utils/ParseRawHostData";
const customStyles = {
  menu: (styles) => ({ ...styles, zIndex: 9999 }),
};

const returnParsedData = (filterType, selectedStudents, allStudents) => {
  let studentIds = [];
  selectedStudents = selectedStudents || [];
  if (filterType === "all") {
    studentIds =
      allStudents.length > 0 ? allStudents.map((stu) => stu.value) : [];
  } else if (filterType === "except") {
    studentIds =
      selectedStudents.length > 0
        ? allStudents.reduce((acc, val) => {
            if (!selectedStudents.find((stu) => stu.value === val.value)) {
              acc.push(val.value);
            }
            return acc;
          }, [])
        : [];
  } else {
    studentIds =
      selectedStudents.length > 0
        ? selectedStudents.map((stu) => stu.value || stu)
        : [];
  }

  return studentIds;
};

const defaultFilterTypes = [
  { label: "All", value: "all" },
  { label: "Only", value: "only" },
  { label: "Except", value: "except" },
  {
    label: "Custom Batch",
    value: "custom_batch",
  },
];

const AdvancedStudentSelection = ({
  customerId = "",
  hookFormControls,
  viewOnly,
  studentFieldName = "",
  labelName = "",
}) => {
  const userPrivileges = sessionPrivileges();
  const isSA = userPrivileges.isSuperAdmin;
  const customerID = sessionStorage.getItem("customer_id");
  const { watch, setValue } = hookFormControls;
  const [customerStudents, setCustomerStudents] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [filteredStudents, setFilteredStudents] = useState([]);
  const [allFilterTypes, setAllFilterTypes] = useState(defaultFilterTypes);

  const adminStudentsFromRedux = useSelector((state) =>
    parseRawHostData(state.StudentAndCustomerReducer?.UserStudents?.students)
  );

  const getRightStudents = () =>
    isSA ? customerStudents : adminStudentsFromRedux;

  const regNoLength = () =>
    getRightStudents()[0] && getRightStudents()[0]["registrationNo"]
      ? getRightStudents()[0]["registrationNo"].length
      : "";

  useEffect(() => {
    if (isSA && customerId) {
      getCustomerStudents(customerId);
    }
  }, [customerId]);

  const getCustomerStudents = async (id) => {
    const allUsers = await GetAllUsers(id);
    setCustomerStudents(parseRawHostData(allUsers));
  };

  const resetFilter = () => {
    if (watch("regNoFrom") || watch("regNoTo")) {
      setValue("regNoFrom", "");
      setValue("regNoTo", "");
      setFilteredStudents([]);
    }
  };

  return (
    <HandleHostSelectionAlt
      students={
        filteredStudents.length > 0 ? filteredStudents : getRightStudents()
      }
      hookFormControls={hookFormControls}
      returnParsedData={returnParsedData}
      style={customStyles}
      resetFilter={resetFilter}
      viewOnly={viewOnly}
      studentFieldName={studentFieldName}
      labelName={labelName}
    />
  );
};

export default connect(null, null)(AdvancedStudentSelection);
