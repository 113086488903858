import { Button } from 'reactstrap';
import {
  Grid,
  LinearProgress as MuiLinearProgress,
  Typography,
  withStyles,
} from '@material-ui/core';
import { Icon } from '@iconify/react';
import bxSearch from '@iconify/icons-bx/bx-search';
import styles from './page-header.module.scss';

const LinearProgress = withStyles({
  root: {
    backgroundColor: '#DFDFDF',
    borderRadius: 10,
    flexGrow: 1,
    height: 7,
  },
  bar: {
    backgroundColor: '#F56F18',
    borderRadius: 10,
  },
})(MuiLinearProgress);

function PageHeader({
  onSearch,
  searchPlaceholder,
  title,
}) {
  return (
    <div className={styles.pageHeader}>
      <div className={styles.content}>
        <h4 className={styles.title}>{title}</h4>
        {onSearch && (
          <div className={styles.searchBar}>
            <Icon icon={bxSearch} style={{ color: '#a6a6a6', margin: '0 16px', fontSize: 20 }} />
            <input
              className={styles.searchBarInput}
              onChange={(e) => {
                onSearch(e.target.value);
              }}
              placeholder={searchPlaceholder}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default PageHeader;
