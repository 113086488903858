import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button as Button1,
} from "@material-ui/core";
import { CloseOutlined } from "@material-ui/icons";
import { Button } from "reactstrap";
import { useHistory } from "react-router-dom";
import styles from "../../CustomerManagement/BulkUserUpload.module.scss";
import * as CustomIcons from "../../../assets/icons/icons";
import StyledDate from "./StyledDate";
import { sessionPrivileges } from "privileges";

const AssessmentModal = (props) => {
  const {
    isOpen,
    toggle,
    summary,
    customerId,
    activePage,
    activeTab,
    data,
    customerName,
  } = props;
  const userPrivileges = sessionPrivileges();
  const history = useHistory();
  const eyeIcon = (
    <svg
      width="18"
      height="14"
      viewBox="0 0 18 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.91625 7.20117C6.91625 7.78133 7.14672 8.33773 7.55695 8.74797C7.96719 9.1582 8.52359 9.38867 9.10375 9.38867C9.68391 9.38867 10.2403 9.1582 10.6505 8.74797C11.0608 8.33773 11.2913 7.78133 11.2913 7.20117C11.2913 6.62101 11.0608 6.06461 10.6505 5.65438C10.2403 5.24414 9.68391 5.01367 9.10375 5.01367C8.52359 5.01367 7.96719 5.24414 7.55695 5.65438C7.14672 6.06461 6.91625 6.62101 6.91625 7.20117ZM17.5842 6.69727C15.7327 2.79688 12.9338 0.833984 9.18188 0.833984C5.42797 0.833984 2.63109 2.79687 0.779532 6.69922C0.705265 6.85648 0.666748 7.02823 0.666748 7.20215C0.666748 7.37606 0.705265 7.54782 0.779532 7.70508C2.63109 11.6055 5.42992 13.5684 9.18188 13.5684C12.9358 13.5684 15.7327 11.6055 17.5842 7.70312C17.7346 7.38672 17.7346 7.01953 17.5842 6.69727ZM9.10375 10.6387C7.20531 10.6387 5.66625 9.09961 5.66625 7.20117C5.66625 5.30273 7.20531 3.76367 9.10375 3.76367C11.0022 3.76367 12.5413 5.30273 12.5413 7.20117C12.5413 9.09961 11.0022 10.6387 9.10375 10.6387Z"
        fill="#794DF5"
      />
    </svg>
  );

  const moveToAssessment = () => {
    window.location.href = `/taketest?id=${
      summary.id
    }&userId=&isAdmin=${true}&customerId=${customerId}&activePage=${
      activePage.allList
    }&activeTab=${activeTab}`;
  };

  return (
    <Dialog open={isOpen} onClose={toggle}>
      <DialogTitle classes={{ root: styles.header }}>
        Assessment Details
        <CloseOutlined
          classes={{ root: styles.closeButton }}
          onClick={toggle}
        />
      </DialogTitle>
      <DialogContent>
        <div style={{ marginTop: 10 }}>
          <span
            style={{
              backgroundColor: "#fdbc1f",
              padding: 8,
              borderRadius: 5,
            }}
          >
            <CustomIcons.pencilSquare size={24} />
          </span>
          <span style={{ marginLeft: 14, fontSize: 16, fontWeight: 600 }}>
            {summary.name}
          </span>
        </div>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            gap: 35,
            marginTop: 20,
            marginBottom: 20,
          }}
        >
          <div style={{ width: 200 }}>
            <span
              style={{
                fontWeight: 600,
              }}
            >
              Total No of Students:{" "}
            </span>
            {summary.TotalNoOfStudents}
          </div>
          <div style={{ width: 150 }}>
            <span
              style={{
                fontWeight: 600,
              }}
            >
              {" "}
              Total Skipped:{" "}
            </span>
            {summary.TotalSkipped}
          </div>
          <div style={{ width: 200 }}>
            <span
              style={{
                fontWeight: 600,
              }}
            >
              {" "}
              Total Completed:{" "}
            </span>
            {summary.TotalCompleted}
          </div>
          <div style={{ width: 150 }}>
            <span
              style={{
                fontWeight: 600,
              }}
            >
              Other Count:{" "}
            </span>
            {summary.Othercount}
          </div>
          {summary?.proctoringServer && (
            <div style={{ width: 150 }}>
              <span
                style={{
                  fontWeight: 600,
                }}
              >
                Proctoring server:{" "}
              </span>
              {summary.proctoringServer}
            </div>
          )}
          {summary?.proctoringRoom && (
            <div style={{ width: 150 }}>
              <span
                style={{
                  fontWeight: 600,
                }}
              >
                Proctoring room:{" "}
              </span>
              {summary.proctoringRoom}
            </div>
          )}
          {summary.passcode && (
            <div style={{ width: 150 }}>
              <span
                style={{
                  fontWeight: 600,
                }}
              >
                Passcode:{" "}
              </span>
              {summary.passcode}
            </div>
          )}
        </div>
        <StyledDate SD={summary.SD} ED={summary.ED} />
      </DialogContent>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          gap: 15,
          marginBottom: 10,
        }}
      >
        {userPrivileges.canEditAssessments && (
          <Button
            color="primary"
            onClick={() => {
              history.push({
                pathname: `/edit-assessment/${summary.allData.id}`,
                state: {
                  fromEdittrue: true,
                  row: summary.allData,
                  customer: customerId,
                  activePage: activePage.allList,
                  activeTab: activeTab,
                  customerName: customerName,
                },
              });
            }}
            style={{
              fontSize: 18,
              paddingLeft: 20,
              paddingRight: 20,
              width: 150,
              height: 43,
              fontFamily: "Poppins",
            }}
          >
            Edit
          </Button>
        )}
        <Button1
          variant="outlined"
          style={{
            border: "1px solid #794DF5",
            color: "#794DF5",
            textTransform: "none",
            width: "150px",
            height: "43px",
            display: "flex",
            pointerEvents:
              summary?.allData?.assessmentExpired === true ? "none" : "",
            opacity: summary?.allData?.assessmentExpired === true ? "0.4" : "",
          }}
          onClick={moveToAssessment}
        >
          <span
            style={{
              marginBottom: "5px",
            }}
          >
            {eyeIcon}
          </span>
          <span
            style={{
              marginLeft: "5px",
              marginTop: "15px",
              fontSize: "18px",
              fontFamily: "Poppins",
            }}
          >
            <p>Preview</p>
          </span>
        </Button1>
      </div>
    </Dialog>
  );
};

export default AssessmentModal;
