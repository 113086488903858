import { Controller, useForm } from "react-hook-form";
import { CourseSettings } from "../../Prepare/Content";
import { uid } from "react-uid";
import styles from "../../Prepare/TabForm4.module.scss";
import { CustomSwitch } from "../../Prepare/CustomSwitch";
import { UpdateSettings } from "../Apis";
import { useEffect } from "react";
import equal from "fast-deep-equal";
import { TervButtonOutlined,TervButtonFilled } from "components/TervButtons";

const GroupBlock = ({ key, control }) => (
  <div key={key} className={styles.groupBlock}>
    <p className={styles.groupBlockHeader}>{`HACKATHON SETTINGS`}</p>
    {/* {fields.map((field, i) => ( */}
      <div className={styles.groupFlex} key={1}>
        <div className={styles.groupFlexOne}>
          <p className={styles.groupFieldHeader}>{`Hackathon share`}</p>
          <p className={styles.groupFieldDesc}>{`Users can share hackathon link to other members`}</p>
        </div>
        <div className={styles.switchContainer}>
          {" "}
          No{" "}
          <Controller
            name={`share`}
            control={control}
            render={({ field: { onChange, value } }) => (
              <CustomSwitch
                checked={!!value}
                onChange={(e, c) => onChange(c)}
              />
            )}
          />{" "}
          Yes{" "}
        </div>
      </div>
    {/* ))} */}
  </div>
);

const TabForm3 = ({
  goNext,
  courseId,
  courseData,
  goPrevious,
  formValue3,
  setFormValue3,
}) => {
  const {
    control,
    formState: { isSubmitting },
    handleSubmit,
    reset,
  } = useForm();

  useEffect(() => {
    if (courseData?.name || formValue3?.name) {
      const value = courseData?.share ? courseData : formValue3;
      reset({
        share: value?.share ?? "",
      });
    }
  }, [reset, courseData]);
  const onSubmit = async (formValues) => {
    try {
      await UpdateSettings({ ...formValues, id: courseId });
      setFormValue3(formValues);
      goNext();
    } catch (error) {}
  };

  const handlePrevious = () => {
    goPrevious();
  };
  
  return (
    <form onSubmit={handleSubmit(onSubmit)} className="manage-prepare__form">
      <div className={styles.container}>
        <div>
          {/* {CourseSettings.map((set, ind) => ( */}
            <GroupBlock key={1}  control={control} />
            {/* <GroupBlock key={uid(ind)} set={set} control={control} /> */}
          {/* ))} */}
        </div>
      </div>

      <div style={{ display: "flex", justifyContent: "center", gap: "5px" }}>
        <TervButtonOutlined
          type="button"
          text="Previous"
          onClick={handlePrevious}
          width="6.125rem"
        />
        <TervButtonFilled
          text="Continue"
          type="submit"
          width="6.125rem"
          disabled={isSubmitting}
        />
      </div>
    </form>
  );
};

export default TabForm3;
