import {
  CrownTilted,
  TriRight,
  backWdIcon,
  fwdIcon,
  commingSoon
} from "./../../assets/icons/icons";

export const paginationClasses = {
  prevPageText: "Back",
  firstPageText: backWdIcon,
  lastPageText: fwdIcon,
  nextPageText: "Next",
  activeLinkClass: "activePage",
  itemClass: "defaultPageContainer",
  linkClass: "defaultPageLink",
  linkClassFirst: "pageLinkFirst",
  linkClassLast: "pageLinkLast",
  linkClassPrev: "pageLinkPrev",
  linkClassNext: "pageLinkNext",
  itemClassFirst: "pageFirst",
  itemClassLast: "pageLast",
  itemClassNext: "pageNext",
  itemClassPrev: "pagePrev",
};

export const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const phoneNumberRegex =
  /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;

export const USER_TYPES = [
  { label: "Admin", value: "admin" },
  { label: "Learner", value: "learner" },
];

export const HomePageFooterHeaders = [
  "resources",
  "plans",
  "subjects",
  "freeResources",
  "prepareResources",
];

export const BasicToastAttributes = {
  position: "top-right",
  autoClose: 3000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: false,
};

export const HomePageHeaderItems = [
  // { label: "Prepare", icon: "", menu: true },
  { label: "Courses", icon: "", menu: true },
  { label: "Bootcamp", icon: "", menu: false },
  { label: "Techruit", icon: "", menu: false },
  // { label: "Cloud Lab", icon: TriRight, menu: false },
  // { label: "Wow Lab", icon: CrownTilted, menu: false },
];

export const Dash = ({ size: { width, height }, color }) => (
  <svg height={height} width={width} style={{ backgroundColor: color }} />
);
