import React from "react";
import ReactQuill, { Quill } from "react-quill";
import ImageResize from "quill-image-resize-module-react";
import "react-quill/dist/quill.snow.css";
import "./RichTextEditor.css";
Quill.register("modules/imageResize", ImageResize);

const modules = {
  imageResize: {
    parchment: Quill.import("parchment"),
    modules: ["Resize", "DisplaySize", "Toolbar"],
  },
  clipboard: {
    matchVisual: false,
  },
  toolbar: [
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{ font: [] }],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    [{ align: [] }],
    [{ background: [] }],
    [{ color: [] }],
    ["link", "image"],
    [{ script: "sub" }, { script: "super" }],
    ["code-block"],
  ],
};

const practiceModules = {
  imageResize: {
    parchment: Quill.import("parchment"),
    modules: ["Resize", "DisplaySize", "Toolbar"],
  },
  clipboard: {
    matchVisual: false,
  },
  toolbar: [
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{ font: [] }],
    [
      { indent: "-1" },
      { indent: "+1" },
    ],
    [{ align: [] }],
    [{ background: [] }],
    [{ color: [] }],
    ["link", "image"],
    [{ script: "sub" }, { script: "super" }],
    ["code-block"],
  ],
};

const formats = [
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "code-block",
  "align",
  "color",
  "background",
  "font",
  "script",
  "sub",
  "super",
  "clean",
];

const RichTextEditor = ({ className, onChange, value, disablePoints, validation, error}) => (
  <div className="editorWraper">
    <ReactQuill
      className={className}
      onChange={onChange}
      value={value}
      validation={validation}
      modules={disablePoints === true ? practiceModules : modules}
      formats={formats}
      bounds={".app"}
    />
    {error && <span style={{ color: "red" }}>{error}</span>}
  </div>
);

export default RichTextEditor;
