import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
} from "@material-ui/core";
import { Button } from "reactstrap";
import { InputField } from "../QuestionWizard/InputField";
import { CloseOutlined } from "@material-ui/icons";

export default function ResetPasswordModel(props) {
  return(
  <>
  <Dialog open={props.isOpen} onClose={props.onClose}>
      <DialogTitle>
        Reset Password
        <CloseOutlined
          style={{position: "absolute", right: 10, cursor: "pointer"}}
          onClick={() => {
            props.onClose();
          }}
        />
      </DialogTitle>
      <DialogContent dividers>
        <div style={{width: 450,}}>
          <InputField
            id="pwd"
            label="New password"
            type="password"
            value={props.newPassword}
            changeFunc={(value) => props.updateState(value, "newPassword")}
            validation={(err) => props.updateErrors(err, "newPassword")}
            validationMessage="Please enter a valid New password"
            error={props.errors.newPassword}
          />
          <br></br>
          <InputField
            id="cnpwd"
            label="Confirm password"
            type="password"
            value={props.confirmPassword}
            changeFunc={(value) => props.updateState(value, "confirmPassword")}
            validation={(err) => props.updateErrors(err, "confirmPassword")}
            validationMessage="Password don't match"
            error={props.errors.newPassword}
            pwdValue={props.newPassword}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button color="primary"
          disabled={!(props.newPassword &&
            props.newPassword === props.confirmPassword
            )}
          onClick={props.update}
        >
          Update
        </Button>
        <Button outline  color="primary" onClick={props.onClose}>
          Cancel
        </Button>
      </DialogActions>
  </Dialog>
  </>

  );
}