import { useEffect, useState } from "react";
import { useUserDetails } from "hooks/use-user-details";
import styles from "./jitsi.module.scss";

export function JitsiProctoring({ domain, roomName }) {
  const [jitsiStarted, setIfJitsiStarted] = useState(false);
  const userDetails = useUserDetails();

  useEffect(() => {
    if ((userDetails?.name) && (userDetails?.email) && !jitsiStarted) {
      const options = {
        configOverwrite: {
          useNewBandwidthAllocationStrategy: true,
          disable1On1Mode: true,
          disableAudioLevels: true,
          disableIncomingMessageSound: true,
          disableJoinLeaveSounds: true,
          disableProfile: true,
          disableShortcuts: true,
          disableShowMoreStats: true,
          disableSpeakerStatsSearch: true,
          disableTileView: true,
          enableNoAudioDetection: false,
          enableNoAudioDetection: false,
          enableNoisyMicDetection: false,
          hideConferenceSubject: true,
          hideConferenceTimer: true,
          hideLobbyButton: true,
          hideParticipantsStats: true,
          hideRecordingLabel: false,
          hideRecordingLabel: true,
          localRecording: {
            enabled: false,
          },
          liveStreamingEnabled: false,
          notifications: [],
          prejoinPageEnabled: false,
          requireDisplayName: false,
          startSilent: true,
          startWithAudioMuted: true,
          startWithVideoMuted: false,
          toolbarButtons: [],
        },
        height: 700,
        parentNode: document.querySelector("#jitsi-proctoring-meeting"),
        roomName,
        userInfo: {
          email: userDetails.email,
          displayName: userDetails.name,
        },
        width: 700,
      };

      const jitsiProctoringMeet = new window.JitsiMeetExternalAPI(
        domain,
        options
      );

      const jitsiIframe = jitsiProctoringMeet?.getIFrame();
      jitsiIframe.setAttribute('allow', 'microphone, camera');

      setIfJitsiStarted(true);
    }
  }, [userDetails, jitsiStarted]);

  return (
    <div
      className={styles.jitsiProctoringMeeting}
      id="jitsi-proctoring-meeting"
    />
  );
}