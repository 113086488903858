import React, { useState, useEffect, useContext } from "react";
import { ContentHeader } from "./ContentHeader";
import { HomeContent } from "./HomeContent";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { uid } from "react-uid";
import { withStyles, Button } from "@material-ui/core";
import styles from "./Courses.module.scss";
import {
  TimeBX,
  StarFilled,
  CourseChapters,
  ChartIcon,
} from "assets/icons/icons";
import { useHistory } from "react-router-dom";
import { PriceContainer } from "./PriceContainer";
import api from "api/baseConfig";
import apiHelper from "api/apiHelper";
import { GetItemWithMedia } from "pages/utils/GetItemWithMedia";
import { Carousel } from "./Carousel";
import { useTranslation } from "react-i18next";
import data from "@iconify/icons-subway/tick";

const CustomButton = withStyles({
  root: {
    backgroundColor: "#794df5",
    textTransform: "none",
    fontFamily: "Poppins",
    color: "#fff",
    width: "90%",
    "&:hover": {
      backgroundColor: "#794df5",
    },
  },
})(Button);

const CustomTabs = withStyles({
  indicator: {
    backgroundColor: "#794df5",
    height: 4,
  },
})(Tabs);

const CustomTab = withStyles({
  root: {
    fontFamily: "Poppins",
    fontWeight: 600,
    fontSize: "1.125rem",
  },
  disabled: {
    color: "#323036",
  },
  selected: {
    color: "#323036",
  },
})(Tab);

interface CourseObj {
  media: string;
  courseId: number;
  isMobile: boolean;
  name: string;
  duration: string;
  // chapters: string;
  courseRating: number;
  numberOfModules: number;
  level: string;
  originalPrice: string;
  strikingPrice: string;
  tag: string;
  handleOpenSignin: () => void;
}

interface CourseCardProps extends CourseObj {
  key: string | number;
}

interface PackageCardProps {
  key: string;
  media: string;
  name: string;
  originalPrice: string;
  strikingPrice: string;
  pkgId: string;
  description: string;
  isMobile: boolean;
}

const GetStars = (howMany: number) => {
  let stars = new Array(5);
  for (let i = 0; i < stars.length; i++) {
    if (i < howMany)
      stars[i] = <StarFilled key={`GetStars${i}`} size="16" color="#EEB60C" />;
    else
      stars[i] = <StarFilled key={`GetStars${i}`} size="16" color="#D8D6DB" />;
  }

  return <div>{stars}</div>;
};

const CourseCard: React.FC<CourseCardProps> = ({
  courseId,
  media,
  name,
  duration,
  // chapters,
  numberOfModules,
  courseRating,
  level,
  originalPrice,
  strikingPrice,
  tag,
  isMobile,
}) => {
  const history = useHistory();
  // const handlePageRedirect = () => {
  //   if(data)
  // }

  return (
    <div className={isMobile ? styles.mobileCourseCard : styles.courseCard}>
      <img
        src={
          media.includes("default")
            ? `https://d2ndap9rlps54r.cloudfront.net/DefaultUploadImages/${media}.jpeg`
            : media
        }
        alt={name}
      />
      <div className={styles.courseContent}>
        <p>{name}</p>
        <div className={styles.courseDetailsGrid}>
          <div>
            <TimeBX size="16" color="#7C7887" />
            {duration} 
            {duration <= '1' ? " hour" : " hours"}
            
          </div>
          <div>
          <CourseChapters size={{ height: 12, width: 16 }} color="#7C7887" />
          {numberOfModules}
          {numberOfModules <= 1 ? " chapter" : " chapters"}
        </div>
          <div>
            {GetStars(+courseRating || 0)}
            {/* {courseRating} */}
          </div>
          <div>
            <ChartIcon size="16" color="#7C7887" />
            {level}
          </div>
        </div>
        <hr />
        <div className={styles.priceContent}>
          <PriceContainer
            originalPrice={originalPrice}
            strikingPrice={strikingPrice}
          />
          </div>
          <div className={styles.previewButton}>
            <CustomButton
              onClick={() => {
                sessionStorage.setItem("viewPkgWithoutSignIn", "true");
                history.push(`/course-overview/${courseId}`);
              }}
            >
              Preview
            </CustomButton>
          </div>
        </div>
      </div>
  );
};

const PackageCard: React.FC<PackageCardProps> = ({
  key,
  media,
  name,
  originalPrice,
  strikingPrice,
  description,
  pkgId,
  isMobile,
}) => {
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <div
      className={isMobile ? styles.mobileCourseCard : styles.courseCardPrac}
      key={key}
    >
      <img src={media} alt={name} />
      <div className={styles.courseContent}>
        <p>{name}</p>
        </div>
        <div>
          <div
            className={styles.description}
         dangerouslySetInnerHTML={{
         __html: description,
         }}
         />
         
        </div>
        <hr />
        <div className={styles.priceContentPrac}>
          <PriceContainer
            originalPrice={originalPrice}
            strikingPrice={strikingPrice}
          />
        </div>
          <div className={styles.previewButtonPrac}>
            <CustomButton
              onClick={() => {
                sessionStorage.setItem("viewPkgWithoutSignIn", "true");
                history.push(`/view-practice-questions/${pkgId}`);
              }}
            >
              {t("Buy")}
            </CustomButton>
          </div>
        </div>
 
  );
};

interface Props {
  isMobile: boolean;
  isTab: boolean;
}

const Courses: React.FC<Props> = ({ isMobile, isTab }) => {
  const [tab, setTab] = useState<number>(0);
  const { t } = useTranslation();
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTab(newValue);
  };

  const [practicePackage, setPracticePackage] = useState<
    { [key: string]: any }[]
  >([]);
  const [topPackages, setTopPackages] = useState<{ [key: string]: any }[]>([]);

  const getpracticePackage = async () => {
    try {
      const data = await apiHelper.axiosCall(
        `${api.baseURL}${api.lab.getTopprepare}`,
        "get"
      );
      if (data) {
        setPracticePackage(data);
        //   data.reduce(
        //     (acc: { [key: string]: any }[], value: { [key: string]: any }) => {
        //       acc.push(GetItemWithMedia(value));
        //       return acc;
        //     },
        //     []
        //   )
        // );
      }
    } catch (e) {}
  };
  const getTopPackages = async () => {
    try {
      const data = await apiHelper.axiosCall(
        `${api.baseURL}${api.homePage.getTopPackages}`,
        "get"
      );
      if (data) {
        setTopPackages(
          data.reduce(
            (acc: { [key: string]: any }[], value: { [key: string]: any }) => {
              acc.push(GetItemWithMedia(value));
              return acc;
            },
            []
          )
        );
      }
    } catch (e) {}
  };

  useEffect(() => {
    getTopPackages();
  }, []);
  useEffect(() => {
    getpracticePackage();
  }, []);

  const handleOpenSignin = () => {};

  return (
    <>
      <ContentHeader
        title={t(HomeContent.Headers.Courses.Main)}
        sub={t(HomeContent.Headers.Courses.Sub)}
        description={t(HomeContent.Headers.Courses.Description)}
        titleRev
      />

      <div style={{ display: "flex", justifyContent: "center" }}>
        <CustomTabs
          value={tab}
          onChange={handleChange}
          aria-label="courses tabs"
        >
          {HomeContent.CoursesTabs.map((tab: string, ind: number) => (
            <CustomTab label={tab} key={uid(ind)} />
          ))}
        </CustomTabs>
      </div>
      {tab === 0 ? (
        <Carousel itemsPerSlide={isMobile ? 1 : isTab ? 2 : 3}>
          {practicePackage.map((course: any, ind: number) => (
            <CourseCard
              courseId={course.id}
              {...course}
              key={uid(ind)}
              // crsid={course.id}
              handleOpenSignin={handleOpenSignin}
              isMobile={isMobile}
            />
          ))}
        </Carousel>
      ) : (
        <Carousel itemsPerSlide={isMobile ? 1 : isTab ? 2 : 3}>
          {topPackages.map((pkg, ind) => (
            <PackageCard
              description={pkg.description}
              name={pkg.name}
              key={uid(ind)}
              media={pkg.media}
              originalPrice={pkg.originalPrice}
              strikingPrice={pkg.strikingPrice}
              pkgId={pkg.id}
              isMobile={isMobile}
            />
          ))}
        </Carousel>
      )}
      {/* {tab === 0 ? (
        <Carousel itemsPerSlide={3}>
          {HomeContent.CoursesMock.map((course: CourseObj, ind: number) => (
            <CourseCard
              {...course}
              key={uid(ind)}
              handleOpenSignin={handleOpenSignin}
            />
          ))}
        </Carousel>
      ) : tab === 1 ? (
        <Carousel itemsPerSlide={2}>
          {topPackages.map((pkg, ind) => (
            <PackageCard
              name={pkg.name}
              key={uid(ind)}
              media={pkg.media}
              originalPrice={pkg.originalPrice}
              strikingPrice={pkg.strikingPrice}
              handleOpenSignin={handleOpenSignin}
            />
          ))}
        </Carousel>
      ) : (
        ""
      )} */}
    </>
  );
};

export default Courses;
