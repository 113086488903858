import React, { useState, useEffect } from "react";
import TourComponent from "reactour";
import { BXCycle } from "assets/icons/icons";
import styles from "./Tour.module.scss";
import { TervButtonFilled } from "components/TervButtons";
import {
  Contents,
  LabCDContents,
  LabMCQContents,
  LpContents,
} from "./TourContent";

interface Props {
  learnPathVisible: boolean;
  mcqQuestion: boolean;
  open: boolean;
  setOpen: (a: boolean) => void;
  handleTourState: () => void;
}

const Tour: React.FC<Props> = ({
  learnPathVisible,
  mcqQuestion,
  open,
  setOpen,
  handleTourState,
}) => {
  const [currentStep, setCurrentStep] = useState<number>(0);
  useEffect(() => {
    if (!open && window.location.search == '?externalPack') {
      handleTourState();
    }
  }, [open]);

  const steps = (
    window.location.pathname === "/practice-lab"
      ? mcqQuestion
        ? LabMCQContents
        : LabCDContents
      : learnPathVisible
      ? LpContents
      : Contents
  ).map(({ className, title, description, icon }) => ({
    selector: className,
    content: (
      <div className={styles.stepContainer}>
        {icon && <BXCycle size="40" color="#1BA94C" />}
        <h6 className={styles.introTitle}>{title}</h6>
        <p className={styles.introDesc}>{description}</p>
        <div className={styles.actionItems}>
          {currentStep !== 0 && (
            <button
              className={styles.skipButton}
              onClick={() => setCurrentStep((prev) => prev - 1)}
            >
              Previous
            </button>
          )}
          {currentStep !==
          (window.location.pathname === "/practice-lab"
            ? LabCDContents.length - 1
            : learnPathVisible
            ? LpContents.length - 1
            : Contents.length - 1) ? (
            <TervButtonFilled
              text="Next"
              onClick={() => setCurrentStep((prev) => prev + 1)}
            />
          ) : (
            <TervButtonFilled text="Done" onClick={() => setOpen(false)} />
          )}
        </div>
      </div>
    ),
  }));

  return (
    <TourComponent
      steps={steps}
      isOpen={open}
      onRequestClose={() => {
        setOpen(false);
      }}
      goToStep={currentStep}
      getCurrentStep={(step) =>
        currentStep !== step ? setCurrentStep(step) : undefined
      }
      rounded={5}
      scrollDuration={3}
      showNumber={false}
      showButtons={false}
    />
  );
};

export default Tour;
