import { useMemo, useState } from 'react';
import { useSolutionInfo } from '../../hooks/use-solution-info';
import TabbedSection from '../../components/tabbed-section/tabbed-section';
import Confirmation from '../../components/confirmation/confirmation';
import styles from './question.module.scss';

export default function SolutionSection({
  activity,
  available,
  canView,
  canViewHint,
  details,
  hintsAvailable,
  id,
  logActivity,
  questionId,
  setActivity,
  setVisibility,
  solutionViewReductionPercentage = 75,
  visible,
  ...props
}) {
  const { data, loading } = useSolutionInfo(id, 'solution', visible);
  const solution = useMemo(() => (data?.[0]?.solution), [data]);
  const [confirmationAnchor, setConfirmationAnchor] = useState(null);
  const [infoAnchor, setInfoAnchor] = useState(null);

  return (
    <>
      <TabbedSection
        available={available}
        canView={canView}
        disabledText="Solution is disabled"
        loading={loading}
        noTabs
        questionId={questionId}
        sectionId="practice-lab-solution"
        setVisibility={(isVisible, e) => {
          if ((hintsAvailable && canViewHint) ? !activity?.hint : false) {
            setInfoAnchor(e?.currentTarget);
          } else if (activity?.solution) {
            setVisibility(isVisible);
          } else {
            setConfirmationAnchor(e?.currentTarget);
          }
        }}
        tabClassName={styles.solution}
        tabIndexLabel="Solution"
        tabs={[solution]}
        title="Solution"
        unAvailableText="Solution was not provided"
        visible={visible}
        {...props}
      />
      <Confirmation
        anchor={infoAnchor}
        description="In order to view the solution, you have to see the hints first."
        setAnchor={setInfoAnchor}
        title="Can't show solution"
      />
      <Confirmation
        anchor={confirmationAnchor}
        confirmation
        description={`Viewing the solution will reduce your score by ${solutionViewReductionPercentage}%. Are you sure you want to view solution?`}
        onContinue={() => {
          setVisibility((v) => {
            if (!v) {
              logActivity('solution');
            }

            return !v;
          });
          setActivity((a) => ({
            ...a,
            [id]: {
              ...(a?.[id] || {}),
              solution: true,
            },
          }));
        }}
        setAnchor={setConfirmationAnchor}
        title="Show solution?"
      />
    </>
  );
}