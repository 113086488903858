import { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import ButtonBase from "@material-ui/core/ButtonBase";
import sampleImage from "../../assets/images/Certificate.png";
import apiHelper from "../../api/apiHelper";
import api from "api/baseConfig";
import { ToastContainer, toast } from 'react-toastify';
import {  useMediaQuery } from "@material-ui/core";

toast.configure()


const useStyles = makeStyles((theme) => ({
  root: {
    // display: "flex",
    flexWrap: "wrap",
    // minWidth: 300,
    width: "100%",
    textAlign: "center",
  },
  image: {
    position: "relative",
    height: 200,
    [theme.breakpoints.down("xs")]: {
      width: "100% !important", // Overrides inline-style
      height: 100,
    },
    "&:hover, &$focusVisible": {
      zIndex: 1,
      "& $imageBackdrop": {
        // opacity: 0.10,
      },
      "& $imageMarked": {
        opacity: 0,
      },
      "& $imageTitle": {
        border: "4px solid currentColor",
      },
    },
  },
  focusVisible: {},
  imageButton: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.common.white,
  },
  imageSrc: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: "cover",
    backgroundPosition: "center 40%",
  },
  imageBackdrop: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: theme.palette.common.black,
    opacity: 0.6,
    transition: theme.transitions.create("opacity"),
  },
  imageTitle: {
    position: "relative",
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px ${
      theme.spacing(1) + 6
    }px`,
  },
  imageMarked: {
    height: 3,
    width: 18,
    backgroundColor: theme.palette.common.white,
    position: "absolute",
    bottom: -2,
    left: "calc(50% - 9px)",
    transition: theme.transitions.create("opacity"),
  },
  button: {
    background: "#794DF5",
  },
}));

export default function PracticeCertificate(data) {
  const classes = useStyles();
  const userId = sessionStorage.getItem("user_id");
  const [certificateFile, setCertificateFile] = useState(data?.data?.certification)
  const [errorOc, setErrorOc] = useState(false)
  const smallScreen = useMediaQuery("(max-width: 420px)");
  const getCertificateStudents = () => {
            try {
              const url = `${api.baseURL}${api.lab.downloadCertificate}${data.data.id}/${userId}`;
              apiHelper.axiosCallPost(url, "get").then((data) => {
                if (data) {
              setCertificateFile(data.resourceURL);
                }
              });
            } catch (e) {}
          };
        
// const getToastMessage = () => {
//     toast.warn("You must complete the package to unlock this certificate", {
//       position: "bottom-right",
//       autoClose: 3000,
//       hideProgressBar: true,
//       closeOnClick: true,
//       pauseOnHover: true,
//       draggable: true,
//       progress: false,
//     });
//   };

useEffect(() => {
 if(data.data.progress == 100) {
     data.data.certification ? "" :
      getCertificateStudents()
 }
},[])
useEffect(() => {
  setCertificateFile(certificateFile);
 },[certificateFile])

  return (
    <div className={classes.root}>
      <ButtonBase
        focusRipple
        key={"image"}
        className={classes.image}
        focusVisibleClassName={classes.focusVisible}
        style={smallScreen ? ({
          width: "25rem",
          height: "15rem",
          // left: "20px"
        }) : ({
          width: "30rem",
          height: "20rem",
        })}
      >
        <span
          className={classes.imageSrc}
          style={{
            backgroundImage: `url(${sampleImage})`,
          }}
        />
        <span className={classes.imageBackdrop} />
        <span className={classes.imageButton}>
        {data.data.progress == 100 && 
        <a
            href={certificateFile}
            className={classes.button}
            onClick={certificateFile}
            download
            style={{color:"#ffffff", padding:"7px 24px", borderRadius:"3px" }}
          >
           <span>Download Certificate</span>
          </a>
        }
        {data.data.progress !== 100 && 
        <div
            style={{color:"#ffffff", padding:"7px 24px", borderRadius:"3px",background: "#96939F" }}
          >
            <svg width="16" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M8 0C5.243 0 3 2.243 3 5V7H2C0.897 7 0 7.897 0 9V18C0 19.103 0.897 20 2 20H14C15.103 20 16 19.103 16 18V9C16 7.897 15.103 7 14 7H13V5C13 2.243 10.757 0 8 0ZM5 5C5 3.346 6.346 2 8 2C9.654 2 11 3.346 11 5V7H5V5ZM14.002 18H9V15.722C9.595 15.375 10 14.737 10 14C10 12.897 9.103 12 8 12C6.897 12 6 12.897 6 14C6 14.736 6.405 15.375 7 15.722V18H2V9H14L14.002 18Z" fill="white"/>
            </svg>
           <span className="pt-2 pl-2" style={{fontSize: "1rem"}}>Certificate locked</span>
          </div>
        }
        </span>
      </ButtonBase>
      {/* <ToastContainer
                position="bottom-right"
                autoClose={3000}
              /> */}
    </div>
  );
}
