import React, { useEffect, useState } from "react";
import api from "../../../api/baseConfig";
import ApiHelper from "../../../api/apiHelper";
import "react-toastify/dist/ReactToastify.css";
import CustomTable from "../../CustomerManagement/CustomListTable";
import { useParams } from "react-router-dom";
import { sessionPrivileges } from "privileges";
import { toast } from "react-toastify";

export default function AllCompetencies(props) {
  const userPrivileges = sessionPrivileges();
  const Priv = sessionPrivileges().userprivileges || [];
  const [data, setData] = useState([]);
  const [subData, setSubData] = useState([]);
  let params = useParams();

  const activePage = props?.location?.state?.activePage;
  const itemsPerPage = props?.location?.state?.itemsPerPage;

  useEffect(() => {
    getAllCompetency();
    window.scrollTo(0, 0);
  }, []);

  const getAllCompetency = async () => {
    await ApiHelper.axiosCall(
      `${api.baseURL}${api.masterController.getAllCompetency}`,
      "get"
    )
      .then((data) => {
        let tempData = [];
        data.forEach((datum) => {
          tempData.push({
            name: datum.name,
            description: datum.description,
            subCompetencyCount: datum.subCount,
            id: datum.id,
          });
          if (datum.subCount === 0) {
            toast.warning("Please add Sub Competency for " + datum.name);
          }
        });
        setData(tempData);
      })
      .catch((e) => {});
  };

  const deleteCompetency = async (id) => {
    await ApiHelper.axiosCall(
      `${api.baseURL}${api.masterController.deleteCompetency}${id}`,
      "post"
    )
      .then((d) => {
        getAllCompetency();
      })
      .catch((e) => {});
  };

  const getSubCompetency = async (id) => {
    await ApiHelper.axiosCall(
      `${api.baseURL}${api.masterController.getSubCompetency}${id}`,
      "get"
    )
      .then((data) => {
        let tempData = [];
        data.forEach((datum) => {
          tempData.push({
            name: datum.name,
            description: datum.description,
            id: datum.id,
          });
        });
        setSubData(tempData);
      })
      .catch((e) => {});
  };

  return (
    <>
    {userPrivileges.isSuperAdmin && (
    <div className="page-content">
      <CustomTable
        header="Competencies"
        createLink="/create-competency"
        data={data}
        dataLength={data.length}
        tableHeaders={[
          { label: "Competency", val: "name" },
          { label: "Description", val: "description" },
          { label: "Sub Competency Count", val: "subCompetencyCount" },
          { label: "Sub Competency", val: "subCompetency", own: true },
        ]}
        ownLink="/sub/competency/"
        searchPlaceholder="Search Competency"
        deleteDatum={(id) => deleteCompetency(id)}
        passEditFromRow
        editData={["name", "description", "id"]}
        accord
        showAdd={Priv.includes("ADD_COMPETENCY")}
        hideEdit={!Priv.includes("EDIT_COMPETENCY")}
        hideDelete={!Priv.includes("DELETE_COMPETENCY")}
        showBulk
        getAllSubs={(id) => {
          getSubCompetency(id);
        }}
        subTitle={"Sub Competency"}
        subData={subData}
        createSubLink="/create-sub-competency/"
        tableSubHeaders={[
          { label: "Sub Competency", val: "name" },
          { label: "Description", val: "description" },
        ]}
        passSubEditFromRow
        editSubData={["name", "description", "id"]}
        hoverable
        hideSubAdd={Priv.includes("ADD_SUB_COMPETENCY")}
        hideSubEdit={Priv.includes("EDIT_SUB_COMPETENCY")}
        hideSubDelete={Priv.includes("DELETE_SUB_COMPETENCY")}
        activePage={activePage}
        itemsPerPage={itemsPerPage}
      />
    </div>
    )}
    </>
  );
}
