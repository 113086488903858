import useSWR from "swr";
import { GetCourse } from "../Apis";
import api from "api/baseConfig";

export const useGetHackathon = (courseId: string) => {
  const { data, error } = useSWR(
    courseId
      ? `${api.baseURL}userHackathon/getUserSubmissionDetails/${courseId}`
      : null,
    GetCourse,
    { refreshInterval: 36 * 100 * 1000 }
  );

  return error ? `Error occured ${error}` : !data ? "Fetching..." : data;
};
