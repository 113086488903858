import React, { useEffect, useState } from "react";
import api from "../../../api/baseConfig";
import ApiHelper from "../../../api/apiHelper";
import "react-toastify/dist/ReactToastify.css";
import "../EcommerceOrders/toastStyles.css";
import CustomTable from "../../CustomerManagement/CustomListTable";
import { useParams } from "react-router-dom";
import { sessionPrivileges } from "privileges";
import { toast } from "react-toastify";

export default function AllTaxonomies(props) {
  const userPrivileges = sessionPrivileges();
  const Priv = sessionPrivileges().userprivileges || [];
  const [data, setData] = useState([]);
  const [subData, setSubData] = useState([]);
  let params = useParams();

  const activePage = props?.location?.state?.activePage;
  const itemsPerPage = props?.location?.state?.itemsPerPage;

  useEffect(() => {
    getAllTaxonomies();
    window.scrollTo(0, 0);
  }, []);

  const getAllTaxonomies = () => {
    ApiHelper.axiosCall(
      `${api.baseURL}${api.masterController.getAllTaxonomy}`,
      "get"
    )
      .then((data) => {
        let tempData = [];
        data.forEach((datum) => {
          tempData.push({
            name: datum.name,
            description: datum.description,
            subTaxonomiesCount: datum.subCount,
            id: datum.id,
          });
          if (datum.subCount === 0) {
            toast.warning("Please add Sub Taxonomy for " + datum.name);
          }
        });
        setData(tempData);
      })
      .catch((e) => {});
  };

  const deleteTaxonomies = (id) => {
    ApiHelper.axiosCall(
      `${api.baseURL}${api.masterController.deleteTaxonomy}${id}`,
      "post"
    )
      .then((d) => {
        getAllTaxonomies();
      })
      .catch((e) => {});
  };

  const getSubTaxonomy = async (id) => {
    await ApiHelper.axiosCall(
      `${api.baseURL}${api.masterController.getSubTaxonomy}${id}`,
      "get"
    )
      .then((data) => {
        let tempData = [];
        data.forEach((datum) => {
          tempData.push({
            name: datum.name,
            description: datum.description,
            id: datum.id,
          });
        });
        setSubData(tempData);
      })
      .catch((e) => {});
  };
  return (
    <>
    {userPrivileges.isSuperAdmin && (
    <div className="page-content">
      <CustomTable
        header="Taxonomies"
        createLink="/create-Taxonomy"
        data={data}
        dataLength={data.length}
        tableHeaders={[
          { label: "Taxonomy", val: "name" },
          { label: "Description", val: "description" },
          { label: "Sub Taxonomy Count", val: "subTaxonomiesCount" },
          { label: "Sub Taxonomy", val: "subTaxonomies", own: true },
        ]}
        ownLink="/sub/taxonomy/"
        searchPlaceholder="Search Taxonomy"
        deleteDatum={(id) => deleteTaxonomies(id)}
        passEditFromRow
        editData={["name", "description", "id"]}
        accord
        showAdd={Priv.includes("ADD_TAXONOMY")}
        hideEdit={!Priv.includes("EDIT_TAXONOMY")}
        hideDelete={!Priv.includes("DELETE_TAXONOMY")}
        getAllSubs={(id) => {
          getSubTaxonomy(id);
        }}
        subTitle={"Sub Taxonomy"}
        subData={subData}
        createSubLink="/create-sub-taxonomy/"
        tableSubHeaders={[
          { label: "Sub Taxonomy", val: "name" },
          { label: "Description", val: "description" },
        ]}
        passSubEditFromRow
        editSubData={["name", "description", "id"]}
        hoverable
        hideSubAdd={Priv.includes("ADD_SUB_TAXONOMY")}
        hideSubEdit={Priv.includes("EDIT_SUB_TAXONOMY")}
        hideSubDelete={Priv.includes("DELETE_SUB_TAXONOMY")}
        activePage={activePage}
        itemsPerPage={itemsPerPage}
      />
    </div>
    )}
    </>
  );
}
