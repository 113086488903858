import { useState, useEffect } from "react";
import QuestionValidations from "./utils/Validations";
import { Button, Card, CardBody, Col, FormGroup, Label, Row } from "reactstrap";
import QuestionMedia from "./dropzoneQuestion";
import { QuestionImage } from "./QuestionImage";
import Checkbox from "@material-ui/core/Checkbox";
import * as CustomIcons from "../../assets/icons/icons";
import { Field, reduxForm, getFormValues } from "redux-form";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import EditorField from "./EditorField";
import { saveQuestion } from "../../store/question/actions";
import api from "../../api/baseConfig";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {
  Table,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from "reactstrap";
import Apihelper from "../../api/apiHelper";
import CustomNumber from "./NumberInput";
import SolutionModal from "./SolutionsModal";
import DefaultSolutions from "./DefaultSolutions";
import { TypeTables } from "./TypeTables.js";
import SelectInput from "./SelectInput";
import { DisableCheck } from "./utils/DisableCheck";
import QuestionTypeModal from "./QuestionTypeModal";
import MasterAPIs from "../utils/MasterAPIs";
import { ModalToggle } from "pages/utils/ModalToggle";
// import Modal from "@material-ui/core/Modal";
// import Box from "@material-ui/core/Box";
// import Typography from "@material-ui/core/Typography";
// import axios from "axios";
// import { win32 } from "path"; unused Imports

const tableHeaders = {
  MC: [
    { label: "Option", value: "optionIdentifier" },
    { label: "Is", value: "answer" },
  ],
  CD: [
    { label: "Input Testcase", value: "inputText" },
    { label: "Output Testcase", value: "outputText" },
  ],
  DESC: [
    { label: "Answer Key", value: "answerKey" },
    { label: "Answer Key Points", value: "answerKeyPoints" },
  ],
  hints: [{ label: "Hint", value: "desc" }],
  SInput: [{ label: "Sample Input", value: "desI" }],
  SOutput: [{ label: "Sample Output", value: "desO" }],
  programmingSolutions: [
    {
      label: "Language",
      value: "lang",
    },
    {
      label: "Solution",
      value: "sol",
    },
  ],
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const ModalLabels = {
  MC: {
    header: "Option",
    FF: "Option",
  },
  CD: {
    header: "Test Case",
    FF: "Input Testcase",
    SF: "Output Testcase",
  },
  DESC: {
    header: "Answer Key",
    FF: "Answer Key",
    SF: "Answer Key Points",
  },
  HINT: {
    header: "Add Hint",
    FF: "Hint",
  },
};

const defaultValues = {
  opValue: {
    MC: {
      optionIdentifier: "",
      answer: false,
      id: 0,
    },
    CD: {
      inputText: "",
      outputText: "",
      id: 0,
    },
    DESC: {
      answerKey: "",
      answerKeyPoints: "0",
      id: 0,
    },
  },
  hintValue: {
    desc: "",
    id: 0,
  },
  SInputValue: {
    desI: "",
    id: 0,
  },
  SOutputValue: {
    desO: "",
    id: 0,
  },
  solValue: {
    val: "",
    lang: {
      label: "",
      value: "",
    },
    id: 0,
  },
  defSolValue: {
    val: "",
    lang: {
      label: "",
      value: "",
    },
    id: 0,
  },
  modalToggle: (prevState) => !prevState,
};

const customStyles = {
  menu: (styles) => ({ ...styles, zIndex: 9999 }),
};

let initialValues = {};
let QBid = "";

const renderField = ({
  input,
  label,
  placeholder,
  meta: { touched, error },
}) => (
  <FormGroup row>
    <Col lg={input.name.includes("answerKeyPoints") ? "4" : "12"}>
      {label && <Label htmlFor="taskname">{label}</Label>}
      <input
        type={input.name.includes("answerKeyPoints") ? "number" : "text"}
        {...input}
        className="form-control"
        placeholder={placeholder}
      />
      {touched && error && <span style={{ color: "red" }}>{error}</span>}
    </Col>
  </FormGroup>
);

const renderTextArea = ({ input, label }) => (
  <>
    <Label>{label}</Label>
    <textarea {...input} className="form-control" style={{ height: "10rem" }} />
  </>
);
const renderNumberField = ({
  input,
  label,
  checkNegative = false,
  meta: { touched, error },
}) => (
  <CustomNumber
    input={{ ...input }}
    len={4}
    label={label}
    checkNegative={checkNegative}
    error={ error ? error : ""}
  />
);

const renderCustomCheckField = ({
  input,
  label,
  allOptions,
  setOptions,
  setPropsOptions,
}) => (
  <Row style={{ marginLeft: 5 }}>
    <Label style={{ marginTop: 11 }}>{label}</Label>
    <Col style={{ width: 300 }}>
      <Checkbox
        onChange={(e) => {
          input.onChange(e);
          if (allOptions.filter((op) => op.answer).length > 1 && input.value) {
            setOptions();
            setPropsOptions();
          }
        }}
        checked={input.value}
        color="primary"
      />
    </Col>
  </Row>
);

const renderCustomFileInput = ({
  input,
  label,
  allOptions,
  setOptions,
  setPropsOptions,
  setAudioFile,
}) => (
  <Row style={{ marginLeft: 5 }}>
    <Label style={{ marginTop: 11 }}>{label}</Label>
    <Col style={{ width: 300 }}>
      <input
        type="file"
        accept="audio/*"
        onChange={(e) => {
          input.onChange(e);
          const file = e.target.files[0];
          if (file) {
            setAudioFile(file);
          }
        }}
        style={{
          display: "block",
          width: "100%",
          padding: "8px",
          borderRadius: "4px",
          border: "1px solid #ccc",
        }}
      />
    </Col>
  </Row>
);

const setInitialValue = (val) => {
  initialValues = val;
};
const asyncValidate = async (values) => {
  let oldName = initialValues?.name
    ? initialValues?.name.replace(/\r?\n|\r/g, "")
    : "";
  let newName = values.name.replace(/\s+$/, "");
  oldName = oldName.replace(/\s+$/, "");
  const encodedName = window.btoa(values.name);
  if (!oldName || (initialValues && oldName !== newName)) {
    await Apihelper.axiosCall(
      `${api.baseURL}${api.questionController.checkIfNameExists}${QBid}/${encodedName}`,
      "get"
    ).then((data) => {
      if (data) {
        throw {
          name: "That name is already taken. Please choose a different name",
        };
      }
    });
  }
};
const CreateQueComponent = (props) => {
  const { handleSubmit, initialValues, formValues, handleCallBack } = props;
  const [show, setShow] = useState(false);
  const [TypeModal, setTypeModal] = useState(false);
  const [hintModal, setHintModal] = useState(false);
  const [SInputModal, setSInputModal] = useState(false);
  const [SOutputModal, setSOutputModal] = useState(false);
  const [editState, setEditState] = useState(false);
  const [languages, setLanguages] = useState([]);
  const [solutionModal, setSolutionModal] = useState(false);
  const [noOfCheckers, setNoOfCheckers] = useState(0);
  const [searchKey, setSearchKey] = useState("");
  const [contains, setContains] = useState("");
  const [containsCount, setContainsCount] = useState("");
  const [message, setMessage] = useState("");
  const [customCheckListData, setCustomCheckListData] = useState([]);
  const [open, setOpen] = useState(false);
  const [defaultSolutionModal, setDefaultSolutionModal] = useState(false);
  const [optionsLists, setOptionsLists] = useState(false);

  const [opValue, setOpValue] = useState(defaultValues.opValue);
  const [hintValue, setHintValue] = useState(defaultValues.hintValue);
  const [SInputValue, setSInputValue] = useState(defaultValues.SInputValue);
  const [SOutputValue, setSOutputValue] = useState(defaultValues.SInputValue);
  const [solValue, setSolValue] = useState(defaultValues.solValue);
  const [defSolValue, setDefSolValue] = useState(defaultValues.defSolValue);
  const [options, setOptions] = useState([]);
  const [hints, setHints] = useState([]);
  const [SInputs, setSInputs] = useState([]);
  const [SOutputs, setSOutputs] = useState([]);
  const [solutions, setSolutions] = useState([]);
  const [defaultSolutions, setDefaultSolutions] = useState([]);
  const [allProficiencies, setAllProficiencies] = useState([]);
  const [allTaxonomies, setAllTaxonomies] = useState([]);
  const [allCategories, setAllCategories] = useState([]);
  const [allTopics, setAllTopics] = useState([]);
  const [custName, setCustName] = useState([])

  const [allSubTaxonomies, setAllSubTaxonomies] = useState([]);
  setInitialValue(initialValues);
  const [audiofile, setAudioFile] = useState(null);

  QBid = props.idAndName.id;
  useEffect(() => {
    window.scrollTo(0, 0);
    getLanguages();
    getAllProficiencies();
    getAllTaxonomies();
    getAllCategories();
  }, []);

  const [hasErrors, setHasErrors] = useState(false);

  const validate=(values)=>{
    if (values.skillWeightage < 0 || values.knowledgeWeightage < 0 || values.marks < 0 || values.skillWeightage > 100 || values.knowledgeWeightage > 100 || values.marks > 100) {
      setHasErrors(true);
    } else {
      setHasErrors(false);
    }
  };

  const getAllProficiencies = async () => {
    const profs = await MasterAPIs.ALL_PROFICIENCIES();
    setAllProficiencies(profs);
  };

  const getAllTaxonomies = async () => {
    const taxonomies = await MasterAPIs.ALL_TAXONOMIES();
    setAllTaxonomies(taxonomies);
  };

  const getAllSubTaxonomies = async (id) => {
    const subTaxonomies = await MasterAPIs.ALL_SUB_TAXONOMIES(id);
    setAllSubTaxonomies(subTaxonomies);
  };

  const getAllCategories = async () => {
    const categories = await MasterAPIs.ALL_CATEGORIES();
    setAllCategories(categories);
  };

  const getAllTopics = async (id) => {
    const topics = await MasterAPIs.ALL_TOPICS(id);
    setAllTopics(topics);
  };

  useEffect(() => {
    if (props.typeValues && props.typeValues.length && !options.length)
      setOptions(props.typeValues);
  }, [props?.typeValues]);

  useEffect(() => {
    if (props.hints && props.hints.length && !hints.length)
      setHints(props.hints);
  }, [props?.hints]);

  useEffect(() => {
    if (props?.solutions && props?.solutions.length && !solutions.length)
      setSolutions(props?.solutions);
  }, [props?.solutions]);

  useEffect(() => {
    if (
      props?.defaultSolutions &&
      props?.defaultSolutions.length &&
      !defaultSolutions.length
    )
      setDefaultSolutions(props?.defaultSolutions);
  }, [props?.defaultSolutions]);

  useEffect(() => {
    if (formValues?.taxonomyId) {
      getAllSubTaxonomies(formValues?.taxonomyId);
    }
  }, [formValues?.taxonomyId]);

  useEffect(() => {
    if (formValues?.categoryId) {
      getAllTopics(formValues?.categoryId);
      props?.setCategoryName(custName.length > 0 ? custName : formValues?.category)
    }
  }, [formValues?.categoryId]);

  const getLanguages = async () => {
    if (!languages.length) {
      await Apihelper.axiosCall(
        `${api.baseURL}${api.LanguageController.currentLanguage}`,
        "get"
      ).then((data) => {
        let option = [];
        data?.forEach?.((element) => {
          option.push({
            label: element.languageNotation,
            value: element.id,
          });
        });
        setLanguages(option);
      });
    }
  };

  const validIndicator = (value) => (
    <>
      {value}
      <span style={{ color: "red" }}>*</span>
    </>
  );

  // useEffect(() => {
  //   if (options.) {
  //     setOptionsLists(true);
  //   }
  // }, []);

  const checkState = () => {
    if (options.length < 2 && formValues?.type !== "AUDIO") return false;
    let boo = false;
    let answerCount = 0;
    if (formValues?.type === "MC") {
      for (let i = 0; i < options.length; i++) {
        if (options[i].answer) {
          answerCount++;
        }
      }
      if (formValues?.multiSelect) {
        boo = answerCount > 1;
      } else {
        boo = answerCount > 0;
      }
    } else {
      boo = true;
    }
    return boo;
  };

  const setOptionValue = () => {
    let temp = [...options];
    let type = formValues?.type || initialValues?.type;
    if (editState) {
      temp[opValue[type].id] = { ...opValue[type] };
      setEditState(false);
    } else {
      temp.push({ ...opValue[type], id: temp.length });
    }   
    setOptions(temp);
    setOpValue(defaultValues.opValue);
    setTypeModal(defaultValues.modalToggle);
    props.setTypeValues(temp);
  };

  const setSInputValues = () => {
    let temp = [...SInputs];
    if (editState) {
      temp[SInputValue.id] = { ...SInputValue };
      setEditState(false);
    } else {
      temp.push({ ...SInputValue, id: temp.length });
    }
    setSInputs(temp);
    setSInputValue(defaultValues.SInputValue);
    setSInputModal(defaultValues.modalToggle);
  };

  const setSOutputValues = () => {
    let temp = [...SOutputs];
    if (editState) {
      temp[SOutputValue.id] = { ...SOutputValue };
      setEditState(false);
    } else {
      temp.push({ ...SOutputValue, id: temp.length });
    }
    setSOutputs(temp);
    setSOutputValue(defaultValues.SOutputValue);
    setSOutputModal(defaultValues.modalToggle);
  };

  const setHintValues = () => {
    let temp = [...hints];
    if (editState) {
      temp[hintValue.id] = { ...hintValue };
      setEditState(false);
    } else {
      temp.push({ ...hintValue, id: temp.length });
    }
    setHints(temp);
    setHintValue(defaultValues.hintValue);
    setHintModal(defaultValues.modalToggle);
    props.setHints(temp);
  };

  const setSolutionValues = () => {
    let temp = [...solutions];
    if (editState) {
      temp[solValue.id] = {
        ...solValue,
      };
      setEditState(false);
    } else {
      temp.push({
        ...solValue,
        id: temp.length,
      });
    }
    setSolutionModal(defaultValues.modalToggle);
    setSolutions(temp);
    setSolValue(defaultValues.solValue);
    props.setSols(temp);
  };

  const setDefaultSolutionValues = () => {
    let temp = [...defaultSolutions];
    if (editState) {
      temp[defSolValue.id] = {
        ...defSolValue,
      };
      setEditState(false);
    } else {
      temp.push({
        ...defSolValue,
        id: temp.length,
      });
    }
    setDefaultSolutionModal(defaultValues.modalToggle);
    setDefaultSolutions(temp);
    setDefSolValue(defaultValues.defSolValue);
    props.setDefSols(temp);
  };

  const setValue = (state, key, deepKey, value) => {
    let temp = {};
    switch (state) {
      case "OP": {
        temp = { ...opValue };
        temp[key] = { ...temp[key], [deepKey]: value };
        setOpValue(temp);
        break;
      }
      case "HN": {
        temp = { ...hintValue };
        temp[key] = value;
        setHintValue(temp);
        break;
      }
      case "SI": {
        temp = { ...SInputValue };
        temp[key] = value;
        setSInputValue(temp);
        break;
      }
      case "SO": {
        temp = { ...SOutputValue };
        temp[key] = value;
        setSOutputValue(temp);
        break;
      }
    }
  };

  const handleTypeModalTextArea = (event) => {
    const value = event.target.value;
    if (hintModal) {
      setValue("HN", "desc", "", value);
    } 
    else if (SInputModal) {
      setValue("SI", "desI", "", value);
    }
    else if (SOutputModal) {
      setValue("SO", "desO", "", value);
    }
    else {
      switch (formValues?.type) {
        case "MC":
          setValue("OP", "MC", "optionIdentifier", value);
          break;
        case "CD":
          setValue("OP", "CD", "inputText", value);
          break;
        case "DESC":
          setValue("OP", "DESC", "answerKey", value);
          break;
      }
    }
  };

  const handleTypeModalText = (event) => {
    const value = event.target.value;

    switch (formValues?.type) {
      case "CD":
        setValue("OP", "CD", "outputText", value);
        break;
      case "DESC":
        setValue("OP", "DESC", "answerKeyPoints", value);
        break;
    }
  };

  const handleCheckbox = () =>
    setValue("OP", "MC", "answer", !opValue.MC.answer);

  const disableCheck = () => {
    const type = formValues?.type;
    if (hintModal || TypeModal || solutionModal) {
      return DisableCheck(
        type,
        hintModal,
        hintValue,
        hints,
        editState,
        options,
        opValue,
        solutionModal,
        solValue,
        solutions
      );
    }
  };

  const isAnswerCheck = () => {
    let boo = false;
    let trueList = options.filter((op) => op.answer);
    if (trueList.length === 1) {
      if (editState) {
        const curr = options[opValue["MC"].id];
        boo = !curr.answer;
      } else {
        boo = true;
      }
    }
    return boo;
  };

  const selectedLanguages = (arr) => {
    let currentLanguages = [];
    let availableLanguages = [...languages];
    if (arr.length > 0) {
      arr.forEach((sol) => {
        currentLanguages.push(sol.lang.value);
      });
      availableLanguages = languages.reduce((acc, val) => {
        let check = currentLanguages.includes(val.value);
        if (!check) {
          acc.push(val);
        }
        return acc;
      }, []);
    }
    return availableLanguages;
  };

  const handleModalClose = () => {
    if (editState) {
      setEditState(false);
    }
    if (hintModal) {
      setHintModal(defaultValues.modalToggle);
      setHintValue(defaultValues.hintValue);
    } 
    else if (SInputModal) {
      setSInputModal(defaultValues.modalToggle);
      setSInputValue(defaultValues.SInputValue);
    }
    else if (SOutputModal) {
      setSOutputModal(defaultValues.modalToggle);
      setSOutputValue(defaultValues.SOutputValue);
    }
    else if (solutionModal) {
      setSolutionModal(defaultValues.modalToggle);
      setSolValue(defaultValues.solValue);
    } else {
      setTypeModal(defaultValues.modalToggle);
      setOpValue(defaultValues.opValue);
    }
  };

  const returnTypeModalTextAreaValue = () => {
    let value = "";
    SInputModal
      ? (value = SInputValue.desI)
      : SOutputModal
      ? (value = SOutputValue.desO)
      : hintModal
      ? (value = hintValue.desc)
      : formValues?.type === "MC"
      ? (value = opValue["MC"].optionIdentifier)
      : formValues?.type === "CD"
      ? (value = opValue["CD"].inputText)
      : formValues?.type === "DESC"
      ? (value = opValue["DESC"].answerKey)
      : "";

    return value;
  };

  const returnTypeModalTextValue = () => {
    let value = "";
    formValues?.type === "CD"
      ? (value = opValue["CD"].outputText)
      : formValues?.type === "DESC"
      ? (value = opValue["DESC"].answerKeyPoints)
      : "";

    return value;
  };

  const removeTypeTableItem = (index, array, which) => {
    let temp = [...array];
    temp.forEach((o, i) => {
      if (index === 0) {
        o.id -= 1;
      } else if (index !== array.length - 1 && i > index) {
        o.id -= 1;
      }
    });
    temp.splice(index, 1);
    if (which === "OP") {
      props.setTypeValues(temp);
      setOptions(temp);
    } else if (which === "HN") {
      props.setHints(temp);
      setHints(temp);
    } else if (which === "SI") {
      // props.setHints(temp);
      setSInputs(temp);
    } else if (which === "SO") {
      setSOutputs(temp);
    } else if (which === "SOL") {
      props.setSols(temp);
      setSolutions(temp);
    } else if (which === "DEF_SOL") {
      props.setDefSols(temp);
      setDefaultSolutions(temp);
    }
  };

  const editTypeTableItem = (index, array, which) => {
    setEditState(true);
    if (which === "OP") {
      setTypeModal(defaultValues.modalToggle);
      let op = { ...opValue };
      op[formValues.type] = array[index];
      setOpValue(op);
    } else if (which === "HN") {
      setHintModal(defaultValues.modalToggle);
      setHintValue({ ...array[index] });
    } else if (which === "SI") {
      setSInputModal(defaultValues.modalToggle);
      setSInputValue({ ...array[index] });
    } else if (which === "SO") {
      setSOutputModal(defaultValues.modalToggle);
      setSOutputValue({ ...array[index] });
    } else if (which === "SOL") {
      setSolutionModal(defaultValues.modalToggle);
      setSolValue({ ...array[index] });
    } else if (which === "DEF_SOL") {
      setDefaultSolutionModal(defaultValues.modalToggle);
      setDefSolValue({ ...array[index] });
    }
  };

  const onSearchKeyChange = (event) => {
    setSearchKey(event.target.value);
  };
  const onMessageChange = (e) => {
    setMessage(e.target.value);
  };
  const onContainsCountChange = (e) => {
    setContainsCount(e.target.value);
  };
  const onContainsChange = (e) => {
    setContains(e.target.value);
  };

  const handleSubmitCustomChecker = () => {
    const checkerData = 
      {
        searchKey: searchKey,
        contains: contains,
        containsCount: containsCount,
        message: message,
      };
    handleClose();
    handleCallBack(checkerData);
    setNoOfCheckers(noOfCheckers + 1);
  };

  const handleOpen = () => {
    setCustomCheckListData(defaultValues.modalToggle);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div className="page-content">
      <DefaultSolutions
        languages={selectedLanguages(defaultSolutions)}
        isOpen={defaultSolutionModal}
        onClose={() => setDefaultSolutionModal(ModalToggle)}
        setLanguage={(lang) => setDefSolValue({ ...defSolValue, lang })}
        setLanguageSolution={(val) => setDefSolValue({ ...defSolValue, val })}
        add={setDefaultSolutionValues}
        editValue={editState}
        editorState={defSolValue}
        saveDisabled={!defSolValue.lang || !defSolValue.val}
      />
      {solutionModal && (
        <SolutionModal
          isOpen={solutionModal}
          close={handleModalClose}
          setSolutions={setSolutionValues}
          customStyles={customStyles}
          availableLanguages={selectedLanguages(solutions)}
          editorState={solValue}
          setLanguage={(lang) => setSolValue({ ...solValue, lang })}
          setEditorState={(val) => setSolValue({ ...solValue, val })}
          editValue={editState}
          disabled={!disableCheck()}
        />
      )}
      {hintModal || TypeModal ? (
        <QuestionTypeModal
          isOpen={hintModal ? hintModal : TypeModal}
          header={
            hintModal
              ? ModalLabels["HINT"].header
              : formValues?.type
              ? ModalLabels[formValues.type].header
              : ""
          }
          close={handleModalClose}
          TextArea={{
            value: returnTypeModalTextAreaValue(),
            onChange: handleTypeModalTextArea,
            label: hintModal
              ? ModalLabels["HINT"].FF
              : formValues?.type
              ? ModalLabels[formValues.type].FF
              : "",
          }}
          TextField={{
            value: returnTypeModalTextValue(),
            onChange: handleTypeModalText,
          }}
          secondField={{
            display: formValues?.type !== "MC" && !hintModal ? true : false,
            type: formValues?.type === "CD" ? "textarea" : "number",
            label: formValues?.type ? ModalLabels[formValues.type].SF : "",
          }}
          checkbox={{
            display: !hintModal && formValues?.type === "MC" ? true : false,
            disabled: !formValues?.multiSelect ? isAnswerCheck() : false,
            value: opValue["MC"].answer,
            onChange: handleCheckbox,
          }}
          disabled={!disableCheck()}
          onSave={hintModal ? setHintValues : setOptionValue}
        />
      ) : (
        ""
      )}

{/* {SInputModal || SOutputModal ? (
        <QuestionTypeModal
          isOpen={SInputModal ? SInputModal : SOutputModal}
          header={
           SInputModal
           ? "Add Sample Input"
           : "Add Sample Output"
          }
          close={handleModalClose}
          TextArea={{
            value: returnTypeModalTextAreaValue(),
            onChange: handleTypeModalTextArea,
            label: SInputModal 
              ? "Option"
              : SOutputModal
              ? "Option"
              : "",
          }}
          TextField={{
            value: returnTypeModalTextValue(),
            onChange: handleTypeModalText,
          }}
          secondField={{
            display: formValues?.type !== "MC" && !SInputModal && !SOutputModal ? true : false,
            type: formValues?.type === "CD" ? "textarea" : "number",
            label: formValues?.type ? ModalLabels[formValues.type].SF : "",
          }}
          checkbox={{
            display: !SInputModal && !SOutputModal && formValues?.type === "MC" ? true : false,
            disabled: !formValues?.multiSelect ? isAnswerCheck() : false,
            value: opValue["MC"].answer,
            onChange: handleCheckbox,
          }}
          // disabled={!disableCheck()}
          onSave={SInputModal ?  setSInputValues : setSOutputValues}
        />
      ) : (
        ""
      )} */}

      <Breadcrumbs title="Assessment" breadcrumbItem="Create Question" />
      <Row>
        <Col lg="12">
          <Card>
            <CardBody style={{ position: "relative" }}>
              <form className="outer-repeater" onSubmit={handleSubmit}>
                <div data-repeater-list="outer-group" className="outer">
                  <div data-repeater-item className="outer">
                    <div style={{ display: "flex" }}>
                      <div
                        style={{
                          padding: 12,
                          paddingRight: 5,
                          flex: 2,
                          width: "50%",
                        }}
                      >
                        <h4>Question Details</h4>
                        <p>Enter the necessary details to create a Question</p>
                      </div>
                      <div style={{ flex: 4, marginLeft: 10, width: "50%" }}>
                        <Field
                          name="name"
                          placeholder="Enter question name"
                          id="name"
                          label={validIndicator("Question Name")}
                          component={renderField}
                          validate={[QuestionValidations.QNAME]}
                        />
                        <FormGroup className="mb-4" row>
                          <Field
                            name="type"
                            placeholder="Choose a Type"
                            id="type"
                            label={validIndicator("Type")}
                            options={[
                              { label: "Multi Choice", value: "MC" },
                              { label: "Coding", value: "CD" },
                              { label: "Descriptive", value: "DESC" },
                              { label: "Audio", value: "AUDIO" },
                              // { label: "Multi question", value: "MQ" },
                            ]}
                            component={SelectInput}
                            validate={[QuestionValidations.CATEGORY]}
                            props={{
                              allOptions: options,
                              setOptions: () => setOptions([]),
                              setPropsOptions: () => props.setTypeValues([]),
                            }}
                          />
                          <Field
                            name="complexity"
                            placeholder="Choose a Complexity"
                            id="complexity"
                            label={validIndicator("Complexity")}
                            options={[
                              { label: "Easy", value: "easy" },
                              { label: "Medium", value: "medium" },
                              { label: "Hard", value: "hard" },
                            ]}
                            component={SelectInput}
                            validate={[QuestionValidations.COMPLEXITY]}
                          />
                        </FormGroup>
                        <div style={{display: "block", }}>
                        {formValues?.type === "MC" && (
                          <Field
                            name="multiSelect"
                            component={renderCustomCheckField}
                            label="Multi Select"
                            props={{
                              allOptions: options,
                              setOptions: () => setOptions([]),
                              setPropsOptions: () => props.setTypeValues([]),
                            }}
                          />
                        )}
                        {formValues?.type === "AUDIO" && (
                          <div style={{marginTop: "10px" , marginBottom: "20px" , marginLeft:"-20px"}}>
                            <Field
                              name="questionSubType"
                              component={SelectInput}
                              label="Sub Type"
                              options={[
                                { label: "Audio - Audio", value: 1 },
                                { label: "Audio - Text", value: 2 },
                                { label: "Text - Audio", value: 3 },
                              ]}
                              props={{
                                allOptions: options,
                                setOptions: () => setOptions([]),
                                setPropsOptions: () => props.setTypeValues([]),
                                setAudioFile,
                              }}
                              
                            />
                          </div>
                        )}                        
                        {formValues?.type === "AUDIO" && formValues?.questionSubType !== 3 &&(
                          <div style={{marginTop: "10px" , marginBottom: "20px"}}>
                            <Field
                              name="audiofile"
                              component={renderCustomFileInput}
                              label="Audio File Upload"
                              props={{
                                allOptions: options,
                                setOptions: () => setOptions([]),
                                setPropsOptions: () => props.setTypeValues([]),
                                setAudioFile,
                              }}
                              
                            />
                          </div>
                        )}
                        

                          {/* <span onChange={() => {
                              setShow(!show);
                            }}>
                          <FormControlLabel
           label={
            <Box component="div" fontSize={12} fontWeight={500}>
               Multi question
             </Box>
       }
          value="start"
          control={<Checkbox  color="primary"/>}
          labelPlacement="start"
        /></span> */}
                        </div>
                        <Field
                          name="question"
                          label={validIndicator("Question")}
                          id="question"
                          disabled={false}
                          placeholder="Type here"
                          validate={[QuestionValidations.QUESTIONS]}
                          component={EditorField}
                        />
                        <Row style={{ marginTop: 20 }}>
                          <Field
                            name="proficiencyId"
                            label={validIndicator("Proficiency")}
                            component={SelectInput}
                            validate={[QuestionValidations.PROFICIENCY]}
                            options={allProficiencies}
                            placeholder="Choose a Proficiency"
                          />

                          <Field
                            name="taxonomyId"
                            label={validIndicator("Taxonomy")}
                            component={SelectInput}
                            validate={[QuestionValidations.TAXONOMY]}
                            options={allTaxonomies}
                            placeholder="Choose a Taxonomy"
                          />
                        </Row>
                        <Row
                          style={{
                            marginBottom: 15,
                            marginTop: 15,
                          }}
                        >
                          <Field
                            name="subTaxonomyId"
                            component={SelectInput}
                            label={validIndicator("Level")}
                            validate={[QuestionValidations.SUBTAX]}
                            options={allSubTaxonomies}
                            placeholder="Choose a Level"
                          />
                          <Field
                            name="categoryId"
                            component={SelectInput}
                            needName={true}
                            setCustName={setCustName}
                            label={validIndicator("Category")}
                            validate={[QuestionValidations.CATE]}
                            options={allCategories}
                            placeholder="Choose a Category"
                          />
                        </Row>
                        <Row>
                          <Field
                            name="topicId"
                            label={validIndicator("Topic")}
                            validate={[QuestionValidations.TOPIC]}
                            component={SelectInput}
                            options={allTopics}
                            placeholder="Choose a Topic"
                          />
                        </Row>
                      </div>
                    </div>
                    <hr />
                    {formValues &&
                      formValues.hasOwnProperty("type") &&
                      formValues["type"] == "CD" && (
                        <>
                          <div style={{ display: "flex" }}>
                            <div
                              style={{
                                padding: 12,
                                marginRight: 4,
                                flex: 2,
                                width: "50%",
                              }}
                            >
                              <h4>
                                Add Input Format, Input Constraint and Output
                                Format
                              </h4>
                            </div>
                            <div
                              style={{
                                flex: 4,
                                marginTop: 10,
                                display: "flex",
                                flexDirection: "column",
                                gap: 10,
                              }}
                            >
                              <Field
                                name="inputFormat"
                                label="Input Format"
                                id="inputFormat"
                                disabled={false}
                                placeholder="Type here"
                                component={renderTextArea}
                              />
                              <Field
                                name="inputConstraints"
                                label="Input Constraint"
                                id="inputConstraints"
                                disabled={false}
                                placeholder="Type here"
                                component={renderTextArea}
                              />
                              <Field
                                name="outputFormat"
                                label="Output Format"
                                id="outputFormat"
                                disabled={false}
                                placeholder="Type here"
                                component={renderTextArea}
                              />
                            </div>
                          </div>
                          <hr />
                        </>
                      )}
                    {formValues &&
                      formValues.hasOwnProperty("type") &&
                      formValues["type"] == "CD" && (
                        <>
                          <div style={{ display: "flex" }}>
                            <div
                              style={{
                                padding: 12,
                                marginRight: 4,
                                flex: 2,
                                width: "50%",
                              }}
                            >
                              <h4>Add Sample Input and Output</h4>
                            </div>
                            <div
                              style={{
                                flex: 4,
                                marginTop: 10,
                                display: "flex",
                                flexDirection: "column",
                                gap: 10,
                              }}
                            >
                              {/* <div
                          style={{
                            marginBottom: 10,
                          }}
                        >
                        <Button
                            style={{ marginBottom: 10 }}
                            color="success"
                            onClick={() =>
                              setSInputModal(defaultValues.modalToggle)
                            }
                          >
                            <CustomIcons.PlusIcon size="17" color="#fff" /> Add
                            Sample Input
                          </Button>
                          <TypeTables
                            name="sampleInput"
                            values={SInputs}
                            tableHeaders={tableHeaders["SInput"]}
                            removeItem={(idx) =>
                              removeTypeTableItem(idx, SInputs, "SI")
                            }
                            editItem={(idx) =>
                              editTypeTableItem(idx, SInputs, "SI")
                            }
                          />
                        </div> */}
                               <Field
                                name="sampleInput"
                                label="Sample Input"
                                id="sampleInput"
                                disabled={false}
                                placeholder="Type here"
                                component={renderTextArea}
                              />
                               <Field
                                name="sampleOutput"
                                label="Sample Output"
                                id="sampleOutput"
                                disabled={false}
                                placeholder="Type here"
                                component={renderTextArea}
                              /> 
                              {/* <div
                          style={{
                            marginBottom: 10,
                          }}
                        >
                        <Button
                            style={{ marginBottom: 10 }}
                            color="success"
                            onClick={() =>
                              setSOutputModal(defaultValues.modalToggle)
                            }
                          >
                            <CustomIcons.PlusIcon size="17" color="#fff" /> Add
                            Sample Output
                          </Button>
                          <TypeTables
                            name="sampleOutput"
                            values={SOutputs}
                            tableHeaders={tableHeaders["SOutput"]}
                            removeItem={(idx) =>
                              removeTypeTableItem(idx, SOutputs, "SO")
                            }
                            editItem={(idx) =>
                              editTypeTableItem(idx, SOutputs, "SO")
                            }
                          />
                        </div> */}
                            </div>
                          </div>
                          <hr />
                        </>
                      )}
                    {formValues && formValues.hasOwnProperty("type") && formValues?.type !== "MQ" && formValues?.type !== "AUDIO" && (
                      <>
                        <div style={{ display: "flex" }}>
                          <div style={{ padding: 12, marginRight: 4, flex: 2 }}>
                            <h4>
                              {formValues?.type === "MC"
                                ? "Add Options"
                                : formValues?.type === "CD"
                                ? "Add Test Cases"
                                : formValues?.type === "DESC"
                                ? "Add Answer Keys"
                                : ""}
                            </h4>
                          </div>
                          <div style={{ flex: 4, marginTop: 10 }}>
                            <Button
                              style={{ marginBottom: 10 }}
                              color="success"
                              onClick={() => {
                                setTypeModal(defaultValues.modalToggle);
                              }}
                            >
                              <CustomIcons.PlusIcon size="17" color="#fff" />{" "}
                              {formValues?.type === "MC"
                                ? "Add Option"
                                : formValues?.type === "CD"
                                ? "Add Test Case"
                                : formValues?.type === "DESC"
                                ? "Add Answer Key"
                                : ""}
                            </Button>
                            <TypeTables
                              name="optionsList"
                              values={options}
                              tableHeaders={tableHeaders[formValues?.type]}
                              removeItem={(idx) =>
                                removeTypeTableItem(idx, options, "OP")
                              }
                              editItem={(idx) =>
                                editTypeTableItem(idx, options, "OP")
                              }
                            />
                            {options.length < 2 ? (
                            <p style={{color:"red"}}>Please add atleast two options</p>
                            ): (
                              <div>
                              {!checkState() && (
                              <p style={{color:"red"}}>Please add right option</p>
                             )}
                             </div>
                            )}
                          </div>
                        </div>
                        <hr />
                      </>
                    )}
                    <div>
                    {formValues?.type === "MQ" && (
                    <div>
                    <div style={{ display: "flex" }}>
                      <div
                        style={{
                          padding: 12,
                          paddingRight: 14,
                          flex: 2,
                          width: "50%",
                        }}
                      >
                        {/* <h4>Hints and Solution</h4> */}
                        <p>Create a new sub question</p>
                      </div>
                      <div style={{ flex: 4, marginTop: 10, width: "50%" }}>
                        <div
                          style={{
                            marginBottom: 10,
                          }}
                        >
                           <Link
        to={{ pathname: "/create-question-sub",justifyContent: "center"}}
      >
                          <Button
                            style={{ marginBottom: 10 }}
                            color="success"
                            // onClick={() =>
                            //   this.props.history.push({
                            //     pathname: `/create-question-sub`,
                            //   })
                            // }
                          >
                            <CustomIcons.PlusIcon size="17" color="#fff" />{" "}  
                            Create Sub question  
                          </Button>
                          </Link>
                          <TypeTables
                            name="hints"
                            values={hints}
                            tableHeaders={tableHeaders["hints"]}
                            removeItem={(idx) =>
                              removeTypeTableItem(idx, hints, "HN")
                            }
                            editItem={(idx) =>
                              editTypeTableItem(idx, hints, "HN")
                            }
                          />
                        </div>
                        <div style={{width:"600px"}}>
                        <Table >
                <thead style={{ backgroundColor: "#C5EAFC", }}>
                  <tr>  
                    <th style={{ color: "#000" , paddingLeft:"40px"}}>Sub question</th>
                    <th style={{ color: "#000" , paddingLeft:"350px" }}>Actions</th>
                  </tr>
                </thead>
                <tbody style={{ backgroundColor: "#F9FDFF" }}>
                <tr>
          <td style={{paddingLeft:"40px"}}>Question 1</td>
          <td style={{paddingLeft:"350px"}}>
          <span
                                  style={{
                                    marginRight: 15,
                                    color: "#51B960",
                                    cursor: "pointer",
                                  }}
                                >
                                  {CustomIcons.penIcon("#51B960")}
                                </span>
                                <span
                                  style={{
                                    color: "#EB5757",
                                    cursor: "pointer",
                                  }}
                                >
                                  {CustomIcons.circleCrossIcon({
                                    size: 19,
                                    color: "#EB5757",
                                  })}
                                </span>
          </td>
          
        </tr>
        <tr>
          <td style={{paddingLeft:"40px"}}>Question 2</td>
          <td style={{paddingLeft:"350px"}}>
          <span
                                  style={{
                                    marginRight: 15,
                                    color: "#51B960",
                                    cursor: "pointer",
                                  }}
                                >
                                  {CustomIcons.penIcon("#51B960")}
                                </span>
                                <span
                                  style={{
                                    color: "#EB5757",
                                    cursor: "pointer",
                                  }}
                                >
                                  {CustomIcons.circleCrossIcon({
                                    size: 19,
                                    color: "#EB5757",
                                  })}
                                </span>
          </td>
          
        </tr>
        <tr>
          <td  style={{paddingLeft:"40px"}}>Question 3</td>
          <td style={{paddingLeft:"350px"}}>
          <span
                                  style={{
                                    marginRight: 15,
                                    color: "#51B960",
                                    cursor: "pointer",
                                  }}
                                >
                                  {CustomIcons.penIcon("#51B960")}
                                </span>
                                <span
                                  style={{
                                    color: "#EB5757",
                                    cursor: "pointer",
                                  }}
                                >
                                  {CustomIcons.circleCrossIcon({
                                    size: 19,
                                    color: "#EB5757",
                                  })}
                                </span>
          </td>
          
        </tr>
        <tr>
          <td style={{paddingLeft:"40px"}}>Question 4</td>
          <td style={{paddingLeft:"350px"}}>
                                <span
                                  style={{
                                    marginRight: 15,
                                    color: "#51B960",
                                    cursor: "pointer",
                                  }}
                                >
                                  {CustomIcons.penIcon("#51B960")}
                                </span>
                                <span
                                  style={{
                                    color: "#EB5757",
                                    cursor: "pointer",
                                  }}
                                >
                                  {CustomIcons.circleCrossIcon({
                                    size: 19,
                                    color: "#EB5757",
                                  })}
                                </span>
          </td>
        </tr>
                </tbody>
              </Table>
              </div>
                      </div>
                    </div>
                    <hr />
                    </div>
                    )}
                    </div>
                    <div>
                    {formValues?.type !== "MQ" && formValues?.type !== "AUDIO" && (
                    <div>
                    <div style={{ display: "flex" }}>
                      <div
                        style={{
                          padding: 12,
                          paddingRight: 14,
                          flex: 2,
                          width: "50%",
                        }}
                      >
                        <h4>Hints and Solution</h4>
                        <p>Provide Hints and Solution to your Question</p>
                      </div>
                      <div style={{ flex: 4, marginTop: 10, width: "50%" }}>
                        <div
                          style={{
                            marginBottom: 10,
                          }}
                        >
                          <Button
                            style={{ marginBottom: 10 }}
                            color="success"
                            onClick={() =>
                              setHintModal(defaultValues.modalToggle)
                            }
                          >
                            <CustomIcons.PlusIcon size="17" color="#fff" /> Add
                            Hint
                          </Button>
                          <TypeTables
                            name="hints"
                            values={hints}
                            tableHeaders={tableHeaders["hints"]}
                            removeItem={(idx) =>
                              removeTypeTableItem(idx, hints, "HN")
                            }
                            editItem={(idx) =>
                              editTypeTableItem(idx, hints, "HN")
                            }
                          />
                        </div>
                        {formValues?.type !== "CD" ? (
                          <Field
                            name="solution"
                            label="Solution"
                            id="solution"
                            disabled={false}
                            placeholder="Type here"
                            component={EditorField}
                          />
                        ) : (
                          <>
                            <Button
                              style={{ marginBottom: 10 }}
                              color="success"
                              onClick={() =>
                                setSolutionModal(defaultValues.modalToggle)
                              }
                            >
                              <CustomIcons.PlusIcon size="17" color="#fff" />{" "}
                              Add Solution
                            </Button>
                            <TypeTables
                              name="solutions"
                              values={solutions.reduce((acc, obj) => {
                                acc.push({
                                  sol: obj?.val,
                                  lang: obj?.lang?.label,
                                });
                                return acc;
                              }, [])}
                              tableHeaders={
                                tableHeaders["programmingSolutions"]
                              }
                              removeItem={(idx) =>
                                removeTypeTableItem(idx, solutions, "SOL")
                              }
                              editItem={(idx) =>
                                editTypeTableItem(idx, solutions, "SOL")
                              }
                            />
                          </>
                        )}
                      </div>
                    </div>
                    <hr />
                    </div>
                    )}
                    {formValues?.type == "AUDIO" && (
                      <div>
                        <div style={{ display: "flex" }}>
                          <div
                            style={{
                              padding: 12,
                              paddingRight: 14,
                              flex: 2,
                              width: "50%",
                            }}
                          >
                            <h4>Solution</h4>
                            <p>Provide the Solution text to your Question</p>
                          </div>
                          <div style={{ flex: 4, marginTop: 10, width: "50%" }}>
                            <Field
                              name="solution"
                              label="Solution"
                              id="solution"
                              disabled={false}
                              placeholder="Type here"
                              component={EditorField}
                            />
                          </div>
                        </div>
                        <hr />
                      </div>
                    )}
                    </div>
                    {/* {formValues?.type !== "MQ" && (
                      <div>
                    <div style={{ display: "flex" }}>
                      <div
                        style={{
                          padding: 12,
                          paddingRight: 14,
                          flex: 2,
                          width: "50%",
                        }}
                      >
                        <h4>Custom check</h4>
                        <p>Enable or disable custom check</p>
                      </div>

                      <div style={{ flex: 4, marginTop: 10, width: "50%" }}>
                        <div
                          style={{
                            marginBottom: 10,
                          }}
                        >
                          <Button
                            style={{ marginBottom: 10 }}
                            color="success"
                            onClick={handleOpen}
                          >
                            <CustomIcons.PlusIcon size="17" color="#fff" /> Add
                            Custom checker
                          </Button>
                          <h7 style={{paddingLeft: '20px'}}>{noOfCheckers} Checkers Added</h7>
                          <Modal
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                          >
                            <form>
                              <Box sx={style}>
                                <Typography
                                  id="modal-modal-title"
                                  variant="h6"
                                  component="h2"
                                >
                                  Add Custom Checker
                                </Typography>
                                <div className="pt-2">
                                  <h6>searchKey;</h6>
                                  <textarea
                                    style={{ width: "20rem", height: "80px" }}
                                    name="searchKey"
                                    type="text"
                                    placeholder="searchKey"
                                    onChange={onSearchKeyChange}
                                  />
                                  <h6>boolean contains;</h6>
                                  <textarea
                                    style={{ width: "20rem", height: "80px" }}
                                    name="Contains"
                                    type={Boolean}
                                    placeholder="Contains"
                                    onChange={onContainsChange}
                                  />
                                  <h6>int ContainsCount;</h6>
                                  <textarea
                                    style={{ width: "20rem", height: "80px" }}
                                    name="ContainsCount"
                                    type= {Number}
                                    placeholder="ContainsCount"
                                    onChange={onContainsCountChange}
                                  />
                                  <h6>Message</h6>
                                  <textarea
                                    style={{ width: "20rem", height: "80px" }}
                                    name="Message"
                                    type="text"
                                    placeholder="Message"
                                    onChange={onMessageChange}
                                  />
                                  <Button
                                    style={{
                                      width: "77px",
                                      height: "40px",
                                      marginBottom: 10,
                                    }}
                                    onClick={() => handleSubmitCustomChecker()}
                                  >
                                    Add
                                  </Button>
                                </div>
                              </Box>
                            </form>
                          </Modal>
                        </div>
                      </div>
                    </div>
                    <hr />
                    </div>
                     )} */}
                    {formValues?.type === "CD" && (
                      <>
                        <div style={{ display: "flex" }}>
                          <div style={{ padding: 15, flex: 2 }}>
                            <h4>Default solution</h4>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flex: 4,
                              flexWrap: "wrap",
                              marginTop: 10,
                            }}
                          >
                            <Button
                              style={{ marginBottom: 10 }}
                              color="success"
                              onClick={() =>
                                setDefaultSolutionModal(ModalToggle)
                              }
                            >
                            <CustomIcons.PlusIcon size="17" color="#fff" />{" "}
                              Add default solution
                            </Button>
                            <TypeTables
                              name="defaultSolutions"
                              values={defaultSolutions.reduce((acc, obj) => {
                                acc.push({
                                  sol: obj?.val,
                                  lang: obj?.lang?.label,
                                });
                                return acc;
                              }, [])}
                              tableHeaders={
                                tableHeaders["programmingSolutions"]
                              }
                              removeItem={(idx) =>
                                removeTypeTableItem(
                                  idx,
                                  defaultSolutions,
                                  "DEF_SOL"
                                )
                              }
                              editItem={(idx) =>
                                editTypeTableItem(
                                  idx,
                                  defaultSolutions,
                                  "DEF_SOL"
                                )
                              }
                            />
                          </div>
                        </div>
                        <hr />
                      </>
                    )}
                    <div>
                    {formValues?.type !== "MQ" && (
                      <div>
                    <div style={{ display: "flex" }}>
                      <div style={{ padding: 15, flex: 2 }}>
                        <h4>Solution Media</h4>
                        <p>Upload Solution Media if any</p>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flex: 4,
                          flexWrap: "wrap",
                          marginTop: 10,
                        }}
                      >
                        <Field
                          name="solutionMedia"
                          component={QuestionMedia}
                          label="Solution Media"
                          media={
                            initialValues?.solutionMedia
                              ? initialValues?.solutionMedia
                              : ""
                          }
                          len={10}
                          props={{
                            type: ["video/*"],
                            sizeLimit: 10000000,
                          }}
                        />
                      </div>
                    </div>
                    <hr />
                    </div>
                    )}
                    </div>
                    <div
                      style={{
                        display: "flex",
                      }}
                    >
                      <div style={{ padding: 10, paddingRight: 2, flex: 2 }}>
                        <h4>Attributes</h4>
                        <p>Select the necessary attributes</p>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flex: 4,
                          flexWrap: "wrap",
                          marginTop: 10,
                        }}
                      >
                        <Field
                          name="skillWeightage"
                          component={renderNumberField}
                          placeholder="e.g.,2"
                          label="Skill Weightage"
                        />

                        <Field
                          name="knowledgeWeightage"
                          component={renderNumberField}
                          placeholder="e.g.,2"
                          label="Knowledge Weightage"
                        />

                        <Field
                          name="marks"
                          validate={[QuestionValidations.MARKS]}
                          component={renderNumberField}
                          placeholder="e.g.,2"
                          label={validIndicator("Marks")}
                        />
                        <div style={{ marginTop: 10, width: "100%" }}>
                          <Field
                            name="negativeMarks"
                            checkNegative={true}
                            component={renderNumberField}
                            placeholder="e.g.,2"
                            label="Negative Marks"
                          />
                        </div>
                      </div>
                    </div>
                    <hr />
                    <div style={{ display: "flex" }}>
                      <div style={{ padding: 15, flex: 2 }}>
                        <h4>Media</h4>
                        <p>Upload Media if any</p>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flex: 4,
                          flexWrap: "wrap",
                          marginTop: 10,
                        }}
                      >
                        <Field
                          name="media"
                          component={QuestionImage}
                          moduleName={"questionBank"}
                          customerName={props?.idAndName?.customerName}
                          currentId={props?.idAndName?.id}
                        />
                      </div>
                    </div>
                    <hr />
                  </div>
                </div>
                {checkState() && (
                  <div style={{ textAlign: "center" }}>
                    <Button
                      type="submit"
                      color="primary"
                      disabled={hasErrors || props.saveState === 'Saving...'}
                      style={{
                        fontSize: 16,
                        paddingLeft: 20,
                        paddingRight: 20,
                      }}
                    >
                      {props.saveState}
                    </Button>
                  </div>
                )}
              </form>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Link
        to={{ pathname: "/view-questions", state: props.idAndName }}
        className="btn text-muted d-none d-sm-inline-block btn-link"
      >
        <i className="mdi mdi-arrow-left mr-1" /> Back to Questions{" "}
      </Link>
    </div>
  );
};

const mapStateToProps = (state) => ({
  initialvalues: initialValues || {},
  formValues: getFormValues("crqForm")(state),
});
const crqForm = reduxForm({
  form: "crqForm",
  asyncValidate,
  asyncBlurFields: ["name"],
  validate: (values) => {
    const errors = {};
      if (values.skillWeightage < 0) {
        errors.skillWeightage = 'Negative values are Invalid';
      }
      if (values.knowledgeWeightage < 0) {
        errors.knowledgeWeightage = 'Negative values are Invalid';
      }
      if (values.knowledgeWeightage > 100) {
        errors.knowledgeWeightage = 'Values should not be more than 100';
      }
      if (values.skillWeightage > 100) {
        errors.skillWeightage = 'Values should not be more than 100';
      }
      if (values.marks > 100) {
        errors.marks = 'Values should not be more than 100';
      }
      return errors;
    }, 
  enableReinitialize: true,
})(CreateQueComponent);
export default connect(mapStateToProps, { saveQuestion })(crqForm);