import React, { useEffect, useMemo, useState } from "react";
import { withRouter } from "react-router-dom";
import { Paper } from "@material-ui/core";
import { GetObjectFromString } from "../../utils/GetObjectFromString";
import Select from "react-select";
import { Button } from "reactstrap";
import styles from "../UserAssessmentMarks.module.scss";
import Apihelper from "../../../api/apiHelper";
import api from "../../../api/baseConfig";
import { connect } from "react-redux";
import { getCustomers } from "../../../store/StudentAndCustomer/actions";
import Skeleton from "@material-ui/lab/Skeleton";
import TotalCard from "pages/Ecommerce/EcommerceOrders/TotalCard";
import { Row, Col } from "reactstrap";
import * as CustomIcons from "../../../assets/icons/icons.js";
import Box from "@material-ui/core/Box";
import tableStyles from "./summaryTable.module.scss";
import searchStyle from "./CustomSearch.module.scss";
import cn from "classnames";
import Pagination from "react-js-pagination";
import { paginationClasses } from "../../utils/constants";
import CustomSearch from "../../Tasks/CustomSearch";
import { sessionPrivileges } from "privileges";

const liveDot = (
  <svg
    width="8"
    height="8"
    viewBox="0 0 8 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="4" cy="4" r="4" fill="#DE5445" />
  </svg>
);

const completedDot = (
  <svg
    width="8"
    height="8"
    viewBox="0 0 8 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="4" cy="4" r="4" fill="#089A79" />
  </svg>
);

const notStartedDot = (
  <svg
    width="8"
    height="8"
    viewBox="0 0 8 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="4" cy="4" r="4" fill="#D99229" />
  </svg>
);
const entriesCount = [5, 10, 15, 20];

const ChooseOption = (props) => {
  return (
    <Paper elevation={3} className={styles.optionEmptySec}>
      <div className={styles.bluebg}></div>
      <p className={styles.optionCenter}>{props.centerContent}</p>
    </Paper>
  );
};

function UserAssessmentMarks(props) {
  const userPrivileges = sessionPrivileges();
  const Priv = userPrivileges.userprivileges || [];
  const [loading, setLoading] = useState(false);
  const [loadingDot, setLoadingDot] = useState(false);
  const [loadingStd, setLoadingStd] = useState(false);
  const [data, setData] = useState(null);
  const [assessment, setAssessment] = useState([]);
  const [students, setStudents] = useState([]);
  const [sectionTab, setSectionTab] = useState({});
  const [userDataList, setuserDataList] = useState([]);
  const [assessmentId, setAssessmentId] = useState("");
  const [customSearchValue, setCustomSearchValue] = useState("");
  const [activePage, setActivePage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [customerId, setCustomerId] = useState(() =>
    Priv.includes("MANAGE_CUSTOMER")
      ? props?.location?.params?.customerId || ""
      : sessionStorage.getItem("customer_id") || ""
  );

  const customers = useMemo(
    () =>
      (props?.Customers || []).sort(
        ({ name: n1 = "" }, { name: n2 = "" }) =>
          n1?.toLowerCase() < n2?.toLowerCase()
      ),
    [props?.Customers]
  );
  const customersList = [];
  customers.forEach(function (item) {
    customersList.push({ label: item.name, value: item.id });
  });
  const assessmentList = [];
  assessment.forEach(function (item) {
    assessmentList.push({ label: item.name, value: item.id });
  });
  const studentList = [];
  students?.forEach(function (item) {
    studentList.push({
      label: item.firstName + " " + item.lastName,
      value: item.emailId,
    });
  });

  useEffect(() => {
    if (Priv.includes("MANAGE_CUSTOMER")) {
      props.dispatch(getCustomers());
    } else {
      getAssessmentsByCustomer(sessionStorage.getItem("customer_id"));
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (customerId && customerId !== "") {
      getAssessmentsByCustomer(customerId);
    }
  }, [customerId]);

  useEffect(() => {
    if (assessmentId && assessmentId !== "") {
      getAssessmentReport();
    }
  }, [assessmentId]);

  const lastIndex = activePage * itemsPerPage;
  const firstIndex = lastIndex - itemsPerPage;

  //getAssessmentReport
  async function getAssessmentReport() {
    setLoading(true);
    await Apihelper.axiosCall(
      `${api.baseURL}${api.assessmentController.getLiveAssessmentDetail}${customerId}/${assessmentId}`,
      "get"
    )
      .then((d) => {
        setLoading(false);
        setData(d);
        //question-summary
        if (d && d.userData && d.userData.length) {
          const userDataList = d.userData;
          tableData(userDataList);
          setSectionTab(0);
        }
      })
      .catch((e) => {});
  }

  //getAssessmentsList
  async function getAssessmentsByCustomer(customer) {
    setLoadingDot(true);
    await Apihelper.axiosCall(
      `${api.baseURL}${api.assessmentController.getAssessmentNameListByCustomer}${customer}`,
      "get"
    )
      .then((data) => {
        setLoadingDot(false);
        setAssessment(data);
        setAssessmentId(data.find((e) => e.id === data["id"]));
      })
      .catch((e) => {});
  }

  const [filteredData, setFilteredData] = useState(0);
  const [searchData, setSearchData] = useState("");
  const [allData, setAllData] = useState([]);

  const searchDataValue = (query) => {
    let arr = [];
    if (query && query.length > 0) {
      data?.userData.forEach((e) => {
        const objArr = Object.keys(e);
        for (let i = 0; i < objArr.length; i++) {
          if (
            e[objArr[i]] &&
            typeof e[objArr[i]] === "string" &&
            e[objArr[i]].toLowerCase().includes(query.toLowerCase())
          ) {
            arr.push(e);
            break;
          }
        }
      });
      if (arr.length > 0) {
        setAllData(arr);
      } else {
        setAllData([]);
      }
    } else {
      setAllData(data?.userData);
    }
  };
  useEffect(() => {
    if (data?.userData?.activePage) {
      setActivePage(data?.userData.activePage);
    }
  }, [data?.userData.activePage]);

  useEffect(() => {
    if (data?.userData?.itemsPerPage) {
      setItemsPerPage(data?.userData.itemsPerPage);
    }
  }, [data?.userData.itemsPerPage]);

  const resetHandleSuperAdmin = () => {
    setCustomerId("");
    setAssessmentId("");
    setuserDataList("");
    setData(null);
  };
  const resetHandleAdmin = () => {
    setAssessmentId("");
    setuserDataList("");
    setData(null);
  };
  return (
    <>
    {(userPrivileges.isSuperAdmin || userPrivileges.isAdmin) && (
    <div className="page-content">
      <Paper elevation={1} className={styles.headerBox}>
        <h3>Live Assessment</h3>
        <div className={styles.selectWrapper1}>
          {userPrivileges.isSuperAdmin ? (
            <div className={styles.selectWrapper1}>
              <div className={styles.selectBox}>
                <Select
                  placeholder="Choose a Customer"
                  value={GetObjectFromString(customersList, customerId)}
                  onChange={(e) => {
                    setCustomerId(e.value);
                    setuserDataList("");
                    setData(null);
                  }}
                  options={customersList}
                  maxMenuHeight={120}
                />
              </div>

              <div className={styles.selectBox}>
                <Select
                  placeholder="Choose a Assessment"
                  value={GetObjectFromString(assessmentList, assessmentId)}
                  onChange={(e) => {
                    setAssessmentId(e.value);
                    setuserDataList("");
                    setData(null);
                  }}
                  options={assessmentList}
                  maxMenuHeight={120}
                  isDisabled={!customerId}
                  isLoading={loadingDot}
                />
              </div>
              <div className={styles.selectBox}>
                <div
                  className={styles.resetLink}
                  onClick={() => resetHandleSuperAdmin()}
                >
                  Reset Selection
                </div>
              </div>
            </div>
          ) : (
            <div className={styles.selectWrapper1}>
              <div className={styles.selectBox}>
                <Select
                  placeholder="Choose a assessment"
                  value={GetObjectFromString(assessmentList, assessmentId)}
                  onChange={(e) => {
                    setAssessmentId(e.value);
                    setuserDataList("");
                    setData(null);
                  }}
                  options={assessmentList}
                  maxMenuHeight={120}
                  isLoading={loadingDot}
                />
              </div>
              <div className={styles.selectBox}>
                <div
                  className={styles.resetLink}
                  onClick={() => resetHandleAdmin()}
                >
                  Reset selection
                </div>
              </div>
            </div>
          )}
        </div>
      </Paper>
      {loading ? (
        <div>
          <Skeleton height={80} />
          <Skeleton height={80} />
          <Skeleton height={80} />
          <Skeleton height={80} />
          <Skeleton height={80} />
        </div>
      ) : data?.userData.length > 0 ? (
        <div>
          <Row className="mb-4 mt-4">
            <Col>
              {!loading ? (
                <TotalCard
                  title={data?.studentsEnrolled || 0}
                  description="Students Enrolled"
                  color="#323036"
                />
              ) : (
                <Skeleton width={230} height={200} />
              )}
            </Col>
            <Col>
              {!loading ? (
                <TotalCard
                  title={data?.studentsStarted || 0}
                  description="Students Started"
                  color="#323036"
                />
              ) : (
                <Skeleton width={230} height={200} />
              )}
            </Col>
            <Col>
              {!loading ? (
                <TotalCard
                  title={data?.studentsNotStarted || 0}
                  description="Students Not Started"
                  color="#D42216"
                />
              ) : (
                <Skeleton width={230} height={200} />
              )}
            </Col>
            <Col>
              {!loading ? (
                <TotalCard
                  title={data?.Completed || 0}
                  description="Completed"
                  color="#1BA94C"
                />
              ) : (
                <Skeleton width={230} height={200} />
              )}
            </Col>
          </Row>
          <Paper elevation={1} className={styles.mainBox}>
            <Box display="flex" justifyContent="flex-start">
              <Box p={1} style={{ fontSize: "18px", marginRight: "5px" }}>
                {""} {data?.name}
              </Box>
              {data?.status == "NOT STARTED" ? (
                <Box
                  style={{
                    fontSize: "14px",
                    color: "#D99229",
                    marginTop: "10px",
                  }}
                >
                  {notStartedDot} {data?.status}
                </Box>
              ) : data?.status == "completed" ? (
                <Box
                  style={{
                    fontSize: "14px",
                    color: "#089A79",
                    marginTop: "10px",
                  }}
                >
                  {completedDot} {data?.status}
                </Box>
              ) : (
                <Box
                  style={{
                    fontSize: "14px",
                    color: "#DE5445",
                    marginTop: "10px",
                  }}
                >
                  {liveDot} {data?.status}
                </Box>
              )}
            </Box>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                backgroundColor: "#fff",
                padding: 10,
                paddingBottom: 5,
                alignItems: "center",
              }}
            >
              <div>
                <CustomSearch
                  placeholder={props.searchPlaceholder}
                  secondary
                  value={customSearchValue}
                  handlesearch={(e) => {
                    searchDataValue(e);
                    setCustomSearchValue(e);
                  }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  gap: 10,
                }}
              >
                <div
                  onClick={getAssessmentReport}
                  style={{
                    cursor: "pointer",
                    border: "1px solid #D7D7D7",
                    borderRadius: 5,
                    padding: "11px 17px",
                    height: "3.2em",
                    background: "#F9F9F9",
                  }}
                >
                  <span style={{ float: "left" }}>
                    {CustomIcons.refreshIcon}
                  </span>
                  <button style={{ border: 0, background: "none" }}>
                    Refresh
                  </button>
                </div>
              </div>
            </div>
            <div className={tableStyles.tableContainer}>
              <table className={tableStyles.table}>
                <thead>
                  <tr>
                    <th style={{ textAlign: "left", paddingLeft: 10 }}>S.No</th>
                    <th style={{ textAlign: "left", paddingLeft: 10 }}>Name</th>
                    <th style={{ textAlign: "left", paddingLeft: 50 }}>
                      Department
                    </th>
                    <th style={{ textAlign: "left", paddingLeft: 10 }}>
                      Reg.No
                    </th>
                    <th style={{ textAlign: "left", paddingLeft: 10 }}>
                      Mail Id
                    </th>
                    <th style={{ textAlign: "left", paddingLeft: 10 }}>
                      Status
                    </th>
                  </tr>
                </thead>
                {customSearchValue == "" || null || undefined || 0 ? (
                  <tbody>
                    {data?.userData.slice(firstIndex, lastIndex).map((e, i) => (
                      <tr key={i} style={{ borderTop: "1px solid #ECECEC" }}>
                        <td style={{ textAlign: "left", paddingLeft: 10 }}>
                          {i + 1}
                        </td>
                        <td style={{ textAlign: "left", paddingLeft: 10 }}>
                          {e.Name}
                        </td>
                        <td style={{ textAlign: "left", paddingLeft: 50 }}>
                          {e.Department}
                        </td>
                        <td style={{ textAlign: "left", paddingLeft: 10 }}>
                          {e.RegNo}
                        </td>
                        <td style={{ textAlign: "left", paddingLeft: 10 }}>
                          {e.MailId}
                        </td>
                        {e.Status == "Complete" ? (
                          <td style={{ padding: "5px 10px" }}>
                            <Button
                              outline
                              style={{
                                borderColor: "#B3DAC7",
                                backgroundColor: "#EEFFEA",
                                color: "#43B224",
                                paddingLeft: 17,
                                paddingRight: 17,
                              }}
                            >
                              {e.Status}
                            </Button>
                          </td>
                        ) : e.Status == "Started" ? (
                          <td style={{ padding: "5px 10px" }}>
                            <Button
                              outline
                              style={{
                                borderColor: "#FFCA99",
                                backgroundColor: "#FFF8E1",
                                color: "#886E1D",
                                paddingLeft: 24,
                                paddingRight: 24,
                              }}
                            >
                              {e.Status}
                            </Button>
                          </td>
                        ) : e.Status == "Not Started" ? (
                          <td style={{ padding: "5px 10px" }}>
                            <Button
                              outline
                              style={{
                                borderColor: "#FFD7D7",
                                backgroundColor: " #FFF4F3",
                                color: "#D42216",
                              }}
                            >
                              {e.Status}
                            </Button>
                          </td>
                        ) : (
                          <td style={{ padding: "5px 10px" }}>
                            <Button
                              outline
                              style={{
                                borderColor: "#FFD7D7",
                                backgroundColor: " #FFF4F3",
                                color: "#D42216",
                                paddingLeft: 22,
                                paddingRight: 22,
                              }}
                            >
                              {e.Status}
                            </Button>
                          </td>
                        )}
                      </tr>
                    ))}
                  </tbody>
                ) : (
                  <tbody>
                    {allData?.map((e, i) => (
                      <tr key={i} style={{ borderTop: "1px solid #ECECEC" }}>
                        <td style={{ textAlign: "left", paddingLeft: 10 }}>
                          {i + 1}
                        </td>
                        <td style={{ textAlign: "left", paddingLeft: 10 }}>
                          {e.Name}
                        </td>
                        <td style={{ textAlign: "left", paddingLeft: 50 }}>
                          {e.Department}
                        </td>
                        <td style={{ textAlign: "left", paddingLeft: 10 }}>
                          {e.RegNo}
                        </td>
                        <td style={{ textAlign: "left", paddingLeft: 10 }}>
                          {e.MailId}
                        </td>
                        {e.Status == "Complete" ? (
                          <td style={{ padding: "5px 2px" }}>
                            <Button
                              outline
                              style={{
                                borderColor: "#B3DAC7",
                                backgroundColor: "#EEFFEA",
                                color: "#43B224",
                                paddingLeft: 17,
                                paddingRight: 17,
                              }}
                            >
                              {e.Status}
                            </Button>
                          </td>
                        ) : e.Status == "Started" ? (
                          <td style={{ padding: "5px 10px" }}>
                            <Button
                              outline
                              style={{
                                borderColor: "#FFCA99",
                                backgroundColor: "#FFF8E1",
                                color: "#886E1D",
                                paddingLeft: 24,
                                paddingRight: 24,
                              }}
                            >
                              {e.Status}
                            </Button>
                          </td>
                        ) : (
                          <td style={{ padding: "5px 10px" }}>
                            <Button
                              outline
                              style={{
                                borderColor: "#FFD7D7",
                                backgroundColor: " #FFF4F3",
                                color: "#D42216",
                              }}
                            >
                              {e.Status}
                            </Button>
                          </td>
                        )}
                      </tr>
                    ))}
                  </tbody>
                )}
              </table>
            </div>
          </Paper>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ fontSize: 14, fontWeight: 500 }}>
              Showing{" "}
              <select
                style={{
                  height: 30,
                  width: 100,
                  borderRadius: 5,
                  backgroundColor: "#F5F5F5",
                  border: "1px solid lavender",
                }}
                value={itemsPerPage}
                onChange={(e) => {
                  setItemsPerPage(e.target.value);
                  setActivePage(1);
                }}
              >
                {entriesCount.map((count, ind) => (
                  <option
                    key={ind}
                    selected={
                      !data?.userData ? 0 : itemsPerPage === count ? count : ""
                    }
                  >
                    {count}
                  </option>
                ))}
              </select>{" "}
              out of {data?.userData.length} entries
            </div>
            <div
              style={{
                marginRight: 0,
              }}
            >
              <Pagination
                {...paginationClasses}
                activePage={activePage}
                itemsCountPerPage={itemsPerPage}
                totalItemsCount={data?.userData.length}
                pageRangeDisplayed={5}
                onChange={(pageNo) => setActivePage(pageNo)}
              />
            </div>
          </div>
        </div>
      ) : (
        <ChooseOption
          centerContent={
            data?.userData
              ? "Assessment yet to be started...."
              : "Choose options from dropdown above..."
          }
        />
      )}
    </div>
    )}
    </>
  );
}

const mapStateToProps = (state) => ({
  Customers: state.StudentAndCustomerReducer.Customers,
});

export default connect(mapStateToProps, null)(withRouter(UserAssessmentMarks));
