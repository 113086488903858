import { Tooltip, Grid, IconButton } from '@material-ui/core';
import { GestureOutlined, CreateOutlined, ExposureOutlined, } from '@material-ui/icons';
import TabbedSection from '../../components/tabbed-section/tabbed-section';
import Calculator from './calculator/calculator';
import Canvas from './canvas/canvas';
import Notes from './notes/notes';

export default function WorkSpaceSection({
  activity,
  canView,
  id,
  isLightMode,
  logActivity,
  questionId,
  setIfSectionIsExpanded,
  setWorkspaceTab,
  workspaceTab,
  ...props
}) {
  return (
    <TabbedSection
      available={canView}
      disabledText="Workspace is disabled"
      noContentTags={canView ? (workspaceTab === 0) : true}
      noTabs
      questionId={ questionId}
      rightSection={canView && (
        <Grid style={{ color: '#fff', marginLeft: 'auto' }}>
          <Tooltip title="Notes">
            <IconButton
              color="inherit"
              size="small"
              onClick={() => {
                setWorkspaceTab(0);
              }}>
              <CreateOutlined />
            </IconButton>
          </Tooltip>
          &nbsp;&nbsp;&nbsp;
          <Tooltip title="Canvas">
            <IconButton
              color="inherit"
              size="small"
              onClick={() => {
                setWorkspaceTab(1);
                setIfSectionIsExpanded(true);
              }}>
              <GestureOutlined />
            </IconButton>
          </Tooltip>
          &nbsp;&nbsp;&nbsp;
          <Tooltip title="Calculator">
            <IconButton
              color="inherit"
              size="small"
              onClick={() => {
                setWorkspaceTab(2);
                setIfSectionIsExpanded(true);
              }}>
              <ExposureOutlined />
            </IconButton>
          </Tooltip>
        </Grid>
      )}
      sectionId="practice-lab-workspace"
      title={canView
        ? `Workspace - ${(workspaceTab === 0)
          ? 'Notes'
          : (workspaceTab === 1)
            ? 'Canvas'
            : 'Calculator'}`
        : 'Workspace'
      }
      visible={canView}
      {...props}>
      {canView
        ? (
          <>
            {(workspaceTab === 0) && (
              <Notes
                id={id}
                isLightMode={isLightMode}
                logActivity={logActivity}
              />
              // <textarea style={{backgroundColor:'#001527', color:'#fff',border:'none', width:'100%', height:'100%', paddingLeft:'1rem', paddingTop:'1rem'}}/>
            )}
            {(workspaceTab === 1) && (
              <Canvas
                id={id}
                isLightMode={isLightMode}
                logActivity={logActivity}
              />
            )}
            {(workspaceTab === 2) && <Calculator />}
          </>
        )
        : null}
    </TabbedSection>
  );
}