export const STUDENT_COMPLETED_ASSESSMENT = (
    <svg width="300" height="300" viewBox="0 0 300 300" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M235.35 161.064L191.712 144.564C190.995 144.293 190.338 143.883 189.78 143.358C189.221 142.833 188.772 142.203 188.457 141.505C188.142 140.806 187.968 140.052 187.945 139.286C187.922 138.52 188.05 137.757 188.322 137.04L211.158 76.6439C211.705 75.196 212.806 74.0248 214.217 73.388C215.627 72.7512 217.234 72.7008 218.682 73.2479L246.36 83.7119L259.428 113.892L242.874 157.692C242.321 159.136 241.219 160.301 239.809 160.933C238.398 161.565 236.795 161.612 235.35 161.064Z" fill="#F5F5F5" />
      <path d="M246.359 83.7119L239.927 100.71C239.675 101.377 239.556 102.087 239.578 102.8C239.6 103.513 239.762 104.214 240.055 104.865C240.348 105.515 240.766 106.101 241.286 106.59C241.805 107.078 242.416 107.459 243.083 107.712L259.427 113.892L246.359 83.7119Z" fill="#EBEBEB" />
      <path d="M239.586 102.288C239.586 102.288 237.816 106.332 241.986 109.488C242.143 109.606 242.308 109.714 242.478 109.812C244.47 111.012 257.574 118.812 257.694 118.494L259.422 113.922L243.078 107.742C243.078 107.742 239.058 106.032 239.586 102.288Z" fill="#E0E0E0" />
      <path d="M236.532 98.634C236.448 98.856 231.864 97.32 226.29 95.214C220.716 93.108 216.264 91.218 216.348 91.014C216.432 90.81 221.016 92.328 226.59 94.434C232.164 96.54 236.616 98.4 236.532 98.634Z" fill="#E0E0E0" />
      <path d="M245.934 113.178C245.85 113.394 238.35 110.778 229.176 107.292C220.002 103.806 212.628 100.83 212.712 100.608C212.796 100.386 220.296 103.008 229.476 106.5C238.656 109.992 246 112.956 245.934 113.178Z" fill="#E0E0E0" />
      <path d="M245.891 122.334C245.807 122.55 237.635 119.664 227.633 115.878C217.631 112.092 209.591 108.852 209.675 108.636C209.759 108.42 217.931 111.306 227.933 115.092C237.935 118.878 245.999 122.112 245.891 122.334Z" fill="#E0E0E0" />
      <path d="M243.029 129.906C242.945 130.122 234.773 127.236 224.771 123.45C214.769 119.664 206.729 116.4 206.813 116.208C206.897 116.016 215.069 118.878 225.071 122.658C235.073 126.438 243.113 129.684 243.029 129.906Z" fill="#E0E0E0" />
      <path d="M240.168 137.478C240.084 137.694 231.906 134.808 221.91 131.022C211.914 127.236 203.868 123.996 203.946 123.78C204.024 123.564 212.202 126.45 222.21 130.23C232.218 134.01 240.246 137.256 240.168 137.478Z" fill="#E0E0E0" />
      <path d="M237.3 145.05C237.222 145.266 229.044 142.374 219.048 138.594C209.052 134.814 201 131.568 201.084 131.352C201.168 131.136 209.34 134.022 219.342 137.802C229.344 141.582 237.384 144.828 237.3 145.05Z" fill="#E0E0E0" />
      <path d="M89.8595 98.3621L32.331 118.508C30.342 119.205 29.2942 121.382 29.9907 123.371L58.8546 205.793C59.5511 207.782 61.7283 208.83 63.7173 208.134L121.246 187.987C123.235 187.291 124.283 185.114 123.586 183.125L94.7223 100.702C94.0257 98.7133 91.8486 97.6655 89.8595 98.3621Z" fill="#EBEBEB" />
      <path d="M90.5966 103.485L34.9482 122.973L63.5562 204.665L119.205 185.177L90.5966 103.485Z" fill="#E0E0E0" />
      <path d="M89.6015 103.101L33.9531 122.589L62.0158 202.723L117.664 183.235L89.6015 103.101Z" fill="#FAFAFA" />
      <path d="M117.654 183.234L89.5197 103.128L89.6217 103.176L33.9837 122.67L34.0377 122.562C44.5257 152.658 54.2877 180.456 62.0817 202.698L61.9917 202.656L117.66 183.234L62.0337 202.8H61.9677V202.734C54.1677 180.492 44.4357 152.694 33.8877 122.604V122.52H33.9657L89.6097 103.044H89.6877V103.116L117.654 183.234Z" fill="#E0E0E0" />
      <path d="M97.9199 190.146C97.9199 190.146 110.31 179.694 112.458 168.81C111.215 170.535 109.635 171.991 107.814 173.089C105.993 174.187 103.968 174.905 101.862 175.2C101.862 175.2 101.616 186.6 97.9199 190.146Z" fill="#E0E0E0" />
      <path d="M52.9698 140.444L50.0421 141.47C48.9725 141.844 48.4091 143.015 48.7837 144.085L49.8089 147.012C50.1835 148.082 51.3542 148.645 52.4238 148.271L55.3515 147.246C56.4211 146.871 56.9845 145.7 56.61 144.631L55.5847 141.703C55.2101 140.633 54.0394 140.07 52.9698 140.444Z" fill="#EBEBEB" />
      <path d="M52.6133 145.488C52.5929 145.499 52.5703 145.504 52.5473 145.504C52.5244 145.504 52.5017 145.499 52.4813 145.488L51.1373 144.708C51.1048 144.683 51.0824 144.647 51.0745 144.607C51.0665 144.567 51.0735 144.525 51.0942 144.49C51.1148 144.455 51.1477 144.428 51.1865 144.415C51.2254 144.402 51.2677 144.404 51.3053 144.42L52.5053 145.104L53.9213 142.104C53.942 142.064 53.9768 142.033 54.019 142.018C54.0612 142.002 54.1077 142.003 54.1493 142.02C54.1696 142.03 54.1878 142.043 54.2028 142.06C54.2178 142.077 54.2292 142.096 54.2365 142.118C54.2438 142.139 54.2467 142.161 54.2451 142.184C54.2436 142.206 54.2375 142.228 54.2273 142.248L52.7333 145.44C52.7179 145.456 52.6992 145.469 52.6785 145.477C52.6578 145.485 52.6356 145.489 52.6133 145.488Z" fill="white" />
      <path d="M91.0381 128.04C91.0381 128.088 83.8381 130.662 74.9041 133.788C65.9701 136.914 58.7041 139.41 58.7041 139.368C58.7041 139.326 65.9041 136.746 74.8441 133.62C83.7841 130.494 91.0261 127.998 91.0381 128.04Z" fill="#E0E0E0" />
      <path d="M74.0695 139.572C74.0695 139.572 73.8835 139.656 73.5475 139.788L72.0955 140.328C70.8955 140.772 69.1675 141.378 67.2955 142.038C65.4235 142.698 63.6955 143.286 62.4955 143.7L61.0255 144.186C60.8475 144.251 60.6651 144.303 60.4795 144.342C60.6473 144.254 60.8218 144.18 61.0015 144.12L62.4535 143.586C63.6535 143.136 65.3815 142.53 67.2535 141.87C69.1255 141.21 70.8535 140.628 72.0535 140.208L73.5235 139.722C73.7008 139.656 73.8835 139.606 74.0695 139.572Z" fill="#E0E0E0" />
      <path d="M91.7999 130.248C91.7999 130.29 84.6 132.864 75.66 135.99C66.72 139.116 59.46 141.618 59.46 141.57C59.46 141.522 66.66 138.954 75.606 135.828C84.552 132.702 91.7999 130.2 91.7999 130.248Z" fill="#E0E0E0" />
      <path d="M57.1495 152.383L54.2218 153.409C53.1522 153.783 52.5888 154.954 52.9634 156.024L53.9886 158.951C54.3632 160.021 55.5339 160.584 56.6035 160.21L59.5312 159.184C60.6008 158.81 61.1642 157.639 60.7897 156.57L59.7644 153.642C59.3898 152.572 58.2191 152.009 57.1495 152.383Z" fill="#EBEBEB" />
      <path d="M56.7959 157.428C56.7744 157.439 56.7508 157.444 56.7269 157.444C56.703 157.444 56.6794 157.439 56.6579 157.428L55.3199 156.648C55.3005 156.637 55.2835 156.623 55.2698 156.606C55.2562 156.588 55.2463 156.568 55.2407 156.547C55.2351 156.526 55.2339 156.503 55.2372 156.481C55.2405 156.46 55.2482 156.439 55.2599 156.42C55.2704 156.401 55.2849 156.384 55.3022 156.37C55.3196 156.356 55.3396 156.346 55.3609 156.341C55.3823 156.335 55.4046 156.334 55.4264 156.337C55.4482 156.341 55.4692 156.348 55.4879 156.36L56.6879 157.044L58.1039 154.014C58.1234 153.974 58.1571 153.944 58.1983 153.928C58.2395 153.913 58.2851 153.913 58.3259 153.93C58.3664 153.948 58.3981 153.982 58.4138 154.024C58.4296 154.065 58.4281 154.111 58.4099 154.152L56.9159 157.35C56.9026 157.371 56.885 157.388 56.8643 157.402C56.8436 157.415 56.8203 157.424 56.7959 157.428Z" fill="white" />
      <path d="M95.2199 139.98C95.2199 140.028 88.0199 142.596 79.0799 145.728C70.1399 148.86 62.8799 151.35 62.8799 151.308C62.8799 151.266 70.0799 148.686 79.0199 145.56C87.9599 142.434 95.2079 139.938 95.2199 139.98Z" fill="#E0E0E0" />
      <path d="M78.2521 151.512C78.2521 151.512 78.0661 151.596 77.7241 151.728L76.2781 152.268C75.0781 152.712 73.3501 153.318 71.4781 153.978C69.6061 154.638 67.8781 155.226 66.6781 155.64L65.2081 156.126C65.0302 156.191 64.8477 156.244 64.6621 156.282C64.8292 156.193 65.0039 156.119 65.1841 156.06L66.6361 155.526C67.8361 155.076 69.5641 154.47 71.4361 153.81C73.3081 153.15 75.0361 152.568 76.2361 152.148L77.7061 151.662C77.8835 151.597 78.0661 151.546 78.2521 151.512Z" fill="#E0E0E0" />
      <path d="M95.9992 142.2C95.9992 142.242 88.7992 144.816 79.8592 147.942C70.9192 151.068 63.6592 153.57 63.6592 153.522C63.6592 153.474 70.8592 150.906 79.7992 147.78C88.7392 144.654 95.9992 142.14 95.9992 142.2Z" fill="#E0E0E0" />
      <path d="M61.3351 164.322L58.4074 165.347C57.3378 165.722 56.7743 166.892 57.1489 167.962L58.1742 170.89C58.5487 171.959 59.7195 172.523 60.7891 172.148L63.7167 171.123C64.7863 170.748 65.3498 169.578 64.9752 168.508L63.9499 165.58C63.5754 164.511 62.4047 163.947 61.3351 164.322Z" fill="#EBEBEB" />
      <path d="M60.9775 169.368C60.956 169.379 60.9324 169.384 60.9085 169.384C60.8846 169.384 60.861 169.379 60.8395 169.368L59.4955 168.6C59.4736 168.591 59.454 168.577 59.4378 168.559C59.4217 168.542 59.4095 168.521 59.402 168.498C59.3946 168.476 59.392 168.452 59.3945 168.428C59.3969 168.404 59.4044 168.382 59.4164 168.361C59.4284 168.34 59.4447 168.323 59.4641 168.309C59.4835 168.295 59.5056 168.285 59.529 168.281C59.5523 168.276 59.5764 168.277 59.5996 168.282C59.6228 168.287 59.6446 168.298 59.6635 168.312L60.8635 168.996L62.2795 165.966C62.2879 165.945 62.3005 165.926 62.3165 165.91C62.3325 165.894 62.3516 165.881 62.3726 165.873C62.3937 165.864 62.4162 165.86 62.4389 165.86C62.4616 165.861 62.4839 165.866 62.5046 165.875C62.5253 165.885 62.5438 165.898 62.5592 165.915C62.5745 165.931 62.5864 165.951 62.5939 165.972C62.6015 165.994 62.6046 166.016 62.6032 166.039C62.6017 166.062 62.5957 166.084 62.5855 166.104L61.0915 169.302C61.0622 169.336 61.0219 169.36 60.9775 169.368Z" fill="white" />
      <path d="M99.4015 151.92C99.4015 151.968 92.2015 154.536 83.2615 157.668C74.3215 160.8 67.0615 163.29 67.0615 163.242C67.0615 163.194 74.2615 160.626 83.2075 157.5C92.1535 154.374 99.3835 151.878 99.4015 151.92Z" fill="#E0E0E0" />
      <path d="M82.4339 163.446C82.2636 163.533 82.0871 163.607 81.9059 163.668L80.4539 164.208C79.2539 164.652 77.5319 165.258 75.6539 165.918C73.7759 166.578 72.0539 167.16 70.8539 167.58L69.3839 168.066C69.2057 168.13 69.0232 168.182 68.8379 168.222C69.005 168.132 69.1796 168.058 69.3599 168L70.8119 167.46C72.0119 167.016 73.7399 166.41 75.6119 165.75C77.4839 165.09 79.2119 164.508 80.4119 164.088L81.8759 163.602C82.0588 163.539 82.2451 163.487 82.4339 163.446Z" fill="#E0E0E0" />
      <path d="M100.199 154.128C100.199 154.17 92.9994 156.744 84.0594 159.87C75.1194 162.996 67.8594 165.498 67.8594 165.45C67.8594 165.402 75.0594 162.834 83.9994 159.702C92.9394 156.57 100.157 154.08 100.199 154.128Z" fill="#E0E0E0" />
      <path d="M65.5147 176.261L62.5871 177.286C61.5175 177.661 60.954 178.831 61.3286 179.901L62.3539 182.829C62.7284 183.898 63.8992 184.462 64.9688 184.087L67.8964 183.062C68.966 182.687 69.5295 181.517 69.1549 180.447L68.1296 177.519C67.7551 176.45 66.5843 175.886 65.5147 176.261Z" fill="#EBEBEB" />
      <path d="M65.1539 181.308C65.1331 181.317 65.1106 181.321 65.0879 181.321C65.0652 181.321 65.0428 181.317 65.0219 181.308L63.6779 180.528C63.6397 180.505 63.6123 180.468 63.6016 180.424C63.5909 180.381 63.5978 180.335 63.6209 180.297C63.644 180.259 63.6813 180.231 63.7246 180.221C63.7679 180.21 63.8137 180.217 63.8519 180.24L65.0519 180.924L66.4679 177.924C66.4863 177.883 66.5199 177.852 66.5615 177.836C66.6032 177.82 66.6493 177.822 66.6899 177.84C66.7294 177.859 66.7601 177.893 66.7757 177.934C66.7912 177.976 66.7906 178.021 66.7739 178.062L65.2739 181.26C65.2416 181.291 65.1986 181.308 65.1539 181.308Z" fill="white" />
      <path d="M103.584 163.86C103.584 163.908 96.3841 166.476 87.4441 169.602C78.5041 172.728 71.2441 175.23 71.2441 175.182C71.2441 175.134 78.4441 172.566 87.3841 169.44C96.3241 166.314 103.566 163.8 103.584 163.86Z" fill="#E0E0E0" />
      <path d="M86.6095 175.386C86.4425 175.475 86.2678 175.549 86.0875 175.608L84.6355 176.148C83.4355 176.592 81.7075 177.198 79.8355 177.858C77.9635 178.518 76.2355 179.1 75.0355 179.52L73.5655 180.006C73.2175 180.114 73.0195 180.168 73.0195 180.162C73.1866 180.072 73.3613 179.998 73.5415 179.94L74.9935 179.4C76.1935 178.956 77.9215 178.35 79.7935 177.69C81.6655 177.03 83.3935 176.448 84.5935 176.028L86.0635 175.542C86.2418 175.477 86.4242 175.425 86.6095 175.386Z" fill="#E0E0E0" />
      <path d="M104.358 166.068C104.358 166.11 97.1576 168.684 88.2176 171.81C79.2776 174.936 72.0176 177.432 72.0176 177.39C72.0176 177.348 79.2176 174.774 88.1576 171.642C97.0976 168.51 104.34 166.02 104.358 166.068Z" fill="#E0E0E0" />
      <path d="M40.2539 114.78L41.7179 118.98C42.1274 120.149 42.9843 121.108 44.1003 121.646C45.2164 122.184 46.5003 122.256 47.6699 121.848L78.4499 111.048C79.0286 110.846 79.5617 110.532 80.0188 110.123C80.476 109.715 80.8481 109.221 81.1139 108.668C81.3797 108.116 81.5341 107.517 81.568 106.905C81.602 106.293 81.515 105.68 81.3119 105.102L79.8479 100.902L40.2539 114.78Z" fill="#E0E0E0" />
      <path d="M60.4617 109.092C59.644 109.379 58.7594 109.418 57.9197 109.203C57.0801 108.987 56.3233 108.528 55.745 107.882C55.1668 107.236 54.7931 106.434 54.6714 105.575C54.5496 104.717 54.6852 103.842 55.0611 103.061C55.4369 102.28 56.036 101.628 56.7826 101.188C57.5292 100.747 58.3897 100.538 59.2551 100.587C60.1205 100.636 60.9519 100.941 61.6442 101.462C62.3364 101.984 62.8582 102.699 63.1437 103.518C63.5255 104.613 63.4576 105.814 62.9548 106.859C62.452 107.904 61.5555 108.707 60.4617 109.092ZM58.1217 102.414C57.6174 102.59 57.1766 102.912 56.8552 103.338C56.5338 103.765 56.3461 104.277 56.316 104.81C56.2858 105.344 56.4145 105.874 56.6858 106.334C56.9571 106.794 57.3587 107.164 57.84 107.395C58.3212 107.627 58.8604 107.711 59.3893 107.636C59.9182 107.562 60.413 107.332 60.8112 106.976C61.2094 106.62 61.4931 106.154 61.6263 105.636C61.7595 105.119 61.7363 104.574 61.5597 104.07C61.323 103.395 60.828 102.841 60.1833 102.53C59.5387 102.22 58.7972 102.178 58.1217 102.414Z" fill="#E0E0E0" />
      <path d="M85.0621 120C85.0621 120.078 76.9741 123 66.9361 126.51C56.8981 130.02 48.7381 132.81 48.7081 132.732C48.6781 132.654 56.7961 129.732 66.8341 126.222C76.8721 122.712 85.0321 119.898 85.0621 120Z" fill="#E0E0E0" />
      <path d="M74.6636 121.47C74.6636 121.548 71.1236 122.874 66.6896 124.428C62.2556 125.982 58.6376 127.2 58.6076 127.092C58.5776 126.984 62.1536 125.688 66.5876 124.134C71.0216 122.58 74.6396 121.392 74.6636 121.47Z" fill="#E0E0E0" />
      <path d="M234.601 243.048C236.81 242.011 239.294 241.71 241.687 242.19C242.971 242.4 244.315 242.79 245.185 243.75C246.055 244.71 246.229 246.414 245.221 247.242C244.57 247.685 243.783 247.882 243.001 247.8C241.123 247.8 239.047 247.446 237.553 248.574C236.695 249.216 236.131 250.284 235.111 250.602C234.597 250.722 234.059 250.669 233.578 250.452C233.098 250.235 232.702 249.866 232.453 249.402C231.968 248.464 231.86 247.375 232.153 246.36C232.279 245.657 232.565 244.993 232.989 244.418C233.414 243.844 233.965 243.375 234.601 243.048Z" fill="#43B224" />
      <path d="M229.633 233.52C230.953 235.398 233.515 234.78 234.703 233.628C235.891 232.476 236.389 230.814 236.845 229.23C237.889 225.6 238.945 221.796 238.171 218.1C237.978 216.99 237.486 215.953 236.749 215.1C236.372 214.677 235.892 214.361 235.355 214.182C234.818 214.003 234.243 213.968 233.689 214.08C232.393 214.422 231.595 215.688 231.013 216.888C229.302 220.446 228.406 224.341 228.391 228.288C228.391 230.124 228.601 232.044 229.633 233.562" fill="#43B224" />
      <path d="M226.014 236.22C226.692 235.335 227.144 234.299 227.33 233.2C227.516 232.101 227.431 230.973 227.082 229.914C226.357 227.806 225.066 225.938 223.35 224.514C221.399 222.778 219.129 221.439 216.666 220.572C215.715 220.188 214.691 220.014 213.666 220.062C213.154 220.091 212.656 220.245 212.217 220.511C211.777 220.777 211.41 221.146 211.146 221.586C210.438 222.93 211.146 224.556 211.842 225.882C213.126 228.223 214.595 230.458 216.234 232.566C217.548 234.264 219.054 235.938 221.064 236.706C223.074 237.474 225.15 237.378 226.212 235.992" fill="#43B224" />
      <path d="M229.548 261.906C229.614 261.627 229.653 261.341 229.662 261.054C229.728 260.454 229.8 259.65 229.89 258.72C230.006 257.57 230.182 256.426 230.418 255.294C230.723 253.917 231.139 252.567 231.66 251.256C232.173 249.947 232.868 248.717 233.724 247.602C234.422 246.694 235.314 245.952 236.334 245.43C237.025 245.087 237.767 244.856 238.53 244.746C238.818 244.736 239.103 244.696 239.382 244.626C239.093 244.595 238.802 244.595 238.512 244.626C237.721 244.69 236.947 244.893 236.226 245.226C235.152 245.732 234.209 246.479 233.472 247.41C232.581 248.543 231.861 249.8 231.336 251.142C230.804 252.471 230.391 253.845 230.1 255.246C229.88 256.392 229.727 257.55 229.644 258.714C229.566 259.704 229.542 260.514 229.53 261.06C229.503 261.342 229.509 261.626 229.548 261.906Z" fill="#263238" />
      <path d="M229.704 260.113C229.722 259.981 229.722 259.848 229.704 259.716V258.601C229.704 257.617 229.704 256.201 229.752 254.401C229.842 250.879 230.142 247.093 230.73 241.753C231.282 236.731 232.026 230.887 232.812 227.089C232.98 226.225 233.148 225.444 233.31 224.766C233.472 224.088 233.592 223.495 233.718 223.021C233.844 222.547 233.91 222.204 233.982 221.916C234.015 221.786 234.039 221.654 234.054 221.521C233.994 221.639 233.948 221.764 233.916 221.893C233.826 222.181 233.718 222.541 233.586 222.985C233.454 223.429 233.286 224.041 233.118 224.719C232.95 225.397 232.752 226.171 232.566 227.035C231.605 231.533 230.882 236.079 230.4 240.655C229.8 246.007 229.548 250.855 229.53 254.407C229.53 256.171 229.53 257.599 229.59 258.607C229.59 259.069 229.638 259.447 229.656 259.741C229.661 259.866 229.677 259.99 229.704 260.113Z" fill="#263238" />
      <path d="M229.729 250.716C229.762 250.317 229.762 249.916 229.729 249.516C229.661 248.441 229.538 247.37 229.363 246.306C229.105 244.728 228.757 243.165 228.319 241.626C227.811 239.733 227.157 237.881 226.363 236.088C225.005 232.784 223.059 229.753 220.621 227.142C220.195 226.696 219.743 226.277 219.265 225.888C219.061 225.708 218.875 225.534 218.665 225.396L218.143 225.036C217.828 224.795 217.493 224.582 217.141 224.4C218.293 225.3 219.385 226.275 220.411 227.316C222.776 229.945 224.672 232.961 226.015 236.232C226.793 238.014 227.45 239.846 227.983 241.716C228.434 243.24 228.805 244.786 229.093 246.348C229.339 247.686 229.483 248.748 229.567 249.528C229.591 249.928 229.646 250.325 229.729 250.716Z" fill="#263238" />
      <path d="M220.645 267.294L221.587 280.194H238.165L239.101 267.294H220.645Z" fill="#794DF5" />
      <path d="M241.087 262.206H219.049V267.294H241.087V262.206Z" fill="#794DF5" />
      <path d="M219.049 279.702V281.106H220.081H240.715V279.702H219.049Z" fill="#9574F3" />
      <path d="M221.094 283.59L220.08 281.106H239.928L238.74 283.59H221.094Z" fill="#9574F3" />
      <path d="M238.8 271.506C238.8 271.506 238.8 271.554 238.722 271.626L238.476 271.956L237.51 273.192L237.468 273.246L237.426 273.186L235.056 269.91H235.2L232.86 273.216L232.788 273.318L232.704 273.222L229.938 269.922H230.094L229.944 270.114L227.466 273.216L227.388 273.324L227.31 273.216C226.47 272.016 225.684 270.912 224.97 269.91H225.108L222.234 273.186L222.18 273.246L222.138 273.18C221.76 272.544 221.46 272.034 221.238 271.668L221.01 271.266C220.98 271.221 220.956 271.173 220.938 271.122C220.938 271.122 220.974 271.158 221.034 271.248L221.292 271.638L222.234 273.12H222.144L224.976 269.802L225.048 269.718L225.114 269.808L227.472 273.102H227.31L229.8 270L229.956 269.808L230.034 269.706L230.112 269.802L232.878 273.108H232.722L235.08 269.814L235.146 269.718L235.218 269.814C236.148 271.152 236.946 272.286 237.54 273.132H237.45L238.458 271.932L238.728 271.62L238.8 271.506Z" fill="#E0E0E0" />
      <path d="M239.334 267.204C239.334 267.264 235.182 267.312 230.058 267.312C224.934 267.312 220.782 267.264 220.782 267.204C220.782 267.144 224.934 267.102 230.058 267.102C235.182 267.102 239.334 267.15 239.334 267.204Z" fill="#455A64" />
      <path d="M240.175 281.25C240.175 281.304 235.657 281.352 230.095 281.352C224.533 281.352 220.015 281.304 220.015 281.25C220.015 281.196 224.527 281.142 230.095 281.142C235.663 281.142 240.175 281.19 240.175 281.25Z" fill="#263238" />
      <path d="M76.6803 240.09C76.6323 240.144 76.2543 239.916 75.8343 239.58C75.4143 239.244 75.1083 238.926 75.1563 238.866C75.2043 238.806 75.5763 239.04 75.9963 239.376C76.4163 239.712 76.7223 240.03 76.6803 240.09Z" fill="#263238" />
      <path d="M88.2005 222.906C88.3638 221.306 88.2316 219.689 87.8105 218.136C87.3065 216.606 86.1305 215.208 84.5585 214.878C82.7585 214.506 80.9225 215.64 79.9145 217.176C78.9065 218.712 78.5645 220.572 78.2645 222.378C76.2174 234.857 75.4634 247.514 76.0145 260.148L83.3585 261.18C86.5565 247.998 87.6005 236.472 88.2005 222.906Z" fill="#43B224" />
      <path d="M83.5082 214.8L83.4722 214.92L83.3523 215.25L82.8603 216.522C82.7583 216.798 82.6203 217.122 82.5123 217.44L82.1582 218.55L81.7443 219.828L81.3663 221.286C80.7497 223.694 80.2749 226.137 79.9443 228.6C79.5483 231.36 79.2903 234.42 79.0803 237.636C78.7083 244.074 78.6483 249.918 78.4803 254.142C78.4023 256.26 78.3303 257.97 78.2523 259.146C78.2103 259.746 78.1802 260.172 78.1562 260.508C78.1562 260.646 78.1562 260.766 78.1562 260.856C78.1562 260.946 78.1562 260.976 78.1562 260.976C78.1562 260.976 78.1562 260.94 78.1562 260.856C78.1562 260.772 78.1562 260.646 78.1562 260.502C78.1562 260.172 78.1563 259.716 78.1983 259.14C78.2463 257.94 78.2883 256.254 78.3423 254.136C78.4623 249.936 78.4923 244.068 78.8703 237.624C79.0743 234.402 79.3443 231.336 79.7523 228.564C80.0964 226.108 80.5913 223.676 81.2343 221.28L81.6303 219.822C81.7863 219.374 81.9303 218.948 82.0623 218.544L82.4463 217.434C82.5603 217.098 82.7043 216.798 82.8123 216.522L83.3523 215.274C83.4083 215.142 83.4563 215.034 83.4963 214.95C83.4919 214.9 83.496 214.849 83.5082 214.8Z" fill="#263238" />
      <path d="M84.1861 214.83C84.5689 215.282 84.8731 215.795 85.0861 216.348C85.6477 217.866 85.8748 219.487 85.7521 221.1C85.5601 225.162 85.1941 230.754 84.7141 236.934C84.2341 243.114 83.6881 248.694 83.2561 252.732C83.0341 254.748 82.8481 256.38 82.7101 257.532C82.6381 258.078 82.5841 258.51 82.5421 258.828L82.4941 259.164C82.4969 259.202 82.4969 259.24 82.4941 259.278C82.4941 259.278 82.4941 259.242 82.4941 259.164C82.4941 259.086 82.4941 258.96 82.4941 258.822C82.4941 258.504 82.5601 258.072 82.6081 257.52C82.7221 256.368 82.8781 254.742 83.0701 252.72C83.4541 248.676 83.9641 243.12 84.4501 236.916C84.9361 230.712 85.3261 225.15 85.5661 221.1C85.6968 219.505 85.4924 217.901 84.9661 216.39C84.7528 215.848 84.4917 215.326 84.1861 214.83Z" fill="#263238" />
      <path d="M89.2809 233.844C88.799 233.809 88.3274 233.687 87.8889 233.484C87.4228 233.363 86.982 233.16 86.5869 232.884C87.0694 232.916 87.5417 233.038 87.9789 233.244C88.4455 233.364 88.8866 233.568 89.2809 233.844Z" fill="#263238" />
      <path d="M90.1682 226.356C89.7703 226.674 89.3221 226.924 88.8422 227.094C88.3963 227.341 87.9112 227.51 87.4082 227.592C88.2495 227.023 89.1835 226.605 90.1682 226.356Z" fill="#263238" />
      <path d="M89.7062 220.242C88.9809 220.373 88.2345 220.322 87.5342 220.092C87.8892 219.991 88.2629 219.972 88.6262 220.038C88.9971 220.022 89.3668 220.092 89.7062 220.242Z" fill="#263238" />
      <path d="M82.1222 216.33C82.0622 216.366 81.7381 215.964 81.4081 215.436C81.168 215.131 80.998 214.777 80.9102 214.398C80.9702 214.362 81.2942 214.758 81.6302 215.292C81.9662 215.826 82.2002 216.288 82.1222 216.33Z" fill="#263238" />
      <path d="M79.6201 220.026C79.286 220.146 78.9277 220.183 78.5761 220.134C78.2225 220.168 77.8661 220.115 77.5381 219.978C77.8722 219.858 78.2305 219.821 78.5821 219.87C78.9356 219.836 79.2921 219.89 79.6201 220.026Z" fill="#263238" />
      <path d="M77.8207 227.4C77.4367 227.385 77.0612 227.282 76.7227 227.1C76.3523 226.995 76.01 226.808 75.7207 226.554C76.1047 226.57 76.4802 226.672 76.8187 226.854C77.1891 226.96 77.5314 227.146 77.8207 227.4Z" fill="#263238" />
      <path d="M77.6287 233.634C77.6287 233.706 77.2267 233.856 76.6927 233.964C76.1587 234.072 75.7207 234.114 75.7027 234.042C75.6847 233.97 76.1107 233.82 76.6387 233.712C76.9571 233.605 77.2973 233.578 77.6287 233.634Z" fill="#263238" />
      <path d="M76.8006 243.81C76.8006 243.882 76.3686 244.02 75.8046 244.122C75.4677 244.222 75.1114 244.239 74.7666 244.17C75.0692 243.99 75.4109 243.885 75.7626 243.864C76.0987 243.76 76.4555 243.742 76.8006 243.81Z" fill="#263238" />
      <path d="M76.2006 249.427C76.2366 249.487 75.8646 249.751 75.3726 250.027C74.8806 250.303 74.4546 250.447 74.4246 250.381C74.3946 250.315 74.7606 250.057 75.2526 249.781C75.7446 249.505 76.2006 249.361 76.2006 249.427Z" fill="#263238" />
      <path d="M80.8028 255.798C80.7428 255.834 80.4188 255.39 80.0828 254.802C79.8398 254.466 79.672 254.081 79.5908 253.674C79.6508 253.638 79.9748 254.082 80.3108 254.67C80.5539 255.007 80.7217 255.391 80.8028 255.798Z" fill="#263238" />
      <path d="M82.1284 245.208C82.1764 245.256 81.8164 245.694 81.3244 246.18C80.8324 246.666 80.3884 247.014 80.3344 246.96C80.2804 246.906 80.6464 246.474 81.1384 245.988C81.6304 245.502 82.0744 245.154 82.1284 245.208Z" fill="#263238" />
      <path d="M81.8343 239.646C81.7803 239.7 81.4503 239.424 81.0903 239.046C80.7303 238.668 80.4903 238.302 80.5383 238.254C80.5863 238.206 80.9223 238.476 81.2823 238.854C81.6423 239.232 81.8883 239.598 81.8343 239.646Z" fill="#263238" />
      <path d="M82.7464 232.548C82.8004 232.596 82.5004 233.022 82.0744 233.49C81.6484 233.958 81.2644 234.312 81.2104 234.264C81.1564 234.216 81.4564 233.79 81.8764 233.316C82.2964 232.842 82.6924 232.5 82.7464 232.548Z" fill="#263238" />
      <path d="M83.2561 227.4C83.2021 227.454 82.7881 227.124 82.3201 226.668C81.8521 226.212 81.5221 225.798 81.5701 225.744C81.6181 225.69 82.0381 226.02 82.5061 226.482C82.9741 226.944 83.3041 227.346 83.2561 227.4Z" fill="#263238" />
      <path d="M84.2643 220.428C84.1849 220.807 84.023 221.164 83.7903 221.472C83.4723 222.012 83.1603 222.42 83.1003 222.384C83.0403 222.348 83.2443 221.88 83.5683 221.34C83.8923 220.8 84.1983 220.392 84.2643 220.428Z" fill="#263238" />
      <path d="M86.1548 213.972C86.2208 214.008 86.0348 214.476 85.7408 215.016C85.4468 215.556 85.1408 215.97 85.0928 215.94C85.1499 215.565 85.2914 215.208 85.5068 214.896C85.8008 214.35 86.0888 213.942 86.1548 213.972Z" fill="#263238" />
      <path d="M88.3022 216.907C88.3322 216.973 88.0382 217.177 87.6422 217.369C87.2462 217.561 86.9042 217.657 86.8742 217.591C86.8442 217.525 87.1382 217.321 87.5342 217.129C87.9302 216.937 88.2722 216.841 88.3022 216.907Z" fill="#263238" />
      <path d="M88.351 240.09C87.3543 240.018 86.4104 239.614 85.6689 238.944C86.1361 239.086 86.5883 239.273 87.0189 239.502C87.4819 239.652 87.9282 239.849 88.351 240.09Z" fill="#263238" />
      <path d="M87.4743 244.998C87.4743 245.07 87.0483 245.214 86.4843 245.322C86.1491 245.43 85.7924 245.452 85.4463 245.388C85.7464 245.204 86.0855 245.093 86.4363 245.064C87.0003 244.956 87.4623 244.926 87.4743 244.998Z" fill="#263238" />
      <path d="M86.4 253.416C86.358 253.476 85.8 253.086 85.284 252.438C84.9404 252.068 84.6986 251.615 84.582 251.124C84.9173 251.481 85.2205 251.866 85.488 252.276C85.8264 252.627 86.1317 253.009 86.4 253.416Z" fill="#263238" />
      <path d="M74.5861 257.268C74.5381 257.214 74.8381 256.878 75.2521 256.524C75.6661 256.17 76.0441 255.924 76.0921 255.984C76.1401 256.044 75.8401 256.368 75.4261 256.728C75.0121 257.088 74.6341 257.322 74.5861 257.268Z" fill="#263238" />
      <path d="M71.1909 238.896C70.7109 237.402 69.8169 235.662 68.2509 235.638C67.7843 235.663 67.333 235.814 66.9445 236.074C66.5561 236.333 66.2446 236.693 66.0429 237.114C65.6119 237.947 65.4051 238.877 65.4429 239.814C65.6362 246.464 66.7695 253.054 68.8089 259.386H74.5509C74.4813 252.425 73.3482 245.515 71.1909 238.896Z" fill="#43B224" />
      <path d="M60.8167 229.495C60.5327 228.095 59.7868 226.832 58.6988 225.907C57.6108 224.982 56.2436 224.449 54.8167 224.395C52.2787 224.839 51.2947 227.335 50.9947 229.891C50.6947 232.447 51.4867 234.991 52.2727 237.445C54.7847 245.317 57.3007 253.191 59.8207 261.066L66.9607 260.287C64.7287 249.739 63.4327 239.959 60.8167 229.495Z" fill="#43B224" />
      <path d="M68.2508 235.638C68.0653 235.893 67.9026 236.164 67.7648 236.448C67.4689 237.259 67.3762 238.131 67.4948 238.986C67.6028 240.078 67.7288 241.386 67.9148 242.826C68.1008 244.266 68.3588 245.826 68.6828 247.494C69.3308 250.794 70.0628 253.746 70.5248 255.894C70.7588 256.968 70.9388 257.844 71.0468 258.45C71.0948 258.732 71.1368 258.96 71.1668 259.152C71.1968 259.344 71.2028 259.398 71.1668 259.398C71.1357 259.323 71.1116 259.244 71.0948 259.164L70.9268 258.474C70.7708 257.832 70.5668 256.974 70.3268 255.936C69.8168 253.788 69.0548 250.842 68.4068 247.536C68.0828 245.88 67.8068 244.296 67.6568 242.844C67.5608 242.124 67.5008 241.428 67.4408 240.786C67.3808 240.144 67.3208 239.538 67.2908 238.986C67.1883 238.106 67.3122 237.213 67.6508 236.394C67.7936 236.102 67.9984 235.844 68.2508 235.638Z" fill="#263238" />
      <path d="M54.9484 224.19C54.6303 224.553 54.3414 224.94 54.0844 225.348C53.4273 226.489 53.0548 227.771 52.9984 229.086C52.9686 231.036 53.2667 232.977 53.8804 234.828C54.4804 236.952 55.1944 239.286 55.9204 241.728C57.3424 246.624 58.6264 251.052 59.5684 254.286L60.6484 258.102C60.7684 258.534 60.8585 258.876 60.9305 259.14C60.9697 259.259 60.9958 259.382 61.0084 259.506C60.9534 259.395 60.9092 259.278 60.8764 259.158C60.7984 258.894 60.6844 258.558 60.5524 258.126C60.2704 257.208 59.8744 255.918 59.3944 254.34L55.6684 241.8C54.9484 239.346 54.2704 237 53.6464 234.888C53.0352 233.011 52.7531 231.042 52.8124 229.068C52.8898 227.728 53.3024 226.428 54.0124 225.288C54.2617 224.873 54.578 224.502 54.9484 224.19Z" fill="#263238" />
      <path d="M65.43 259.524C65.3696 259.414 65.3155 259.299 65.268 259.182C65.178 258.948 65.028 258.618 64.866 258.174C64.524 257.298 64.086 256.008 63.666 254.388C63.246 252.768 62.766 250.788 62.334 248.628C61.902 246.468 61.47 244.05 60.99 241.53C60.51 239.01 59.946 236.64 59.304 234.522C58.7471 232.639 58.0512 230.8 57.222 229.02C56.6584 227.823 56.0149 226.664 55.296 225.552C55.05 225.156 54.828 224.862 54.696 224.658C54.6209 224.556 54.5546 224.448 54.498 224.334C54.586 224.424 54.6663 224.52 54.738 224.622C54.888 224.826 55.122 225.102 55.38 225.498C56.1389 226.592 56.8109 227.744 57.39 228.942C58.2507 230.718 58.967 232.56 59.532 234.45C60.2097 236.764 60.7664 239.111 61.2 241.482C61.674 243.996 62.118 246.396 62.526 248.586C62.934 250.776 63.366 252.714 63.798 254.34C64.23 255.966 64.632 257.256 64.944 258.138C65.088 258.588 65.226 258.924 65.304 259.158C65.3597 259.275 65.4019 259.398 65.43 259.524Z" fill="#263238" />
      <path d="M52.4281 236.268C52.4281 236.334 52.0561 236.49 51.5581 236.61C51.0601 236.73 50.6461 236.772 50.6341 236.7C50.6221 236.628 51.0001 236.478 51.4981 236.358C51.9961 236.238 52.4101 236.196 52.4281 236.268Z" fill="#263238" />
      <path d="M51.6725 231.396C51.2824 231.506 50.877 231.551 50.4725 231.528C50.0733 231.585 49.6675 231.572 49.2725 231.492C49.6461 231.314 50.0595 231.236 50.4725 231.264C50.8772 231.205 51.2902 231.251 51.6725 231.396Z" fill="#263238" />
      <path d="M52.4769 226.944C52.4289 227.004 52.0389 226.752 51.6129 226.386C51.1869 226.02 50.8689 225.678 50.9169 225.624C50.9649 225.57 51.3489 225.816 51.7809 226.182C52.2129 226.548 52.5249 226.89 52.4769 226.944Z" fill="#263238" />
      <path d="M54.6547 222.828C54.7267 222.828 54.7027 223.194 54.6967 223.608C54.6907 224.022 54.6967 224.376 54.6067 224.388C54.5167 224.4 54.4207 224.052 54.4327 223.602C54.4447 223.152 54.6007 222.81 54.6547 222.828Z" fill="#263238" />
      <path d="M59.1007 224.766C59.1607 224.808 58.9867 225.162 58.7107 225.552C58.4347 225.942 58.1647 226.23 58.1107 226.188C58.0567 226.146 58.2247 225.798 58.4947 225.402C58.7647 225.006 59.0407 224.724 59.1007 224.766Z" fill="#263238" />
      <path d="M61.9864 229.8C61.9864 229.872 61.6444 230.082 61.1644 230.28C60.6844 230.478 60.2644 230.58 60.2404 230.514C60.2164 230.448 60.5824 230.232 61.0624 230.04C61.5424 229.848 61.9624 229.716 61.9864 229.8Z" fill="#263238" />
      <path d="M63.738 234.942C63.4205 235.218 63.0275 235.391 62.61 235.44C62.2101 235.57 61.7818 235.584 61.374 235.482C61.759 235.33 62.1625 235.229 62.574 235.182C62.9508 235.055 63.3417 234.975 63.738 234.942Z" fill="#263238" />
      <path d="M55.6981 233.478C55.6321 233.478 55.6981 233.1 55.8121 232.68C55.9261 232.26 56.1061 231.942 56.1721 231.966C56.2381 231.99 56.1721 232.344 56.0581 232.764C55.9441 233.184 55.8001 233.502 55.6981 233.478Z" fill="#263238" />
      <path d="M56.8203 239.958C56.8446 239.58 56.9554 239.213 57.1443 238.884C57.3903 238.32 57.6423 237.888 57.7083 237.918C57.684 238.297 57.5732 238.664 57.3843 238.992C57.1383 239.556 56.8863 240 56.8203 239.958Z" fill="#263238" />
      <path d="M54.0483 241.734C53.6948 241.726 53.3462 241.648 53.0223 241.506C52.4763 241.332 52.0323 241.176 52.0503 241.104C52.4075 241.058 52.7703 241.11 53.1003 241.254C53.6583 241.428 54.0783 241.668 54.0483 241.734Z" fill="#263238" />
      <path d="M55.6449 247.014C55.6869 247.074 55.3809 247.362 54.9669 247.662C54.5529 247.962 54.1689 248.154 54.1269 248.1C54.0849 248.046 54.3909 247.746 54.8109 247.446C55.2309 247.146 55.6029 246.954 55.6449 247.014Z" fill="#263238" />
      <path d="M56.9284 252.282C56.9284 252.348 56.5684 252.504 56.0824 252.618C55.5964 252.732 55.1944 252.774 55.1764 252.708C55.1584 252.642 55.5364 252.486 56.0224 252.366C56.5084 252.246 56.9104 252.21 56.9284 252.282Z" fill="#263238" />
      <path d="M63.8829 239.346C63.9189 239.406 63.5949 239.664 63.1269 239.838C62.6588 240.012 62.2388 240.036 62.2268 239.97C62.2148 239.904 62.5868 239.766 63.0309 239.592C63.4749 239.418 63.8469 239.28 63.8829 239.346Z" fill="#263238" />
      <path d="M64.8726 247.536C64.8426 247.602 64.4586 247.488 64.0146 247.278C63.5706 247.068 63.2346 246.846 63.2646 246.78C63.2946 246.714 63.6846 246.834 64.1286 247.038C64.5725 247.242 64.9086 247.47 64.8726 247.536Z" fill="#263238" />
      <path d="M66.6301 253.404C66.2327 253.384 65.8441 253.28 65.4901 253.098C64.8901 252.864 64.4281 252.618 64.4521 252.546C64.8475 252.567 65.2341 252.671 65.5861 252.852C66.1861 253.086 66.6541 253.332 66.6301 253.404Z" fill="#263238" />
      <path d="M57.21 258C57.174 257.934 57.642 257.622 58.182 257.196C58.722 256.77 59.142 256.386 59.196 256.44C59.25 256.494 58.908 256.956 58.344 257.4C58.0302 257.703 57.637 257.911 57.21 258Z" fill="#263238" />
      <path d="M61.8425 255C61.7705 255 61.6685 254.616 61.4885 254.16C61.3085 253.704 61.1285 253.338 61.1885 253.296C61.2485 253.254 61.5485 253.566 61.7345 254.064C61.9205 254.562 61.9145 255 61.8425 255Z" fill="#263238" />
      <path d="M60.6002 247.962C60.5582 248.022 60.2582 247.878 59.9282 247.644C59.5982 247.41 59.3702 247.17 59.4122 247.11C59.4542 247.05 59.7542 247.194 60.0842 247.428C60.4142 247.662 60.6602 247.902 60.6002 247.962Z" fill="#263238" />
      <path d="M71.4005 236.91C71.4425 236.97 71.1485 237.258 70.7465 237.552C70.3445 237.846 69.9845 238.044 69.9425 237.984C69.9005 237.924 70.1885 237.642 70.5905 237.342C70.9925 237.042 71.4005 236.85 71.4005 236.91Z" fill="#263238" />
      <path d="M73.05 241.362C73.05 241.434 72.6 241.53 72.036 241.47C71.472 241.41 71.046 241.218 71.064 241.152C71.082 241.086 71.526 241.152 72.066 241.206C72.606 241.26 73.044 241.29 73.05 241.362Z" fill="#263238" />
      <path d="M66.0483 242.364C66.0483 242.43 65.6463 242.58 65.0943 242.616C64.5423 242.652 64.1103 242.562 64.1163 242.49C64.1223 242.418 64.5543 242.394 65.0823 242.358C65.6103 242.322 66.0363 242.292 66.0483 242.364Z" fill="#263238" />
      <path d="M66.6541 238.153C66.6541 238.225 66.2221 238.237 65.7541 238.015C65.2861 237.793 65.0161 237.475 65.0641 237.415C65.1121 237.355 65.4361 237.583 65.8621 237.775C66.2881 237.967 66.6601 238.081 66.6541 238.153Z" fill="#263238" />
      <path d="M73.6502 246.144C73.6142 246.204 73.1762 246 72.6722 245.682C72.1682 245.364 71.7962 245.082 71.8382 244.998C71.8802 244.914 72.3122 245.148 72.8162 245.46C73.3202 245.772 73.6922 246.084 73.6502 246.144Z" fill="#263238" />
      <path d="M90.7328 258.636H53.3828V265.254H90.7328V258.636Z" fill="#794DF5" />
      <path d="M56.1729 263.946L60.7149 283.728H82.9749L87.9429 263.946H56.1729Z" fill="#794DF5" />
      <path d="M90.714 265.266C90.714 265.338 82.314 265.398 71.898 265.398C61.482 265.398 53.082 265.338 53.082 265.266C53.082 265.194 61.482 265.134 71.898 265.134C82.314 265.134 90.714 265.2 90.714 265.266Z" fill="#263238" />
      <path d="M69.9426 242.508C69.8826 242.544 69.6246 242.25 69.3726 241.842C69.1206 241.434 68.9646 241.074 69.0246 241.038C69.0846 241.002 69.3426 241.296 69.5946 241.704C69.8466 242.112 70.0026 242.466 69.9426 242.508Z" fill="#263238" />
      <path d="M69.954 247.83C69.936 247.485 70.0041 247.141 70.152 246.828C70.326 246.294 70.53 245.88 70.596 245.904C70.6145 246.252 70.5464 246.598 70.398 246.912C70.2 247.44 70.026 247.854 69.954 247.83Z" fill="#263238" />
      <path d="M71.7789 253.422C71.7129 253.458 71.4369 253.008 71.1789 252.426C70.9719 252.089 70.8409 251.71 70.7949 251.316C70.8609 251.28 71.1369 251.73 71.3949 252.312C71.6019 252.65 71.733 253.029 71.7789 253.422Z" fill="#263238" />
      <path d="M67.8362 257.922C67.7822 257.874 68.0042 257.532 68.3342 257.148C68.6642 256.764 68.9702 256.5 69.0302 256.548C69.0902 256.596 68.8622 256.944 68.5322 257.322C68.2022 257.7 67.8902 258 67.8362 257.922Z" fill="#263238" />
      <path d="M67.0085 249.756C67.0085 249.828 66.6365 249.822 66.2045 249.756C65.7725 249.69 65.4366 249.564 65.4486 249.492C65.4606 249.42 65.8206 249.426 66.2466 249.492C66.6726 249.558 67.0205 249.684 67.0085 249.756Z" fill="#263238" />
      <path d="M196.95 213.144H108.378V283.776H196.95V213.144Z" fill="#E0E0E0" />
      <g opacity="0.3">
        <path opacity="0.3" d="M114.828 213.288C125.256 217.008 136.758 217.806 147.948 218.016C150.27 218.058 152.616 218.052 154.86 218.616C157.104 219.18 159.27 220.458 160.422 222.468C161.676 224.658 161.544 227.4 160.83 229.818C160.116 232.236 158.886 234.474 157.998 236.832C156.426 241.004 155.945 245.508 156.6 249.918C156.802 251.797 157.434 253.603 158.448 255.198C158.962 255.99 159.654 256.651 160.468 257.128C161.283 257.606 162.198 257.886 163.14 257.946C166.506 257.994 168.81 254.688 170.478 251.76C177.324 239.76 184.728 226.14 189.108 213.042L114.828 213.288Z" fill="black" />
      </g>
      <path d="M138.557 283.398C138.467 283.398 138.401 267.744 138.401 248.436C138.401 229.128 138.467 213.468 138.557 213.468C138.647 213.468 138.713 229.122 138.713 248.436C138.713 267.75 138.641 283.398 138.557 283.398Z" fill="#455A64" />
      <path d="M174.852 160.944C174.588 159.18 169.32 153.36 169.32 153.36C169.32 153.36 166.692 142.794 165.9 139.68C165.636 138.78 165.25 137.92 164.754 137.124C162.891 134.021 160.242 131.465 157.074 129.714L149.784 125.616L148.044 121.74L130.536 112.74L125.79 121.14C125.79 121.14 114.264 124.8 112.74 127.398C111.216 129.996 113.496 165.078 113.496 165.078C113.496 165.078 108.696 185.31 107.658 189.498C106.62 193.686 107.982 198.678 107.982 198.678L116.568 187.908C119.238 184.56 125.094 183.018 129.3 183.078L165.246 183.24L163.8 173.172L192.882 178.8C192.882 178.8 197.88 169.044 198.282 168.852C198.684 168.66 174.852 160.944 174.852 160.944Z" fill="#794DF5" />
      <path d="M129.792 124.41C129.54 124.152 131.82 131.862 134.688 139.716C136.23 143.96 137.016 148.442 137.01 152.958V181.398L154.242 180.228L151.668 161.496C151.668 161.496 149.568 149.412 148.98 144.816C148.44 140.616 146.58 129.216 146.58 129.216C146.58 129.216 137.01 131.76 129.792 124.41Z" fill="white" />
      <path d="M168.036 168C168.009 167.855 168.009 167.707 168.036 167.562C168.075 167.17 168.152 166.782 168.264 166.404C168.628 165.142 169.305 163.992 170.232 163.062C171.164 162.134 172.312 161.454 173.574 161.082C173.952 160.97 174.339 160.893 174.732 160.854C174.874 160.827 175.021 160.827 175.164 160.854C175.164 160.908 174.564 160.974 173.64 161.292C172.441 161.703 171.351 162.384 170.456 163.282C169.561 164.179 168.882 165.27 168.474 166.47C168.156 167.4 168.09 168 168.036 168Z" fill="#263238" />
      <path d="M168.924 161.184C169.724 160.714 170.63 160.452 171.558 160.422C172.477 160.295 173.413 160.406 174.276 160.746C174.276 160.842 173.076 160.608 171.582 160.746C170.088 160.884 168.96 161.274 168.924 161.184Z" fill="#263238" />
      <g opacity="0.3">
        <path opacity="0.3" d="M111.762 176.706L110.262 178.398C111.826 180.329 113.945 181.732 116.334 182.418C118.741 182.929 121.2 183.15 123.66 183.078C124.656 183.126 125.654 183.031 126.624 182.796C127.602 182.514 128.508 181.962 129.522 181.902C130.202 181.905 130.878 182.002 131.532 182.19C132.189 182.377 132.885 182.387 133.548 182.22C133.876 182.131 134.167 181.939 134.378 181.672C134.588 181.406 134.708 181.078 134.718 180.738C127.238 178.552 119.539 177.2 111.762 176.706Z" fill="black" />
      </g>
      <path d="M160.962 178.878L156.762 174.39L148.032 172.2C148.032 172.2 140.064 174.6 139.872 174.6C139.68 174.6 132.936 172.752 132.936 172.752L130.578 181.404L142.728 182.238L156.6 181.68C156.6 181.68 157.326 181.008 156.894 180.546C156.462 180.084 159.216 181.326 159.54 181.338C159.864 181.35 160.488 181.602 160.92 181.086C161.214 180.702 161.61 179.568 160.962 178.878Z" fill="#FFBE9D" />
      <path d="M156.894 180.516C156.422 180.427 155.96 180.294 155.514 180.12C154.674 179.856 153.504 179.52 152.202 179.16C150.9 178.8 149.7 178.56 148.83 178.41C148.354 178.348 147.885 178.237 147.432 178.08C147.912 178.059 148.394 178.095 148.866 178.188C150.013 178.348 151.151 178.573 152.274 178.86C153.398 179.145 154.504 179.498 155.586 179.916C156.045 180.06 156.485 180.261 156.894 180.516Z" fill="#EB996E" />
      <path d="M160.962 178.878C160.308 178.713 159.663 178.512 159.03 178.278C157.83 177.882 156.192 177.39 154.35 176.88C152.508 176.37 150.876 175.788 149.634 175.716C148.965 175.682 148.294 175.708 147.63 175.794C147.792 175.705 147.969 175.644 148.152 175.614C148.642 175.508 149.145 175.468 149.646 175.494C150.93 175.494 152.646 176.04 154.446 176.58C156.246 177.12 157.938 177.618 159.114 178.074C159.714 178.296 160.164 178.5 160.488 178.644C160.657 178.698 160.816 178.777 160.962 178.878Z" fill="#EB996E" />
      <path d="M112.74 127.398C112.74 127.398 106.47 131.76 104.94 138.036C103.41 144.312 96.8038 169.962 98.1598 173.796C99.5158 177.63 125.88 181.398 125.88 181.398L129.006 168.198L115.614 164.532C115.614 164.532 115.788 152.322 115.956 147.264C116.124 142.206 112.74 127.398 112.74 127.398Z" fill="#794DF5" />
      <path d="M128.837 170.184L133.151 171.246L130.553 181.194L126.317 180.516L128.837 170.184Z" fill="white" />
      <path d="M98.0638 172.44C98.0638 172.44 98.0638 172.662 98.1298 173.04C98.2756 173.62 98.5961 174.142 99.0478 174.534C100.194 175.602 102.384 176.49 105.156 176.988C106.422 177.408 107.868 177.738 109.41 178.086C110.952 178.434 112.62 178.788 114.372 179.142C117.882 179.862 121.764 180.618 125.88 181.386L125.7 181.5C125.994 180.3 126.3 178.986 126.63 177.672C127.368 174.672 128.088 171.72 128.766 168.942L128.874 169.122L117 165.756C115.36 165.066 113.586 164.754 111.81 164.844C110.46 164.919 109.133 165.232 107.892 165.768C107.103 166.109 106.349 166.527 105.642 167.016L105.096 167.412C105.036 167.46 104.972 167.502 104.904 167.538C104.904 167.538 104.958 167.478 105.072 167.382C105.186 167.286 105.36 167.136 105.6 166.956C106.297 166.439 107.047 165.999 107.838 165.642C109.087 165.076 110.429 164.74 111.798 164.652C113.606 164.537 115.416 164.836 117.09 165.528L129 168.864L129.144 168.9L129.108 169.044C128.43 171.822 127.716 174.75 126.984 177.78C126.66 179.088 126.348 180.384 126.048 181.602L126.012 181.746H125.868C121.752 180.972 117.876 180.204 114.366 179.472C112.614 179.106 110.952 178.74 109.404 178.38C107.856 178.02 106.404 177.678 105.132 177.24C102.324 176.706 100.134 175.782 98.9758 174.66C98.5166 174.247 98.2013 173.699 98.0758 173.094C98.0326 172.878 98.0285 172.657 98.0638 172.44Z" fill="#263238" />
      <path d="M116.346 165.426C116.31 165.468 115.86 165.054 115.056 164.52C112.95 163.11 110.461 162.383 107.928 162.438C106.956 162.438 106.362 162.57 106.35 162.516C106.852 162.334 107.381 162.235 107.916 162.222C110.519 162.033 113.103 162.785 115.2 164.34C115.639 164.636 116.026 165.003 116.346 165.426Z" fill="#263238" />
      <path d="M118.104 143.946C118.126 144.213 118.126 144.482 118.104 144.75C118.104 145.272 118.05 146.016 117.978 146.94C117.828 148.782 117.558 151.32 117.072 154.104C116.692 156.486 116.136 158.836 115.41 161.136C115.266 161.574 115.11 161.97 114.984 162.336L114.6 163.2C114.487 163.446 114.348 163.679 114.186 163.896C114.261 163.639 114.361 163.389 114.486 163.152C114.588 162.894 114.696 162.6 114.822 162.276C114.948 161.952 115.068 161.532 115.2 161.076C115.855 158.768 116.378 156.424 116.766 154.056C117.246 151.284 117.552 148.758 117.756 146.922C117.846 146.052 117.924 145.326 117.984 144.75C117.991 144.478 118.032 144.208 118.104 143.946Z" fill="#263238" />
      <path d="M133.392 137.148C133.392 137.148 133.332 136.986 133.188 136.704C132.975 136.304 132.663 135.965 132.282 135.72C132 135.526 131.678 135.4 131.34 135.35C131.002 135.3 130.658 135.328 130.332 135.432C129.919 135.573 129.551 135.819 129.262 136.147C128.974 136.474 128.776 136.871 128.688 137.298L128.604 137.724L128.418 137.328C127.716 135.822 127.105 134.275 126.588 132.696C125.671 130.065 125.213 127.297 125.232 124.512C125.232 123.978 125.304 123.498 125.334 123.072C125.364 122.646 125.46 122.292 125.526 122.004C125.589 121.701 125.678 121.404 125.79 121.116C125.585 122.237 125.471 123.372 125.448 124.512C125.497 127.266 125.981 129.996 126.882 132.6C127.4 134.167 128 135.705 128.682 137.208L128.412 137.244C128.516 136.772 128.741 136.336 129.067 135.979C129.392 135.622 129.806 135.356 130.266 135.21C130.623 135.102 131.001 135.079 131.368 135.142C131.736 135.206 132.084 135.354 132.384 135.576C132.774 135.85 133.081 136.225 133.272 136.662C133.324 136.777 133.363 136.898 133.386 137.022C133.398 137.1 133.404 137.148 133.392 137.148Z" fill="#263238" />
      <path d="M148.02 138.498C147.956 138.264 147.91 138.025 147.882 137.784C147.751 137.123 147.709 136.447 147.756 135.774C147.891 134.739 148.407 133.792 149.202 133.116C149.694 132.711 150.311 132.489 150.948 132.486C151.296 132.482 151.64 132.554 151.957 132.696C152.273 132.838 152.556 133.047 152.784 133.308L152.52 133.464L151.95 132.03C150.828 129.204 149.832 126.63 149.124 124.776L148.302 122.562C148.188 122.298 148.101 122.022 148.044 121.74C148.192 121.988 148.316 122.249 148.416 122.52C148.656 123.12 148.962 123.822 149.334 124.698C150.096 126.54 151.134 129.09 152.244 131.898L152.808 133.338L153.174 134.262L152.574 133.488C152.376 133.258 152.13 133.075 151.853 132.95C151.576 132.826 151.276 132.764 150.972 132.768C150.401 132.765 149.846 132.957 149.4 133.314C148.65 133.941 148.15 134.816 147.99 135.78C147.926 136.437 147.938 137.1 148.026 137.754C148.055 138.002 148.053 138.251 148.02 138.498Z" fill="#263238" />
      <path d="M159.15 139.986C159.234 139.986 160.59 149.094 162.18 160.356C163.77 171.618 164.982 180.756 164.898 180.756C164.814 180.756 163.458 171.648 161.868 160.356C160.278 149.064 159.066 139.998 159.15 139.986Z" fill="#263238" />
      <g opacity="0.3">
        <path opacity="0.3" d="M126.768 132.792C126.841 135.125 127.347 137.424 128.262 139.572C128.916 138.762 129.642 137.91 130.662 137.67C131.898 137.376 133.152 138.138 134.046 139.074C133.891 138.138 133.521 137.251 132.966 136.482C132.688 136.104 132.327 135.795 131.911 135.58C131.495 135.364 131.034 135.248 130.566 135.24C130.098 135.251 129.648 135.417 129.286 135.713C128.925 136.009 128.672 136.416 128.568 136.872L126.846 132.834" fill="black" />
      </g>
      <path d="M116.634 139.386C116.58 139.386 116.598 138.756 116.418 137.778C116.171 136.504 115.676 135.29 114.96 134.208C114.556 133.622 114.096 133.077 113.586 132.582C113.163 132.181 112.703 131.822 112.212 131.508C111.366 130.974 110.772 130.77 110.796 130.716C110.943 130.726 111.087 130.763 111.222 130.824C111.39 130.869 111.552 130.931 111.708 131.01C111.915 131.099 112.116 131.203 112.308 131.322C112.829 131.617 113.318 131.967 113.766 132.366C114.304 132.867 114.785 133.427 115.2 134.034C115.943 135.148 116.427 136.415 116.616 137.742C116.675 138.139 116.695 138.541 116.676 138.942C116.676 139.23 116.658 139.386 116.634 139.386Z" fill="#263238" />
      <path d="M151.463 96.6002C151.121 93.3874 149.581 90.4213 147.151 88.2923C144.72 86.1632 141.577 85.0272 138.347 85.1102H137.681C130.511 85.7102 127.019 92.1362 127.823 99.2822L129.791 124.422L130.625 125.442C132.498 127.742 135.078 129.359 137.965 130.039C140.852 130.72 143.883 130.426 146.585 129.204L146.033 121.116C146.033 121.116 152.909 119.868 152.813 112.968C152.771 109.62 152.123 102.768 151.463 96.6002Z" fill="#FFBE9D" />
      <path d="M146.087 121.098C142.886 121.187 139.727 120.354 136.985 118.698C136.985 118.698 139.235 123.612 146.105 122.814L146.087 121.098Z" fill="#EB996E" />
      <path d="M149.28 102.276C149.287 102.532 149.195 102.781 149.022 102.971C148.85 103.161 148.612 103.277 148.356 103.296C148.231 103.308 148.105 103.296 147.984 103.259C147.864 103.222 147.753 103.162 147.656 103.081C147.56 103.001 147.48 102.902 147.422 102.791C147.364 102.679 147.328 102.557 147.318 102.432C147.31 102.175 147.401 101.925 147.574 101.735C147.746 101.545 147.986 101.429 148.242 101.412C148.367 101.399 148.494 101.411 148.614 101.447C148.735 101.484 148.847 101.544 148.943 101.625C149.04 101.705 149.12 101.804 149.178 101.916C149.235 102.028 149.27 102.15 149.28 102.276Z" fill="#263238" />
      <path d="M149.718 98.526C149.64 98.646 148.86 98.73 147.918 99.234C146.976 99.738 146.424 100.368 146.298 100.356C146.172 100.344 146.298 100.182 146.532 99.9C146.891 99.5021 147.317 99.1709 147.792 98.922C148.245 98.6689 148.741 98.504 149.256 98.436C149.61 98.4 149.76 98.466 149.718 98.526Z" fill="#263238" />
      <path d="M139.32 102.798C139.331 103.055 139.241 103.307 139.068 103.498C138.895 103.689 138.653 103.804 138.396 103.818C138.271 103.831 138.144 103.819 138.024 103.783C137.903 103.746 137.791 103.686 137.694 103.605C137.598 103.525 137.518 103.425 137.46 103.314C137.402 103.202 137.368 103.079 137.358 102.954C137.35 102.697 137.441 102.447 137.614 102.257C137.786 102.067 138.026 101.951 138.282 101.934C138.407 101.921 138.534 101.933 138.654 101.969C138.775 102.006 138.887 102.066 138.983 102.147C139.08 102.227 139.16 102.326 139.218 102.438C139.275 102.55 139.31 102.672 139.32 102.798Z" fill="#263238" />
      <path d="M139.199 99.9722C139.061 99.9722 138.419 99.5702 137.399 99.3182C136.379 99.0662 135.533 99.1202 135.443 99.0362C135.353 98.9522 135.551 98.9162 135.917 98.8862C136.452 98.8523 136.989 98.9029 137.507 99.0362C138.01 99.1555 138.488 99.3583 138.923 99.6362C139.163 99.8282 139.235 99.9602 139.199 99.9722Z" fill="#263238" />
      <path d="M143.328 108.21C143.884 108.024 144.46 107.906 145.044 107.856C145.314 107.856 145.566 107.76 145.608 107.574C145.625 107.296 145.555 107.019 145.41 106.782L144.552 104.736C143.352 101.832 142.5 99.4381 142.62 99.3961C142.74 99.3541 143.82 101.664 144.978 104.568C145.266 105.288 145.542 105.972 145.8 106.626C145.977 106.948 146.036 107.321 145.968 107.682C145.93 107.779 145.872 107.866 145.796 107.937C145.72 108.007 145.629 108.06 145.53 108.09C145.382 108.136 145.228 108.163 145.074 108.168C144.496 108.257 143.909 108.271 143.328 108.21Z" fill="#263238" />
      <path d="M139.2 95.8799C139.08 96.1619 138.03 95.9759 136.8 96.0599C135.57 96.1439 134.526 96.4439 134.4 96.1739C134.328 96.0479 134.52 95.7839 134.934 95.5259C135.491 95.2089 136.113 95.0236 136.752 94.9841C137.392 94.9446 138.032 95.0519 138.624 95.2979C139.02 95.5079 139.236 95.7419 139.2 95.8799Z" fill="#263238" />
      <path d="M149.694 96.1139C149.46 96.3599 148.548 96.1139 147.456 96.1139C146.364 96.1139 145.434 96.2999 145.218 96.0479C145.122 95.9279 145.266 95.6819 145.668 95.4479C146.222 95.1573 146.842 95.0147 147.468 95.0339C148.099 95.0296 148.719 95.191 149.268 95.5019C149.664 95.7479 149.796 95.9999 149.694 96.1139Z" fill="#263238" />
      <path d="M153.474 90.468C153.413 90.153 153.239 89.8712 152.985 89.6756C152.731 89.4799 152.414 89.384 152.094 89.406C152.438 89.3076 152.76 89.1425 153.042 88.92C153.181 88.8072 153.286 88.6573 153.345 88.4875C153.403 88.3177 153.412 88.1348 153.372 87.96C153.192 87.426 152.508 87.312 151.968 87.162C151.259 86.956 150.63 86.537 150.168 85.962C149.514 85.134 149.262 84.054 148.692 83.16C148.323 82.5828 147.831 82.0956 147.249 81.7341C146.668 81.3727 146.013 81.1462 145.332 81.0713C144.652 80.9965 143.963 81.0752 143.317 81.3017C142.671 81.5282 142.084 81.8966 141.6 82.38C141.07 81.8358 140.418 81.4258 139.699 81.1841C138.979 80.9425 138.212 80.8761 137.462 80.9905C136.711 81.105 135.999 81.3969 135.384 81.842C134.769 82.2872 134.269 82.8727 133.926 83.55C133.005 83.1661 131.998 83.0368 131.011 83.1755C130.023 83.3143 129.091 83.716 128.312 84.3386C127.533 84.9612 126.936 85.7818 126.583 86.7145C126.23 87.6471 126.134 88.6576 126.306 89.64L130.602 92.274C130.636 91.9472 130.74 91.6316 130.906 91.348C131.072 91.0644 131.296 90.8192 131.564 90.6286C131.832 90.4381 132.137 90.3065 132.459 90.2426C132.781 90.1787 133.113 90.1839 133.434 90.258C134.73 90.636 135.558 92.136 136.902 92.238C137.952 92.316 138.846 91.506 139.86 91.236C141.09 90.906 142.392 91.398 143.502 92.022C144.612 92.646 145.674 93.426 146.91 93.738C148.386 94.0339 149.918 93.8511 151.284 93.216C151.89 93.0033 152.442 92.6614 152.904 92.214C153.131 91.9894 153.301 91.7138 153.4 91.4101C153.499 91.1064 153.525 90.7834 153.474 90.468Z" fill="#263238" />
      <path d="M131.328 90.246L151.008 90.138C150.298 88.8154 149.266 87.6929 148.008 86.874C145.395 84.9644 142.245 83.9291 139.008 83.916C135.798 83.916 129.378 88.038 127.116 90.312C125.316 92.112 125.364 93.666 125.364 93.666C125.136 96.222 126.642 99.066 127.284 101.568C127.608 102.852 128.286 106.74 128.286 106.74C129.366 106.71 129.744 105.834 129.552 104.766L129.234 100.566C130.296 99.6801 131.04 98.4719 131.353 97.1248C131.666 95.7777 131.531 94.3653 130.968 93.102C130.698 92.73 130.05 90.996 131.328 90.246Z" fill="#263238" />
      <path d="M128.952 100.29C127.95 96.8099 127.026 93.0899 128.094 89.6099H127.494L127.254 89.7839C126.699 89.5974 126.099 89.5926 125.542 89.7701C124.984 89.9475 124.497 90.2981 124.152 90.7708C123.807 91.2436 123.622 91.814 123.623 92.3993C123.624 92.9846 123.811 93.5543 124.158 94.0259C123.573 94.0207 123.006 94.2295 122.564 94.6129C122.122 94.9963 121.835 95.5279 121.758 96.1079C121.76 96.916 121.982 97.7083 122.4 98.3999C122.819 99.0935 123.075 99.873 123.15 100.68C123.175 101.082 123.058 101.479 122.818 101.803C122.579 102.127 122.233 102.356 121.842 102.45C122.031 102.859 122.356 103.191 122.762 103.389C123.167 103.586 123.628 103.638 124.068 103.536C123.788 104.22 123.32 104.811 122.718 105.24C124.002 106.116 125.76 105.84 127.152 105.138C128.015 104.791 128.72 104.137 129.132 103.302C129.516 102.336 129.228 101.274 128.952 100.29Z" fill="#263238" />
      <path d="M128.628 104.352C128.508 104.304 123.828 103.074 124.122 107.784C124.416 112.494 129.072 111.252 129.072 111.114C129.072 110.976 128.628 104.352 128.628 104.352Z" fill="#FFBE9D" />
      <path d="M127.943 109.404C127.943 109.404 127.865 109.464 127.727 109.536C127.541 109.628 127.326 109.646 127.127 109.584C126.617 109.428 126.155 108.666 126.083 107.814C126.047 107.407 126.102 106.997 126.245 106.614C126.284 106.462 126.358 106.323 126.463 106.207C126.567 106.09 126.699 106.002 126.845 105.948C126.938 105.919 127.039 105.923 127.129 105.959C127.22 105.995 127.296 106.061 127.343 106.146C127.415 106.272 127.385 106.368 127.415 106.38C127.445 106.392 127.511 106.29 127.463 106.098C127.425 105.986 127.354 105.888 127.259 105.816C127.193 105.769 127.118 105.737 127.038 105.721C126.959 105.704 126.877 105.704 126.797 105.72C126.606 105.77 126.431 105.868 126.288 106.004C126.146 106.141 126.04 106.311 125.981 106.5C125.808 106.921 125.736 107.378 125.771 107.832C125.861 108.78 126.371 109.632 127.091 109.8C127.219 109.828 127.352 109.827 127.479 109.798C127.607 109.769 127.726 109.713 127.829 109.632C127.943 109.518 127.961 109.41 127.943 109.404Z" fill="#EB996E" />
      <path d="M123.738 100.446C123.738 100.446 123.606 100.266 123.648 99.8941C123.698 99.6417 123.803 99.4034 123.956 99.1961C124.108 98.9887 124.304 98.8173 124.53 98.6941C125.206 98.3722 125.941 98.1944 126.69 98.1721C127.109 98.1721 127.521 98.0732 127.895 97.8835C128.268 97.6937 128.591 97.4184 128.838 97.0801C129.23 96.3171 129.361 95.4465 129.21 94.6021C129.096 93.8101 128.886 93.0961 128.784 92.4781C128.594 91.8159 128.604 91.1123 128.814 90.4561C128.818 91.1181 128.892 91.7778 129.036 92.4241C129.168 93.0241 129.402 93.7261 129.546 94.5601C129.727 95.4828 129.587 96.4397 129.15 97.2721C128.89 97.7023 128.496 98.0353 128.028 98.2201C127.609 98.3754 127.171 98.4723 126.726 98.5081C126.016 98.5122 125.314 98.6507 124.656 98.9161C124.45 99.022 124.267 99.1678 124.118 99.3449C123.968 99.5221 123.855 99.7271 123.786 99.9481C123.759 100.113 123.743 100.279 123.738 100.446Z" fill="#455A64" />
      <path d="M122.598 98.4C122.598 98.4 122.496 98.268 122.454 97.974C122.406 97.5594 122.487 97.14 122.688 96.774C122.826 96.514 123.016 96.2852 123.246 96.102C123.515 95.9 123.824 95.7569 124.152 95.682C124.47 95.6138 124.773 95.4877 125.046 95.31C125.167 95.2022 125.261 95.0684 125.322 94.9188C125.384 94.7691 125.41 94.6074 125.4 94.446C125.4 94.092 125.358 93.714 125.37 93.336C125.375 92.975 125.415 92.6153 125.49 92.262C125.611 91.6652 125.841 91.0957 126.168 90.582C126.272 90.3935 126.422 90.2339 126.603 90.1176C126.784 90.0014 126.991 89.9321 127.206 89.916C127.518 89.916 127.65 90.06 127.638 90.072C127.626 90.084 127.482 90.018 127.218 90.072C127.038 90.1105 126.869 90.1901 126.724 90.3046C126.579 90.419 126.463 90.5653 126.384 90.732C126.113 91.2353 125.923 91.7778 125.82 92.34C125.762 92.6729 125.732 93.0101 125.73 93.348C125.73 93.696 125.784 94.068 125.766 94.476C125.775 94.6896 125.734 94.9023 125.648 95.0979C125.562 95.2934 125.432 95.4666 125.268 95.604C124.958 95.8114 124.609 95.9523 124.242 96.018C123.969 96.0647 123.709 96.1692 123.479 96.3244C123.249 96.4796 123.055 96.6819 122.91 96.918C122.664 97.3709 122.555 97.8861 122.598 98.4Z" fill="#455A64" />
      <path d="M150.174 85.962C150.204 85.962 149.82 86.22 149.202 85.932C148.854 85.7432 148.549 85.4836 148.308 85.17C148.01 84.7797 147.639 84.4515 147.216 84.204C146.665 83.8942 146.036 83.7514 145.405 83.793C144.774 83.8345 144.169 84.0586 143.664 84.438C143.064 84.858 142.566 85.452 141.864 85.944C141.507 86.2087 141.098 86.3948 140.664 86.4899C140.215 86.5686 139.752 86.5251 139.326 86.364C138.474 86.058 137.802 85.536 137.07 85.272C136.363 85.0008 135.612 84.8644 134.856 84.87C133.552 84.8683 132.286 85.3011 131.256 86.1C130.568 86.6374 129.996 87.3089 129.576 88.0739C129.282 88.6199 129.174 88.9559 129.144 88.9439C129.114 88.9319 129.144 88.854 129.192 88.692C129.26 88.4584 129.344 88.2298 129.444 88.0079C129.831 87.1909 130.395 86.4697 131.094 85.896C132.147 85.0351 133.46 84.5552 134.82 84.534C135.621 84.5131 136.418 84.6497 137.166 84.936C137.958 85.224 138.642 85.746 139.416 86.016C139.783 86.1572 140.181 86.1966 140.568 86.13C140.943 86.0438 141.297 85.8804 141.606 85.65C142.236 85.2 142.764 84.606 143.406 84.162C143.999 83.7173 144.722 83.4792 145.464 83.484C146.096 83.4886 146.717 83.6582 147.264 83.976C147.702 84.2554 148.083 84.6158 148.386 85.038C148.596 85.3462 148.868 85.6077 149.184 85.8059C149.482 85.9831 149.836 86.0389 150.174 85.962Z" fill="#455A64" />
      <path d="M140.712 109.446C140.862 109.446 141.03 110.418 142.014 110.988C142.998 111.558 144.048 111.27 144.084 111.408C144.12 111.546 143.874 111.648 143.424 111.732C142.836 111.841 142.228 111.739 141.708 111.444C141.21 111.155 140.841 110.688 140.676 110.136C140.568 109.722 140.64 109.452 140.712 109.446Z" fill="#263238" />
      <path d="M202.8 203.196C200.973 201.626 198.602 200.838 196.2 201L192.144 213.882C195.114 214.014 198.078 215.544 200.946 214.758C202.091 214.405 203.111 213.731 203.886 212.817C204.661 211.903 205.159 210.786 205.32 209.598C205.444 208.407 205.281 207.203 204.842 206.089C204.403 204.975 203.703 203.982 202.8 203.196Z" fill="#794DF5" />
      <path d="M196.247 200.706C196.247 200.604 196.685 200.52 197.447 200.526C198.581 200.547 199.703 200.76 200.765 201.156C202.389 201.789 203.794 202.879 204.809 204.294C205.43 205.134 205.888 206.082 206.159 207.09C206.453 208.193 206.486 209.35 206.255 210.468C206.032 211.601 205.526 212.66 204.785 213.546C204.076 214.353 203.177 214.972 202.169 215.346C200.532 215.925 198.767 216.039 197.069 215.676C195.977 215.462 194.91 215.13 193.889 214.686C193.175 214.38 192.797 214.152 192.833 214.05C192.869 213.948 193.301 213.978 194.033 214.104C194.765 214.23 195.875 214.464 197.195 214.584C198.692 214.75 200.207 214.544 201.605 213.984C202.372 213.66 203.051 213.158 203.585 212.52C204.146 211.822 204.529 210.999 204.701 210.12C204.866 209.249 204.854 208.354 204.665 207.488C204.477 206.623 204.115 205.804 203.603 205.08C202.777 203.828 201.64 202.811 200.303 202.128C199.356 201.681 198.365 201.335 197.345 201.096C196.655 200.94 196.235 200.814 196.247 200.706Z" fill="#F5F5F5" />
      <path d="M158.67 249.036L149.85 273.75L180.54 284.88C181.428 281.88 167.682 270.24 167.682 270.24L173.982 254.64L158.67 249.036Z" fill="#794DF5" />
      <g opacity="0.6">
        <path opacity="0.6" d="M158.675 265.47C158.347 265.453 158.024 265.557 157.769 265.763C157.513 265.969 157.342 266.262 157.289 266.586C157.26 266.912 157.357 267.237 157.56 267.494C157.763 267.751 158.057 267.92 158.381 267.966C158.731 267.985 159.075 267.872 159.346 267.65C159.617 267.428 159.795 267.113 159.845 266.766C159.845 266.112 159.197 265.362 158.549 265.452" fill="white" />
      </g>
      <path d="M149.85 273.75L150.744 271.26L179.892 282.888C179.892 282.888 181.044 283.974 180.54 284.88L149.85 273.75Z" fill="white" />
      <path d="M168.24 270.264C168.18 270.414 167.406 270.21 166.494 270.426C165.582 270.642 164.958 271.128 164.844 271.026C164.73 270.924 165.264 270.132 166.374 269.892C167.484 269.652 168.318 270.138 168.24 270.264Z" fill="#263238" />
      <path d="M171 272.766C171 272.922 170.238 272.94 169.5 273.366C168.762 273.792 168.372 274.434 168.222 274.374C168.072 274.314 168.288 273.432 169.218 272.886C170.148 272.34 171 272.622 171 272.766Z" fill="#263238" />
      <path d="M171.402 277.278C171.258 277.278 171.318 276.438 172.002 275.754C172.686 275.07 173.502 275.004 173.52 275.154C173.538 275.304 172.95 275.58 172.398 276.15C171.846 276.72 171.558 277.32 171.402 277.278Z" fill="#263238" />
      <path d="M169.278 266.55C169.164 266.664 168.552 266.208 167.688 265.95C166.824 265.692 166.062 265.728 166.032 265.572C166.002 265.416 166.824 265.11 167.832 265.422C168.84 265.734 169.398 266.46 169.278 266.55Z" fill="#263238" />
      <path d="M169.866 262.644C169.314 262.596 168.786 262.401 168.336 262.08C167.815 261.782 167.336 261.42 166.908 261C166.651 260.755 166.413 260.493 166.194 260.214C166.055 260.056 165.942 259.878 165.858 259.686C165.797 259.544 165.786 259.386 165.827 259.237C165.867 259.088 165.956 258.957 166.08 258.864C166.192 258.787 166.321 258.737 166.457 258.718C166.592 258.7 166.73 258.713 166.86 258.756C167.054 258.826 167.238 258.923 167.406 259.044C167.705 259.244 167.986 259.471 168.246 259.722C168.679 260.142 169.044 260.628 169.326 261.162C169.634 261.63 169.791 262.18 169.776 262.74C169.692 262.74 169.512 262.17 168.99 261.36C168.68 260.894 168.313 260.469 167.898 260.094C167.652 259.874 167.389 259.673 167.112 259.494C166.818 259.29 166.542 259.188 166.41 259.302C166.278 259.416 166.344 259.368 166.38 259.488C166.442 259.63 166.525 259.761 166.626 259.878C166.822 260.144 167.037 260.397 167.268 260.634C167.657 261.044 168.085 261.416 168.546 261.744C169.338 262.314 169.902 262.566 169.866 262.644Z" fill="#263238" />
      <path d="M169.596 262.8C169.466 262.256 169.512 261.684 169.728 261.168C169.91 260.59 170.187 260.046 170.55 259.56C170.767 259.275 171.01 259.009 171.276 258.768C171.434 258.596 171.633 258.468 171.854 258.394C172.076 258.321 172.312 258.305 172.542 258.348C172.677 258.402 172.795 258.493 172.881 258.611C172.967 258.729 173.018 258.869 173.028 259.014C173.039 259.215 173.023 259.417 172.98 259.614C172.885 260.101 172.694 260.564 172.418 260.976C172.142 261.389 171.786 261.741 171.372 262.014C170.484 262.614 169.794 262.542 169.8 262.488C169.806 262.434 170.4 262.296 171.144 261.696C171.567 261.351 171.911 260.92 172.152 260.43C172.292 260.145 172.391 259.842 172.446 259.53C172.518 259.188 172.488 258.876 172.356 258.87C172.221 258.865 172.086 258.89 171.962 258.942C171.837 258.995 171.726 259.075 171.636 259.176C171.387 259.387 171.158 259.62 170.952 259.872C170.6 260.302 170.313 260.781 170.1 261.294C169.728 262.2 169.686 262.8 169.596 262.8Z" fill="#263238" />
      <path d="M128.94 183.036C124.284 183.144 119.868 183.798 116.658 187.176C112.404 191.658 107.148 198.036 106.848 202.458C106.404 209.058 110.394 211.038 113.304 212.658C114.591 213.354 116.041 213.692 117.504 213.636L171.312 213.228L172.998 204.114L138.198 195.534L139.644 182.436L128.94 183.036Z" fill="#263238" />
      <path d="M139.674 182.43L156.6 181.65C156.6 181.65 185.844 183.276 192.078 188.994C198.312 194.712 197.538 201.21 195.456 207.444C193.374 213.678 175.056 253.572 175.056 253.572C175.056 253.572 176.544 256.11 175.71 258.642C175.372 259.751 174.753 260.753 173.911 261.55C173.07 262.348 172.035 262.913 170.91 263.19L154.638 257.19C154.638 257.19 152.43 256.41 153.99 254.328C155.55 252.246 156.588 251.862 156.588 251.862V247.704C156.588 247.704 162.306 237.306 162.822 235.878C163.338 234.45 168.822 213.294 168.822 213.294L172.464 207.036C172.464 207.036 163.662 202.896 156.03 199.896L138.21 195.504L139.674 182.43Z" fill="#263238" />
      <path d="M167.13 256.458C166.638 256.172 166.161 255.859 165.702 255.522C163.403 254.033 160.814 253.049 158.106 252.636C157.538 252.581 156.973 252.497 156.414 252.384C156.985 252.318 157.562 252.328 158.13 252.414C160.905 252.711 163.557 253.719 165.828 255.342C166.309 255.655 166.747 256.031 167.13 256.458Z" fill="#455A64" />
      <path d="M175.26 253.602C174.905 253.311 174.588 252.976 174.318 252.606C173.766 251.964 173.034 251.052 172.272 250.008C171.51 248.964 170.85 247.998 170.4 247.284C170.131 246.91 169.906 246.508 169.728 246.084C170.05 246.41 170.335 246.77 170.58 247.158L172.524 249.822C173.25 250.806 173.916 251.694 174.492 252.468C174.788 252.817 175.046 253.197 175.26 253.602Z" fill="#455A64" />
      <path d="M181.2 201.348C181.2 201.396 180.6 201.528 179.658 201.84C177.191 202.654 174.956 204.049 173.142 205.908C172.787 206.317 172.413 206.707 172.02 207.078C172.273 206.594 172.597 206.149 172.98 205.758C173.852 204.772 174.863 203.918 175.98 203.22C177.096 202.518 178.308 201.982 179.58 201.63C180.102 201.455 180.648 201.36 181.2 201.348Z" fill="#455A64" />
      <path d="M164.58 229.596C164.587 229.476 164.609 229.357 164.646 229.242L164.898 228.252L165.87 224.622L169.2 212.676C169.878 210.342 170.454 208.122 171.108 206.106C171.799 204.354 172.842 202.763 174.174 201.432C175.053 200.53 176.027 199.725 177.078 199.032C177.456 198.792 177.75 198.612 177.96 198.504C178.061 198.44 178.167 198.386 178.278 198.342C178.278 198.342 177.87 198.618 177.15 199.128C176.134 199.854 175.189 200.675 174.33 201.582C173.045 202.908 172.041 204.479 171.378 206.202C170.778 208.188 170.178 210.438 169.5 212.76L166.086 224.676C165.642 226.17 165.276 227.388 165.012 228.276C164.886 228.672 164.79 228.996 164.706 229.248C164.68 229.369 164.638 229.486 164.58 229.596Z" fill="#455A64" />
      <path d="M149.61 199.122C149.52 199.122 149.166 195.966 148.812 192.054C148.458 188.142 148.212 184.962 148.332 184.956C148.452 184.95 148.77 188.112 149.124 192.024C149.478 195.936 149.694 199.116 149.61 199.122Z" fill="#455A64" />
      <path d="M121.434 207.6C121.386 207.6 121.128 207.114 120.594 206.364C119.905 205.388 119.078 204.517 118.14 203.778C117.195 203.048 116.15 202.456 115.038 202.02C114.18 201.684 113.622 201.558 113.634 201.504C114.139 201.528 114.637 201.631 115.11 201.81C117.456 202.584 119.461 204.149 120.78 206.238C121.071 206.653 121.292 207.113 121.434 207.6Z" fill="#455A64" />
      <path d="M131.802 196.08C131.802 196.128 131.058 195.672 129.816 195.102C126.557 193.622 122.943 193.105 119.4 193.614C118.05 193.806 117.228 194.046 117.216 193.992C117.39 193.893 117.578 193.82 117.774 193.776C118.295 193.615 118.826 193.486 119.364 193.392C122.947 192.763 126.638 193.293 129.9 194.904C130.389 195.145 130.862 195.418 131.316 195.72C131.494 195.817 131.657 195.938 131.802 196.08Z" fill="#455A64" />
      <path d="M172.337 204.096C172.193 204.089 172.05 204.064 171.911 204.024L170.711 203.766L166.331 202.8C162.629 201.972 157.505 200.856 151.823 199.734C146.141 198.612 140.981 197.682 137.243 197.04L132.809 196.278L131.609 196.068C131.468 196.051 131.329 196.017 131.195 195.966C131.338 195.97 131.48 195.982 131.621 196.002L132.821 196.158C133.871 196.302 135.389 196.53 137.267 196.824C141.017 197.424 146.189 198.3 151.877 199.428C157.565 200.556 162.677 201.702 166.373 202.584C168.215 203.028 169.709 203.4 170.735 203.67L171.935 203.982C172.072 204.008 172.207 204.046 172.337 204.096Z" fill="#455A64" />
      <path d="M138.227 197.07C138.167 197.07 138.041 196.362 137.483 195.354C137.157 194.772 136.753 194.237 136.283 193.764C135.691 193.18 135.062 192.635 134.399 192.132C133.179 191.231 131.889 190.428 130.541 189.732C129.971 189.461 129.422 189.148 128.897 188.796C129.501 188.987 130.086 189.234 130.643 189.534C132.036 190.179 133.359 190.966 134.591 191.88C135.263 192.392 135.896 192.951 136.487 193.554C136.969 194.053 137.374 194.621 137.687 195.24C137.903 195.658 138.066 196.102 138.173 196.56C138.223 196.725 138.241 196.898 138.227 197.07Z" fill="#455A64" />
      <path d="M198.161 205.2C198.227 205.272 197.327 206.178 196.625 207.564C195.923 208.95 195.695 210.198 195.605 210.18C195.603 209.21 195.86 208.258 196.349 207.42C196.747 206.533 197.372 205.768 198.161 205.2Z" fill="#263238" />
      <g opacity="0.3">
        <path opacity="0.3" d="M159.647 143.724L163.499 170.856L163.691 171.288C164.595 161.908 163.207 152.449 159.647 143.724Z" fill="black" />
      </g>
      <path d="M180.39 159.312L167.868 181.2L137.01 181.872L136.932 183.27L166.812 183.048L167.76 183.012L199.308 183.078L205.686 172.08L213.834 160.404C213.952 160.231 214.02 160.029 214.031 159.819C214.043 159.61 213.997 159.402 213.899 159.217C213.801 159.031 213.654 158.876 213.475 158.769C213.295 158.661 213.089 158.604 212.88 158.604L181.386 158.724C181.183 158.726 180.983 158.781 180.808 158.884C180.634 158.987 180.489 159.135 180.39 159.312Z" fill="#E0E0E0" />
      <path d="M205.686 172.098C205.686 172.098 207.306 173.586 207.054 174.846C206.802 176.106 206.88 177.048 205.686 177.132C205.708 177.593 205.553 178.045 205.251 178.395C204.95 178.744 204.525 178.965 204.066 179.01C204.066 179.01 204.432 180.99 202.128 181.332C202.128 181.332 201.42 183.45 199.968 182.934C199.131 182.65 198.327 182.278 197.568 181.824L197.142 181.056C197.142 181.056 195 180.252 195.528 179.046C195.852 178.314 196.956 178.122 196.956 178.122C196.829 177.822 196.775 177.495 196.799 177.17C196.823 176.844 196.924 176.529 197.094 176.25C197.604 175.56 199.272 176.13 199.272 176.13C199.238 175.539 199.426 174.957 199.8 174.498C200.4 173.82 203.028 175.65 203.028 175.65L204.474 173.922L205.686 172.098Z" fill="#FFBE9D" />
      <path d="M202.128 181.332C201.192 180.934 200.3 180.441 199.464 179.862C198.573 179.371 197.737 178.787 196.968 178.122C197.903 178.524 198.795 179.019 199.632 179.598C200.52 180.091 201.356 180.672 202.128 181.332Z" fill="#EB996E" />
      <path d="M204.066 179.01C203.196 178.668 202.367 178.229 201.594 177.702C200.769 177.265 199.994 176.737 199.284 176.13C200.156 176.469 200.985 176.908 201.756 177.438C202.582 177.875 203.356 178.402 204.066 179.01Z" fill="#EB996E" />
      <path d="M201.426 174.666C201.474 174.576 202.464 175.098 203.616 175.83C204.33 176.242 205.017 176.699 205.674 177.198C205.632 177.3 204.6 176.832 203.448 176.094C202.725 175.69 202.048 175.212 201.426 174.666Z" fill="#EB996E" />
      <path d="M213.834 159.444C213.784 159.452 213.733 159.452 213.684 159.444H213.24H211.536L205.272 159.492L184.602 159.54H183.096C182.874 159.519 182.65 159.558 182.449 159.653C182.247 159.748 182.075 159.895 181.95 160.08C181.47 160.936 181.002 161.78 180.546 162.612L177.882 167.412L173.364 175.53C172.08 177.822 171.03 179.682 170.292 180.996C169.938 181.596 169.65 182.112 169.446 182.472L169.218 182.85L169.134 182.976C169.134 182.976 169.134 182.922 169.188 182.832L169.392 182.436L170.19 180.936L173.19 175.422L177.654 167.268C178.494 165.738 179.382 164.13 180.294 162.468L181.704 159.918C181.858 159.69 182.069 159.506 182.317 159.386C182.564 159.266 182.839 159.213 183.114 159.234H184.62L205.29 159.276L211.554 159.33L213.258 159.36H213.702C213.75 159.38 213.795 159.409 213.834 159.444Z" fill="#263238" />
      <path d="M191.633 170.454C191.488 170.665 191.406 170.913 191.398 171.169C191.39 171.425 191.456 171.678 191.588 171.898C191.72 172.117 191.913 172.294 192.143 172.407C192.373 172.52 192.631 172.564 192.885 172.534C193.14 172.504 193.38 172.401 193.578 172.238C193.775 172.075 193.922 171.858 193.999 171.614C194.077 171.369 194.082 171.108 194.015 170.861C193.947 170.614 193.81 170.391 193.619 170.22C193.324 169.989 192.95 169.885 192.578 169.929C192.207 169.973 191.867 170.161 191.633 170.454Z" fill="white" />
      <g opacity="0.3">
        <path opacity="0.3" d="M104.034 162C103.77 162.108 103.464 162.288 103.464 162.6C103.473 162.716 103.522 162.825 103.601 162.91C103.681 162.995 103.787 163.051 103.902 163.068C104.342 163.006 104.773 162.891 105.186 162.726C108.432 162.012 111.102 162.696 114.186 163.926L114.288 163.824C113.064 161.166 107.526 160.578 104.034 162Z" fill="black" />
      </g>
      <path d="M271.2 283.8C271.2 283.884 216.93 283.956 150 283.956C83.0698 283.956 28.7998 283.86 28.7998 283.8C28.7998 283.74 83.0578 283.644 150 283.644C216.942 283.644 271.2 283.686 271.2 283.8Z" fill="#263238" />
      <path d="M87.2518 92.5262C87.3538 92.6282 107.28 85.7822 107.28 85.7822L99.5998 68.2021L81.8818 75.0002L91.6378 80.6042L87.2518 92.5262Z" fill="#403DC7" />
      <path d="M96.5996 73.4942V49.5602C96.5996 49.5602 124.086 44.5322 143.892 44.8322C163.698 45.1322 184.092 48.3782 184.092 48.3782V71.3582C184.092 71.3582 122.016 65.8142 96.5996 73.4942Z" fill="#403DC7" />
      <path d="M96.4561 73.5184L107.256 85.8844V71.3584C107.256 71.3584 98.2261 72.4384 96.4561 73.5184Z" fill="#263238" />
      <path d="M96.5993 49.5542C96.6893 49.5542 96.7253 54.9542 96.6893 61.5542C96.6533 68.1542 96.5453 73.5542 96.4613 73.5542C96.3773 73.5542 96.3353 68.1902 96.3713 61.5542C96.4073 54.9182 96.5093 49.5542 96.5993 49.5542Z" fill="#263238" />
      <path d="M193.392 90.6904C193.29 90.7924 173.364 83.9464 173.364 83.9464L181.026 66.3604L198.762 73.1464L189 78.7504L193.392 90.6904Z" fill="#403DC7" />
      <path d="M184.199 71.6823L173.399 84.0483V70.5723C177.02 70.6962 180.629 71.0671 184.199 71.6823Z" fill="#263238" />
      <path d="M184.079 48.3784C184.163 48.3784 184.259 53.5924 184.289 60.0304C184.319 66.4684 184.289 71.6824 184.187 71.6824C184.085 71.6824 184.007 66.4684 183.977 60.0304C183.947 53.5924 183.989 48.3784 184.079 48.3784Z" fill="#263238" />
      <path d="M112.098 60.0541C112.041 59.3651 112.189 58.6746 112.523 58.0691C112.857 57.4636 113.361 56.9699 113.974 56.6497C114.587 56.3295 115.28 56.1972 115.968 56.2691C116.656 56.341 117.307 56.6141 117.84 57.0541L117 58.0621C116.774 57.8557 116.505 57.7019 116.213 57.6117C115.92 57.5214 115.611 57.497 115.308 57.5401C114.77 57.6149 114.284 57.9003 113.957 58.3335C113.629 58.7667 113.487 59.3122 113.562 59.8501C113.637 60.388 113.922 60.8741 114.355 61.2015C114.789 61.529 115.334 61.6709 115.872 61.5961C116.177 61.556 116.469 61.4478 116.727 61.2796C116.984 61.1113 117.201 60.8873 117.36 60.6241L118.416 61.3621C118.025 61.9313 117.474 62.3724 116.834 62.6302C116.193 62.888 115.49 62.951 114.814 62.8113C114.137 62.6717 113.517 62.3356 113.031 61.8452C112.545 61.3547 112.214 60.7317 112.08 60.0541H112.098Z" fill="white" />
      <path d="M118.644 59.1541C118.533 58.4614 118.631 57.7511 118.928 57.1152C119.225 56.4793 119.705 55.947 120.308 55.5874C120.91 55.2278 121.607 55.0574 122.307 55.0982C123.008 55.139 123.68 55.3892 124.237 55.8163C124.793 56.2435 125.209 56.8279 125.43 57.4939C125.65 58.16 125.666 58.8769 125.475 59.552C125.283 60.2271 124.894 60.8291 124.356 61.2802C123.819 61.7313 123.158 62.0107 122.46 62.0821C122.016 62.153 121.561 62.1325 121.125 62.022C120.689 61.9114 120.279 61.7131 119.922 61.4391C119.565 61.165 119.268 60.8211 119.048 60.4282C118.828 60.0354 118.691 59.6018 118.644 59.1541ZM124.11 58.5901C124.062 58.2008 123.899 57.8344 123.644 57.5368C123.388 57.2391 123.05 57.0233 122.673 56.9163C122.296 56.8092 121.895 56.8156 121.521 56.9348C121.147 57.0539 120.817 57.2805 120.571 57.5862C120.325 57.892 120.175 58.2633 120.138 58.654C120.102 59.0447 120.182 59.4373 120.367 59.7831C120.553 60.1288 120.836 60.4123 121.181 60.5981C121.527 60.7839 121.92 60.8639 122.31 60.8281C122.58 60.8104 122.842 60.7369 123.082 60.6123C123.322 60.4877 123.533 60.3148 123.702 60.1044C123.871 59.894 123.995 59.6508 124.065 59.3901C124.135 59.1295 124.151 58.857 124.11 58.5901Z" fill="white" />
      <path d="M132.786 61.1524L132.528 57.3244L130.854 60.6004L130.194 60.6424L128.124 57.6904L128.37 61.4344L126.984 61.5244L126.6 55.1464L127.8 55.0684L130.44 58.8784L132.534 54.7684L133.734 54.6904L134.166 61.0624L132.786 61.1524Z" fill="white" />
      <path d="M141.048 56.784C141.084 58.212 140.04 59.124 138.336 59.184L137.046 59.22L137.1 60.984L135.618 61.026L135.438 54.6L138.204 54.522C139.914 54.498 141 55.356 141.048 56.784ZM139.548 56.826C139.548 56.124 139.062 55.728 138.156 55.752L136.956 55.788L137.022 58.008L138.222 57.972C139.122 57.948 139.566 57.522 139.548 56.826Z" fill="white" />
      <path d="M142.2 54.4624H143.676V59.6464H146.88V60.8464H142.2V54.4624Z" fill="white" />
      <path d="M152.61 59.8325V61.0325L147.66 60.8885L147.846 54.4985L152.646 54.6425L152.61 55.8425L149.25 55.7405L149.214 57.1325L152.178 57.2165L152.142 58.3685L149.178 58.2785L149.136 59.7605L152.61 59.8325Z" fill="white" />
      <path d="M155.346 55.9802L153.306 55.8602L153.378 54.6602L158.934 54.9962L158.862 56.1962L156.822 56.0702L156.51 61.2482L155.034 61.1582L155.346 55.9802Z" fill="white" />
      <path d="M164.315 60.7083L164.207 61.9083L159.275 61.4523L159.875 55.0923L164.675 55.5363L164.567 56.7363L161.219 56.4003L161.093 57.7863L164.045 58.0563L163.943 59.2023L160.985 58.9323L160.853 60.4023L164.315 60.7083Z" fill="white" />
      <path d="M166.163 55.6743L169.037 56.0583C171.107 56.3343 172.361 57.7683 172.109 59.6583C171.857 61.5483 170.261 62.6583 168.191 62.3583L165.317 61.9743L166.163 55.6743ZM168.281 61.1763C168.543 61.2182 168.809 61.2074 169.066 61.1445C169.323 61.0815 169.565 60.9677 169.777 60.8098C169.989 60.6519 170.168 60.4531 170.302 60.2252C170.436 59.9972 170.523 59.7446 170.558 59.4824C170.593 59.2202 170.575 58.9537 170.505 58.6986C170.435 58.4435 170.315 58.205 170.151 57.9971C169.988 57.7892 169.784 57.6162 169.553 57.4883C169.321 57.3604 169.066 57.2801 168.803 57.2523L167.465 57.0723L166.943 60.9963L168.281 61.1763Z" fill="white" />
      <path d="M196.373 50.5557L197.945 52.3977L202.373 50.5557L201.077 48.6177L196.373 50.5557Z" fill="#455A64" />
      <path d="M194.345 41.7898C194.345 42.2455 194.21 42.691 193.957 43.0699C193.704 43.4488 193.344 43.7441 192.923 43.9185C192.502 44.0928 192.039 44.1385 191.592 44.0496C191.145 43.9607 190.734 43.7412 190.412 43.419C190.09 43.0968 189.87 42.6863 189.782 42.2393C189.693 41.7924 189.738 41.3291 189.913 40.9081C190.087 40.4871 190.382 40.1273 190.761 39.8741C191.14 39.621 191.586 39.4858 192.041 39.4858C192.652 39.4874 193.237 39.7307 193.669 40.1624C194.1 40.5942 194.344 41.1793 194.345 41.7898Z" fill="#794DF5" />
      <path d="M142.734 76.1577C142.732 76.386 142.663 76.6088 142.535 76.7979C142.407 76.9869 142.226 77.1336 142.014 77.2193C141.802 77.305 141.57 77.3259 141.347 77.2793C141.123 77.2327 140.918 77.1207 140.759 76.9575C140.599 76.7944 140.491 76.5874 140.449 76.363C140.407 76.1385 140.433 75.9066 140.523 75.6968C140.613 75.487 140.764 75.3087 140.955 75.1846C141.147 75.0605 141.371 74.9961 141.6 74.9997C141.903 75.0044 142.192 75.1287 142.405 75.3455C142.617 75.5623 142.735 75.8542 142.734 76.1577Z" fill="#794DF5" />
      <path d="M89.0635 58.8419C89.0635 59.0157 89.0118 59.1855 88.915 59.3298C88.8182 59.4741 88.6807 59.5864 88.5199 59.6523C88.3592 59.7182 88.1824 59.7349 88.0122 59.7001C87.8419 59.6653 87.6859 59.5807 87.5638 59.457C87.4418 59.3332 87.3594 59.176 87.3269 59.0053C87.2945 58.8346 87.3136 58.6581 87.3817 58.4983C87.4498 58.3384 87.564 58.2025 87.7096 58.1077C87.8552 58.0129 88.0258 57.9635 88.1995 57.9659C88.4298 57.9691 88.6495 58.0627 88.8112 58.2267C88.9729 58.3906 89.0636 58.6116 89.0635 58.8419Z" fill="#794DF5" />
      <path d="M85.8115 49.1998L88.3915 49.3558L89.6155 44.2798L87.2035 44.3818L85.8115 49.1998Z" fill="#455A64" />
      <path d="M166.451 89.7178L167.321 92.1538L172.469 91.2898L171.431 89.1118L166.451 89.7178Z" fill="#794DF5" />
      <path d="M114.384 75.6597L112.794 77.6997L116.28 81.5817L117.564 79.5357L114.384 75.6597Z" fill="#455A64" />
      <path d="M108.222 27.7378L114.312 33.9478L122.886 30.7078L123.228 32.8378L119.514 40.4398L122.94 46.1158L122.652 47.8138L114.354 46.4158L109.446 51.7978L108.294 50.9278L107.28 44.0818L99.5402 40.0498L99.1982 38.4838L106.992 36.3538L108.222 27.7378Z" fill="#F9C62A" />
      <g opacity="0.3">
        <path opacity="0.3" d="M108.222 27.7378L114.312 33.9478L122.886 30.7078L123.228 32.8378L119.514 40.4398L122.94 46.1158L122.652 47.8138L114.354 46.4158L109.446 51.7978L108.294 50.9278L107.28 44.0818L99.5402 40.0498L99.1982 38.4838L106.992 36.3538L108.222 27.7378Z" fill="black" />
      </g>
      <path d="M108.222 25.9922L114.312 32.2082L122.886 30.7082L118.86 38.4242L122.94 46.1162L114.354 44.6762L108.294 50.9282L107.016 42.3182L99.1982 38.4842L106.992 34.6082L108.222 25.9922Z" fill="#F9C62A" />
      <path d="M171.372 27.7378L165.276 33.9478L156.702 30.7078L156.36 32.8378L160.074 40.4398L156.648 46.1158L156.936 47.8138L165.234 46.4158L170.142 51.7978L171.294 50.9278L172.308 44.0818L180.048 40.0498L180.39 38.4838L172.596 36.3538L171.372 27.7378Z" fill="#F9C62A" />
      <g opacity="0.3">
        <path opacity="0.3" d="M171.372 27.7378L165.276 33.9478L156.702 30.7078L156.36 32.8378L160.074 40.4398L156.648 46.1158L156.936 47.8138L165.234 46.4158L170.142 51.7978L171.294 50.9278L172.308 44.0818L180.048 40.0498L180.39 38.4838L172.596 36.3538L171.372 27.7378Z" fill="black" />
      </g>
      <path d="M171.372 25.9922L165.276 32.2082L156.702 30.7082L160.728 38.4242L156.648 46.1162L165.234 44.6762L171.294 50.9282L172.572 42.3182L180.39 38.4842L172.596 34.6082L171.372 25.9922Z" fill="#F9C62A" />
      <path d="M139.596 16.8359L144.666 26.7419L156.36 26.0519L155.964 28.7759L148.536 36.6419L150.546 44.8679L149.556 46.8299L139.962 41.9639L131.928 46.6679L130.854 45.1619L132.222 36.4199L124.314 28.5539L124.494 26.5199L134.814 26.8859L139.596 16.8359Z" fill="#F9C62A" />
      <g opacity="0.3">
        <path opacity="0.3" d="M139.596 16.8359L144.666 26.7419L156.36 26.0519L155.964 28.7759L148.536 36.6419L150.546 44.8679L149.556 46.8299L139.962 41.9639L131.928 46.6679L130.854 45.1619L132.222 36.4199L124.314 28.5539L124.494 26.5199L134.814 26.8859L139.596 16.8359Z" fill="black" />
      </g>
      <path d="M140.256 14.7119L145.326 24.6119L156.36 26.0519L148.506 33.9359L150.546 44.8679L140.628 39.8399L130.854 45.1619L132.576 34.1699L124.494 26.5199L135.48 24.7559L140.256 14.7119Z" fill="#F9C62A" />
      <path d="M160.458 103.578L168.93 104.514C171.79 100.465 176.062 97.6318 180.905 96.5717C185.747 95.5116 190.812 96.3011 195.102 98.7852C199.393 101.269 202.599 105.268 204.091 109.996C205.583 114.724 205.252 119.838 203.165 124.335C201.077 128.832 197.383 132.385 192.809 134.297C188.235 136.208 183.111 136.34 178.445 134.666C173.778 132.991 169.907 129.633 167.591 125.249C165.275 120.865 164.683 115.774 165.93 110.976L160.458 103.578Z" fill="#FAFAFA" />
      <path d="M160.458 103.578C160.788 104.022 162.504 106.29 166.008 110.94H165.882C165.886 110.93 165.893 110.921 165.901 110.914C165.909 110.907 165.918 110.902 165.929 110.899C165.939 110.896 165.95 110.896 165.961 110.898C165.971 110.9 165.981 110.904 165.99 110.91C166.004 110.919 166.015 110.931 166.022 110.946C166.028 110.961 166.03 110.978 166.026 110.994C165.167 114.36 165.213 117.893 166.158 121.236C166.436 122.239 166.803 123.215 167.256 124.152C167.496 124.632 167.724 125.124 167.982 125.61L168.876 127.032C171.731 131.171 176.049 134.072 180.96 135.15C183.678 135.748 186.493 135.76 189.216 135.186C192.087 134.561 194.785 133.312 197.118 131.526C199.562 129.67 201.526 127.257 202.848 124.488C204.217 121.672 204.891 118.57 204.816 115.44C204.729 112.446 203.97 109.51 202.596 106.848C201.304 104.325 199.473 102.117 197.232 100.38C193.196 97.2606 188.117 95.8088 183.042 96.3241C180.898 96.5815 178.804 97.1599 176.832 98.0401C173.694 99.4903 170.99 101.737 168.99 104.556C168.984 104.568 168.974 104.577 168.962 104.582C168.95 104.588 168.937 104.589 168.924 104.586L160.458 103.578L168.942 104.436L168.87 104.472C170.864 101.615 173.58 99.3371 176.742 97.8721C178.724 96.9588 180.835 96.3557 183 96.0841C188.144 95.5394 193.301 96.9962 197.4 100.152C199.679 101.909 201.542 104.149 202.854 106.71C204.257 109.41 205.03 112.393 205.116 115.434C205.195 118.613 204.512 121.765 203.124 124.626C201.779 127.438 199.781 129.888 197.298 131.772C194.927 133.582 192.186 134.848 189.27 135.48C186.498 136.063 183.634 136.045 180.87 135.426C175.907 134.332 171.546 131.391 168.672 127.2L167.772 125.76C167.514 125.268 167.286 124.764 167.04 124.278C166.589 123.329 166.223 122.341 165.948 121.326C165.007 117.948 164.982 114.379 165.876 110.988L166.02 111.042C166.009 111.06 165.992 111.072 165.972 111.078C165.952 111.083 165.931 111.081 165.912 111.072L160.458 103.578Z" fill="#263238" />
      <path d="M177.84 108.864C177.847 108.956 177.847 109.048 177.84 109.14C177.84 109.338 177.84 109.59 177.84 109.908C177.84 110.604 177.84 111.57 177.876 112.794C177.876 115.284 177.918 118.794 177.948 122.994L177.81 122.856H191.928C191.964 122.82 191.634 123.15 191.772 123.006V121.086C191.772 120.456 191.772 119.826 191.772 119.202V115.548C191.772 113.184 191.772 110.946 191.772 108.888L191.904 109.026L181.704 108.954L178.818 108.924H178.05H177.774H178.02H178.764L181.614 108.888L191.892 108.822H192.03V108.954C192.03 111.012 192.03 113.25 192.03 115.614V123C192.174 122.862 191.844 123.198 191.874 123.162H177.756H177.618V123C177.618 118.764 177.666 115.23 177.684 112.722C177.684 111.522 177.684 110.55 177.72 109.866C177.72 109.56 177.72 109.314 177.72 109.122C177.748 109.031 177.789 108.944 177.84 108.864Z" fill="#263238" />
      <path d="M190.434 111.198C190.638 111.36 188.742 114.096 186.192 117.312C185.688 117.954 185.19 118.566 184.728 119.112L184.428 119.478L184.092 119.172C181.884 117.186 180.39 115.662 180.522 115.506C180.654 115.35 182.388 116.61 184.674 118.506L184.032 118.566C184.47 117.966 184.95 117.366 185.46 116.73C188.004 113.514 190.236 111.042 190.434 111.198Z" fill="#794DF5" />
    </svg>
);