import { useContext } from 'react';
import cn from "classnames";
import {
  Paper,
  Tabs as MuiTabs,
  Tab,
  withStyles,
} from '@material-ui/core';
import {
  DescriptionOutlined,
  CheckCircleOutline,
  CodeOutlined,
  WebAssetOutlined,
  EmojiObjectsOutlined,
  FormatListNumberedRtlOutlined,
} from '@material-ui/icons';
import { PracticeLabContext } from '../../context/practice-lab.context';
import styles from './tab-bar.module.scss';

const TabBar = withStyles({
  root: {
    backgroundColor: 'transparent',
    border: 'none',
    borderRadius: '0 !important',
    height: 64,
    position: 'relative',
    width: '100%',
  },
})(Paper);

const Tabs = withStyles({
  root: {
    borderRadius: 0,
  },
  indicator: {
    backgroundColor: 'currentColor !important',
    height: 4,
    top: '0 !important',
  },
})(MuiTabs);

const TabButton = withStyles({
  root: {
    fontSize: 12,
    margin: 'auto',
    minWidth: 'auto',
    opacity: 0.75,
    position: 'relative',
    textTransform: 'capitalize',
    whiteSpace: 'nowrap',
  },
  selected: {
    color: 'inherit !important',
    opacity: 1,
  },
})(Tab);

export default function TabBarComponent(props) {
  const { isMCQ, isLightMode, smallScreen } = useContext(PracticeLabContext);

  return smallScreen ? (
    <div
      className={cn(styles.tabbarContainer, {
        [styles.lightMode]: isLightMode,
      })}>
      <TabBar square>
        {!props?.disabled && (
          <Tabs
            onChange={(_e, newTab) => {
              props?.setCurrentTab?.(newTab);
            }}
            value={props?.currentTab}
            variant="fullWidth"
            textColor="secondary">
            <TabButton icon={<DescriptionOutlined />} label="Question" />
            {!isMCQ && <TabButton icon={<CodeOutlined />} label="Code" />}
            {!isMCQ && <TabButton icon={<WebAssetOutlined />} label="Output" />}
            <TabButton icon={<EmojiObjectsOutlined />} label="Hints" />
            {isMCQ && <TabButton icon={<CheckCircleOutline />} label="Solution" />}
            {!isMCQ && <TabButton icon={<FormatListNumberedRtlOutlined />} label="Test Cases" />}
          </Tabs>
        )}
      </TabBar>
    </div>
  ) : null;
}
