import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Button } from "reactstrap";
import { connect, useSelector } from "react-redux";
import Paper from "@material-ui/core/Paper";
import { paginationClasses } from "../utils/constants";
import * as CustomIcons from "../../assets/icons/icons.js";
import MainTable from "./MainTable";
import CustomSearch from "../Tasks/CustomSearch";
import { DeleteModule } from "../../components/DeleteModule.jsx";
import Pagination from "react-js-pagination";
import BulkUserUpload from "./BulkUserUpload";
import { toast, ToastContainer } from "react-toastify";
import { CSVLink } from "react-csv";
import Select from "react-select";
import { EmptyState } from "components/EmptyState";
import { GetObjectFromString } from "pages/utils/GetObjectFromString";
import { GetAllUsers } from "./utils/GetUsers";
import Skeleton from "@material-ui/lab/Skeleton";
import CopyModal from "./CopyModal";
import { sessionPrivileges } from "privileges";

const entriesCount = [5, 10, 15, 20];

function CustomListTable(props) {
  const history = useHistory();
  const [customSearchValue, setCustomSearchValue] = useState("");

  const allCustomers = useSelector((state) =>
    state.StudentAndCustomerReducer.Customers
      ? state.StudentAndCustomerReducer.Customers.reduce((acc, val) => {
          acc.push({
            label: val.name,
            value: val.id,
          });
          return acc;
        }, [])
      : []
  );

  const [activePage, setActivePage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const [deleteId, setDeleteId] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const [bulkModal, setBulkModal] = useState(false);

  const [allData, setAllData] = useState([]);
  const [currentCustomer, setCurrentCustomer] = useState("");
  const [bulkCustomer, setBulkCustomer] = useState({ label: "", value: "" });

  const [collapse, setCollapse] = useState([]);
  const [copyModal, setCopyModal] = useState(false);
  const [checkedId, setChecked] = useState([]);
  const [checkedAll, setCheckedAll] = useState(false);

  const [dataTable, setDataTable] = useState(false);

  useEffect(() => {
    setAllData(props.data);
    if (props?.accord) {
      let tempCollapse = {};
      props.data.forEach((ele) => {
        tempCollapse[ele.id] = "none";
      });
      setCollapse(tempCollapse);
    }
  }, [props.data]);

  useEffect(() => {
    if (props?.activePage) {
      setActivePage(props?.activePage);
    }
  }, [props?.activePage]);

  useEffect(() => {
    if (props?.itemsPerPage) {
      setItemsPerPage(props?.itemsPerPage);
    }
  }, [props?.itemsPerPage]);

  useEffect(() => {
    if (props?.setCustomer) {
      setCurrentCustomer(props?.setCustomer);
    }
  }, [props?.setCustomer]);

  useEffect(() => {
    if (props?.customer) {
      setCurrentCustomer(props?.customer);
    }
  }, [props?.customer]);

  useEffect(() => {
    if (currentCustomer) {
      handleCustomerChange(currentCustomer);
    }
  }, [currentCustomer]);

  const handleCollapse = (id) => {
    let currentCollapse = { ...collapse };
    if (currentCollapse[id] === "table-row") {
      currentCollapse[id] = "none";
    } else {
      for (const key in currentCollapse) {
        currentCollapse[key] = "none";
      }
      currentCollapse[id] = "table-row";
    }
    setCollapse(currentCollapse);
  };

  const searchData = (query) => {
    let arr = [];
    if (query && query.length > 0) {
      props.data.forEach((e) => {
        const objArr = Object.keys(e);
        for (let i = 0; i < objArr.length; i++) {
          if (
            e[objArr[i]] &&
            typeof e[objArr[i]] === "string" &&
            e[objArr[i]].toLowerCase().includes(query.toLowerCase())
          ) {
            arr.push(e);
            break;
          }
        }
      });
      if (arr.length > 0) {
        setAllData(arr);
      } else {
        setAllData([]);
      }
    } else {
      setAllData(props.data);
    }
  };

  const setEditObject = (row) => {
    let editObject = {};
    props.editData.forEach((item) => {
      editObject[item] = row[item];
    });
    return editObject;
  };

  const handleCustomerChange = async (id) => {
    const users = await GetAllUsers(id);
    props.setUsers(users, id);
  };

  const handleDeleteModal = () => {
    setDeleteModal((prevModal) => !prevModal);
  };

  const lastIndex = activePage * itemsPerPage;
  const firstIndex = lastIndex - itemsPerPage;
  const Priv = sessionPrivileges().userprivileges || [];

  const isSA = Priv && Priv.includes("MANAGE_CUSTOMER");

  const toggleCloseModal = () => setCopyModal((p) => !p);

  return (
    <Paper elevation={0}>
      <CopyModal
        isOpen={copyModal}
        close={toggleCloseModal}
        qpIds={checkedId}
        setChecked={setChecked}
        setCheckedAll={setCheckedAll}
      />
      {props.createLink === "/userManagement" && currentCustomer ? (
        <BulkUserUpload
          customer={bulkCustomer}
          isOpen={bulkModal}
          onClose={() => {
            setBulkModal((m) => !m);
          }}
        />
      ) : (
        ""
      )}
      <ToastContainer />
      <DeleteModule
        Header={props.header.slice(0, props.header.length)}
        Close={handleDeleteModal}
        isOpen={deleteModal}
        Delete={() => props.deleteDatum(deleteId, currentCustomer)}
      />

      {props?.noNeedHeader ? (
        ""
      ) : (
        <>
          <div
            style={{
              padding: 20,
              display: "flex",
              justifyContent: "space-between",
              borderBottom: "1px solid lavender",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <h4 style={{ fontSize: 24, color: "#323036", fontWeight: 600 }}>
                {props.header}
              </h4>
            </div>
            <div style={{ display: "flex", gap: "1vw" }}>
              {props?.showCustomers && (
                <div style={{ width: 207 }}>
                  <Select
                    options={allCustomers}
                    value={GetObjectFromString(allCustomers, currentCustomer)}
                    onChange={(val) => {
                      setCurrentCustomer(val.value);
                      setBulkCustomer(val);
                      setCustomSearchValue("");
                      setActivePage(1);
                      setItemsPerPage(10);
                    }}
                    placeholder="Choose a customer"
                    maxMenuHeight={200}
                  />
                </div>
              )}
              {props.showAdd && (
                <Button
                  style={{ fontSize: "16px" }}
                  color="primary"
                  onClick={() => {
                    if (
                      props?.isCustomBatch &&
                      props?.showCustomers &&
                      !currentCustomer
                    ) {
                      toast.info("Please Choose a customer!...", {
                        position: "top-center",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: false,
                      });
                    } else {
                      history.push({
                        pathname: props.createLink,
                        state: {
                          customer: currentCustomer,
                          customerName: GetObjectFromString(
                            allCustomers,
                            currentCustomer
                          )?.label,
                        },
                      });
                    }
                  }}
                >
                  <CustomIcons.PlusIcon size="16" color="#fff" />
                  &nbsp;
                  {window.location.pathname === "/userList" ||
                  window.location.pathname === "/customerList"
                    ? "Add New"
                    : "Create New"}
                </Button>
              )}
              {props?.showBulk && currentCustomer ? (
                <Button
                  color="primary"
                  outline
                  onClick={() => setBulkModal((m) => !m)}
                >
                  Bulk Upload
                </Button>
              ) : (
                ""
              )}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              backgroundColor: "#fff",
              padding: 15,
              paddingBottom: 5,
              alignItems: "center",
            }}
          >
            {props.modalTable == true ? (
                 <CustomSearch
                 placeholder={props.searchPlaceholder}
                 secondary
                 value={""}
                 handleClick={() => {
                  props.setModalTable(false);
                 }}
               />
            ):( 
              <CustomSearch
              placeholder={props.searchPlaceholder}
              secondary
              value={customSearchValue}
              handlesearch={(e) => {
                searchData(e);
                setCustomSearchValue(e);
                setActivePage(1);
              }}
            />
            )} 
            <div
              style={{
                display: "flex",
                gap: 10,
              }}
            >
              <div
                onClick={props.getActiveUsers}
                style={{
                  border: "1px solid #D7D7D7",
                  backgroundColor: "#F9F9F9",
                  color: "#3A3A3A",
                  height: 36,
                  padding: "3px 16px",
                  borderRadius: 5,
                  display:
                    window.location.pathname === "/activeUsersList"
                      ? "flex"
                      : "none",
                  alignItems: "center",
                }}
              >
                <span style={{ float: "left" }}>{CustomIcons.refreshIcon}</span>
                <button style={{ border: 0, background: "none" }}>
                  Refresh
                </button>
              </div>
              {/* <div
                style={{
                  border: "1px solid #D7D7D7",
                  backgroundColor: "#F9F9F9",
                  color: "#3A3A3A",
                  height: 36,
                  padding: "3px 16px",
                  alignItems: "center",
                  borderRadius: 5,
                  display:
                    window.location.pathname === "/activeUsersList"
                      ? "none"
                      : "flex" && window.location.pathname === "/manual-grading"
                      ? "none"
                      : "flex",
                }}
              >
                <span style={{ float: "left", marginRight: 6 }}>
                  {CustomIcons.filterIcon({ size: 16, color: "" })}
                </span>
                <button style={{ border: 0, background: "none" }}>Filter</button>
              </div> */}
              {checkedId.length > 0 && (
                <div
                  style={{
                    border: "1px solid #D7D7D7",
                    backgroundColor: "#F9F9F9",
                    color: "#3A3A3A",
                    borderRadius: 5,
                    height: 36,
                    padding: "3px 16px",
                    alignItems: "center",
                    display:
                      window.location.pathname === "/create-question-paper"
                        ? "flex"
                        : "none",
                  }}
                  onClick={() => {
                    toggleCloseModal();
                  }}
                >
                  <span style={{ float: "left", marginRight: 6 }}>
                    <i className="far fa-clone" />
                  </span>
                  <button style={{ border: 0, background: "none" }}>
                    Copy
                  </button>
                </div>
              )}
              {isSA && (
                <CSVLink
                  data={props.header == "Customers" || props.header == "Users"  ? props.dataHeader : props.data}
                  filename={`${props.header} List.csv`}
                >
                  <div
                    style={{
                      border: "1px solid #D7D7D7",
                      backgroundColor: "#F9F9F9",
                      color: "#3A3A3A",
                      display: "flex",
                      alignItems: "center",
                      height: 36,
                      padding: "3px 16px",
                      borderRadius: 5,
                    }}
                  >
                    <span style={{ float: "left", marginRight: 6 }}>
                      {CustomIcons.uploadIconTwo}
                    </span>
                    <button style={{ border: 0, background: "none" }}>
                      Export
                    </button>
                  </div>
                </CSVLink>
              )}
            </div>
          </div>
        </>
      )}
      <div style={{ backgroundColor: "#fff", padding: 10 }}>
        {allData.length > 0 ? (
          !props.loading ? (
            <MainTable
              checkedId={checkedId}
              setChecked={setChecked}
              setCheckedAll={setCheckedAll}
              checkedAll={checkedAll}
              toggleClick={(emailId) => {
                props.onClickChange(emailId);
                props.setModalTable(true);
              }
              }
              header={props.header}
              hoverable={props?.hoverable}
              tableHeaders={props.tableHeaders}
              setAllData={setAllData}
              allData={allData}
              createLink={props.createLink}
              history={history}
              accord={props.accord}
              pagination={{
                firstIndex,
                lastIndex,
              }}
              sub={{
                getAllSubs: props.getAllSubs,
                subTitle: props.subTitle,
                subData: props.subData,
                createSubLink: props.createSubLink,
                tableSubHeaders: props.tableSubHeaders,
                editSubData: props.editSubData,
                passSubEditFromRow: props.passSubEditFromRow,
              }}
              collapse={{
                collapse,
                handleCollapse,
              }}
              delete={{
                deleteId,
                setDeleteId,
                handleDeleteModal,
                hideDelete: props.hideDelete,
              }}
              edit={{
                passEditFromRow: props.passEditFromRow,
                setEditObject,
                hideEdit: props.hideEdit,
              }}
              view={{
                hideShowView: props.hideShowView,
              }}
              resetPass={{
                hideResetPass: props.hideResetPass,
              }}
              hideUseTemp={props.hideUseTemp}
              useTempOnclick={() => props.onClickTemp()}
              useTe
              hideSubAdd={props.hideSubAdd}
              hideSubEdit={props.hideSubEdit}
              hideSubDelete={props.hideSubDelete}
              customer={currentCustomer}
              activePage={activePage}
              itemsPerPage={itemsPerPage}
              // assessmentId={props.assessmentId}
              // assessmentName={props.assessmentName}
              customerId={props.customerId}
              overAllReport={props?.overAllReport}
              report={props.report}
            />
          ) : (
            <div>
              <Skeleton height={80} />
              <Skeleton height={80} />
              <Skeleton height={80} />
              <Skeleton height={80} />
              <Skeleton height={80} />
            </div>
          )
        ) : props.createLink === "/userManagement" ? (
          !currentCustomer ? (
            <h4 style={{ textAlign: "center" }}>
              Choose a Customer from the list above
            </h4>
          ) : (
            <EmptyState label="No Users available" />
          )
        ) : (
          <EmptyState label={`No ${props.header}`} />
        )}
        <div
          style={{
            borderTop: "1px solid lavender",
            marginTop: 10,
            padding: 20,
            paddingBottom: 0,
            backgroundColor: "#fff",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div style={{ fontSize: 14, fontWeight: 500 }}>
            Showing{" "}
            <select
              style={{
                height: 30,
                width: 100,
                borderRadius: 5,
                backgroundColor: "#F5F5F5",
                border: "1px solid lavender",
              }}
              value={itemsPerPage}
              onChange={(e) => {
                setItemsPerPage(e.target.value);
                setActivePage(1);
              }}
            >
              {entriesCount.map((count, ind) => (
                <option
                  key={ind}
                  selected={
                    !props.dataLength ? 0 : itemsPerPage === count ? count : ""
                  }
                >
                  {count}
                </option>
              ))}
            </select>{" "}
            of total {props.dataLength} entries
          </div>
          <div
            style={{
              marginRight: 10,
            }}
          >
            <Pagination
              {...paginationClasses}
              activePage={activePage}
              itemsCountPerPage={itemsPerPage}
              totalItemsCount={allData?.length}
              pageRangeDisplayed={5}
              onChange={(pageNo) => setActivePage(pageNo)}
            />
          </div>
        </div>
      </div>
    </Paper>
  );
}

export default connect(null, null)(CustomListTable);