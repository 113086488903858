import React, { useState, useEffect } from "react";
import { Card, CardBody, Container, Input, Button, Col, Row } from "reactstrap";
import Breadcrumbs from "components/Common/Breadcrumb";
import api from "../../../api/baseConfig";
import Apihelper from "../../../api/apiHelper";
import Select from "react-select";

const customStyles = {
  control: (styles) => ({
    ...styles,
    width: "500px",
  }),
  menu: (styles) => ({
    ...styles,
    width: "500px",
  }),
};

export default function ConsolidatedReportLogin(props) {
  const [studentId, setStudentId] = useState({});
  const [classes, setClasses] = useState([]);
  const [selectedClass, setSelectedClass] = useState({
    label: "Select a Class",
    value: "",
  });

  useEffect(async () => {
    Apihelper.axiosCallLC(
      `${api.lcBaseURL}${api.lcUserManagement.getclassess}`,
      "get"
    )
      .then((data) => {
        let option = [];
        if (data.details && classes.length === 0) {
          data.details.forEach((element, i) => {
            option.push({
              value: element.class_id,
              id: i,
              label: element.class_name,
            });
          });
          if (option !== classes) {
            setClasses(option);
          }
        }
      })
      .catch((e) => {});
  }, []);

  const getStuReport = async (e) => {
    e.preventDefault();

    await Apihelper.axiosCall(
      `${api.baseURL}${api.dashboard.getUserReport}${studentId.value}`,
      "get"
    )
      .then((d) => {
        props.history.push({
          pathname: "/ConsolidatedReport",
          state: { data: d, studentName: studentId.label },
        });
      })
      .catch((e) => {});
  };

  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs title="Report" breadcrumbItem="Overall Report" />
        <Card>
          <CardBody>
            <Row>
              <Col lg={2} style={{ marginRight: 7 }}>
                <label style={{ marginLeft: 14 }}>Select a Class</label>
              </Col>
              <Col>
                <Select
                  value={selectedClass}
                  name="colors"
                  styles={customStyles}
                  options={classes}
                  onChange={(e) => {
                    setSelectedClass(e);
                  }}
                />
              </Col>
            </Row>

            <Col>
              &nbsp;
              {/* <SelectStudent
                from={"ConsolidatedReportLogin"}
                data={selectedClass.value}
                optionUI={(val) => setStudentId(val)}
                returnIds={(val) => {}}
              /> */}
              &nbsp;
              <div style={{ textAlign: "center" }}>
                <Button
                  color="primary"
                  disabled={studentId?.value > 0 ? false : true}
                  onClick={(e) => getStuReport(e)}
                >
                  View Report
                </Button>
              </div>
            </Col>
          </CardBody>
        </Card>
      </Container>
    </div>
  );
}
