import { withRouter } from "react-router-dom";
import cn from "classnames";
import { Icon } from "@iconify/react";
import shoppingCart from "@iconify/icons-majesticons/shopping-cart";
import GridItem from "../../../components/GridItem/GridItem";
import Dots from "../../../icons/dots";
import styles from "./BuyPackages.module.scss";

export const BuyPackages = withRouter(({ showReal, isMobile, ...props }) => {
  return (
    <GridItem
      gridPosition={[
        isMobile ? [1, -1] : [1, 3],
        showReal ? (isMobile ? [8, 9] : [4, 5]) : isMobile ? [8, 9] : [5, 6],
      ]}
    >
      <div className={styles.packagePurchaseContainer}>
        <Dots
          className={cn(
            styles.packagePurchaseContainerDots,
            styles.packagePurchaseContainerDots1
          )}
        />
        <span className={styles.packagePurchaseText}>
          Improve your
          <br />
          skills by enrolling
          <br />
          in our packages
        </span>
        <button
          className={styles.packagePurchaseButton}
          onClick={() => {
            props.history.push("/lab");
          }}
        >
          <Icon
            icon={shoppingCart}
            style={{ color: "#ffffff", fontSize: 20 }}
          />
          &nbsp;&nbsp;
          {"Buy Packages"}
        </button>
        <Dots
          className={cn(
            styles.packagePurchaseContainerDots,
            styles.packagePurchaseContainerDots2
          )}
        />
      </div>
    </GridItem>
  );
});
