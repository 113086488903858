import { useState, useEffect, useRef } from "react";
import styles from "./Python.module.scss";
import { circleCheckSolid } from "assets/icons/icons";
import { uid } from "react-uid";
import api from "api/baseConfig";
import apiHelper from "api/apiHelper";
import SignInModal from "components/VerticalLayout/SignInModal";
import { ModalToggle } from "pages/utils/ModalToggle";
import { CalculateTimeLeft } from "./../utils/CalculateTimeLeft";
import { withStyles, Button } from "@material-ui/core";
import MarqueeComponent from "react-fast-marquee";
import sliderBannerBg2 from "../../assets/Landing/TestimonialBG.png";
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
  DotGroup,
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import {
  amazon,
  cognizant,
  accenture,
  adobe,
  infosys,
  capgemini,
  tcs,
  zoho,
  freshworks,
  google,
  hcl,
  hp,
  ibm,
  ms,
  morganstanley,
  msigma,
  paypal,
  paytm,
  sopia,
  techmahindra,
  boeing,
  verizon,
  walmart,
  wipro,
} from "./../LoginPage/New/PlacedCompanies";
import {
  Gift,
  PreparationBannerSvg,
  StarCornerSvg,
  Pythonlogo,
  AchievementSvg,
  WiproLogoMobile,
} from "./CompanyPackageSVGs";
import { ReviewsList } from "./CompanyPackageContent";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useMediaQuery } from "@material-ui/core";
import moment from "moment";
import  Frame  from "../../assets/Landing/Desktop2.png";
import { arrowLeft, arrowRight, Blob1, } from "../../assets/icons/icons";
import sliderBannerBg from "../../assets/Landing/Frame.png";
import slide1 from "../../assets/Landing/python1.png";
import slide2 from "../../assets/Landing/python2.png";
import slide3 from "../../assets/Landing/python3.png";
import slide4 from "../../assets/Landing/python4.png";
import slide5 from "../../assets/Landing/python5.png";
import slide6 from "../../assets/Landing/python6.png";
import slide7 from "../../assets/Landing/python7.png";
import slide8 from "../../assets/Landing/python8.png";
import slide9 from "../../assets/Landing/python9.png";
import Scroll1 from "../../assets/Landing/Scroll1.png";
import Scroll2 from "../../assets/Landing/Scroll2.png";
import Scroll3 from "../../assets/Landing/Scroll3.png";
import Scroll4 from "../../assets/Landing/Scroll4.png";
import Scroll5 from "../../assets/Landing/Scroll5.png";
import Scroll6 from "../../assets/Landing/Scroll6.png";
import Scroll7 from "../../assets/Landing/Scroll7.png";
import Scroll8 from "../../assets/Landing/Scroll8.png";
import Scroll9 from "../../assets/Landing/Scroll9.png";
import Scroll10 from "../../assets/Landing/Scroll10.png";
import Group1 from "../../assets/Landing/Group1.png";
import Group2 from "../../assets/Landing/Group2.png";
import Group3 from "../../assets/Landing/Group3.png";
import Group4 from "../../assets/Landing/Group4.png";

import Zero from "../../assets/Landing/zero.png";
import Coding from "../../assets/Landing/coding.png";


const PythonNew = () => {
  const [packageData, setPackageData] = useState({});
  const [viewLearningPath, setViewLearningPath] = useState(null);
  const [openSignIn, setOpenSignIn] = useState(false);
  const [signInQuestion, setSignInQuestion] = useState(null);
  const smallScreen = useMediaQuery("(max-width: 1080px)");
  const videoEl = useRef(null);
  const [video, setVideo] = useState();
  const [isHovered, setIsHovered] = useState(false);
  
  const [lines, setLines] = useState([]);
  const [currentLine, setCurrentLine] = useState(0);
  const [currentWord, setCurrentWord] = useState(0);
  const [currentCharacter, setCurrentCharacter] = useState(0);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  const currentDate = new Date().toLocaleString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric'
  });

  const textData = [
    "python.",
    `Python 3.11.5 (${currentDate}).`,
    ">>> you = AspiringSoftwareEngineer().",
    ">>>>",
    ">>> print(you.knows_how_to_code().",
    ">>>>",
    "false.",
    ">>>>",
    ">>> if you.wants_to_learn_to_code()::",
    "... you.purchase (Terv).",
    "....",
    ">>> while you.is_learning_to_code()::",
    "... you.use (Terv).",
    "....",
    ">>> quit().",
    "....",
  ];

  useEffect(() => {
    const lineInterval = setInterval(() => {
      if (currentCharacter < textData[currentLine].length) {
        setCurrentCharacter(prevChar => prevChar + 1);
      } else {
        setCurrentWord(0);
        setCurrentCharacter(0);
        clearInterval(lineInterval);

        if (currentLine === textData.length - 1) {
          setTimeout(() => {
            setLines([]);
            setCurrentLine(0);
          }, 1000); 
        } else {
          setCurrentLine(prevLine => prevLine + 1);
        }
      }
    }, 100); 

    return () => {
      clearInterval(lineInterval);
    };
  }, [currentCharacter, currentLine, currentWord, textData]);

  useEffect(() => {
    const timer = setTimeout(() => {
      const newLines = [...lines];
      newLines[currentLine] = textData[currentLine].slice(0, currentCharacter);
      setLines(newLines);
    }, 100); 

    return () => clearTimeout(timer);
  }, [currentCharacter, currentLine, lines, textData]);

  const handleMouseEnter = () => {
    setIsHovered(true);
  }

  const handleMouseLeave = () => {
    setIsHovered(false);
  }

  const attemptPlay = () => {
    videoEl &&
      videoEl.current
  };

  useEffect(() => {
    attemptPlay();
  }, []);

  useEffect(() => {
    getPackageData();
  }, []);

  const isLoggedIn = useSelector((state) => state.LoggedInReducer);

  const toggleSignIn = () => setOpenSignIn(ModalToggle);

  const getPackageData = async () => {
    try {
      const data = await apiHelper.axiosCall(
        `${api.baseURL}${
          api.lab.userPracticeLab
        }6149c082c1b0aa34effb02f8/${sessionStorage.getItem("user_id")}`,
        "get"
      );

      setPackageData(data);
    } catch (error) {}
  };

  const solvedQuestionsList = [];
  const todoQuestionsList = [];
  const easyQuestionsList = [];
  const mediumQuestionsList = [];
  const hardQuestionsList = [];
  const cdTypeQuestionsList = [];
  const mcTypeQuestionsList = [];
  const categorizedQuestionsList = {};

  packageData?.questionsList?.forEach((question) => {
    if (question?.solutionMap?.completed) {
      solvedQuestionsList.push(question);
    } else {
      todoQuestionsList.push(question);
    }

    if (question?.complexity === "easy") {
      easyQuestionsList.push(question);
    } else if (question?.complexity === "medium") {
      mediumQuestionsList.push(question);
    } else if (question?.complexity === "hard") {
      hardQuestionsList.push(question);
    }

    if (question?.type === "MC") {
      mcTypeQuestionsList.push(question);
    } else if (question?.type === "CD") {
      cdTypeQuestionsList.push(question);
    }

    if (categorizedQuestionsList[question?.category]) {
      categorizedQuestionsList[question?.category].push(question);
    } else {
      categorizedQuestionsList[question?.category] = [question];
    }
  });

  return (
    <div style={{ backgroundColor: "#261B4E" }}>
    
      <div className={smallScreen ? styles.heroCardMobile : styles.heroCard}>
        <div className={smallScreen ? styles.heroBannerMobile : styles.heroBanner} style={{ backgroundImage: `url(${sliderBannerBg})`, backgroundPosition: 'bottom right', backgroundRepeat: 'no-repeat', backgroundSize:"520px"  }}>

        <div
          className={
            smallScreen
              ? styles.heroCardChildOneMobile
              : styles.heroCardChildOne
          }
        >
    
           <p>
            Programming Pro
          </p>

          {smallScreen ? (
            <div>
            <h3
              className={styles.heroTitle}
            >
              Mastering Python Online
            </h3>
           
            </div>
          ):(
          <div>
          <h3
            className={styles.heroTitle}
          >
            Mastering Python
          </h3>
          <h3
            className={styles.heroTitle}
          >
           Online
          </h3>
          </div>
          )}
            <div style={smallScreen ? {display:"flex", gap:'2%', flexDirection:"column", paddingTop:"3%"} : {display:"flex", gap:'2%', paddingTop:"3%"}}>
            <p className={styles.boxexpert} >
            Professional Certification 
            </p>
            <p className={styles.boxexpert} >
            Part-Time 
            </p>
            <p className={styles.boxexpert} >
            Expert Instructors
            </p>
          </div>
          <div style={{width:"8%", paddingBottom:"3%",}}>
          <hr style={{ height:"6px", backgroundColor:"#2FFF4B", textAlign:"left"}}/>
          </div>
           <Link
            to={ {
                pathname: "/pythonCourse",
                params: { items: [packageData?.id] },
              }
            }
          > 
              {/* <Link
              href="/pythonCourse"
            > */}
            <Button variant="outlined" style={{color:"#794DF5",borderColor: '#794DF5',textTransform: "capitalize", fontSize:"16px"}}>Enroll now</Button>
          </Link>
          <p style={{paddingTop:"4%", width:"90%"}}>
          Learn to program with Python, one of the most popular programming languages for Web Developers, Data Scientists, and Machine Learning Engineers.
          </p>
         
        </div>
        
        <div
          className={
            smallScreen
              ? styles.heroCardChildTwoMobile
              : styles.heroCardChildTwo
          }

        >
        <div style={{backgroundColor:"#1c2736", 
        borderRadius:"4px", 
        height:"100%", 
        width:"100%", 
        padding:"2px, 5px",
        color:'#ffffff',
        margin:"30px,10px"}}>
          <div className={styles.typingEffect}>
      <div>
        {lines.map((line, index) => (
          <div key={index} className="line">{line}
           </div>
        ))}
      </div>
    </div>
        </div>
        </div>

        </div>

        <div className={smallScreen ? styles.marqueeFormatMob : styles.marqueeFormat}>
          <div style={{paddingTop:"1.5%"}}>
          <p className={smallScreen ? styles.heroSecTitleMobile : styles.heroSecTitle}>Popular Opportunities</p>
          </div>
          
      

           <div style={{display:"flex", overflow:"hidden"}}>
           <div><img src={slide1} ></img> </div>
           <div><img src={slide2} ></img> </div>
           <div><img src={slide3} ></img> </div>
           <div><img src={slide4} ></img> </div>
           <div><img src={slide5} ></img> </div>
           <div><img src={slide6} ></img> </div>
           <div><img src={slide7} ></img> </div>
           <div><img src={slide8} ></img> </div>
           <div><img src={slide9} ></img> </div>
      </div>
        </div>
      </div>

      <div className={smallScreen ? styles.heroCardOverviewMobile : styles.heroCardOverview}>

      <div style={{paddingBottom:"4%" , width:"100%",}}>
      <div style={{ paddingTop:"3%", paddingBottom:"2%", backgroundColor:"#6B41E0",  backgroundImage: `url(${sliderBannerBg2})`, backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat',  }}>
      
      <div style={{display:"flex", justifyContent:"center", textAlign:'center', }}>

       <div>
       <p style={{color:"#ffffff", fontSize:"18px"}}><span style={{fontSize:"38px"}}>Python</span> is the No. 1 fastest-growing major programming language</p>
       <p style={{ color: "#ffffff", width: "80%", textAlign: 'center', margin: 'auto', fontSize:"18px" }}>
  The future is bright for programmers who know Python — it’s a baseline skill for competitive industries like analytics, artificial intelligence, cybersecurity, and data science. And, thanks to its intuitive, readable syntax, it’s also one of the easiest languages to learn on the market.
</p>
       {/* <p style={{color:"#ffffff", paddingTop:'1%'}}>Statistic source</p> */}
       <Button variant="outlined" style={{color:"#ffffff",borderColor: '#794DF5',textTransform: "capitalize", fontSize:"16px",marginTop:"10px"}}><a style={{color:"white"}} target="_blank" href="https://stackoverflow.blog/2017/09/06/incredible-growth-python/">Statistic source</a> </Button>
       </div>

  </div>

        </div>
        </div>


{/* first */}
        <div className={smallScreen ? styles.heroBannerMobile : styles.heroBanner3}>

<div
  className={
    smallScreen
      ? styles.heroCardChildOneMobile
      : styles.heroCardChildOne
  }
>
<p style={{width:"90%"}}>
You cannot learn swimming by referring<span style={{color:"#2FFF4B", fontSize:"24px"}}> to books and same with coding.</span></p>
   <p>
   All you need in one single streamlined platform <span style={{color:"#6C42E1", fontSize:"24px"}}>TERV</span>
  </p>

    <div style={{display:"flex", gap:'2%', paddingTop:"3%"}}>
    <div className={styles.box} >
    <div><img src={Group1} ></img> </div>
    <p style={{ textAlign:"left", fontSize:"12px",paddingTop:'5%'}}>Get immersed in our high-quality concept walkthrough & Question explanation videos</p>
    </div>
    <div className={styles.box} >
    <div><img src={Group2} ></img> </div>
    <p style={{ textAlign:"left", fontSize:"12px",paddingTop:'5%'}}> Hand-picked Questions to solidify your understanding and prepares you to meet today’s job demand</p>
    </div>
  </div>

  <div style={{display:"flex", gap:'2%', paddingTop:"3%"}}>
    <div className={styles.box} >
    <div><img src={Group3} ></img> </div>
    <p style={{ textAlign:"left", fontSize:"12px",paddingTop:'5%'}}> Time - Space Complexity Analysis to improve your Accuracy, Finiteness & Efficiency</p>
    </div>
    <div className={styles.box} >
    <div><img src={Group4} ></img> </div>
    <p style={{ textAlign:"left", fontSize:"12px",paddingTop:'5%'}}>Rich performance analytics & Industry recognized Professional Certificate</p>
    </div>
  </div>
</div>

<div><img src={Coding} style={{width:"100%"}}></img> </div>

</div>

<div className={smallScreen ? styles.heroBannerMobile : styles.heroBanner2} >

<div
  className={
    smallScreen
      ? styles.heroCardChildOneMobile
      : styles.heroCardChildOne
  }
>

   <p style={{color:"#2FFF4B"}}>
   Python 
  </p>

  {smallScreen ? (
    <div>
    <h3
      className={styles.heroTitle2}
    >
      Go from <span style={{color:"#2FFF4B"}}>ZERO TO HERO</span> with our most advanced curriculum
    </h3>
   
    </div>
  ):(
  <div>
  <h3
    className={styles.heroTitle2}
  >
    Go from <span style={{color:"#2FFF4B"}}>ZERO TO HERO</span>
  </h3>
  <h3
    className={styles.heroTitle2}
  >
   with our most advanced
  </h3>
  <h3
    className={styles.heroTitle2}
  >
   curriculum
  </h3>
  </div>
  )}
   <Link
            to={ {
                pathname: "/pythonCourse",
                params: { items: [packageData?.id] },
              }
            }
          > 
    <Button style={{backgroundColor:"#794DF5",color:"#ffffff",textTransform: "capitalize", fontSize:"12px"}}>Go to Course</Button>
  </Link>
  <p style={{paddingTop:"4%", width:"90%", fontSize:"12px"}}>
  Learn by doing with help from world-classpractitioners who use Pythonevery day. Instructors bring the latest techniques from the field straight tothe classroom. Our thoughtfully designed curriculum emphasises real-worldrelevance and offers a new age learning platform with 10x efficiency and multi-lingual programming features in a seamless execution environment bringing youclose to the real world with
   assistance to solve every questions with hints,test cases, video explanation and expert led solutions. All in one workspacewith no installation or setup time.
  </p>
 
</div>


<div><img src={Zero} style={{width:"100%"}}></img> </div>

</div>

{/* second */}
        <div
        className={
          smallScreen
            ? styles.learningContainerMobile
            : styles.learningContainer
        }  
      >
       

        <div style={{paddingTop:"3%", textAlign:'center', color:'#ffffff'}}>
        <h4 style={{color:'#ffffff'}}>We are Accredited By</h4>

        
        <div className={styles.marquee} style={{paddingTop:"2%"}}>
    
        <div className={styles.marqueeContent}>
     
        <div><img src={Scroll1} ></img> </div>
           <div><img src={Scroll2} ></img> </div>
           <div><img src={Scroll3} ></img> </div>
           <div><img src={Scroll4} ></img> </div>
           <div><img src={Scroll5} ></img> </div>
           <div><img src={Scroll6} ></img> </div>
           <div><img src={Scroll7} ></img> </div>
           <div><img src={Scroll8} ></img> </div>
           <div><img src={Scroll9} ></img> </div>
           <div><img src={Scroll10} ></img> </div>
      </div>
    </div>  
        </div>
       
      </div>
      </div>
      
      {/* <Footer /> */}
      <SignInModal
        modal={openSignIn}
        setModal={toggleSignIn}
        signInQuestion={signInQuestion}
        buyPkg
        pkgId={packageData?.id}
      />
    </div>
  );
};

export default PythonNew;
