import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import styles from './modalTableView.module.scss';
import CapitalizeFirstLetter from '../utils/CapitalizeFirstLetter'

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#C5EAFC',
    color: '#000000',
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

// const useStyles = makeStyles({
//   table: {
//     minWidth: 650,
//   },
// })

export default function CustomizedTables(props) {
  var listQuestions = [];
  props?.sectionList?.map((e) => {
    listQuestions.push(e?.questionList)
  })

  return (
    <TableContainer component={Paper}>
      <Table aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Question name</StyledTableCell>
            <StyledTableCell>Type</StyledTableCell>
            <StyledTableCell>Complexity</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {listQuestions[props?.tableValue || 0]?.map((row) => (
            <StyledTableRow key={row.id}>
                <StyledTableCell>
                    {row.name}
                </StyledTableCell>
                <StyledTableCell>{row?.type}</StyledTableCell>
                <StyledTableCell>
                  <div 
                    className={
                      row?.complexity == 'easy' ? styles.complexButtonEasy : 
                      row?.complexity == 'medium' ? styles.complexButtonMed : 
                      row?.complexity == 'hard' ? styles.complexButtonHard : ''
                    }
                    style={{textTransform: 'capitalize'}}
                  >
                        {row?.complexity}         
                    </div>
                </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}