import cn from 'classnames';
import styles from './timer.module.scss';

export default function Timer(props) {
  return (
    <div
      className={cn(
        styles.timer,
        (props?.size === 'regular') ? styles.regular : styles.compact,
      )}>
      <span className={styles.number}>
        {('0' + Math.floor(((props.time / 60000) / 60) % 60)).slice(-2)}
      </span>
      <span className={styles.separator}>:</span>
      <span className={styles.number}>
        {('0' + Math.floor((props.time / 60000) % 60)).slice(-2)}
      </span>
      <span className={styles.separator}>:</span>
      <span className={styles.number}>
        {('0' + Math.floor((props.time / 1000) % 60)).slice(-2)}
      </span>
    </div>
  );
}