import MaterialTable, { MTableToolbar } from "@material-table/core";
import React, {useState, useRef, useEffect } from "react";
import styles from "../../report/components/report-table/index.module.scss";
// import { useState, useRef, useEffect } from "react";
import { Dialog, DialogContent } from "@material-ui/core";
import GetAppIcon from "@material-ui/icons/GetApp";
import Backdrop from "@material-ui/core/Backdrop";
import DialogContentText from "@material-ui/core/DialogContentText";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import Box from "@material-ui/core/Box";
import Checkbox from "@material-ui/core/Checkbox";
import Divider from "@material-ui/core/Divider";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "none",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

function ExportButton({
  exportCSV,
  exportHTML,
  exportPDF,
  loadingCsvExport,
  loadingHtmlExport,
  loadingPdfExport,
  exportDepartmentPerformancePdf,
  exportStudentPerformancePdf,
  exportSectionPerformancePdf,
  exportMockPerformancePdf,
  exportDeptStudPerformancePdf,
  exportDeptSecPerformancePdf,
  exportDeptMockPerformancePdf,
  exportStudSecPerformancePdf,
  exportStudMockPerformancePdf,
  exportSecMockPerformancePdf,
  exportDeptSecStudPerformancePdf,
  exportDeptStudMockPerformancePdf,
  exportDeptSecMockPerformancePdf,
  exportDepartmentPerformanceHtml,
  exportStudentPerformanceHtml,
  exportSectionPerformanceHtml,
  exportMockPerformanceHtml,
  exportDeptStudPerformanceHtml,
  exportDeptSecPerformanceHtml,
  exportDeptMockPerformanceHtml,
  exportStudSecPerformanceHtml,
  exportStudMockPerformanceHtml,
  exportSecMockPerformanceHtml,
  exportDeptSecStudPerformanceHtml,
  exportDeptStudMockPerformanceHtml,
  exportDeptSecMockPerformanceHtml,
  exportDepartmentPerformanceCsv,
  exportStudentPerformanceCsv,
  exportSectionPerformanceCsv,
  exportMockPerformanceCsv,
  exportDeptStudPerformanceCsv,
  exportDeptSecPerformanceCsv,
  exportDeptMockPerformanceCsv,
  exportStudSecPerformanceCsv,
  exportStudMockPerformanceCsv,
  exportSecMockPerformanceCsv,
  exportDeptSecStudPerformanceCsv,
  exportDeptStudMockPerformanceCsv,
  exportDeptSecMockPerformanceCsv,
  exportProctoringPdf,
  exportProctoringCsv,
  exportProctoringHtml,
  exportProctoringDeptPdf,
  exportProctoringSectionPdf,
  exportProctoringStudentPdf,
  exportProctoringStuSecPdf,
  exportProctoringSecdeptPdf,
  exportProctoringStudeptPdf,
  mockButton = [],
  // exportStudentPerformanceHtml,
  // exportSectionPerformanceHtml,
  // exportDepartmentPerformanceHtml,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [value, setValue] = useState("");
  const [error, setError] = useState(false);
  const [state, setState] = useState({});
  const [checked, setChecked] = useState(false);
  const [departmentPerformance, setDepartmentPerformance] = useState(false);
  const [studentPerformance, setStudentPerformance] = useState(false);
  const [sectionPerformance, setSectionPerformance] = useState(false);
  const [mockPerformance, setMockPerformance] = useState(false);
  const [proctoringLog, setProctoringLog] = useState(false);

  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = () => {
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const classes = useStyles();
  const handleRadioChange = (event) => {
    setValue(event.target.value);
    setError(false);
  };

  const handleCheckBox = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };
  const ReportAll = (e) => {
    setChecked(!checked);
  };
  const DepartmentPerformance = (e) => {
    setDepartmentPerformance(!departmentPerformance);
  };
  const StudentPerformance = (e) => {
    setStudentPerformance(!studentPerformance);
  };
  const SectionPerformance = (e) => {
    setSectionPerformance(!sectionPerformance);
  };
  const MockPerformance = (e) => {
    setMockPerformance(!mockPerformance);
  };
  const ProctoringLogDetails = (e) => {
    setProctoringLog(!proctoringLog)
  }

  
  return (
    <>
      <FormControl>
        <Button
          className={styles.exportButton}
          startIcon={<GetAppIcon />}
          aria-label="Export"
          aria-haspopup="true"
          aria-controls="export-menu"
          onClick={handleClickListItem}
        >
          Export
        </Button>
        <Menu
          id="export-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          style={{ padding: "110px" }}
        >
          <p style={{ fontWeight: 500, fontSize: "14px", paddingLeft: "10px" }}>
            Export As
          </p>
          <RadioGroup
            aria-label="quiz"
            name="form-lable"
            value={value}
            onChange={handleRadioChange}
          >
            <Box
              display="flex"
              flexDirection="row"
              style={{ paddingLeft: "10px" }}
            >
              <FormControlLabel
                style={{ fontWeight: 400, fontSize: "14px" }}
                control={<Radio color="primary" />}
                value="html"
                labelPlacement="end"
                size="small"
                label="HTML"
              />
              <FormControlLabel
                style={{ fontWeight: 400, fontSize: "14px" }}
                control={<Radio color="primary" />}
                value="pdf"
                labelPlacement="end"
                size="small"
                label="PDF"
              />
              <FormControlLabel
                style={{ fontWeight: 400, fontSize: "14px" }}
                control={<Radio color="primary" />}
                value="csv"
                labelPlacement="end"
                size="small"
                label="Excel"
              />
            </Box>
          </RadioGroup>
          <p style={{ fontWeight: 500, fontSize: "14px", paddingLeft: "10px" }}>
            Select Report
          </p>
          <Box
            display="flex"
            flexDirection="column"
            style={{ paddingLeft: "10px" }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={
                    (departmentPerformance &&
                      studentPerformance &&
                      sectionPerformance &&
                      mockPerformance &&
                      proctoringLog) ||
                    checked
                  }
                  onChange={(handleCheckBox, ReportAll)}
                  name="checked"
                  color="primary"
                />
              }
              label="All report"
            />
            <Divider variant="middle" />
            <FormControlLabel
              control={
                <Checkbox
                  checked={departmentPerformance || checked}
                  onChange={(handleCheckBox, DepartmentPerformance)}
                  name="checked"
                  color="primary"
                />
              }
              label="Department performance"
            />
            <Divider variant="middle" />
            <FormControlLabel
              control={
                <Checkbox
                  checked={studentPerformance || checked}
                  onChange={(handleCheckBox, StudentPerformance)}
                  name="checked"
                  color="primary"
                />
              }
              label="Student performance"
            />
            <Divider variant="middle" />
            <FormControlLabel
              control={
                <Checkbox
                  checked={sectionPerformance || checked}
                  onChange={(handleCheckBox, SectionPerformance)}
                  name="checked"
                  color="primary"
                />
              }
              label="Section performance"
            />
            <Divider variant="middle" />
            <FormControlLabel
              control={
                <Checkbox
                  checked={ proctoringLog || checked }
                  onChange={(handleCheckBox, ProctoringLogDetails)}
                  name="checked"
                  color="primary"
                />
              }
              label="AI Proctoring"
            />
            {exportSecMockPerformancePdf ? (
              <div>
                <Divider variant="middle" />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={mockPerformance || checked}
                      onChange={(handleCheckBox, MockPerformance)}
                      name="checked"
                      color="primary"
                    />
                  }
                  label="Mock Performance"
                />{" "}
              </div>
            ) : (
              ""
            )}
          </Box>
          <Box style={{ paddingLeft: "80px" }}>
            <Button
              variant="contained"
              style={{ backgroundColor: "#794DF5", color: "white" }}
              onClick={(event) => {
                (value == "html" && checked) ||
                (value == "html" &&
                  departmentPerformance &&
                  studentPerformance &&
                  sectionPerformance &&
                  mockPerformance)
                  ? exportHTML?.() && handleMenuItemClick(event)
                  : (value == "pdf" && checked) ||
                    (value == "pdf" &&
                      departmentPerformance &&
                      studentPerformance &&
                      sectionPerformance &&
                      mockPerformance)
                  ? exportPDF?.() && handleMenuItemClick(event)
                  : (value == "csv" && checked) ||
                    (value == "csv" &&
                      departmentPerformance &&
                      studentPerformance &&
                      sectionPerformance &&
                      mockPerformance)
                  ? exportCSV?.() && handleMenuItemClick(event)
                  : value == "pdf" &&
                    departmentPerformance &&
                    studentPerformance &&
                    sectionPerformance
                  ? exportDeptSecStudPerformancePdf?.() &&
                    handleMenuItemClick(event)
                  : value == "pdf" &&
                    departmentPerformance &&
                    studentPerformance &&
                    mockPerformance
                  ? exportDeptStudMockPerformancePdf?.() &&
                    handleMenuItemClick(event)
                  : value == "pdf" &&
                    departmentPerformance &&
                    sectionPerformance &&
                    mockPerformance
                  ? exportDeptSecMockPerformancePdf?.() &&
                    handleMenuItemClick(event)
                  : value == "pdf" &&
                    departmentPerformance &&
                    studentPerformance
                  ? exportDeptStudPerformancePdf?.() &&
                    handleMenuItemClick(event)

                  : value ="pdf" && departmentPerformance && sectionPerformance && proctoringLog
                  ? exportProctoringSecdeptPdf?.() && handleMenuItemClick(event)
                  : value ="pdf" && studentPerformance && sectionPerformance && proctoringLog
                  ? exportProctoringStuSecPdf?.() && handleMenuItemClick(event)
                  : value ="pdf" && departmentPerformance && studentPerformance && proctoringLog
                  ? exportProctoringStudeptPdf?.() && handleMenuItemClick(event)


                  : value ="pdf" && departmentPerformance && proctoringLog
                  ? exportProctoringDeptPdf?.() && handleMenuItemClick(event)   
                  : value == "pdf" &&
                    departmentPerformance &&
                    sectionPerformance
                  ? exportDeptSecPerformancePdf?.() &&
                    handleMenuItemClick(event)
                  : value == "pdf" && sectionPerformance && proctoringLog
                  ? exportProctoringSectionPdf?.() && handleMenuItemClick(event)
                  : value == "pdf" && studentPerformance && proctoringLog
                  ? exportProctoringStudentPdf?.() && handleMenuItemClick(event)
                  : value == "pdf" && departmentPerformance && mockPerformance
                  ? exportDeptMockPerformancePdf?.() &&
                    handleMenuItemClick(event)
                  : value == "pdf" && studentPerformance && sectionPerformance
                  ? exportStudSecPerformancePdf?.() &&
                    handleMenuItemClick(event)
                  : value == "pdf" && studentPerformance && mockPerformance
                  ? exportStudMockPerformancePdf?.() &&
                    handleMenuItemClick(event)
                  : value == "pdf" && sectionPerformance && mockPerformance
                  ? exportSecMockPerformancePdf?.() &&
                    handleMenuItemClick(event)
                  : value == "pdf" && departmentPerformance
                  ? exportDepartmentPerformancePdf?.() &&
                    handleMenuItemClick(event)
                  : value == "pdf" && studentPerformance
                  ? exportStudentPerformancePdf?.() &&
                    handleMenuItemClick(event)
                  : value == "pdf" && sectionPerformance
                  ? exportSectionPerformancePdf?.() &&
                    handleMenuItemClick(event)
                  : value == "pdf" && mockPerformance
                  ? exportMockPerformancePdf?.() && handleMenuItemClick(event)
                  
                  : value == "html" &&
                    departmentPerformance &&
                    studentPerformance &&
                    sectionPerformance
                  ? exportDeptSecStudPerformanceHtml?.() &&
                    handleMenuItemClick(event)
                  : value == "html" &&
                    departmentPerformance &&
                    studentPerformance &&
                    mockPerformance
                  ? exportDeptStudMockPerformanceHtml?.() &&
                    handleMenuItemClick(event)
                  : value == "html" &&
                    departmentPerformance &&
                    sectionPerformance &&
                    mockPerformance
                  ? exportDeptSecMockPerformanceHtml?.() &&
                    handleMenuItemClick(event)
                  : value == "html" &&
                    departmentPerformance &&
                    studentPerformance
                  ? exportDeptStudPerformanceHtml?.() &&
                    handleMenuItemClick(event)
                  : value == "html" &&
                    departmentPerformance &&
                    sectionPerformance
                  ? exportDeptSecPerformanceHtml?.() &&
                    handleMenuItemClick(event)
                  : value == "html" && departmentPerformance && mockPerformance
                  ? exportDeptMockPerformanceHtml?.() &&
                    handleMenuItemClick(event)
                  : value == "html" && studentPerformance && sectionPerformance
                  ? exportStudSecPerformanceHtml?.() &&
                    handleMenuItemClick(event)
                  : value == "html" && studentPerformance && mockPerformance
                  ? exportStudMockPerformanceHtml?.() &&
                    handleMenuItemClick(event)
                  : value == "html" && sectionPerformance && mockPerformance
                  ? exportSecMockPerformanceHtml?.() &&
                    handleMenuItemClick(event)
                  : value == "html" && departmentPerformance
                  ? exportDepartmentPerformanceHtml?.() &&
                    handleMenuItemClick(event)
                  : value == "html" && studentPerformance
                  ? exportStudentPerformanceHtml?.() &&
                    handleMenuItemClick(event)
                  : value == "html" && sectionPerformance
                  ? exportSectionPerformanceHtml?.() &&
                    handleMenuItemClick(event)
                  : value == "html" && mockPerformance
                  ? exportMockPerformanceHtml?.() && handleMenuItemClick(event)
                  : value == "csv" &&
                    departmentPerformance &&
                    studentPerformance &&
                    sectionPerformance
                  ? exportDeptSecStudPerformanceCsv?.() &&
                    handleMenuItemClick(event)
                  : value == "csv" &&
                    departmentPerformance &&
                    studentPerformance &&
                    mockPerformance
                  ? exportDeptStudMockPerformanceCsv?.() &&
                    handleMenuItemClick(event)
                  : value == "csv" &&
                    departmentPerformance &&
                    sectionPerformance &&
                    mockPerformance
                  ? exportDeptSecMockPerformanceCsv?.() &&
                    handleMenuItemClick(event)
                  : value == "csv" &&
                    departmentPerformance &&
                    studentPerformance
                  ? exportDeptStudPerformanceCsv?.() &&
                    handleMenuItemClick(event)
                  : value == "csv" &&
                    departmentPerformance &&
                    sectionPerformance
                  ? exportDeptSecPerformanceCsv?.() &&
                    handleMenuItemClick(event)
                  : value == "csv" && departmentPerformance && mockPerformance
                  ? exportDeptMockPerformanceCsv?.() &&
                    handleMenuItemClick(event)
                  : value == "csv" && studentPerformance && sectionPerformance
                  ? exportStudSecPerformanceCsv?.() &&
                    handleMenuItemClick(event)
                  : value == "csv" && studentPerformance && mockPerformance
                  ? exportStudMockPerformanceCsv?.() &&
                    handleMenuItemClick(event)
                  : value == "csv" && sectionPerformance && mockPerformance
                  ? exportSecMockPerformanceCsv?.() &&
                    handleMenuItemClick(event)
                  : value == "csv" && departmentPerformance
                  ? exportDepartmentPerformanceCsv?.() &&
                    handleMenuItemClick(event)
                  : value == "csv" && studentPerformance
                  ? exportStudentPerformanceCsv?.() &&
                    handleMenuItemClick(event)
                  : value == "csv" && sectionPerformance
                  ? exportSectionPerformanceCsv?.() &&
                    handleMenuItemClick(event)
                  : value == "csv" && mockPerformance
                  ? exportMockPerformanceCsv?.() && handleMenuItemClick(event)
                  : value == "html" && proctoringLog
                  ? exportProctoringHtml?.() && handleMenuItemClick(event)
                  : value == "csv" && proctoringLog
                  ? exportProctoringCsv?.() && handleMenuItemClick(event)
                  : value == "pdf" && proctoringLog
                  ? exportProctoringPdf?.() && handleMenuItemClick(event)                  
                  : "";
              }}
            >
              Download
            </Button>
          </Box>
        </Menu>
      </FormControl>

      {/* <Menu
        id="export-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <ListSubheader>Export As</ListSubheader>
        <RadioGroup aria-label="quiz" name="form-lable" value={value} onChange={handleRadioChange}>
        <FormControlLabel
          disabled={loadingHtmlExport}
          checked={selectedValue === 'HTML'}
          control={<Radio />}
          value = "html"
          // onClick={(event) => {
          //   exportHTML?.();
          //   handleMenuItemClick(event);
          // }}
        >
          HTML
        </FormControlLabel>
        <FormControlLabel
          disabled={loadingPdfExport}
          checked={selectedValue === 'PDF'}
          control={<Radio />}
          value = "pdf"
          // onClick={(event) => {
          //   exportPDF?.();
          //   handleMenuItemClick(event);
          // }}
        >
          PDF
        </FormControlLabel>
        <FormControlLabel
          disabled={loadingCsvExport}
          checked={selectedValue === 'CSV'}
          control={<Radio />}
          value = "csv"
          onClick={(event) => {
            exportCSV?.();
            handleMenuItemClick(event);
          }}
        >
          Excel
        </FormControlLabel>
        </RadioGroup>
      </Menu> */}

      {loadingCsvExport || loadingPdfExport || loadingHtmlExport ? (
        <Dialog
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          onClose={handleClose}
          open={loadingCsvExport || loadingPdfExport || loadingHtmlExport}
          closeAfterTransition
          BackdropComponent={Backdrop}
        >
          <DialogContent className={classes.paper}>
            <img
              src="https://d2ndap9rlps54r.cloudfront.net/DefaultUploadImages/export.gif"
              width="150px"
              style={{
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            />
            <DialogContentText id="alert-dialog-description">
              <p
                style={{
                  fontSize: "18px",
                  fontWeight: "600",
                  textAlign: "center",
                }}
              >
                Please Hold On, While we are preparing your consolidated report
              </p>
              <p style={{ fontSize: "16px", textAlign: "center" }}>
                Report will be downloaded in a while .
              </p>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      ) : (
        ""
      )}
    </>
  );
}
export default function ConsoleTable({
  actions = [],
  columns = [],
  customExport,
  data = [],
  exportCSV,
  exportFileName,
  exportHTML,
  exportPDF,
  // exportDepartmentPerformancehtml,
  // exportStudentPerformancehtml,
  // exportSectionPerformancehtml,
  exportStudentPerformancePdf,
  exportDepartmentPerformancePdf,
  exportSectionPerformancePdf,
  exportMockPerformancePdf,
  exportDeptStudPerformancePdf,
  exportDeptSecPerformancePdf,
  exportDeptMockPerformancePdf,
  exportStudSecPerformancePdf,
  exportStudMockPerformancePdf,
  exportSecMockPerformancePdf,
  exportDeptSecStudPerformancePdf,
  exportDeptStudMockPerformancePdf,
  exportDeptSecMockPerformancePdf,
  exportDepartmentPerformanceHtml,
  exportStudentPerformanceHtml,
  exportSectionPerformanceHtml,
  exportMockPerformanceHtml,
  exportDeptStudPerformanceHtml,
  exportDeptSecPerformanceHtml,
  exportDeptMockPerformanceHtml,
  exportStudSecPerformanceHtml,
  exportStudMockPerformanceHtml,
  exportSecMockPerformanceHtml,
  exportDeptSecStudPerformanceHtml,
  exportDeptStudMockPerformanceHtml,
  exportDeptSecMockPerformanceHtml,
  exportDepartmentPerformanceCsv,
  exportStudentPerformanceCsv,
  exportSectionPerformanceCsv,
  exportMockPerformanceCsv,
  exportDeptStudPerformanceCsv,
  exportDeptSecPerformanceCsv,
  exportDeptMockPerformanceCsv,
  exportStudSecPerformanceCsv,
  exportStudMockPerformanceCsv,
  exportSecMockPerformanceCsv,
  exportDeptSecStudPerformanceCsv,
  exportDeptStudMockPerformanceCsv,
  exportDeptSecMockPerformanceCsv,
  exportProctoringPdf,
  exportProctoringCsv,
  exportProctoringHtml,
  exportProctoringDeptPdf,
  exportProctoringSectionPdf,
  exportProctoringStudentPdf,
  exportProctoringStuSecPdf,
  exportProctoringSecdeptPdf,
  exportProctoringStudeptPdf,
  isLoading = false,
  loadingCsvExport,
  loadingHtmlExport,
  loadingPdfExport,
  options = {},
  title,
  fetchData,
  labId,
  totalCount,
  activeSection = null,
  activeTopic = null,
  exportMock,
}) {
  const tableRef = useRef(null);
  useEffect(() => {
    if ((activeSection || activeTopic) && tableRef.current) {
      tableRef.current.onQueryChange();
    }
  }, [activeSection, activeTopic]);


  const SSP = async (query) => {
    try {
      const data = await fetchData({
        id: labId,
        page: query.page,
        pageLimit: query.pageSize,
        totalCount,
        currentSection: activeSection,
        currentTopic: activeTopic,
        searchkey: query?.search || "",
      });

      return data;
    } catch (error) {
      return {
        data: [],
        page: 0,
        totalCount: 0,
      };
    }
  };

  return (
    <>
      <MaterialTable
        actions={actions}
        tableRef={tableRef}
        columns={[
          {
            align: "right",
            export: false,
            field: "tableData.id",
            render: () => <span className={styles.sno} />,
            sorting: false,
            title: "S.no",
            width: 50,
          },
          ...columns,
        ]}
        components={{
          Container: (props) => <div className={styles.container} {...props} />,
          Toolbar: (props) => (
            <div className={styles.customTableToolbar}>
              <MTableToolbar {...props} />
              {customExport && (
                <>
                  <ExportButton
                    exportCSV={exportCSV}
                    exportHTML={exportHTML}
                    exportPDF={exportPDF}
                    exportDepartmentPerformancePdf={
                      exportDepartmentPerformancePdf
                    }
                    exportStudentPerformancePdf={exportStudentPerformancePdf}
                    exportSectionPerformancePdf={exportSectionPerformancePdf}
                    exportMockPerformancePdf={exportMockPerformancePdf}
                    exportDeptStudPerformancePdf={exportDeptStudPerformancePdf}
                    exportDeptSecPerformancePdf={exportDeptSecPerformancePdf}
                    exportDeptMockPerformancePdf={exportDeptMockPerformancePdf}
                    exportStudSecPerformancePdf={exportStudSecPerformancePdf}
                    exportDeptSecStudPerformancePdf={
                      exportDeptSecStudPerformancePdf
                    }
                    exportDeptStudMockPerformancePdf={
                      exportDeptStudMockPerformancePdf
                    }
                    exportDeptSecMockPerformancePdf={
                      exportDeptSecMockPerformancePdf
                    }
                    exportStudentPerformanceHtml={exportStudentPerformanceHtml}
                    exportDepartmentPerformanceHtml={
                      exportDepartmentPerformanceHtml
                    }
                    exportSectionPerformanceHtml={exportSectionPerformanceHtml}
                    exportMockPerformanceHtml={exportMockPerformanceHtml}
                    exportDeptStudPerformanceHtml={
                      exportDeptStudPerformanceHtml
                    }
                    exportDeptSecPerformanceHtml={exportDeptSecPerformanceHtml}
                    exportStudSecPerformanceHtml={exportStudSecPerformanceHtml}
                    exportDeptSecStudPerformanceHtml={
                      exportDeptSecStudPerformanceHtml
                    }
                    exportDeptStudMockPerformanceHtml={
                      exportDeptStudMockPerformanceHtml
                    }
                    exportDeptSecMockPerformanceHtml={
                      exportDeptSecMockPerformanceHtml
                    }
                    exportDepartmentPerformanceCsv={
                      exportDepartmentPerformanceCsv
                    }
                    exportStudentPerformanceCsv={exportStudentPerformanceCsv}
                    exportSectionPerformanceCsv={exportSectionPerformanceCsv}
                    exportMockPerformanceCsv={exportMockPerformanceCsv}
                    exportDeptStudPerformanceCsv={exportDeptStudPerformanceCsv}
                    exportDeptSecPerformanceCsv={exportDeptSecPerformanceCsv}
                    exportDeptMockPerformanceCsv={exportDeptMockPerformanceCsv}
                    exportStudSecPerformanceCsv={exportStudSecPerformanceCsv}
                    exportStudMockPerformanceCsv={exportStudMockPerformanceCsv}
                    exportSecMockPerformanceCsv={exportSecMockPerformanceCsv}
                    exportDeptSecStudPerformanceCsv={
                      exportDeptSecStudPerformanceCsv
                    }
                    exportDeptStudMockPerformanceCsv={
                      exportDeptStudMockPerformanceCsv
                    }
                    exportDeptSecMockPerformanceCsv={
                      exportDeptSecMockPerformanceCsv
                    }
                    exportStudMockPerformanceHtml={
                      exportStudMockPerformanceHtml
                    }
                    exportSecMockPerformanceHtml={exportSecMockPerformanceHtml}
                    exportDeptMockPerformanceHtml={
                      exportDeptMockPerformanceHtml
                    }
                    exportStudMockPerformancePdf={exportStudMockPerformancePdf}
                    exportSecMockPerformancePdf={exportSecMockPerformancePdf}
                    exportProctoringPdf={exportProctoringPdf}   
                    exportProctoringCsv={exportProctoringCsv}
                    exportProctoringHtml={exportProctoringHtml}
                    exportProctoringDeptPdf={exportProctoringDeptPdf}
                    exportProctoringSectionPdf={exportProctoringSectionPdf}
                    exportProctoringStudentPdf={exportProctoringStudentPdf}
                    exportProctoringStuSecPdf={exportProctoringStuSecPdf}
                    exportProctoringSecdeptPdf={exportProctoringSecdeptPdf}
                    exportProctoringStudeptPdf={exportProctoringStudeptPdf}
                    data={data}
                    loadingCsvExport={loadingCsvExport}
                    loadingHtmlExport={loadingHtmlExport}
                    loadingPdfExport={loadingPdfExport}
                    exportMock
                  />
                </>
              )}
            </div>
          ),
        }}
        data={fetchData ? SSP : data}
        isLoading={isLoading}
        options={{
          ...options,
          actionsColumnIndex: -1,
          columnsButton: true,
          draggable: false,
          emptyRowsWhenPaging: false,
          exportButton: !customExport,
          ...(exportFileName && { exportFileName }),
          headerStyle: {
            fontWeight: "bold",
          },
          exportAllData: true,
          loadingType: "overlay",
          maxBodyHeight: 500,
          padding: "default",
          pageSize: 10,
          paginationPosition: "bottom",
          paginationType: "stepped",
          rowStyle: {
            whiteSpace: "nowrap",
          },
          search: true,
          tableLayout: "auto",
        }}
        title={title}
      />
    </>
  );
}
