import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  FilterMenu: {
    marginTop: 48,
    "& .MuiList-root": {
      "& .MuiListItemIcon-root": {
        minWidth: 28,
        "& .MuiButtonBase-root": {
          fontSize: 13,
          padding: 0,
        },
      },
      "& .MuiTypography-root": {
        fontSize: 13,
      },
    },
  },
  DialogAddQuestion: {
    width: "100%",
    height: "100%",
    "& .MuiBackdrop-root": {
      background: "rgba(0,0,0,0.2)",
    },
    "& .MuiButtonBase-root": {
      minHeight: 40,
      boxShadow: "none",
      "&.addButton": {
        background: "#51B960",
        color: "#fff",
      },
      "&.removeButton": {
        background: "#f46a6a",
        color: "#fff",
      },
      "&.MuiButton-containedPrimary": {
        background: "#784df5",
        color: "#fff",
      },
    },
    "& .MuiDialog-container .MuiPaper-root": {
      background: "#FFFFFF",
      border: `0px solid transparent`,
      borderRadius: "8px",
      height: "calc(100% - 64px)",
      overflow: "hidden",
      boxShadow: "0px 4px 16px rgb(48, 48, 48, 0.25)",
      [theme.breakpoints.down("sm")]: {
        maxHeight: "calc(100% - 8px)",
        width: "calc(100% - 8px)",
        height: "calc(100% - 8px)",
        margin: "2px",
      },
    },
    "& .MuiDialog-container .MuiDialogTitle-root": {
      borderBottom: `1px solid #f2f1f3`,
      padding: "0",
    },
    "& .MuiDialog-container .MuiDialogContent-root": {
      borderTop: "0px solid transparent",
      borderBottom: "0px solid transparent",
      background: "#FFFFFF",
      padding: 0,
      position: "relative",
    },
    "& .MuiDialog-container .MuiDialogActions-root": {
      borderTop: `1px solid #f2f1f3`,
      padding: "16px 24px 16px 24px",
      zIndex: 2,
      "& .MuiButtonBase-root": {
        minWidth: 148,
      },
      [theme.breakpoints.down("sm")]: {
        padding: "8px",
      },
    },
    "& .MuiButton-root": {
      textTransform: "none",
    },
    "& .quesBank": {
      marginBottom: 32,
      "& > div": {
        background: "#f7fbff",
        border: `1px solid #d7e4f1`,
        color: "#19181b",
        minHeight: "40px",
        boxShadow: "none",
        borderRadius: "4px",
        zIndex: 10,
        margin: 0,
      },
      "& > div > div > div": {
        color: "#19181b",
      },
      "& > div > div > div": {
        color: "#19181b",
        fontSize: 14,
      },
      "& [class$='-MenuList']": {
        backgroundColor: "#fff",
      },
      "& [class$='-MenuList'] > div:hover": {
        backgroundColor: `#f7fbff !important`,
      },
      "& [class$='-MenuList'] > div:active": {
        backgroundColor: "#f7fbff",
      },
      "& [class$='-indicatorSeparator']": {
        backgroundColor: "#d7e4f1",
      },
    },
    "& .infoText": {
      margin: "6px 0 0 8px",
      opacity: "0.6",
      fontSize: "10.5px",
    },
    "& .footerMenu": {
      display: "flex",
      width: "100%",
      justifyContent: "center",
      alignItems: "center",
    },
    "& .footerMenu .menuItem": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    "& .footerMenu .menuItem.center": { flex: "1 1 0%" },
    "& .status": {
      margin: 0,
      fontSize: "12px",
      letterSpacing: "0.5px",
      opacity: 0.6,
    },
    "& .loading": {
      display: "flex",
      position: "absolute",
      width: "100%",
      height: "100%",
      left: 0,
      right: 0,
      margin: 0,
      zIndex: 11,
      justifyContent: "center",
      alignItems: "center",
      background: "rgba(0,0,0,0.05)",
      "&:after": {
        top: 0,
        left: 0,
        zIndex: -1,
        width: "100%",
        height: "100%",
        content: "''",
        position: "absolute",
        backdropFilter: "blur(1px)",
      },
    },
    "& .hide-md": {
      [theme.breakpoints.down("md")]: {
        display: "none !important",
      },
    },
    "& .hide-sm": {
      [theme.breakpoints.down("sm")]: {
        display: "none !important",
      },
    },
    "& .spacing": {
      minWidth: "16px",
    },
    "& .CustomSearch": {
      marginBottom: "0",
      "& .buttonGroup": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        "& > *": { borderRadius: 0, borderRightWidth: 0, borderLeftWidth: 0 },
        "& .MuiButton-root": {
          paddingTop: "8px",
          paddingBottom: "8px",
        },
        "& .first": {
          borderRadius: "4px 0 0 4px",
          borderLeftWidth: 1,
        },
        "& .last": {
          borderRadius: "0 4px 4px 0",
          borderRightWidth: 1,
        },
      },
      "& .MuiButtonBase-root": {
        minHeight: 42,
        background: "#51B960",
        color: "#fff",
        fontSize: 14,
        letterSpacing: 1,
      },
      "& .MuiFormControl-root": {
        "&.MuiTextField-root": {
          border: "1px solid #d7e4f1",
          background: "#f7fbff",
          borderRadius: 3,
        },
        "& .MuiInputLabel-formControl": {
          zIndex: 1,
          fontSize: 14,
          fontWeight: 600,
          lineHeight: 1,
          margin: 0,
          padding: "0 16px",
          color: "rgba(0, 0, 0, 0.54)",
          transform: "translate(-12px, -20px) scale(0.96)",
          "&.Mui-focuse": {
            color: "rgba(0, 0, 0, 0.54)",
          },
          "&.MuiInputLabel-shrink": {
            transform: "translate(-12px, -20px) scale(0.96)",
          },
        },
        "& .MuiInputBase-root": {
          marginTop: 0,
          "& .MuiInputBase-input": {
            padding: "10px 16px 10px 16px",
            minHeight: 20,
            fontSize: 14,
          },
          "&:before": { display: "none" },
          "&:after": { display: "none" },
        },
      },
    },
  },
  tabsWrapper: {
    display: "flex",
    "& .MuiTabs-root": {
      display: "flex",
      flex: "1 1 0%",
      justifyContent: "center",
      padding: "0",
    },
    "& .MuiTabs-root button": {
      padding: "16px",
      background: "rgba(3,131,47,0.01)",
      border: "0px solid transparent",
      // color: "#19181b",
      color: "#784DF5",
      position: "relative",
      fontSize: "14px",
      fontWeight: 600,
      textTransform: "none",
      minHeight: "64px",
      fontFamily: "'Poppins', sans-serif !important",
      position: "relative",
      "& .count": {
        background: "#2b2b2b",
        color: "#ffffff",
        minWidth: 20,
        height: 20,
        lineHeight: "14px",
        padding: "4px 8px",
        fontSize: 12,
        display: "inline-block",
        borderRadius: 12,
        marginLeft: 6,
        marginTop: -6,
        position: "absolute",
      },
    },
    "& > div button.Mui-selected": {
      color: "#784DF5",
      // background: "#7bc193",
    },
    "& > .MuiTabs-root .MuiTabs-indicator": {
      height: "10px",
      backgroundColor: "#784DF5",
      bottom: -5,
      "&:after": {
        bottom: 4,
        left: 0,
        zIndex: 1,
        height: 2,
        width: "100%",
        content: "''",
        borderRadius: "50%",
        position: "absolute",
        backgroundColor: "#784DF5",
        filter: "blur(1px)",
      },
    },
    "& .MuiTouchRipple-root span": {
      backgroundColor: "#784DF5",
    },
    "& .MuiTabs-root button .MuiBadge-root": {
      right: "-8px",
    },
    "& .MuiTabs-root button .MuiBadge-root .MuiBadge-badge": {
      background: "#784DF5",
    },
  },
  dialogContent: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    "& .ctrls": {
      width: "100%",
      padding: "16px 32px 0 32px",
      "&.no": {
        padding: 0,
      },
      "& .tostMsg": {
        position: "absolute",
        zIndex: "1",
        right: "24px",
        bottom: "32px",
        "& .msg": {
          padding: "6px 16px",
          position: "absolute",
          zIndex: "1",
          color: "#0052b3",
          background: "#cbe1fb",
          display: "flex",
          borderRadius: 4,
          transform: "translateX(-100%)",
          whiteSpace: "nowrap",
        },
      },
    },
    "& .MuiFormControl-root .MuiOutlinedInput-root .MuiButton-root": {
      marginRight: "-14px",
      whiteSpace: "nowrap",
      paddingLeft: "24px",
      paddingRight: "24px",
    },
  },
  table: {
    width: "100%",
    height: "100%",
    flex: "1 1 0%",
    overflow: "hidden",
    padding: "24px 0 0 0",
    "& .containers": {
      width: "100%",
      height: "100%",
      overflow: "auto",
    },
    "& .MuiCheckbox-root": { padding: "2px 0" },
    "& .msg": {
      padding: "8px 16px",
      border: `1px solid #f2f2f2`,
      display: "inline-block",
      opacity: 0.8,
    },
    "& .thead": {
      width: "100%",
      borderCollapse: "collapse",
      border: `1px solid #ababab`,
      color: "#333",
      borderColor: "#ababab",
      fontSize: "14px",
      marginBottom: 0,
    },
    "& .table": {
      margin: 0,
      border: "0 solid transparent",
      borderRadius: 4,
      overflow: "hidden",
      "&.full": {
        height: "100%",
      },
      "& thead": {
        "& tr": {
          borderRadius: 4,
          overflow: "hidden",
          "& th": {
            border: `1px solid #e2ecf7`,
            borderBottomWidth: 0,
            background: "#f3f6f9",
            verticalAlign: "middle",
            padding: "4px 0px",
            lineHeight: 1,
            fontSize: "14px",
            fontWeight: 500,
            color: "#464e5f",
            "&.first": {
              borderTopLeftRadius: 4,
              borderBottomLeftRadius: 4,
              overflow: "hidden",
            },
            "&.last": {
              borderTopRightRadius: 4,
              borderBottomRightRadius: 4,
            },
            "&.full": {
              width: "100%",
            },
          },
        },
      },
    },
    "& .table th > div": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    "& .table th > div .name": {
      paddingLeft: "8px",
      whiteSpace: "nowrap",
    },
    "& .table th > div .name.only": {
      paddingRight: "8px",
      flex: "1 1 0%",
    },
    "& .table th .MuiIconButton-root": {
      padding: "6px",
    },
    "& .table tbody tr": {
      height: "43px",
    },
    "& .table tbody tr:hover": {
      background: "#f5f5f5",
    },
    "& .table.full tbody tr:hover": {
      background: "transparent",
    },
    "& .table tr.true > td": {
      opacity: 0.6,
    },
    "& .table td": {
      position: "relative",
      border: `1px solid #f3f6f9`,
      borderColor: "#ebebeb",
      fontSize: "13px",
      lineHeight: "24px",
      verticalAlign: "middle",
      fontWeight: "normal",
      padding: "2px 2px",
      "& div": {
        opacity: 0.7,
        padding: "2px 8px",
      },
      "& .MuiChip-root": {
        opacity: 1,
        position: "absolute",
        top: "50%",
        right: 3,
        transform: "translateY(-50%)",
        color: "#322c09",
        background: "#fddb6c",
        fontWeight: 500,
        fontSize: 11.5,
      },
    },
    "& .table td .qname": {
      maxWidth: "100%",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    "& .table td .bankname": {
      maxWidth: "92px",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    "& .table td .type": {
      maxWidth: "80px",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    "& .table td .category": {
      maxWidth: "106px",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    "& .table td .topic": {
      maxWidth: "92px",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    "& .table td .center": {
      textAlign: "center",
    },
    "& .table td.center": {
      textAlign: "center",
    },
    "& .table td div": {
      opacity: 0.7,
      padding: "2px 8px",
    },
    "& .table td .MuiCheckbox-root": {
      opacity: 0.7,
    },
  },
}));
