import { withStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";

export const CustomSwitch = withStyles({
  root: {
    width: 45,
    height: 25,
    padding: 0,
    display: "flex",
  },
  switchBase: {
    padding: 2,
    color: "#D0BFFF",
    "&$checked": {
      transform: "translateX(20px)",
      color: "#794DF5",
      "&$checked + $track": {
        border: `1px solid #9C9C9C`,
        opacity: 1,
        backgroundColor: "#fff",
      },
    },
  },
  track: {
    border: `1px solid #9C9C9C`,
    borderRadius: 25 / 2,
    opacity: 1,
    backgroundColor: "#fff",
  },
  checked: {},
})(Switch);
