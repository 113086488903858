import useSWR from "swr";
import { GetCourse } from "../component/Apis";
import api from "api/baseConfig";

export const useGetCourse = (courseId: string) => {
  const { data, error } = useSWR(
    courseId
      ? `${api.baseURL}${api.CloudLab.CloudLabGtDetails}/${courseId}`
      : null,
    GetCourse,
    { refreshInterval: 36 * 100 * 1000 }
  );

  return error ? `Error occured ${error}` : !data ? "Fetching..." : data;
};
