import styles from "./GradingCD.module.scss";

const Tabs = ({ items = [], selectedtab, setSelectedTab }) => {
  return (
    <div className={styles.codingTabs}>
      {items.map((item) => (
        <span
          key={item}
          className={`${styles.codingTab} ${
            selectedtab === item.toLowerCase() && styles.codingTabSelected
          }`}
          onClick={() => setSelectedTab(item.toLowerCase())}
        >
          {item}
        </span>
      ))}
    </div>
  );
};

export default Tabs;
