import styles from "./CustomSearch.module.scss";
import cn from "classnames";

export default function CustomSearch({
  handlesearch,
  placeholder,
  value,
  secondary,
  style,
  handleClick,
}) {
  return (
    <div
      className={cn(styles.searchForm, {
        [styles.searchFormPrimary]: !secondary,
        [styles.searchFormSecondary]: secondary,
      })}
      style={style}
    >
      <span className={cn("bx bx-search search-icon", styles.searchIcon)} />
      <input
        placeholder={placeholder}
        className={cn(styles.searchInput, {
          [styles.secondaryInput]: secondary,
        })}
        value={value}
        onChange={(e) => {
          handlesearch(e.target.value);
        }}
        onClick={handleClick}
        autoComplete="off"
      />
    </div>
  );
}
