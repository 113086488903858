import apiHelper from "api/apiHelper";
import api from "api/baseConfig";
import { sessionPrivileges } from "privileges";
import { connect, useSelector } from "react-redux";
import React, { useState, useEffect } from "react";
import UserManagementDumb from "./UserManagementDumb";
import { TrimString } from "pages/utils/TrimString";
import { DefaultToast } from "components/DefaultToast";

function UserManagementSmart(props) {
  const userPrivileges = sessionPrivileges();
  const [userRoles, setUserRoles] = useState([]);
  const [reportingTos, setReportingTos] = useState([]);

  const activePage = props?.location?.state?.activePage;
  const itemsPerPage = props?.location?.state?.itemsPerPage;

  const allCustomers = useSelector((state) =>
    state.StudentAndCustomerReducer.Customers
      ? state.StudentAndCustomerReducer.Customers.map((cus) => ({
          label: cus.name,
          value: cus.id,
        }))
      : []
  );

  useEffect(() => {
    if (props?.location?.state?.customer) {
      getAllRoles(props?.location?.state?.customer);
    }
    window.scrollTo(0, 0);
  }, []);

  const validateUserEmail = async (email) => {
    try {
      const res = await apiHelper.axiosCall(
        `${api.baseURL}${api.userManagement.checkUserEmail}${email}`
      );
      return res;
    } catch (e) {}
  };

  const createUser = async (userData) => {
    const condition =
      userData.filterType !== "only" &&
      userData.filterType !== "except" &&
      userData.filterType !== "all";
    let data = {
      ...userData,
      createdBy: userPrivileges.userId,
      updatedBy: userPrivileges.userId,
      roleList: [userData.roleList.value],
      filterType:
        userData.hasOwnProperty("batchName") && condition
          ? userData.batchName
          : userData.filterType,
    };

    if (!condition) {
      delete data.batchName;
    }

    delete data.regNoTo;
    delete data.regNoFrom;

    try {
      await apiHelper.axiosCallPost(
        `${api.baseURL}${api.userManagement.createUser}`,
        "post",
        data
      );
      DefaultToast({ message: "Successfully created User" });
      props.history.push({
        pathname: "/userList",
        state: { customer: userData.customerId },
      });
    } catch (e) {}
  };

  const updateUser = async (userData) => {
    const condition =
      userData.filterType !== "only" &&
      userData.filterType !== "except" &&
      userData.filterType !== "all";
    let data = {
      ...userData,
      updatedBy: userPrivileges.userId,
      roleList: [userData.roleList.value],
      filterType:
        userData.hasOwnProperty("batchName") && condition
          ? userData.batchName
          : userData.filterType,
    };

    if (!condition) {
      delete data.batchName;
    }

    delete data.regNoTo;
    delete data.regNoFrom;

    const url = TrimString(`${api.baseURL}${api.userManagement.updateUser}
		${userData.customerId}/${props?.location?.state?.id}`);

    try {
      await apiHelper.axiosCallPost(url, "put", data);
      DefaultToast({ message: "Successfully updated User" });
      props.history.push({
        pathname: "/userList",
        state: { customer: userData.customerId },
      });
    } catch (e) {}
  };

  const getUserById = async (id) => {
    try {
      const userData = await apiHelper.axiosCall(
        `${api.baseURL}${api.userManagement.getUser}${id}`
      );

      return userData;
    } catch (e) {}
  };

  const getAllRoles = async (id) => {
    try {
      const roles = await apiHelper.axiosCallPost(
        `${api.baseURL}${api.userManagement.getRoles}${id}`
      );
      if (roles && roles.length > 0) {
        setUserRoles(
          roles.map((role) => ({
            label: role.name,
            value: role.name,
            roleType: role?.roleType || role.name,
          }))
        );
      }
    } catch (e) {}
  };

  const getReportingTos = async (customer) => {
    try {
      const data = await apiHelper.axiosCallPost(
        `${api.baseURL}${api.userManagement.getReportingTos}${customer}`
      );
      if (data && data.length > 0) {
        setReportingTos(
          data.map((datum) => ({
            label: `${datum.firstName} ${datum?.lastName}`,
            value: datum.emailId,
          }))
        );
      }
    } catch (e) {}
  };

  return (
    <UserManagementDumb
      userRoles={userRoles}
      reportingTos={reportingTos}
      createUser={createUser}
      updateUser={updateUser}
      getUserById={getUserById}
      validateUserEmail={validateUserEmail}
      getAllRoles={getAllRoles}
      getReportingTos={getReportingTos}
      allCustomers={allCustomers}
      id={props?.location?.state?.id}
      customer={props?.location?.state?.customer}
      existingEmailId={props?.location?.state?.emailId}
      viewOnly={props?.location?.state?.type === "viewOnly"}
      activePage={activePage}
      itemsPerPage={itemsPerPage}
    />
  );
}

export default connect(null, null)(UserManagementSmart);
