import React, { Component } from "react"
import { Redirect } from "react-router-dom"
import { connect } from "react-redux"
export default class sso extends Component {
  componentDidMount() {}
  render() {
    const {
      match: { params },
    } = this.props
    let { token } = params
    if (token == "h8j2g09vb5") {
      return <Redirect to="/assessments" />
    } else if (token == "j923n!hjkl") {
      return <Redirect to="/assessment" />
    } else {
      return <Redirect to="/pages-404" />
    }
  }
}
