import { useMemo, useState } from 'react';
import Editor from '@monaco-editor/react';
import { useSolutionInfo } from '../../hooks/use-solution-info';
import TabbedSection from '../../components/tabbed-section/tabbed-section';
import Confirmation from '../../components/confirmation/confirmation';
import Tabs from '../../components/tabs/tabs';
import styles from './testcases.module.scss';

export default function TestCasesSection({
  activity,
  available,
  canView,
  canViewHint,
  customInput,
  details,
  hintsAvailable,
  id,
  isLightMode,
  logActivity,
  questionId,
  setActivity,
  setCustomInput,
  setTestCaseTab,
  setVisibility,
  testCaseTab,
  testCaseViewReductionPercentage = 50,
  visible,
  debugcCodeConform,
  ...props
}) {
  const { data, loading } = useSolutionInfo(id, 'testcase', visible);
  const testCases = useMemo(() => Object.values(data?.[0]?.testCaseList || {}), [data]);
  const [confirmationAnchor, setConfirmationAnchor] = useState(null);
  const [infoAnchor, setInfoAnchor] = useState(null);
  return (
    <>
      <TabbedSection
        valueForCurrent={testCaseTab}
        available={available}
        canView={canView}
        disabledText="Test cases are disabled"
        disableFreeScroll={testCaseTab === 1}
        header={(
          <Tabs
            tab={testCaseTab}
            tabs={[
              { name: 'Test cases' },
              { name: 'Custom input' },
              { name: debugcCodeConform ? 'Debug' : ""},
            ]}
            setTab={setTestCaseTab}
          />
        )}
        loading={loading}
        questionId={questionId}
        sectionId="practice-lab-testcases"
        tabIndexLabel="Test case"
        tabs={testCases}
        title="Test cases"
        setVisibility={(testCaseTab === 0) && ((isVisible, e) => {
          if ((hintsAvailable && canViewHint) ? !activity?.hint : false) {
            setInfoAnchor(e?.currentTarget);
          } else if (activity?.testCase) {
            setVisibility(isVisible);
          } else {
            setConfirmationAnchor(e?.currentTarget);
          }
        })}
        tabContentRender={({ inputText, outputText }) => (
          <div className={styles.content}>
            <h4>Input:</h4>
            <pre dangerouslySetInnerHTML={{ __html: inputText }} />
            <h4>Output:</h4>
            <pre dangerouslySetInnerHTML={{ __html: outputText }} />
          </div>
        )}
        transparentTabButtonBG
        unAvailableText="Test cases were not provided"
        visible={visible}
        {...props}>
        {(testCaseTab === 1) && (
          <Editor
            editor
            value={customInput}
            language="text"
            onChange={setCustomInput}
            theme={isLightMode ? 'light' : 'vs-dark'}
            options={{
              autoIndent: 'advanced',
              contextmenu: false,
              fontFamily: 'Fira Code',
              fontLigatures: true,
              fontSize: 16,
              formatOnPaste: true,
              matchBrackets: 'always',
              minimap: {
                enabled: false,
              },
              padding: {
                bottom: 12,
                top: 12,
              },
              quickSuggestions: false,
              smoothScrolling: true,
              snippetSuggestions: 'none',
            }}
          />
        )}
        {(testCaseTab === 2) && (
          <Editor
            editor
            value={customInput}
            language="text"
            onChange={setCustomInput}
            theme={isLightMode ? 'light' : 'vs-dark'}
            options={{
              autoIndent: 'advanced',
              contextmenu: false,
              fontFamily: 'Fira Code',
              fontLigatures: true,
              fontSize: 16,
              formatOnPaste: true,
              matchBrackets: 'always',
              minimap: {
                enabled: false,
              },
              padding: {
                bottom: 12,
                top: 12,
              },
              quickSuggestions: false,
              smoothScrolling: true,
              snippetSuggestions: 'none',
            }}
          />
        )}
      </TabbedSection>
      <Confirmation
        anchor={infoAnchor}
        description="In order to view the test case, you have to see the hints first."
        setAnchor={setInfoAnchor}
        title="Can't show test case"
      />
      <Confirmation
        anchor={confirmationAnchor}
        confirmation
        description={`Viewing the test case will reduce your score by ${testCaseViewReductionPercentage}%. Are you sure you want to view test case?`}
        onContinue={() => {
          setVisibility((v) => {
            if (!v) {
              logActivity('testCase');
            }

            return !v;
          });
          setActivity((a) => ({
            ...a,
            [id]: {
              ...(a?.[id] || {}),
              testCase: true,
            },
          }));
        }}
        setAnchor={setConfirmationAnchor}
        title="Show test case?"
      />
    </>
  );
}