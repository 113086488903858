import { useContext } from 'react';
import {
  AppBar,
  Paper,
  Toolbar,
  withStyles,
} from '@material-ui/core';
import cn from 'classnames';
import { Skeleton } from '@material-ui/lab';
import { PracticeLabContext } from '../../context/practice-lab.context';
import styles from './section.module.scss';

const TitleBar = withStyles({
  root: {
    padding: '0 !important',
  },
})(AppBar);

const TitleBarToolBar = withStyles({
  root: {
    height: '100%',
    minHeight: 'auto',
    padding: '0 !important',
  },
})(Toolbar);

const SectionContainer = withStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
})(Paper);

export default function SectionSkeleton(props) {
  const { isLightMode, largeScreen } = useContext(PracticeLabContext);

  return (
    <SectionContainer
      classes={{ root: cn(styles.container, props?.smallScreen && styles.smallScreen) }}
      style={{
        ...largeScreen && {
          gridColumn: `${props?.gridPosition?.[0]?.[0]} / ${props?.gridPosition?.[0]?.[1]}`,
          gridRow: `${props?.gridPosition?.[1]?.[0]} / ${props?.gridPosition?.[1]?.[1]}`,
        },
      }}>
      <TitleBar position="relative" style={{ height: 40 }}>
        <TitleBarToolBar style={{ ...isLightMode && { backgroundColor: '#ccc' } }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <Skeleton width="20%" />
        </TitleBarToolBar>
      </TitleBar>
      <div className={styles.content}>
        <Skeleton width="75%" style={{ transform: 'none' }} />
        <br />
        <Skeleton width="50%" style={{ transform: 'none' }} />
      </div>
    </SectionContainer>
  );
}
