import React, { useEffect, useState } from "react";
import api from "../../../api/baseConfig";
import ApiHelper from "../../../api/apiHelper";
import "react-toastify/dist/ReactToastify.css";
import CustomTable from "../../CustomerManagement/CustomListTable";
import { useParams } from "react-router-dom";
import { sessionPrivileges } from "privileges";
import { toast } from "react-toastify";

export default function AllDomains(props) {
  const userPrivileges = sessionPrivileges();
  const Priv = sessionPrivileges().userprivileges || [];
  const [data, setData] = useState([]);
  const [subData, setSubData] = useState([]);
  let params = useParams();

  const activePage = props?.location?.state?.activePage;
  const itemsPerPage = props?.location?.state?.itemsPerPage;

  useEffect(() => {
    getAllDomain();
    window.scrollTo(0, 0);
  }, []);

  const getAllDomain = async () => {
    await ApiHelper.axiosCall(
      `${api.baseURL}${api.masterController.getAllDomain}`,
      "get"
    )
      .then((data) => {
        let tempData = [];
        data.forEach((datum) => {
          tempData.push({
            name: datum.name,
            description: datum.description,
            subDomainCount: datum.subCount,
            id: datum.id,
          });
          if (datum.subCount === 0) {
            toast.warning("Please add Sub Domain for " + datum.name);
          }
        });
        setData(tempData);
      })
      .catch((e) => {});
  };

  const getSubDomains = async (id) => {
    await ApiHelper.axiosCall(
      `${api.baseURL}${api.masterController.getSubDomain}${id}`,
      "get"
    )
      .then((data) => {
        let tempData = [];
        data.forEach((datum) => {
          tempData.push({
            name: datum.name,
            description: datum.description,
            id: datum.id,
          });
        });
        setSubData(tempData);
      })
      .catch((e) => {});
  };

  const deleteDomain = async (id) => {
    await ApiHelper.axiosCall(
      `${api.baseURL}${api.masterController.deleteDomain}${id}`,
      "post"
    )
      .then((d) => {
        getAllDomain();
      })
      .catch((e) => {});
  };

  return (
    <>
    {userPrivileges.isSuperAdmin && (
    <div className="page-content">
      <CustomTable
        header="Domains"
        createLink="/create-domain"
        data={data}
        dataLength={data.length}
        tableHeaders={[
          { label: "Domain", val: "name" },
          { label: "Description", val: "description" },
          { label: "Sub Domain Count", val: "subDomainCount" },
          { label: "Sub Domain", val: "subDomain", own: true },
        ]}
        ownLink="/sub/domain/"
        searchPlaceholder="Search Domain"
        deleteDatum={(id) => deleteDomain(id)}
        passEditFromRow
        editData={["name", "description", "id"]}
        accord
        showAdd={Priv.includes("ADD_DOMAIN")}
        hideEdit={!Priv.includes("EDIT_DOMAIN")}
        hideDelete={!Priv.includes("DELETE_DOMAIN")}
        getAllSubs={(id) => {
          getSubDomains(id);
        }}
        subTitle={"Sub Domain"}
        subData={subData}
        createSubLink="/create-sub-domain/"
        tableSubHeaders={[
          { label: "Sub Domain", val: "name" },
          { label: "Description", val: "description" },
        ]}
        passSubEditFromRow
        editSubData={["name", "description", "id"]}
        hoverable
        hideSubAdd={Priv.includes("ADD_SUB_DOMAIN")}
        hideSubEdit={Priv.includes("EDIT_SUB_DOMAIN")}
        hideSubDelete={Priv.includes("DELETE_SUB_DOMAIN")}
        activePage={activePage}
        itemsPerPage={itemsPerPage}
      />
    </div>
    )}
    </>
  );
}
