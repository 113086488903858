import React from "react";
import "./legalStyles.scss";
import * as BsIcons from "react-icons/bs";
import { Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { sessionPrivileges } from "privileges";
import { useLogoContext } from "context/logoContext";

function studentsLegal() {
  const Priv = sessionPrivileges().userprivileges || [];
  let history = useHistory();
  const logoImage = useLogoContext();

  return (
    <>
      <div
        className="legal"
        style={
          Priv && Priv.includes("ADMIN_ROLE")
            ? { marginLeft: 225 }
            : Priv && Priv.includes("ATTEND_ASSESSMENT")
            ? { marginLeft: 225 }
            : { marginLeft: 225 }
        }
      >
        <h1 className="legalheader">Legal</h1>
        <div className="topics">
          <div className="subtopic-content1">
            <p>
              {`For the benefit of the entire ${logoImage?.productName} community, and to comply
              with applicable laws, we have a few mandatory guidelines we call
              ["Legal (Terms of Use) "/"Legal Terms"/ "Agreement"]. These Legal
              Terms are an agreement between you (referred as “User”, “Member”,
              “Visitor”, “You” or “Your”) and ${logoImage?.productName}, a division of Top
              Freshers Technologies Private Limited (TFTPL), having its
              principal place of business in Madurai & Chennai, India (referred
              as “${logoImage?.productName}”, “Company”, “We”, “Us” or “Our”). This Agreement
              governs your use of the ${logoImage?.productName} Website (the “Website”, “Site”,
              “Sites”, or “Websites”) and transactions conducted through this
              Website including, but not limited to, the billing and payment
              services. It is important that you understand both the benefits we
              provide as well as our limitations and that any conduct that
              violates the “Legal (Terms of Use)” is grounds for termination of
              your account. For this reason, we ask that you carefully read and
              follow them.`}
            </p>
          </div>
          <p className="topic-heading1">1. Acceptance of Terms</p>
          <div className="subtopic-content1">
            <p>
              {`For the benefit of its varied users, ${logoImage?.productName} provides a variety
              of online/offline resources & services, (referred to hereafter as
              “the Service” and “Content”) subject to the following Legal (Terms
              of Use) (“Terms”), which may be updated by ${logoImage?.productName} from time to
              time. These terms and conditions regarding your use of the Site
              constitute a legally binding agreement (“Agreement”) between you
              and ${logoImage?.productName} (“${logoImage?.productName}”, “We”, “Us” or “Our”). In this agreement,
              the terms “Site”, “Sites”, “Website” or “Websites” includes all
              Websites and web pages within ${logoImage?.productName} as well as any equivalent,
              mirror, replacement, substitute or backup Websites and web pages
              thereto and thereof. The words “use” or “using” in this agreement,
              means any time an individual (a “user”), directly or indirectly,
              with or without the aid of a machine or device, does or attempts
              to access, interact with use, display, view, print or copy from
              the Site, transmit, receive or exchange data or communicate with
              the Site, or in any way utilizes, benefits, takes advantage of or
              interacts with any function, service or feature of the Site, for
              any purpose whatsoever.`}
            </p>
          </div>
          <div className="subtopic-content2">
            <p>
              {`The Site may also contain links or references to additional rules,
              policies and terms which may apply to specific features or
              functions of the Site and when you use those features or
              functions, those rules, policies and terms will also apply to you
              and form a part of these terms and conditions, and consequently,
              your agreement with us. With respect to these features and
              services provided on our Website, you may be charged, and agree to
              pay us any fees, as set forth in separate sections of the Website
              detailing such features and services. By using ${logoImage?.productName} in any
              way, you are agreeing to comply with this Agreement. Additionally,
              when using a particular ${logoImage?.productName} service you agree to abide by any
              applicable posted guidelines for such ${logoImage?.productName} service, which may
              change from time to time. Also, when using free or paid services
              offered through ${logoImage?.productName}’s third-party service providers, you will
              be subject to terms of these third-party service providers. Your
              access to ${logoImage?.productName} and/or registration for a membership on
              ${logoImage?.productName} Site signifies your agreement to those additional terms.`}
            </p>
          </div>
          <div className="subtopic-content2">
            <p>
              {`If, for any reason, you do not agree with all of the terms and
              conditions contained in this Agreement, please discontinue using
              the Site immediately, because by using or attempting to use the
              Site, you are agreeing to be legally bound by this agreement. This
              is the entire and exclusive agreement between you and us regarding
              use of the Site and it cannot be modified. ${logoImage?.productName} may, from time
              to time, update the Legal Terms without notice to you.`}
            </p>
          </div>
          <div className="subtopic-content2">
            <p>
              {`Privacy Policy (web link) – ${logoImage?.productName} respects your privacy and has
              developed a detailed Privacy Policy that is incorporated into this
              Agreement. Please take the time to read our Privacy Policy. By
              agreeing to these Legal Terms, you are also accepting the terms of
              our Privacy Policy.`}
            </p>
          </div>
          <p className="topic-heading1">
            2. Eligibility, Registration & Membership
          </p>
          <div className="subtopic-content1">
            <p>
              {`By using and/or viewing this Site or by applying for membership
              and subsequently becoming a member of ${logoImage?.productName} , whether said
              application is made by regular or special mail, or by any form of
              electronic communication, including, but not limited to,
              electronic e-mail, internet common gateway interface programming
              or by telecommunication, the person or persons applying for
              membership and irrespective of gender, as the masculine shall be
              determined to jointly and severally apply to the feminine and the
              feminine to the masculine, and that the singular shall also refer
              and unilaterally refer to the plural, you do hereby state and
              affirm under penalty of perjury that:`}
            </p>
          </div>
          <div className="subtopic-contentInDotList">
            <span>
              <BsIcons.BsDot className="container-dotlist-ellipse" />
            </span>
            <span className="subtopic-content3">
              <p>
                You are an adult meeting the minimum age requirements for access
                to this Site. You have the right, authority and capacity to
                agree to and abide by these Legal (Terms of Use).
              </p>
            </span>
          </div>
          <div className="subtopic-contentInDotList">
            <span>
              <BsIcons.BsDot className="container-dotlist-ellipse" />
            </span>
            <span className="subtopic-content3">
              <p>
                {`You also represent and warrant to the Company that you will use
                ${logoImage?.productName} in a manner consistent with any and all applicable laws
                and regulations. You are not located in a community,
                jurisdiction or country where this service is illegal &
                prohibited by law or could be deemed a violation of any law,
                regulation, rule, ordinance, edict, culture or custom.`}
              </p>
            </span>
          </div>
          <div className="subtopic-contentInDotList">
            <span>
              <BsIcons.BsDot className="container-dotlist-ellipse" />
            </span>
            <span className="subtopic-content3">
              <p>
                {`You state and affirm that ${logoImage?.productName} relies upon the
                representations made by you in whole and in part. When you
                register to become a member, you agree not to impersonate
                anybody and to provide accurate, current and complete
                information about yourself as prompted by our registration form,
                and to maintain and update your information to keep it accurate,
                current and complete. You understand that impersonating somebody
                else or by furnishing false information constitutes a fraud and
                submitting a misrepresented or fraudulent application may
                subject you to any and all legal actions as deemed appropriate.`}
              </p>
            </span>
          </div>
          <div className="subtopic-contentInDotList">
            <span>
              <BsIcons.BsDot className="container-dotlist-ellipse" />
            </span>
            <span className="subtopic-content3">
              <p>
                {`You understand that some sections of the Site like the feedback
                forums and chat rooms may be unmoderated and may include
                patently offensive content/material and you are not bothered by
                such content/ material and agree that it does not offend you in
                any way, form or manner. You are voluntarily choosing to do so,
                because you want to view, read and/or hear the various materials
                which are available, for your own personal information,
                enjoyment and/or education.`}
              </p>
            </span>
          </div>
          <div className="subtopic-contentInDotList">
            <span>
              <BsIcons.BsDot className="container-dotlist-ellipse" />
            </span>
            <span className="subtopic-content3">
              <p>
                {`You understand that ${logoImage?.productName} cannot guarantee that each member
                is at least the required minimum age, nor accept responsibility
                or liability for any content, communication or other use or
                access of the Site by persons in violation of these terms.`}
              </p>
            </span>
          </div>
          <div className="subtopic-contentInDotList">
            <span>
              <BsIcons.BsDot className="container-dotlist-ellipse" />
            </span>
            <span className="subtopic-content3">
              <p>
                This Agreement will remain in full force and effect while you
                use the Website and/or are a Member. You may terminate your
                membership at any time, for any reason by contacting us through
                our contact form and your membership will be terminated upon
                receipt of email notice of termination.
              </p>
            </span>
          </div>
          <div className="subtopic-contentInDotList">
            <span>
              <BsIcons.BsDot className="container-dotlist-ellipse" />
            </span>
            <span className="subtopic-content3">
              <p>
                Your membership in the Site is for your sole, personal use only.
                You may not authorize others to use your membership, and you may
                not assign or otherwise transfer your account to any other
                person or entity. Each person must have his or her own login id.
                You are solely responsible for maintaining the confidentiality
                of your username and password and for any and all activities
                that are conducted through your account. You agree to notify
                {logoImage?.productName} immediately of any unauthorized use of your account or
                any other breach of security.
              </p>
            </span>
          </div>
          <p className="topic-heading1">3. User Registration</p>
          <div className="subtopic-content1">
            <p>
              Some areas of the Site may require you to register with us. When
              and if you register, you agree to (a) provide accurate, current,
              and complete information about yourself as prompted by our
              registration form (including your e-mail address) and (b) to
              maintain and update your information (including your e-mail
              address) to keep it accurate, current, and complete. You
              acknowledge that should any information provided by you be found
              to be untrue, inaccurate, not current, or incomplete, we reserve
              the right to terminate this Agreement with you and your use of the
              Site.
            </p>
          </div>
          <div className="subtopic-content2">
            <p>
              As part of the registration process, we may refuse to allow you to
              use a username that impersonates someone else, may be illegal, may
              be protected by trademark or other proprietary rights law, is
              vulgar or otherwise offensive, or may cause confusion, as
              determined by us in our sole discretion. You will be responsible
              for the confidentiality and use of your password and agree not to
              distribute, transfer or resell your use of or access to the Site
              to any third party. If more than one individual wishes to use a
              single password belonging to a registered user, such registered
              user must request permission from the Company in writing, it being
              understood that the Company shall be under no obligation to
              approve any such request. If you have reason to believe that your
              account with us is no longer secure, you must promptly change your
              password by updating your account information and immediately
              notifying us of the problem. YOU ARE SOLELY AND ENTIRELY
              RESPONSIBLE FOR MAINTAINING THE CONFIDENTIALITY OF YOUR USERNAME
              AND PASSWORD AND SOLELY AND ENTIRELY RESPONSIBLE FOR ANY AND ALL
              ACTIVITIES THAT ARE CONDUCTED THROUGH YOUR ACCOUNT.
            </p>
          </div>
          <div className="subtopic-content2">
            <p>
              In addition to the Legal (Terms of Use) and any Privacy Policy
              applicable to any {logoImage?.productName} Website, You understand and agree that
              the Company may disclose to third parties, on an anonymous basis,
              certain aggregate information contained in your registration
              application. The Company will not disclose to any third party your
              name, address, e-mail address or telephone number without your
              prior consent, except to the extent necessary or appropriate to
              comply with applicable laws or in legal proceedings where such
              information is relevant.
            </p>
          </div>
          <div className="subtopic-content2">
            <p>
              The Company reserves the right to offer third party services and
              products to you based on the preferences that you identify in your
              registration and at any time thereafter. Such offers may be made
              by the Company or by third parties. Without limiting any of the
              other disclaimers of warranty set forth in these Legal (Terms of
              Use) , {logoImage?.productName} does not provide or make any representation as to
              the quality or nature of any of the third party products or
              services purchased through the {logoImage?.productName} or any other {logoImage?.productName}
              Site, or any other representation, warranty or guaranty. Any such
              undertaking, representation, warranty or guarantee would be
              furnished solely by the provider of the applicable class, or
              learning material, product or service, under the terms agreed to
              by the provider.
            </p>
          </div>
          <p className="topic-heading1">4. Use of {logoImage?.productName} Content</p>
          <div className="subtopic-content1">
            <p>
              The Company authorizes you to view and access a single copy of the
              content available on or from {logoImage?.productName} solely for your personal
              use. The contents of {logoImage?.productName}, and of all other Websites under the
              Company’s control, whether partial or otherwise ({logoImage?.productName} and such
              other Websites are sometimes collectively referred to as “{logoImage?.productName}
              Sites”) such as text, graphics, images, logos, button icons,
              software and other {logoImage?.productName} Content (collectively, “{logoImage?.productName}
              Content”), are protected under India and foreign copyright,
              trademark and other laws. All {logoImage?.productName} Content is the property of
              the Company or its content suppliers or clients. The compilation
              (meaning the collection, arrangement and assembly) of all content
              on the {logoImage?.productName} Sites are the exclusive property of the Company
              and protected by Indian and international copyright laws.
              Unauthorized use of the {logoImage?.productName} Content may violate copyright,
              trademark, and other laws. You must retain all copyright,
              trademark, service mark and other proprietary notices contained in
              the original {logoImage?.productName} Content on any copy you make of the {logoImage?.productName}
              Content. You may not sell or modify the {logoImage?.productName} Content or
              reproduce, display, publicly perform, distribute, or otherwise use
              the {logoImage?.productName} Content in any way for any public or commercial
              purpose. The use of the {logoImage?.productName} Content on any other Website or
              in a networked computer environment for any purpose is prohibited.
              You shall not copy or adapt the HTML code that the Company creates
              to generate any {logoImage?.productName} Content or the pages making up any
              {logoImage?.productName} Site, which is also protected by the Company’s copyright.
            </p>
          </div>
          <p className="topic-heading1">5. Content Rules & Limitations</p>
          <div className="topic-content1">
            <span className="subtopic-header">5a. Site Content –</span>
            <span className="subtopic-content">
              You understand that all postings, messages, text, files, images,
              photos, video, sounds,{" "}
            </span>
          </div>
          <div className="subtopic-content1">
            <p>
              or other materials (“Content”) posted on, transmitted through, or
              linked from the Service, are the sole responsibility of the person
              from whom such Content originated. More specifically, you are
              entirely responsible for all Content that you post, email or
              otherwise make available via the Service. You must also have the
              legal right to display the Content. Content that is protected by
              copyright by a third party and content which you do not have a
              license to use should not be uploaded, shared or copied on the
              Site.
            </p>
          </div>
          <div className="subtopic-content2">
            <p>
              You understand that {logoImage?.productName} does not control, and is not
              responsible for Content made available through the Service, and
              that by using the Service, you may be exposed to Content that is
              offensive, indecent, inaccurate, misleading, slanderous,
              offensive, crude, rude, racist, sexist, demeaning and generally
              offensive or otherwise objectionable. You further acknowledge that
              you will solely assume all risk associated with any actions you
              take in response to, or under the advice of, the Content made
              available through this Service. {logoImage?.productName} is not responsible for
              any loss or damages of any kind that may result from your use of
              the Content made available through this Service. You agree to hold
              {logoImage?.productName} harmless from any loss or damages associated with the
              Content or operation of this Service.
            </p>
          </div>
          <div className="topic-content1">
            <span className="subtopic-header">
              5b. Third Party Content/External Sites –
            </span>
            <span className="subtopic-content">
              Furthermore, the {logoImage?.productName} Site and Content available through{" "}
            </span>
          </div>
          <div className="subtopic-content1">
            <p>
              the Service may contain links to other Websites, which are or may
              be completely independent of {logoImage?.productName}. {logoImage?.productName} makes no
              representation or warranty as to the accuracy, completeness or
              authenticity of the information contained in any such Site. Your
              linking to any other Websites is at your own risk. You agree that
              you must evaluate, and bear all risks associated with, the use of
              any Content, that you may not rely on said Content, and that under
              no circumstances will {logoImage?.productName} be liable in any way for any
              Content or for any loss or damage of any kind incurred as a result
              of the use of any Content posted, emailed or otherwise made
              available via the Service.
            </p>
          </div>
          <div className="topic-content1">
            <span className="subtopic-header">
              5c. User Submitted Content –
            </span>
            <span className="subtopic-content">
              You acknowledge that {logoImage?.productName} does not pre-screen or{" "}
            </span>
          </div>
          <div className="subtopic-content1">
            <p>
              approve/disapprove user submitted Content, but that {logoImage?.productName} shall
              have the right (but not the obligation) in its sole discretion to
              screen, modify, alter, refuse, delete, duplicate, display,
              distribute or move any Content that is available via the Service,
              for violating the letter or spirit of the Terms or for any other
              reason. You agree not to duplicate, display or distribute any
              Content from the Service, and agree to abide by any and all
              copyright notices displayed on the Service. {logoImage?.productName} may take any
              action with respect to User Content that it deems necessary or
              appropriate in its sole discretion if it believes it may create
              liability for the Company or may cause the Company to lose (in
              whole or in part) the services of its ISPs or other suppliers.
            </p>
          </div>
          <p className="topic-heading1">6. Limits on Use</p>
          <div className="subtopic-content1">
            <p>
              By using any of the {logoImage?.productName} Websites or networks you agree not to
              data scrape, copy, aggregate, redistribute, alter, reproduce or
              re-use for commercial purposes any user’s (whether specific to any
              particular user or as an aggregation of users’ information)
              information accessible through {logoImage?.productName} or any Websites or
              networks owned or operated by {logoImage?.productName} . “Commercial purposes” can
              include but is not limited to selling information to third
              parties. Using the data collected to customize users’ experiences
              at a Site or network outside of {logoImage?.productName}. Using the data for
              targeted marketing campaigns not affiliated with {logoImage?.productName}. Or
              using the data to offer services to {logoImage?.productName} users. {logoImage?.productName}
              reserves the right to take immediate action against any individual
              or entity participating in any of the prohibited actions mentioned
              above.
            </p>
          </div>
          <p className="topic-heading1">7. Information Disclosure</p>
          <div className="subtopic-content1">
            <p>
              Your use of the {logoImage?.productName} Website or the Service signifies
              acknowledgement of an agreement to our Privacy Policy. You further
              acknowledge and agree that {logoImage?.productName} may, in its sole discretion,
              preserve or disclose your Content, as well as your information,
              such as email addresses, IP addresses, timestamps, and other user
              information, if required to do so by law or in the good faith
              belief that such preservation or disclosure is reasonably
              necessary to: comply with legal process, enforce these terms,
              respond to claims that any Content violates the rights of
              third-parties, respond to claims that contact information (e.g.
              phone number, street address) of a third-party has been posted or
              transmitted without their consent or as a form of harassment,
              protect the rights, property, or personal safety of {logoImage?.productName} , its
              users or the general public.
            </p>
          </div>
          <p className="topic-heading1">8. General Code of Conduct</p>
          <div className="subtopic-content1">
            <p>
              While using the {logoImage?.productName} Site or Site-related services, you agree
              not to post, email, or otherwise make available content that is
              unlawful, harmful, threatening, abusive, harassing, defamatory,
              pornographic, libelous, invasive of another’s privacy, or harms
              minors in any way.
            </p>
          </div>
          <div className="subtopic-content2">
            <p>
              That harasses, degrades, intimidates or is hateful toward an
              individual or group of individuals on the basis of religion,
              gender, sexual orientation, race, ethnicity, age, or disability.
            </p>
          </div>
          <div className="subtopic-content2">
            <p>
              That impersonates any person or entity, including, but not limited
              to, a {logoImage?.productName} employee, or falsely states or otherwise
              misrepresents your affiliation with a person or entity.
            </p>
          </div>
          <div className="subtopic-content2">
            <p>
              That includes personal or identifying information about another
              person without that person’s explicit consent.
            </p>
          </div>
          <div className="subtopic-content2">
            <p>
              That is false, deceptive, misleading, deceitful, mis-informative,
              or constitutes “bait and switch”. That infringes any patent,
              trademark, trade secret, copyright or other proprietary rights of
              any party, or content that you do not have a right to make
              available under any law or under contractual or fiduciary
              relationships.
            </p>
          </div>
          <div className="subtopic-content2">
            <p>
              That constitutes or contains “affiliate marketing,” “link referral
              code,” “junk mail,” “spam,” “chain letters,” “pyramid schemes,” or
              unsolicited commercial advertisement.
            </p>
          </div>
          <div className="subtopic-content2">
            <p>
              That constitutes or contains any form of advertising or
              solicitation if (1) posted in areas of the {logoImage?.productName} Sites, which
              are not designated for such purposes. Or (2) emailed to {logoImage?.productName}
              users who have requested not to be contacted about other services,
              products or commercial interests.
            </p>
          </div>
          <div className="subtopic-content2">
            <p>
              That includes links to commercial services or Websites, except as
              allowed in “services”.
            </p>
          </div>
          <div className="subtopic-content2">
            <p>
              That contains software viruses or any other computer code, files
              or programs designed to interrupt, destroy or limit the
              functionality of any computer software or hardware or
              telecommunications equipment.
            </p>
          </div>
          <div className="subtopic-content2">
            <p>
              That disrupts the normal flow of dialogue with an excessive number
              of messages (flooding attack) to the Service, or that otherwise
              negatively affects other users’ ability to use the Service or That
              employs misleading email addresses, or forged headers or otherwise
              manipulated identifiers in order to disguise the origin of Content
              transmitted through the Service.
            </p>
          </div>
          <div className="subtopic-content2">
            <p>
              Additionally, you agree not to Resell or make available to any
              person not expressly licensed by {logoImage?.productName}, whether via phone, fax,
              e-mail, mail or any other medium, any of the {logoImage?.productName} services or
              any information obtained there from express or imply that any
              statements you make are endorsed by us.
            </p>
          </div>
          <div className="subtopic-content2">
            <p>
              Respond to a job listing on behalf of anyone other than yourself.
              Delete or revise any material posted by any other person or
              entity. Restrict or inhibit any other user from using and enjoying
              the Site and services, including, without limitation, by means of
              “hacking” or defacing any portion of the Site. Use the Site for
              any unlawful commercial, research or information gathering
              purposes.
            </p>
          </div>
          <div className="subtopic-content2">
            <p>
              Post any content on any {logoImage?.productName} Site for any competitor of
              {logoImage?.productName} or that contains links to any Site competitive with
              {logoImage?.productName} contact anyone who has asked not to be contacted or
              “stalk” or otherwise harass anyone.
            </p>
          </div>
          <div className="subtopic-content2">
            <p>
              Collect personal data about other users for commercial or unlawful
              purposes.
            </p>
          </div>
          <div className="subtopic-content2">
            <p>
              Use automated means, including spiders, robots, crawlers, data
              mining tools, or the like to download data from the Service –
              exception is made for standard internet search engines (e.g.
              Google). Post non-local or otherwise irrelevant Content,
              repeatedly post the same or similar Content or otherwise impose an
              unreasonable or disproportionately large load on our
              infrastructure.
            </p>
          </div>
          <div className="subtopic-content2">
            <p>
              Attempt to gain unauthorized access to {logoImage?.productName}’s computer systems
              or engage in any activity that disrupts, diminishes the quality
              of, interferes with the performance of, or impairs the
              functionality of, the Service or the {logoImage?.productName} Website.
            </p>
          </div>
          <p className="topic-heading1">9. Terms of Participation</p>
          <div className="topic-content1">
            <span className="subtopic-header">9a. Modifications – </span>
            <span className="subtopic-content">
              {logoImage?.productName} reserves the right to change or discontinue, temporarily
              or permanently,{" "}
            </span>
          </div>
          <div className="subtopic-content1">
            <p>
              the Service at any time without notice including
              changing/disabling the Site, eliminating or discontinuing any
              content on or feature of the Site as well as adding new features
              at any time. The service may be temporarily unavailable from time
              to time for maintenance or other reasons. You agree that {logoImage?.productName}
              will not be liable to you or any third party for any modification
              or discontinuance of the Service.
            </p>
          </div>
          <div className="topic-content1">
            <span className="subtopic-header">
              9b. Dealings with Individuals and Organizations –{" "}
            </span>
            <span className="subtopic-content">
              Your interactions with organizations and/or{" "}
            </span>
          </div>
          <div className="subtopic-content1">
            <p>
              individuals found on or through the Service, including payment and
              delivery of goods or services, and any other terms, conditions,
              warranties or representations associated with such dealings, are
              solely between you and such organizations and/or individuals. You
              agree that {logoImage?.productName} shall not be responsible or liable for any
              loss or damage of any sort incurred as the result of any such
              dealings.
            </p>
          </div>
          <div className="subtopic-content2">
            <p>
              Therefore and if there is a dispute between participants on this
              Site, or between users and any third party, you understand and
              agree that {logoImage?.productName} is under no obligation to become involved. In
              the event that you have a dispute with one or more other users,
              you hereby forever release {logoImage?.productName}, its owners, its officers,
              employees, service providers, agents and successors in rights from
              claims, demands and damages (actual and consequential) of every
              kind or nature, known or unknown, suspected and unsuspected,
              disclosed and undisclosed, arising out of or in any way related to
              such disputes and / or our service
            </p>
          </div>
          <div className="topic-content1">
            <span className="subtopic-header">9c. Costs – </span>
            <span className="subtopic-content">
              User understands and agrees that User is responsible for all
              applicable costs, taxes and all{" "}
            </span>
          </div>
          <div className="subtopic-content1">
            <p>
              other expenses that are incurred in using the {logoImage?.productName} service. We
              may also, in our sole discretion, add or delete fees associated
              with the Service. If any charge is applicable, we will notify our
              Users in advance.
            </p>
          </div>
          <div className="topic-content1">
            <span className="subtopic-header">9d. No Spam Policy – </span>
            <span className="subtopic-content">
              {logoImage?.productName} strongly opposes SPAM, which floods the Internet with
              unwanted and{" "}
            </span>
          </div>
          <div className="subtopic-content1">
            <p>
              unsolicited email and deteriorates the performance and
              availability of the {logoImage?.productName} service. All forms of SPAM, and all
              activities that have the effect of facilitating SPAM, are strictly
              prohibited. {logoImage?.productName} also prohibits the use of another Internet
              service to send or post SPAM to drive visitors to Your Site hosted
              on or through {logoImage?.productName} systems, whether or not the messages were
              originated by You, under Your direction, or by or under the
              direction of a related or unrelated third party.
            </p>
          </div>
          <div className="subtopic-content2">
            <p>
              You understand and agree that sending unsolicited email
              advertisements to {logoImage?.productName} email addresses or through {logoImage?.productName}
              computer systems, which is expressly prohibited by these Terms,
              will use or cause to be used our servers. Any unauthorized use of
              {logoImage?.productName} computer systems is a violation of these Terms. Such
              violations may subject the sender and his or her agents to civil
              and criminal penalties.
            </p>
          </div>
          <div className="topic-content1">
            <span className="subtopic-header">
              9e.Limitations on Service –{" "}
            </span>
            <span className="subtopic-content">
              You acknowledge that {logoImage?.productName} may establish limits concerning use
              of the{" "}
            </span>
          </div>
          <div className="subtopic-content1">
            <p>
              Service, including but not limited to: the frequency with which
              you may post and/or access the service, the number, length and
              size of Content & how long {logoImage?.productName} will maintain and make
              available the Content
            </p>
          </div>
          <div className="subtopic-content2">
            <p>
              You further agree that {logoImage?.productName} has no responsibility or liability
              for Content that is deleted, failure to store Content. You further
              acknowledge and agree that {logoImage?.productName} may modify or discontinue
              service, or any portion thereof, at any time and for any reason
              and duration, with or without notice, and that {logoImage?.productName} shall not
              be held liable to you or any third party for any reason
              whatsoever, including loss, potential loss, or damages of any
              kind.
            </p>
          </div>
          <div className="topic-content1">
            <span className="subtopic-header">
              9f. Termination Of Service -{" "}
            </span>
            <span className="subtopic-content">
              You agree that {logoImage?.productName} , in its sole discretion, has the right
              (but not the{" "}
            </span>
          </div>
          <div className="subtopic-content1">
            <p>
              obligation) to delete or deactivate your account, block your email
              or IP address, or otherwise terminate your access to or use of the
              Service (or any part thereof), immediately and without notice, and
              remove and discard any Content within the Service, for any reason
              whatsoever, including, without limitation, if {logoImage?.productName} believes
              that you have acted inconsistently with the letter or spirit of
              the Terms. Further, you agree that {logoImage?.productName} shall not be liable to
              you or any third-party for any termination of your access to the
              Service. Further, you agree not to attempt to use the Service
              after said termination.
            </p>
          </div>
          <div className="topic-content1">
            <span className="subtopic-header">9g. Content Violations – </span>
            <span className="subtopic-content">
              If {logoImage?.productName}, in its sole discretion, determines that a violation
              of the Terms of{" "}
            </span>
          </div>
          <div className="subtopic-content1">
            <p>
              Participation has occurred or believes in its sole discretion that
              any content may create liability for {logoImage?.productName}, {logoImage?.productName} may pursue
              any of its legal remedies and may take any actions that it
              believes are prudent or necessary to minimize or eliminate its
              potential liability, including but not limited to, the release of
              User information. In sum, {logoImage?.productName} reserves the right to refuse
              service to anyone at any time, and to remove any material for any
              reason, and without notice. {logoImage?.productName} could also pursue violators
              with claims that they have violated various criminal and/or civil
              laws as applied by the relevant jurisdiction. {logoImage?.productName} will
              cooperate with any investigation by any National, State, or local
              body or any court or tribunal legitimately exercising its rights.
              Such cooperation may be without notice to you.
            </p>
          </div>
          <div className="topic-content1">
            <span className="subtopic-header">
              9h. Cautionary Use on Forums and Other Sections of the Site –{" "}
            </span>
            <span className="subtopic-content">
              {logoImage?.productName} is not involved in any{" "}
            </span>
          </div>
          <div className="subtopic-content1">
            <p>
              transaction between any parties who uses our forums. There are
              risks, which you assume when dealing with people who might be
              acting under false pretenses, all of these risks are borne by you.
              We are a venue only and do not screen or censor or otherwise
              control the postings made on our forums, nor does {logoImage?.productName} screen
              or censor or otherwise control the Users of its service. {logoImage?.productName}
              cannot and does not control the behavior of the participants on
              this Site. It is extremely important that you use care throughout
              your dealings with other people on this Site! {logoImage?.productName} does not
              assume responsibility for the content or context of the user
              comment areas and will not remove or edit postings to the public
              comments areas once they are entered into the service, except to
              expire records or in our sole discretion.
            </p>
          </div>
          <p className="topic-heading1">
            10. Copyrights, Ownership & Content Usage
          </p>
          <div className="topic-content1">
            <span className="subtopic-header">10a. Ownership - </span>
            <span className="subtopic-content">
              The Site, including all pages within and all code related thereto,
              is the property of{" "}
            </span>
          </div>
          <div className="subtopic-content1">
            <p>
              {logoImage?.productName}. No portion of the materials or code on these pages or
              anywhere on the Site may be reprinted or republished (other than
              as is necessary to view the page on your monitor) in any form
              without the express written permission of the Company. The Site is
              owned and operated by us in conjunction with others pursuant to
              contractual arrangements. Unauthorized use of the materials on the
              Site may violate copyright, trademark, patent and other laws and
              is prohibited. You acknowledge that you do not acquire any
              ownership rights by using the Site.
            </p>
          </div>
          <div className="topic-content1">
            <span className="subtopic-header">10b. Copyrights – </span>
            <span className="subtopic-content">
              The Website and all intellectual property rights related to the
              Website, including but{" "}
            </span>
          </div>
          <div className="subtopic-content1">
            <p>
              not limited to copyrights, trademarks, service marks and any
              feedback left by Registered Users on the Website, are owned or
              licensed by us, and all right, title and interest in the Website
              and the related intellectual property rights remain our property.
              You may not reproduce, reverse engineer, decompile, disassemble,
              modify or create derivative works with respect to the Website and
              the related intellectual property, including any services provided
              via the Website, and this Agreement does not grant you permission
              to do so. Certain content is licensed from third parties. All such
              third party content and all intellectual property rights related
              to that content belong to the respective third parties. You may
              not remove any copyright, trademark, or other intellectual
              property or proprietary notice or legend contained in the Website,
              including any Website content.
            </p>
          </div>
          <div className="topic-content1">
            <span className="subtopic-header">10c. Content Usage – </span>
            <span className="subtopic-content">
              You agree that by posting Content to any publicly available area
              of the Service{" "}
            </span>
          </div>
          <div className="subtopic-content1">
            <p>
              and/or by sending or transmitting to us resources, information,
              ideas, notes, concepts, trademarks, service marks or other
              materials (collectively, “Content”), or by posting such Content to
              any area of the Site, you automatically grant and you represent
              and warrant that you have the right to grant to {logoImage?.productName} and our
              designees, an irrevocable, non-exclusive, perpetual, fully paid,
              worldwide license to use, duplicate, display, and distribute said
              Content, and to create derivative works of, or incorporate it into
              other works, and to grant and authorize sublicenses of the
              foregoing. You hereby also grant {logoImage?.productName} permission to display
              your logo, trademarks and company name on the Site and in press
              and other public releases or filings.
            </p>
          </div>
          <div className="subtopic-content2">
            <p>
              By submitting content, you automatically also agree, or promise,
              that the owner of such content has expressly agreed that, without
              any particular time limit, and without the payment of any fees,
              the {logoImage?.productName} and anyone they permit may reproduce, display,
              distribute and create new works of authorship based on and
              including the content. Additionally, you will defend and indemnify
              and hold harmless {logoImage?.productName} from any third-party claim related to a
              breach of any of the foregoing representations and warranties.
            </p>
          </div>
          <div className="subtopic-content2">
            <p>
              Also, note that {logoImage?.productName} endorses none of the Content and {logoImage?.productName}
              cannot and does not make any representations with respect to the
              truth or reliability of the Content. {logoImage?.productName} reserves the right
              to remove any Content, in whole or in part, from the Site.
              Communications between {logoImage?.productName} and the users of the Site are not
              confidential unless specified.
            </p>
          </div>
          <p className="topic-heading1">11. Changed Terms</p>
          <div className="subtopic-content1">
            <p>
              We reserve the right, at any time and from time to time, for any
              reason in our sole discretion, to change the terms of this
              Agreement without notice to you. Once we post them on the Site,
              these changes become effective immediately and if you use the Site
              after they become effective it will signify your agreement to be
              bound by the changes. You should check back frequently and review
              the terms and conditions of this agreement regularly so you are
              aware of the most current rights and obligations that apply to you
              and the terms and conditions of your agreement with us.
            </p>
          </div>
          <p className="topic-heading1">
            12. Data Privacy and SMS/Email Alerts
          </p>
          <div className="subtopic-content1">
            <p>
              In the context of sending important notifications and critical
              alerts, the user consents to the use of his/her personal data in
              the form of his/her communication postal address, fixed landline
              number(s), cellular phone number(s) and email id(s) being used by
              {logoImage?.productName} to forward relevant alerts and other information as and
              when they become available.
            </p>
          </div>
          <p className="topic-heading1">13. Indemnity</p>
          <div className="subtopic-content1">
            <p>
              You agree to defend, hold harmless and indemnify {logoImage?.productName} from and
              against any and all losses, costs, expenses, damages or other
              liabilities incurred by {logoImage?.productName} from and against any cause of
              action, claim, suit or proceeding brought by a third party against
              {logoImage?.productName} in connection with your use of the Services including any
              payment obligations incurred through use of the Services. You
              further agree to indemnify, defend and hold {logoImage?.productName} harmless from
              and against any and all claims, demands, actions, costs,
              liabilities, losses and damages of any kind (including attorneys’
              fees) resulting from:
            </p>
          </div>
          <div className="subtopic-contentInRomanList">
            <span>
              <p className="container-romanlist">(i)</p>
            </span>
            <span className="subtopic-content3">
              <p>your use of the Website</p>
            </span>
          </div>
          <div className="subtopic-contentInRomanList">
            <span>
              <p className="container-romanlist">(ii)</p>
            </span>
            <span className="subtopic-content3">
              <p>
                your decision to supply credit information via the Website,
                including personal financial information.
              </p>
            </span>
          </div>
          <div className="subtopic-contentInRomanList">
            <span>
              <p className="container-romanlist">(iii)</p>
            </span>
            <span className="subtopic-content3">
              <p>
                Your decision to submit postings and accept offers from other
                Users.
              </p>
            </span>
          </div>
          <div className="subtopic-contentInRomanList">
            <span>
              <p className="container-romanlist">(iv)</p>
            </span>
            <span className="subtopic-content3">
              <p>
                Any breach of contract or other claims made by Users with which
                you conducted business through the Website.
              </p>
            </span>
          </div>
          <div className="subtopic-contentInRomanList">
            <span>
              <p className="container-romanlist">(v)</p>
            </span>
            <span className="subtopic-content3">
              <p>Your breach of any provision of this Agreement.</p>
            </span>
          </div>
          <div className="subtopic-contentInRomanList">
            <span>
              <p className="container-romanlist">(vi)</p>
            </span>
            <span className="subtopic-content3">
              <p>
                Any liability arising from the tax treatment of Payments or any
                portion thereof.
              </p>
            </span>
          </div>
          <div className="subtopic-contentInRomanList">
            <span>
              <p className="container-romanlist">(vii)</p>
            </span>
            <span className="subtopic-content3">
              <p>Any negligent or intentional wrongdoing by any User.</p>
            </span>
          </div>
          <div className="subtopic-contentInRomanList">
            <span>
              <p className="container-romanlist">(viii)</p>
            </span>
            <span className="subtopic-content3">
              <p>
                Any act or omission of yours with respect to the Payment in
                respect of Professional Assignments.
              </p>
            </span>
          </div>
          <div className="subtopic-contentInRomanList">
            <span>
              <p className="container-romanlist">(ix)</p>
            </span>
            <span className="subtopic-content3">
              <p>
                Your dispute of or failure to pay any Invoice or any other
                Payment. and/or
              </p>
            </span>
          </div>
          <div className="subtopic-contentInRomanList">
            <span>
              <p className="container-romanlist">(x)</p>
            </span>
            <span className="subtopic-content3">
              <p>
                your obligations to a Professional. Any such indemnification
                shall be conditioned on our:
              </p>
            </span>
          </div>
          <div className="subtopic-contentInDotList">
            <span>
              <BsIcons.BsDot className="container-dotlist-ellipse" />
            </span>
            <span className="subtopic-content3">
              <p>
                (a) Notifying you in writing of any such claim, demand, action,
                cost, liability, loss or threat of any thereof.
              </p>
            </span>
          </div>
          <div className="subtopic-contentInDotList">
            <span>
              <BsIcons.BsDot className="container-dotlist-ellipse" />
            </span>
            <span className="subtopic-content3">
              <p>
                (b) Cooperating with you in the defense or settlement thereof
                and
              </p>
            </span>
          </div>
          <div className="subtopic-contentInDotList">
            <span>
              <BsIcons.BsDot className="container-dotlist-ellipse" />
            </span>
            <span className="subtopic-content3">
              <p>
                (c) Allowing you to control such defense or settlement. We shall
                be entitled to participate in such defense through our own
                counsel at our own cost and expense. We reserve the right to
                report any wrongdoing of which we become aware to the applicable
                government agencies or otherwise.
              </p>
            </span>
          </div>
          <div className="subtopic-content1">
            <p>
              Please report any violations of the Terms by emailing us. Our
              failure to act with respect to a breach by you or others does not
              waive our right to act with respect to subsequent or similar
              breaches. In its sole discretion, {logoImage?.productName} may elect to issue a
              warning before assessing damages. Otherwise, you agree to pay
              {logoImage?.productName}’s actual damages, to the extent such actual damages can
              be reasonably calculated.
            </p>
          </div>
          <p className="topic-heading1">14. Disclaimer of Warranties</p>
          <div className="subtopic-content1">
            <p>
              a. You agree that use of the service is at user’s sole risk. The
              service is provided on an as is and on an as available basis.
              {logoImage?.productName} expressly disclaims all warranties of any kind, whether
              express or implied, including, but not limited to the implied
              warranties of merchantability, fitness for a particular purpose
              and non-infringement. We make no representations or warranties, of
              any kind, either express or implied, as to the quality, identity
              or reliability of any user or third party, or as to the accuracy
              of the content made on the Website.
            </p>
          </div>
          <div className="subtopic-content2">
            <p>
              b. {logoImage?.productName} makes no warranty that the service will meet user’s
              requirements, that the service will be uninterrupted, timely,
              secure, or error free nor does {logoImage?.productName} make any warranty as to
              the results that may be obtained from the use of the service or as
              to the accuracy or reliability of any information obtained from
              the service.
            </p>
          </div>
          <div className="subtopic-content2">
            <p>
              c. User understands and agrees that any information or material
              and/or goods or services obtained through the service is done at
              user’s own discretion and risk and that user will be solely
              responsible for any damage resulting from any transaction.
            </p>
          </div>
          <div className="subtopic-content2">
            <p>
              d. No advice or information, whether oral or written, obtained by
              user from {logoImage?.productName} or through or from the service shall create any
              warranty not expressly stated herein.
            </p>
          </div>
          <div className="subtopic-content2">
            <p>
              e. No agency – there is no agency, partnership, joint venture,
              employee-employer or franchisor-franchisee relationship between
              {logoImage?.productName} and any user of the service.
            </p>
          </div>
          <p className="topic-heading1">15. Limitation of Liability</p>
          <div className="subtopic-content1">
            <p>
              a. User agrees that neither {logoImage?.productName} nor any of its associates or
              providers of information shall be liable for any direct, indirect,
              incidental, special, consequential or exemplary damages, resulting
              from the use or the inability to use the service or for cost of
              procurement of substitute goods and services or resulting from any
              goods, data, information or services purchased or obtained or
              messages received or transactions entered into through or from the
              service or resulting from unauthorized access to or alteration of
              user’s transmissions or data or arising from any other matter
              relating to the service, including but not limited to, damages for
              loss of profits, use, data or other intangible, even if {logoImage?.productName}
              has been advised of the possibility of such damages.
            </p>
          </div>
          <div className="subtopic-content2">
            <p>
              b. User further agrees that {logoImage?.productName} shall not be liable for any
              damages arising from interruption, suspension or termination of
              service, including but not limited to direct, indirect,
              incidental, special consequential or exemplary damages, whether
              such interruption, suspension or termination was justified or not,
              negligent or intentional, inadvertent or advertent. User agrees
              that {logoImage?.productName} shall not be responsible or liable to user, or
              anyone, for the statements or conduct of any third party of the
              service. These limitations shall apply to the fullest extent
              permitted by law. In sum, in no event shall {logoImage?.productName} total
              liability to you for all damages, losses, and causes of action
              exceed the amount paid by you to {logoImage?.productName} , if any that is related
              to the cause of action.
            </p>
          </div>
          <div className="subtopic-content2">
            <p>
              c. These terms of participation and the relationship between user
              and {logoImage?.productName} shall be governed by the laws of the state of Tamil
              Nadu, India without regard to its conflict of law provisions. User
              and {logoImage?.productName} agree to submit to the personal and exclusive
              jurisdiction of the courts of Chennai, Tamil Nadu, India.
            </p>
          </div>
          <div className="subtopic-content2">
            <p>
              d. In the event that you have a dispute with one or more Users,
              you release us (and our agents and employees) from claims, demands
              and damages (actual, consequential and punitive) of every kind and
              nature, known and unknown, suspected and unsuspected, disclosed
              and undisclosed, arising out of or in any way connected with the
              Website, the Services, or such dispute. You waive the provisions
              of any state law limiting or prohibiting a general release.
            </p>
          </div>
          <div className="subtopic-content2">
            <p>
              e. Our contracts with certain of our suppliers provide for
              scheduled maintenance, preventative maintenance and emergency
              maintenance work. We will have no liability whatsoever for any
              unavailability of the Website caused by our supplier’s performance
              or lack of performance of the maintenance work needed to keep the
              Website operable. We will also not have any liability for any loss
              of data or transactions resulting from delays, non-deliveries,
              mis-deliveries or service interruptions caused by us, any third
              party acts, the acts of any web services provider or problems with
              the Internet infrastructure and networks external to the Website.
              The limitations of liability provided in this Agreement inure to
              the benefit of us, our affiliates and all of our respective
              officers, directors, employees, attorneys and agents.
            </p>
          </div>
          <p className="topic-heading1">16. Assignment</p>
          <div className="subtopic-content1">
            <p>
              {logoImage?.productName} has the right to transfer or assign this Agreement and
              the rights hereunder to any other person or entity without your
              consent. You do not have the right to assign or transfer this
              Agreement or any rights thereunder to any other person or entity
              without our prior written consent. Subject to the foregoing, this
              Agreement will be binding upon and will inure to the benefit of
              the successors and permitted assigns of the parties hereto.
            </p>
          </div>
          <p className="topic-heading1">17. Feedback</p>
          <div className="subtopic-content1">
            <p>
              Please help us keep the {logoImage?.productName} Website an enjoyable experience
              for all Users. If you observe material or behavior that may
              violate any of the {logoImage?.productName}’s Legal (Terms of Use) or Privacy
              Policy, or have suggestions and ideas to improve our service,
              please contact us.
            </p>
          </div>
        </div>
        <div className="legalBackButton-Container">
          <Button
            variant="outlined"
            style={{
              border: "1px solid #794DF5",
              color: "#794DF5",
              textTransform: "none",
              marginTop: "20px",
              width: "110px",
              height: "43px",
              fontSize: "18px",
              fontWeight: 600,
              fontFamily: "Poppins",
            }}
            onClick={() => history.goBack()}
          >
            Back
          </Button>
        </div>
      </div>
    </>
  );
}

export default studentsLegal;
