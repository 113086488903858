import React, { useEffect, useMemo, useState } from "react";
import { Paper } from "@material-ui/core";
import styles from "./UserAssessmentMarks.module.scss";
import { sessionPrivileges } from "privileges";
import Select from "react-select";
import { GetObjectFromString } from "../utils/GetObjectFromString";
import { getCustomers } from "../../store/StudentAndCustomer/actions";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import CustomSearch from "./component/CustomSearch";
import * as CustomIcons from "../../assets/icons/icons.js";
import { Button } from "reactstrap";
import { Tabs } from "../../components/tabs/tabsStudent";
import Calender from "./component/calender";
import Apihelper from "../../api/apiHelper";
import api from "../../api/baseConfig";
import MeatingList from "./meetingList";
import Container from "@material-ui/core/Container";
import CloudLabBanner from "./component/cloudLabBanner";
import MeatingListStudent from "./meetingListStudent";
import CalenderStudent from "./component/calenderStudent";

function CloudLabStudent(props) {
  const defaultCourses = {
    meetingTimeline: [],
    MeetingsList: [],
  };
  const [data, setData] = useState(null);
  const Priv = sessionPrivileges().userprivileges || [];
  const [activeTab, setActiveTab] = useState("MeetingTimeline");
  const [userDataList, setuserDataList] = useState([]);
  const [loadingDot, setLoadingDot] = useState(false);
  const [customSearchValue, setCustomSearchValue] = useState("");
  const [statusValue, setStatusValue] = useState("");
  const [cloudLabData, setCloudLabData] = useState([]);
  const [courseSearch, setCourseSearch] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [cloudLabList, setCloudLabList] = useState({
    real: defaultCourses,
    modify: defaultCourses,
  });
  const [customerId, setCustomerId] = useState(() =>
    Priv?.includes("MANAGE_CUSTOMER")
      ? props?.location?.params?.customerId || ""
      : sessionStorage.getItem("customer_id") || ""
  );
  const customers = useMemo(
    () =>
      (props?.Customers || []).sort(
        ({ name: n1 = "" }, { name: n2 = "" }) =>
          n1?.toLowerCase() < n2?.toLowerCase()
      ),
    [props?.Customers]
  );
  const customersList = [];
  customers.forEach(function (item) {
    customersList.push({ label: item.name, value: item.id });
  });
  useEffect(() => {
    if (Priv?.includes("MANAGE_CUSTOMER")) {
      props.dispatch(getCustomers());
    } else {
      getAssessmentsByCustomer(sessionStorage.getItem("customer_id"));
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (customerId && customerId !== "") {
      getAssessmentsByCustomer(customerId);
    }
  }, [customerId]);

  async function getAssessmentsByCustomer(customer) {
    setLoadingDot(true);
    await Apihelper.axiosCall(
      `${api.baseURL}${api.CloudLab.CloudLabStudent}`,
      "get"
    )
      .then((data) => {
        const newCoursesSkeleton = { ...data };
        setCloudLabData(data.find((e) => setStatusValue(e.status)));
        setCloudLabList({
          real: newCoursesSkeleton,
          modify: newCoursesSkeleton,
        });
      })
      .catch((e) => {
        console.log(`erroe${e}`);
      });
  }
  const totalCloud = Object.keys(cloudLabList.modify);
  const tabList = [
    {
      name: "Meeting timeline",
      id: "MeetingTimeline",
    },
    {
      name: "Meetings list",
      id: "MeetingsList",
    },
  ];
  const handleChange = (e) => {
    setSearchTerm(e.target.value);
  };
  const handleCourseChange = (e) => {
    setCourseSearch(e.target.value);
  };
  return (
    <div>
      <Container>
        <div>
          <CloudLabBanner />
        </div>
        <div className="">
          <div className="mb-4 d-flex justify-content-between">
            <div className="mb-4 search-input">
              <input
                type="search"
                placeholder="&#xf002; Search...."
                aria-describedby="button-addon4"
                className="form-controls"
                style={{ fontFamily: "FontAwesome, Poppins" }}
                value={courseSearch}
                onChange={handleCourseChange}
                onClick={(e) => setActiveTab("MeetingsList")}
                autoComplete="off"
              />
            </div>
          </div>
        </div>
        <Tabs
          activeTab={activeTab}
          contentClassName={styles.tabContent}
          onChange={setActiveTab}
          tabs={tabList}
          hideBadge={true}
        />
        {activeTab === "MeetingTimeline" && (
          <>
            <CalenderStudent cloudLabList={cloudLabList} />
          </>
        )}
        {activeTab === "MeetingsList" && (
          <>
            {/* {sessionPrivileges.isStudent && */}
            <MeatingListStudent
              customerId={customerId}
              cloudLabList={cloudLabList}
              setCloudLabList={setCloudLabList}
              totalCloud={totalCloud}
              statusValue={statusValue}
              courseSearch={courseSearch}
            />
            {/* } */}
          </>
        )}
      </Container>
    </div>
  );
}
const mapStateToProps = (state) => ({
  Customers: state.StudentAndCustomerReducer.Customers,
});

export default connect(mapStateToProps, null)(withRouter(CloudLabStudent));
