import { useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import {
  Paper,
  Tab as MuiTab,
  Tabs as MuiTabs,
  TabScrollButton as MuiTabScrollButton,
  withStyles,
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import Section from '../section/section';
import styles from './tabbed-section.module.scss';

const TabBar = withStyles((theme) => ({
  root: {
    backgroundColor: (theme.palette.type === 'light') ? '#EAF4FF' : '#00315C',
    border: 'none',
    borderRadius: '0 !important',
    minHeight: 'auto',
    position: 'relative',
  },
}))(Paper);

const Tabs = withStyles({
  root: {
    borderRadius: 0,
    height: 40,
    justifyContent: 'flex-start !important',
    minHeight: 'auto',
    position: 'relative',
  },
  indicator: {
    height: 3,
  },
})(MuiTabs);

const TabButton = withStyles((theme) => ({
  root: {
    backgroundColor: 'transparent',
    color: `${(theme.palette.type === 'light') ? '#000' : '#fff'} !important`,
    fontSize: 14,
    height: 40,
    margin: 'auto 0',
    minHeight: 'auto',
    minWidth: 'auto',
    position: 'relative',
    padding: '0 14px',
    textTransform: 'capitalize',
    whiteSpace: 'nowrap',
    '&:first-of-type': {
      marginLeft: 6,
    },
    '&:last-of-type': {
      marginRight: 6,
    },
  },
  selected: {
    backgroundColor: (theme.palette.type === 'light') ? 'transparent' : '#0D487B',
  },
}))(MuiTab);

const TabScrollButton = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    height: '100%',
    opacity: 1,
    position: 'absolute',
    width: 24,
    zIndex: 10,
  },
}))(MuiTabScrollButton);

export default function TabbedSection({
  available,
  canView,
  children,
  disabledText,
  isLightMode,
  loading,
  noTabs,
  setVisibility,
  tabClassName,
  tabContentRender,
  tabIndexLabel,
  tabs,
  title,
  transparentTabButtonBG,
  unAvailableText,
  visible,
  valueForCurrent,
  ...props
}) {
  const [currentTab, setCurrentTab] = useState(0);
  const contentRef = useRef(null);

  // useEffect(() => {
  //   setCurrentTab(0);
  // }, [disabledText]);

  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.scrollTop = 0;
    }
  }, [currentTab]);
  return (
    <Section
      noContentTags
      visible={visible}
      setVisibility={(available && canView) ? setVisibility : undefined}
      title={title}
      {...props}>
      {valueForCurrent == 0 ?
      (canView ? available
        ? ((visible && loading)
          ? (
            <div className={styles.container}>
              {!noTabs && (
                <TabBar square>
                  <Tabs
                    indicatorColor="primary"
                    textColor="primary"
                    value={0}
                    variant="scrollable">
                    <TabButton label={<Skeleton width={60} />} />
                  </Tabs>
                </TabBar>
              )}
              <div
                className={cn(styles.content, {
                  [styles.noTabs]: noTabs,
                })}>
                <Skeleton height={32} width="65%" />
                <Skeleton height={32} width="45%" />
                <Skeleton height={32} width="55%" />
              </div>
            </div>
          )
          : (
            <div
              className={cn(styles.container, styles.group, {
                [styles.visible]: visible,
              })}>
              {!noTabs && (
                <TabBar square>
                  {!!tabs?.length
                    ? (
                      <Tabs
                        indicatorColor="primary"
                        onChange={(_e, newTab) => {
                          setCurrentTab?.(newTab);
                        }}
                        ScrollButtonComponent={(props) => (
                          <TabScrollButton
                            {...props}
                            style={{
                              ...(props?.direction === 'left') && { left: 0 },
                              ...(props?.direction === 'right') && { right: 0 },
                            }}
                          />
                        )}
                        scrollButtons="auto"
                        textColor="primary"
                        value={currentTab}
                        variant="scrollable">
                        {tabs?.map((_tab, idx) => (
                          <TabButton
                            key={idx}
                            label={`${tabIndexLabel} ${idx + 1}`}
                            style={{
                              ...transparentTabButtonBG && {
                                backgroundColor: 'transparent',
                              },
                            }}
                          />
                        ))}
                      </Tabs>
                    ) : (
                      <Tabs
                        indicatorColor="primary"
                        textColor="primary"
                        value={0}
                        variant="scrollable">
                        <TabButton label={<Skeleton animation={false} width={60} />} />
                      </Tabs>
                    )}
                </TabBar>
              )}
              {visible
                ? (
                  <div
                    className={cn(styles.content, {
                      [styles.noTabs]: noTabs,
                    })}
                    ref={contentRef}>
                    {tabContentRender
                      ? tabContentRender?.(tabs?.[currentTab])
                      : <p className={tabClassName} dangerouslySetInnerHTML={{ __html: tabs?.[currentTab] }} />}
                  </div>
                )
                : (
                  <div
                    className={cn(styles.content, {
                      [styles.noTabs]: noTabs,
                    })}>
                    <Skeleton animation={false} height={32} width="65%" />
                    <Skeleton animation={false} height={32} width="45%" />
                    <Skeleton animation={false} height={32} width="55%" />
                  </div>
                )}
            </div>
          )
        )
        : <p className={styles.noHintText}>{unAvailableText}</p>
      : <p className={styles.noHintText}>{disabledText}</p>
  ) : children || (canView ? available
    ? ((visible && loading)
      ? (
        <div className={styles.container}>
          {!noTabs && (
            <TabBar square>
              <Tabs
                indicatorColor="primary"
                textColor="primary"
                value={0}
                variant="scrollable">
                <TabButton label={<Skeleton width={60} />} />
              </Tabs>
            </TabBar>
          )}
          <div
            className={cn(styles.content, {
              [styles.noTabs]: noTabs,
            })}>
            <Skeleton height={32} width="65%" />
            <Skeleton height={32} width="45%" />
            <Skeleton height={32} width="55%" />
          </div>
        </div>
      )
      : (
        <div
          className={cn(styles.container, styles.group, {
            [styles.visible]: visible,
          })}>
          {!noTabs && (
            <TabBar square>
              {!!tabs?.length
                ? (
                  <Tabs
                    indicatorColor="primary"
                    onChange={(_e, newTab) => {
                      setCurrentTab?.(newTab);
                    }}
                    ScrollButtonComponent={(props) => (
                      <TabScrollButton
                        {...props}
                        style={{
                          ...(props?.direction === 'left') && { left: 0 },
                          ...(props?.direction === 'right') && { right: 0 },
                        }}
                      />
                    )}
                    scrollButtons="auto"
                    textColor="primary"
                    value={currentTab}
                    variant="scrollable">
                    {tabs?.map((_tab, idx) => (
                      <TabButton
                        key={idx}
                        label={`${tabIndexLabel} ${idx + 1}`}
                        style={{
                          ...transparentTabButtonBG && {
                            backgroundColor: 'transparent',
                          },
                        }}
                      />
                    ))}
                  </Tabs>
                ) : (
                  <Tabs
                    indicatorColor="primary"
                    textColor="primary"
                    value={0}
                    variant="scrollable">
                    <TabButton label={<Skeleton animation={false} width={60} />} />
                  </Tabs>
                )}
            </TabBar>
          )}
          {visible
            ? (
              <div
                className={cn(styles.content, {
                  [styles.noTabs]: noTabs,
                })}
                ref={contentRef}>
                {tabContentRender
                  ? tabContentRender ?.(tabs?.[currentTab])
                  : <p className={tabClassName} dangerouslySetInnerHTML={{ __html: tabs?.[currentTab] }} />}
              </div>
            )
            : (
              <div
                className={cn(styles.content, {
                  [styles.noTabs]: noTabs,
                })}>
                <Skeleton animation={false} height={32} width="65%" />
                <Skeleton animation={false} height={32} width="45%" />
                <Skeleton animation={false} height={32} width="55%" />
              </div>
            )}
        </div>
      )
    )
    : <p className={styles.noHintText}>{unAvailableText}</p>
  : <p className={styles.noHintText}>{disabledText}</p>
  )}
    </Section>
  );
}
