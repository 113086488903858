import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button as Button1,
} from "@material-ui/core";
import { CloseOutlined } from "@material-ui/icons";
import { Button } from "reactstrap";
import { useHistory } from "react-router-dom";
import styles from "./ComposeMail1.module.scss";
import moment from "moment";
import * as CustomIcons from "../../../assets/icons/icons";
// import { sessionPrivileges } from "privileges";

const ScheduleMailModal = (props) => {
  const {
    isOpen,
    toggle,
    disabled,
    summary,
    customerId,
    activePage,
    activeTab,
    data,
    scheduledTime,
    setScheduledTime,
    submitScheduleTimer,
    setScheduled,
  } = props;

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    watch,
    control,
    reset,
    setValue,
  } = useForm({ mode: "onBlur" });

  const [schdTime, setSchdTime] = useState();

  const history = useHistory();
  const eyeIcon = (
    <svg
      width="18"
      height="14"
      viewBox="0 0 18 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.91625 7.20117C6.91625 7.78133 7.14672 8.33773 7.55695 8.74797C7.96719 9.1582 8.52359 9.38867 9.10375 9.38867C9.68391 9.38867 10.2403 9.1582 10.6505 8.74797C11.0608 8.33773 11.2913 7.78133 11.2913 7.20117C11.2913 6.62101 11.0608 6.06461 10.6505 5.65438C10.2403 5.24414 9.68391 5.01367 9.10375 5.01367C8.52359 5.01367 7.96719 5.24414 7.55695 5.65438C7.14672 6.06461 6.91625 6.62101 6.91625 7.20117ZM17.5842 6.69727C15.7327 2.79688 12.9338 0.833984 9.18188 0.833984C5.42797 0.833984 2.63109 2.79687 0.779532 6.69922C0.705265 6.85648 0.666748 7.02823 0.666748 7.20215C0.666748 7.37606 0.705265 7.54782 0.779532 7.70508C2.63109 11.6055 5.42992 13.5684 9.18188 13.5684C12.9358 13.5684 15.7327 11.6055 17.5842 7.70312C17.7346 7.38672 17.7346 7.01953 17.5842 6.69727ZM9.10375 10.6387C7.20531 10.6387 5.66625 9.09961 5.66625 7.20117C5.66625 5.30273 7.20531 3.76367 9.10375 3.76367C11.0022 3.76367 12.5413 5.30273 12.5413 7.20117C12.5413 9.09961 11.0022 10.6387 9.10375 10.6387Z"
        fill="#794DF5"
      />
    </svg>
  );
  const bsxTimer = (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13 3H17V5H13V3ZM3 8H7V10H3V8ZM3 16H7V18H3V16ZM2 12H5.99V14H2V12ZM21.707 6.707L20.293 5.293L18.586 7C17.5041 6.34616 16.2641 6.00037 15 6C11.141 6 8 9.141 8 13C8 16.859 11.141 20 15 20C18.859 20 22 16.859 22 13C21.999 11.2461 21.3366 9.55701 20.145 8.27L21.707 6.707ZM16 14H14V8.958H16V14Z"
        fill="#63606C"
      />
    </svg>
  );

  let istdate = new Date(new Date().getTime() + (5 * 60 + 30) * 60000);
  let [date, restdata] = istdate.toISOString().split("T");
  let time = restdata.split(".")[0];
  let minDateTime = date + "T" + time;

  return (
    <Dialog open={isOpen} onClose={toggle}>
      <DialogTitle classes={{ root: styles.header }}>
        Schedule mail
        <CloseOutlined
          classes={{ root: styles.closeButton }}
          onClick={toggle}
        />
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit(submitScheduleTimer)}>
          <div
            style={{ marginTop: 10, display: "flex", flexDirection: "column" }}
          >
            <p className={styles.scheduleMailSubHeader}>
              Please select schedule date and time below
            </p>
            <label
              className={styles.scheduleMailLabel}
              htmlFor="scheduledMail-time"
            >
              Schedule date and time
            </label>
            <input
              type="datetime-local"
              id="scheduledMail-time"
              {...register("scheduleTimer")}
              className="terv-form terv-form-xsd"
              style={{ width: "315px" }}
              onChange={(e) => {
                setScheduledTime(e.target.value);
                setScheduled(true);
                setSchdTime(e.target.value);
              }}
            />
            <p className={styles.scheduledMailInfo}>
              Mail has been scheduled on
            </p>
            <div className={styles.scheduledMailInfoContainer}>
              {schdTime && (
                <p style={{ marginTop: "5px" }}>
                  {bsxTimer} {moment(schdTime).format("Do MMMM YYYY h:mm a")}
                </p>
              )}
            </div>
            <div
              style={{
                display: "flex",
                gap: 15,
                marginTop: 20,
                marginBottom: 20,
              }}
            >
              <Button
                color="primary"
                type="submit"
                onClick={() => {
                  toggle();
                  setShowMailLoadingMsg(
                    "Please Hold On, While your mails is being scheduled"
                  );
                }}
                style={{
                  fontSize: 18,
                  paddingLeft: 20,
                  paddingRight: 20,
                  width: 164,
                  height: 43,
                  fontFamily: "Poppins",
                }}
                disabled={disabled}
              >
                Schedule mail
              </Button>
              <Button1
                onClick={() => {
                  toggle;
                }}
                style={{
                  fontSize: 18,
                  paddingLeft: 20,
                  paddingRight: 20,
                  width: 150,
                  height: 43,
                  fontFamily: "Poppins",
                  backgroundColor: "#F2F1F3",
                  color: "#63606C",
                  border: "1px solid #F2F1F3",
                  textTransform: "lowercase",
                }}
              >
                Cancel
              </Button1>
            </div>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default ScheduleMailModal;
