import cn from 'classnames';
import { Button } from '@material-ui/core';
import { TBackButtonProps } from './back-button.props';
import styles from './back-button.module.scss';

export function BackButton(props: TBackButtonProps) {
  return (
    <Button
      className={cn(
        styles.submitButton,
        props?.smallScreen && styles.smallScreen,
      )}
      color="primary"
      size="large"
      onClick={() => {
        props.submitAssessment(false, false);
      }}
      variant="contained">
      Back
    </Button>
  );
}
