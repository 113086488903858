import React, { useState } from "react"
import api from "../../../api/baseConfig"
import Apihelper from "../../../api/apiHelper"
import { Card, CardBody } from "reactstrap"
import Breadcrumbs from "../../../components/Common/Breadcrumb"

export default function Blobs(props) {
  const [blob, setBlob] = useState(props.location?.state.blobs)

  const [y, setY] = useState("")

  const getURL = async media => {
    let url = ""
    try {
      await Apihelper.axiosCallLC(
        `${api.lcBaseURL}${api.lcUserManagement.getAttachment}?attachment=${media}`,
        "get"
      ).then(data => {
        url = data.attachment_url
      })
    } catch (e) {}
    return url ? url : ""
  }

  const blobGet = async () => {
    let tempBlob = await getURL(blob[0])
    if (tempBlob) setY(tempBlob)
  }

  if (blob) blobGet()

  return (
    <div className="page-content">
      <Breadcrumbs title="Blobs" breadcrumbItem="Assessment Report" />
      <Card>
        <CardBody>
          {blob ? (
            <img src={y} width="100px" height="100px" />
          ) : (
            <h2>No Blobs found!</h2>
          )}
        </CardBody>
      </Card>
    </div>
  )
}
