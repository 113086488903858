import MasterForm from "./MasterForm";

export default function CreateSubCompetency(props) {
  return (
    <MasterForm
      sub
      postLink="createCompetency"
      label={{
        breadcrumb: "Sub Competency",
        back: "Sub Competencies",
      }}
      backLink="/competency"
      location={{
        ...props.location,
      }}
      history={{
        ...props.history,
      }}
      match={{
        ...props.match,
      }}
      activePage={props?.location?.state?.activePage}
      itemsPerPage={props?.location?.state?.itemsPerPage}
    />
  );
}
