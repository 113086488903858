import { GetRandom } from "./GetRandom";

type ItemProp = {
  [key: string]: any;
};

export const GetItemWithMedia = (item: ItemProp): ItemProp => ({
  ...item,
  media: item?.media
    ? item.media.includes("default")
      ? `https://d2ndap9rlps54r.cloudfront.net/DefaultUploadImages/${item.media}.jpeg`
      : item.media
    : `https://d2ndap9rlps54r.cloudfront.net/DefaultUploadImages/default-${
        GetRandom(9) + 1
      }.jpeg`,
});
