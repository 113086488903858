import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import {
  PracIcon,
  LiveClassIcon,
  AssessmentIcon,
  scromIcon,
} from "./../../assets/icons/icons";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "60.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));
const timePad = (val) => ("0" + Math.floor(val)).slice(-2);
function timeFormat(t) {
  const time = parseFloat(t);
  if (time && time > 0) {
    const sec = time % 60;
    const hr = Math.floor(time / 3600);
    return `${hr > 0 ? `${timePad(hr)}:` : ""}${timePad(
      (time % 3600) / 60
    )}:${timePad(time % 60)}`;
  } else return `00:00`;
}

export default function CourseContent(props) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <>
      <Grid container spacing={0}>
        <Grid item xs={12} sm={3}>
          <h2 className="sec-title">Course content</h2>
        </Grid>
        <Grid item xs={12} sm={9}>
          <div
            className="d-flex p-2 bd-highlight course-desc text-muted"
            style={{ width: "100%", justifyContent: "flex-end" }}
          >
              {props.course_overall?.courseVideoCount > 0 && (

            <p>
              {/* <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="ml-2 mb-1"
              >
                <path
                  d="M9.99996 1.66669C5.40496 1.66669 1.66663 5.40502 1.66663 10C1.66663 14.595 5.40496 18.3334 9.99996 18.3334C14.595 18.3334 18.3333 14.595 18.3333 10C18.3333 5.40502 14.595 1.66669 9.99996 1.66669ZM9.99996 16.6667C6.32413 16.6667 3.33329 13.6759 3.33329 10C3.33329 6.32419 6.32413 3.33335 9.99996 3.33335C13.6758 3.33335 16.6666 6.32419 16.6666 10C16.6666 13.6759 13.6758 16.6667 9.99996 16.6667Z"
                  fill="#7C7887"
                />
                <path
                  d="M7.5 14.1666L14.1667 9.99998L7.5 5.83331V14.1666Z"
                  fill="#7C7887"
                />
              </svg>{" "} */}
              {props.course_overall.courseVideoCount === 1
                  ? '1 video'
                  : `${props.course_overall.courseVideoCount} videos`} &nbsp;
              </p>
            )}
  
            {props.course_overall?.courseDocumentCount > 0 && (
            <p>
              {/* <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="ml-2 mb-1"
              >
                <path
                  d="M17.5 2.5H11.6666C11.0241 2.5 10.4433 2.75083 9.99996 3.15C9.55663 2.75083 8.97579 2.5 8.33329 2.5H2.49996C2.03913 2.5 1.66663 2.87333 1.66663 3.33333V15.8333C1.66663 16.2942 2.03913 16.6667 2.49996 16.6667H7.29829C7.73663 16.6667 8.16663 16.845 8.47663 17.155L9.41079 18.0892C9.41829 18.0967 9.42829 18.0992 9.43579 18.1067C9.50746 18.1725 9.58746 18.2308 9.68079 18.27C9.68163 18.27 9.68163 18.27 9.68246 18.27C9.78329 18.3117 9.89079 18.3333 9.99996 18.3333C10.1091 18.3333 10.2166 18.3117 10.3175 18.27C10.3183 18.27 10.3183 18.27 10.3191 18.27C10.4125 18.2308 10.4925 18.1725 10.5641 18.1067C10.5716 18.0992 10.5816 18.0967 10.5891 18.0892L11.5233 17.155C11.8333 16.845 12.2633 16.6667 12.7016 16.6667H17.5C17.9608 16.6667 18.3333 16.2942 18.3333 15.8333V3.33333C18.3333 2.87333 17.9608 2.5 17.5 2.5ZM7.29829 15H3.33329V4.16667H8.33329C8.79329 4.16667 9.16663 4.54083 9.16663 5V15.5742C8.61829 15.205 7.96413 15 7.29829 15ZM16.6666 15H12.7016C12.0358 15 11.3816 15.205 10.8333 15.5742V5C10.8333 4.54083 11.2066 4.16667 11.6666 4.16667H16.6666V15Z"
                  fill="#7C7887"
                />
              </svg> */}
              <span style={{ paddingRight: "5%" }}>
                <svg
                  width="6"
                  height="6"
                  viewBox="0 0 6 6"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="3" cy="3" r="3" fill="#D8D6DB" />
                </svg>
              </span>
              {props.course_overall?.courseDocumentCount} study material &nbsp;
            </p>
              )}

          {props.course_overall && (
            (props.course_overall.courseDurationHour > 0 ||
             props.course_overall.courseDurationMin > 0 ||
             props.course_overall.courseDurationSec > 0) && (            
              <p style={{whiteSpace:"nowrap", paddingLeft:"2%"}}>
              {/* <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="ml-2 mb-1"
              >
                <path
                  d="M9.99996 1.66669C5.40496 1.66669 1.66663 5.40502 1.66663 10C1.66663 14.595 5.40496 18.3334 9.99996 18.3334C14.595 18.3334 18.3333 14.595 18.3333 10C18.3333 5.40502 14.595 1.66669 9.99996 1.66669ZM9.99996 16.6667C6.32413 16.6667 3.33329 13.6759 3.33329 10C3.33329 6.32419 6.32413 3.33335 9.99996 3.33335C13.6758 3.33335 16.6666 6.32419 16.6666 10C16.6666 13.6759 13.6758 16.6667 9.99996 16.6667Z"
                  fill="#7C7887"
                />
                <path
                  d="M10.8333 5.83331H9.16663V10.8333H14.1666V9.16665H10.8333V5.83331Z"
                  fill="#7C7887"
                />
              </svg> */}
              <span style={{ paddingRight: "5%", paddingLeft: "5%" }}>
                <svg
                  width="6"
                  height="6"
                  viewBox="0 0 6 6"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="3" cy="3" r="3" fill="#D8D6DB" />
                </svg>
              </span>
              {props.course_overall && `${(props.course_overall.courseDurationHour)}:${(props.course_overall.courseDurationMin)}:${(props.course_overall.courseDurationSec)}`}
            </p>
              )
              )}

            {props.course_overall?.courseLabCount > 0 && (
              <p style={{paddingLeft:"2%"}}>
                <span style={{ paddingRight: "5%", paddingLeft: "5%" }}>
                  <svg
                    width="6"
                    height="6"
                    viewBox="0 0 6 6"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="3" cy="3" r="3" fill="#D8D6DB" />
                  </svg>
                </span>
                {/* {PracIcon({ size: 20, color: "#7C7887" })} */}
                {props.course_overall.courseLabCount === 1
                  ? '1 Lab'
                  : `${props.course_overall.courseLabCount} Labs`} &nbsp;
              </p>
            )}
            {props.course_overall?.courseLiveClassCount > 0 && (
              <p>
                <span style={{ paddingRight: "5%" }}>
                  <svg
                    width="6"
                    height="6"
                    viewBox="0 0 6 6"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="3" cy="3" r="3" fill="#D8D6DB" />
                  </svg>
                </span>
                {props.course_overall?.courseLiveClassCount} Classes &nbsp;
              </p>
            )}
            {props.course_overall?.courseAssessmentCount > 0 && (
              <p style={{paddingLeft:"2%"}}>
                {/* {AssessmentIcon({ size: 20, color: "#7C7887" })} */}
                <span style={{ paddingRight: "5%" }}>
                  <svg
                    width="6"
                    height="6"
                    viewBox="0 0 6 6"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="3" cy="3" r="3" fill="#D8D6DB" />
                  </svg>
                </span>
                {props.course_overall.courseAssessmentCount === 1
                  ? '1 Assessment'
                  : `${props.course_overall.courseAssessmentCount} Assessments`} &nbsp;
              </p>
            )}
          </div>
        </Grid>
      </Grid>
      <div className={classes.root}>
        {props.course_content &&
          props.course_content.length > 0 &&
          props.course_content.map((data, index) => {
            if (data.active !== true) {
              return;
            }
            return (
              <Accordion
                expanded={expanded === `panel${index}`}
                onChange={handleChange(`panel${index}`)}
                elevation={0}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography className={classes.heading}>
                    <strong>{data.title}</strong>
                  </Typography>
                  {data.mediaType && data.mediaType["video"] && (
                    <>
                      <Typography
                        className={classes.secondaryHeading}
                        class="text-muted acc-min list-group list-group-horizontal"
                      >
                        <span>
                          <i className="far fa-play-circle">&nbsp;</i>&nbsp;
                          {data.mediaType["video"] && `${data.mediaType["video"]} Videos`}
                        </span>
                        <span className="ml-3 ">
                          <i className="far fa-clock">&nbsp;</i>&nbsp;
                          {`${timePad(data.moduleDurationHour)}:${timePad(data.moduleDurationMin)}:${timePad(data.moduleDurationSec)}`}
                        </span>
                      </Typography>
                    </>
                  )}
                </AccordionSummary>
                {data.resourceList.length > 0 &&
                  data.resourceList.map((resourceData) => {
                    return (
                      <div
                        className="d-flex bd-highlight mb-3 ml-3 border"
                        style={{
                          backgroundColor: "#fafafa",
                          borderRadius: "10px",
                        }}
                      >
                        {/* <div className="mr-auto bd-highlight text-muted"> */}
                        {(resourceData.type.toLowerCase() === "video" ||
                          resourceData.type.toLowerCase() === "video quiz" ||
                          resourceData.type.toLowerCase() === "audio") && (
                          <div style={{ width: "100%" }}>
                            <Box display="flex" p={1}>
                              <Box p={1} flexGrow={1}>
                                <div className="mt-2 root">
                                  <svg
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M9.99999 1.66669C5.40499 1.66669 1.66666 5.40502 1.66666 10C1.66666 14.595 5.40499 18.3334 9.99999 18.3334C14.595 18.3334 18.3333 14.595 18.3333 10C18.3333 5.40502 14.595 1.66669 9.99999 1.66669ZM9.99999 16.6667C6.32416 16.6667 3.33332 13.6759 3.33332 10C3.33332 6.32419 6.32416 3.33335 9.99999 3.33335C13.6758 3.33335 16.6667 6.32419 16.6667 10C16.6667 13.6759 13.6758 16.6667 9.99999 16.6667Z"
                                      fill="#7C7887"
                                    />
                                    <path
                                      d="M7.5 14.1666L14.1667 9.99998L7.5 5.83331V14.1666Z"
                                      fill="#7C7887"
                                    />
                                  </svg>

                                  <span> &nbsp; {resourceData.name}</span>
                                </div>
                              </Box>
                              <Box p={1}>
                                <div
                                  className="p-2 bd-highlight"
                                  style={{ marginRight: "10px" }}
                                >
                                  {/* <a
                                    style={{ color: "#1D20D5" }}
                                    onClick={() =>
                                      props.video_url()
                                    }
                                  >
                                    <u>Play</u>
                                  </a> */}
                                </div>
                              </Box>
                              <Box p={1}>
                                <div className="p-2 bd-highlight content-min text-muted">
                                  {`${timePad(resourceData.durationHour)}:${timePad(resourceData.durationMin)}:${timePad(resourceData.durationSec)}`}
                                </div>
                              </Box>
                            </Box>
                          </div>
                        )}
                        {resourceData.type.toLowerCase() ===
                          "Link".toLowerCase() && (
                          <div style={{ width: "100%" }}>
                            <Box display="flex" p={1}>
                              <Box p={1} flexGrow={1}>
                                <div className="mt-2 root">
                                  <svg
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M9.99999 1.66669C5.40499 1.66669 1.66666 5.40502 1.66666 10C1.66666 14.595 5.40499 18.3334 9.99999 18.3334C14.595 18.3334 18.3333 14.595 18.3333 10C18.3333 5.40502 14.595 1.66669 9.99999 1.66669ZM9.99999 16.6667C6.32416 16.6667 3.33332 13.6759 3.33332 10C3.33332 6.32419 6.32416 3.33335 9.99999 3.33335C13.6758 3.33335 16.6667 6.32419 16.6667 10C16.6667 13.6759 13.6758 16.6667 9.99999 16.6667Z"
                                      fill="#7C7887"
                                    />
                                    <path
                                      d="M7.5 14.1666L14.1667 9.99998L7.5 5.83331V14.1666Z"
                                      fill="#7C7887"
                                    />
                                  </svg>

                                  <span> &nbsp; {resourceData.name}</span>
                                </div>
                              </Box>
                              <Box p={1}>
                                <div
                                  className="p-2 bd-highlight"
                                  style={{ marginRight: "10px" }}
                                >
                                  {/* <a
                                    style={{ color: "#1D20D5" }}
                                    onClick={() =>
                                      props.video_url()
                                    }
                                  >
                                    <u>Play</u>
                                  </a> */}
                                </div>
                              </Box>
                              {/* <Box p={1}>
                              <div className="p-2 bd-highlight content-min text-muted">
                                {resourceData.durationMin} mins
                              </div>
                            </Box> */}
                            </Box>
                          </div>
                        )}
                        {resourceData.type.toLowerCase() ===
                          "Study material".toLowerCase() && (
                          <div style={{ width: "100%" }}>
                            <Box display="flex" p={1}>
                              <Box p={1} flexGrow={1}>
                                <div className="mt-2 root">
                                  <svg
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M17.5 2.5H11.6667C11.0242 2.5 10.4433 2.75083 9.99999 3.15C9.55666 2.75083 8.97582 2.5 8.33332 2.5H2.49999C2.03916 2.5 1.66666 2.87333 1.66666 3.33333V15.8333C1.66666 16.2942 2.03916 16.6667 2.49999 16.6667H7.29832C7.73666 16.6667 8.16666 16.845 8.47666 17.155L9.41082 18.0892C9.41832 18.0967 9.42832 18.0992 9.43582 18.1067C9.50749 18.1725 9.58749 18.2308 9.68082 18.27C9.68166 18.27 9.68166 18.27 9.68249 18.27C9.78332 18.3117 9.89082 18.3333 9.99999 18.3333C10.1092 18.3333 10.2167 18.3117 10.3175 18.27C10.3183 18.27 10.3183 18.27 10.3192 18.27C10.4125 18.2308 10.4925 18.1725 10.5642 18.1067C10.5717 18.0992 10.5817 18.0967 10.5892 18.0892L11.5233 17.155C11.8333 16.845 12.2633 16.6667 12.7017 16.6667H17.5C17.9608 16.6667 18.3333 16.2942 18.3333 15.8333V3.33333C18.3333 2.87333 17.9608 2.5 17.5 2.5ZM7.29832 15H3.33332V4.16667H8.33332C8.79332 4.16667 9.16666 4.54083 9.16666 5V15.5742C8.61832 15.205 7.96416 15 7.29832 15ZM16.6667 15H12.7017C12.0358 15 11.3817 15.205 10.8333 15.5742V5C10.8333 4.54083 11.2067 4.16667 11.6667 4.16667H16.6667V15Z"
                                      fill="#7C7887"
                                    />
                                  </svg>
                                  <span> &nbsp; {resourceData.name}</span>
                                </div>
                              </Box>
                              <Box p={1}>
                                <div
                                  className="p-2 bd-highlight"
                                  style={{ marginRight: "10px" }}
                                >
                                  {/* <a
                                   style={{ color: "#1D20D5" }}
                                   onClick={() =>
                                     props.video_url()
                                   }
                                 >
                                   <u>Play</u>
                                 </a> */}
                                </div>
                              </Box>
                              {/* <Box p={1}>
                             <div className="p-2 bd-highlight content-min text-muted">
                               {resourceData.durationMin} mins
                             </div>
                           </Box> */}
                            </Box>
                          </div>
                        )}
                        {resourceData.type.toLowerCase() ===
                          "Practice lab".toLowerCase() && (
                          <div style={{ width: "100%" }}>
                            <Box display="flex" p={1}>
                              <Box p={1} flexGrow={1}>
                                <div className="mt-2 root">
                                  {PracIcon({ size: 20, color: "#7C7887" })}
                                  <span> &nbsp; {resourceData.name}</span>
                                </div>
                              </Box>
                              <Box p={1}>
                                <div
                                  className="p-2 bd-highlight"
                                  style={{ marginRight: "10px" }}
                                >
                                  {/* <a
                                   style={{ color: "#1D20D5" }}
                                   onClick={() =>
                                     props.video_url()
                                   }
                                 >
                                   <u>Play</u>
                                 </a> */}
                                </div>
                              </Box>
                              <Box p={1}>
                                {/* <div className="p-2 bd-highlight content-min text-muted">
                               {resourceData.durationMin} mins
                             </div> */}
                              </Box>
                            </Box>
                          </div>
                        )}
                        {resourceData.type.toLowerCase() ===
                          "Live class".toLowerCase() && (
                          <div style={{ width: "100%" }}>
                            <Box display="flex" p={1}>
                              <Box p={1} flexGrow={1}>
                                <div className="mt-2 root">
                                  {LiveClassIcon({
                                    size: 20,
                                    color: "#7C7887",
                                  })}

                                  <span> &nbsp; {resourceData.name}</span>
                                </div>
                              </Box>
                              <Box p={1}>
                                <div
                                  className="p-2 bd-highlight"
                                  style={{ marginRight: "10px" }}
                                ></div>
                              </Box>
                              <Box p={1}></Box>
                            </Box>
                          </div>
                        )}
                        {resourceData.type.toLowerCase() ===
                          "Assessment".toLowerCase() && (
                          <div style={{ width: "100%" }}>
                            <Box display="flex" p={1}>
                              <Box p={1} flexGrow={1}>
                                <div className="mt-2 root">
                                  {AssessmentIcon({
                                    size: 20,
                                    color: "#7C7887",
                                  })}

                                  <span> &nbsp; {resourceData.name}</span>
                                </div>
                              </Box>
                              <Box p={1}>
                                <div
                                  className="p-2 bd-highlight"
                                  style={{ marginRight: "10px" }}
                                ></div>
                              </Box>
                              <Box p={1}></Box>
                            </Box>
                          </div>
                        )}
                        {resourceData.type.toLowerCase() ===
                          "Scrom".toLowerCase() && (
                          <div style={{ width: "100%" }}>
                            <Box display="flex" p={1}>
                              <Box p={1} flexGrow={1}>
                                <div className="mt-2 root">
                                  {scromIcon({ size: 20, color: "#7C7887" })}
                                  <span> &nbsp; {resourceData.name}</span>
                                </div>
                              </Box>
                              <Box p={1}>
                                <div
                                  className="p-2 bd-highlight"
                                  style={{ marginRight: "10px" }}
                                >
                                </div>
                              </Box>
                              <Box p={1}>
                              </Box>
                            </Box>
                          </div>
                        )}
                        {/* </div> */}
                      </div>
                    );
                  })}
              </Accordion>
            );
          })}
      </div>
    </>
  );
}
