import { useEffect, useRef } from "react";
import { Button, Grid } from '@material-ui/core';
import CanvasDraw from 'react-canvas-draw';
import { ClearOutlined, UndoOutlined } from "@material-ui/icons";
import styles from './canvas.module.scss';

export default function CanvasComponent({ id, isLightMode, logActivity }) {
  const canvasComponent = useRef(null);
  const saveTimer = useRef(null);

  useEffect(() => {
    canvasComponent.current?.clear?.();

    if (canvasComponent.current) {
      try {
        const canvasData = sessionStorage.getItem(`practice-${id}-ws-canvas`);

        if (canvasData) {
          canvasComponent.current?.loadSaveData?.(JSON.parse(canvasData));
        }
      } catch {
        // 
      }
    }
  }, [canvasComponent.current, id]);

  return (
    <div className={styles.canvas}>
      <div
        className={styles.content}
        style={{ backgroundColor: isLightMode ? '#ccc' : '#444' }}>
        <Grid className={styles.toolbar}>
          <Button
            color="inherit"
            startIcon={<ClearOutlined />}
            onClick={() => {
              sessionStorage.setItem(`practice-${id}-ws-canvas`, '');
              canvasComponent.current?.clear?.();
            }}>
            Clear
          </Button>
          &nbsp;&nbsp;&nbsp;
          <Button
            color="inherit"
            startIcon={<UndoOutlined />}
            onClick={() => {
              canvasComponent.current?.undo?.();
            }}>
            Undo
          </Button>
        </Grid>
        <CanvasDraw
          brushRadius={2}
          loadTimeOffset={1}
          canvasHeight={400}
          canvasWidth={380}
          onChange={() => {
            if (saveTimer.current) {
              clearTimeout(saveTimer.current);
              saveTimer.current = null;
            }

            sessionStorage.setItem(
              `practice-${id}-ws-canvas`,
              JSON.stringify(canvasComponent.current?.getSaveData?.()),
            );

            saveTimer.current = setTimeout(() => {
              logActivity?.('workspace-canvas');
            }, 1000);
          }}
          ref={canvasComponent}
        />
      </div>
    </div>
  );
}
