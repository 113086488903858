import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import MobileStepper from "@material-ui/core/MobileStepper";
import { Button, CircularProgress, useMediaQuery } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import styles from "./loader.module.scss";
import { InfoRounded } from "@material-ui/icons";
import logo from "pages/LoginPage/New/chittilogo.svg";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: "rgba(0, 0, 0, 0.05) 0 0 16px 0px",
    maxWidth: 530,
    borderRadius: 12,
    overflow: "hidden",
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(3),
    width: "100% !important",
    backgroundColor: theme.palette.background.default,
    flexGrow: 1,
  },
  header: {
    display: "flex",
    alignItems: "center",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    border: "1px solid #D8D6DB",
    backgroundColor: theme.palette.background.default,
  },
  img: {
    // height: 255,
    display: "block",
    maxWidth: "100%",
    objectPosition: "center",
    objectFit: "contain",
    overflow: "hidden",
    width: "100%",
  },
}));

export function Loader({
  testIsLoaded,
  setIfLoadingTest,
  customerLogo,
}: {
  testIsLoaded: boolean;
  setIfLoadingTest: any;
  customerLogo: {productName: string, productLogo: string};

}) {
  const theme = useTheme();
  const isMobile = useMediaQuery("(max-width: 767px)");
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);

  const tutorialSteps = [
    {
      label:
        'For navigating through sections use "Section dropdown" at the top left of the screen',
      imgPath: !isMobile
        ? "/assets/assessment-tips/tip-1.png"
        : "/assets/assessment-tips/mob-tip-1.png",
    },
    {
      label:
        'For submitting the assessment use "Submit Test" button at the top right of the screen',
      imgPath: !isMobile
        ? "/assets/assessment-tips/tip-2.png"
        : "/assets/assessment-tips/mob-tip-2.png",
    },
    {
      label:
        'After choosing the answer use "Confirm" button at bottom right to save your answer',
      imgPath: !isMobile
        ? "/assets/assessment-tips/tip-3.png"
        : "/assets/assessment-tips/mob-tip-3.png",
    },
    {
      label: !isMobile
        ? 'For switching between question use "Pagination" at the top or use question navigation below the question'
        : "For Coding question use bottom navigation to navigate to code editor",
      imgPath: !isMobile
        ? "/assets/assessment-tips/tip-4.png"
        : "/assets/assessment-tips/mob-tip-4.png",
    },
    {
      label:
        'When there is any problem regarding question, Click "Report a problem" button in question container',
      imgPath: !isMobile
        ? "/assets/assessment-tips/tip-5.png"
        : "/assets/assessment-tips/mob-tip-5.png",
    },
  ];

  const maxSteps = tutorialSteps.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step: number) => {
    setActiveStep(step);
  };

  return (
    <div className={styles.container}>
      <span style={{paddingTop:"2%", paddingBottom:"2%"}}>
        {customerLogo && customerLogo.productLogo && (
          <div className="pl-2">
            <img src={customerLogo.productLogo} alt={customerLogo.productName ? customerLogo.productName : "logo"} height="44px" />
          </div>
        )}
      </span>
      <div
        className={classes.root}
        style={{
          ...(isMobile && {
            maxWidth: "calc(100% - 20px)",
            marginLeft: 10,
            marginRight: 10,
          }),
        }}
      >
        <AutoPlaySwipeableViews
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={activeStep}
          onChangeIndex={handleStepChange}
          enableMouseEvents
        >
          {tutorialSteps.map((step, index) => (
            <div key={step.label}>
              {Math.abs(activeStep - index) <= 2 ? (
                <div>
                  <img
                    className={classes.img}
                    src={step.imgPath}
                    alt={step.label}
                  />
                </div>
              ) : null}
            </div>
          ))}
        </AutoPlaySwipeableViews>
        <Paper square elevation={0} className={classes.header}>
          {!isMobile && (
            <>
              <Box
                padding={1}
                style={{
                  backgroundColor: "#FFF9EF",
                  borderRadius: 32,
                }}
              >
                <InfoRounded style={{ color: "#D99229", fontSize: 36 }} />
              </Box>
              <Box marginRight={2.5} />
            </>
          )}
          <Typography>{tutorialSteps[activeStep].label}</Typography>
        </Paper>
        <MobileStepper
          variant="dots"
          steps={tutorialSteps.length}
          position="static"
          activeStep={activeStep}
          nextButton={
            <Button
              size="large"
              color="primary"
              onClick={handleNext}
              disabled={activeStep === tutorialSteps.length - 1}
            >
              <KeyboardArrowRight />
            </Button>
          }
          backButton={
            <Button
              size="large"
              color="primary"
              onClick={handleBack}
              disabled={activeStep === 0}
            >
              <KeyboardArrowLeft />
            </Button>
          }
        />
      </div>
      <br />
      <br />
      {testIsLoaded ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              setIfLoadingTest?.(false);
            }}
          >
            Continue to test
          </Button>
        </Box>
      ) : (
        <Box
          style={{ display: "flex", flexWrap: "nowrap", alignItems: "center" }}
        >
          <CircularProgress size={24} />
          <Box mx={1} />
          <Typography variant="h5">Loading</Typography>
        </Box>
      )}
      <br />
      <br />
    </div>
  );
}
