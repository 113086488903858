import MasterForm from "./MasterForm";

export default function CreateCompetency(props) {
  return (
    <MasterForm
      postLink="createCompetency"
      label={{
        breadcrumb: "Competency",
        back: "Competencies",
      }}
      backLink="/competency"
      location={{
        ...props.location,
      }}
      history={{
        ...props.history,
      }}
      activePage={props?.location?.state?.activePage}
      itemsPerPage={props?.location?.state?.itemsPerPage}
    />
  );
}
