import { useEffect, useState } from "react";
import TabForm1 from "./TabForm1";
import SectionCreation from "./SectionCreation";
import TabForm3 from "./TabForm3";
import TabForm4 from "./TabForm4";
import TabForm5 from "./TabForm5";
import Header from "./Header";
import { Link, useParams } from "react-router-dom";
import { useGetCourse } from "./hooks/useCourse";
import MasterAPIs from "pages/utils/MasterAPIs";
import { defaultFormValues } from "./Content";
import { getAllQuestionBanks } from "pages/utils/CommonAPIs";

function Prepare({ location }) {
  const [tabValue, setTabValue] = useState(1);
  const [courseId, setCourseId] = useState(null);
  const [allCategories, setAllCategories] = useState([]);
  const [allFormValues, setAllFormValues] = useState(defaultFormValues);

  const [customerId, setCustomerId] = useState(location?.state?.customerId || "");
  const [customerName, setCustomerName] = useState("");
  // const [selectedUserList, setSelectedUserList] = useState([]);
  // const [sDate, setSDate] = useState("");
  // const [eDate, setEDate] = useState("");
  const [selSec, setSelSec] = useState(location?.state?.currentSection);
  const [selLes, setSelLes] = useState();
  const [secVal, setSecVal] = useState(location?.state?.sectionValues || [])
  const [selCurr, setSelCurr] = useState(location?.state?.currentLesson || {})
  const [prepPracticeRes, setPrepPracticeRes] = useState(location?.state?.prepPracticeRes || {})
  const [questionBanks, setQuestionBanks] = useState([]);

  const [loadEdit, setLoadEdit] = useState("");

  const goNext = () => setTabValue((pre) => pre + 1);
  const goPrevious = () => setTabValue((pre) => pre - 1);

  const hasId = useParams();

  const getAllCategories = async () => {
    try {
      const data = await MasterAPIs.ALL_COURSE_CATEGORIES();
      if (data && data.length > 0) {
        setAllCategories(data);
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (hasId?.id) {
      setCourseId(hasId?.id);
    }
    getAllCategories();
    if (location?.state?.activeTab && location?.state?.currentStep) {
      setTabValue(location?.state?.activeTab);
      setLoadEdit(location?.state?.currentStep);
    }
    if (location?.state?.customerId || location?.state?.customerName) {
      // setCustomerId(location?.state?.customerId);
      setCustomerName(location?.state?.customerName)
    }

  }, []);

  const courseData = useGetCourse(hasId?.id);
  
  const setFormValue = (values) =>{
    setAllFormValues({ ...allFormValues, [`formValue${tabValue}`]: values });
  }

  const isEdit = location?.state?.fromEdit;
  // const customerId = location?.state?.customerId;
  const prepPracticeId = location?.state?.prepPracticeId || "";
  const createdFromPrepare = location?.state?.createdFromPrepare || false;
  // const cName = location?.state?.contentName || "";
  // const cPer = location?.state?.completionCriteria || "";

  const allQBCall = async () => {
    try {
      const allQBs = await getAllQuestionBanks();
      setQuestionBanks(allQBs);
    } catch (e) {}
  };

  useEffect(() => {
    allQBCall();
  }, []);

  return (
    <div className="page-content" style={{position: "relative"}}>
      <Header tabValue={tabValue} />
      {tabValue === 1 ? (
        <TabForm1
          goNext={goNext}
          setCourseId={setCourseId}
          courseId={courseId}
          courseData={courseData}
          allCategories={allCategories}
          setFormValue1={setFormValue}
          formValue1={allFormValues.formValue1}
          isEdit={isEdit}
          customerID={customerId}
          customerName={customerName}
          setCustomerId={setCustomerId}
          setCustomerName={setCustomerName}
          // setSelectedUserList={setSelectedUserList}
          // setSDate={setSDate}
          // setEDate={setEDate}
        />
      ) : tabValue === 2 ? (
        <SectionCreation
          goNext={goNext}
          goPrevious={goPrevious}
          courseId={courseId}
          setFormValue2={setFormValue}
          formValue2={allFormValues.formValue2}
          isEdit={isEdit}
          customerId={customerId}
          customerName={customerName}
          loadEdit={loadEdit}
          // selectedUserList={selectedUserList}
          // sDate={sDate}
          // eDate={eDate}
          selSec={selSec}
          setSelSec={setSelSec}
          selCurr={selCurr}
          prepPracticeRes={prepPracticeRes}
          setPrepPracticeRes={setPrepPracticeRes}
          selLes={selLes}
          setSelLes={setSelLes}
          secVal={secVal}
          createdFromPrepare={createdFromPrepare}
          prepPracticeId={prepPracticeId}
          // cName={cName}
          // cPer={cPer}
          courseData={Object.keys(allFormValues.formValue1).length > 0 ? allFormValues.formValue1 : courseData}
          listQB={questionBanks}
        />
      ) : tabValue === 3 ? (
        <TabForm3
          goNext={goNext}
          goPrevious={goPrevious}
          courseId={courseId}
          courseData={courseData}
          setFormValue3={setFormValue}
          formValue3={allFormValues.formValue3}
          isEdit={isEdit}
        />
      ) : tabValue === 4 ? (
        <TabForm4
          goNext={goNext}
          goPrevious={goPrevious}
          courseId={courseId}
          courseData={courseData}
          setFormValue4={setFormValue}
          formValue4={allFormValues.formValue4}
          isEdit={isEdit}
        />
      ) : tabValue === 5 ? (
        <TabForm5
          goPrevious={goPrevious}
          courseId={courseId}
          courseData={courseData}
          setFormValue5={setFormValue}
          formValue5={allFormValues.formValue5}
          isEdit={isEdit}
          customerId={customerId}
          customerName={customerName}
        />
      ) : (
        ""
      )}
      <Link
        to={{ pathname: "/view-prepare", state: { customer: customerId, customerName: customerName } }}
        className="terv-back"
      >
        <i className="mdi mdi-arrow-left mr-1" /> Back to Courses
      </Link>
    </div>
  );
}

export default Prepare;
