import React, { Component } from "react"
import api from "../../api/baseConfig"
import Breadcrumbs from "components/Common/Breadcrumb"
import Apihelper from "../../api/apiHelper"
import { Col, Container, Row, Table, Button } from "reactstrap"
import { Link } from "react-router-dom"
import SectionReport from "./userReport/sectionLevel"
import moment from "moment"
export default class Viewreport extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      userId: props.location.userId,
      assessmentid: props.location.state.id,
      details: {},
    }
  }
  componentDidMount() {
    try {
      Apihelper.axiosCall(
        `${api.baseURL}${api.userManagement.getAssementReview}${this.state.userId}/${this.state.assessmentid}`,
        "get"
      ).then(data => {
        this.setState({ details: data })
      })
    } catch (e) {}
  }
  render() {
    return (
      <React.Fragment>
        <div className="page-content" style={{ backgroundColor: "#FFF" }}>
          <Container fluid>
            <Breadcrumbs title="Report" breadcrumbItem="Student Report" />
            <div
              style={{
                fontSize: 14,
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div>
                <strong>Marks Obtained:</strong>
                &nbsp;{this.props.location.state?.marks}
              </div>
              <div>
                <strong>Grading: </strong>
                {this.state.details.gradingProcess == "auto" ? "Auto" :
                (this.state.details.manualGradingCompleted
                  ? "Completed"
                  : "Pending"
                )
              
              }
              </div>
              <div>
                <strong>Start Date:</strong>&nbsp;
                {moment(this.props.location.state?.startDate).format("LLL")}
              </div>
              <div>
                <strong>End Date:</strong>&nbsp;
                {moment(this.props.location.state?.endDate).format("LLL")}
              </div>
            </div>

            <SectionReport data={this.state.details} />
          </Container>
          <Link
            to="/assessment"
            className="btn text-muted d-none d-sm-inline-block btn-link"
          >
            <i className="mdi mdi-arrow-left mr-1" /> Back to Assessments{" "}
          </Link>
        </div>
      </React.Fragment>
    )
  }
}
