import { convertFromHTML } from "draft-js";
import { Table } from "reactstrap";
import * as CustomIcons from "../../assets/icons/icons";
import "./QuestionForm.scss";
import ReactHtmlParser from "html-react-parser";

const getOnlyStrings = (str) =>
  convertFromHTML(str) &&
  convertFromHTML(str).contentBlocks &&
  convertFromHTML(str).contentBlocks.length > 0
    ? convertFromHTML(str).contentBlocks[0].text
    : str;

export const TypeTables = ({ values, initialValues, ...props }) => (
  <>
    {values.length > 0 && (
      <div>
        <Table striped bordered>
          <thead style={{ backgroundColor: "#DAF2FD" }}>
            <tr>
              <th style={{ width: 100 }}>S.No</th>

              {props.tableHeaders.map((header, ind) => (
                <th style={{ width: 233 }} key={ind}>
                  {header.label}
                </th>
              ))}
              <th style={{ width: 100 }}>Actions</th>
            </tr>
          </thead>
          <tbody className="typeTableBody">
            {values.map((item, index) => (
              <tr style={{ backgroundColor: "#F9FDFF" }} className="TableRow">
                <td style={{ width: 100, fontWeight: 550 }}>{index + 1}</td>
                {props.tableHeaders.map((header, ind) => (
                  <td style={{ width: 233, fontWeight: 550 }} key={ind}>
                    <p
                      style={
                        header.label === "Solution"
                          ? {
                              maxHeight: 250,
                              overflow: "scroll",
                              overflowX: "hidden",
                            }
                          : {}
                      }
                    >
                      {" "}
                      {header.label === "Is"
                        ? item[header.value]
                          ? "Right"
                          : "Wrong"
                        : JSON.stringify(item[header.value])?.slice(5, 8) ===
                          "img"
                        ? `IMAGE - ${index + 1}`
                        : header.label === "Option"
                        ? getOnlyStrings(item[header.value])
                        : ReactHtmlParser(item[header.value])
                    }
                    </p>
                  </td>
                ))}
                <td style={{ width: 100 }}>
                  <span
                    onClick={() => props.editItem(index)}
                    style={{ color: "red", cursor: "pointer" }}
                  >
                    {CustomIcons.penIcon("#51B960")}
                  </span>
                  &nbsp; &nbsp; &nbsp;
                  <span
                    onClick={() => props.removeItem(index)}
                    style={{ color: "red", cursor: "pointer" }}
                  >
                    {CustomIcons.circleCrossIcon({
                      size: 19,
                      color: "#EB5757",
                    })}
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    )}
  </>
);
