export const handleSearch = (query, array) => {
  let arr = [];
  if (query) {
    array.forEach((e) => {
      if (e.name.toLowerCase().includes(query.toLowerCase())) {
        arr.push(e);
      }
    });
  }
  return arr;
};
