import React from "react";
import styles from "./Footer.module.scss";
import { Link } from "react-router-dom";
import { HomeContent } from "./HomeContent";
import { uid } from "react-uid";
import { instaNew, linkedinNew, fbNew, youtubeNew } from "./SVGs";

const socialMedia = [
  {
    name: "instagram",
    icon: instaNew,
    links: "https://www.instagram.com/terv.pro?igsh=MTAybGkzM3RmMnJnNQ==",
  },
  {
    name: "facebook",
    icon: fbNew,
    links: "https://www.facebook.com/tervpro.edu",
  },
  {
    name: "linkedin",
    icon: linkedinNew,
    links: "https://www.linkedin.com/company/tervpro/mycompany/",
  },
  {
    name: "youtube",
    icon: youtubeNew,
    links: "https://www.youtube.com/@TervProTech",
  },
];

const ListGroup = ({ data }: { data: string[] }) => (
  <div className={styles.listGroup}>
    {data.map((datum, ind) => (
      <div className={styles.listGroupItem} key={uid(ind)}>
        {datum}
      </div>
    ))}
  </div>
);

const SocialMediaItem = ({
  icon,
  pageLink,
}: {
  icon: JSX.Element;
  pageLink: string;
}) => (
  <div className={styles.socialMediaItem} onClick={() => window.open(pageLink)}>
    {icon}
  </div>
);

interface Props {
  isMobile: boolean;
  isTab: boolean;
}

const Footer: React.FC<Props> = ({ isMobile, isTab }) => {
  return (
    <div className={styles.footerContainer}>
      <p className={styles.footerP1}>{HomeContent.Footer.headers[0]}</p>
      <p className={styles.footerP2}>{HomeContent.Footer.headers[1]}</p>

      <div className={styles.footerBlock1}>
        <p className={styles.blockHeader}>{HomeContent.Footer.headers[2]}</p>
        <ListGroup data={HomeContent.Footer.sub.resources} />
      </div>
      <div className={styles.footerBlock2}>
        <p className={styles.blockHeader}>{HomeContent.Footer.headers[3]}</p>
        <ListGroup data={HomeContent.Footer.sub.plans} />
        <div className={styles.listGroupSp}>
          <Link to="/board-of-advisors" className={styles.listGroupItemSp}>
            Board of Advisors
          </Link>
        </div>
      </div>
      <div className={styles.footerBlock3}>
        <p className={styles.blockHeader}>{HomeContent.Footer.headers[4]}</p>
        <ListGroup data={HomeContent.Footer.sub.subjects} />
      </div>
      <div className={styles.footerBlock4}>
        <p className={styles.blockHeader}>{HomeContent.Footer.headers[5]}</p>
        <ListGroup data={HomeContent.Footer.sub.freeResources} />
      </div>
      <div className={styles.footerBlock5}>
        <p className={styles.blockHeader}>{HomeContent.Footer.headers[6]}</p>
        <ListGroup data={HomeContent.Footer.sub.prepareResources} />
      </div>
      <div
        className={isMobile ? styles.mobileFooterContact : styles.footerContact}
      >
        <p className={styles.footerContactUs}>
          {HomeContent.Footer.headers[7]}
        </p>
        <div className={styles.footerContactUsContainer}>
          {socialMedia.map((it, ind) => (
            <SocialMediaItem
              key={`socialMedia${ind}`}
              icon={it.icon}
              pageLink={it.links}
            />
          ))}
        </div>
      </div>
      {window.location.pathname === "/login" ? (
        ""
      ) : (
        <div className={styles.footerContactAddress}>
          <p className={styles.footerContactAddressP}>
            {HomeContent.Footer.headers[8]}
          </p>
          <div className={styles.footerAddressStyle}>
            <p>{HomeContent.Footer.address.line1}</p>
            <p>
              <span>Landline:</span>
              <span
                style={{
                  fontWeight: "bold",
                }}
              >
                {HomeContent.Footer.address.landLine}
              </span>
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default Footer;
