import React, { useState, useEffect } from "react";
import { TabContent, TabPane, Button, Modal, ModalBody } from "reactstrap";
import cn from "classnames";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import { connect, useSelector } from "react-redux";
import QuestionBankActions from "./QuestionBankActions";
import Styles from "./AllQuestionBanks.module.scss";
import Select from "react-select";
import Pagination from "react-js-pagination";
import { paginationClasses } from "../utils/constants";
import Tooltip from "@material-ui/core/Tooltip";
import api from "../../api/baseConfig";
import { convertFromHTML } from "draft-js";
import Apihelper from "../../api/apiHelper";
import { ToastContainer, toast } from "react-toastify";
import CustomSearch from "./CustomSearch";
import "./AllQuestionBanks.scss";
import * as CustomIcons from "../../assets/icons/icons";
import { Tabs } from "components/tabs/tabs";
import classes from "../CardStyles.module.scss";
import { NewHeader } from "components/NewHeader";
import { GetObjectFromString } from "pages/utils/GetObjectFromString";
import DelConfirmModal from "../Ecommerce/EcommerceOrders/DelConfirmModal";
import { sessionPrivileges } from "privileges";

const buttonStyle = {
  width: 40,
  height: 35,
  border: "1px solid #784df4",
  textAlign: "center",
  borderRadius: 5,
  padding: 6,
  color: "#784df4",
  cursor: "pointer",
};

const tabItems = ["approved", "draft", "pending", "rejected", "all"];

const paramsCondensed = {
  data: ["competency", "subCompetency", "domain", "subDomain"],
  name: ["Competency", "Sub Competency", "Domain", "Sub Domain"],
};

function QuestionBanks(props) {
  const userPrivileges = sessionPrivileges();
  const Priv = sessionPrivileges().userprivileges || [];
  const UserEmail = sessionPrivileges().userName;
  const customerID = sessionStorage.getItem("customer_id");
  const [activeTab, setActiveTab] = useState("approved");
  const [viewDetails, setViewDetails] = useState(false);
  const [customer, setCustomer] = useState("");
  const [appTab, setAppTab] = useState(false);
  const [delModal, setDelModal] = useState(false);
  const [qbDelele, setQbDelele] = useState({});
  const [originalData, setOriginalData] = useState({});
  const [loading, setLoading] = useState(false);
  const [propmtValue, setPropmtValue] = useState({
    title: "Delete Question Bank",
    body: "Are you sure you want to delete this Question Bank?",
    button: "No",
  });

  const [allBanks, setAllBanks] = useState({
    draftList: [],
    approvedList: [],
    pendingList: [],
    rejectedList: [],
    allList: [],
    realAllList: [],
  });
  const [activePages, setActivepages] = useState({
    draftList: 1,
    approvedList: 1,
    pendingList: 1,
    rejectedList: 1,
    allList: 1,
  });

  const [approveQb, setApproveQb] = useState({
    name: "",
    id: "",
    createdBy: "",
    type: "",
  });
  const [details, setDetails] = useState({
    name: "",
    description: "",
    competency: "",
    subCompetency: "",
    domain: "",
    subDomain: "",
    questionId: [],
    qb_type: "",
  });

  const allCustomers = useSelector((state) =>
    state.StudentAndCustomerReducer.Customers
      ? state.StudentAndCustomerReducer.Customers.reduce((acc, val) => {
          acc.push({
            label: val.name,
            value: val.id,
          });
          return acc;
        }, [])
      : []
  );

  useEffect(() => {
    window.scrollTo(0, 0);
    if (props?.location?.state?.activeTab) {
      setActiveTab(props?.location?.state?.activeTab);
    }
    if (props?.location?.state?.activePage) {
      const tabName = props?.location?.state?.activePage.tabName;
      const pageNo = props?.location?.state?.activePage.pageNo;
      let temp = { ...activePages };
      switch (tabName) {
        case "draftList":
          temp.draftList = +pageNo;
          setActivepages(temp);
          break;
        case "approvedList":
          temp.approvedList = +pageNo;
          setActivepages(temp);
          break;
        case "pendingList":
          temp.pendingList = +pageNo;
          setActivepages(temp);
          break;
        case "rejectedList":
          temp.rejectedList = +pageNo;
          setActivepages(temp);
          break;
        case "allList":
          temp.allList = +pageNo;
          setActivepages(temp);
          break;
      }
    }
  }, []);

  useEffect(() => {
    if (!Priv.includes("MANAGE_CUSTOMER")) {
      getAllQuestionBanks();
    } else {
      if (props?.location?.state?.customer) {
        setCustomer(
          GetObjectFromString(allCustomers, props?.location?.state?.customer)
        );
      } else {
        const defaultVal = allCustomers.find((customer) =>
          customer.label.toLowerCase().includes("varsity")
        );
        if (defaultVal) {
          setCustomer(defaultVal);
        }
      }
    }
  }, []);

  useEffect(() => {
    if (customer.value) {
      getAllQuestionBanks();
    }
  }, [customer]);

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  const toggleModalApp = () => {
    setAppTab(!appTab);
  };
  const viewToggle = () => {
    setViewDetails(!viewDetails);
  };
  const handlesearch = (query) => {
    let arr = [];
    if (query.length >= 1) {
      if (activeTab !== "all") {
        setActiveTab("all");
      }
      allBanks.realAllList.forEach((e) => {
        if (e.name.toLowerCase().includes(query.toLowerCase())) {
          arr.push(e);
        }
      });
    }
    if (arr.length > 0) {
      setAllBanks({ ...allBanks, allList: arr });
    } else if (query.length >= 2 && arr.length == 0) {
      setAllBanks({ ...allBanks, allList: arr });
    } else {
      setAllBanks({ ...allBanks, allList: allBanks.realAllList });
    }
  };
  const sendForApproval = async (type) => {
    if (approveQb.id.length > 0) {
      let payload = {
        id: approveQb.id,
        status: type,
        submissionMessage: "Message requesting for ",
        responseMessage: "Approved",
        updatedBy: UserEmail,
        approverId: UserEmail,
      };
      await Apihelper.axiosCallPost(
        `${api.baseURL}${api.questionBankController.UpdateStatus}`,
        "post",
        payload
      )
        .then(() => {
          getAllQuestionBanks();
        })
        .catch((e) => {});
    }
  };
  const getViewQB = async (id) => {
    await Apihelper.axiosCall(
      `${api.baseURL}${api.questionBankController.getQuestionBank}${id}`,
      "get"
    ).then((data) => {
      let tempType = "";
      allBanks.allList.forEach((i) => {
        if (i.id === id) {
          tempType = i.qb_type;
        }
      });
      setDetails({
        name: data.name,
        description: data.description,
        competency: data.competencyName,
        subCompetency: data.subCompetencyName,
        domain: data.domainName,
        subDomain: data.subDomainName,
        questionId: data.questionId,
        qb_type: tempType,
      });
    });
  };
  const getAllQuestionBanks = async () => {
    setLoading(true);
    const url = Priv.includes("MANAGE_CUSTOMER")
      ? `${api.baseURL}${api.questionBankController.getQuestionBanksByCustomerUser}${customer.value}`
      : `${api.baseURL}${api.questionBankController.getQuestionBanksByCustomerUser}${customerID}`;

    await Apihelper.axiosCall(url, "get")
      .then((datum) => {
        setOriginalData(datum);
        let tempDL = [];
        let tempAP = [];
        let tempPN = [];
        let tempRJ = [];
        let tempAll = [];
        let all = {
          draft: [],
          pending: [],
          approved: [],
          rejected: [],
        };
        setLoading(false);
        if (datum.hasOwnProperty("APPROVED_LIST")) {
          datum.DRAFT_LIST.forEach((data) => {
            data.qb_type = "Draft";
            tempDL.push(data);
          });
          datum.APPROVED_LIST.forEach((data) => {
            data.qb_type = "Approved";
            tempAP.push(data);
          });
          datum.PENDING_LIST.forEach((data) => {
            data.qb_type = "Pending";
            tempPN.push(data);
          });
          datum.REJECTED_LIST.forEach((data) => {
            data.qb_type = "Rejected";
            tempRJ.push(data);
          });
          tempAll = [...tempDL, ...tempAP, ...tempPN, ...tempRJ];
          setAllBanks({
            ...allBanks,
            draftList: tempDL,
            pendingList: tempPN,
            approvedList: tempAP,
            rejectedList: tempRJ,
            allList: tempAll,
            realAllList: tempAll,
          });
        } else {
          datum.forEach((data) => {
            data.qb_type =
              data.status.charAt(0).toUpperCase() + data.status.slice(1);
            all[data.status].push(data);
          });
          tempAll = [
            ...all.draft,
            ...all.pending,
            ...all.approved,
            ...all.rejected,
          ];
          setAllBanks({
            ...allBanks,
            draftList: all.draft,
            pendingList: all.pending,
            approvedList: all.approved,
            rejectedList: all.rejected,
            allList: tempAll,
            realAllList: tempAll,
          });
        }
      })
      .catch(() => {});
  };

  const handlePageChange = (pageNo, from) => {
    let temp = { ...activePages };
    switch (from) {
      case "approved":
        temp.approvedList = +pageNo;
        setActivepages(temp);
        break;
      case "draft":
        temp.draftList = +pageNo;
        setActivepages(temp);
        break;
      case "pending":
        temp.pendingList = +pageNo;
        setActivepages(temp);
        break;
      case "rejected":
        temp.rejectedList = +pageNo;
        setActivepages(temp);
        break;
      case "all":
        temp.allList = +pageNo;
        setActivepages(temp);
        break;
    }
  };

  const handlererender = async (e) => {
    await Apihelper.axiosCall(
      `${api.baseURL}${api.questionBankController.deleteQuestionBank}?id=${e.id}`,
      "post"
    )
      .then((resp) => {
        if (resp !== false) {
          setDelModal(!delModal);
          getAllQuestionBanks();
          toast.success(
            <>
              Deleted <span style={{ fontWeight: 500 }}>{e.name}</span>{" "}
              <i className="fas fa-check"></i>
            </>,
            {
              position: "bottom-start",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }
          );
        } else {
          setPropmtValue({
            ...propmtValue,
            propmtValue: {
              title: "Oops Error Occured ",
              body: "Seems this Question Bank is currently being used",
              button: "Cancel",
            },
          });
        }
      })
      .catch(function () {});
  };

  const delConfirmation = (confirmation) => {
    if (confirmation === true) {
      handlererender(qbDelele);
    } else {
      setDelModal(!delModal);
      setPropmtValue({
        ...propmtValue,
        propmtValue: {
          title: "Delete Question Bank",
          body: "Are you sure you want to delete this Question Bank?",
          button: "No",
        },
      });
    }
  };

  const setDelQuestionBank = (row) => {
    setQbDelele(row);
    setDelModal(!delModal);
  };

  let lastIndices = {
    approved: activePages.approvedList * 6,
    draft: activePages.draftList * 6,
    pending: activePages.pendingList * 6,
    rejected: activePages.rejectedList * 6,
    all: activePages.allList * 6,
  };
  let firstIndices = {
    approved: lastIndices.approved - 6,
    draft: lastIndices.draft - 6,
    pending: lastIndices.pending - 6,
    rejected: lastIndices.rejected - 6,
    all: lastIndices.all - 6,
  };

  return (
    <>
    {userPrivileges.showQuestionBanks && (
    <div className="page-content" style={{ position: "relative" }}>
      <DelConfirmModal
        isOpen={delModal}
        toggle={delConfirmation}
        data={propmtValue}
      />
      <ToastContainer />
      <QuestionBankActions
        open={appTab}
        onClose={toggleModalApp}
        Status={approveQb.type}
        headerFor={{
          toDraft: "Move to Draft",
          profEval: "Approve",
          adminSend:
            UserEmail === approveQb.createdBy && Priv.includes("SELF_APPROVAL")
              ? " Self Approval"
              : "Send for Approval ",
        }}
        contentFor={{
          toDraft: `Move ${approveQb.name} to Draft ?`,
          profEval: `Approve or Reject ${approveQb.name}`,
          adminSend:
            UserEmail === approveQb.createdBy && Priv.includes("SELF_APPROVAL")
              ? `Self Approve ${approveQb.name} ?`
              : `Send ${approveQb.name} for Approval ?`,
        }}
        buttonsFor={{
          toDraft: [{ label: "Move", action: "draft" }],
          profEval: [
            { label: "Approve", action: "approved" },
            { label: "Reject", action: "rejected" },
          ],
          adminSend: [
            {
              label:
                UserEmail === approveQb.createdBy &&
                Priv.includes("SELF_APPROVAL")
                  ? "Approve"
                  : "Send",
              action:
                UserEmail === approveQb.createdBy &&
                Priv.includes("SELF_APPROVAL")
                  ? "approved"
                  : "pending",
            },
          ],
        }}
        Action={(action) => sendForApproval(action)}
      />

      <Modal isOpen={viewDetails} size="lg" style={{maxWidth: '680px', width: '100%', top:'20%', border:'1px', borderRadius:'5px'}}>
        <div
          style={{ position: "relative", backgroundColor: "#000", padding: 10, border:'1px', borderRadius:'5px' }}
        >
          <div style={{ color: "white", textAlign: "center", fontSize: 15 }}>
            View Details
          </div>{" "}
          <span
            style={{
              position: "absolute",
              right: 15,
              bottom: 12,
              cursor: "pointer",
            }}
            onClick={() => {
              viewToggle();
              setDetails({
                name: "",
                description: "",
                competency: "",
                subCompetency: "",
                domain: "",
                subDomain: "",
                questionId: [],
                qb_type: "",
              });
            }}
          >
            {" "}
            {CustomIcons.closeIcon}
          </span>
        </div>
        <ModalBody>
        <div style={{ display: "flex", justifyContent:'space-between'}}>
          <div style={{ display: "flex", gap: 10, marginBottom: 15, width:'358px' }}>
            <div
              style={{
                backgroundColor: "#784df5",
                borderRadius: 7,
                padding: 10,
                height:51,
                width: 51,
                textAlign: "center",
              }}
            >
              {CustomIcons.noteIcon}
            </div>
            <div>
            <Tooltip
              title={details?.name}
              arrow
              placement="bottom-start"
            >
              <div style={{ fontWeight: 700 }} className='name_restrict'>{details?.name}</div>
            </Tooltip>
              <p className="details_restrict">
                {details.description.length > 0
                  ? convertFromHTML(details?.description).contentBlocks[0]?.text
                  : ""}
              </p>
            </div>
            </div>
            <div
              style={{
                color:
                  details.qb_type === "Approved"
                    ? "#70b678"
                    : details.qb_type === "Rejected"
                    ? "#f52a30"
                    : details.qb_type === "Pending"
                    ? "#d9bc00"
                    : details.qb_type === "Draft"
                    ? "#0085ff"
                    : "",
                fontSize:"16px"
              }}
            >
              {details.qb_type}
              <span style={{paddingLeft: '5px'}}>
                  {details.qb_type === "Approved"
                    ?  
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12 0C5.3832 0 0 5.3832 0 12C0 18.6168 5.3832 24 12 24C18.6168 24 24 18.6168 24 12C24 5.3832 18.6168 0 12 0ZM9.6012 17.2956L5.1456 12.8496L6.84 11.1504L9.5988 13.9044L15.9516 7.5516L17.6484 9.2484L9.6012 17.2956Z" fill="#43B224"/>
                      </svg>
                    : details.qb_type === "Rejected"
                    ? 
                      <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10.5 1.15625C15.6604 1.15625 19.8438 5.33959 19.8438 10.5C19.8438 15.6604 15.6604 19.8438 10.5 19.8438C5.33959 19.8438 1.15625 15.6604 1.15625 10.5C1.15625 5.33959 5.33959 1.15625 10.5 1.15625Z" stroke="#EB5757" strokeWidth="1.4375" stroke-miterlimit="10"/>
                        <path d="M3.89258 17.1072L17.1068 3.89296" stroke="#EB5757" strokeWidth="1.4375" stroke-miterlimit="10"/>
                      </svg>                                                            
                    : details.qb_type === "Pending"
                    ? 
                      <svg width="18" height="22" viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.75 21.5C0.551088 21.5 0.360322 21.421 0.21967 21.2803C0.0790178 21.1397 0 20.9489 0 20.75C0 20.5511 0.0790178 20.3603 0.21967 20.2197C0.360322 20.079 0.551088 20 0.75 20H2.25V18.5C2.24969 17.2265 2.60968 15.9788 3.28837 14.9012C3.96707 13.8235 4.93679 12.9599 6.0855 12.41C6.5205 12.2015 6.75 11.8445 6.75 11.525V10.475C6.75 10.1555 6.519 9.7985 6.0855 9.59C4.93679 9.04011 3.96707 8.17647 3.28837 7.09883C2.60968 6.0212 2.24969 4.77355 2.25 3.5V2H0.75C0.551088 2 0.360322 1.92098 0.21967 1.78033C0.0790178 1.63968 0 1.44891 0 1.25C0 1.05109 0.0790178 0.860322 0.21967 0.71967C0.360322 0.579018 0.551088 0.5 0.75 0.5H17.25C17.4489 0.5 17.6397 0.579018 17.7803 0.71967C17.921 0.860322 18 1.05109 18 1.25C18 1.44891 17.921 1.63968 17.7803 1.78033C17.6397 1.92098 17.4489 2 17.25 2H15.75V3.5C15.7503 4.77355 15.3903 6.0212 14.7116 7.09883C14.0329 8.17647 13.0632 9.04011 11.9145 9.59C11.4795 9.7985 11.25 10.1555 11.25 10.475V11.525C11.25 11.8445 11.481 12.2015 11.9145 12.41C13.0632 12.9599 14.0329 13.8235 14.7116 14.9012C15.3903 15.9788 15.7503 17.2265 15.75 18.5V20H17.25C17.4489 20 17.6397 20.079 17.7803 20.2197C17.921 20.3603 18 20.5511 18 20.75C18 20.9489 17.921 21.1397 17.7803 21.2803C17.6397 21.421 17.4489 21.5 17.25 21.5H0.75ZM3.75 2V3.5C3.75 4.3055 3.93 5.0675 4.2555 5.75H13.7445C14.0685 5.0675 14.25 4.3055 14.25 3.5V2H3.75ZM8.25 11.525C8.25 12.5765 7.533 13.379 6.7335 13.763C5.83994 14.1907 5.08562 14.8624 4.55768 15.7007C4.02974 16.5389 3.74973 17.5094 3.75 18.5C3.75 18.5 5.049 16.5515 8.25 16.28V11.525ZM9.75 11.525V16.28C12.951 16.5515 14.25 18.5 14.25 18.5C14.2503 17.5094 13.9703 16.5389 13.4423 15.7007C12.9144 14.8624 12.1601 14.1907 11.2665 13.763C10.467 13.379 9.75 12.578 9.75 11.5265V11.525Z" fill="#FDBC1F"/>
                      </svg>
                    : details.qb_type === "Draft"
                    ? 
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M20.2467 1.19254L20.4702 0.969042C20.6384 0.800917 20.8381 0.667573 21.0579 0.576622C21.2776 0.485671 21.5132 0.438895 21.751 0.438965C21.9888 0.439035 22.2243 0.485948 22.444 0.577028C22.6637 0.668107 22.8633 0.801569 23.0315 0.969792C23.1996 1.13802 23.3329 1.33771 23.4239 1.55746C23.5148 1.77722 23.5616 2.01274 23.5616 2.25057C23.5615 2.48841 23.5146 2.7239 23.4235 2.9436C23.3324 3.1633 23.1989 3.36292 23.0307 3.53104L22.8072 3.75304C23.1896 4.18183 23.3934 4.74063 23.3769 5.31489C23.3605 5.88914 23.125 6.43535 22.7187 6.84154L7.28072 22.281C7.18422 22.377 7.0634 22.445 6.93122 22.4775L0.931224 23.9775C0.805663 24.0088 0.674148 24.0071 0.549463 23.9725C0.424779 23.9379 0.311166 23.8716 0.21967 23.7801C0.128173 23.6886 0.0619052 23.575 0.0273071 23.4503C-0.00729101 23.3256 -0.0090421 23.1941 0.022224 23.0685L1.52222 17.0685C1.55504 16.9369 1.62295 16.8166 1.71872 16.7205L16.1817 2.25754C16.0371 2.15574 15.8611 2.10833 15.6849 2.1237C15.5087 2.13907 15.3436 2.21623 15.2187 2.34154L10.2807 7.28104C10.211 7.35078 10.1282 7.40609 10.0371 7.44383C9.94599 7.48157 9.84834 7.50099 9.74972 7.50099C9.65111 7.50099 9.55346 7.48157 9.46235 7.44383C9.37124 7.40609 9.28846 7.35078 9.21872 7.28104C9.14899 7.21131 9.09368 7.12853 9.05594 7.03742C9.0182 6.94631 8.99878 6.84866 8.99878 6.75004C8.99878 6.65143 9.0182 6.55378 9.05594 6.46267C9.09368 6.37156 9.14899 6.28877 9.21872 6.21904L14.1597 1.28104C14.5662 0.874565 15.1128 0.639073 15.6874 0.622895C16.262 0.606717 16.821 0.811079 17.2497 1.19404C17.6619 0.8257 18.1952 0.62196 18.7479 0.621683C19.3007 0.621407 19.8342 0.824613 20.2467 1.19254Z" fill="#1F6AFD"/>
                      </svg>
                    : ""
                  }
              </span>
            </div>
          </div>
          <div
            style={{
              display: "grid",
              grid: "repeat(2,max-content)/320px 320px",
              marginBottom: 15,
            }}
          >
            {paramsCondensed["data"].map((item, ind) => (
              <div
                key={`paramsCondensed${ind}`}
                style={{
                  gridRow: ind === 0 || ind === 1 ? "1/2" : "2/3",
                  gridColumn: ind === 0 || ind === 2 ? "1/2" : "2/3",
                }}
              >
                <div style={{display:'flex', paddingTop:'20px'}}>
                  <div style={{ fontWeight: 700, paddingRight:'5px' }}>
                    {paramsCondensed["name"][ind]}
                  </div>{" "}
                  <div className='name_restrict' style={paramsCondensed["name"][ind] == 'Sub Domain' || paramsCondensed["name"][ind] == 'Domain'  ? {width:'175px', paddingLeft:'34px'}: {width:'175px',}}>{`: ${details[`${item}`]}`}</div>
                </div>
              </div>
            ))}
          </div>

          <div
            style={{
              fontSize: "14px",
              textAlign: "center",
              marginBottom: 15,
              marginTop: 45,
            }}
          >
            {details?.questionId ?
            <p>
              This question bank has{" "}{details?.questionId?.length}{" "}
              {details?.questionId?.length == 1 || details?.questionId?.length == 0 ? 'question' : 'questions'}
            </p>
            :
            <p>
              This question bank has{" "}{0}{" "}question
            </p>
          }
          </div>
        </ModalBody>
      </Modal>
      <NewHeader name="Question Banks" />
      {Priv.includes("ADD_QUESTION_BANK") && (
        <Button
          color="primary"
          onClick={() =>
            props.history.push({
              pathname: "/create-new-qb",
              state: {
                customer: customer?.value,
                customerName: customer?.label,
              },
            })
          }
          className={Styles.cardViewButton}
        >
          <i className="fas fa-plus" />
          &nbsp; Create New
        </Button>
      )}
      <CustomSearch
        handlesearch={(e) => handlesearch(e)}
        boxshadowsetActiveTab
        placeholder="Search Question Banks"
        primary
        style={{ position: "absolute", top: 20, left: "31.5%" }}
      />
      <div style={{ position: "relative" }}>
        {Priv.includes("MANAGE_CUSTOMER") && (
          <div
            style={{
              position: "absolute",
              top: 8,
              right: 18,
              width: "20%",
              zIndex: 12,
            }}
          >
            <Select
              onChange={(val) => {
                setCustomer(val);
                setActiveTab("approved");
                setActivepages({
                  draftList: 1,
                  approvedList: 1,
                  pendingList: 1,
                  rejectedList: 1,
                  allList: 1,
                });
              }}
              options={allCustomers}
              value={customer}
              placeholder="Choose a customer"
            />
          </div>
        )}
      </div>
      <Tabs
        onChange={toggle}
        activeTab={activeTab}
        tabs={[
          {
            name: "Approved",
            badge:
              Object.keys(originalData).length > 0
                ? allBanks["approvedList"].length
                : 0,
            id: "approved",
          },
          {
            name: "Draft",
            badge:
              Object.keys(originalData).length > 0
                ? allBanks["draftList"].length
                : 0,
            id: "draft",
          },
          {
            name: "Pending",
            badge:
              Object.keys(originalData).length > 0
                ? allBanks["pendingList"].length
                : 0,
            id: "pending",
          },
          {
            name: "Rejected",
            badge:
              Object.keys(originalData).length > 0
                ? allBanks["rejectedList"].length
                : 0,
            id: "rejected",
          },
          {
            name: "All",
            badge:
              Object.keys(originalData).length > 0
                ? allBanks["allList"].length
                : 0,
            id: "all",
          },
        ]}
      >
        <TabContent activeTab={activeTab} className={Styles.tabContent}>
          {tabItems.map((tab, index) => (
            <TabPane tabId={tab} key={`${index}${tab}`}>
              <div className={Styles.TabPane}>
                {allBanks.realAllList.length > 0 &&
                  allBanks.allList.length > 0 &&
                  Object.keys(originalData).length > 0 &&
                  allBanks[`${tab}List`]
                    .slice(firstIndices[tab], lastIndices[tab])
                    .map((j) => (
                      <>
                        <Card classes={{ root: classes.CARD_NEW }} key={j.id}>
                          <CardActionArea>
                            <CardMedia
                              classes={{ root: classes.CardMedia }}
                              image={
                                j.media
                                  ? j.media.includes("default")
                                    ? `https://d2ndap9rlps54r.cloudfront.net/DefaultUploadImages/${j.media}.jpeg`
                                    : j.media
                                  : `https://d2ndap9rlps54r.cloudfront.net/DefaultUploadImages/default-${
                                      Math.floor(Math.random() * 9) + 1
                                    }.jpeg`
                              }
                              title={`${j.media}`}
                            />
                            <CardContent>
                              <div className={Styles.question_count}>
                                <span className={Styles.fileIcon}>
                                  {CustomIcons.fileText({
                                    size: 15,
                                    color: "#fff",
                                  })}
                                </span>
                                &nbsp;
                                {j?.questionId ? j?.questionId?.length : 0}
                                &nbsp; {j?.questionId?.length !== 1 ? 'questions' : 'question'}
                              </div>
                              <Tooltip
                                title={j?.name}
                                arrow
                                placement="bottom-start"
                              >
                                <p className={classes.CardHeader}>{j?.name}</p>
                              </Tooltip>
                              {false && (
                                <p
                                  className={classes.CardDesc}
                                  dangerouslySetInnerHTML={{
                                    __html: j?.description,
                                  }}
                                />
                              )}
                              {tab === "all" && (
                                <span
                                  className={cn([Styles.tag], {
                                    [Styles.tagAP]: j.qb_type === "Approved",
                                    [Styles.tagDF]: j.qb_type === "Draft",
                                    [Styles.tagPN]: j.qb_type === "Pending",
                                    [Styles.tagRJ]: j.qb_type === "Rejected",
                                  })}
                                >
                                  {j.qb_type === "Approved"
                                    ? CustomIcons.approved({
                                        size: 16,
                                        color: "#fff",
                                      })
                                    : j.qb_type === "Rejected"
                                    ? CustomIcons.circleCrossIcon
                                    : j.qb_type === "Pending"
                                    ? CustomIcons.hourGlass
                                    : j.qb_type === "Draft"
                                    ? CustomIcons.penFill2({
                                        size: 16,
                                        color: "#fff",
                                      })
                                    : ""}
                                  &nbsp;
                                  {j.qb_type}
                                </span>
                              )}
                            </CardContent>
                          </CardActionArea>

                          <CardActions
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            {Priv.includes("VIEW_QUESTION_BANK") && (
                              <Tooltip
                                title="View Bank"
                                arrow
                                placement="top-start"
                              >
                                <div
                                  onClick={() => {
                                    getViewQB(j.id);
                                    viewToggle();
                                  }}
                                  style={buttonStyle}
                                >
                                  {CustomIcons.eyeFilled}
                                </div>
                              </Tooltip>
                            )}{" "}
                            {Priv.includes("DELETE_QUESTION_BANK_QUESTIONS") &&
                            (tab === "draft" || j.qb_type === "Draft") ? (
                              <Tooltip
                                title="Delete"
                                arrow
                                placement="top-start"
                              >
                                <div
                                  style={{
                                    ...buttonStyle,
                                  }}
                                  onClick={() => setDelQuestionBank(j)}
                                >
                                  {CustomIcons.trashFilled}
                                </div>
                              </Tooltip>
                            ) : (
                              ""
                            )}{" "}
                            {tab === "draft" ||
                            tab === "rejected" ||
                            j.qb_type === "Draft" ? (
                              <>
                                {Priv.includes("EDIT_QUESTION_BANK") && (
                                  <Tooltip
                                    title="Edit"
                                    arrow
                                    placement="top-start"
                                  >
                                    <div
                                      style={buttonStyle}
                                      onClick={() =>
                                        props.history.push({
                                          pathname: "/create-new-qb",
                                          state: {
                                            data: j,
                                            customer: customer.value,
                                            activeTab: activeTab,
                                            activePage: activePages,
                                            customerName: customer.label,
                                          },
                                        })
                                      }
                                    >
                                      {CustomIcons.penIcon("#784df4")}
                                    </div>
                                  </Tooltip>
                                )}
                                {Priv.includes("QUESTION_BANK_APPROVE") &&
                                  j.approvalReady && (
                                    <Tooltip
                                      title="Send for Approval"
                                      arrow
                                      placement="top-start"
                                    >
                                      <div
                                        style={buttonStyle}
                                        onClick={() => {
                                          toggleModalApp();
                                          setApproveQb({
                                            name: j.name,
                                            id: j.id,
                                            createdBy: j.createdBy,
                                            type: "adminSend",
                                          });
                                        }}
                                      >
                                        {CustomIcons.paperPlane("#784df4")}
                                      </div>
                                    </Tooltip>
                                  )}
                              </>
                            ) : (
                              ""
                            )}{" "}
                            {tab === "pending" &&
                              Priv.includes(
                                "QUESTION_BANK_APPROVE_OR_REJECT"
                              ) && (
                                <Tooltip
                                  title="Approve"
                                  arrow
                                  placement="top-start"
                                >
                                  <div
                                    style={buttonStyle}
                                    onClick={() => {
                                      toggleModalApp();
                                      setApproveQb({
                                        name: j.name,
                                        id: j.id,
                                        type: "profEval",
                                      });
                                    }}
                                  >
                                    {CustomIcons.checkIcon("#784df4")}
                                  </div>
                                </Tooltip>
                              )}
                            {Priv.includes("VIEW_QUESTION_BANK_QUESTIONS") && (
                              <Tooltip
                                title="Questions"
                                arrow
                                placement="top-start"
                              >
                                <div
                                  style={buttonStyle}
                                  onClick={() =>
                                    props.history.push({
                                      pathname: "/view-questions",
                                      state: {
                                        id: j.id,
                                        name: j.name,
                                        type: j.qb_type,
                                        from: tab,
                                        customerId: customer.value,
                                        activeTab: activeTab,
                                        activePage: activePages,
                                        defaultPage: 1,
                                        customerName: customer.label,
                                      },
                                    })
                                  }
                                >
                                  {CustomIcons.clipboardIcon("#784df4")}
                                </div>
                              </Tooltip>
                            )}{" "}
                            {(Priv.includes("QUESTION_BANK_DRAFT") &&
                              tab === "approved") ||
                            tab === "rejected" ||
                            j.qb_type === "Approved" ? (
                              <Tooltip
                                title="Move to Draft"
                                arrow
                                placement="top-start"
                              >
                                <div
                                  style={buttonStyle}
                                  onClick={() => {
                                    toggleModalApp();
                                    setApproveQb({
                                      name: j.name,
                                      id: j.id,
                                      type: "toDraft",
                                    });
                                  }}
                                >
                                  {CustomIcons.boxArrowRight("#794df5")}
                                </div>
                              </Tooltip>
                            ) : (
                              ""
                            )}
                          </CardActions>
                        </Card>
                      </>
                    ))}
                {(allBanks.allList.length == 0 ||
                  Object.keys(originalData).length == 0) && (
                  <div>
                    {loading ? (
                      <p style={{ fontWeight: "bold", fontSize: "20px" }}>
                        loading....
                      </p>
                    ) : (
                      <p style={{ fontWeight: "bold", fontSize: "20px" }}>
                        No Question bank found
                      </p>
                    )}
                  </div>
                )}
              </div>
              {Object.keys(originalData).length > 0 &&
                allBanks[`${tab}List`].length > 0 && (
                  <div className={Styles.paginationWrap}>
                    <Pagination
                      activePage={activePages[`${tab}List`]}
                      itemsCountPerPage={6}
                      {...paginationClasses}
                      totalItemsCount={allBanks[`${tab}List`].length}
                      pageRangeDisplayed={5}
                      onChange={(pageNo) => handlePageChange(pageNo, tab)}
                    />
                  </div>
                )}
            </TabPane>
          ))}
        </TabContent>
      </Tabs>
    </div>
    )}
    </>
  );
}

export default connect(null, null)(QuestionBanks);
