import useSWR from 'swr';
import { Config } from '../../../../config';
import { getData } from '../../../../api';
import { apiList } from '../../../../api/list';

export function useQuestionInfo(questionId: string, labId: string, onSuccess: any, sessionId: string) {
  const { data, error } = useSWR(
    (questionId && labId) ? [questionId, labId, sessionStorage.getItem('user_id') || '', sessionId] : null,
    {
      async fetcher(a: string, b: string, c: string, d: string) {
        return await getData(apiList.practiceLab.getQuestionInfo(a, b, c))
          .catch(() => ({}));
      },
      dedupingInterval: Config.sessionDuration,
      onSuccess(data) {
        onSuccess?.(data);

        return data;
      },
      onError() {
        return {};
      },
    },
  );

  return { data, error, loading: !data && !error };
}