import cn from 'classnames';
import {
  Drawer,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from '@material-ui/core';
import { CollapsibleSummarySection } from '../collapsible-summary-section/collapsible-summary-section';
import { QuestionButton } from '../question-button/question-button';
import { TProps } from './sidenav.props';
import styles from './sidenav.module.scss';

export function SideNav(props: TProps) {
  return (
    <Drawer
      anchor={props.smallScreen ? 'bottom' : 'right'}
      classes={{
        paper: cn(styles.sectionDrawer, props.smallScreen && styles.smallScreen),
      }}
      onClose={() => {
        props.setIfOpen(false);
      }}
      open={props.open}>
      <DialogTitle
        style={{
          borderBottom: '1px solid #ddd',
          padding: '0 0 14px 0',
          textAlign: 'center',
        }}>
        Questions
      </DialogTitle>
      <DialogContent
        style={{ padding: 0 }}>
        {props.sectionIds.map((sectionId) => (
          <div className={styles.sectionDrawerSection} key={sectionId}>
            <CollapsibleSummarySection
              sectionId={sectionId}
              alignTitleLeft={false}
              smallScreen={props.smallScreen}
              alwaysOpen={!props.smallScreen}>
              <div className={styles.sectionDrawerQuestionsContainer}>
                {props.sections?.[sectionId]?.questions?.map?.(({ id: questionId }, idx) => (
                  <QuestionButton
                    sectionId={sectionId}
                    key={questionId}
                    large
                    questionNum={idx + 1}
                    onClick={() => {
                      props.navToQuestion(sectionId, idx + 1);
                      props.setIfOpen(false);
                    }}
                    currentQuestion={(props.currentQuestion.num === (idx + 1)) && (props.currentSection.id === sectionId)}
                    flagged={props.flaggedQuestions?.[questionId]}
                    answered={props.answeredQuestions?.[questionId]}
                  />
                ))}
              </div>
            </CollapsibleSummarySection>
          </div>
        ))}
      </DialogContent>
      {!props.smallScreen && (
        <DialogActions
          style={{
            backgroundColor: '#F2F1F3',
            borderTop: '1px solid #D8D6DB',
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            padding: 20,
            gap: 20,
          }}>
          <Grid
            direction="row"
            container
            wrap="nowrap"
            style={{
              alignItems: 'center',
              display: 'flex',
              margin: 0,
            }}>
            <QuestionButton
              questionNum={0}
              currentQuestion
            />
            <Typography
              style={{
                fontSize: 14,
                marginLeft: 12,
              }}
              noWrap>
              Active
            </Typography>
          </Grid>
          <Grid
            direction="row"
            container
            wrap="nowrap"
            style={{
              alignItems: 'center',
              display: 'flex',
              margin: 0,
            }}>
            <QuestionButton
              questionNum={0}
              flagged
            />
            <Typography
              style={{
                fontSize: 14,
                marginLeft: 12,
              }}
              noWrap>
              Flagged ({props.numFlaggedQuestions})
            </Typography>
          </Grid>
          <Grid
            direction="row"
            container
            wrap="nowrap"
            style={{
              alignItems: 'center',
              display: 'flex',
              margin: 0,
            }}>
            <QuestionButton
              questionNum={0}
              answered
            />
            <Typography
              style={{
                fontSize: 14,
                marginLeft: 12,
              }}
              noWrap>
              Answered ({props.numAnsweredQuestions})
            </Typography>
          </Grid>
          <Grid
            direction="row"
            container
            wrap="nowrap"
            style={{
              alignItems: 'center',
              display: 'flex',
              margin: 0,
            }}>
            <QuestionButton
              questionNum={0}
            />
            <Typography
              style={{
                fontSize: 14,
                marginLeft: 12,
              }}
              noWrap>
              Not Answered ({props.numQuestions - props.numAnsweredQuestions})
            </Typography>
          </Grid>
        </DialogActions>
      )}
    </Drawer>
  );
}
