import { useState, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";

import { AddImageComponent } from "components/AddImageComponent";
import { AddMobImageComponent } from "components/AddImageComponent";
import { AddMediaComponent } from "components/AddMediaComponent";
import { FormLayout } from "components/FormLayout";
import { ErrorBlock } from "components/ErrorBlock";
import StyledNumberInput from "components/StyledNumberInput";

import { RequiredIndicator } from "pages/utils/RequiredIndicator";
import { GetObjectFromString } from "pages/utils/GetObjectFromString";
import { ReactSelectTervStyle } from "pages/utils/ReactSelectTervStyle";

import { LANGUAGES, LEVELS } from "./Content";
import { useGetCustomers } from "hooks/useGetCustomers";
import { sessionPrivileges } from "privileges";
import "./Prepare.scss";
import { TervButtonFilled } from "components/TervButtons";
import { CreateOrUpdate } from "./Apis";
import { HandleDefaultMedia } from "./../utils/HandleDefaultMedia";
import { yupResolver } from "@hookform/resolvers/yup";
import { Form1Schema } from "./ValidationSchemas";
import moment from "moment";
import AdvancedStudentSelectionAlt from "./AdvancedStudentSelectionAlt";
import { Input } from "reactstrap";
import { useMediaQuery } from "@material-ui/core";

const TabForm1 = ({
  goNext,
  setCourseId,
  courseData,
  allCategories,
  setFormValue1,
  formValue1,
  isEdit,
  customerID,
  setCustomerId,
  setCustomerName,
  setSelectedUserList,
  setSDate,
  setEDate,
}) => {
  const [descri, setDescri] = useState("");
  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(Form1Schema(isEdit, courseData, customerID)),
  });

  const isSmallScreen = useMediaQuery("(max-width: 1080px)");

  const {
    register,
    control,
    formState: { isSubmitting, errors },
    handleSubmit,
    reset,
    watch,
    setValue,
    getValues,
  } = methods;
  // useForm({
  //   mode: "onBlur",
  //   resolver: yupResolver(Form1Schema(isEdit, courseData)),
  // });

  const allCustomers = useGetCustomers();

  // const [customerId, setCustomerId] = useState(() =>
  //   sessionStorage.getItem("privileges").includes("MANAGE_CUSTOMER")
  //     ? props?.location?.params?.customerId || ""
  //     : sessionStorage.getItem("customer_id") || ""
  // );

  // const methods = useForm({
  //   mode: "onBlur",
  //   resolver: yupResolver(Form1Schema),
  // });
  const userPrivileges = sessionPrivileges();
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (
      (customerID && !courseData?.name) ||
      (!formValue1?.name &&
        userPrivileges &&
        userPrivileges.isSuperAdmin &&
        userPrivileges.isAdmin)
    ) {
      reset({
        customerId: customerID,
      });
    }
    if (courseData?.name || formValue1?.name) {
      const value = courseData?.name ? courseData : formValue1;
      // setSelectedUserList(value?.userIdList);
      // setSDate(value?.startDate ? value?.startDate.slice(0, 10) : "");
      // setEDate(value?.endDate ? value?.endDate?.slice(0, 10) : "");
      reset({
        name: value?.name ?? "",
        description: value?.description ?? "",
        allFilterType: value?.allFilterType ?? "",
        startDate: value?.startDate ? value?.startDate.slice(0, 10) : "",
        endDate: value?.endDate ? value?.endDate?.slice(0, 10) : "",
        duration: value?.duration ?? 0,
        level: value?.level ?? "",
        languages: value?.languages ?? [],
        media: value?.media ?? "",
        mobileMedia: value?.mobileMedia ?? "",
        category: courseData?.name
          ? { label: value?.category, value: value?.categoryId }
          : value?.category,
        promotionalVideo: value?.promotionalVideo ?? "",
        id: value?.id,
        ...(userPrivileges &&
          userPrivileges.isSuperAdmin && {
            customerId: value?.customerId ?? "",
          }),
        ...(userPrivileges &&
          userPrivileges.isAdmin && {
            customerId: value?.customerId ?? "",
          }),
        filterType: value?.filterType ?? "",
        userIdList: value?.userIdList ?? "",
      });
      setDescri(value?.description ?? "")
    }
  }, [reset, courseData]);

  const onSubmit = async (formValues) => {
    try {
      const res = await CreateOrUpdate({
        ...formValues,
        startDate: moment(formValues.startDate).format("yyyy-MM-DD"),
        endDate: moment(formValues.endDate).format("yyyy-MM-DD"),
        status: "draft",
        ...(customerID && { customerID }),
      });
      setCourseId(res.id);
      setFormValue1({
        ...formValues,
        startDate: moment(formValues.startDate).format("yyyy-MM-DD"),
        endDate: moment(formValues.endDate).format("yyyy-MM-DD"),
        id: res.id,
      });
      goNext();
    } catch (error) {}
  };
  return (
    <form className="manage-prepare__form" onSubmit={handleSubmit(onSubmit)}>
      <FormLayout
        description="Enter details of the course , duration and validity"
        title="Course Details"
      >
        <div className="terv-row">
          <div>
            <label className="terv-label">
              {RequiredIndicator("Name of the Course")}
            </label>
            <input
              {...register("name", { setValueAs: (v) => v.trim() })}
              className="terv-form terv-form-lg"
            />
            {errors.name && (
              <ErrorBlock
                eType="div"
                errorText={errors.name.message || ""}
                maxWidth="100%"
              />
            )}
          </div>
        </div>
        <div className="terv-row">
          <div>
            <label className="terv-label">
              {RequiredIndicator("Short Description ")}
            </label>
            <textarea 
              {...register("description", { setValueAs: (v) => v.trim() })}
              onChange={(e)=> setDescri(e.target.value)}
              className="terv-form terv-form-lg"
              style={{ height: "100px" }}
            />
            <div style={{display:"flex", gap:"16px"}}>
              <div style={{flex:"1 1 0%"}}>
                {errors.description && (
                  <ErrorBlock
                    eType="div"
                    errorText={errors.description.message || ""}
                    maxWidth="100%"
                  />
                )}
              </div>
              {/* <div style={{flex:"1 1 0%"}}>
                  <div style={{textAlign: "right",color: descri.length > 99 && descri.length < 501 ?  "#1ba94c" : "red"}}>
                  {`${parseInt(descri.length)}/500`}
                  </div>
              </div> */}
            </div>
          </div>
        </div>
        <div className="terv-row">
          <div>
            <label className="terv-label">
              {RequiredIndicator("Start Date ")}
            </label>
            <input
              type="date"
              {...register("startDate")}
              className="terv-form terv-form-md"
            />
            {errors.startDate && (
              <ErrorBlock
                eType="div"
                errorText={errors.startDate.message || ""}
                maxWidth="100%"
              />
            )}
          </div>
          <div>
            <label className="terv-label">
              {RequiredIndicator("End Date ")}
            </label>
            <input
              type="date"
              {...register("endDate")}
              className="terv-form terv-form-md"
            />
            {errors.endDate && (
              <ErrorBlock
                eType="div"
                errorText={errors.endDate.message || ""}
                maxWidth="100%"
              />
            )}
          </div>
        </div>
        <div className="terv-row">
          <div>
            <label className="terv-label">
              {RequiredIndicator("Course Duration (in hrs) ")}
            </label>
            <Controller
              name="duration"
              control={control}
              disableNegative
              render={({ field: { onChange, value, onBlur } }) => (
                <StyledNumberInput
                  onChange={onChange}
                  onBlur={onBlur}
                  value={+value || 0}
                  className="terv-form-with-icon"
                />
              )}
            />
            {errors.duration && (
              <ErrorBlock
                eType="div"
                errorText={errors.duration.message || ""}
                maxWidth="100%"
              />
            )}
          </div>
        </div>
      </FormLayout>
      <FormLayout
        title="Course Info"
        description="Choose course level, language and review option is applicable or not"
      >
        <div className="terv-row">
          <div>
            <label className="terv-label">
              {RequiredIndicator("Course Level ")}
            </label>
            <Controller
              name="level"
              control={control}
              render={({ field: { onChange, value, onBlur } }) => {
                return (
                  <Select
                    value={GetObjectFromString(LEVELS, value)}
                    onChange={(val) => onChange(val.value)}
                    options={LEVELS}
                    styles={ReactSelectTervStyle("md")}
                    onBlur={onBlur}
                  />
                );
              }}
            />
            {errors.level && (
              <ErrorBlock
                eType="div"
                errorText={errors.level.message || ""}
                maxWidth="100%"
              />
            )}
          </div>
          <div>
            <label className="terv-label">Course Language</label>

            <Controller
              name="languages"
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <Select
                    value={GetObjectFromString(LANGUAGES, value)}
                    onChange={(val) => onChange(val.value)}
                    options={LANGUAGES}
                    styles={ReactSelectTervStyle("md", true)}
                  />
                );
              }}
            />
          </div>
        </div>
        <div className="terv-row">
          <div>
            <label className="terv-label">
              {RequiredIndicator("Category")}
            </label>
            <Controller
              name="category"
              control={control}
              render={({ field }) => {
                return (
                  <Select
                    {...field}
                    options={allCategories}
                    styles={ReactSelectTervStyle("md", true)}
                  />
                );
              }}
            />
            {errors.category && (
              <ErrorBlock
                eType="div"
                errorText={errors.category.message || ""}
                maxWidth="100%"
              />
            )}
          </div>
        </div>
      </FormLayout>
      {userPrivileges && userPrivileges.isSuperAdmin && (
        <FormLayout
          title="Select Customer"
          description="Choose customer for this course, course will be visible to these customers only"
        >
          <div className="terv-row">
            <div>
              <label className="terv-label">
                {RequiredIndicator("Customer ")}
              </label>
              <Controller
                name="customerId"
                control={control}
                render={({ field: { onChange, value, onBlur } }) => (
                  <Select
                    onChange={(val) => {
                      onChange(val.value);
                      setCustomerId(val.value);
                      setCustomerName(val.label);
                      setShow(true);
                    }}
                    value={GetObjectFromString(allCustomers, value)}
                    styles={ReactSelectTervStyle("md")}
                    options={allCustomers}
                    onBlur={onBlur}
                  />
                )}
              />
              {errors.customerId && (
                <ErrorBlock
                  eType="div"
                  errorText={errors.customerId.message || ""}
                />
              )}
            </div>
          </div>
        </FormLayout>
      )}
      <FormLayout
        title="Assign Students"
        description="Assign students for this course, only assigned student can take the course"
      >
        {userPrivileges && userPrivileges.isSuperAdmin && (
          <AdvancedStudentSelectionAlt
            customerId={watch("customerId")}
            hookFormControls={methods}
            show={show}
            setShow={setShow}
            viewOnly={false}
            studentFieldName="userIdList"
          />
        )}
        {userPrivileges && userPrivileges.isAdmin && (
          <AdvancedStudentSelectionAlt
            customerId={sessionStorage.getItem("customer_id")}
            hookFormControls={methods}
            show={show}
            setShow={setShow}
            viewOnly={false}
            studentFieldName="userIdList"
          />
        )}
        {errors.filterType && (
          <ErrorBlock eType="div" errorText={errors.filterType.message || ""} />
        )}
      </FormLayout>
      <FormLayout
        description="Upload title image and promotional video of the course"
        title="Course Media"
      >
        <div className="terv-row">
          <div
            style={{
              display: "flex",
              gap: isSmallScreen? "1.5%": "10%",
            }}
          >
            <div>
              <label className="terv-label">
                {RequiredIndicator("Upload Website Title Image ")}
              </label>
              <Controller
                name="media"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <AddImageComponent
                    handleCroppedImage={onChange}
                    media={HandleDefaultMedia(value)}
                  />
                )}
              />
              {errors.media && (
                <ErrorBlock
                  eType="div"
                  errorText={errors.media.message || ""}
                  maxWidth="100%"
                />
              )}
            </div>
            <div>
              <label className="terv-label">
                {("Upload Title Image for Mobile")}
              </label>
              <Controller
                name="mobileMedia"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <AddMobImageComponent
                    handleCroppedImage={onChange}
                    media={HandleDefaultMedia(value)}
                  />
                )}
              />
            </div>
          </div>
        </div>
        <div className="terv-row">
          <div>
            <label className="terv-label">
              Promotional Video or Explanation Video
            </label>
            <div
              style={{
                color: "#7C7887",
                fontStyle: "normal",
                fontSize: "12px",
              }}
            >
              video must be maximum 5 minutes
            </div>
            <Controller
              name="promotionalVideo"
              control={control}
              render={({ field: { onChange, value } }) => (
                <AddMediaComponent
                  handleChange={onChange}
                  value={value}
                  mediaType="video/*"
                />
              )}
            />
          </div>
        </div>
      </FormLayout>
      <FormLayout hideDivider>
        <TervButtonFilled
          text="Continue"
          type="submit"
          width="6.125rem"
          disabled={isSubmitting}
        />
      </FormLayout>
    </form>
  );
};

export default TabForm1;
