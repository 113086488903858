import { useGetDashboardInfo } from "pages/Dashboard/SWRhooks";
import { useEffect, useState } from "react";
import GridItem from "../../../components/GridItem/GridItem";
// import InsightBox from "../../../components/InsightBox/InsightBox";
import NewInsightBox from "../../../components/NewInsightBox/NewInsightBox";
import SkeletonGridItem from "../../../components/SkeletonGridItem/SkeletonGridItem";
import AssessmentIcon from "../../../icons/assessment";
import PackageIcon from "../../../icons/package";
import api from "../../../../../api/baseConfig";
import Apihelper from "../../../../../api/apiHelper";
import QuestionIcon from "../../../icons/question";
import StudentIcon from "../../../icons/student";
import { sessionPrivileges } from "privileges";
import { Link } from "react-router-dom";

export function Cards(props) {
  const [dataIsLoading, setIfDataIsLoading] = useState(false);
  const [assessmentInfo, setAssessmentInfo] = useState();
  const [courseInfo, setCourseInfo] = useState();
  const [packageInfo, setPackageInfo] = useState();
  const [userInfo, setUserInfo] = useState();
  const [custId, setCustId] = useState("");
  const userPrivileges = sessionPrivileges()
  // const custId = props?.currentCustomer;

  const [insights, setInsights] = useState({
    numStudents: null,
    numPackagesCreated: null,
    numAssessmentsCreated: null,
    numQuestionsCreated: null,
  });

  const Priv = sessionPrivileges().userprivileges || [];

  const getAssessmentDetails = async (cusID) => {
    setIfDataIsLoading(true);
    let userId = sessionStorage.getItem("user_id"); //sessionStorage.getItem("applicable_authors")
    await Apihelper.getAssessmentDetails(
      `${api.baseURL}${api.dashboard.getAssessmentCount}${userId}${
        cusID ? `?customerId=${cusID}` : ""
      }`,
      "get"
    )
      .then((datum) => {
        setAssessmentInfo(datum);
        setIfDataIsLoading(false);
      })
      .catch((e) => {});
  };

  const getCoursesDetails = async (cusID) => {
    setIfDataIsLoading(true);
    let userId = sessionStorage.getItem("user_id"); //sessionStorage.getItem("applicable_authors")
    await Apihelper.getCoursesDetails(
      `${api.baseURL}${api.dashboard.getCourseCount}${userId}${
        cusID ? `?customerId=${cusID}` : ""
      }`,
      "get"
    )
      .then((datum) => {
        setCourseInfo(datum);
        setIfDataIsLoading(false);
      })
      .catch((e) => {});
  };

  const getPackageDetails = async (cusID) => {
    setIfDataIsLoading(true);
    let userId = sessionStorage.getItem("user_id"); //sessionStorage.getItem("applicable_authors")
    await Apihelper.getPackageDetails(
      `${api.baseURL}${api.dashboard.getPackageCount}${userId}${
        cusID ? `?customerId=${cusID}` : ""
      }`,
      "get"
    )
      .then((datum) => {
        setPackageInfo(datum);
        setIfDataIsLoading(false);
      })
      .catch((e) => {});
  };

  const getCustomerDetails = async (cusID) => {
    setIfDataIsLoading(true);
    let userId = sessionStorage.getItem("user_id"); //sessionStorage.getItem("applicable_authors")
    await Apihelper.getCustomerDetails(
      `${api.baseURL}${api.dashboard.getUserCount}${userId}${
        cusID ? `?customerId=${cusID}` : ""
      }`,
      "get"
    )
      .then((datum) => {
        setUserInfo(datum);
        setIfDataIsLoading(false);
      })
      .catch((e) => {});
  };

  useEffect(() => {
    if (Priv.includes("MANAGE_CUSTOMER") && props?.currentCustomer) {
      getAssessmentDetails(props.currentCustomer);
      getCoursesDetails(props.currentCustomer);
      getPackageDetails(props.currentCustomer);
      getCustomerDetails(props.currentCustomer);
      setCustId(props.currentCustomer);
    }
    if (!Priv.includes("MANAGE_CUSTOMER")) {
      getAssessmentDetails();
      getCoursesDetails();
      getPackageDetails();
      getCustomerDetails();
    }
  }, [props?.currentCustomer]);

  const apiResp = useGetDashboardInfo("getAdminDashBoardCountCards", true);

  useEffect(() => {
    if (apiResp && apiResp.hasOwnProperty("numStudents")) {
      setInsights({
        numStudents: apiResp.numStudents,
        numPackagesCreated: apiResp.numPackagesCreated,
        numAssessmentsCreated: apiResp.numAssessmentsCreated,
        numQuestionsCreated: apiResp.numQuestionsCreated,
      });
    }
  }, [apiResp]);

  return dataIsLoading ? (
    <>
      <SkeletonGridItem
        gridPosition={[
          [1, 7],
          [1, 2],
        ]}
      />
      <SkeletonGridItem
        gridPosition={[
          [7, 13],
          [1, 2],
        ]}
      />
      <SkeletonGridItem
        gridPosition={[
          [1, 7],
          [2, 2],
        ]}
      />
      <SkeletonGridItem
        gridPosition={[
          [7, 13],
          [2, 2],
        ]}
      />
    </>
  ) : (
    <>
      <GridItem
        gridPosition={[
          [1, 7],
          [1, 2],
        ]}
      >
        <Link
          to={{
            pathname: userPrivileges.isSuperAdmin ? "/customerList" : "/userList",
          }}
        >
        <NewInsightBox
          color="ink"
          description={
            Priv.includes("MANAGE_CUSTOMER") && custId === "all"
              ? "Customers"
              : "Users in total"
          }
          description2={
            Priv.includes("MANAGE_CUSTOMER") && custId === "all"
              ? "Users"
              : "Active users"
          }
          description3="Questions created"
          description4="Active users"
          Icon={PackageIcon}
          title1={
            Priv.includes("MANAGE_CUSTOMER") && custId === "all"
              ? "CUSTOMER"
              : "DETAILS"
          }
          count1={
            Priv.includes("MANAGE_CUSTOMER")
              ? props?.currentCustomer === "all"
                ? userInfo?.userCard?.totalCustomers
                : userInfo?.userCard?.noOfUsersTotal
              : userInfo?.userCard?.noOfUsersTotal
          }
          count2={
            Priv.includes("MANAGE_CUSTOMER") && custId === "all"
              ? userInfo?.userCard?.noOfUsersTotal
              : userInfo?.userCard?.noOfActiveUsers
          }
          count3={userInfo?.userCard?.noOfQuestionsCreated}
          count4={userInfo?.userCard?.noOfActiveUsers}
          todayCount1={
            Priv.includes("MANAGE_CUSTOMER") && props?.currentCustomer === "all"
              ? userInfo?.userCard?.noOfCustomersCreatedToday
              : ""
          }
          todayCount2={userInfo?.userCard?.noOfQuestionsCreatedToday}
          currentCustomer={custId !== "all" ? true : false}
          showTile4={
            Priv.includes("MANAGE_CUSTOMER") && custId === "all" ? true : false
          }
          card1={true}
        />
        </Link>
      </GridItem>
      <GridItem
        gridPosition={[
          [7, 13],
          [1, 2],
        ]}
      >
        <Link
          to={{
            pathname: "/assessments",
          }}
        >
        <NewInsightBox
          color="red"
          description="Assessments created"
          description2="Active"
          description3="Completed"
          Icon={PackageIcon}
          title1="ASSESSMENTS"
          count1={assessmentInfo?.assessmentCard?.noOfAssessmentCreated}
          count2={assessmentInfo?.assessmentCard?.noOfAssessmentInActive}
          count3={assessmentInfo?.assessmentCard?.noOfAssessmentCompleted}
          currentCustomer={custId !== "all" ? true : false}
          showTile4={false}
          card1={false}
        />
        </Link>
      </GridItem>
      <GridItem
        gridPosition={[
          [1, 7],
          [2, 3],
        ]}
      >
        <Link
          to={{
            pathname: "/view-prepare",
          }}
        >
        <NewInsightBox
          color="green"
          data={courseInfo}
          description="Courses created"
          description2="Live"
          description3="Scheduled/draft"
          Icon={AssessmentIcon}
          title1="COURSES"
          count1={courseInfo?.courseCard?.noOfCoursesCreated}
          count2={courseInfo?.courseCard?.noOfCoursesOnLive}
          count3={courseInfo?.courseCard?.noOfCoursesDraft}
          currentCustomer={custId !== "all" ? true : false}
          showTile4={false}
          card1={false}
        />
        </Link>
      </GridItem>
      <GridItem
        gridPosition={[
          [7, 13],
          [2, 3],
        ]}
      >
        <Link
          to={{
            pathname: "/manage-allLabs",
          }}
        >
        <NewInsightBox
          color="blue"
          data={packageInfo}
          description="Packages created"
          description2="Active"
          description3="Newly added"
          Icon={QuestionIcon}
          title1="PACKAGES"
          count1={packageInfo?.packageCard?.noOfPackagesCreated}
          count2={packageInfo?.packageCard?.noOfPackagesInActive}
          count3={packageInfo?.packageCard?.noOfPackagesNewlyAdded}
          currentCustomer={custId !== "all" ? true : false}
          showTile4={false}
          card1={false}
        />
        </Link>
      </GridItem>
    </>
  );
}
