import React ,{useRef} from 'react'
import ReactHtmlParser from "html-react-parser";
import { useMediaQuery } from "@material-ui/core";

export default function HackathonContent(props) {
    const smallScreen = useMediaQuery("(max-width: 767px)");
    const videoRef = useRef();
    const mediaProps = {
        controls: true,
        ref: videoRef,
      };
      
      const sourceProps = {
        src: props?.overview?.promotionalVideo,
        type:"video/mp4",
      };
      
    return (
        <div>
            <div>
                {props?.overview?.promotionalVideo &&
                <div className={smallScreen ? '' :"pl-5"}>
                    <video {...mediaProps} key={props?.overview?.id} controlsList="nodownload" style={{width:"45rem"}}>
                        <source {...sourceProps} />
                    </video>
                </div>
                }
            <div className="pt-3">
            <div style={smallScreen ?{display:"flex"} : {display:"flex", paddingLeft: "3rem"}}>
                <h1 className={"hackathonCardHeader"} style={{fontSize: "20px"}}>Hackathon overview</h1>
                <svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M14 5L7 0V10L14 5ZM0 0V10L7 5L0 0Z" fill="#2170D8"/>
                </svg>
            </div>
            <p className={smallScreen ? '' : "paragraphreg"}>{ReactHtmlParser(props?.overview?.overview)}</p>
            </div>
            </div>
            <div className="pt-3">
                <div style={smallScreen ? {display:"flex"} : {display:"flex", paddingLeft: "3rem"}}>
                    <h1 className="hackathonCardHeader" style={{fontSize: "20px"}}>What to build in hackathon</h1>
                    <svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M14 5L7 0V10L14 5ZM0 0V10L7 5L0 0Z" fill="#2170D8"/>
                    </svg>
                 </div>
                <p className={smallScreen ? '' :"paragraphreg"}>{ReactHtmlParser(props?.overview?.details)}</p>
            </div>
            </div>
    )
  }