import cn from "classnames";
import { useEffect, useState } from "react";
import Tooltip from "@material-ui/core/Tooltip";
import styles from "./NewInsightBox.module.scss";
import { sessionPrivileges } from "privileges";

function NewInsightBox({
  color,
  description,
  description2,
  description3,
  description4,
  Icon,
  title,
  title1,
  count1,
  count2,
  count3,
  count4,
  todayCount1,
  todayCount2,
  currentCustomer,
  showTile4,
  card1,
}) {
  const divider = (
    <svg
      width="1"
      height="32"
      viewBox="0 0 1 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="1" height="32" fill="#D8D6DB" />
    </svg>
  );
  const dotSVG = (
    <svg
      width="6"
      height="6"
      viewBox="0 0 6 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="3" cy="3" r="3" fill="#089A79" />
    </svg>
  );
  const Priv = sessionPrivileges().userprivileges || [];

  return (
    <div
      className={cn(styles.box, {
        [styles.blue]: color === "blue",
        [styles.green]: color === "green",
        [styles.ink]: color === "ink",
        [styles.red]: color === "red",
      })}
    >
      {description === "Students in total" && (
        <>
          <div className={styles.titleContainer}>
            <h2 className={styles.title}>{title}</h2>
            <div className={styles.icon}>
              <Icon />
            </div>
          </div>
          <h2 className={styles.description}>{description}</h2>
        </>
      )}
      {description !== "Students in total" && (
        <div style={{ display: "flex" }}>
          <div
            className={
              Priv.includes("MANAGE_CUSTOMER") &&
              count4 &&
              description4 &&
              showTile4
                ? styles.newSATitleContainer1
                : styles.newtitleContainer1
            }
          >
            <p className={styles.newtitle}>{title1}</p>
            <div style={{ display: "flex" }}>
              <p className={styles.newCount}>{count1}</p>
              {(title1 === "CUSTOMER" ||
                (title1 === "DETAILS" && todayCount1)) && (
                <p className={styles.todayCount}>{`+${todayCount1} Today`}</p>
              )}
            </div>
            <p className={styles.newdescription}>{description}</p>
          </div>
          <div className={styles.divider}>{divider}</div>
          <div className={styles.newtitleContainer2}>
            <p className={styles.newCount}>{count2}</p>
            {card1 ? (
              <Tooltip title={description2} arrow placement="top-start">
                <p className={styles.newdescription1}>
                  {description !== "Customers" && <span>{dotSVG}</span>}
                  <span style={{ marginLeft: "5px" }}>{description2}</span>
                </p>
              </Tooltip>
            ) : (
              <p className={styles.newdescription}>
                {description !== "Customers" && <span>{dotSVG}</span>}
                <span style={{ marginLeft: "5px" }}>{description2}</span>
              </p>
            )}
          </div>
          {Priv.includes("MANAGE_CUSTOMER") && showTile4 && (
            <>
              <div className={styles.divider}>{divider}</div>
              <div className={styles.newtitleContainer4}>
                <p className={styles.newCount}>{count4}</p>
                <Tooltip title={description4} arrow placement="top-start">
                  <p className={styles.newdescription1}>
                    <span>{dotSVG}</span>
                    <span style={{ marginLeft: "5px" }}>{description4}</span>
                  </p>
                </Tooltip>
              </div>
            </>
          )}
          <div className={styles.divider}>{divider}</div>
          <div className={styles.newtitleContainer3}>
            <div style={{ display: "flex" }}>
              <p className={styles.newCount}>{count3}</p>
              {(title1 === "CUSTOMER" || title1 === "DETAILS") &&
                currentCustomer && (
                  <p className={styles.todayCount}>{`+${todayCount2} Today`}</p>
                )}
            </div>
            {Priv.includes("MANAGE_CUSTOMER") && showTile4 ? (
              <>
                <Tooltip title={description3} arrow placement="top-start">
                  <p className={styles.newdescription1}>{description3}</p>
                </Tooltip>
              </>
            ) : (
              <p className={styles.newdescription}>{description3}</p>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default NewInsightBox;
